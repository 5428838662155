/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useRecoilState } from "recoil";
import { siteId, userInfo } from "../../atoms";
import SelectSite from "../../Utilities/SelectSite";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

import "./users.css";
import { WebApimanager } from "../../WebApiManager";

const Employee = () => {
  let webApi = new WebApimanager();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [datasites, setDataSites] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [userId, setUserId] = useState(currentUserInfo._id || "");

  const [formValues, setFormValues] = useState({
    firstname: "",
    lastname: "",
    contactnumber: "",
    emailid: "",
    bloodGroup: "",
    emergencyContactnumber: "",
    reportingEmpIdName: "",
    employeeId: "",
    selectedDepartment: "",
    selectedRole: "",
    selectedWorkModel: "",
    permittedSite: [],
    reportingEmpId: "",
    reportingUserId: "",
  });

  const [selectedSiteIds, setSelectedSiteIds] = useState([]);

  useEffect(() => {
    console.log(formValues);
  }, [formValues]);

  useEffect(() => {
    webApi
      .get("api/sites/AllSites")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          const sitesList = response.data.sites.map((permittedSite) => ({
            id: permittedSite._id,
            name: permittedSite.siteName,
          }));
          setDataSites(sitesList);

          console.log("All sites data:", response.data.sites);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      });
  }, []);

  const [ModulesData, SetModulesData] = useState([]);
  const [allData, setAllData] = useState(null);
  const [selectedFavorite, setSelectedFavorite] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleCardClick = (item) => {
    setSelectedFavorite(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFavorite(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await webApi.get("api/sites/getAll");
        const fetchedData = response.data;

        setAllData(fetchedData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (selectedSiteIds.length > 0) {
      fetchData();
    }
  }, [selectedSiteIds]);

  useEffect(() => {
    webApi
      .get("api/users")
      .then((response) => {
        if (response.data && Array.isArray(response.data.data.allUsers)) {
          setData(response.data.data.allUsers);
          const allUsers = response.data.data.allUsers;

          const favoriteUsers = allUsers.filter(
            (user) => user.favouriteUsers.length > 0
          );

          const loggedInUserId = userId;
          const allOtherUsers = allUsers.filter(
            (user) =>
              !favoriteUsers.includes(user._id) && user._id !== loggedInUserId
          );

          setData(allUsers);
          setFavorites(favoriteUsers);
          setEveryone(allOtherUsers);

          console.log("ALL USERS", setData);
          setFavorites(favoriteUsers);
          setEveryone(allOtherUsers);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error.meg);
        setError(
          error.response?.data?.msg || "There was an error fetching the data"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [favorites, setFavorites] = useState([]);

  const [everyone, setEveryone] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const removeFavorite = async (favoriteUserId) => {
    try {
      const response = await webApi.delete(
        `api/users/${userId}/removeFavouriteUser/${favoriteUserId}`
      );
      window.location.reload();
      if (response.status === 200 && response.data.status === "success") {
        const updatedFavorites = favorites.filter(
          (user) => user._id !== favoriteUserId
        );
        const userToAdd = favorites.find((user) => user._id === favoriteUserId);

        setCurrentUserInfo({
          ...currentUserInfo,
          favouriteUsers: currentUserInfo.favouriteUsers.filter(
            (id) => id !== favoriteUserId
          ),
        });
        setFavorites(updatedFavorites);
        if (userToAdd) {
          setEveryone([...everyone, userToAdd]);
        }
      }
    } catch (error) {
      console.error("Error removing favorite:", error);
    }
  };

  const addFavorite = async (favoriteUserId) => {
    try {
      const response = await webApi.put(`api/users/${userId}`, {
        favouriteUserId: favoriteUserId,
      });
      window.location.reload();
      if (response.status === 200 && response.data.status === "success") {
        const userToAdd = everyone.find((user) => user._id === favoriteUserId);
        if (userToAdd) {
          setCurrentUserInfo({
            ...currentUserInfo,
            favouriteUsers: [...currentUserInfo.favouriteUsers, favoriteUserId],
          });
          setFavorites([...favorites, userToAdd]);
          setEveryone(everyone.filter((user) => user._id !== favoriteUserId));
        }
      }
    } catch (error) {
      console.error("Error adding favorite:", error);
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredFavorites = favorites.filter((user) =>
    `${user.firstName} ${user.lastName} ${user.empId}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const filteredEveryone = everyone.filter(
    (user) =>
      !currentUserInfo.favouriteUsers.includes(user._id) &&
      `${user.firstName} ${user.lastName} ${user.empId}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (currentUserInfo) {
      setFavorites(
        everyone.filter((user) =>
          currentUserInfo.favouriteUsers.includes(user._id)
        )
      );
    }
  }, [everyone, currentUserInfo]);

  return (
    <>
      <div>
        {site === "" ? (
          <>
            <SelectSite />
          </>
        ) : (
          <>
            <div
              style={{
                marginTop: "50px",
                marginRight: "50px",
                marginLeft: "50px",
                borderRadius: "12px",
                backgroundColor: "white",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "18px",
              }}
            >
              <div
                onClick={toggleVisibility}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "80px",
                  padding: "20px 20px",
                  cursor: "pointer",
                }}
              >
                <div>
                  <p className="favouriteTitle">FAVOURITE</p>
                </div>
                <div className="favorateIcons">
                  {isVisible ? (
                    <FaAngleUp size={25} />
                  ) : (
                    <FaAngleDown size={25} />
                  )}
                </div>
              </div>
              {isVisible && (
                <div
                  className="favorites-box"
                  style={{
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  {filteredFavorites.length === 0 ? (
                    <p>No favorites added yet.</p>
                  ) : (
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                    >
                      {filteredFavorites.map((favorite, index) => (
                        <div
                          className="favresponse"
                          key={favorite._id}
                          style={{
                            backgroundColor:
                              index % 6 < 3
                                ? "rgba(243, 246, 248, 1)"
                                : "rgba(253, 248, 244, 0.5)",
                            color: "black",
                            padding: "15px 25px",
                            textAlign: "left",
                            borderRadius: "5px",
                            border: "none",
                            // flex: "0 0 30%",
                            flex: "0 1 calc(33% - 20px)",
                            width: "32%",
                            // minWidth: "350px",
                            height: "141px",
                            fontWeight: "400",
                            boxSizing: "border-box",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="favFullname">
                              {favorite.firstName} {favorite.lastName}
                            </p>
                            <Icon.StarFill
                              size={20}
                              style={{
                                cursor: "pointer",
                                stroke: "#E47331",
                                strokeWidth: "0.5px",
                                color: "#E47331",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                removeFavorite(favorite._id);
                              }}
                            />
                          </div>

                          <p className="favEmpID">{favorite.empId}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div
              style={{
                marginTop: "50px",
                marginRight: "50px",
                marginLeft: "50px",
                borderRadius: "12px",
                backgroundColor: "white",
                padding: "15px",
                paddingTop: "20px",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "18px",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginLeft: "10px",
                  marginRight: "10px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="everyonetitel">EVERYONE</p>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      position: "relative",
                      width: "250px",
                      paddingLeft: "20px",
                      marginLeft: "500px",
                      height: "50px",
                    }}
                  >
                    <input
                      className="serchPlaceholder"
                      placeholder="Search Users"
                      value={searchTerm}
                      onChange={handleSearchInputChange}
                      style={{
                        borderRadius: "6px",
                        border: "1px solid black",
                        width: "250px",
                        height: "40px",
                        paddingLeft: "20px",
                        borderColor: "#e47331",
                        marginLeft: "-40px",
                      }}
                    />
                    <Icon.Search
                      style={{
                        position: "absolute",
                        right: "25px",
                        top: "40%",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                {filteredEveryone.map((item, index) => (
                  <div
                    onClick={() => handleCardClick(item)}
                    key={item._id}
                    style={{
                      backgroundColor:
                        index % 6 < 3
                          ? "rgba(243, 246, 248, 1)"
                          : "rgba(253, 248, 244, 0.5)",
                      color: "black",
                      padding: "15px 25px",
                      textAlign: "left",
                      borderRadius: "5px",
                      border: "none",
                      // flex: "0 0 30%",
                      flex: "0 1 calc(33% - 20px)",
                      width: "32%",
                      // minWidth: "350px",
                      height: "141px",
                      fontWeight: "400",
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="everyOneFullName">
                        {item.firstName} {item.lastName}
                      </p>
                      <Icon.StarFill
                        size={20}
                        style={{
                          cursor: "pointer",
                          stroke: "#E47331",
                          strokeWidth: "0.5px",
                          color: currentUserInfo.favouriteUsers.includes(
                            item._id
                          )
                            ? "#E47331"
                            : "white",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          addFavorite(item._id);
                        }}
                      />
                    </div>
                    <p className="everyoneEmpID">{item.empId}</p>
                  </div>
                ))}
              </div>
            </div>

            <div
              className={`modal fade ${showModal ? "show" : ""}`}
              style={{
                display: showModal ? "block" : "none",
                marginTop: "95px",
              }}
              tabIndex="-1"
              role="dialog"
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div
                    className="modal-header"
                    style={{
                      padding: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "#4D4D4D",
                      color: "white",
                    }}
                  >
                    <h5
                      className="modal-title favTitleVIEW"
                      style={{
                        color: "white",
                        margin: "0 auto",
                        textAlign: "center",
                        flexGrow: 1,
                      }}
                    >
                      View
                    </h5>

                    <button
                      type="button"
                      onClick={handleCloseModal}
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "13px",
                        color: "white",
                      }}
                    >
                      <RxCross2 size={25} />
                    </button>
                  </div>

                  <div className="modal-body">
                    {selectedFavorite ? (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr",
                          padding: "0px 20px",
                        }}
                      >
                        <div>
                          <p className="ViewLables">Full Name</p>
                        </div>
                        <div>
                          <p style={{ marginLeft: "90px" }}>:</p>
                        </div>
                        <div style={{ textAlign: "start" }}>
                          <p>
                            {selectedFavorite.firstName}
                            {selectedFavorite.lastName}
                          </p>
                        </div>
                        <div>
                          <p style={{ width: "200px" }} className="ViewLables">
                            Contact Number
                          </p>
                        </div>
                        <div>
                          <p style={{ marginLeft: "90px" }}>:</p>
                        </div>
                        <div style={{ textAlign: "start" }}>
                          <p>{selectedFavorite.contactNumber}</p>
                        </div>
                        <div>
                          <p className="ViewLables">Role</p>
                        </div>
                        <div>
                          <p style={{ marginLeft: "90px" }}>:</p>
                        </div>
                        <div style={{ textAlign: "start" }}>
                          <p>{selectedFavorite.role}</p>
                        </div>
                        <div>
                          <p className="ViewLables">Email</p>
                        </div>
                        <div>
                          <p style={{ marginLeft: "90px" }}>:</p>
                        </div>
                        <div style={{ textAlign: "start" }}>
                          <p>{selectedFavorite.email}</p>
                        </div>
                        <div>
                          <p className="ViewLables">Employee ID</p>
                        </div>
                        <div>
                          <p style={{ marginLeft: "90px" }}>:</p>
                        </div>
                        <div style={{ textAlign: "start" }}>
                          <p>{selectedFavorite.empId}</p>
                        </div>
                        <div>
                          <p className="ViewLables">Reporting Employee Name</p>
                        </div>
                        <div>
                          <p style={{ marginLeft: "90px" }}>:</p>
                        </div>
                        <div style={{ textAlign: "start" }}>
                          <p>{selectedFavorite.reportingEmpIdName}</p>
                        </div>
                      </div>
                    ) : (
                      <p>No data available</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Employee;
