/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dialog, DialogContent } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Icon from "react-bootstrap-icons";
import { WebApimanager } from "../../../WebApiManager";
import styles from "./AssetCodeCreation.module.css"


const EditAssetcode = ({ open,close,data }) => {
    console.log(data,"editdata")
    let webApi = new WebApimanager();
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [editData, setEditData] = useState(data || {});
    const [checklistData,setCheckListData] = useState([]);
    const [filteredFormNos, setFilteredFormNos] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
    useEffect(() => {
        if (data) {
          setEditData(data);
        }
      }, [data]);
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        if (name === "formNo") {
            const selectedForm = checklistData.find((item) => item._id === value);
        
            if (selectedForm) {
              setEditData((prevData) => ({
                ...prevData,
                formNo: selectedForm._id, 
                activity: selectedForm.activity?._id || "", 
              }));
            } else {
              setEditData((prevData) => ({
                ...prevData,
                activityName: "",
              }));
            }
          }
          if (name === "activity") {
            const selectedActivity = checklistData.find((item) => item.activity?._id === value);
        
            if (selectedActivity) {
              setEditData((prevData) => ({
                ...prevData,
                activityName: selectedActivity.activity._id, 
                formNo: selectedActivity._id || "", 
              }));
            } else {
              setEditData((prevData) => ({
                ...prevData,
                formNo: "", 
              }));
            }
          }
      };
      const fetchData = async () => {
        try {
          let response = await webApi.get(
            `api/checklistDesign/pnmDepartmentChecklistDesigns`
          );
       
            setCheckListData(response.data?.data || []); 
       
          console.log(checklistData,"pnm responsedata of designform")
        } catch (error) {
          console.error("Error fetching checklist data", error);
        }
      };
      
      useEffect(() => {
          fetchData();
      }, []);
    
      useEffect(() => {
        if (checklistData.length > 0) {
          setFilteredFormNos(checklistData.map((item) => item.formNo));
          setFilteredActivities(checklistData.map((item) => item.activity?.activity));
        }
      }, [checklistData]);
      const handleClose =() =>{
        close();
        setEditData({});

      }
  
    
      const addEditQuery = () => {
        setEditData((prevData) => ({
          ...prevData,
          queries: [...(prevData.documents || []), ""], 
        }));
      };
    
      const handleEditQueryChange = (index, value) => {
        setEditData((prevData) => {
          const updatedQueries = [...(prevData.queries || [])];
          updatedQueries[index] = value;
          return {
            ...prevData,
            queries: updatedQueries,
          };
        });
      };
    
      const removeEditQuery = (index) => {
        setEditData((prevData) => {
          const updatedQueries = (prevData.queries || []).filter(
            (_, i) => i !== index
          );
          return {
            ...prevData,
            queries: updatedQueries,
          };
        });
      };
      const handleEditSubmit = async () => {
        let valid = true;
        const newErrors = {};
    
        if (!editData.type.trim()) {
          newErrors.type = "type is required";
          valid = false;
        }
    
        if (!editData.assetCode.trim()) {
          newErrors.assetCode = "AssetCode is required";
          valid = false;
        }
        if (!editData.Name.trim()) {
          newErrors.Name = "Name is required";
          valid = false;
        }
    
        editData &&
          editData.documents &&
          editData.documents.forEach((drawing, index) => {
            if (drawing.trim() === "") {
              newErrors[`drawing${index}`] = "Please fill out this field";
              valid = false;
            }
          });
        editData &&
          editData.description &&
          editData.description.forEach((query, index) => {
            if (query.trim() === "") {
              newErrors[`query${index}`] = "Please fill out this field";
              valid = false;
            }
          });
    
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return; 
        }
        console.log("Form submitted:", editData);
        const tempEditData = {
          type: editData.type,
          assetCode: editData.assetCode,
          name: editData.Name,
          documents: editData.queries,
         
        };
        try {
          const response = await webApi.put(
            `api/pnms/assetCode/${editData._id}`,
            tempEditData
          );
      
          if (response.status === 200) {
            window.location.reload();
            handleClose();
          } else if (response.status === 400) {
    
            if (
              response.data &&
              Array.isArray(response.data) &&
              response.data.length > 0
            ) {
              setErrorMessage(response.data[0]); 
            } else {
            
              setErrorMessage("");
            }
          } else {
            setErrorMessage("An unexpected error occurred.");
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            Array.isArray(error.response.data) &&
            error.response.data.length > 0
          ) {
            setErrorMessage(error.response.data[0]);
          } else {
            setErrorMessage("Failed to submit data.");
          }
        }
      };
       useEffect(()=>{
        console.log(editData,"editData")
       },[editData])
     
    
    
return(


    <Dialog
    open={open}
    onClose={ handleClose}
    maxWidth="sm"
    fullWidth
    PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0px",
      }}
    >
      <p
        style={{ marginLeft: "25px", fontSize: "25px", marginTop: "20px" }}
      >
        Edit AssetCode
      </p>
      <Icon.XCircleFill
        size={20}
        style={{
          marginLeft: "auto",
          cursor: "pointer",
          marginRight: "25px",
        }}
        onClick={handleClose}
      />
    </div>
    <DialogContent
      style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
    >
      <section>
        <div>
          <label htmlFor="type" className={styles.AssetCodeCreationAddQueryButton}>
            Type
            <span style={{ color: "red" }}>*</span>
          </label>
          <select
            id="type"
            name="type"
            value={editData.type}
            onChange={handleEditChange}
            className={styles.EditAssetcodeInputField}
            style={{ width: "100%" }}
          >
            <option value="">Select Type</option>
            <option value="Vehicle">Vehicle</option>
            <option value="Machinery">Machinery</option>
            <option value="Power tools">PowerTools</option>
          </select>
          {errors.type && (
            <p style={{ color: "red", fontSize: "12px" }}>{errors.type}</p>
          )}
        </div>
        <div>
          <label
            htmlFor="assetCode"
            className={styles.AssetCodeCreationAddQueryButton}
          >
            Asset Code
            <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            id="assetCode"
            name="assetCode"
            value={editData.assetCode}
            onChange={handleEditChange}
            placeholder="Enter your AssetCode"
            className={styles.EditAssetcodeInputField}
            style={{ width: "100%", textTransform: "uppercase" }}
          />
          {errors.assetCode && (
            <p style={{ color: "red", fontSize: "12px" }}>
              {errors.assetCode}
            </p>
          )}
          {errorMessage && (
            <div style={{ color: "red", fontSize: "15px" }}>
              {errorMessage}
            </div>
          )}
        </div>

        <div>
          <label htmlFor="Name" className={styles.AssetCodeCreationAddQueryButton}>
            Name
            <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            id="Name"
            name="Name"
            value={editData.Name}
            onChange={handleEditChange}
            placeholder="Enter your Machinery Name"
            className={styles.EditAssetcodeInputField}
            style={{ width: "100%", textTransform: "uppercase" }}
          />
          {errors.Name && (
            <p style={{ color: "red", fontSize: "12px" }}>{errors.Name}</p>
          )}
        </div>
        <div>
          <label htmlFor="formNo" className={styles.AssetCodeCreationAddQueryButton}>
            Form No
            <span style={{ color: "red" }}>*</span>
          </label>
          <select
            id="formNo"
            name="formNo"
            value={editData.formNo}
            onChange={handleEditChange}
            className={styles.EditAssetcodeInputField}
            style={{ width: "100%" }}
          >
          <option value="">Select Form No</option>
       {checklistData.map((item) => (
    <option key={item._id} value={item._id}> {/* Pass formNo */}
      {item.formNo} {/* Display the form number */}
    </option>
       ))}
          </select>
          {errors.formNo && (
            <p style={{ color: "red", fontSize: "12px" }}>{errors.formNo}</p>
          )}
        </div>
        <div>
          <label htmlFor="activity" className={styles.AssetCodeCreationAddQueryButton}>
          Acticity Name
            <span style={{ color: "red" }}>*</span>
          </label>
          <select
            id="activity"
            name="activity"
            value={editData.activity}
            onChange={handleEditChange}
            className={styles.EditAssetcodeInputField}
            style={{ width: "100%" }}
          >
          <option value="">Select Activity Name</option>
        {checklistData.map((item) => (
    <option key={item.activity?._id} value={item.activity?._id}>
      {item.activity?.activity} 
    </option>
  ))}
          </select>
          {errors.activityName && (
            <p style={{ color: "red", fontSize: "12px" }}>{errors.activityName}</p>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <label
            htmlFor="addQueryButton"
            className={styles.AssetCodeCreationAddQueryButton}
          >
            Add Query
            <span style={{ color: "red" }}>*</span>
          </label>
          <button
            className="btn btn-primary"
            onClick={addEditQuery}
            style={{ marginTop: "10px" }}
            id="addQueryButton"
          >
            Add Query
          </button>
        </div>
        {(editData.queries || editData.documents || []).map((query, index) => (
          <>
            <div
              key={index}
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                id={`query${index}`}
                name={`query${index}`}
                value={query}
                onChange={(e) =>
                  handleEditQueryChange(index, e.target.value)
                }
                placeholder="Enter a query"
                className={styles.EditAssetcodeInputField}
                style={{
                  flex: "1 0 auto",
                  marginRight: "10px",
                  width: "80%",
                }}
              />
              <button
                className="btn btn-outline-danger btn-sm"
                onClick={() => removeEditQuery(index)}
                style={{ flex: "0 0 auto" }}
              >
                Delete
              </button>
            </div>
            <div>
              {errors[`query${index}`] && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  {errors[`query${index}`]}
                </p>
              )}
            </div>
          </>
        ))}

        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid lightgray",
              backgroundColor: "transparent",
              width: "100px",
            }}
            onMouseOver={(e) =>
              (e.target.style.backgroundColor = "lightgray")
            }
            onMouseOut={(e) =>
              (e.target.style.backgroundColor = "transparent")
            }
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            style={{
              marginLeft: "50px",
              padding: "10px",
              borderRadius: "5px",
              width: "100px",
            }}
            className="btn btn-primary"
            onClick={handleEditSubmit}
          >
            Submit
          </button>
        </div>
      </section>
    </DialogContent>
  </Dialog>

    );
}
export default EditAssetcode;