/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "./AboutYourAccount.css";
import { userInfo } from "../../../atoms";
import { useRecoilState } from "recoil";

const AboutYourAccount = () => {
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);

  useEffect(() => {
    console.log("User Info State:", currentUserInfo);
  }, [currentUserInfo]);

  return (
    <div className="container">
      <div className="d-flex flex-column AboutYourAccount">
        <div className="p-2">
          <p className="AboutYourAccount-title">Full Name</p>
          <p>
            {currentUserInfo?.firstName} {currentUserInfo?.lastName}
          </p>
        </div>
        <div className="p-2">
          <p className="AboutYourAccount-title">Contact Number</p>
          <p>{currentUserInfo?.contactNumber}</p>
        </div>
        <div className="p-2">
          <p className="AboutYourAccount-title">Role</p>
          <p>{currentUserInfo?.role}</p>
        </div>
        <div className="p-2">
          <p className="AboutYourAccount-title">Email Id</p>
          <p>{currentUserInfo?.email}</p>
        </div>
        <div className="p-2">
          <p className="AboutYourAccount-title">Employee Id</p>
          <p>{currentUserInfo?.empId}</p>
        </div>
        <div className="p-2">
          <p className="AboutYourAccount-title">Created Date</p>
          <p>{new Date(currentUserInfo?.createdDate).toLocaleDateString()}</p>
        </div>
        <div className="p-2">
          <p className="AboutYourAccount-title">Work Mode</p>
          <p>{currentUserInfo?.workMode}</p>
        </div>
      </div>
    </div>
  );
};

export default AboutYourAccount;
