import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { registerAtom, siteId } from "../../../atoms";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { processFile, updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import {Tabs, Tab} from '../../Tabs/Tabv1'

const PendingCollected = ({filterCondition,
  reloadData,
  setReloadData,
  searchValue,forwardAccess}) => {
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDateString = tomorrow.toISOString().split("T")[0];

  let webApi = new WebApimanager();

  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [error, setError] = useState({});
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    designDrawingConsultant: "",
    acceptedArchitectDate: "",
    // actualSubmissionDate: todayDate(),
    // dueDays: '',
    // remarks:'',
    submittedDate: "",
    issuedSoftCopy: "",
    receivedSoftCopy: "",
    drawingFileName: null,
    receivedCopies: "",
    revisions: [],
    selectedRevision: "",
  });
  const [drawingTableList,setDrawingTableList] = useState([])
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [selectedTab, setSelectedTab] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const [currentTab, setCurrentTab] = useState("received");

  // const handleTabChange = (tabKey) => {
  //   setCurrentTab(tabKey);
  // };
  // const currentTab = 'received';

  // Example validation function
  const validateForm = (formData, currentTab) => {
    const newErrors = {};
    if (currentTab === "upload") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      }
      // if (!formData.remarks) errors.remarks = 'This field is required.';
    } else if (currentTab === "received") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }
      if (!formData.selectedRevision) {
        newErrors.selectedRevision = "revision is required";
      }
      if (!formData.receivedCopies) {
        newErrors.receivedCopies = "receivedCopies are required";
      }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      }
    }
    return newErrors;
  };

  const handleFormSubmit = async () => {
    // Validate the form data
    const errors = validateForm(formData, currentTab);
    setErrors(errors);

    // If there are errors, return early
    if (Object.keys(errors).length > 0) return;

    if (currentTab === "received") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitReceived(formData.registerId);
    }
  };

  const submitReceived = async (drawingItemId) => {
    try {
      const ApprovalFormData = {
        acceptedROHardCopyRevisions: [
          {
            hardCopySubmittedDate: todayDate(),
            revision: formData.selectedRevision,
            receivedCopies: formData.receivedCopies,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/HardCopyRevision/${drawingItemId}`,
        ApprovalFormData
      );
      const id = response.data.data._id;

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const hardCopyFile = new FormData();
        hardCopyFile.append("hardCopyFile", formData.drawingFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/RoHardCopyFile/${drawingItemId}`,
          hardCopyFile
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
      // window.location.reload();
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      console.error("Error submitting form:", error);
      // Handle error, show error message, etc.
    }
  };

  const handleEdit = (drawingItem) => {
    console.log(drawingItem,"drawingItem in Collected Pending")
    if(Object.keys(drawingItem).length){
      setFormData({
        registerId: "",
    drawingNumber:drawingItem.drawingNo ,
    drawingName: drawingItem.drawingTitle,
    category: drawingItem.category?.category,
    designDrawingConsultant: drawingItem.designDrawingConsultant?.role,
    acceptedArchitectDate: "",
    // actualSubmissionDate: todayDate(),
    // dueDays: '',
    // remarks:'',
    submittedDate: "",
    issuedSoftCopy: "",
    receivedSoftCopy: "",
    drawingFileName: null,
    receivedCopies: "",
    revisions: [],
    selectedRevision: "",
      })
    }
    // const selected = drawingList.find((drawing) => drawing._id === drawingId);
    // if (selected) {
    //   const combinedRevisions = [
    //     // ...selected.acceptedArchitectRevisions,
    //     ...(selected.acceptedRORevisions || []),
    //   ];

    //   setSelectedDrawing(selected);
    //   setFormData({
    //     registerId: selected._id,
    //     drawingNumber: selected.drawingNo || "",
    //     drawingName: selected.drawingTitle || "",
    //     category: selected.category.category || "", // Ensure the category field is properly accessed
    //     designDrawingConsultant: `${selected.designDrawingConsultant?.role}`,
    //     acceptedArchitectDate: selected.acceptedArchitectDate
    //       ? new Date(selected.acceptedArchitectDate).toLocaleDateString()
    //       : "",
    //     revisions: combinedRevisions,
    //     submittedDate: todayDate(),
    //     // dueDays: 0,
    //     // issuedSoftCopy: selected.issuedSoftCopy,
    //     // receivedSoftCopy: selected.receivedSoftCopy,
    //     // drawingFileName: selected.drawingFileName
    //   });
      setOpen(true);
    // }
  };
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      const file = files[0];
      setFormData({
        ...formData,
        [name]: files[0], // Assuming you are only uploading one file
      });
      return;
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    if (drawingItem.acceptedROHardCopyRevisions.length > 0) {
      setSelectedTab(drawingItem.acceptedROHardCopyRevisions.length - 1);
    }
    setViewDialogOpen(true);
  };

  // const handleViewOpen = (drawingItem) => {
  //   setSingleDrawingData(drawingItem);
  //   setViewDialogOpen(true);
  // };

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const fetchInData = async () => {
    if (registerInfo && registerInfo.length > 0) {
      setDrawingList(registerInfo);
      setInitialDrawingList(registerInfo);
      console.log("fetched from recoil", registerInfo);
    } else {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setDrawingList(response.registers);
          setInitialDrawingList(response.registers);
        }
      });
    }
  };

  const fetchData = async () => {
    await updateRegister(setRegisterInfo, site).then((response) => {
      if (response.status === SUCESS_STATUS) {
        setDrawingList(response.registers);
        setInitialDrawingList(response.registers);
      }
    });
  };

  useEffect(() => {
    fetchInData();
  }, []);

  useEffect(() => {
    fetchData();
    setReloadData(false);
  }, [reloadData === true]);

  const handleDownload = async (id, revision) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/RoHardCopy/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewFile = async (id, revision) => {
    setApiProcessing({
      loader: true,
      message: "opening...",
    });
    try {
      // Make the request to get the response based on the updated endpoint
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/RoHardCopy/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "view", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const filteredDrawingList = drawingList
    .filter(
      (item) =>
        (filterCondition === "approved"
          ? item.acceptedROHardCopyRevisions.length > 0
          : item.acceptedROHardCopyRevisions.length === 0) &&
        (item.drawingNo?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.drawingTitle
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item.designDrawingConsultant?.role
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          (item.category && typeof item.category === "string"
            ? item.category.toLowerCase().includes(searchValue.toLowerCase())
            : false))
      // (item.acceptedROHardCopyRevisions[0]?.hardCopySubmittedDate && typeof item.acceptedROHardCopyRevisions[0].hardCopySubmittedDate === 'string' ? item.acceptedROHardCopyRevisions[0].hardCopySubmittedDate.toLowerCase().includes(searchValue.toLowerCase()) : false) ||
      // (item.acceptedROHardCopyRevisions[0]?.receivedCopies && typeof item.acceptedROHardCopyRevisions[0].receivedCopies === 'string' ? item.acceptedROHardCopyRevisions[0].receivedCopies.toLowerCase().includes(searchValue.toLowerCase()) : false) ||
      // (item.acceptedROHardCopyRevisions[0]?.revision && typeof item.acceptedROHardCopyRevisions[0].revision === 'string' ? item.acceptedROHardCopyRevisions[0].revision.toLowerCase().includes(searchValue.toLowerCase()) : false)
    )
    .sort((a, b) => {
      const consultantA = a.category?.category
        ? a.category?.category.toLowerCase()
        : "";
      const consultantB = b.category?.category
        ? b.category?.category.toLowerCase()
        : "";
      return consultantA.localeCompare(consultantB); // Sort alphabetically
    });

    useEffect(()=>{
      const fetchDrawingTableData =async (site)=>{
          try {
            const response = await webApi.get(`api/architect/pendingRo/${site}?filterType=collected`)
            if(response.status === 200){
              setDrawingTableList(response.data.data)
            }
            console.log(response,"RO tabs tableData")
          } catch (error) {
            console.log(error.message)
          }
      
      
      }
  
      fetchDrawingTableData(site)
    },[site])
  
    useEffect(()=>{
      console.log(drawingTableList,"drawingTableList")
      
    
    },[drawingTableList])
  return (
    <>
    <DrawingTableWrapper
      tableHead={
        <tr>
          <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
          <th style={{ width: "15%" }}>DRWG.No</th>
          <th style={{ width: "15%" }}>Consultant</th>
          <th style={{ width: "15%" }}>Folder</th>
          <th style={{ width: "15%" }}>Drawing Category</th>
          <th style={{ width: "20%" }}>Drawing Title</th>
          {/* <th style={{ width: "15%" }}>
            Accepted RO Submission Date
          </th>
          <th style={{ width: '13%' }}>Recieved Date</th>
          <th style={{ width: "10%" }}>Recieved Copies</th>
          <th style={{ width: '10%' }}>Revisions</th> */}
          {forwardAccess &&
          <th style={{ width: "8%" }}>Action</th>
      }
        </tr>
      }
      tableBody={
        <tbody>
<Loader
open={apiProcessing.loader}
message={apiProcessing.message}
/>
{drawingTableList.length ? (
  // drawingTableList.filter(
  //       (item) =>
  //         (
  //            item.acceptedArchitectRevisions.length > 0
  //     ))
  drawingTableList.sort((a, b) => {
    const consultantA = a.category?.category
      ? a.category?.category.toLowerCase()
      : "";
    const consultantB = b.category?.category
      ? b.category?.category.toLowerCase()
      : "";
    return consultantA.localeCompare(consultantB);
  }).map((drawingItem, index) => (
    <tr
      key={index}
      style={{
        height:"60px",
        backgroundColor:
          index % 2 === 0
            ? "var(--table-oddrow-color)"
            : "var(--table-evenrow-color)",
      }}
    >
      <td>{index + 1}</td>
      <td>{drawingItem?.drawingNo || ""}</td>
      <td>{drawingItem.designDrawingConsultant?.role || ""}</td>
      <td>
      {drawingItem.folderId?.folderName || ""}
      </td>
      <td>{drawingItem.category?.category || ""}</td>
      <td>{drawingItem?.drawingTitle || ""}</td>
      {/* <td>{drawingItem?.acceptedROSubmissionDate.slice(0,10) || ""}</td> */}
      
        {/* <>
          <td>
            {drawingItem.acceptedROHardCopyRevisions.length > 0
              ? formatDate(
                  drawingItem.acceptedROHardCopyRevisions[0]
                    ?.hardCopySubmittedDate
                ) || "N/A"
              : "N/A"}
          </td>
          <td>
            {drawingItem.acceptedROHardCopyRevisions.length > 0
              ? drawingItem.acceptedROHardCopyRevisions[0]
                  ?.receivedCopies || "N/A"
              : "N/A"}
          </td> 

          <td>
            {drawingItem.acceptedROHardCopyRevisions.length > 0 ? (
              <div>
                {drawingItem.acceptedROHardCopyRevisions &&
                  drawingItem.acceptedROHardCopyRevisions
                    .map(
                      (revisionItem) =>
                        `${revisionItem.revision}` || "NA"
                    )
                    .join(", ")}
              </div>
            ) : (
              <div>-</div>
            )}
          </td>
        </> */}
        <td
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent:"center",
          marginTop: "18px",
        }}
      >
        
          <>{forwardAccess&&
            <Icon.Pen 
              size={20}
              style={{marginRight:"5px",cursor:"pointer"}}
              onClick={() => handleEdit(drawingItem)}
            />
          }
            {/* <Icon.EyeFill
              size={20}
              style={{ marginRight: "5px", cursor: "pointer" }}
              onClick={() => handleViewOpen(drawingItem)}
            />
            <Icon.Download
              size={20}
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={() => {
                const latestRevision =
                  drawingItem.acceptedROHardCopyRevisions.length > 0
                    ? drawingItem.acceptedROHardCopyRevisions[
                        drawingItem.acceptedROHardCopyRevisions
                          .length - 1
                      ].revision
                    : null;
                handleDownload(drawingItem._id, latestRevision);
              }}
            /> */}
          </>
        
        
      </td>


      
    </tr>
  ))
) : (
  <tr>
    <td colSpan="8" style={{ textAlign: "center" }}>
      No data available
    </td>
  </tr>
)}

</tbody>
      }
    />

    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: " #4D4D4D",
          margin: "0px",
        }}
      >
        <p
          style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
        >
          Drawing
        </p>
        <RxCross1
          size={20}
          style={{
            marginLeft: "auto",
            cursor: "pointer",
            marginRight: "15px",
            color: "white",
          }}
          onClick={handleClose}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0",
            border: "1px solid var(--primary-color)",
          }}
        >
          {/* <button
          onClick={() => {
            setCurrentTab('upload')
          }}
          style={{
            backgroundColor: currentTab === 'upload' ? 'var(--primary-color)': 'transparent',
            color: currentTab === 'upload' ? 'white' : 'var(--primary-color)',
            width: '150px',
            height: '35px',
            cursor: 'pointer',
            borderRight: '1px solid var(--primary-color)', 
           
          }}
        >
          Upload
        </button> */}

          <button
            onClick={() => setCurrentTab("received")}
            style={{
              background:
                currentTab === "received"
                  ? "var(--primary-color)"
                  : "transparent",
              color:
                currentTab === "received" ? "white" : "var(--primary-color)",
              width: "150px",
              height: "35px",
              cursor: "pointer",
            }}
          >
            Received
          </button>
        </div>
      </div>
      <DialogContent
        style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
      >
        <>
          <Loader
            open={apiProcessing.loader}
            message={apiProcessing.message}
          />
          <section
            style={{
              marginLeft: "80px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "50px",
                width: "80%",
              }}
            >
              <div>
                <label
                  htmlFor="drawingNumber"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing No
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="drawingNumber"
                  name="drawingNumber"
                  value={formData.drawingNumber}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />

                {errors.drawingNumber && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingNumber}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="drawingName"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Title
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="drawingName"
                  name="drawingName"
                  value={formData.drawingName}
                  onChange={handleChange}
                  placeholder="Enter your drawing title"
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.drawingName && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingName}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="category"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Category
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  placeholder="Enter your Drawing Category"
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.category && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.category}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="designDrawingConsultant"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Design Consultant
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="designDrawingConsultant"
                  name="designDrawingConsultant"
                  value={formData.designDrawingConsultant}
                  onChange={handleChange}
                  placeholder="Enter your  Design Consultant"
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {errors.designDrawingConsultant && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.designDrawingConsultant}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="revisions"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Revisions
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="revisions"
                  name="selectedRevision"
                  value={formData.selectedRevision}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                >
                  <option value="">Select Revision</option>
                  {formData.revisions.map((revision, index) => (
                    <option key={index} value={revision.revision}>
                      {revision.revision}
                    </option>
                  ))}
                </select>
                {errors.selectedRevision && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.selectedRevision}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="receivedCopies"
                  className="fs-10 fw-bold text-start py-2
            "
                >
                  No of Copies Received
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  id="receivedCopies"
                  name="receivedCopies"
                  placeholder="Enter No of Received Copies"
                  value={formData.receivedCopies}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />
              </div>

              <div>
                <label
                  htmlFor="drawingFileName"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Upload Your Drawing File
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="file"
                  accept=".pdf, .jpg, .jpeg, .png"
                  id="drawingFileName"
                  name="drawingFileName"
                  onChange={handleChange}
                  placeholder="Select your drawing file"
                  className="form-control fs-12"
                  style={{ width: "100%", height: '43px'  }}
                />
                {errors.drawingFileName && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.drawingFileName}
                  </p>
                )}
              </div>
            </div>
          </section>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              style={{
                padding: "10px",

                border: "1px solid lightgray",
                backgroundColor: "transparent",
                width: "100px",
              }}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor = "lightgray")
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = "transparent")
              }
              onClose={handleClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              style={{
                marginLeft: "50px",
                padding: "10px",
               
                border: "1px solid var(--primary-color)",
                width: "100px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={handleFormSubmit}
            >
              Submit
            </button>
          </div>
        </>
      </DialogContent>
    </Dialog>

    </>

        
  )
}

export default PendingCollected