

import React, { useState, useEffect, useCallback } from "react";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";
import styles from "./PnmTable.module.css";
import Eye from "../../Images/Eye.svg";
import { RxCross1 } from "react-icons/rx";

function PnmTable(props) {
  const {selectedTab, equipmentType} = props;
  console.log(props);
  const webApi = new WebApimanager();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tableData, setTableData] = useState({})

  const [formData, setFormData] = useState({
    sCode: "",
    subCode: "",
    Name: "",
    brandName: "",
    fuelornot: "",
    type: "",
    ownorHire: "",
    preServiceDate: "",
    nextServiceDate: "",
  });
  const [errors, setErrors] = useState({});
  const [PnmList, setPnmList] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    let Valid = true;
    const newErrors = {};
    if (!formData.sCode.trim()) {
      newErrors.sCode = "SCode  is required";
      Valid = false;
    }
    if (!formData.subCode.trim()) {
      newErrors.subCode = "SubCode  is required";
      Valid = false;
    }
    if (!formData.Name.trim()) {
      newErrors.Name = "Name is required";
      Valid = false;
    }
    if (!formData.brandName.trim()) {
      newErrors.brandName = "Brand Name is required";
      Valid = false;
    }
    if (!formData.fuelornot.trim()) {
      newErrors.fuelornot = "Fuel Or Not Field is required";
      Valid = false;
    }
    if (!formData.type.trim()) {
      newErrors.type = "Type Field is required";
      Valid = false;
    }
    if (!formData.ownorHire.trim()) {
      newErrors.ownorHire = "Own Or Hire Field is required";
      Valid = false;
    }
    if (!formData.preServiceDate.trim()) {
      newErrors.preServiceDate = "PreService Date is required";
      Valid = false;
    }
    if (!formData.nextServiceDate.trim()) {
      newErrors.nextServiceDate = "NextService Date is required";
      Valid = false;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    }

    if (Valid) {
      setApiProcessing({ loader: true, message: "Submitting..." });

      const requestPayload = {
        SCode: formData.sCode,
        SubCode: formData.subCode,
        Name: formData.Name,
        BrandName: formData.brandName,
        FuelOrNot: formData.fuelornot,
        Type: formData.type,
        OwnOrHire: formData.ownorHire,
        PreService: formData.preServiceDate,
        NextService: formData.nextServiceDate,
      };

      webApi
        .post("api/pnm", requestPayload)
        .then((response) => {
          setOpen(false);
          setApiProcessing({ loader: false, message: "" });
        })
        .catch((error) => {
          console.error("There was an error!", error);
          setApiProcessing({ loader: false, message: "" });
        });
    } else {
      const newPnmItem = { ...formData };
      setPnmList([...PnmList, newPnmItem]);
      setFormData({
        sCode: "",
        subCode: "",
        Name: "",
        brandName: "",
        fuelornot: "",
        type: "",
        ownorHire: "",
        preServiceDate: "",
        nextServiceDate: "",
      });
      setErrors({});
      setOpen(false);
      window.location.reload();
    }
  };
  useEffect(() => {
    webApi
      .get(`api/newPnm/newpnm?equipmentType=${equipmentType}&type=${selectedTab}`)
      .then((response) => {
        if (response.data && Array.isArray(response.data.data.newPnms)) {
          setData(response.data.data.newPnms);
          console.log(response,"responseddata");
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const filtered = data.filter((item) =>
      `${item.scode}
  ${item.subCode}
  ${item.Name}
  ${item.brandName}
  ${item.fuelornot}
  ${item.type}
  ${item.ownorHire}
    ${new Date(item.preServiceDate).toLocaleDateString()}
    ${new Date(item.nextServiceDateServiceDate).toLocaleDateString()}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [data, searchTerm]);

  const handleEye=(tabData)=>{
    setTableData(tabData)
    setOpen(true)

  }
  
  useEffect(()=>{
    console.log(filteredData,"filterData")
    console.log(tableData,"tableData")
  },[filteredData,tableData])

  return (
    <>
      <div>
        <div style={{height:'300px',overflowY:'auto'}}>
          {equipmentType!=="Distribution Board" ? <table style={{ width: "100%" }}>
            <thead
              style={{
                backgroundColor: "lightslategrey",
                textAlign: "center",
                height: "45px",
              }}
            >
              <tr>
                <th className={styles.tableTH} style={{ width: "5%" }}>
                  S.No
                </th>
                <th style={{ width: "11%" }}>Asset Code</th>
                <th style={{ width: "11%" }}>Sub Code</th>
                <th style={{ width: "11%" }}>Name</th>
                <th style={{ width: "11%" }}>Make Name</th>
                <th style={{ width: "11%" }}>Fuel/Not</th>
                <th style={{ width: "11%" }}>Next Service</th>
                <th style={{ width: "11%" }}>Next Inspection</th>
                <th style={{ width: "11%" }}>Actions</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {filteredData
                .map((item, index) => {
                  const backgroundColor =
                    index % 2 === 0 ? "#FDF8F4" : "#F3F6F8";

                  return (
                    <tr
                      key={item._id}
                      style={{
                        borderBottom: "1px solid black",
                        padding: "10px",
                        backgroundColor,
                        textAlign:'center'
                      }}
                    >
                      <td style={{ width: "5%" ,textAlign:"center"}}>{index + 1}</td>
                      <td style={{ width: "11%",textAlign:"center" }}>{item.assetCode?.assetCode}</td>
                      <td style={{ width: "11%",textAlign:"center" }}>{item.assetCode?.subCode}</td>
                      <td style={{ width: "11%",textAlign:"center" }}>{item.assetCode?.name}</td>
                      <td style={{ width: "11%",textAlign:"center" }}>{item.makeName}</td>
                      <td style={{ width: "11%",textAlign:"center" }}>{item.fuelRnot}</td>
                      <td style={{ width: "11%" ,textAlign:"center"}}>{item.services}</td>
                      <td style={{ width: "11%" ,textAlign:"center"}}>{item.inspections}</td>
                      <td style={{ width: "11%" ,textAlign:"center"}}><img src={Eye} style={{marginInline:"auto"}} loading="eager" onClick={()=>handleEye(item)}/></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>:<table style={{ width: "100%" }}>
            <thead
              style={{
                backgroundColor: "lightslategrey",
                textAlign: "center",
                height: "45px",
              }}
            >
              <tr>
                <th className={styles.tableTH} style={{ width: "5%" }}>
                  S.No
                </th>
                <th style={{ width: "13%" }}>Asset Code</th>
                <th style={{ width: "12%" }}>Sub Code</th>
                <th style={{ width: "13%" }}>Name</th>
                <th style={{ width: "13%" }}>Make Name</th>
                <th style={{ width: "13%" }}>Location</th>
                <th style={{ width: "13%" }}>Next Inspection</th>
                <th style={{ width: "11%" }}>Actions</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {filteredData
                .map((item, index) => {
                  const backgroundColor =
                    index % 2 === 0 ? "#FDF8F4" : "#F3F6F8";

                  return (
                    <tr
                      key={item._id}
                      style={{
                        borderBottom: "1px solid black",
                        padding: "10px",
                        backgroundColor,
                        textAlign:'center'
                      }}
                    >
                      <td style={{ width: "5%" ,textAlign:"center"}}>{index + 1}</td>
                      <td style={{ width: "13%",textAlign:"center" }}>{item.assetCode?.assetCode}</td>
                      <td style={{ width: "12%",textAlign:"center" }}>{item.assetCode?.subCode}</td>
                      <td style={{ width: "13%",textAlign:"center" }}>{item.assetCode?.name}</td>
                      <td style={{ width: "13%",textAlign:"center" }}>{item.makeName}</td>
                      <td style={{ width: "13%",textAlign:"center" }}>{item?.location || "-"}</td>
                      <td style={{ width: "13%" ,textAlign:"center"}}>{item.inspections}</td>
                      <td style={{ width: "11%" ,textAlign:"center"}}><img src={Eye} style={{marginInline:"auto"}} loading="eager" onClick={()=>handleEye(item)}/></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>}
        </div>
      </div>
      <Dialog
          open={open}
          disableEnforceFocus
          onClose={handleClose}
          maxWidth="md" 
          fullWidth 
          PaperProps={{
            style: {
              height: "1553px", 
              width: "90vw", 
              maxWidth: "none",
            },
          }}
        >
          <div className={styles.PnMTableDialogContainer}>
            <h3 className={styles.PnMTableHeading}> View </h3>
            <RxCross1
              size={20}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                marginRight: "25px",
                color: "white",
              }}
              onClick={handleClose}
            />
          </div>
          <>
            

              
                
              
                  
                  <section className={styles.PnMTableViewForm}>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Type</strong>
                    </p>
                    :<p>{tableData.type}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Fuel/Not</strong>
                    </p>
                    :<p>{tableData.fuelRnot}</p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Name</strong>
                    </p>
                    :<p>{tableData.assetCode?.name}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Make Name</strong>
                    </p>
                    :<p>{tableData.makeName}</p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Model</strong>{" "}
                    </p>
                    :<p>{tableData.modelName}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Inspection</strong>
                    </p>
                    :<p>{tableData.inspections}</p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>
                        select Engine / Motor
                      </strong>
                    </p>
                    :<p>{tableData.engineRmotor}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Subcode</strong>
                    </p>
                    :<p>{tableData.assetCode?.subCode}</p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Insurance</strong>
                    </p>
                    :<p>{tableData.Insurance}</p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>New / Old</strong>
                    </p>
                    :<p>{selectedTab === "own"?tableData.own:""}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>
                        Purchase Date
                      </strong>
                    </p>
                    :
                    <p>
                      {selectedTab === "own" ? tableData.own ==="New"?tableData?.newPurchaseDate?.slice(0,10):tableData?.oldPurchaseDate?.slice(0,10):""}
                    </p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>
                        Purchase Price
                      </strong>
                    </p>
                    :
                    <p>
                      {selectedTab === "own" ? tableData.own ==="New"?tableData?.newPurchasePrice:tableData?.oldPreviousPurchasePrice:""}
                    </p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    

                  </section> 
                    {console.log(tableData.documents,"tableDocuments")}
                    
                     {  
                      (tableData.documents && tableData.documents.length > 0)
                      ? tableData.documents.map((doc, index) => (
                    
                                         
                        <section className={styles.PnMTableViewForm}>
                        <p>
                          <strong style={{ color: "#4D4D4D" }}>RC:</strong>
                        </p>
                        :<p>{doc.documentNo || "N/A"}</p>
                        <p></p>
                        <p>
                          <strong style={{ color: "#4D4D4D" }}>Date of Regn:</strong>
                        </p>
                        :<p>{doc.regDate.slice(0,10) || "N/A"}</p>
                        <p>
                          <strong style={{ color: "#4D4D4D" }}>Regn Validity:</strong>
                        </p>
                        :<p>{doc.expDate.slice(0,10) || "N/A"}</p>
                        <p></p>
                        <p>
                          <strong style={{ color: "#4D4D4D" }}>Chosen File:</strong>
                        </p>
                        :<p>{doc?.file?.name || "N/A"}</p>
                        
                        
  
                      </section>)):<p style={{padding:"16px"}}>No Document Available</p>
                    }
                    
                
                
                
            
          </>
         
        </Dialog>
    </>
  );
}

export default PnmTable;
