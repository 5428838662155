import React from 'react'
import Style from '../../../DailyLogReport/VehicleAndMachinery.module.css'
import FormHeadings from '../../../PNMAssets/FormHeadings'
import { FaX } from "react-icons/fa6";
import style from "../../../Breakdown.module.css"

const LogReportViewBreakDown = ({handleClose,tableFormData}) => {
  return (
    <>
    <div  style={{position:"relative",width:"100%",backgroundColor:"white",padding:"80px"}}>
    { <div style={{position:"absolute",right:"40px",top:"40px",color:"#E47331",cursor:"pointer"}} >
            <FaX onClick={handleClose}/>
          </div>}
          <h3 className={Style.VMHeading}>
            Breakdown Report
          </h3>
          <form  className={Style.VMFormContainer}>
            <div className={Style.VMSiteContainer}>
              <div className={Style.VMSiteHeading}>
                <FormHeadings text={"Site Name"} />
              </div>
              <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>

              <div className={Style.VMSite}>
              <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.siteName.siteName}</p>
              </div>
            </div>
            <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition ID"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>

                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.transitionId}</p>

                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition Date"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.transitionDate.slice(0,10)}</p>
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Equipment Type"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.equipmentType}</p>
                      
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Asset code/Name"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.assetCode.assetCode}</p>
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Type"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.type}</p>
                    
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"SubCode"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.subCode}</p>
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Model"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.model}</p>
                     
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"BreakDown Type"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.breakDownType}</p>
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"BreakDown Date"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.breakDownDate.slice(0,10)}</p>
                    
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Breakdown Time"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.breakDownTime}</p>
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Repaired Date"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.repairDate.slice(0,10)}</p>
                    
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Repaired Time"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.repairTime}</p>
                    
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Repair Status"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.repairStatus}</p>
                    
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Mech. Incharge"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.mechIncharge}</p>
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Issue"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.issue}</p>
                    
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Action Taken"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.actionTaken}</p>
                    
                    </div>
                  </div>
                </div>
              </div>
              
                
            </div>
            <div className={Style.VMFormSeperator}></div>
            {(tableFormData.equipmentType ==="Vehicle" || tableFormData.equipmentType === "Machinery") &&<>
            <h3 className={Style.VMHeading} style={{marginBlock:"30px"}}>
            Vehicle/Machinery Query Details
          </h3>

          <div className={style.BRVMMidFormCont}>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Mechanic"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.mechanic}</p>
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"1st Km/Hr Meter"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.firstKmOrHrMeter}</p>
                      
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"2nd Km/Hr Meter"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.secondKmOrHrMeter}</p>

                    </div>
                </div>
          </div>
            
          <div className={style.BRVMMidFormCont}>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Repeated Problem"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.repeatedProblem}</p>
                    
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Leakeges"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.leakages}</p>
                    
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Fuel Pump System"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.fuelPumpSystem}</p>
                    
                    </div>
                </div>
             
          </div>

          <div className={style.BRVMMidFormCont}>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Fuel Pump Status"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.fuelPumpStatus}</p>
                    
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Tyre Status"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.tyreStatus}</p>
                    
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Gas/Oil Condition"} />
                    </div>
                    
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.gasOrOilCondition}</p>
                    
                    </div>
                </div>
             
          </div>

          <div className={style.BRVMMidFormCont}>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Eng/Motor Status"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.engineOrMotorStatus}</p>
                    
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Battery Status"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.batteryStatus}</p>
                    
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Radiator Status"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.radiatorStatus}</p>
                    
                    </div>
                </div>
             
          </div>

          <div className={style.BRVMMidFormCont}>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Spare Cost"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.spareCost}</p>
                    
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Service Cost"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.serviceCost}</p>
                    
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Total Cost"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={style.BRVMMidFormInputContainer}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.totalCost}</p>
                    
                    </div>
                </div>
             
          </div>
          <div className={style.BRVMSpareUsedCont}>

          <div className={style.BRVMSpareUsedHeading}>
            <FormHeadings text={"Spare Used"} />
          </div>
          <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
          <div className={style.BRVMInputCont}>
          <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.sparesUsed}</p>
          
          </div>

          </div>

          <div className={style.BRVMSpareUsedCont} style={{marginTop:"30px"}}>

            <div className={style.BRVMSpareUsedHeading}>
              <FormHeadings text={"Remarks"} />
            </div>
            <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
            <div className={style.BRVMInputCont}>
            <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.remarks}</p>
            
            </div>

          </div>

          <div  style={{marginTop:"30px",display:"flex"}}>

            <div className={style.BRVMDocumentAttached}>
              <FormHeadings text={"Document Attached"} />
            </div>
            <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
            <div style={{width:"35%"}}>
            {/* <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.vehicleAndMachineryQueriesDetails.mechanic}</p> */}
            
            </div>

          </div>
          </>}

          {tableFormData.equipmentType === "Power Tools" && <>
          
          <h3 className={Style.VMHeading} style={{marginBlock:"30px"}}>
            Power Tools Query Details
          </h3>

          
          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTFormHeading}>
                    <FormHeadings text={"Mechanic"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.powerToolsQueriesDetails.mechanic}</p>

                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTHeadings} >
                    <FormHeadings text={"Machine Handle Condition"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont} >
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.powerToolsQueriesDetails.machineHandleCondition}</p>
                    </div>
                  </div>
                </div>
              </div>
             
              
              
                
          </div>

          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTFormHeading}>
                    <FormHeadings text={"Dead Man Switch"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.powerToolsQueriesDetails.deadManSwitch}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTHeadings}>
                    <FormHeadings text={"Machine Cable Condition"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont} >
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.powerToolsQueriesDetails.machineCableCondition}</p>
                    </div>
                  </div>
                </div>
              </div>
             
              
              
                
          </div>

          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTFormHeading}>
                    <FormHeadings text={"Repeated Problem"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.powerToolsQueriesDetails.repeatedProblem}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTHeadings}>
                    <FormHeadings text={"Wheel Guard Condition"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont} >
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.powerToolsQueriesDetails.wheelGuardCondition}</p>
                    </div>
                  </div>
                </div>
              </div>
             
              
              
                
          </div>

          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTFormHeading}>
                    <FormHeadings text={"Spare Cost"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.powerToolsQueriesDetails.spareCost}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTHeadings}>
                    <FormHeadings text={"Service Cost"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont} >
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.powerToolsQueriesDetails.serviceCost}</p>
                    </div>
                  </div>
                </div>
              </div>
             
              
              
                
          </div>

          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTFormHeading}>
                    <FormHeadings text={"Total Cost"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.powerToolsQueriesDetails.totalCost}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTHeadings}>
                    <FormHeadings text={"Spare Used"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont} >
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.powerToolsQueriesDetails.sparesUsed}</p>
                    </div>
                  </div>
                </div>
              </div>
             
              
              
                
          </div>

          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTFormHeading}>
                    <FormHeadings text={"Remarks"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.powerToolsQueriesDetails.remarks}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTHeadings}>
                    <FormHeadings text={"Document Attached"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont} >
                    {/* <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.powerToolsQueriesDetails.mechanic}</p> */}
                    </div>
                  </div>
                </div>
              </div>
             
              
              
                
          </div>
        
        </>}

        {tableFormData.equipmentType === "Distribution Board" && <>
        <h3 className={Style.VMHeading} style={{marginBlock:"30px"}}>
            Distribution Board-Query Details
        </h3>


        <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTFormHeading}>
                    <FormHeadings text={"Mechanic"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.distributionBoardQueriesDetails.mechanic}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTHeadings}>
                    <FormHeadings text={"MCB & RCB Condition"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont} >
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.distributionBoardQueriesDetails.mcbAndRcbCondition}</p>
                    </div>
                  </div>
                </div>
              </div>
             
              
              
                
          </div>

          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTFormHeading}>
                    <FormHeadings text={"Body Earthing"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.distributionBoardQueriesDetails.bodyEarthing}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTHeadings}>
                    <FormHeadings text={"3 Phase Indicator Light"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont} >
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.distributionBoardQueriesDetails.threePhaseIndicatorLight}</p>
                    </div>
                  </div>
                </div>
              </div>
             
              
              
                
          </div>

          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTFormHeading}>
                    <FormHeadings text={"Repeated Problem"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.distributionBoardQueriesDetails.repeatedProblem}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTHeadings}>
                    <FormHeadings text={"Voltage Indicator Display"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont} >
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.distributionBoardQueriesDetails.voltageIndicatorDisplay}</p>
                    </div>
                  </div>
                </div>
              </div>
             
              
              
                
          </div>

          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTFormHeading}>
                    <FormHeadings text={"Spare Cost"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.distributionBoardQueriesDetails.spareCost}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTHeadings}>
                    <FormHeadings text={"Service Cost"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont} >
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.distributionBoardQueriesDetails.serviceCost}</p>
                    </div>
                  </div>
                </div>
              </div>
             
              
              
                
          </div>

          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTFormHeading}>
                    <FormHeadings text={"Total Cost"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.distributionBoardQueriesDetails.totalCost}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTHeadings}>
                    <FormHeadings text={"Spare Used"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont} >
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.distributionBoardQueriesDetails.sparesUsed}</p>
                    </div>
                  </div>
                </div>
              </div>
             
              
              
                
          </div>

          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTFormHeading}>
                    <FormHeadings text={"Remarks:"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.distributionBoardQueriesDetails.remarks}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={style.BRPTHeadings}>
                    <FormHeadings text={"Document Attached"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont} >
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData.distributionBoardQueriesDetails.mechanic}</p>
                    </div>
                  </div>
                </div>
              </div>
             
              
              
                
          </div>
          </>}


          
          





                  
         

          </form>
    </div>
      
    </>
  )
}

export default LogReportViewBreakDown