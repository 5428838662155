import React from "react";
import { useNavigate } from "react-router-dom";
import {
  OUR_PRODUCTS,
} from "../../Utilities/Constants";
import Hero from "./HeroSection/Hero";
import Navbar from "../Navbar/Header";
import Projects from "../Projects/Projects";
import Contact from "../Contact/Contact";
import FloatingImageContainer from "../../Widgets/FloatingImageContainer";

function Dashboard() {
  let navigate = useNavigate();

  return (
    <>
      <main>
        <Navbar />
        <Hero />
        {/* <Stats /> */}
        <FloatingImageContainer title={"Overview"} titleMarginTop={'110px'} description={`
          We transform Construction Projects with Advanced Management Software by Streamlining work flows, Optimizing costs, Adhering to planning schedules and completion of projects before timelines.

          Our ERP business software solution offers efficiency through real-time project tracking. We Give a bird's-eye view of your entire project, from material deliveries to worker productivity. Our system optimizes workflows, assigns tasks, and predicts bottlenecks using AI analytics. We Track expenses, compare budgets, and identify savings with AI analysis and control cost management.

          We are a perfect solution for large firms, residential developers, commercial real estate, and government projects.
          
          We provide customizable , User-Friendly Interface through One-click reporting Mobile access Customizable views
          
          
          
        `}/>
        <Projects projectsData={OUR_PRODUCTS} readMore={true} from="overview" />
        {/* <Partners />
        <Testimonials /> */}
        <Contact />
      </main>
    </>
  );
}

export default Dashboard;
