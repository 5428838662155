import React from "react";
import * as Icon from "react-bootstrap-icons";

const AmenityContainer = (props) => {
  const {
    handleAddAmenity,
    amenities,
    handleAmenityChange,
    handleRemoveAmenity,
  } = props;
  return (
    <div style={{ marginTop: "20px" }}>
      <div style={{ display: "flex", flexDirection: "row", gap: "800px" }}>
        <h3>Amenities</h3>
        <button
          style={{
            backgroundColor: "#4D4D4D",
            color: "white",
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          onClick={handleAddAmenity}
        >
          Add
        </button>
      </div>
      {amenities.length === 0 && (
        <p style={{ color: "gray", fontSize: "12px" }}>
          Note:No amenities are added. So, please add the amenities
        </p>
      )}
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
        {amenities.map((amenity, index) => (
          <div
            key={index}
            style={{
              width: "48%",
              marginTop: "10px",
              position: "relative",
            }}
          >
            <label htmlFor="Amenities">
              Amenity {index + 1} <a style={{ color: "red" }}>*</a>
            </label>
            <div style={{ position: "relative" }}>
              <input
                type="text"
                value={amenity}
                style={{ marginTop: "15px" }}
                onChange={(e) => handleAmenityChange(index, e)}
                placeholder="Enter Amenity"
              />
              <Icon.DashCircleFill
                size={20}
                style={{
                  position: "absolute",
                  right: "50px",
                  top: "60%",
                  transform: "translateY(-50%)",
                  color: "#4D4D4D",
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveAmenity(index)}
              />
            </div>
            {!amenity.trim() && (
              <small style={{ color: "red" }}>Amenity Field is Required</small>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AmenityContainer;
