import React, { useState } from "react";
import "./users.css";

function CustomDropdown({ options, handleInputChange, errors }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);

    handleInputChange({
      target: {
        name: "reportingEmpIdName",
        value: option.value,
      },
    });
  };

  const getLabelText = (option) => {
    if (option && option.label && option.label.props) {
      return option.label.props.children[1].props.children;
    }

    return option ? option.label : "Enter Employee id";
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <label
        htmlFor="reportingEmpIdName"
        className="fs-10 fw-bold userlableName"
        style={{ marginLeft: "-750px" }}
      >
        Reporting Employee ID / Name
        <span style={{ color: "red" }}>*</span>
      </label>
      <br />
      <div
        className="custom-dropdown-toggle"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        style={{
          color: "black",
          backgroundColor: "#F3F3F3",
          marginTop: "10px",
          width: "100%",
          textAlign: "left",
          height: "70px",
          padding: "20px",
          border: "1px solid rgba(151, 151, 151, 0.25)",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>{getLabelText(selectedOption)}</div>
        <span
          style={{ transform: isDropdownOpen ? "rotate(180deg)" : "rotate(0)" }}
        >
          ▼
        </span>
      </div>

      {isDropdownOpen && (
        <div
          className="custom-dropdown-menu"
          style={{
            position: "absolute",
            width: "100%",
            maxHeight: "200px",
            backgroundColor: "#fff",
            border: "1px solid rgba(151, 151, 151, 0.25)",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            overflowY: "auto",
            zIndex: 9999,
          }}
        >
          {options.map((option) => (
            <div
              key={option.value}
              className="custom-dropdown-item"
              onClick={() => handleSelect(option)}
              style={{
                padding: "10px 20px",
                cursor: "pointer",
                transition: "background-color 0.2s ease",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#f0f0f0")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#fff")
              }
            >
              {option.label}
            </div>
          ))}
        </div>
      )}

      <span style={{ color: "red" }}>{errors.reportingEmpIdName}</span>
    </div>
  );
}

export default CustomDropdown;
