/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import TaskUpdates from "./TaskUpdates/TaskUpdates";
import Works from "./Works/Works";
import AnalysisReport from "./AnalysisReport/AnalysisReport";
import SitesDropdown from "../../Utilities/SitesDropdown";
import { useRecoilState } from "recoil";
import { registerAtom, userInfo } from "../../atoms";
import { WebApimanager } from "../../WebApiManager";

const TaskTracker = () => {
  let webApi = new WebApimanager();

  const [value, setValue] = useState(0);
  const [register, setRegister] = useRecoilState(registerAtom);
  const [sites, setDatasites] = useState([]);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  useEffect(() => {
    console.log("Delayed page current user data:", currentUserInfo);

    // console.log(site, "Naveen");
  });

  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          // if (site === "") {
          //   setSite(response.data.sites[0]._id);
          // }

          console.log("Site data set to state:", response.data.sites);
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const handleSiteChange = async (e) => {
    const newSiteId = e.target.value;
    // setSite(newSiteId);

    // Log the site ID after it is set
    console.log("Task Tracker SiteID:", newSiteId);

    setRegister([]);
    setApiProcessing({
      loader: true,
      message: "Loading...",
    });

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setApiProcessing({
        loader: false,
        message: "",
      });
    } catch (error) {
      console.error("Error occurred while changing the site:", error);
      setApiProcessing({
        loader: false,
        message: "Failed to load site data",
      });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 50px",
          position: "relative",
          backgroundColor: "#F3F3F3",
        }}
      >
        <div
          className="task-tabs-container"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <div className="tabs" style={{ gap: "50px", display: "flex" }}>
            <button
              className={`tab-button ${value === 0 ? "active" : ""}`}
              onClick={() => handleChange(null, 0)}
              style={{
                borderBottom: value === 0 ? "3px solid #E47331" : "none",
                color: "#131313",
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
            >
              Task Updates
            </button>
            <button
              className={`tab-button ${value === 1 ? "active" : ""}`}
              onClick={() => handleChange(null, 1)}
              style={{
                borderBottom: value === 1 ? "3px solid #E47331" : "none",
                color: "#131313",
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
            >
              Works
            </button>
            <button
              className={`tab-button ${value === 2 ? "active" : ""}`}
              onClick={() => handleChange(null, 2)}
              style={{
                borderBottom: value === 2 ? "3px solid #E47331" : "none",
                color: "#131313",
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
            >
              Analysis Report
            </button>
          </div>
        </div>

        <div
          className="site-dropdown-container"
          style={{ marginLeft: "auto", marginRight: "50px", marginTop: "15px" }}
        >
          <SitesDropdown
            // siteId={site}
            handleSiteChange={handleSiteChange}
            sites={sites}
            defaultValue={"Select Site"}
          />
        </div>
      </div>

      <TabPanel value={value} index={0}>
        <TaskUpdates />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Works />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AnalysisReport />
      </TabPanel>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="tabpanel"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div className="tabpanel-content">{children}</div>}
    </div>
  );
}

export default TaskTracker;
