import React, { useState, useEffect } from "react";
import "./Work.css";
import Teams from "./Team/Teams";
import { WebApimanager } from "../../../WebApiManager";

const Works = ({ siteId }) => {
  const webApi = new WebApimanager();
  const [selectedTab, setSelectedTab] = useState(null);
  const [towers, setTowers] = useState([]);

  useEffect(() => {
    console.log(siteId, "Work site ID");
  });

  useEffect(() => {
    webApi.get(`api/sites/getAllSitesInfo`).then((response) => {
      const siteData = response.data.sites.find((site) => site._id === siteId);
      const towers = siteData?.apartmentsDetails?.towers || [];
      setTowers(towers);

      if (towers.length > 0) {
        setSelectedTab(towers[0]._id);
      }
    });
  }, [siteId]);

  const handleTabClick = (towerId) => {
    setSelectedTab(towerId);
    console.log(towerId, "work tab tower ID");
  };

  return (
    <>
      <div className="WorkContainer">
        <ul className="WorkTabs">
          {towers.map((tower, index) => (
            <li
              key={tower._id}
              className={`WorkTabSelected ${
                selectedTab === tower._id ? "active" : ""
              }`}
              onClick={() => handleTabClick(tower._id)}
            >
              {`Tower ${String.fromCharCode(65 + index)}`}{" "}
            </li>
          ))}
        </ul>

        <div className="tabpanelArchitecture">
          <div className="tabpanel-content">
            {selectedTab && <Teams towerId={selectedTab} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Works;
