/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Cards from './HomePage UI/Cards';
import { WebApimanager } from "../../WebApiManager";
import { useRecoilState } from "recoil";
import { siteId } from '../../atoms';

const HeroSection = () => {
  let webApi = new WebApimanager();
  const [cardData, setCardData] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [site, setSite] = useRecoilState(siteId)
  const [formData, setFormData] = useState({
    type: '',
    category: ''
  });

  // useEffect(() => {
  //   const fetchSiteData = async () => {
  //     let response = await webApi.get(`api/sites/AllSites`)
  //     setNameData(response.data.sites)
  //   }

  //   fetchSiteData()
  // }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};
    Object.keys(formData).forEach(key => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
    });
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    }  else {
      try {
        console.log(loading)
        setLoading(true)
        setErrors({})
        console.log(`api/Task/${formData.type}/?siteid=${site}&category=${formData.category}`)
        const response = await webApi.get(`api/Task/${formData.type}/?siteid=${site}&category=${formData.category}`);
        setCardData(response.data.data);
        console.log(loading)
        setLoading(true)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };


  return (
    <>
      <div className='p-3 bg-white flex gap-2'>
        {/* <div>
          <select className="form-select" name="siteid" value={formData.siteid} onChange={handleChange}>
            <option value="" >Select Site Name</option>
            {nameData && Array.isArray(nameData) && nameData.map(item => (
              <option key={item._id} value={item._id}>{item.siteName}</option>
            ))}
          </select>
          {errors.siteid && <p className="text-danger">{errors.siteid}</p>}
        </div>
   */}
        <div>
          <select className="form-select" name="type" value={formData.type} onChange={handleChange}>
            <option value="" >Select type</option>
            <option value="getTrackerDetails" >Units</option>
            <option value="getTrackerDetailsForFloor" >Floors</option>
          </select>
          {errors.type && <p className="text-danger">{errors.type}</p>}
        </div>
  
        <div>
          <select className="form-select" name="category" value={formData.category} onChange={handleChange}>
            <option value="" >Select category</option>
            <option value="Structural" >Structural</option>
            <option value="Finishing" >Finishing</option>
            <option value="Others" >Others</option>
          </select>
          {errors.category && <p className="text-danger">{errors.category}</p>}
        </div>
  
        <button className='btn btn-primary' onClick={handleSubmit}>Submit</button>
      </div>
      {/*<Tabs/>*/}
      <Cards cardData = {cardData} loading = {loading} />
    </>
  )
}

export default HeroSection;