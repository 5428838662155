
// import React, { useState, useEffect } from "react";
// import * as Icon from "react-bootstrap-icons";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { WebApimanager } from "../../../WebApiManager";
// import { useRecoilState } from "recoil";
// import { Dialog, DialogContent } from "@mui/material";
// import { RxCross1 } from "react-icons/rx";
// import { Button } from "react-bootstrap";
// import { Loader } from "../../../Widgets/notificationFeedbacks";
// import { Tabs, Tab, Typography, Box } from "@mui/material";
// import Filter from "../../../Images/Filter.svg";
// import Pen from "../../../Images/Pen.svg";
// import Eye from "../../../Images/Eye.svg";
// import DescriptionFile from "../../../Images/DescriptionFile.svg";
// import Share from "../../../Images/Share.svg";
// import Trash from "../../../Images/Trash.svg";
// import SelectSite from "../../../Utilities/SelectSite";
// import AddActivity from "./AddActivity";
// import Create from "./Create";
// import ViewDialog from "./ViewDialog";
// import AddDescriptions from "./AddDescriptions";
// import styles from "./Design.module.css";
// import CheckListTable from "../ChecklistTable";
// import TableWrapper from "../../../Utilities/TableWrapper";


// const AssignWork = () => {
  
//   return (
//     <>
//     <TableWrapper
//         children={
//           <>
//             <div
//               style={{
//                 display: "flex",
//                 marginLeft: "5px",
//                 marginRight: "5px",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//               }}
//             >
//               <div style={{ display: "flex" }}>
//                 <Tabs
//                   value={currentView}
//                   onChange={handleTabViewChange}
//                   sx={{
//                     "& .MuiTabs-indicator": {
//                       backgroundColor: "var(--primary-color)",
//                     },
//                     "& .MuiTab-root": {
//                       textTransform: "none",
//                       color: "#131313",
//                       borderBottom: "none",
//                       fontWeight: "bold",
//                     },
//                     "& .Mui-selected": {
//                       color: "#131313 !important",
//                     },
//                     "& .MuiTab-root:not(.Mui-selected)": {
//                       color: "#131313",
//                     },
//                   }}
//                 >
//                   <Tab label="Assign Work" value="assignwork" />
//                   <Tab label="Not Assign Work" value="notassignwork" />
//                 </Tabs>
//               </div>
//               <div>
//                   <>
//                     <div style={{ display: "flex", flexDirection: "row" }}>
//                       <div
//                         style={{
//                           position: "relative",
//                           width: "250px",
//                           paddingLeft: "5px",
//                           height: "50px",
//                         }}
//                       >
//                         <input
//                           placeholder="Search Forms"
//                           value={searchInput}
//                           onChange={(e) => setSearchInput(e.target.value)}
//                           style={{
//                             paddingLeft: "20px",
//                             border: "1px solid var(--primary-color)",
//                             width: "250px",
//                             height: "40px",
//                           }}
//                         />
//                         <Icon.Search
//                           style={{
//                             position: "absolute",
//                             right: "15px",
//                             top: "40%",
//                             transform: "translateY(-50%)",
//                             color: "#ACACAC",
//                           }}
//                         />
//                       </div>
//                       {currentView === "notassignwork" && (
//                       <Button
//                         style={{
//                           height: "40px",
//                           marginLeft: "5px",
//                           display: "flex",
//                           alignItems: "center",
//                           backgroundColor: "var(--primary-color)",
//                           border: "1px solid var(--primary-color)",
//                           borderRadius: "0px",
//                         }}
//                         onClick={handleClickOpen}
//                       >
//                         <Icon.PlusCircle
//                           size={20}
//                           style={{ marginRight: "5px" }}
//                         />{" "}
//                         Add New
//                       </Button>
//                       )}
//                     </div>
//                     <div>
//                     </div>
                 
//                 </>
              

// </div>
//             </div>

//             {currentView === "assignwork" && (
//                   <CheckListTable
//                   CheckListTableHead={
//                   <tr className="HeaderTableRow">
//                     <th className="CheckListTableTableHead" style={{width:"4%"}}>S.No</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}>Activity</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}>Department</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}>Checklist Type</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}>Approval Status</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}>Form No</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}>Revisions</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}> Action </th>
//                   </tr>
//                 }
//                 CheckListTableBody={
                 
//                       <tr className="ValueTableRow" 
//                       style={{
//                         // backgroundColor: index % 2 === 0 ? 'var(--table-oddrow-color)' : 'var(--table-evenrow-color)',
//                         }}>
//                         <td className="CheckListTableTableData" style={{ width: "4%" }}></td>
//                         <td className="CheckListTableTableData" style={{ width: "14%" }}></td>
//                         <td className="CheckListTableTableData" style={{ width: "14%" }}>
                    
//                         </td>
//                         <td className="CheckListTableTableData" style={{ width: "14%" }}></td>
//                         <td className="CheckListTableTableData" style={{ width: "14%" }}>
                        
//                         </td>
//                         <td className="CheckListTableTableData" style={{ width: "14%" }}></td>
//                         <td className="CheckListTableTableData" style={{ width: "14%" }}>
//                         </td>
//                         <td className="CheckListTableTableData"
//                           style={{
//                             display: "flex",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             width: "14%",
//                           }}>
//                           <img
//                             src={Pen}
//                             loading="eager"
//                             style={{ marginRight: "10px", cursor: "pointer" }}
//                             // onClick={() => handleEdit(item)}
//                           />
//                           <img
//                             src={Eye}
//                             loading="eager"
//                             style={{ marginRight: "10px", cursor: "pointer" }}
//                             // onClick={() => handleView(item)}
//                           />
                         
//                         </td>
//                       </tr>
               
//                 }
              
//               />
//             )}
//            {currentView === "notassignwork" && (
//                   <CheckListTable
//                   CheckListTableHead={
//                   <tr className="HeaderTableRow">
//                     <th className="CheckListTableTableHead" style={{width:"4%"}}>S.No</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}>Activity</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}>Department</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}>Checklist Type</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}>Approval Status</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}>Form No</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}>Revisions</th>
//                     <th className="CheckListTableTableHead" style={{width:"14%"}}> Action </th>
//                   </tr>
//                 }
//                 CheckListTableBody={
                 
//                       <tr className="ValueTableRow" 
//                       style={{
//                         // backgroundColor: index % 2 === 0 ? 'var(--table-oddrow-color)' : 'var(--table-evenrow-color)',
//                         }}>
//                         <td className="CheckListTableTableData" style={{ width: "4%" }}></td>
//                         <td className="CheckListTableTableData" style={{ width: "14%" }}></td>
//                         <td className="CheckListTableTableData" style={{ width: "14%" }}>
                    
//                         </td>
//                         <td className="CheckListTableTableData" style={{ width: "14%" }}></td>
//                         <td className="CheckListTableTableData" style={{ width: "14%" }}>
                        
//                         </td>
//                         <td className="CheckListTableTableData" style={{ width: "14%" }}></td>
//                         <td className="CheckListTableTableData" style={{ width: "14%" }}>
//                         </td>
//                         <td className="CheckListTableTableData"
//                           style={{
//                             display: "flex",
//                             justifyContent: "center",
//                             alignItems: "center",
//                             width: "14%",
//                           }}>
//                           <img
//                             src={Pen}
//                             loading="eager"
//                             style={{ marginRight: "10px", cursor: "pointer" }}
//                             // onClick={() => handleEdit(item)}
//                           />
//                           <img
//                             src={Eye}
//                             loading="eager"
//                             style={{ marginRight: "10px", cursor: "pointer" }}
//                             // onClick={() => handleView(item)}
//                           />
                         
//                         </td>
//                       </tr>
               
//                 }
              
//               />
//             )}
//           </>
//         }
//       />

//     </>
//   );
// };

// export default AssignWork;
import React, { useState, useEffect } from "react";
import TableWrapper from "../../../Utilities/TableWrapper";
import DrawingTableWrapper from "../../Drawing/Components/DrawingTableWrapper";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import {Tabs, Tab} from "../../Tabs/Tabv1"
import { WebApimanager } from "../../../WebApiManager";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import "./AssignWork.css"
import EditAssignTask from "./EditAssignTask";
import ViewAssignTask from "./ViewAssignTask";
import { FaCirclePlus } from "react-icons/fa6";
import { FaCircleMinus } from "react-icons/fa6";
import ViewAssignedWork from "./ViewAssignedWork";


const AssignWork = () => {
    let webApi=new  WebApimanager();
    const [apiProcessing, setApiProcessing] = useState({
        loader: false,
        message: "Loading...",
      });
      const [currentView, setCurrentView] = useState("Assigned");
      const [worksData,setWorksData]= useState([])
      const [showNotAssignedWork ,setShowNotAssignedWork] = useState(false)
      const [showEditForm,setShowEditForm] = useState(false)
      const [showViewForm,setShowViewForm] = useState(false)
      const [showViewAssignedWork,setShowViewAssignedWork] = useState(false)
      const [pnmUsers,setPnmUsers] = useState([])
      const [formData, setFormData] = useState({
        assignTo: "",
        tasks: [""]
      });
      const [errors, setErrors] = useState({});
      const [tableRowData,setTableRowData] = useState([])

      const handleTabViewChange = (event,newValue) => {
        setCurrentView(newValue);

        
      };
      const handleClickOpen = async() => {
        setShowNotAssignedWork(true)
        setShowEditForm(false)
    setShowViewForm(false)
    setShowViewAssignedWork(false)
    try {
      const response= await webApi.get(`api/checklistDesign/users?department=PNM`)
      console.log(response,"Add New Click")
      if(response.status === 200){
        setPnmUsers(response.data.data.users)
      }
    } catch (error) {
      console.log(error.message)
    }

      };

      useEffect(()=>{
        const fetchWorks = async ()=>{
          try {
            const response = await webApi.get(`api/newPnm/pnmsByStatus?status=${currentView}`)
            console.log(response.data.data,"resData")
            setWorksData(response.data.data)
          } catch (error) {
            console.log(error.message)
          }
        }
        fetchWorks()
      },[currentView])

      useEffect(()=>{
          console.log(showNotAssignedWork,showEditForm,showViewForm,showViewAssignedWork,"showNotAssignedWork","showEditForm","showViewForm","showViewAssignedWork")
      },[showNotAssignedWork,showEditForm,showViewForm,showViewAssignedWork])
  
      const addHandleTask = () => {
        setFormData((prevState) => ({
          ...prevState,
          tasks: [...prevState.tasks, ""], // Add new task as empty
        }));
      };
    
      const removeHandleTask = (index) => {
        const updatedTasks = [...formData.tasks];
    updatedTasks.splice(index, 1); // Remove task from array
    setFormData((prevState) => ({
      ...prevState,
      tasks: updatedTasks,
    }));
      };

      const validateForm = () => {
        let formErrors = {};
        if (!formData.assignTo) {
          formErrors.assignTo = "Please assign a user.";
        }
        if (!formData.tasks.length || formData.tasks.some((task) => !task)) {
          formErrors.tasks = "Please add at least one task.";
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
          const payload = {
            assignTOUser:formData.assignTo,
            assignNewPnmTasks:formData.tasks
          }
          try {
            const response= await webApi.post(`api/newPnmWorkFlow`,payload)
            console.log(response,"response of assignTaskApi")
          } catch (error) {
            console.log(error.message,"assign work Api fail")
          }
        } else {
          console.log("Form has errors. Not submitting.");
        }
      };

      const handleTaskChange = (index, value) => {
        const updatedTasks = [...formData.tasks];
        updatedTasks[index] = value;
        setFormData((prevState) => ({
          ...prevState,
          tasks: updatedTasks,
        }));
      };

  const handleEditOpen =(workData)=>{
    console.log("handleEditOpen")
    setShowEditForm(true)
    setShowViewForm(false)
    setShowNotAssignedWork(false)
    setShowViewAssignedWork(false)
    setTableRowData(workData)
  }

  const handleViewOpen = (workData)=>{
    setShowEditForm(false)
    setShowViewForm(true)
    setShowNotAssignedWork(false)
    setShowViewAssignedWork(false)
  }

  const handleClose = () =>{
    setShowNotAssignedWork(false)
    setShowEditForm(false)
    setShowViewForm(false)
    setShowViewAssignedWork(false)
    setWorksData([])
  }

  const handleAssignedWork =(workData) =>{
    setShowEditForm(false)
    setShowViewForm(false)
    setShowNotAssignedWork(false)
    setShowViewAssignedWork(true)
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

     
  return (
    <>
       <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "10px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
              <Tabs
              value={currentView}
              onChange={handleTabViewChange}
            
            >
              <Tab label="Assign Work" value="Assigned" />
              <Tab label="Not Assign Work" value="Not Assigned" />
            </Tabs>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    position: "relative",
                    width: "250px",
                    paddingLeft: "20px",
                    height: "50px",
                    marginRight:"10px"

                  }}
                >
                  <input
                    placeholder="Search Works"
                    style={{
                      paddingLeft: "20px",
                      border: "1px solid var(--primary-color)",
                      width: "250px",
                      height: "40px",
                    }}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "-3px",
                      top: "40%",
                      transform: "translateY(-50%)",
                      color: "#ACACAC",
                    }}
                  />
                </div>
                
                {currentView === "Not Assigned" && <Button
                  style={{
                    height: "40px",
                    marginLeft: "40px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "var(--primary-color)",
                    border: "1px solid var(--primary-color)",
                    borderRadius: "0px",
                  }}
                  onClick={handleClickOpen}
                >
                  <Icon.PlusCircle size={20} style={{ marginRight: "5px" }} />{" "}
                  Add New
                </Button>}
                
              </div>
            </div>
            <div>
            
            
            

              {currentView === "Assigned" ? (
                <DrawingTableWrapper
                  tableHead={
                    <tr>
                      <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                      <th style={{ width: "10%" }}>Type</th>
                      <th style={{ width: "14%" }}>Asset Code</th>
                      <th style={{ width: "13%" }}>Name</th>
                      <th style={{ width: "10%" }}>Assigned To</th>
                      <th style={{ width: "7%" }}>Action</th>
                    </tr>
                  }
                  tableBody={
                    worksData.length ? worksData.map((workData,index)=>(
                    <tbody>
                        <tr>
                        <td style={{ width: "5%", paddingLeft: "10px" }}>{index+1}</td>
                          <td style={{ width: "10%" }}>{workData.equipmentType}</td>
                          <td style={{ width: "14%" }}>{workData.assetCode?.assetCode || "-"}</td>
                          <td style={{ width: "13%" }}>{workData.makeName}</td>
                          <td style={{ width: "10%" }}>{workData.assignToUser?.length?workData.assignToUser?.length:"-"}</td>
                          <td style={{ width: "7%" }}> <Icon.EyeFill
                            size={20}
                            style={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={()=>handleAssignedWork(workData)}
                          /></td>
                        </tr>
                        </tbody>))
                  :""}
                />
              ) : (
                <> <DrawingTableWrapper
                tableHead={
                  <tr>
                    <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{ width: "10%" }}>Type</th>
                    <th style={{ width: "14%" }}>Asset Code</th>
                    <th style={{ width: "13%" }}>Name</th>
                    <th style={{ width: "7%" }}>Action</th>
                  </tr>
                }
                tableBody={
                   worksData.length ? worksData.map((workData,index)=>(
                   <tbody key={workData._id}>
                      <tr>
                          <td>{index+1}</td>
                          <td>{workData.equipmentType}</td>
                          <td>{workData.assetCode?.assetCode||"-"}</td>
                          <td>{workData.makeName}</td>
                          <td  style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            marginTop: "10px",
                            textAlign:"center"
                          }}><Icon.Pen
                            size={20}
                            style={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={()=>handleEditOpen(workData)}
                          />
                          <Icon.EyeFill
                            size={20}
                            style={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={()=>handleViewOpen(workData)}
                          />
                          </td>
                      </tr>
                      </tbody>))
                :""}
              />
                </>
              )}
            </div>
          </>
        }
        />
        <Dialog
        open={showNotAssignedWork}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 500px", textAlign: "center", color: "white" }}
          >
            Assign Task
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "300px" }}
        >
          <form className="AWFormContainer" onSubmit={handleSubmit}>
              <div className="AWMainInputContainer">
                <div className="AWInputContainer" style={{position:"relative"}}>
                  <label className="AWLabel">Assign To</label>
                  <select className="AWInputField" onChange={handleChange}
                name="assignTo"
                value={formData.assignTo} >
                    
                    <option value="">Select</option>
                    {
                      pnmUsers.length ? pnmUsers.map((user)=>(
                        <option key={user._id} value={user._id}>{user.firstName+" "+user.lastName}</option>
                      ))
                    :""}
                  </select> 
                  {errors.assignTo && <p style={{position:"absolute",top:"100%",color:"red"}}>{errors.assignTo}</p>}
                </div>
                
                
                  {formData.tasks.map((task,taskIndex)=>(<div  className="AWInputContainer" style={{position:"relative"}}>
                  <label className="AWLabel">Add Task</label>
                  <select className="AWInputField" value={task}
                  onChange={(e) => handleTaskChange(taskIndex, e.target.value)} >
                    <option value="">Select</option>
                    {
                      worksData.length && worksData.map((work,workIndex)=>(
                        <option value={work._id}>{work.equipmentType+" "+work.assetCode?.assetCode+" "+work.makeName}</option>
                      ))
                    }

                  </select>
                  {taskIndex==0?<FaCirclePlus  style={{fontSize:"24px",position:"absolute",top:"60%",left:"100%",marginLeft:"10px",cursor:"pointer"}} onClick={()=>addHandleTask()}/>:<FaCircleMinus  style={{fontSize:"24px",position:"absolute",top:"60%",left:"100%",marginLeft:"10px",cursor:"pointer"}} onClick={()=>removeHandleTask(taskIndex)}/>}
                  {errors.tasks && <p style={{position:"absolute",top:"100%",color:"red"}}>{errors.tasks}</p>}
                </div>))}
             
                
                
              </div>
              <div className="AWbtnContainer">
                <button>SUBMIT</button>
              </div>
          </form>
        </DialogContent>
      </Dialog>
      {
        showEditForm && <EditAssignTask showEditForm={showEditForm} handleClose={handleClose} pnmUsers={pnmUsers} tableRowData={tableRowData}/>
      }
      {
        showViewForm && <ViewAssignTask showViewForm={showViewForm} handleClose={handleClose}/>
      }
      {
        showViewAssignedWork && <ViewAssignedWork showViewAssignedWork={showViewAssignedWork} handleClose={handleClose} worksData={worksData}/>
      }
    </>
  );
};

export default AssignWork;
