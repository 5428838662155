import React from "react";

const VillaPreciseDetails = (props) => {
  const { formData, handleChange, errors, enableNoofVillas } = props;
  return (
    <>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "80px",
          }}
        >
          <label>
            Cellar Is there for Villas?
            <a style={{ color: "red" }}>*</a>
          </label>
          <select
            name="Vceller"
            value={formData.Vceller}
            onChange={handleChange}
            disabled={!!formData.VnoOfVillas}
            style={{marginTop:'15px'}}
          >
            <option value="">Select Yes/No</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          {errors.Vceller && <p style={{ color: "red" }}>{errors.Vceller}</p>}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "80px",
          }}
        >
          <label>
            Floors are common for every Villas?
            <a style={{ color: "red" }}>*</a>
          </label>
          <select
            name="VcommonFloor"
            value={formData.VcommonFloor}
            onChange={handleChange}
            disabled={!!formData.VnoOfVillas}
            style={{marginTop:'15px'}}
          >
            <option value="">Select Yes/No</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>

          {errors.VcommonFloor && (
            <p style={{ color: "red" }}>{errors.VcommonFloor}</p>
          )}
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "20px" }}>
        {formData.Vtypecommon === "Yes" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "80px",
            }}
          >
            <label>
              Select Villa Type
              <a style={{ color: "red" }}>*</a>
            </label>
            <select
              name="VtypeHK"
              value={formData.VtypeHK}
              onChange={(e)=>handleChange(e)}
              disabled={!!formData.VnoOfVillas}
              style={{marginTop:'15px'}}
            >
              <option value="">Select Type</option>
              <option value="1BHK">1BHK</option>
              <option value="2BHK">2BHK</option>
              <option value="2.5BHK">2.5BHK</option>
              <option value="3BHK">3BHK</option>
              <option value="4BHK">4BHK</option>
              <option value="5BHK">5BHK</option>
            </select>
            {errors.VtypeHK && <p style={{ color: "red" }}>{errors.VtypeHK}</p>}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {formData.VcommonFloor === "Yes" && (
            <>
              <label>
                Number of Floors for villas
                <a style={{ color: "red" }}>*</a>
              </label>
              <input
                type="text"
                
                name="VnoOfFloorVillas"
                placeholder="Enter Number of Floors for villas"
                value={formData.VnoOfFloorVillas}
                onChange={(e) => {
                  const value = e.target.value;
              
                  
                  if (/^\d{0,3}$/.test(value) && (value === '' || (Number(value) >= 1 && Number(value) <= 100))) {
                    handleChange(e); 
                  }
                }}
                disabled={!!formData.VnoOfVillas}
                style={{marginTop:'15px'}}
              />
              {errors.VnoOfFloorVillas && (
                <p style={{ color: "red" }}>{errors.VnoOfFloorVillas}</p>
              )}
            </>
          )}
        </div>
      </div>
      {formData.Vceller === "Yes" && (
        <div style={{ display: "flex", marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "80px",
            }}
          >
            <label>
              Cellar are common for all?
              <a style={{ color: "red" }}>*</a>
            </label>
            <select
              name="VcommonCellar"
              value={formData.VcommonCellar}
              onChange={handleChange}
              disabled={!!formData.VnoOfVillas}
              style={{marginTop:'15px'}}
            >
              <option value="">Select Yes/No</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>

            {errors.VcommonCellar && (
              <p style={{ color: "red" }}>{errors.VcommonCellar}</p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {formData.VcommonCellar === "Yes" && (
              <>
                <label>
                  Number of Cellars for all
                  <a style={{ color: "red" }}>*</a>
                </label>
                <input
                  type="text"
                  
                  name="VnoOfCellar"
                  placeholder="Enter Number of Villas"
                  value={formData.VnoOfCellar}
                  onChange={(e) => {
                    const value = e.target.value;
                
                    
                    if (/^\d{0,3}$/.test(value) && (value === '' || (Number(value) >= 1 && Number(value) <= 100))) {
                      handleChange(e); 
                    }
                  }}
                  disabled={!!formData.VnoOfVillas}
                  style={{marginTop:'15px'}}
                />
                {errors.VnoOfCellar && (
                  <p style={{ color: "red" }}>{errors.VnoOfCellar}</p>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {enableNoofVillas && (
        <div
          style={{
            display: "flex",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "80px",
            }}
          >
            <label>
              Please Enter Number of Villas
              <a style={{ color: "red" }}>*</a>
            </label>
            <input
              type="text"
              
              name="VnoOfVillas"
              placeholder="Enter Number of Villas"
              value={formData.VnoOfVillas}
              onChange={(e) => {
                const value = e.target.value;
            
                
                if (/^\d{0,3}$/.test(value) && (value === '' || (Number(value) >= 1 && Number(value) <= 100))) {
                  handleChange(e); 
                }
              }}
              style={{marginTop:'15px'}}
            />
            {errors.VnoOfVillas && (
              <p style={{ color: "red" }}>{errors.VnoOfVillas}</p>
            )}
            {formData.VnoOfVillas && (
              <p style={{ color: "gray", fontSize: "12px" }}>
                Note: Number of Villas has been set. <br />
                Other fields are cannot chnage the value.
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label>
              Number of Club Houses<a style={{ color: "red" }}>*</a>
            </label>
            <input
              type="text"
              name="VnoOfClubHouses"
              
              placeholder="Enter Number of Club Houses"
              value={formData.VnoOfClubHouses}
              onChange={(e) => {
                const value = e.target.value;
            
                
                if (/^\d{0,3}$/.test(value) && (value === '' || (Number(value) >= 1 && Number(value) <= 100))) {
                  handleChange(e); 
                }
              }}
              style={{marginTop:'15px'}}
            />
            {errors.VnoOfClubHouses && (
              <p style={{ color: "red" }}>{errors.VnoOfClubHouses}</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default VillaPreciseDetails;
