import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dialog, DialogContent } from "@mui/material";
import * as Icon from "react-bootstrap-icons";


const ViewAssetcode = ({open,close,data }) => {
    const [singleAssetCodeData, setSingleAssetCodeData] = useState(null);

    // Use useEffect to set the state when data changes
    useEffect(() => {
      if (data) {
        setSingleAssetCodeData(data);
      }
    }, [data]);
console.log(data,"viewdata",singleAssetCodeData,"assign viewdata")
return(
    <Dialog
    open={open}
    onClose={close}
    maxWidth="sm"
    fullWidth
    PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0px",
      }}
    >
      <p
        style={{ marginLeft: "25px", fontSize: "25px", marginTop: "20px" }}
      >
        Details Of AssetCode
      </p>
      <Icon.XCircleFill
        size={20}
        style={{
          marginLeft: "auto",
          cursor: "pointer",
          marginRight: "25px",
        }}
        onClick={close}
      />
    </div>
    <DialogContent
      style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          rowGap: "20px",
          columnGap: "20px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div>
          <strong>Type</strong>
        </div>
        <div>:</div>
        <div>{singleAssetCodeData?.type}</div>
        <div>
          <strong>Asset Code</strong>
        </div>
        <div>:</div>
        <div>{singleAssetCodeData?.assetCode}</div>
        <div>
          <strong>Name</strong>
        </div>
        <div>:</div>
        <div>{singleAssetCodeData?.name}</div>
        <div>
        {/* <div>
          <strong>FormNo</strong>
        </div>
        <div>:</div>
        <div>{singleAssetCodeData?.formNo}</div>
        <div>
          <strong>Activity Name</strong>
        </div>
        <div>:</div>
        <div>{singleAssetCodeData?.activity?.activity}</div> */}
          <strong>Document Names</strong>
        </div>
        <div>:</div>
        <div>{singleAssetCodeData?.documents.join(',')}</div>
     
      </div>
    </DialogContent>
  </Dialog>

    );
}
export default ViewAssetcode;