import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import ConstructionNeedsFiles from "./ConstructionNeedsFiles";
import CardDetailsModal from "./CardDetailsModal";
import { IoArrowBack } from "react-icons/io5";
import { BACKEND_URL } from "../../../Utilities/Constants";
import { IoAdd } from "react-icons/io5";

const ConstructionNeedsDetails = ({ item, onClose, fetchData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [items, setItems] = useState(item.contactDetails || []);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filteredFiles, setFilteredFiles] = useState([]);

  useEffect(() => {
    if (item) {
      setItems(item.contactDetails || []);
    }
  }, [item]);

  useEffect(() => {
    console.log("called");
    setFilteredFiles(
      items.filter(
        (contact) =>
          (contact.companyName &&
            contact.companyName
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (contact.location &&
            contact.location.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    );
  }, [items]);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFileUpload = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCardClick = (card, index) => {
    setSelectedCard(card);
    setCurrentIndex(index);
    setIsCardModalOpen(true);
  };

  const handleCloseCardModal = () => {
    setIsCardModalOpen(false);
  };

  return (
    <div
      style={{
        padding: "30px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        maxWidth: "1400px",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "20px",
          width: "100%",
          marginLeft: "10px",
        }}
      >
        <button
          className="btn"
          onClick={onClose}
          style={{
            marginTop: "-10px",
            color: "#E47331",
            cursor: "pointer",
            border: "none",
          }}
        >
          <IoArrowBack size={30} />
        </button>
        <h4 style={{ color: "#E47331" }}>{item.fName}</h4>
        <div
          style={{
            position: "relative",
            width: "250px",
            marginLeft: "auto",
            marginRight: "20px",
            height: "40px",
          }}
        >
          <input
            placeholder="Search Files"
            value={searchTerm}
            onChange={handleSearchInputChange}
            style={{
              paddingLeft: "20px",
              borderRadius: "6px",
              border: "1px solid #E47331",
              width: "100%",
              height: "100%",
            }}
          />
          <FaSearch
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              color: "#ACACAC",
            }}
          />
        </div>
        <button
          className="btn"
          style={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#E47331",
            borderColor: "#E47331",
            color: "white",
            fontWeight: "400",
          }}
          onClick={handleFileUpload}
        >
          <IoAdd size={20} style={{ marginRight: "5px" }} /> Add Contact
        </button>
      </div>

      <div className="row">
        {filteredFiles.length > 0 ? (
          filteredFiles.map((contact, index) => (
            <div
              className="col-lg-4 col-md-6 col-sm-12 mb-4"
              key={contact._id || index}
              onClick={() => handleCardClick(contact, index)}
            >
              <div
                className="card"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#F3F6F8",
                  width: "100%",
                  height: "auto",
                  gap: "20px",
                  marginLeft: "10px",
                }}
              >
                <img
                  src={`${BACKEND_URL}${contact.uploadFile}`}
                  className="card-img-top"
                  alt={contact.companyName}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "283px",
                  }}
                />
                <div className="card-body text-start">
                  <p
                    className="card-title"
                    style={{
                      color: "#303030",
                      fontSize: "20px",
                      fontWeight: "400",
                      marginLeft: "12px",
                    }}
                  >
                    {contact.companyName}
                  </p>
                  <p
                    className="card-text"
                    style={{
                      color: "#979797",
                      fontSize: "16px",
                      fontWeight: "400",
                      marginLeft: "12px",
                    }}
                  >
                    {contact.typesOfServiceProviding} , {contact.location}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p style={{ textAlign: "center", width: "100%" }}>No results found</p>
        )}
      </div>

      {isModalOpen && (
        <ConstructionNeedsFiles
          open={isModalOpen}
          handleClose={handleCloseModal}
          itemId={item._id}
          onDataUpdated={() => {
            console.log("called");
            fetchData();
          }}
        />
      )}

      {selectedCard && (
        <CardDetailsModal
          show={isCardModalOpen}
          handleClose={handleCloseCardModal}
          item={selectedCard}
          index={currentIndex}
          items={filteredFiles}
          onNavigate={(index) => {
            setSelectedCard(filteredFiles[index]);
            setCurrentIndex(index);
          }}
        />
      )}
    </div>
  );
};

export default ConstructionNeedsDetails;
