import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { WebApimanager } from "../../WebApiManager";
import Style from "./DailyLogReport/VehicleAndMachinery.module.css"
import style from "./Breakdown.module.css"
import { siteId } from "../../atoms";
import { useRecoilState } from "recoil";
import FormHeadings from "../PNM/PNMAssets/FormHeadings";
import { FaX } from "react-icons/fa6";

const BreakdownReport = ({handleClose,showLogReport=true,tableFormData={}}) => {
  const webApi = new WebApimanager();
  const [formData, setFormData] = useState({
    siteName: "",
    type: "",
    assetCode: "",
    transitionDate: new Date().toISOString().split("T")[0],
    subCode: "",
    equipmentType: "",
    breakDownDate: "",
    breakDownTime: "",
    breakDownType: "",
    model: "",
    repairDate: "",
    repairTime: "",
    repairStatus: "",
    actionTaken: "",
    leakages: "",
    engineStatus: "",
    tyreStatus: "",
    radiatorStatus: "",
    batteryStatus: "",
    mechanic: "",
    documentAttached: "",
    firstKmOrHrMeter: "",
    secondKmOrHrMeter: "",
    repeatedProblem: "",
    fuelPumpStatus: "",
    tyreStatus: "",
    gasOrOilCondition: "",
    engineOrMotorStatus: "",
    radiatorStatus: "",
    batteryStatus: "",
    spareCost: "",
    serviceCost: "",
    totalCost: "",
    sparesUsed: "",
    remarks: "",
    mechIncharge: "",
    issue: "",
    // PowerTools
    deadManSwitch: "",
    machineHandleCondition: "",
    machineCableCondition: "",
    wheelGuardCondition: "",
    //Db
    bodyEarthing: "",
    mcbAndRcbCondition: "",
    threePhaseIndicatorLight: "",
    voltageIndicatorDisplay: "",
    //Batching Plant
    weighingSystem:"",
    concreteMixerSystem:"",
    dustCollectors:"",
    storageSilasSystem:"",
    screwConveyor:"",
    fourBinFeederSystem:"",
    PlcControlSystem:"",
    airCompressorSystem:"",
    firstMeter:"",
    secondMeter:""

  });

  const [errors, setErrors] = useState({});
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [nameData, setNameData] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [sites, setDatasites] = useState([]);
  const [id, setId] = useState();
  const [equipment, setEquipment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const handleSiteChange = (e) => {
    const { name, value } = e.target;

    setSite(e.target.value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlefileChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.files[0] }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name,value,"name,value")

    console.log(name, value);
    const newValue = name === "kmOrHrReading" ? parseInt(value, 10) : value;

    let newFormData = {
      ...formData,
      [name]: newValue,
    };
    if (name === "equipmentType" && value) {
      setEquipment(value);
    }
    if (name === "assetCode" && value) {
      // Ensure that value is a string before splitting
      const assCode = assetCodeData.find((doc) => doc._id === value);
    
      if (assCode) {
        setId(assCode._id);
        console.log(assCode.name);
        newFormData = {
          ...newFormData,
          ["subCode"]: assCode.subCode,
          ["equipmentType"]: assCode.type,
        };
      }
    }
    
    setFormData(newFormData);
    if((name==="spareCost" && value) || (name === "serviceCost" && value)){
      newFormData={
        ...newFormData,
        ["totalCost"]:Number(newFormData.spareCost) + Number(newFormData.serviceCost)
      }
      setFormData(newFormData)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};

    const requiredFields = [
      "siteName",
      "equipmentType",
      "transitionDate",
      "assetCode",
      "type",
      "subCode",
      "model",
      "breakDownType",
      "breakDownDate",
      "breakDownTime",
      "repairDate",
      "repairTime",
      "repairStatus",
      "mechIncharge",
      "issue",
      "actionTaken",
      "mechanic",
      "repeatedProblem",
      "spareCost",
      "serviceCost",
      "totalCost",
      "sparesUsed",
      "remarks",
      "documentAttached",
    ];

    requiredFields.forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
    });

    if (
      formData.equipmentType === "Vehicle" ||
      formData.equipmentType === "Machinery"
    ) {
      const conditionalFields = [
        "firstKmOrHrMeter",
        "leakages",
        "fuelPumpSystem",
        "fuelPumpStatus",
        "tyreStatus",
        "gasOrOilCondition",
        "engineOrMotorStatus",
        "batteryStatus",
        "radiatorStatus",
      ];
      conditionalFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
      });
    } else if (formData.equipmentType === "Power Tools") {
      const conditionalFields = [
        "deadManSwitch",
        "machineHandleCondition",
        "machineCableCondition",
        "wheelGuardCondition",
      ];
      conditionalFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] =` ${key} is required`;
        }
      });
    } else if (formData.equipmentType === "Distribution Board") {
      const conditionalFields = [
        "bodyEarthing",
        "mcbAndRcbCondition",
        "threePhaseIndicatorLight",
        "voltageIndicatorDisplay",
      ];
      conditionalFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
      });
    } else if (formData.equipmentType === "Batching Plant"){
      const conditionalFields = [
        "weighingSystem",
        "concreteMixerSystem",
        "dustCollectors",
        "storageSilasSystem",
        "screwConveyor",
        "fourBinFeederSystem",
        "PlcControlSytem",
        "airCompressorSystem",
        "firstMeter",
        "secondMeter"
      ]

      conditionalFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
      });
    }

    console.log("submitted", formData);

    if (Object.keys(formErrors).length) {
      console.log("Form errors:", formErrors);
      setErrors(formErrors);
    } else {
      console.log(formData);
   
      let tempFormData = {
        siteName: site,
        transitionDate: formData.transitionDate,
        equipmentType: formData.equipmentType,
        assetCode:formData.assetCode,
        type: formData.type,
        subCode: formData.subCode,
        model: formData.model,
        breakDownType: formData.breakDownType,
        breakDownDate: formData.breakDownDate,
        breakDownTime: formData.breakDownTime,
        repairDate: formData.repairDate,
        repairTime: formData.repairTime,
        repairStatus: formData.repairStatus,
        mechIncharge: formData.mechIncharge,
        issue: formData.issue,
        actionTaken: formData.actionTaken,
        mechanic: formData.mechanic,
        repeatedProblem: formData.repeatedProblem,
        spareCost: formData.spareCost,
        serviceCost: formData.serviceCost,
        totalCost: formData.totalCost,
        sparesUsed: formData.sparesUsed,
        remarks: formData.remarks,
        // documentAttached: formData.documentAttached,
      };
      if (
        formData.equipmentType === "Vehicle" ||
        formData.equipmentType === "Machinery"
      ) {
        tempFormData = {
          ...tempFormData,vehicleAndMachineryQueriesDetails:{
            mechanic:formData.mechanic,
            firstKmOrHrMeter: formData.firstKmOrHrMeter,
            secondKmOrHrMeter: formData.secondKmOrHrMeter,
            leakages: formData.leakages,
            fuelPumpSystem: formData.fuelPumpSystem,
            fuelPumpStatus: formData.fuelPumpStatus,
            tyreStatus: formData.tyreStatus,
            gasOrOilCondition: formData.gasOrOilCondition,
            engineOrMotorStatus: formData.engineOrMotorStatus,
            batteryStatus: formData.batteryStatus,
            radiatorStatus: formData.radiatorStatus,
            repeatedProblem: formData.repeatedProblem,
            spareCost: formData.spareCost,
            serviceCost: formData.serviceCost,
            totalCost: formData.totalCost,
            sparesUsed: formData.sparesUsed,
            remarks: formData.remarks,
          
          },
            
          
        };
      } else if (formData.equipmentType === "Power Tools") {
        tempFormData = {
          ...tempFormData,powerToolsQueriesDetails:{
            mechanic:formData.mechanic,
            deadManSwitch: formData.deadManSwitch,
          machineHandleCondition: formData.machineHandleCondition,
          machineCableCondition: formData.machineCableCondition,
          wheelGuardCondition: formData.wheelGuardCondition,
          repeatedProblem: formData.repeatedProblem,
            spareCost: formData.spareCost,
            serviceCost: formData.serviceCost,
            totalCost: formData.totalCost,
            sparesUsed: formData.sparesUsed,
            remarks: formData.remarks,
          
          }
          
        };
      } else if (formData.equipmentType === "Distribution Board") {
        tempFormData = {
          ...tempFormData,distributionBoardQueriesDetails:{
            mechanic:formData.mechanic,
            bodyEarthing: formData.bodyEarthing,
          mcbAndRcbCondition: formData.mcbAndRcbCondition,
          threePhaseIndicatorLight: formData.threePhaseIndicatorLight,
          voltageIndicatorDisplay: formData.voltageIndicatorDisplay,
          repeatedProblem: formData.repeatedProblem,
            spareCost: formData.spareCost,
            serviceCost: formData.serviceCost,
            totalCost: formData.totalCost,
            sparesUsed: formData.sparesUsed,
            remarks: formData.remarks,
          
          }
          
        };
      } else if (formData.equipmentType === "Batching Plant"){
        tempFormData={
          ...tempFormData,batchingPointQueriesDetails:{
            weighingSystem:formData.weighingSystem,
            concreteMixerSystem:formData.concreteMixerSystem,
            storageSilasSystem:formData.storageSilasSystem,
            screwConveyor:formData.screwConveyor,
            fourBinFeederSystem:formData.fourBinFeederSystem,
            PlcControlSystem:formData.PlcControlSystem,
            airCompressorSystem:formData.airCompressorSystem,
            firstMeter:formData.firstMeter,
            secondMeter:formData.secondMeter,
            mechanic:formData.mechanic,
            repeatedProblem: formData.repeatedProblem,
            spareCost: formData.spareCost,
            serviceCost: formData.serviceCost,
            totalCost: formData.totalCost,
            sparesUsed: formData.sparesUsed,
            remarks: formData.remarks,
          }
        }
      }
      console.log("temp", tempFormData);
      console.log("formData", formData);
      if(Object.keys(tableFormData).length > 0){
        const response = await webApi.put(
          `api/pnms/breakDownReport/update/${tableFormData._id}`,
          tempFormData
        );
        console.log(response);
        if (response.status === 200 || response.status === 201) {
          const responseData = response.data;
          if (responseData.status === "error") {
            setErrorMessage(responseData.message);
            console.log("Response data:", responseData.message);
            console.log(response,"resssssss")
            setApiProcessing({ loader: false, message: "" });
            return; 
          }
  
          const id = response.data.data.breakDownReport._id;
          
  
          if (formData.documentAttached) {
            const documentAttached = new FormData();
            documentAttached.append("documentAttached", formData.documentAttached);
            const uploadResponse = await webApi.imagePut(
              `api/pnms/breakDownReport/${id}`,
              documentAttached
            );
            console.log("Upload response:", uploadResponse);

          }
          setApiProcessing({ loader: false, message: "" });
     
        } else if (response.status === 400) {
          if (response.data && response.data.message) {
            setErrorMessage(response.data.message);
            console.log("Response data:", response.data.message);
          }
          setApiProcessing({ loader: false, message: "" });
        } else {
          setErrorMessage("An unexpected error occurred.");
          setApiProcessing({ loader: false, message: "" });
        }
        window.location.reload();
      } else {
        
      const response = await webApi.post(
        "api/pnms/breakDownReport",
        tempFormData
      );
      
      if (response.status === 200 || response.status === 201) {
        const responseData = response.data;
        if (responseData.status === "error") {
          setErrorMessage(responseData.message);
          console.log("Response data:", responseData.message);
          setApiProcessing({ loader: false, message: "" });
          return; 
        }

        const id = response.data.data.breakDownReport._id;
        

        if (formData.documentAttached) {
          const documentAttached = new FormData();
          documentAttached.append("documentAttached", formData.documentAttached);
          const uploadResponse = await webApi.imagePut(
            `api/pnms/breakDownReport/${id}`,
            documentAttached
          );
          window.location.reload()

          console.log("Upload response:", uploadResponse);
        }
        setApiProcessing({ loader: false, message: "" });
   
      } else if (response.status === 400) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
          console.log("Response data:", response.data.message);
        }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }

      }
      

    }
  };

  const convertTo24Hour = (time) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":");
  
    if (hours === "12") {
      hours = modifier === "AM" ? "00" : "12";
    } else if (modifier === "PM") {
      hours = String(parseInt(hours, 10) + 12);
    }
  
    return `${hours.padStart(2, "0")}:${minutes}`;
  };

  useEffect(()=>{
    if(Object.keys(tableFormData).length){
      let updateForm={...tableFormData,
        ["transitionDate"]:tableFormData.transitionDate.slice(0,10),
        ["breakDownDate"]:tableFormData.breakDownDate.slice(0,10),
        ["breakDownTime"]:convertTo24Hour(tableFormData.breakDownTime),
        ["repairDate"]:tableFormData.repairDate.slice(0,10),
        ["repairTime"]:convertTo24Hour(tableFormData.repairTime),
        ["documentAttached"]:tableFormData.documentAttached.split('-')[1],
        ["assetCode"]:tableFormData.assetCode._id

      }
      if(tableFormData.equipmentType === "Distribution Board"){
        updateForm = {...updateForm,
          ["mechanic"]:tableFormData.distributionBoardQueriesDetails.mechanic,
          ["mcbAndRcbCondition"]:tableFormData.distributionBoardQueriesDetails.mcbAndRcbCondition,
          ["threePhaseIndicatorLight"]:tableFormData.distributionBoardQueriesDetails.threePhaseIndicatorLight,
          ["repeatedProblem"]:tableFormData.distributionBoardQueriesDetails.repeatedProblem,
          ["voltageIndicatorDisplay"]:tableFormData.distributionBoardQueriesDetails.voltageIndicatorDisplay,
          ["spareCost"]:tableFormData.distributionBoardQueriesDetails.spareCost,
          ["serviceCost"]:tableFormData.distributionBoardQueriesDetails.serviceCost,
          ["totalCost"]:tableFormData.distributionBoardQueriesDetails.totalCost,
          ["sparesUsed"]:tableFormData.distributionBoardQueriesDetails.sparesUsed,
          ["remarks"]:tableFormData.distributionBoardQueriesDetails.remarks,
          ["sparesUsed"]:tableFormData.distributionBoardQueriesDetails.sparesUsed,
        }
          

        
      }
      if(tableFormData.equipmentType === "Vehicle" ||tableFormData.equipmentType === "Machinery"){
        updateForm ={...updateForm,
          ["mechanic"]:tableFormData.vehicleAndMachineryQueriesDetails.mechanic,
          ["firstKmOrHrMeter"]:tableFormData.vehicleAndMachineryQueriesDetails.firstKmOrHrMeter,
          ["secondKmOrHrMeter"]:tableFormData.vehicleAndMachineryQueriesDetails.secondKmOrHrMeter,
          ["leakages"]:tableFormData.vehicleAndMachineryQueriesDetails.leakages,
          ["fuelPumpSystem"]:tableFormData.vehicleAndMachineryQueriesDetails.fuelPumpSystem,
          ["fuelPumpStatus"]:tableFormData.vehicleAndMachineryQueriesDetails.fuelPumpStatus,
          ["tyreStatus"]:tableFormData.vehicleAndMachineryQueriesDetails.tyreStatus,
          ["gasOrOilCondition"]:tableFormData.vehicleAndMachineryQueriesDetails.gasOrOilCondition,
          ["engineOrMotorStatus"]:tableFormData.vehicleAndMachineryQueriesDetails.engineOrMotorStatus,
          ["spareCost"]:tableFormData.vehicleAndMachineryQueriesDetails.spareCost,
          ["serviceCost"]:tableFormData.vehicleAndMachineryQueriesDetails.serviceCost,
          ["totalCost"]:tableFormData.vehicleAndMachineryQueriesDetails.totalCost,
          ["sparesUsed"]:tableFormData.vehicleAndMachineryQueriesDetails.sparesUsed,
          ["remarks"]:tableFormData.vehicleAndMachineryQueriesDetails.remarks,
          ["repeatedProblem"]:tableFormData.vehicleAndMachineryQueriesDetails.repeatedProblem,
          ["radiatorStatus"]:tableFormData.vehicleAndMachineryQueriesDetails.radiatorStatus,
          ["batteryStatus"]:tableFormData.vehicleAndMachineryQueriesDetails.batteryStatus,
        }
      }
      if(tableFormData.equipmentType === "Power Tools"){
        updateForm = {...updateForm,
          ["mechanic"]:tableFormData.powerToolsQueriesDetails.mechanic,
          ["deadManSwitch"]:tableFormData.powerToolsQueriesDetails.deadManSwitch,
          ["machineHandleCondition"]:tableFormData.powerToolsQueriesDetails.machineHandleCondition,
          ["machineCableCondition"]:tableFormData.powerToolsQueriesDetails.machineCableCondition,
          ["repeatedProblem"]:tableFormData.powerToolsQueriesDetails.repeatedProblem,
          ["wheelGuardCondition"]:tableFormData.powerToolsQueriesDetails.wheelGuardCondition,
          ["spareCost"]:tableFormData.powerToolsQueriesDetails.spareCost,
          ["serviceCost"]:tableFormData.powerToolsQueriesDetails.serviceCost,
          ["totalCost"]:tableFormData.powerToolsQueriesDetails.totalCost,
          ["sparesUsed"]:tableFormData.powerToolsQueriesDetails.sparesUsed,
          ["remarks"]:tableFormData.powerToolsQueriesDetails.remarks,
          
        }
      }
      if(tableFormData.equipmentType === "Batching Plant"){
        updateForm = {...updateForm,
          ["weighingSystem"]:tableFormData.batchingPointQueriesDetails.weighingSystem,
          ["concreteMixerSystem"]:tableFormData.batchingPointQueriesDetails.concreteMixerSystem,
          ['storageSilasSystem']:tableFormData.batchingPointQueriesDetails.storageSilasSystem,
          ["screwConveyor"]:tableFormData.batchingPointQueriesDetails.screwConveyor,
          ["fourBinFeederSystem"]:tableFormData.batchingPointQueriesDetails.fourBinFeederSystem,
          ["PlcControlSystem"]:tableFormData.batchingPointQueriesDetails.PlcControlSystem,
          ["airCompressorSystem"]:tableFormData.batchingPointQueriesDetails.airCompressorSystem,
          ["firstMeter"]:tableFormData.batchingPointQueriesDetails.firstMeter,
          ["secondMeter"]:tableFormData.batchingPointQueriesDetails.secondMeter,
          ["mechanic"]:tableFormData.batchingPointQueriesDetails.mechanic,
          ["repeatedProblem"]: tableFormData.batchingPointQueriesDetails.repeatedProblem,
          ["spareCost"]: tableFormData.batchingPointQueriesDetails.spareCost,
          ["serviceCost"]: tableFormData.batchingPointQueriesDetails.serviceCost,
          ["totalCost"]: tableFormData.batchingPointQueriesDetails.totalCost,
          ["sparesUsed"]: tableFormData.batchingPointQueriesDetails.sparesUsed,
          ["remarks"]: tableFormData.batchingPointQueriesDetails.remarks,
        }
      }
      setFormData(updateForm)
    }
  },[tableFormData])
  
  useEffect(() => {
    const fetchAssetCodeData = async () => {
      const response = await webApi.get(`api/pnms/assetCode`);
      setAssetCodeData(response.data.data.AssetCodes);
    };

    const fetchSiteData = async () => {
      const response = await webApi.get(`api/sites/AllSites`);
      setNameData(response.data.sites);
    };

    fetchAssetCodeData();
    fetchSiteData();
  }, []);

  useEffect(() => {
 
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
      
          if (!site) {
            const defaultSiteId = response.data.sites[0]._id;
            setSite(defaultSiteId); 
            setFormData((prev) => ({ ...prev, siteName: defaultSiteId })); 
          } else {
            setFormData((prev) => ({ ...prev, siteName: site })); 
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [site]);

  return (
    <>
    <div className={showLogReport?Style.VMMainContainer:Style.VMEditMainContainer} style={{position:"relative"}}>
    {!showLogReport && <div style={{position:"absolute",right:"40px",top:"40px",color:"#E47331",cursor:"pointer"}} >
            <FaX onClick={handleClose}/>
          </div>}
          <h3 className={Style.VMHeading}>  
            Breakdown Report
          </h3>
          <form onSubmit={handleSubmit} className={Style.VMFormContainer}>
            <div className={Style.VMSiteContainer}>
              <div className={Style.VMSiteHeading}>
                <FormHeadings text={"Site Name:"} />
              </div>
              <div className={Style.VMSite}>
                <select
                  className={Style.VMSiteInputField}
                  name="siteName"
                  value={formData.siteName}
                  onChange={handleSiteChange}
                  
                >
                  <option value="" hidden>
                    Select a site
                  </option>
                  {sites &&
                    Array.isArray(sites) &&
                    sites.map((item) => (
                      <option value={item._id} key={item._id}>
                        {item.siteName}
                      </option>
                    ))}
                </select>
                <p className={Style.PNMFormErrors}>{errors.siteName}</p>
              </div>
            </div>
            <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition ID:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                      <input type="text" readOnly name="transitionId" value={tableFormData?.transitionId?tableFormData.transitionId:""} className={Style.VMTopInputField} />
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition Date:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                            type="date"
                            className={Style.VMTopInputField}
                            name="transitionDate"
                            value={formData.transitionDate}
                            onChange={handleChange}
                            placeholder="---------"
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.transitionDate}
                          </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Equipment Type:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                      <select
                        className={Style.VMTopInputField}
                        name="equipmentType"
                        value={formData.equipmentType}
                        onChange={handleChange}
                      >
                        <option value="" hidden>
                          Select Equipment Type
                        </option>
                        <option value="Vehicle">Vehicle</option>
                        <option value="Machinery">Machinery</option>
                        <option value="Power Tools">Power Tools</option>
                        <option value="Distribution Board">
                          Distribution Board 
                        </option>
                        <option value="Batching Plant">Batching Plant</option>
                      </select>
                      <p className={Style.PNMFormErrors}>
                        {errors.equipmentType}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Asset code/Name:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="assetCode"
                          value={formData.assetCode}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            Select name
                          </option>
                          {assetCodeData.length && equipment ?
                            assetCodeData
                            .filter((item) => item.type.includes(equipment))
                              .map((item) => (
                                <option
                                  value={item._id}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              )):assetCodeData.map((item)=>(
                                <option
                                  value={item._id}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              ))}
                        </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.assetCode}
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Type:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="type"
                          value={formData.type}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            select Type
                          </option>
                          <option value="Own">Own</option>
                          <option value="Hire">Hire</option>
                        </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.type}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"SubCode:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          type="text"
                          placeholder="Enter SubCode"
                          name="subCode"
                          onChange={handleChange}
                          value={formData.subCode}
                        />
                        <p className={Style.PNMFormErrors}>{errors.subCode}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Model:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                      <input type="text"
                      className={Style.VMTopInputField} 
                      name="model"
                      value={formData.model}
                      onChange={handleChange}
                      placeholder="Enter Model"/>
                      <p className={Style.PNMFormErrors}>{errors.model}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"BreakDown Type:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField} 
                          name="breakDownType"
                          value={formData.breakDownType}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            select Type
                          </option>
                          <option value="General Service">
                            General Service
                          </option>
                          <option value="Service">Service</option>
                          <option value="BreakDown">BreakDown</option>
                        </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.breakDownType}
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"BreakDown Date:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          type="date"
                          className={Style.VMTopInputField}
                          name="breakDownDate"
                          value={formData.breakDownDate}
                          onChange={handleChange}
                        />
                        <p className={Style.PNMFormErrors}>
                          {errors.breakDownDate}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Breakdown Time:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                      className={Style.VMTopInputField}
                      type="time"
                      placeholder="Enter SubCode"
                      name="breakDownTime"
                      onChange={handleChange}
                      value={formData.breakDownTime}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.breakDownTime}
                    </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Repaired Date:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                      type="date"
                      className={Style.VMTopInputField}
                      name="repairDate"
                      value={formData.repairDate}
                      onChange={handleChange}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.repairDate}
                    </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Repaired Time:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                      className={Style.VMTopInputField}
                      type="time"
                      placeholder="Enter"
                      name="repairTime"
                      onChange={handleChange}
                      value={formData.repairTime}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.repairTime}
                    </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Repair Status:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                      className={Style.VMTopInputField}
                      name="repairStatus"
                      value={formData.repairStatus}
                      onChange={handleChange}
                      >
                      <option value="" hidden>
                        select Type
                      </option>
                      <option value="Ok for use">Ok for use</option>
                      <option value="Not for use">Not for use</option>
                    </select>
                    <p className={Style.PNMFormErrors}>
                      {errors.repairStatus}
                    </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Mech. Incharge:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                      className={Style.VMTopInputField}
                      type="text"
                      placeholder="Enter Mech Incharge"
                      name="mechIncharge"
                      onChange={handleChange}
                      value={formData.mechIncharge}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.mechIncharge}
                    </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Issue:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <textarea
                      name="issue"
                      id="issue"
                      value={formData.issue}
                      onChange={handleChange}
                      rows="3"
                      className={Style.BRTopInputField}
                      placeholder="message"
                    ></textarea>
                    <p className={Style.PNMFormErrors}>{errors.issue}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Action Taken:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <textarea
                      name="actionTaken"
                      id="actionTaken"
                      value={formData.actionTaken}
                      onChange={handleChange}
                      rows="3"
                      placeholder="message"
                      className={Style.BRTopInputField}
                    ></textarea>
                    <p className={Style.PNMFormErrors}>
                      {errors.actionTaken}
                    </p>
                    </div>
                  </div>
                </div>
              </div>
              
                
            </div>
            <div className={Style.VMFormSeperator}></div>
            {(formData.equipmentType ==="Vehicle" || formData.equipmentType === "Machinery") &&<>
            <h3 className={Style.VMHeading} style={{marginBlock:"30px"}}>
            Vehicle/Machinery-Equipment Condition Queries
          </h3>

          
          <div className={style.BRVMMidFormCont}>
              <div className={style.BRVMMidFormContent}>
                  <div className={style.BRVMMidFormInputHeading}>
                    <FormHeadings text={"Fuel Pump Status:"} />
                  </div>
                  <div className={style.BRVMMidFormInputContainer} style={{position:"relative"}}>
                  <select
                      className={Style.VMMidInput}
                      onChange={handleChange}
                      value={formData.fuelPumpStatus}
                      name="fuelPumpStatus"
                    >
                      <option value="" hidden>
                        Select Fuel Pump Status
                      </option>
                      <option value="Ok">Ok</option>
                      <option value="Not">Not Ok</option>
                      <option value="Not Applicable">Not Applicable</option>
                    </select>
                    <p className={Style.PNMFormErrors} >
                      {errors.fuelPumpStatus}
                    </p>
                  </div>
              </div>   
              <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Leakages:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <select
                        className={Style.VMMidInput}
                        onChange={handleChange}
                        name="leakages"
                        value={formData.leakages}
                      >
                        <option value="" hidden>
                          Leakages?
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                      <p className={Style.PNMFormErrors}>
                        {errors.leakages}
                      </p>
                    </div>
              </div>
              <div className={style.BRVMMidFormContent}>
                  <div className={style.BRVMMidFormInputHeading}>
                    <FormHeadings text={"Fuel Pump System:"} />
                  </div>
                  <div className={style.BRVMMidFormInputContainer}>
                  <select
                    className={Style.VMMidInput}
                    onChange={handleChange}
                    value={formData.fuelPumpSystem}
                    name="fuelPumpSystem"
                  >
                    <option value="" hidden>
                      Select Fuel Pump System
                    </option>
                    <option value="Ok">Ok</option>
                    <option value="Not">Not Ok</option>
                    <option value="Not Applicable">Not Applicable</option>
                  </select>
                  <p className={Style.PNMFormErrors}>
                    {errors.fuelPumpSystem}
                  </p>
                  </div>
              </div>
          </div>
            
          <div className={style.BRVMMidFormCont}>
              <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Eng/Motor Status:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <select
                      className={Style.VMMidInput}
                      name="engineOrMotorStatus"
                      onChange={handleChange}
                      value={formData.engineOrMotorStatus}
                    >
                      <option value="" hidden>
                        Select Eng/Motor Status
                      </option>
                      <option value="Ok">Ok</option>
                      <option value="Not">Not Ok</option>
                      <option value="Not Applicable">Not Applicable</option>
                    </select>
                    <p className={Style.PNMFormErrors} style={{width:"150px"}}>
                      {errors.engineOrMotorStatus}
                    </p>
                    </div>
              </div>
              <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Tyre Status:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <select
                        className={Style.VMMidInput}
                        onChange={handleChange}
                        name="tyreStatus"
                        value={formData.tyreStatus}
                      >
                        <option value="" hidden>
                          Select Tyre Status
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                      <p className={Style.PNMFormErrors}>
                        {errors.tyreStatus}
                      </p>
                    </div>
              </div>
              <div className={style.BRVMMidFormContent}>
                  <div className={style.BRVMMidFormInputHeading}>
                    <FormHeadings text={"Gas/Oil Condition:"} />
                  </div>
                  <div className={style.BRVMMidFormInputContainer}>
                  <select
                      className={Style.VMMidInput}
                      name="gasOrOilCondition"
                      onChange={handleChange}
                      value={formData.gasOrOilCondition}
                    >
                      <option value="" hidden>
                        Select Gas/Oil Condition
                      </option>
                      <option value="Ok">Ok</option>
                      <option value="Not">Not Ok</option>
                      <option value="Not Applicable">Not Applicable</option>
                    </select>
                    <p className={Style.PNMFormErrors}>
                      {errors.gasOrOilCondition}
                    </p>
                  </div>
              </div>
                
             
          </div>

          <div className={style.BRVMMidFormCont}>
                
                
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Battery Status:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <select
                        className={Style.VMMidInput}
                        onChange={handleChange}
                        name="batteryStatus"
                        value={formData.batteryStatus}
                      >
                        <option value="" hidden>
                          Select Battery Status
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                      <p className={Style.PNMFormErrors}>
                        {errors.batteryStatus}
                      </p>
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Radiator Status:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <select
                        className={Style.VMMidInput}
                        name="radiatorStatus"
                        onChange={handleChange}
                        value={formData.radiatorStatus}
                      >
                        <option value="" hidden>
                          Select Radiator Status
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                      <p className={Style.PNMFormErrors}>
                        {errors.radiatorStatus}
                      </p>
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={""} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>

                    </div>
                </div>
          </div>
          <div className={Style.VMFormSeperator}></div>
          <p style={{fontSize:"14px", textAlign:"left",color:"#e47331"}}>Note: If your equipment is having single engine. Please don't enter 2nd Km/Hr Meter</p>
          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"1st Km/Hr Meter:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                      type="number"
                      placeholder="Enter Reading"
                      className={Style.VMTopInputField}
                      name="firstKmOrHrMeter"
                      onChange={handleChange}
                      value={formData.firstKmOrHrMeter}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.firstKmOrHrMeter}
                    </p>                    
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"2nd Km/Hr Meter:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                      type="number"
                      placeholder="Enter Reading"
                      className={Style.VMTopInputField}
                      onChange={handleChange}
                      value={formData.secondKmOrHrMeter}
                      name="secondKmOrHrMeter"
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.secondKmOrHrMeter}
                    </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Repeated Problem:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                      type="text"
                      className={Style.VMTopInputField}
                      placeholder=""
                      name="repeatedProblem"
                      onChange={handleChange}
                      value={formData.repeatedProblem}
                    >
                      <option value="">Select Yes/No</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    <p className={Style.PNMFormErrors}>
                      {errors.repeatedProblem}
                    </p>                  
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Mechanic Name:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                      type="text"
                      className={Style.VMTopInputField}
                      placeholder="Mechanic name"
                      name="mechanic"
                      onChange={handleChange}
                      value={formData.mechanic}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.mechanic}
                    </p>
                    </div>
                  </div>
                </div>
              </div>
              
          </div>
          
          <div className={style.BRVMMidFormCont} style={{marginTop:"40px",marginBottom:"30px"}}>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Spare Cost:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <input
                      type="text"
                      placeholder="Enter Cost"
                      className={Style.VMMidInput}
                      name="spareCost"
                      onChange={(e)=>{
                        const value=e.target.value
                        if (!isNaN(value) ) {
                          handleChange(e);
                        }
                      }}
                      value={formData.spareCost}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.spareCost}
                    </p>
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Service Cost:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <input
                      type="text"
                      placeholder="Enter Cost "
                      className={Style.VMMidInput}
                      onChange={(e)=>{
                        const value=e.target.value
                        if (!isNaN(value) ) {
                          handleChange(e);
                        }
                      }}
                      name="serviceCost"
                      value={formData.serviceCost}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.serviceCost}
                    </p>
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Total Cost:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <input
                      type="Number"
                      className={Style.VMMidInput}
                      name="totalCost"
                      onChange={handleChange}
                      value={formData.spareCost && formData.serviceCost ? formData.totalCost:""}
                      readOnly
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.totalCost}
                    </p>
                    </div>
                </div>
             
          </div>
          <div className={style.BRVMSpareUsedCont}>

          <div className={style.BRVMSpareUsedHeading}>
            <FormHeadings text={"Spare Used:"} />
          </div>
          <div className={style.BRVMInputCont}>
          <input
            name="sparesUsed"
            placeholder="Enter "
            value={formData.sparesUsed}
            onChange={handleChange}
            type="text"
            className={Style.VMMidInput}
          />
          <p className={Style.PNMFormErrors}>
            {errors.sparesUsed}
          </p>
          </div>

          </div>

          <div className={style.BRVMSpareUsedCont} style={{marginTop:"30px"}}>

            <div className={style.BRVMSpareUsedHeading}>
              <FormHeadings text={"Remarks:"} />
            </div>
            <div className={style.BRVMInputCont}>
            <input
              name="remarks"
              placeholder="Enter Remark"
              value={formData.remarks}
              onChange={handleChange}
              type="text"
              className={Style.VMMidInput}
            />
            <p className={Style.PNMFormErrors}>
              {errors.remarks}
            </p>
            </div>

          </div>

          <div  style={{marginTop:"30px",display:"flex"}}>

            <div className={style.BRVMDocumentAttached}>
              <FormHeadings text={"Document Attached:"} />
            </div>
            <div style={{width:"35%"}}>
            <input
              name="documentAttached"
              onChange={handlefileChange}
              type="file"
              className={Style.BRDocument}
              style={{ height: '43px' }}
            />
            <p className={Style.PNMFormErrors}>
              {errors.documentAttached}
            </p>
            </div>

          </div>
          </>}

          {formData.equipmentType === "Power Tools" && <>
          
          <h3 className={Style.VMHeading} style={{marginBlock:"30px"}}>
            Power Tools Query Details
          </h3>

          <div className={Style.VMTopFormMainContainer}>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"Dead Man Switch:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                        <select
                          className={Style.VMTopInputField}
                          name="deadManSwitch"
                          onChange={handleChange}
                          value={formData.deadManSwitch}
                        >
                          <option value="" hidden>
                            Select option
                          </option>
                          <option value="Ok">Ok</option>
                          <option value="Not">Not Ok</option>
                          <option value="Not Applicable">Not Applicable</option>
                        </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.deadManSwitch}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"Machine Handle Condition:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                        <select
                        type="text"
                        className={Style.VMTopInputField}
                        name="machineHandleCondition"
                        onChange={handleChange}
                        value={formData.machineHandleCondition}
                      >
                        <option value="" hidden>
                          Select option
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.machineHandleCondition}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"Wheel Guard Condition:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <select
                        type="text"
                        className={Style.VMTopInputField}
                        name="wheelGuardCondition"
                        onChange={handleChange}
                        value={formData.wheelGuardCondition}
                      >
                        <option value="" hidden>
                          Select option
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.wheelGuardCondition}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"Machine Cable Condition:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <select
                        type="text"
                        className={Style.VMTopInputField}
                        name="machineCableCondition"
                        onChange={handleChange}
                        value={formData.machineCableCondition}
                      >
                        <option value="" hidden>
                          Select option
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.machineCableCondition}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                
          </div>
          
          <div className={Style.VMFormSeperator}></div>
          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Repeated Problem:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                      type="text"
                      className={Style.VMTopInputField}
                      placeholder=""
                      name="repeatedProblem"
                      onChange={handleChange}
                      value={formData.repeatedProblem}
                    >
                      <option value="">Select Yes/No</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    <p className={Style.PNMFormErrors}>
                      {errors.repeatedProblem}
                    </p>                  
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Mechanic Name:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                      type="text"
                      className={Style.VMTopInputField}
                      placeholder="Mechanic name"
                      name="mechanic"
                      onChange={handleChange}
                      value={formData.mechanic}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.mechanic}
                    </p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          
          <div className={style.BRVMMidFormCont} style={{marginTop:"40px",marginBottom:"30px"}}>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Spare Cost:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <input
                      type="text"
                      placeholder="Enter Cost"
                      className={Style.VMMidInput}
                      name="spareCost"
                      onChange={(e)=>{
                        const value=e.target.value
                        if (!isNaN(value) ) {
                          handleChange(e);
                        }
                      }}
                      value={formData.spareCost}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.spareCost}
                    </p>
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Service Cost:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <input
                      type="text"
                      placeholder="Enter Cost "
                      className={Style.VMMidInput}
                      onChange={(e)=>{
                        const value=e.target.value
                        if (!isNaN(value) ) {
                          handleChange(e);
                        }
                      }}
                      name="serviceCost"
                      value={formData.serviceCost}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.serviceCost}
                    </p>
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Total Cost:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <input
                      type="Number"
                      className={Style.VMMidInput}
                      name="totalCost"
                      onChange={handleChange}
                      value={formData.spareCost && formData.serviceCost ?formData.totalCost:""}
                      readOnly
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.totalCost}
                    </p>
                    </div>
                </div>
             
          </div>
          <div className={style.BRVMSpareUsedCont}>

          <div className={style.BRVMSpareUsedHeading}>
            <FormHeadings text={"Spare Used:"} />
          </div>
          <div className={style.BRVMInputCont}>
          <input
            name="sparesUsed"
            placeholder="Enter "
            value={formData.sparesUsed}
            onChange={handleChange}
            type="text"
            className={Style.VMMidInput}
          />
          <p className={Style.PNMFormErrors}>
            {errors.sparesUsed}
          </p>
          </div>

          </div>

          <div className={style.BRVMSpareUsedCont} style={{marginTop:"30px"}}>

            <div className={style.BRVMSpareUsedHeading}>
              <FormHeadings text={"Remarks:"} />
            </div>
            <div className={style.BRVMInputCont}>
            <input
              name="remarks"
              placeholder="Enter Remark"
              value={formData.remarks}
              onChange={handleChange}
              type="text"
              className={Style.VMMidInput}
            />
            <p className={Style.PNMFormErrors}>
              {errors.remarks}
            </p>
            </div>

          </div>

          <div  style={{marginTop:"30px",display:"flex"}}>

            <div className={style.BRVMDocumentAttached}>
              <FormHeadings text={"Document Attached:"} />
            </div>
            <div style={{width:"35%"}}>
            <input
              name="documentAttached"
              onChange={handlefileChange}
              type="file"
              className={Style.BRDocument}
              style={{ height: '43px' }}
            />
            <p className={Style.PNMFormErrors}>
              {errors.documentAttached}
            </p>
            </div>

          </div>

          
        
        </>}

        {formData.equipmentType === "Distribution Board" && <>
        <h3 className={Style.VMHeading} style={{marginBlock:"30px"}}>
            Distribution Board-Equipment Condition Queries
        </h3>
        <div className={Style.VMTopFormMainContainer}>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"Body Earthing:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                        <select
                          className={Style.VMTopInputField}
                          name="bodyEarthing"
                          onChange={handleChange}
                          value={formData.bodyEarthing}
                        >
                          <option value="" hidden>
                            Select option
                          </option>
                          <option value="Ok">Ok</option>
                          <option value="Not">Not Ok</option>
                          <option value="Not Applicable">Not Applicable</option>
                        </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.bodyEarthing}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"MCB & RCB Condition:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                        <select
                        type="text"
                        className={Style.VMTopInputField}
                        name="mcbAndRcbCondition"
                        onChange={handleChange}
                        value={formData.mcbAndRcbCondition}
                      >
                        <option value="" hidden>
                          Select option
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.mcbAndRcbCondition}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"Voltage Indicator Display:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <select
                        type="text"
                        className={Style.VMTopInputField}
                        name="voltageIndicatorDisplay"
                        onChange={handleChange}
                        value={formData.voltageIndicatorDisplay}
                      >
                        <option value="" hidden>
                          Select option
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.voltageIndicatorDisplay}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"3 Phase Indicator Light"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <select
                        type="text"
                        className={Style.VMTopInputField}
                        name="threePhaseIndicatorLight"
                        onChange={handleChange}
                        value={formData.threePhaseIndicatorLight}
                      >
                        <option value="" hidden>
                          Select option
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.threePhaseIndicatorLight}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>

              <div className={Style.VMFormSeperator}></div>
          <div className={Style.VMTopFormMainContainer}>
              
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Repeated Problem:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                      type="text"
                      className={Style.VMTopInputField}
                      placeholder=""
                      name="repeatedProblem"
                      onChange={handleChange}
                      value={formData.repeatedProblem}
                    >
                      <option value="">Select Yes/No</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    <p className={Style.PNMFormErrors}>
                      {errors.repeatedProblem}
                    </p>                  
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Mechanic Name:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                      type="text"
                      className={Style.VMTopInputField}
                      placeholder="Mechanic name"
                      name="mechanic"
                      onChange={handleChange}
                      value={formData.mechanic}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.mechanic}
                    </p>
                    </div>
                  </div>
                </div>
              </div>
              
          </div>
          
          <div className={style.BRVMMidFormCont} style={{marginTop:"40px",marginBottom:"30px"}}>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Spare Cost:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <input
                      type="text"
                      placeholder="Enter Cost"
                      className={Style.VMMidInput}
                      name="spareCost"
                      onChange={(e)=>{
                        const value=e.target.value
                        if (!isNaN(value) ) {
                          handleChange(e);
                        }
                      }}
                      value={formData.spareCost}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.spareCost}
                    </p>
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Service Cost:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <input
                      type="text"
                      placeholder="Enter Cost "
                      className={Style.VMMidInput}
                      onChange={(e)=>{
                        const value=e.target.value
                        if (!isNaN(value) ) {
                          handleChange(e);
                        }
                      }}
                      name="serviceCost"
                      value={formData.serviceCost}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.serviceCost}
                    </p>
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Total Cost:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <input
                      type="Number"
                      className={Style.VMMidInput}
                      name="totalCost"
                      onChange={handleChange}
                      value={formData.spareCost && formData.serviceCost ?  formData.totalCost:''}
                      readOnly
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.totalCost}
                    </p>
                    </div>
                </div>
             
          </div>
          <div className={style.BRVMSpareUsedCont}>

          <div className={style.BRVMSpareUsedHeading}>
            <FormHeadings text={"Spare Used:"} />
          </div>
          <div className={style.BRVMInputCont}>
          <input
            name="sparesUsed"
            placeholder="Enter "
            value={formData.sparesUsed}
            onChange={handleChange}
            type="text"
            className={Style.VMMidInput}
          />
          <p className={Style.PNMFormErrors}>
            {errors.sparesUsed}
          </p>
          </div>

          </div>

          <div className={style.BRVMSpareUsedCont} style={{marginTop:"30px"}}>

            <div className={style.BRVMSpareUsedHeading}>
              <FormHeadings text={"Remarks:"} />
            </div>
            <div className={style.BRVMInputCont}>
            <input
              name="remarks"
              placeholder="Enter Remark"
              value={formData.remarks}
              onChange={handleChange}
              type="text"
              className={Style.VMMidInput}
            />
            <p className={Style.PNMFormErrors}>
              {errors.remarks}
            </p>
            </div>

          </div>

          <div  style={{marginTop:"30px",display:"flex"}}>

            <div className={style.BRVMDocumentAttached}>
              <FormHeadings text={"Document Attached:"} />
            </div>
            <div style={{width:"35%"}}>
            <input
              name="documentAttached"
              onChange={handlefileChange}
              type="file"
              className={Style.BRDocument}
              style={{ height: '43px' }}
            />
            <p className={Style.PNMFormErrors}>
              {errors.documentAttached}
            </p>
            </div>

          </div>

          </>}
          {
            formData.equipmentType === "Batching Plant" && (
              <>
                <h3 className={Style.VMHeading} style={{marginBlock:"30px"}}>
                  Batching Plant - Equipment Condition Queries
                </h3>
              <div className={Style.VMTopFormMainContainer}>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"Weighing System:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                        <select
                          className={Style.VMTopInputField}
                          name="weighingSystem"
                          onChange={handleChange}
                          value={formData.weighingSystem}
                        >
                          <option value="" hidden>
                            Select option
                          </option>
                          <option value="Ok">Ok</option>
                          <option value="Not">Not Ok</option>
                          <option value="Not Applicable">Not Applicable</option>
                        </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.weighingSystem}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"Concrete Mixer System:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                        <select
                        type="text"
                        className={Style.VMTopInputField}
                        name="concreteMixerSystem"
                        onChange={handleChange}
                        value={formData.concreteMixerSystem}
                      >
                        <option value="" hidden>
                          Select option
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.concreteMixerSystem}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"Dust Collectors:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <select
                        type="text"
                        className={Style.VMTopInputField}
                        name="dustCollectors"
                        onChange={handleChange}
                        value={formData.dustCollectors}
                      >
                        <option value="" hidden>
                          Select option
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.dustCollectors}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"Storage Silos System:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <select
                        type="text"
                        className={Style.VMTopInputField}
                        name="storageSilasSystem"
                        onChange={handleChange}
                        value={formData.storageSilasSystem}
                      >
                        <option value="" hidden>
                          Select option
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.storageSilasSystem}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"Screw Conveyor:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <select
                        type="text"
                        className={Style.VMTopInputField}
                        name="screwConveyor"
                        onChange={handleChange}
                        value={formData.screwConveyor}
                      >
                        <option value="" hidden>
                          Select option
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.screwConveyor}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"Four Bin Feeder System:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <select
                        type="text"
                        className={Style.VMTopInputField}
                        name="fourBinFeederSystem"
                        onChange={handleChange}
                        value={formData.fourBinFeederSystem}
                      >
                        <option value="" hidden>
                          Select option
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.fourBinFeederSystem}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"PLC Control System:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <select
                        type="text"
                        className={Style.VMTopInputField}
                        name="PlcControlSytem"
                        onChange={handleChange}
                        value={formData.PlcControlSytem}
                      >
                        <option value="" hidden>
                          Select option
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.PlcControlSytem}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.BPTopInputHeading}>
                      <FormHeadings text={"Air Compressor System:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <select
                        type="text"
                        className={Style.VMTopInputField}
                        name="airCompressorSystem"
                        onChange={handleChange}
                        value={formData.airCompressorSystem}
                      >
                        <option value="" hidden>
                          Select option
                        </option>
                        <option value="Ok">Ok</option>
                        <option value="Not">Not Ok</option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.airCompressorSystem}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={Style.VMFormSeperator}></div>
          <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"1st Km/Hr Meter:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                      type="number"
                      placeholder="Enter Reading"
                      className={Style.VMTopInputField}
                      name="firstMeter"
                      onChange={handleChange}
                      value={formData.firstMeter}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.firstMeter}
                    </p>                    
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"2nd Km/Hr Meter:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                      type="number"
                      placeholder="Enter Reading"
                      className={Style.VMTopInputField}
                      onChange={handleChange}
                      value={formData.secondMeter}
                      name="secondMeter"
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.secondMeter}
                    </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Repeated Problem:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                      type="text"
                      className={Style.VMTopInputField}
                      placeholder=""
                      name="repeatedProblem"
                      onChange={handleChange}
                      value={formData.repeatedProblem}
                    >
                      <option value="">Select Yes/No</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                    <p className={Style.PNMFormErrors}>
                      {errors.repeatedProblem}
                    </p>                  
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Mechanic Name:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                      type="text"
                      className={Style.VMTopInputField}
                      placeholder="Mechanic name"
                      name="mechanic"
                      onChange={handleChange}
                      value={formData.mechanic}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.mechanic}
                    </p>
                    </div>
                  </div>
                </div>
              </div>
              
          </div>
          
          <div className={style.BRVMMidFormCont} style={{marginTop:"40px",marginBottom:"30px"}}>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Spare Cost:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <input
                      type="text"
                      placeholder="Enter Cost"
                      className={Style.VMMidInput}
                      name="spareCost"
                      onChange={(e)=>{
                        const value=e.target.value
                        if (!isNaN(value) ) {
                          handleChange(e);
                        }
                      }}
                      value={formData.spareCost}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.spareCost}
                    </p>
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Service Cost:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <input
                      type="text"
                      placeholder="Enter Cost "
                      className={Style.VMMidInput}
                      onChange={(e)=>{
                        const value=e.target.value
                        if (!isNaN(value) ) {
                          handleChange(e);
                        }
                      }}
                      name="serviceCost"
                      value={formData.serviceCost}
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.serviceCost}
                    </p>
                    </div>
                </div>
                <div className={style.BRVMMidFormContent}>
                    <div className={style.BRVMMidFormInputHeading}>
                      <FormHeadings text={"Total Cost:"} />
                    </div>
                    <div className={style.BRVMMidFormInputContainer}>
                    <input
                      type="Number"
                      className={Style.VMMidInput}
                      name="totalCost"
                      onChange={handleChange}
                      value={formData.spareCost && formData.serviceCost ? formData.totalCost:""}
                      readOnly
                    />
                    <p className={Style.PNMFormErrors}>
                      {errors.totalCost}
                    </p>
                    </div>
                </div>
             
          </div>
          <div className={style.BRVMSpareUsedCont}>

          <div className={style.BRVMSpareUsedHeading}>
            <FormHeadings text={"Spare Used:"} />
          </div>
          <div className={style.BRVMInputCont}>
          <input
            name="sparesUsed"
            placeholder="Enter "
            value={formData.sparesUsed}
            onChange={handleChange}
            type="text"
            className={Style.VMMidInput}
          />
          <p className={Style.PNMFormErrors}>
            {errors.sparesUsed}
          </p>
          </div>

          </div>

          <div className={style.BRVMSpareUsedCont} style={{marginTop:"30px"}}>

            <div className={style.BRVMSpareUsedHeading}>
              <FormHeadings text={"Remarks:"} />
            </div>
            <div className={style.BRVMInputCont}>
            <input
              name="remarks"
              placeholder="Enter Remark"
              value={formData.remarks}
              onChange={handleChange}
              type="text"
              className={Style.VMMidInput}
            />
            <p className={Style.PNMFormErrors}>
              {errors.remarks}
            </p>
            </div>

          </div>

          <div  style={{marginTop:"30px",display:"flex"}}>

            <div className={style.BRVMDocumentAttached}>
              <FormHeadings text={"Document Attached:"} />
            </div>
            <div style={{width:"35%"}}>
            <input
              name="documentAttached"
              onChange={handlefileChange}
              type="file"
              className={Style.BRDocument}
              style={{ height: '43px' }}
            />
            <p className={Style.PNMFormErrors}>
              {errors.documentAttached}
            </p>
            </div>

          </div>
              </>
            )
          }


          
          





                  
          <div className={Style.VMBtnContainer}>
            <button >UPLOAD</button>
          </div>

          </form>
    </div>
      
    </>
    
  );
};

export default BreakdownReport;
