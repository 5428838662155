import AppRoutes from "./Components/Navigation/Routes/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import React from "react";
import "./styles/variables.module.css";
import { ThemeProvider } from "./ThemeContext";

const App = () => {
  return (
    // <div>
    //   <p>You clicked {count} times</p>
    //   <button onClick={() => setCount(count + 1)}>Click me</button>
    // </div>
    <RecoilRoot>
      <ThemeProvider>
        <Router>
          <RecoilNexus />

          <AppRoutes />
        </Router>
      </ThemeProvider>
    </RecoilRoot>
    //  <Router>
    //      <AppRoutes />
    //  </Router>
  );
};

export default App;

//  <RecoilRoot>
//    <Router>
//      <RecoilNexus />
//    <Home/>
//        <AppRoutes />
//    </Router>
//  </RecoilRoot>
