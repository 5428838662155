/* eslint-disable no-unused-vars */
import React from "react";
import { useRecoilState } from "recoil";
import AssignWork from "./AssignWork";
import TransferWork from "./TransferWork";
import { workflowTab } from "../../../atoms";
const Workflow = () => {
  const [value, setValue] = useRecoilState(workflowTab) ;
  const handleChange = (newValue) => {
    setValue(newValue);
  };

//   return (
//     <>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           padding: "20px",
//           marginTop: "5px",
//         }}
//       >
//         <Box
//           sx={{
//             border: "1px solid var(--primary-color)",
//             width: "100%",
//             maxWidth: "400px", 
//           }}
//         >
//           <Tabs
//             id="controlled-tab-example"
//             value={value}
//             onChange={handleChange}
//             sx={{
//               "& .MuiTabs-indicator": {
//                 display: "none",
//               },
//               "& .MuiTab-root": {
//                 textTransform: "none",
//                 borderBottom: "none",
//                 borderLeft: "1px solid var(--primary-color)",
//                 minWidth: "200px",
//                 fontWeight: "bold",
//                 color: "var(--primary-color)",
//                 "&:first-of-type": {
//                   borderLeft: "none",
//                 },
//                 "&:last-of-type": {
//                   borderRight: "none",
//                 },
//               },
//               "& .Mui-selected": {
//                 backgroundColor: "var(--primary-color)", // Background color for selected tab
//                 color: "white",
//                 borderBottom: "1px solid var(--primary-color)", // Maintain border at the bottom of selected tab
//               },
//               "& .MuiTab-root:not(.Mui-selected)": {
//                 color: "var(--primary-color)", // Text color for non-selected tabs
//               },
//             }}
//           >
//             <Tab label="Assign Work" />
//             <Tab label="Transfer Work" />
//           </Tabs>
//         </Box>
//       </div>

//       <TabPanel value={value} index={0}>
//         <AssignWork />
//       </TabPanel>
//       <TabPanel value={value} index={1}>
//         <TransferWork/>
//       </TabPanel>
      
//     </>
//   );
// };

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`tabpanel-${index}`}
//       aria-labelledby={`tab-${index}`}
//       {...other}
//       style={{
//         width: "100%",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       {value === index && (
//         <Box p={3} style={{ textAlign: "center", width: "100%" }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }
const allTabs = [
  { label: "Assign Work", index: 0, component: <AssignWork /> },
  { label: "Transfer Work", index: 1, component:   <TransferWork/> },
];

return (
  <>
    <div className="containerDrawingPagesRotoSite">
      <ul className="tabsRotoSite" role="tablist">
        {allTabs.map((tab) => (
          <li
            key={tab.index}
            className={`tabRotoSite ${value === tab.index ? "active" : ""}`}
            role="tab"
            aria-selected={value === tab.index}
            aria-controls={`tabpanel-${tab.index}`}
            id={`tab-${tab.index}`}
  
            onClick={() => handleChange(tab.index)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
    </div>

    {allTabs.map((tab) => (
      <TabPanel key={tab.index} value={value} index={tab.index}>
        {tab.component}
      </TabPanel>
    ))}
  </>
  
);
};
function TabPanel(props) {
const { children, value, index, ...other } = props;

return (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
    className="tabpanel"
  >
    {value === index && <div className="tabpanel-content">{children}</div>}
  </div>
);
}
export default Workflow;
 