import React, { useState, useEffect, useMemo, useRef } from "react";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DrawingTableWrapper from "../../Components/DrawingTableWrapper";
import { Tabs, Tab } from "../../../Tabs/Tabv1";
import TableWrapper from "../../../../Utilities/TableWrapper";
import filter from "../../../../Images/taskFilter.svg";

import Eye from "../IconsSiteHead/Eye.svg";
import Download from "../IconsSiteHead/Download.svg";
import IssuedFromR0 from "./IssuedFromR0";
import CollectedfromR0 from "./CollectedfromR0";
import { siteId } from "../../../../atoms";
import { useRecoilState } from "recoil";
import { WebApimanager } from "../../../../WebApiManager";

const UploadtoSitelevel = () => {
  const webApi = new WebApimanager();
  const [currentView, setCurrentView] = useState("Upload to Sitelevel");
  const [site] = useRecoilState(siteId);
  const [uploadData, setUploadData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [typeSelected, setTypeSelected] = useState("");
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutSide = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilterDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutSide);

    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, [dropdownRef]);

  const handleType = (type) => {
    console.log("Folder Click");
    setTypeSelected(type);
  };

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
  };

  useEffect(() => {
    const fetchUploadData = async () => {
      try {
        const response = await webApi.get(
          `api/architect/pendingSiteHead/${site}?filterType=upload`
        );
        console.log("upload Data:", response.data);
        setUploadData(response.data.data);
      } catch (error) {
        console.error("Error fetching issued data:", error);
      }
    };

    fetchUploadData();
  }, [site]);

  const searchResult = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filterSerchData = uploadData.filter((item) => {
    const matchesSearch =
      item.drawingNo?.toLowerCase().includes(searchQuery) ||
      item.designDrawingConsultant?.role?.toLowerCase().includes(searchQuery) ||
      item.category?.category?.toLowerCase().includes(searchQuery) ||
      item.drawingTitle?.toLowerCase().includes(searchQuery);

    const matchesCategory = selectedCategory
      ? item.category?.category === selectedCategory
      : true;

    return matchesSearch && matchesCategory;
  });

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen((prev) => !prev);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setFilterDropdownOpen(false);
  };

  const categories = [
    ...new Set(uploadData.map((data) => data.category?.category)),
  ];
  const folderNames = [
    ...new Set(uploadData.map((data) => data.folderId?.folderName)),
  ];

  console.log("Unique Categories:", categories);
  console.log("Unique Folder Names:", folderNames);

  return (
    <TableWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "5px",
        }}
      >
        <Tabs
          value={currentView}
          onChange={handleTabViewChange}
          sx={{
            "& .MuiTabs-indicator": { backgroundColor: "var(--primary-color)" },
            "& .MuiTab-root": {
              textTransform: "none",
              color: "#131313",
              fontWeight: "bold",
            },
            "& .Mui-selected": { color: "#131313 !important" },
          }}
        >
          <Tab label="Upload to Sitelevel" value="Upload to Sitelevel" />
          <Tab label="Issued From R0" value="Issued From R0" />
          <Tab label="Collected from R0" value="Collected from R0" />
        </Tabs>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "5px",
          }}
        >
          <input
            placeholder="Search Drawing Lists"
            onChange={searchResult}
            style={{
              paddingLeft: "20px",
              border: "1px solid var(--primary-color)",
              width: "250px",
              height: "40px",
              marginRight: "10px",
            }}
          />
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon.Search
              style={{
                position: "absolute",
                right: "25px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#ACACAC",
              }}
            />
          </div>

          <div style={{ position: "relative" }}>
            <div onClick={toggleFilterDropdown} style={{ cursor: "pointer" }}>
              <img src={filter} alt="filter" />
            </div>
            {isFilterDropdownOpen && (
              <div
                ref={dropdownRef}
                style={{
                  position: "absolute",
                  background: "white",
                  borderRadius: "4px",
                  zIndex: 1000,
                  textAlign: "left",
                  marginTop: "20px",
                  marginLeft: "-30px",
                  padding: "15px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <span style={{ marginTop: "0px", display: "block" }}>
                  <span
                    style={{
                      cursor: "pointer",
                      padding: "5px 0",
                      position: "relative",
                    }}
                  >
                    <div
                      onClick={() => {
                        setTypeSelected("All");
                        setSelectedCategory("");
                        setFilterDropdownOpen(false);
                      }}
                    >
                      All
                    </div>
                  </span>
                </span>

                <span
                  style={{
                    cursor: "pointer",
                    padding: "5px 0",
                    position: "relative",
                  }}
                >
                  <div onClick={() => handleType("categories")}>Categories</div>
                  {typeSelected === "categories" && (
                    <div
                      style={{
                        position: "absolute",
                        background: "white",
                        borderRadius: "4px",
                        zIndex: 1000,
                        marginLeft: "-120px",
                        padding: "20px 15px",
                        left: 0,
                        width: "100%",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {categories.map((category) => (
                          <span
                            key={category}
                            onClick={() => {
                              handleCategorySelect(category);
                              setFilterDropdownOpen(false); // Close dropdown
                            }}
                            onMouseEnter={() => setHoveredCategory(category)}
                            onMouseLeave={() => setHoveredCategory(null)}
                            style={{
                              cursor: "pointer",
                              padding: "5px 0",
                              transition: "background-color 0.2s",
                              backgroundColor:
                                selectedCategory === category
                                  ? "#d0e1ff"
                                  : hoveredCategory === category
                                  ? "#b0c4ff"
                                  : "white",
                            }}
                          >
                            {category}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </span>

                <span style={{ marginTop: "10px", display: "block" }}>
                  <span
                    style={{
                      cursor: "pointer",
                      padding: "5px 0",
                      position: "relative",
                    }}
                  >
                    <div onClick={() => handleType("folders")}>Folders</div>
                    {typeSelected === "folders" && (
                      <div
                        style={{
                          position: "absolute",
                          background: "white",
                          borderRadius: "4px",
                          zIndex: 1000,
                          marginLeft: "-120px",
                          padding: "20px 15px",
                          left: 0,
                          width: "100%",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                      >
                        {folderNames.map((folder) => (
                          <span
                            key={folder}
                            onClick={() => {
                              handleCategorySelect(folder);
                              setFilterDropdownOpen(false);
                            }}
                            style={{
                              cursor: "pointer",
                              padding: "5px 0",
                              transition: "background-color 0.2s",
                              backgroundColor:
                                selectedCategory === folder
                                  ? "#d0e1ff"
                                  : hoveredCategory === folder
                                  ? "#b0c4ff"
                                  : "white",
                            }}
                            onMouseEnter={() => setHoveredCategory(folder)}
                            onMouseLeave={() => setHoveredCategory(null)}
                          >
                            {folder}
                          </span>
                        ))}
                      </div>
                    )}
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        {currentView === "Upload to Sitelevel" && (
          <DrawingTableWrapper
            tableHead={
              <tr>
                <th style={{ width: "5%" }}>S.No</th>
                <th style={{ width: "10%" }}>Drawing No</th>
                <th style={{ width: "10%" }}>Consultant</th>
                <th style={{ width: "10%" }}>Drawing category</th>
                <th style={{ width: "10%" }}>Drawing title</th>
                <th style={{ width: "13%" }}>Accepted Site Submission Date</th>
                <th style={{ width: "13%" }}>Due/Overdue</th>
                <th style={{ width: "10%" }}>Revision</th>
                <th style={{ width: "7%" }}>Action</th>
              </tr>
            }
            tableBody={
              filterSerchData.length > 0 ? (
                filterSerchData.map((uploadData, index) => (
                  <tr
                    key={uploadData._id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#FDF8F4" : "#F3F6F8",
                    }}
                  >
                    <td style={{ width: "5%", padding: "10px" }}>
                      {(index + 1).toString().padStart(3, "0")}
                    </td>
                    <td style={{ width: "10%", padding: "10px" }}>
                      {uploadData?.drawingNo}
                    </td>
                    <td style={{ width: "10%", padding: "10px" }}>
                      {uploadData?.designDrawingConsultant?.role}
                    </td>
                    <td style={{ width: "10%", padding: "10px" }}>
                      {uploadData?.category?.category}
                    </td>
                    <td style={{ width: "10%", padding: "10px" }}>
                      {uploadData?.drawingTitle}
                    </td>
                    <td style={{ width: "13%", padding: "10px" }}>
                      {uploadData?.acceptedSiteSubmissionDate
                        ? new Date(
                            uploadData.acceptedSiteSubmissionDate
                          ).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td style={{ width: "13%", padding: "10px" }}>
                      Final Submission Date
                    </td>
                    <td style={{ width: "10%", padding: "10px" }}>
                      {uploadData?.acceptedSiteHeadRevisions
                        ?.slice(-2)
                        .map((revisionItem) => revisionItem.revision)
                        .join(", ")}
                    </td>
                    <td style={{ width: "10%", padding: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          marginLeft: "30px",
                        }}
                      >
                        <img
                          src={Download}
                          alt="Download"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" style={{ textAlign: "center" }}>
                    No data found
                  </td>
                </tr>
              )
            }
          />
        )}
        {currentView === "Issued From R0" && (
          <IssuedFromR0
            searchQuery={searchQuery}
            selectedCategory={selectedCategory}
          />
        )}
        {currentView === "Collected from R0" && (
          <CollectedfromR0
            searchQuery={searchQuery}
            selectedCategory={selectedCategory}
          />
        )}
      </div>
    </TableWrapper>
  );
};

export default UploadtoSitelevel;
