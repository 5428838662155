import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../../../WebApiManager";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import { Dialog, DialogContent } from "@mui/material";
import styles from "../Form.module.css";
import CheckListTable from "../../ChecklistTable";
import Pen from "../../../../Images/Pen.svg";
import Tick from "../../../../Images/tickcircle.svg";
import Wrong from "../../../../Images/criclecross.svg";
import { RxCross1 } from "react-icons/rx"; // Assuming this is an icon for closing the dialog
import Correct from "../../../../Images/orangecorrect.svg"
// const FolderfileEditDialog = (props) => {
//   const {data} = props;
//   console.log(data,"selected rowdata of edit")
//   let webApi = new WebApimanager();

//   const [apiProcessing, setApiProcessing] = useState({
//     loader: false,
//     message: "Loading...",
//   });
  
//   const [hoveredRow, setHoveredRow] = useState(null);
//   const [descriptions, setDescriptions] = useState([]);
//   const [dialogOpen, setDialogOpen] = useState(false); 
//   const [editingDescription, setEditingDescription] = useState(null); 
//   const truncateText = (text, maxLength) => {
//     return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
//   };
  

  

//   useEffect(() => {
//     if (Array.isArray(data?.descriptionDetails) && data.descriptionDetails.length > 0) {
//       setDescriptions(data.descriptionDetails.description);
//     } else {
//       setDescriptions([]); 
//     }
//   }, [data]);
//   const handleEdit = (description) => {
//     setEditingDescription(description); 
//     setDialogOpen(true); 
//   };


//   const handleClose = () => {
//     setDialogOpen(false);
//     setEditingDescription(null); 
//   };

//   return (
//     <>
//       <Loader open={apiProcessing.loader} message={apiProcessing.message} />

//       <div>
//         <CheckListTable
//           CheckListTableHead={
//             <tr className="HeaderTableRow">
//               <th className="CheckListTableTableHead" style={{ width: "35%" }}>Descriptions</th>
//               <th className="CheckListTableTableHead" style={{ width: "5%" }}>Input Type</th>
//               <th className="CheckListTableTableHead" style={{ width: "10%" }}>Image</th>
//               <th className="CheckListTableTableHead" style={{ width: "10%" }}>Remarks</th>
//               <th className="CheckListTableTableHead" style={{ width: "10%" }}>Action</th>
//             </tr>
//           }
//           CheckListTableBody={
//             descriptions && descriptions.length > 0 ? (
//               descriptions.map((desc, index) => (
//                 <tr className="ValueTableRow" key={index} style={{ borderTop: index > 0 ? "1px solid #97979740" : "none", paddingBottom: '5px' }}>
//                   <td
//                     className="CheckListTableTableData"
//                     style={{ width: "35%" }}
//                     onMouseEnter={() => setHoveredRow(index)}
//                     onMouseLeave={() => setHoveredRow(null)}
//                   >
//                     {hoveredRow === index ? desc.description : truncateText(desc.description, 20)}
//                   </td>
//                   <td className="CheckListTableTableData" style={{ width: "5%" }}>Y/N</td>
//                   <td className="CheckListTableTableData" style={{ width: "10%" }}><img src={Wrong} alt="loading" /></td>
//                   <td className="CheckListTableTableData" style={{ width: "10%" }}><img src={Wrong} alt="loading" /></td>
//                   <td className="CheckListTableTableData" 
//                     style={{
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       width: "10%"
//                     }}
//                   >
//                     <img
//                       src={Pen}
//                       alt="Edit"
//                       loading="eager"
//                       style={{ marginRight: "10px", cursor: "pointer" }}
//                       onClick={() => handleEdit(desc)} 
//                     />
//                   </td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="5">Loading descriptions...</td>
//               </tr>
//             )
//           }
//         />
//         <div style={{ display: 'flex', justifyContent: 'right',marginRight:'10px' }}>
//           <button className={styles.submitButton}>
//             Submit
//           </button>
//         </div>
//       </div>

//       <Dialog
//         open={dialogOpen}
//         onClose={handleClose}
//         maxWidth="xs"
//         fullWidth
//         PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
//       >
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             backgroundColor: " #4D4D4D",
//             margin: "0px",
//             height: '50px'
//           }}
//         >
//           <p style={{ margin: "5px 180px", textAlign: "center", color: "white" }}>
//             Edit
//           </p>
//           <RxCross1
//             size={20}
//             style={{
//               marginLeft: "auto",
//               cursor: "pointer",
//               marginRight: "20px",
//               color: "white",
//             }}
//             onClick={handleClose}
//           />
//         </div>
//         <DialogContent>
//             <div style={{margin:'10px'}}>
//             <div style={{borderBottom:'1px solid #97979740'}}>
//             <div style={{display:'flex',flexDirection:'row',gap:'15px'}}>
//                 <p style={{fontFamily: 'Poppins',
//                 fontSize: '18px',
//                 fontWeight:'500'
//                 }}>Activity Name: 
//                 </p>
//                 <p style={{fontFamily: 'Poppins',
//                 fontSize: '16px',
//                 fontWeight:'300'
//                 }}> 
//                 Gypsum work
//                 </p>
//             </div>
//             <div style={{display:'flex',flexDirection:'row',gap:'18px'}}>
//                 <p style={{fontFamily: 'Poppins',
//                 fontSize: '18px',
//                 fontWeight:'500'
//                 }}>Description: </p>
//                 <p style={{fontFamily: 'Poppins',
//                 fontSize: '16px',
//                 fontWeight:'300'
//                 }}>{editingDescription}</p></div>
//             </div>
            // <div style={{display:'flex',flexDirection:'row',gap:'50px',marginTop:'10px'}}>
            //     <div style={{display:'flex',flexDirection:'column'}}>
            //         <label>Mandatory/Not :</label>
            //         <select style={{width:'150px',marginTop:'10px'}}>
            //             <option>Select Yes/No</option>
            //             <option value="Yes">Yes</option>
            //             <option value="No">No</option>
            //         </select>
            //     </div>
            //     <div style={{display:'flex',flexDirection:'column'}}>
            //         <label>Input Type :</label>
            //         <select style={{width:'150px',marginTop:'10px'}}>
            //             <option>Select Yes/No</option>
            //             <option value="Yes">Yes</option>
            //             <option value="No">No</option>
            //             <option value="NotApplicable">NA</option>
            //         </select>
            //     </div>
            // </div>
            // <div style={{display:'flex',flexDirection:'row',gap:'68px',marginTop:'10px'}}>
            //     <div style={{display:'flex',flexDirection:'column'}}>
            //         <label>Image :</label>
            //         <select style={{width:'150px',marginTop:'10px'}}>
            //             <option>Select Yes/No</option>
            //             <option value="Yes">Yes</option>
            //             <option value="No">No</option>
            //         </select>
            //     </div>
            //     <div style={{display:'flex',flexDirection:'column'}}>
            //         <label>Remarks :</label>
            //         <select style={{width:'150px',marginTop:'10px'}}>
            //             <option>Select Yes/No</option>
            //             <option value="Yes">Yes</option>
            //             <option value="No">No</option>
            //             <option value="NotApplicable">NA</option>
            //         </select>
            //     </div>
            // </div>

        //     <div className={styles.buttons}>
        // <button className={styles.noButton}   onClick={handleClose}>Cancle</button>
        // <button className={styles.submitButton}>Save</button>
        // </div>
        //     </div>
//         </DialogContent>
//       </Dialog>
//     </>
//   );
// };

// export default FolderfileEditDialog;
const FolderfileEditDialog = (props) => {
  const { data } = props;
  console.log(data,"selected rowdata of edit")
  let webApi = new WebApimanager();

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  
  const [hoveredRow, setHoveredRow] = useState(null);
  const [descriptions, setDescriptions] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false); 
  const [editingDescription, setEditingDescription] = useState(null); 
  const [selectedIndex, setSelectedIndex] = useState(null); 
  const [errorMessage, setErrorMessage] = useState("");
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  useEffect(() => {
    if (Array.isArray(data?.descriptionDetails) && data.descriptionDetails.length > 0) {
      setDescriptions(data.descriptionDetails);
    } else {
      setDescriptions([]); 
    }
  }, [data]);

  const handleEdit = (description, index) => {
    setEditingDescription(description); 
    setSelectedIndex(index); 
    setDialogOpen(true); 
  };

  const handleClose = () => {
    setDialogOpen(false);
    setEditingDescription(null); 
  };
  const updateDescription = (field, value) => {
    const updatedDescriptions = [...descriptions];
    updatedDescriptions[selectedIndex] = {
      ...updatedDescriptions[selectedIndex],
      [field]: value
    };
    setDescriptions(updatedDescriptions);
  };

  const handleSave = async (id,dno) => {
console.log(id,dno,"checking getting or not");
setApiProcessing({ loader: true, message: "Submitting..." });
    const descriptionFields = {
        image: editingDescription.image,
        inputType: editingDescription.inputType,
        mandatoryOrNot: editingDescription.mandatory, 
        Remarks: editingDescription.remarks
    };
    try {
      const  response = await webApi.put(`api/checklistDesign/updateDescription/${id}/dno/${dno}`, descriptionFields);
      if (response.status === 200) {
        setApiProcessing({ loader: false, message: "" });
        handleClose(); 
      } else {
        setApiProcessing({ loader: false, message: "" });
        setErrorMessage("Failed to submit. Please try again.");
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      setErrorMessage("An error occurred while submitting the form.");
      console.error(error);
    }

    handleClose();
};



  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />

      <div  >
      <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #979797' }}>
    <thead style={{height:"50px"}}>
      <tr>
      <th style={{ width: "45%" }}>Descriptions</th>
              <th  style={{ width: "10%" }}>Input Type</th>
              <th style={{ width: "10%" }}>Image</th>
              <th  style={{ width: "10%" }}>Remarks</th>
              <th  style={{ width: "10%" }}>Action</th>

      </tr>
    </thead>
    <tbody>
    {descriptions.length > 0 ? (
              descriptions.map((desc, index) => (
                <tr  key={index}
                //  style={{ borderTop: index > 0 ? "1px solid #97979740" : "none", paddingBottom: '5px' }}
                style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #979797' ,borderTop:  "1px solid #979797" , paddingBottom: '5px',marginTop:'0px' }}
                 >
                  <td
                    onMouseEnter={() => setHoveredRow(index)}
                    onMouseLeave={() => setHoveredRow(null)}
                    style={{width:'45%' }}
                  >
                    <span style={{ display: 'inline-block' }}>
        {hoveredRow === index ? desc.description : truncateText(desc.description, 20)} 
        {desc.mandatoryOrNot === 'Yes' && <span style={{ color: 'red', marginLeft: '5px' }}>*</span>}
    </span>
                  </td>
                  <td style={{ width: "10%" }}>
    {desc.inputType === "Not Applicable" ? (
        "Y/N/NA"
    ) : (
        desc.inputType
    )}
</td>
                  <td  style={{ width: "10%" }}>
                    {(desc.image === 'Yes')? (
                          <img src={Correct} alt="Correct" />
                      ) : desc.image === 'No'  || "Not Applicable" ? (
                          <img src={Wrong} alt="Wrong" />
                      ):(null)}
                    </td>
                  <td  style={{ width: "10%" }}>
                   
                    {(desc.image === 'Yes' )? (
                          <img src={Correct} alt="Correct" />
                      ) : desc.image === 'No' || "Not Applicable" ? (
                          <img src={Wrong} alt="Wrong" />
                      ):(null)}
                    </td>
                  <td style={{width: "10%"}}>
                    <img
                      src={Pen}
                      alt="Edit"
                      loading="eager"
                      style={{  cursor: "pointer", }}
                      onClick={() => handleEdit(desc, index)} 
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">Loading descriptions...</td>
              </tr>
            )}
    </tbody>
  </table>
        {/* <CheckListTable
          CheckListTableHead={
            <tr className="HeaderTableRow" >
              <th className="CheckListTableTableHead" style={{ width: "45%" }}>Descriptions</th>
              <th className="CheckListTableTableHead" style={{ width: "10%" }}>Input Type</th>
              <th className="CheckListTableTableHead" style={{ width: "10%" }}>Image</th>
              <th className="CheckListTableTableHead" style={{ width: "10%" }}>Remarks</th>
              <th className="CheckListTableTableHead" style={{ width: "10%" }}>Action</th>
            </tr>
          }
          CheckListTableBody={
            descriptions.length > 0 ? (
              descriptions.map((desc, index) => (
                <tr className="ValueTableRow" key={index}
                //  style={{ borderTop: index > 0 ? "1px solid #97979740" : "none", paddingBottom: '5px' }}
                style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #979797' ,borderTop:  "1px solid #979797" , paddingBottom: '5px',marginTop:'0px' }}
                 >
                  <td
                    className="CheckListTableTableData"
                    onMouseEnter={() => setHoveredRow(index)}
                    onMouseLeave={() => setHoveredRow(null)}
                    style={{width:'45%' }}
                  >
                    {hoveredRow === index ? desc.description : truncateText(desc.description, 20)}
                  </td>
                  <td className="CheckListTableTableData" style={{ width: "10%" }}>Y/N</td>
                  <td className="CheckListTableTableData" style={{ width: "10%" }}><img src={Wrong} alt="loading" /></td>
                  <td className="CheckListTableTableData" style={{ width: "10%" }}><img src={Wrong} alt="loading" /></td>
                  <td className="CheckListTableTableData" 
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "10%"
                    }}
                  >
                    <img
                      src={Pen}
                      alt="Edit"
                      loading="eager"
                      style={{ marginRight: "10px", cursor: "pointer", }}
                      onClick={() => handleEdit(desc, index)} 
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">Loading descriptions...</td>
              </tr>
            )
          }
        /> */}
        {/* <div style={{ display: 'flex', justifyContent: 'right', marginRight: '10px' }}>
          <button className={styles.submitButton} onClick={handleSubmit}>
            Submit
          </button>
        </div> */}
      </div>

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: '50px'
          }}
        >
          <p style={{ margin: "5px 180px", textAlign: "center", color: "white" }}>
            Edit
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <DialogContent>
          <div style={{ margin: '10px' }}>
            <div style={{ borderBottom: '1px solid #97979740' }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
                <p style={{
                  fontFamily: 'Poppins',
                  fontSize: '18px',
                  fontWeight: '500'
                }}>Activity Name:</p>
                <p style={{
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: '300'
                }}>{data?.activity.activity}</p>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '18px' }}>
                <p style={{
                  fontFamily: 'Poppins',
                  fontSize: '18px',
                  fontWeight: '500'
                }}>Description:</p>
                <p style={{
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: '300'
                }}>{editingDescription?.description}</p>
              </div>
            </div>
          
               <div style={{display:'flex',flexDirection:'row',gap:'50px',marginTop:'10px'}}>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <label>Mandatory/Not :</label>
                    <select
                  style={{ width: '150px', marginTop: '10px' }}
                  value={editingDescription?.mandatory}
                  onChange={(e) => setEditingDescription({ ...editingDescription, mandatory: e.target.value })}
                >
                        <option>Select Yes/No</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <label>Input Type :</label>
                    <select
                  style={{ width: '150px', marginTop: '10px' }}
                  value={editingDescription?.inputType}
                  onChange={(e) => setEditingDescription({ ...editingDescription, inputType: e.target.value })}
                >
                        <option>Select Yes/No</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="NotApplicable">NA</option>
                    </select>
                </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:'68px',marginTop:'10px'}}>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <label>Image :</label>
                    <select
                  style={{ width: '150px', marginTop: '10px' }}
                  value={editingDescription?.image}
                  onChange={(e) => setEditingDescription({ ...editingDescription, image: e.target.value })}
                >
                        <option>Select Yes/No</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <label>Remarks :</label>
                    <select
                  style={{ width: '150px', marginTop: '10px' }}
                  value={editingDescription?.remarks}
                  onChange={(e) => setEditingDescription({ ...editingDescription, remarks: e.target.value })}
                >
                        <option>Select Yes/No</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="NotApplicable">NA</option>
                    </select>
                </div>
            </div>
            <div className={styles.buttons}>
        <button className={styles.noButton}   onClick={handleClose}>Cancle</button>
        <button className={styles.submitButton} onClick={ () =>handleSave (data._id,editingDescription?.dNo)}>Save</button>
        </div>
            
          </div>
          
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FolderfileEditDialog;
