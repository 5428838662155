

import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import styles from "../Design/Design.module.css"
import { WebApimanager } from "../../../WebApiManager";
import Style from "./ApprovalView/ApprovalView.module.css"
import { Loader } from "../../../Widgets/notificationFeedbacks";
import CheckListTable from "../ChecklistTable";

const ApprovalEdit = (props) => {
  const { open, handleDialogClose, formData } = props;
  console.log(formData,"edit");
  let webApi = new WebApimanager();
  
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

 const [reason,setReason] = useState('')
 const [error,setError] = useState('')
 const [showForm,setShowForm] = useState(false)
 const [activeButton, setActiveButton] = useState(null);
 const [errorMessage,setErrorMessage] =useState("");
  const handleClose = () => {
    handleDialogClose();
    setShowForm("");
    setError("");
    setReason("");

  };
  

 const handleClick = (buttonType) => {
    setActiveButton(buttonType);
    
    if (buttonType === 'approve') {
      handleSubmit(formData._id);
      setShowForm(false); 
    } else {
      setShowForm(true);
    }
  };


const handleSubmit =async (id) => {

console.log(activeButton);
      let body ={}
      if(activeButton === 'approve'){
       body ={
       approvalStatus: "Approved"
    }
}else if(activeButton === 'redo'){
    if (!reason) {

        setErrorMessage("Please provide a reason for the redo.");
        return; 
      }
     body ={
         approvalStatus: "Redo",
    reDoReason: reason
     }

}
else if(activeButton === 'reject'){
    if (!reason) {

        setErrorMessage("Please provide a reason for the reject.");
        return; 
      }
     body ={

approvalStatus: "Rejected",
    rejectReason: reason
    }

}
   
setApiProcessing({ loader: true, message: "Submitting..." });
      try {
        const response = await webApi.put(`api/checklistDesign/updateApprovalStatus/${id}`,body);
        if(response.status === 200){
        
          setApiProcessing({ loader: false, message: "" });
          handleClose();
          setActiveButton("");
        //   window.location.reload();
        }
       
      } catch (error) {
        setApiProcessing({ loader: false, message: "" });
        setErrorMessage("An error occurred during submission");
        console.error("Error while submitting form:", error);
      }
    
  };
 

  return (
    <>
     
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: '50px',
          }}
        >
          <p
            style={{ margin: "5px 550px", textAlign: "center", color: "white" }}
          >
          View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "500px" }}
        >
          <div className={styles.overall}>
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
            <div>
              <p className={styles.heading}>
                {formData?.companyId ? ` ${formData.companyId.companyDetails.companyName}` : 'No Company Data'}
              </p>
            </div>
            <div className={styles.second}>
              <div className={styles.grid}>
                <p style={{fontSize:'16px',fontWeight:'500'}}>Activity Name</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.activity?.activity || 'No Activity Data'}</p>

                <p style={{fontSize:'16px',fontWeight:'500'}}>Checklist Type</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.checklistType || 'No Checklist Type'}</p>

                <p style={{fontSize:'16px',fontWeight:'500'}}>Form No</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.formNo || 'No Form No'}</p>

                <p style={{fontSize:'16px',fontWeight:'500'}}>Departments</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.department?.join(', ') || 'No Departments'}</p>
              </div>
            </div>
            {formData?.descriptionDetails?.length > 0 && (
            <div>
              <CheckListTable
                CheckListTableHead={
                  <tr className="HeaderTableRow">
                    <th className="CheckListTableTableHead" style={{ width: "5%" }}>
                      S.No
                    </th>
                    <th className="CheckListTableTableHead" style={{ width: "95%" }}>
                      Description
                    </th>
                  </tr>
                }
              
                CheckListTableBody={
                  
                  formData?.descriptionDetails?.map((desc, index) => (
                    <tr
                      key={index}
                      className="ValueTableRow"
                      style={{
                        borderBottom: "1px dotted #97979740",
                        paddingBottom: "8px",
                      }}
                    >
                      <td className="CheckListTableTableData" style={{ width: "5%" }}>
                        {index + 1}
                      </td>
                      <td className="CheckListTableTableData" style={{ width: "95%" }}>
                        {desc.description || 'No Description'}
                      </td>
                    </tr>
                  )) || (
                    <tr>
                      <td colSpan="2">No Description Details Available</td>
                    </tr>
                  )
                }
          
              />
            </div>
)}
            <div className={styles.last}>
  {formData?.assigningIncharge?.length > 0 && (
    <>
      <p>Signature Of</p>
      <div className={styles.assignednames}>
        {formData.assigningIncharge.map((incharge, index) => (
          <p key={index}>
            {incharge.department ? incharge.department : 'No Department'} -{" "}
            {incharge.role ? incharge.role : 'No Role'}
          </p>
        ))}
      </div>
    </>
  )}
</div>
<div className={Style.MainBtnContainer}>
      <button 
        onClick={() => handleClick('reject')} 
        style={activeButton === 'reject' ? { backgroundColor: 'var(--primary-color)', color: 'white' } : {}}
      >
        REJECT
      </button>   
      <button 
        onClick={() => handleClick('redo')} 
        style={activeButton === 'redo' ? { backgroundColor: 'var(--primary-color)', color: 'white' } : {}}
      >
        REDO
      </button>
         <button  style={activeButton === 'approve' ? { backgroundColor: 'var(--primary-color)', color: 'white' } : {}} 
          onClick={() => handleClick('approve')} 
         >APPROVE</button>
   </div>

   {showForm && <form  className={Style.FormContainer}>
        <label >Reason</label>
        <div className={Style.TextContainer}>
          <textarea 
          name="reason" 
          value={reason} 
          id="reason" 
          placeholder="Enter Reason"  
          cols={4} 
          className={Style.TextArea}
          onChange={(e)=>setReason(e.target.value)}
          ></textarea>
          {!reason && <p className={Style.Error}>{errorMessage}</p>}
        </div>
        <div className={Style.SubmitBtnContainer}>
          <button onClick={() => handleSubmit(formData._id)}>SUBMIT</button>
        </div>
   </form>}
         
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ApprovalEdit;
