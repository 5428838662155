import React, { useState, useEffect } from "react";
import "./Profile.css";
import { useRecoilState } from "recoil";
import { profileImageUrl, userInfo } from "../../atoms";
import defaultBanner from "../../Images/profileBanner.png";
import ChangeLoginPassword from "./profilePassword/ChangeLoginPassword";
import Theme from "./Theme/Theme";
import ConnectedDevices from "./ConnectedDevices/ConnectedDevices";
import DevicePermissions from "./DevicePermissions/DevicePermissions";
import AboutYourAccount from "./AboutYourAccount/AboutYourAccount";
import { WebApimanager } from "../../WebApiManager";
import { BACKEND_URL } from "../../Utilities/Constants";
import { MdEdit } from "react-icons/md";

const Profile = () => {
  const webApi = new WebApimanager();
  const [currentUserInfo] = useRecoilState(userInfo);
  const [isUploadingProfile, setIsUploadingProfile] = useState(false);
  const [isUploadingBanner, setIsUploadingBanner] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [profilePicBanner, setProfilePicBanner] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [activeProfilePage, setActiveProfilePage] = useState(
    "ChangeLoginPassword"
  );
  const userId = currentUserInfo?._id;

  // Fetch profile data
  const fetchProfileData = async () => {
    try {
      const response = await webApi.get("api/users/profilePicBanner");
      const { banner, profilePic } = response.data.data;
      setProfilePicBanner(banner);
      setProfilePic(profilePic);
    } catch (error) {
      console.log("Server error:", error);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleProfilePicUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("profilePic", file);

    setIsUploadingProfile(true);
    setUploadError("");

    try {
      await webApi.imagePut(`api/users/upload/${userId}`, formData);
      await fetchProfileData(); // Refetch data after upload
      setIsUploadingProfile(false);
    } catch (error) {
      setUploadError("Failed to upload profile picture");
      setIsUploadingProfile(false);
    }
  };

  const handleBannerUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("banner", file);

    setIsUploadingBanner(true);
    setUploadError("");

    try {
      await webApi.imagePut(`api/users/upload/${userId}`, formData);
      await fetchProfileData(); // Refetch data after upload
      setIsUploadingBanner(false);
    } catch (error) {
      setUploadError("Failed to upload banner");
      setIsUploadingBanner(false);
    }
  };

  const profilePageRender = () => {
    switch (activeProfilePage) {
      case "ChangeLoginPassword":
        return <ChangeLoginPassword />;
      case "Theme":
        return <Theme />;
      case "ConnectedDevices":
        return <ConnectedDevices />;
      case "DevicePermissions":
        return <DevicePermissions />;
      case "AboutYourAccount":
        return <AboutYourAccount />;
      default:
        return <ChangeLoginPassword />;
    }
  };

  return (
    <div className="py-5" style={{ backgroundColor: "#F3F3F3" }}>
      <div className="background-container" style={{ position: "relative" }}>
        <img
          src={
            profilePicBanner ? BACKEND_URL + profilePicBanner : defaultBanner
          }
          alt="Profile Banner"
          className="banner-image"
          style={{
            width: "100%",
            height: "300px",
            objectFit: "cover",
            cursor: "pointer",
          }}
        />
        <div
          className="overlay"
          onClick={() => document.getElementById("banner-upload").click()}
        >
          <MdEdit size={24} color="#fff" />
        </div>
        <input
          type="file"
          id="banner-upload"
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleBannerUpload}
        />
        {isUploadingBanner && (
          <div className="loading-overlay">Uploading...</div>
        )}
      </div>

      <div className="profile-container">
        {profilePic ? (
          <img
            src={`${BACKEND_URL}${profilePic}`}
            alt="Profile"
            className="overlay-image"
            style={{
              borderRadius: "50%",
              cursor: "pointer",
              width: "150px",
              height: "150px",
              objectFit: "cover",
              border: "5px solid white",
              position: "absolute",
              left: "calc(20% - 75px)",
              top: "65%",
              transform: "translateY(-50%)",
            }}
            onClick={() => document.getElementById("profile-upload").click()}
          />
        ) : (
          <img
            src="https://w7.pngwing.com/pngs/981/645/png-transparent-default-profile-united-states-computer-icons-desktop-free-high-quality-person-icon-miscellaneous-silhouette-symbol-thumbnail.png"
            alt="Default Profile"
            className="overlay-image"
            style={{
              borderRadius: "50%",
              cursor: "pointer",
              width: "150px",
              height: "150px",
              objectFit: "cover",
              border: "5px solid white",
              position: "absolute",
              left: "calc(20% - 75px)",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
        )}
        <div
          className="profile-overlay"
          style={{
            position: "absolute",
            top: "-75px",
            left: "calc(50% - 75px)",
            width: "150px",
            height: "150px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            color: "#fff",
            opacity: 0,
            transition: "opacity 0.3s",
            cursor: "pointer",
          }}
        >
          <MdEdit size={24} />
        </div>
        <input
          type="file"
          id="profile-upload"
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleProfilePicUpload}
        />
        {isUploadingProfile && (
          <div className="loading-overlay">Uploading...</div>
        )}
      </div>

      <div>
        <p className="profileSetting">Settings</p>
      </div>

      <div className="names-container">
        {["ChangeLoginPassword", "ConnectedDevices", "AboutYourAccount"].map(
          (page) => (
            <div
              key={page}
              className={`name-item ${
                activeProfilePage === page ? "active-item" : ""
              }`}
              onClick={() => setActiveProfilePage(page)}
              style={{
                cursor: "pointer",
                color: activeProfilePage === page ? "#E47331" : "black",
                marginRight: "20px",
                textTransform: "capitalize",
              }}
            >
              {page.replace(/([A-Z])/g, " $1").trim()}
            </div>
          )
        )}
      </div>

      <div
        style={{
          backgroundColor: "white",
          width: "85%",
          margin: "0px auto",
          padding: "30px",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        {uploadError && (
          <div
            style={{ color: "red", marginBottom: "10px", textAlign: "center" }}
          >
            {uploadError}
          </div>
        )}
        {profilePageRender()}
      </div>
    </div>
  );
};

export default Profile;
