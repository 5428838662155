import React, { useEffect, useState } from "react";
import "./Teams.css";
import DrawingTableWrapper from "../../../Drawing/Components/DrawingTableWrapper";
import { CiSearch } from "react-icons/ci";
import { WebApimanager } from "../../../../WebApiManager";

const Teams = ({ towerId }) => {
  const webApi = new WebApimanager();

  const [floorsData, setFloorsData] = useState([]);

  useEffect(() => {
    console.log("towerID for work", towerId);
    console.log("towerID for work floorsData floorsData", floorsData);

    const fetchSiteData = async () => {
      try {
        const SiteResponse = await webApi.get("api/sites/getAllSitesInfo");
        const siteData = SiteResponse.data;

        console.log(siteData, "Site details for Tower");

        const FindTower = siteData.sites.buildingsDetails.towers.find(
          (tower) => tower._id === towerId
        );

        console.log(FindTower, "Details for Tower FindTower");
      } catch (error) {
        console.error("Error fetching site data:", error);
      }
    };

    fetchSiteData();
  }, [towerId]);

  return (
    <div className="TeamsTabContainer">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="TeamsTitle">Drawing Lists</p>
        <div
          className="search-container"
          style={{
            position: "relative",
          }}
        >
          <input
            type="text"
            placeholder="Search Planner Lists"
            style={{
              width: "225px",
              height: "40px",
              borderRadius: "5px",
              border: "1px solid #E47331",
              paddingRight: "30px",
              borderColor: "#E47331",
            }}
          />
          <button className="searchTeams-button">
            <CiSearch />
          </button>
        </div>
      </div>
      <DrawingTableWrapper
        tableHead={
          <tr>
            <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
            <th style={{ width: "10%" }}>Floor</th>
            <th style={{ width: "10%" }}>No of Units</th>
            <th style={{ width: "10%" }}>Completed Units</th>
            <th style={{ width: "10%" }}>Not Completed Units</th>
          </tr>
        }
        tableBody={
          <>
            {floorsData.length > 0 ? (
              <>
                {console.log(floorsData)}
                {floorsData.map((floor, index) => (
                  <tr key={floor._id}>
                    <td style={{ paddingLeft: "10px" }}>{index + 1}</td>
                    <td>{floor.name || `Floor ${index + 1}`}</td>
                    <td>{floor.numUnits || 0}</td>
                    <td>{floor.completedUnits || 0}</td>
                    <td>{floor.notCompletedUnits || 0}</td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="5">No data available</td>
              </tr>
            )}
          </>
        }
      />
    </div>
  );
};

export default Teams;
