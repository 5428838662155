

import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../../../WebApiManager";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import { Dialog, DialogContent } from "@mui/material";
import styles from "../Form.module.css"


const ViewDialog = (props) => {
  const {  handleDialogClose, data} = props;
  console.log(data,"selected rowdata of view")
  let webApi = new WebApimanager();
  
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });




  const handleClose = () => {
    handleDialogClose();

  };

 
  



  

 

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
    
   <div className={styles.overall}>
   <p className={styles.viewP}>View</p>
   <div className={styles.borderdiv}>
    <div>
        <p className={styles.heading}>{data?.companyId?.companyDetails?.companyName}</p>
    </div>
    <div className={styles.second}>
       
    <div className={styles.ViewContent}>
                <p style={{fontSize:'14px',fontWeight:'500'}}>Activity Name</p>
                <p style={{fontSize:'14px',fontWeight:'500'}}>:</p>
                <p>{data?.activity?.activity || 'No Activity Data'}</p>

                <p style={{fontSize:'14px',fontWeight:'500'}}>Form No</p>
                <p style={{fontSize:'14px',fontWeight:'500'}}>:</p>
                <p>{data?.formNo || 'No Form No'}</p>

               
    </div>
    <div className={styles.ViewContent}>
                

                 <p style={{fontSize:'14px',fontWeight:'500'}}>Checklist Type</p>
                <p style={{fontSize:'14px',fontWeight:'500'}}>:</p>
                <p>{data?.checklistType || 'No Checklist Type'}</p>

                

                <p style={{fontSize:'14px',fontWeight:'500'}}>Departments</p>
                <p style={{fontSize:'14px',fontWeight:'500'}}>:</p>
                <p>{data?.department?.join(', ') || 'No Departments'}</p>  
    </div>
  </div>

<div>
<div>
  <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #979797' }}>
    <thead style={{height:"40px"}}>
      <tr>
        <th style={{width:'5%'}}>S.No</th>
        <th style={{width:'95%'}}>Description</th>
      </tr>
    </thead>
    <tbody>
      {data?.descriptionDetails.map((item, index) => (
        <tr key={item.dNo} style={{borderTop:'1px solid  #979797'}}  >
          <td style={{ borderLeft: '1px solid  #979797',width:'5%' }}>{index + 1}</td>
          <td style={{ borderRight: '1px solid  #979797',width:'95%'}}>{item.description}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

    </div>

    <div className={styles.last}>
        <p>Signature Of</p>
        <div className={styles.assignednames}>
  {data?.assigningIncharge.map((incharge, index) => (
    <p key={incharge._id}>
     {incharge.department} {incharge.role}
    </p>
  ))}
</div>

    </div>
    </div>
   </div>

  
   
    </>
  );
};

export default ViewDialog;
