
import styles from './Headline.module.css'

function Headline ({headlineText})  {
  return <>
  <div style={{display:'flex', justifyContent:'center'}}>
<h1 className={styles.Headline}>{headlineText}</h1>
  </div>
  </>;
};
export default Headline;
