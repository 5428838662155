import React, { useState, useEffect } from "react";
import ApprovedTable from "./ApprovedTable";
import RequestedTable from "./RequestedTable";
import PendingTable from "./PendingTable";
import Analysis from "../Components/Analysis";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { siteId, siteTab, userInfo } from "../../../atoms";
import SelectSite from "../../../Utilities/SelectSite";
import "./SiteLevel.css";
import DrawingDefaultICon from "../../../Images/DrawingDefault.svg";
import RegisterPage from "../RegisterPage"
const SiteLevel = () => {
  const webApi = new WebApimanager();
  const [value, setValue] = useRecoilState(siteTab);
  const [site] = useRecoilState(siteId);
  const [currentUserInfo] = useRecoilState(userInfo);
  const [tabEnableModules, setTabEnableModules] = useState({
    drawing: false,
    rfi: false,
    pending: false,
    register: false,
    analysis: false,
  });

  useEffect(() => {
    if (currentUserInfo?.permittedSites) {
      const enabledTabs = {
        drawing: false,
        rfi: false,
        pending: false,
        register: false,
        analysis: false,
      };

      currentUserInfo.permittedSites.forEach((site) => {
        const siteToSiteDetails =
          site.enableModules?.drawingDetails?.siteToSiteDetails;

        if (siteToSiteDetails) {
          enabledTabs.drawing =
            siteToSiteDetails.drawing || enabledTabs.drawing;
          enabledTabs.rfi = siteToSiteDetails.rfi || enabledTabs.rfi;
          enabledTabs.pending =
            siteToSiteDetails.pending || enabledTabs.pending;
          enabledTabs.register =
            siteToSiteDetails.register || enabledTabs.register;
          enabledTabs.analysis =
            siteToSiteDetails.analysis || enabledTabs.analysis;
        }
      });

      setTabEnableModules(enabledTabs);
    }
  }, [currentUserInfo]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  if (!site) {
    return <SelectSite />;
  }

  // Define available tabs
  const allTabs = [
    { label: "Drawing", index: 0, component: <ApprovedTable /> },
    // { label: "RFI", index: 1, component: <RequestedTable /> },
    { label: "Pending", index: 1, component: <PendingTable /> },
    { label: "Register", index: 2, component: <RegisterPage  type="SiteeLevel"/> },
    // { label: "Analysis", index: 4, component: <Analysis type="SiteLevel" /> },
  ];

  // Filter enabled tabs based on permissions
  const enabledTabs = allTabs.filter(
    (tab) => tabEnableModules[tab.label.toLowerCase()]
  );

  // Determine if all tabs are disabled
  const allTabsDisabled = enabledTabs.length === 0;

  return (
    <div className="containerSiteLevel">
      {allTabsDisabled ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <p
            style={{
              margin: "0 auto",
              color: "#E47331",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            You do not have the necessary permissions to view any SiteLevel
            tabs.
          </p>
          <p
            style={{
              margin: "0 auto",
              color: "#E47331",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Please contact your administrator for access rights.
          </p>
          {/* <img
            src={DrawingDefaultICon}
            alt="NoAccessIcon"
            style={{
              width: "350px",
              height: "350px",
              margin: "50px auto",
            }}
          /> */}
        </div>
      ) : (
        <>
          <ul className="tabsSiteLevel" role="tablist">
            {enabledTabs.map((tab) => (
              <li
                key={tab.index}
                className={`tabSiteLevel ${
                  value === tab.index ? "active" : ""
                }`}
                role="tab"
                aria-selected={value === tab.index}
                aria-controls={`tabpanel-${tab.index}`}
                id={`tab-${tab.index}`}
                onClick={() => handleChange(tab.index)}
              >
                {tab.label}
              </li>
            ))}
          </ul>

          {enabledTabs.map((tab) => (
            <TabPanel key={tab.index} value={value} index={tab.index}>
              {tab.component}
            </TabPanel>
          ))}
        </>
      )}
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      className="tabpanelSiteLevel"
    >
      {value === index && <div className="tabpanel-content">{children}</div>}
    </div>
  );
}

export default SiteLevel;
