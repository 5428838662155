
import React, { useState, useEffect } from "react";
import ROtoSiteLevel from "./ROtoSiteLevel/ROtoSiteLevel";
import ArchitectureToRo from "./ArichtectureToRo/ArchitectureToRo";
import SiteLevel from "./SiteLevel/SiteLevel";
import { useRecoilState } from "recoil";
import { drawingTab, registerAtom, siteId, userInfo } from "../../atoms";
import SitesDropdown from "../../Utilities/SitesDropdown";
import { WebApimanager } from "../../WebApiManager";
import Options from "./Options/Options";
import Sitehead from "./SITEHEAD/Sitehead";
import { Menu, MenuItem, Button } from "@mui/material";

function Drawing() {
  let webApi = new WebApimanager();
  const [value, setValue] = useRecoilState(drawingTab);
  const [site, setSite] = useRecoilState(siteId);
  const [register, setRegister] = useRecoilState(registerAtom);
  const [sites, setDatasites] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [tabEnableModules, setTabEnableModules] = useState({
    architectureToRo: false,
    ro: false,
    siteHead:false,
    siteToSite: false,
  });
  const tabNames = ["ARCHITECT >", "RO >", "SITEHEAD >", "SITELEVEL >", "OPTIONS >"];
  useEffect(() => {
    console.log("currentUserInfo Drawing.js file", currentUserInfo);

    const enabledTabs = {
      architectureToRo: false,
      ro: false,
      siteHead: false,
      options: false,
      siteToSite: false,
    };

    currentUserInfo?.permittedSites?.forEach((siteObj) => {
      const { architectureToRo, ro, siteHead, options, siteToSite } =
        siteObj.enableModules?.drawingDetails || {};

      if (architectureToRo) enabledTabs.architectureToRo = true;
      if (ro) enabledTabs.ro = true;
      if (siteHead) enabledTabs.siteHead = true;
      if (options) enabledTabs.options = true;
      if (siteToSite) enabledTabs.siteToSite = true;
    });

    setTabEnableModules(enabledTabs);
  }, [currentUserInfo]);

  const handleSiteChange = async (e) => {
    setSite(e.target.value);
    setRegister([]);
    setApiProcessing({
      loader: true,
      message: "Loading...",
    });

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setApiProcessing({
        loader: false,
        message: "",
      });
    } catch (error) {
      console.error("Error occurred while changing the site:", error);
      setApiProcessing({
        loader: false,
        message: "Failed to load site data",
      });
    }
  };

  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setAnchorEl(null); // Close the dropdown after selecting a tab
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const allTabsDisabled =
    !tabEnableModules.architectureToRo &&
    !tabEnableModules.ro &&
    !tabEnableModules.siteHead &&
    !tabEnableModules.siteToSite;

    return (
      <div style={{ margin: "20px", position: 'relative' }}>
        {/* Dropdown button and SitesDropdown section */}
        <div style={{ position: 'absolute', paddingTop: '25px', top: '5px' }}>
          <Button
              onClick={handleMenuOpen}
              style={{
                color: "#131313",
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
            >
              {tabNames[value]}
          </Button>
          <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "tab-menu",
              }}
            >
              {tabEnableModules.architectureToRo && (
                <MenuItem onClick={(e) => handleChange(e, 0, "ARCHITECT >")}>ARCHITECT</MenuItem>
              )}
              {tabEnableModules.ro && (
              <MenuItem onClick={(e) => handleChange(e, 1, "RO >")}>RO</MenuItem>
              )}
               {tabEnableModules.siteHead && (

              <MenuItem onClick={(e) => handleChange(e, 2, "SITEHEAD >")}>SITEHEAD</MenuItem>
               )}
              {tabEnableModules.siteToSite && (
                <MenuItem onClick={(e) => handleChange(e, 3, "SITELEVEL >")}>SITELEVEL</MenuItem>
              )}
               {tabEnableModules.options && (
              <MenuItem onClick={(e) => handleChange(e, 4, "OPTIONS >")}>OPTIONS</MenuItem>
               )}
          </Menu>
        </div>
        <div style={{ position: 'absolute', paddingTop: '25px', right: 0 }}>
          {!allTabsDisabled && value !== 4 && (
            <div style={{ marginLeft: "auto"}}>
              <SitesDropdown
                siteId={site}
                handleSiteChange={handleSiteChange}
                sites={sites}
                defaultValue={"Select Site"}
              />
            </div>
          )}
        </div>
    
        {/* Tab panel section */}
        <div style={{ marginTop: "20px" }}>
          {allTabsDisabled ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "#E47331",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              You do not have the necessary permissions to view any drawing tabs.
              <br />
              Please contact your administrator for access rights.
            </div>
          ) : (
            <>
              {tabEnableModules.architectureToRo && (
                <TabPanel value={value} index={0}>
                  <ArchitectureToRo />
                </TabPanel>
              )}
               {tabEnableModules.ro && (
              <TabPanel value={value} index={1}>
                <ROtoSiteLevel />
              </TabPanel>
               )}
                {tabEnableModules.siteHead && (
              <TabPanel value={value} index={2}>
                <Sitehead />
              </TabPanel>
                )}
                {tabEnableModules.siteToSite && (
              <TabPanel value={value} index={3}>
                <SiteLevel />
              </TabPanel>
                )}
                  {tabEnableModules.options && (
              <TabPanel value={value} index={4}>
                <Options />
              </TabPanel>
                  )}
            </>
          )}
        </div>
      </div>
    );
    
}


    function TabPanel(props) {
      const { children, value, index, ...other } = props;
    
      return (
        <div
          className="tabpanel"
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          {...other}
          style={{ display: value === index ? "block" : "none"}}
        >
          {value === index && <div style={{ width: "100%" }}>{children}</div>}
        </div>
      );
    }
    
    export default Drawing;


