import React from "react";
import * as Icon from "react-bootstrap-icons";
import DrawingTableWrapper from "../../Components/DrawingTableWrapper";
import filter from "../../../../Images/taskFilter.svg";
import Eye from "../IconsSiteHead/Eye.svg";
import Download from "../IconsSiteHead/Download.svg";
import RegisterPage from "../../RegisterPage";

const Register = () => {
  return (
    <div style={{ margin: "5px", backgroundColor: "white", padding: "20px" }}>
      <RegisterPage filter="table" />
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <p
            style={{
              color: "#303030",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Drawing Register
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            placeholder="Search Drawing Lists"
            style={{
              paddingLeft: "20px",
              border: "1px solid var(--primary-color)",
              width: "250px",
              height: "40px",
              marginRight: "10px",
            }}
          />
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon.Search
              style={{
                position: "absolute",
                right: "25px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#ACACAC",
              }}
            />
          </div>
          <div>
            <img src={filter} alt="filter" style={{ cursor: "pointer" }} />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <DrawingTableWrapper
          tableHead={
            <tr>
              <th style={{ width: "5%" }}>S.No</th>
              <th style={{ width: "10%" }}>Drawing No</th>
              <th style={{ width: "10%" }}>Design Consultant</th>
              <th style={{ width: "10%" }}>Drawing Category</th>
              <th style={{ width: "10%" }}>Drawing Title</th>
              <th style={{ width: "13%" }}>R0 Submission Date</th>
              <th style={{ width: "13%" }}>Due/Overdue</th>
              <th style={{ width: "10%" }}>Revision</th>
              <th style={{ width: "7%" }}>Action</th>
            </tr>
          }
          tableBody={
            <tr>
              <td style={{ width: "5%" }}>1</td>
              <td style={{ width: "10%" }}>Drawing No</td>
              <td style={{ width: "10%" }}>Design Consultant</td>
              <td style={{ width: "10%" }}>Drawing Category</td>
              <td style={{ width: "10%" }}>Drawing Title</td>
              <td style={{ width: "13%" }}>Accepted Site Submission Date</td>
              <td style={{ width: "13%" }}>Final Submission Date</td>
              <td style={{ width: "10%" }}>Revision</td>
              <td style={{ width: "10%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginLeft: "30px",
                  }}
                >
                  <img src={Eye} alt="View" style={{ cursor: "pointer" }} />
                  <img
                    src={Download}
                    alt="Download"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </td>
            </tr>
          }
        />
      </div> */}
    </div>
  );
};

export default Register;
