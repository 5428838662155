import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { Button } from "react-bootstrap";
import {
  userInfo,
  siteId,
} from "../../../atoms";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import {Tabs, Tab} from '../../Tabs/Tabv1'

import Pen from "../../../Images/Pen.svg";
import Eye from "../../../Images/Eye.svg";
import SelectSite from "../../../Utilities/SelectSite";
import AddActivity from "../Design/AddActivity";
import Create from "../Design/Create";
import ViewDialog from "../Design/ViewDialog";
import AddDescriptions from "../Design/AddDescriptions";
import styles from "../Design/Design.module.css";
import CheckListTable from "../ChecklistTable";
import TableWrapper from "../../../Utilities/TableWrapper";
import AddLevel from "./AddLevel";
import Addtemporary from "./Addtemporary";
import ViewWorkflow from "./ViewWorkflow";
import AddTempView from "./AddTempView";
const Workflow = () => {
  let webApi = new WebApimanager();
  const[userinfo,setUserInfo] =useRecoilState(userInfo);
  const [site, setSite] = useRecoilState(siteId);
  const [searchInput, setSearchInput] = useState('');
  const [open, setOpen] = useState(false);
  const [temparoryopen,settemparoryOpen] = useState(false);
  const [viewopen, setviewOpen] = useState(false);
  const [tempViewOpen,setTempViewOpen]=useState(false)
  const [levelEdit,setLevelEdit] = useState(false)
  const [tempEdit,setTempEdit] = useState(false)
  const [formData, setFormData] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [error,setError] = useState("");
  const [workflowData,setWorkflowData] =useState([]);
  const [tempData,setTempData] =useState([]);
  const [currentView, setCurrentView] = useState("level");
  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
    setSearchValue("");
    setSearchActive(false);
  };
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const handleClickOpen = () => {
    if(currentView === "level"){
    setOpen(true);
    } else{
        settemparoryOpen(true);
    }
  };

  const handleDialogClose = () => {
    if(currentView === "level"){
      setOpen(false);
      } else{
          settemparoryOpen(false);
      };
  };

  const handleEdit = (item) => {
    console.log(item,"item data")
    setFormData(item);
    if(currentView === "level"){
      setOpen(true);
      setLevelEdit(true)
      } else{
          settemparoryOpen(true);
          setTempEdit(true)
      }
  };

  const handleView = (item) => {
    // setViewData(item);
    // setviewOpen(true);
    if(currentView === "level"){
      setviewOpen(true);
      } else{
          setTempViewOpen(true);
      };
  };

  const handleViewClose = () => {
    if(currentView === "level"){
      setviewOpen(false);
      } else{
          setTempViewOpen(false);
      };
  };
  const fetchData = async () => {
    try {
      let response = await webApi.get(`api/checklistWorkFlow`);
      const checklistData = response?.data?.data?.checklistWorkFlows; 
      if (checklistData && checklistData.length > 0) {
        setWorkflowData(checklistData);
      } else {
        console.error("Checklist data not found in response.data.data.checklistWorkFlows");
      }
    } catch (error) {
      console.error("Error fetching checklist data", error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  
  const fetchtempData = async () => {
    try {
      let response = await webApi.get("api/checklistTemporary");
      const checklistData = response?.data?.data?.checklists; 
      if (checklistData && checklistData.length > 0) {
        setTempData(checklistData);
      } else {
        console.error("Checklist data not found in response.data.data.checklistWorkFlows");
      }
    } catch (error) {
      console.error("Error fetching checklist data", error);
    }
  };
  
  useEffect(() => {
    fetchtempData();
  }, []);
  
    
  if (site === "") {
    return <SelectSite />;
  }

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <Tabs
                  value={currentView}
                  onChange={handleTabViewChange}
                >
                  <Tab label="Level" value="level" />
                  <Tab label="Temporary" value="temporary" />
                </Tabs>
              </div>
              <div>
             
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          position: "relative",
                          width: "250px",
                          paddingLeft: "5px",
                          height: "50px",
                        }}
                      >
                        <input
                          placeholder="Search"
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                          style={{
                            paddingLeft: "20px",
                            border: "1px solid var(--primary-color)",
                            width: "250px",
                            height: "40px",
                          }}
                        />
                        <Icon.Search
                          style={{
                            position: "absolute",
                            right: "15px",
                            top: "40%",
                            transform: "translateY(-50%)",
                            color: "#ACACAC",
                          }}
                        />
                      </div>
                      <Button
                        style={{
                          height: "40px",
                          marginLeft: "10px",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "var(--primary-color)",
                          border: "1px solid var(--primary-color)",
                          borderRadius: "0px",
                        }}
                        onClick={handleClickOpen}
                      >
                        <Icon.PlusCircle
                          size={20}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        Add New
                      </Button>
                    </div>
                    <div>
                    </div>
</div>
            </div>

            {currentView === "level" && (
  <CheckListTable
    CheckListTableHead={
      <tr className="HeaderTableRow">
        <th className="CheckListTableTableHead" style={{width: "25%"}}>S.No</th>
        <th className="CheckListTableTableHead" style={{width: "25%"}}>Department</th>
        <th className="CheckListTableTableHead" style={{width: "25%"}}>No of Levels</th>
        <th className="CheckListTableTableHead" style={{width: "25%"}}>Action</th>
      </tr>
    }
    CheckListTableBody={
      workflowData && workflowData.length > 0 ? (
        workflowData.map((item, index) => (
          <tr
            className="ValueTableRow"
            key={item._id}
            style={{
              backgroundColor: index % 2 === 0 ? 'var(--table-oddrow-color)' : 'var(--table-evenrow-color)',
            }}
          >
            <td className="CheckListTableTableData" style={{ width: "25%" }}>{index + 1}</td>
            <td className="CheckListTableTableData" style={{ width: "25%" }}>
              {item.levelsInfo.length > 0 ? item.levelsInfo[0].department : "N/A"}
            </td>
            <td className="CheckListTableTableData" style={{ width: "25%" }}>
              {item.levelsInfo.length}
            </td>
          
            <td
              className="CheckListTableTableData"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "25%",
              }}
            >
              <img
                src={Pen}
                loading="eager"
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={() => handleEdit(item)}
              />
              <img
                src={Eye}
                loading="eager"
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={() => handleView(item)}
              />
            </td>
          </tr>
        ))
      ) : (
        <tr className="ValueTableRow">
          <td colSpan={8} style={{ textAlign: "center" }}>Loading .....</td>
        </tr>
      )
    }
  />
)}

{currentView === "temporary" && (
  <CheckListTable
    CheckListTableHead={
      <tr className="HeaderTableRow">
        <th className="CheckListTableTableHead" style={{width: "5%"}}>S.No</th>
        <th className="CheckListTableTableHead" style={{width: "20%"}}>Employee on leave</th>
        <th className="CheckListTableTableHead" style={{width: "20%"}}>From Date</th>
        <th className="CheckListTableTableHead" style={{width: "20%"}}>To Date</th>
        <th className="CheckListTableTableHead" style={{width: "20%"}}>Assigned to</th>
        <th className="CheckListTableTableHead" style={{width: "15%"}}>Action</th>
      </tr>
    }
    CheckListTableBody={
      tempData.length > 0 ? (
        tempData.map((item, index) => (
          <tr
            className="ValueTableRow"
            key={item._id}
            style={{
              backgroundColor: index % 2 === 0 ? 'var(--table-oddrow-color)' : 'var(--table-evenrow-color)',
            }}
          >
            <td className="CheckListTableTableData" style={{ width: "5%" }}>{index + 1}</td>
            <td className="CheckListTableTableData" style={{ width: "20%" }}>
              {item.user ? item.user.firstName : "N/A"}
            </td>
            <td className="CheckListTableTableData" style={{ width: "20%" }}>
              {item.fromDate ? new Date(item.fromDate).toLocaleDateString() : "N/A"}
            </td>
            <td className="CheckListTableTableData" style={{ width: "20%" }}>
              {item.toDate ? new Date(item.toDate).toLocaleDateString() : "N/A"}
            </td>
            <td className="CheckListTableTableData" style={{ width: "20%" }}>
              {item.allocateUser ? item.allocateUser.firstName : "N/A"}
            </td>
            <td
              className="CheckListTableTableData"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
              }}
            >
              <img
                src={Pen}
                loading="eager"
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={() => handleEdit(item)}
              />
              <img
                src={Eye}
                loading="eager"
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={() => handleView(item)}
              />
            </td>
          </tr>
        ))
      ) : (
        <tr className="ValueTableRow">
          <td colSpan={8} style={{ textAlign: "center" }}>
            Loading .....
          </td>
        </tr>
      )
    }
  />
)}

          </>
        }
      />
    {<>
    <AddLevel
        handleDialogClose={handleDialogClose}
        open={open}
        existingData={formData}
        levelEdit={levelEdit}
      />
      
      <ViewWorkflow
        handleDialogClose={handleViewClose}
        open={viewopen}
        formData={viewData}

      />
    </>}
    { <>
    <Addtemporary
       handleDialogClose={handleDialogClose}
       open={temparoryopen}
       existingData={tempData}
       tempEdit={tempEdit}
       />
    <AddTempView handleDialogClose={handleViewClose}
       open={tempViewOpen}
       existingData={tempData}/>
    
    </>}
      
      
   
    </>
  );
};

export default Workflow;
 