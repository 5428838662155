/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dialog } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { AiFillPlusCircle } from "react-icons/ai";
import { WebApimanager } from "../../../WebApiManager";
import styles from "./AssetCodeCreation.module.css"


const AddAssetcode = ({ open,close }) => {
    let webApi = new WebApimanager();
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [checklistData,setCheckListData] = useState([]);
    const [filteredFormNos, setFilteredFormNos] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
    const [formData, setFormData] = useState({
        type: "",
        assetCode: "",
        Name: "",
        documents: [],
        description: [],
        queries: [],
           formNo: "",
    activityName: ""
      });
 
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
        
    //     // Update the value of the field the user interacted with
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       [name]: value,
    //     }));
    
    //     // Handle logic when formNo changes
    //     if (name === "formNo") {
    //       const selectedForm = checklistData.find((item) => item.formNo === value);
    //       if (selectedForm) {
    //         setFormData((prevData) => ({
    //           ...prevData,
    //           activityName: selectedForm.activity?.activity || "", // Auto-set activityName based on selected formNo
    //         }));
    //       } else {
    //         setFormData((prevData) => ({
    //           ...prevData,
    //           activityName: "", // Reset activityName if formNo doesn't match any data
    //         }));
    //       }
    //     }
    
    //     // Handle logic when activityName changes
    //     if (name === "activityName") {
    //       const selectedActivity = checklistData.find((item) => item.activity?.activity === value);
    //       if (selectedActivity) {
    //         setFormData((prevData) => ({
    //           ...prevData,
    //           formNo: selectedActivity.formNo || "", // Auto-set formNo based on selected activityName
    //         }));
    //       } else {
    //         setFormData((prevData) => ({
    //           ...prevData,
    //           formNo: "", // Reset formNo if activityName doesn't match any data
    //         }));
    //       }
    //     }
    //   };
    const handleChange = (e) => {
        setErrorMessage("");
        setErrors("");
        const { name, value } = e.target;
      
        // Update the field the user interacted with
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      
        // Handle logic when formNo changes
        if (name === "formNo") {
          const selectedForm = checklistData.find((item) => item._id === value);
      
          if (selectedForm) {
            setFormData((prevData) => ({
              ...prevData,
              formNo: selectedForm._id, // Pass the form object's _id
              activityName: selectedForm.activity?._id || "", // Set activity ID if available
            }));
          } else {
            setFormData((prevData) => ({
              ...prevData,
              activityName: "", // Reset activityName if formNo doesn't match
            }));
          }
        }
      
        // Handle logic when activityName changes
        if (name === "activityName") {
          const selectedActivity = checklistData.find((item) => item.activity?._id === value);
      
          if (selectedActivity) {
            setFormData((prevData) => ({
              ...prevData,
              activityName: selectedActivity.activity._id, // Pass the activity's _id
              formNo: selectedActivity._id || "", // Auto-set formNo if available
            }));
          } else {
            setFormData((prevData) => ({
              ...prevData,
              formNo: "", // Reset formNo if activityName doesn't match
            }));
          }
        }
      };
      
      
    const handleClose =() =>{
        close();
        setFormData({});

      }
      const handleQueryChange = (index, value) => {
        const updatedQueries = [...formData.queries];
        updatedQueries[index] = value;
        setFormData((prevData) => ({
          ...prevData,
          queries: updatedQueries,
        }));
      };
      const addQuery = () => {
        setFormData((prevData) => ({
          ...prevData,
          queries: [...(prevData.queries || []), ""],
        }));
      };
      const removeQuery = (index) => {
        const updatedQueries = formData.queries.filter((_, i) => i !== index);
        setFormData((prevData) => ({
          ...prevData,
          queries: updatedQueries,
        }));
      };
        const handleSubmit = async () => {
    console.log("submit clicked");
    let valid = true;
    const newErrors = {};

    if (!formData.type.trim()) {
      newErrors.type = "Type is required";
      valid = false;
    }
    if (!formData.assetCode.trim()) {
      newErrors.assetCode = "AssetCode is required";
      valid = false;
    }
    if (!formData.Name.trim()) {
      newErrors.Name = "Name is required";
      valid = false;
    }
    if (formData.queries.length === 0) {
        newErrors.queries = "Please add at least one document for the vehicle type.";
        valid = false;
      }
    
    formData.queries.forEach((query, index) => {
      if (query.trim() === "") {
        newErrors[`query${index}`] = "Please fill out this field";
        valid = false;
      }
    });
    console.log(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      console.log("exiting");
      return; 
    }
    console.log("Form submitted:", formData);
    const tempFormData = {
      type: formData.type,
      assetCode: formData.assetCode,
      name: formData.Name,
      documents: formData.queries,
      activity:formData.activityName,
      formNo:formData.formNo,
    };
    try {
      console.log("posting");
      const response = await webApi.post("api/pnms/assetCode", tempFormData);

      if (response.status === 201) {
        handleClose();
        window.location.reload();
      } else if (response.status === 200 || response.status === 400) {
       
        if (
          response.data &&
          Array.isArray(response.data) &&
          response.data.length > 0
        ) {
          setErrorMessage(response.data[0]); 
   
        } else {
       
          setErrorMessage("Asset code and name must be unique");
  
        }
      } else {
        
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data) &&
        error.response.data.length > 0
      ) {
        setErrorMessage(error.response.data[0]); 
      } else {
        setErrorMessage("Failed to submit data.");
      }
    }
  };
  const fetchData = async () => {
    try {
      let response = await webApi.get(
        `api/checklistDesign/pnmDepartmentChecklistDesigns`
      );
   
        setCheckListData(response.data?.data || []); 
   
      console.log(checklistData,"pnm responsedata of designform")
    } catch (error) {
      console.error("Error fetching checklist data", error);
    }
  };
  
  useEffect(() => {
      fetchData();
  }, []);

  useEffect(() => {
    if (checklistData.length > 0) {
      setFilteredFormNos(checklistData.map((item) => item.formNo));
      setFilteredActivities(checklistData.map((item) => item.activity?.activity));
    }
  }, [checklistData]);
return(

<Dialog
open={open}
onClose={handleClose}
maxWidth="md" 
fullWidth 
PaperProps={{
  style: {
    height: "715px",
    width: "1000px", 
    maxWidth: "none",
  },
}}
>
<div className={styles.AddNewPnMFormDailogHeadingContainer}>
  <h3 className={styles.AddNewPnMFormDailogHeading}> Asset Code </h3>
  <RxCross1
    size={20}
    style={{
      marginLeft: "auto",
      cursor: "pointer",
      marginRight: "25px",
      color: "white",
    }}
    onClick={handleClose}
  />
</div>

<section className={styles.AssetCodeCreationDialogContainer}>
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      gap: "75px",
    }}
  >
    {/* Type Field */}
    <div style={{ flex: 1 }}>
      <label
        htmlFor="type"
        className={styles.AssetCodeCreationInputLabel}
      >
        Type
        <span style={{ color: "black" }}>*</span>
      </label>

      <select
        id="type"
        name="type"
        value={formData.type}
        onChange={handleChange}
        
        style={{
          width: "100%",
          backgroundColor: "#F3F3F3",
          height: "50px",
          marginTop:"5px"
        }}
      >
        <option value="">Select Type</option>
        <option value="Vehicle">Vehicle</option>
        <option value="Machinery">Machinery</option>
        <option value="Power Tools">Power Tools</option>
        <option value="Distribution Board">Distribution Board</option>
        <option value="Batching Plant">Batching Plant</option>
      </select>
      {errors.type && (
        <p style={{ color: "red", fontSize: "12px" }}>
          {errors.type}
        </p>
      )}
    </div>

    {/* Asset Code Field */}
    <div style={{ flex: 1 }}>
      <label
        htmlFor="type"
        className={styles.AssetCodeCreationInputLabel}
      >
        Asset Code
        <span style={{ color: "black" }}>*</span>
      </label>
      <input
        type="text"
        id="assetCode"
        name="assetCode"
        value={formData.assetCode}
        onChange={handleChange}
        
        placeholder="Enter your asset code"
        style={{
          width: "100%",
          textTransform: "uppercase",
          backgroundColor: "#F3F3F3",
          height: "50px",
          marginTop:"5px",
          marginBottom:"15px"
        }}
      />
      {errors.assetCode && (
        <p style={{ color: "red", fontSize: "12px" }}>
          {errors.assetCode}
        </p>
      )}
      {errorMessage && (
        <div style={{ color: "red", fontSize: "15px" }}>
          {errorMessage}
        </div>
      )}
    </div>
  </div>

  <div>
    <label
      htmlFor="type"
      className={styles.AssetCodeCreationInputLabel}
    >
      Name
      <span style={{ color: "black" }}>*</span>
    </label>
    <input
      type="text"
      id="Name"
      name="Name"
      value={formData.Name}
      onChange={handleChange}
      placeholder="Enter your Machinery Name"
      style={{
        width: "100%",
        textTransform: "uppercase",
        backgroundColor: "#F3F3F3",
        height: "50px",
        marginTop:"5px",
        marginBottom:"15px"
      }}
    />
    {errors.Name && (
      <p style={{ color: "red", fontSize: "12px" }}>{errors.Name}</p>
    )}
  </div>
  {/* Query fields */}
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      marginTop: "10px",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <label
        htmlFor="addQueryButton"
        className={styles.AssetCodeCreationInputLabel}
      >
        Add your Queries-Equipment Details
        <span style={{ color: "black" }}>*</span>
        <span
          style={{
            fontSize: "12px",
            color: "#999",
            marginLeft: "8px",
          }}
        >
          DOCUMENT - (ID/SOFT COPY)
        </span>
      </label>
      <label
        htmlFor="addQueryButton"
        className={styles.AssetCodeCreationAddQueryButton}
        style={{ display: "inline-block" }}
      ></label>
    </div>
    <button
      // className="btn btn-primary"
      onClick={addQuery}
      style={{
        marginTop: "10px",
        fontSize:"30px",
        color:"green"
        
      }}
    >
      <AiFillPlusCircle />
    </button>
  </div>
  {errors.queries && (
        <p style={{ color: "red", fontSize: "12px" }}>
          {errors.queries}
        </p>
      )}
  {(formData.queries || []).map((query, index) => (
    <>
      <div
        key={index}
        style={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <input
          type="text"
          id={`query${index}`}
          name={`query${index}`}
          value={query}
          onChange={(e) => handleQueryChange(index, e.target.value)}
          placeholder="Enter A Document Name"
          style={{
            flex: "1 0 auto",
            marginRight: "10px",
            width: "80%",
            backgroundColor: "#F3F3F3",
          }} 
        />
        <button
          className={styles.AssetCodeCreationRemoveQueryButton}
          onClick={() => removeQuery(index)}
          style={{ flex: "0 0 auto" }}
        >
          Delete
        </button>
      </div>
      <div>
        {errors[`query${index}`] && (
          <p
            style={{
              color: "red",
              fontSize: "12px",
              marginLeft: "10px",
            }}
          >
            {errors[`query${index}`]}
          </p>
        )}
      </div>
    </>
  ))}
  

  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      gap: "75px",
      marginTop:'15px'
    }}
  >
    {/* Type Field */}
    <div style={{ flex: 1 }}>
      <label
        htmlFor="type"
        className={styles.AssetCodeCreationInputLabel}
      >
        Form No
        <span style={{ color: "black" }}>*</span>
      </label>

      <select
        id="formNo"
        name="formNo"
        value={formData.formNo}
        onChange={handleChange}
        className=" mt-[5px]"
        style={{
          width: "100%",
          backgroundColor: "#F3F3F3",
          height: "50px",
          marginTop:"5px"
        }}
      >
        <option value="">Select Form No</option>
       {checklistData.map((item) => (
    <option key={item._id} value={item._id}> {/* Pass formNo */}
      {item.formNo} {/* Display the form number */}
    </option>
  ))}
      </select>
      {errors.formNo && (
        <p style={{ color: "red", fontSize: "12px" }}>
          {errors.formNo}
        </p>
      )}
    </div>

    {/* Asset Code Field */}
    <div style={{ flex: 1 }}>
      <label
        htmlFor="type"
        className={styles.AssetCodeCreationInputLabel}
      >
        Activity Name
        <span style={{ color: "black" }}>*</span>
      </label>
      <select
        id="activityName"
        name="activityName"
        value={formData.activityName}
        onChange={handleChange}
        className=" mt-[5px]"
        style={{
          width: "100%",
          backgroundColor: "#F3F3F3",
          height: "50px",
          marginTop:"5px"
        }}
      >
        <option value="">Select Activity Name</option>
        {checklistData.map((item) => (
    <option key={item.activity?._id} value={item.activity?._id}>
      {item.activity?.activity} 
    </option>
  ))}
      </select>
      {errors.activityName && (
        <p style={{ color: "red", fontSize: "12px" }}>
          {errors.activityName}
        </p>
      )}
   
    </div>
  </div>







  {/* Drawing fields */}
  {/* <div style = {{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
      <label htmlFor="addbutton" className="fs-10 fw-bold text-start py-2">
          Add Document
          <span style={{ color: 'red' }}>*</span>
        </label>
        <button
        className="btn btn-primary"
        onClick={addDrawing}
        style={{ marginTop: '10px' }}
        id = 'addbutton'
      >
        Add Name
      </button>
    </div>
    {formData.drawings.map((drawing, index) => (
      <>
      <div key={index} style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
        <input
          type="text"
          id={`drawing${index}`}
          name={`drawing${index}`}
          value={drawing}
          onChange={(e) => handleDrawingChange(index, e.target.value)}
          placeholder="Enter a drawing"
          className="form-control fs-12"
          style={{ flex: '1 0 auto', marginRight: '10px', width: '80%', backgroundColor: '#F3F3F3' }}
        />
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => removeDrawing(index)}
          style={{ flex: '0 0 auto' }}
        >
          Delete
        </button>
      </div>
      <div>
        {errors[`drawing${index}`] && <p style={{ color: 'red', fontSize: '12px', marginLeft: '10px' }}>{errors[`drawing${index}`]}</p>}
      </div>
      </>
    ))} */}

  <div
    style={{
      marginTop: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {/* <button
        style={{ padding: '8px', borderRadius: '5px', border: '1px solid lightgray', backgroundColor: 'transparent', width: '100px' }}
        onMouseOver={(e) => e.target.style.backgroundColor = 'lightgray'}
        onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
        onClick={() => setShowForm(false)}
      >
        Cancel
      </button> */}
    <button
      style={{
        marginBottom: "20px",
        padding: "10px",
        borderRadius: "5px",
        width: "175px",
        backgroundColor: "var(--primary-color)",
        color: "white",
      }}
      className="btn "
      onClick={handleSubmit}
    >
      Submit
    </button>
  </div>
</section>
</Dialog>

    );
}
export default AddAssetcode;