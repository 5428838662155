/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import Filter from "../../../Images/Filter.svg"
import { WebApimanager } from "../../../WebApiManager";
import TableWrapper from "../../../Utilities/TableWrapper";
import Styles from "./AssetCodeCreation.module.css";
import DrawingTableWrapper from "../../Drawing/Components/DrawingTableWrapper";
import AddAssetcode from "./AddAssetCode";
import EditAssetcode from "./EditAssetCode";
import ViewAssetcode from "./ViewAssetCode";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import styles from "../../Checklist/Design/Design.module.css";
import { Loader } from "../../../Widgets/notificationFeedbacks";
const Assetcode = ({}) => {
  let webApi = new WebApimanager();
  const [open, setOpen] = useState(false);
  const [assetCode, setAssetCodeData] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleAssetCodeData, setSingleAssetCodeData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showFilter,setShowFilter] = useState(false)
  const [filterType,setFilterType] = useState("type")
  const [query,setQuery] = useState("")
  const [trashopen, setTrashOpen] = useState(false);
  const [formId,setFormId] = useState(null);
  const [formData, setFormData] = useState({
    type: "",
    assetCode: "",
    Name: "",
    documents: [],
    drawings: [""], 
    description: [],
    queries: [],
  });
  const [editData, setEditData] = useState({
    _id: "",
    type: "",
    assetCode: "",
    Name: "",
    documents: [],
    drawings: [""],
    queries: [""],
  });
  const [errors, setErrors] = useState({});
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
    //   setShowForm(false);
    }, 2000);
    setFormData({
      drawings: [""],
    });
  };

  const handleEditOpen = (assetCode) => {
    setEditData({
      _id: assetCode._id,
      type: assetCode.type,
      assetCode: assetCode.assetCode,
      Name: assetCode.name,
      documents: assetCode.documents,
      drawings: assetCode.documents,
      description: assetCode.queries,
      queries: assetCode.queries,
    });
    setEditDialogOpen(true);
  };
  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditData({
      _id: "",
      type: "",
      assetCode: "",
      Name: "",
      documents: [],
      drawings: [""],
      queries: [""],
      description: [],
    });
  };
   useEffect(()=>{
    console.log(formData,"formData")
    console.log(editData,"editData")
   },[formData,editData])
  const handleViewOpen = (assetCode) => {
    setSingleAssetCodeData(assetCode);
    setViewDialogOpen(true);
  };
  const handleViewClose = () => {
    setSingleAssetCodeData(null);
    setViewDialogOpen(false);
  };


  const fetchAssetCodes = async () => {
    try {
      const response = await webApi.get("api/pnms/assetCode");
      console.log("API response:", response);
      if (response.data && Array.isArray(response.data.data.AssetCodes)) {
        setAssetCodeData(response.data.data.AssetCodes);
        console.log(response.data.data.AssetCodes);
      } else {
        setErrors("Unexpected data format");
        console.error("Unexpected data format:", response.data);
      }
    } catch (error) {
      console.error("Error object:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Asset code and name must be unique"
      ) {
        setErrorMessage("Asset code and name must be unique");
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    }
  };
  useEffect(() => {
    fetchAssetCodes();
  }, []);
  const handleTrash =(id) => {
    setTrashOpen(true);
    setFormId(id);
  }
  const handleTrashClose = () => {
    setTrashOpen(false);
    setFormId("");
  };
  const handleDelete = async (id) => {
    try {
      const response = await webApi.delete(`api/pnms/deleteAssetCode/${id}`);
      if (response.status === 204) {
        window.location.reload();
        fetchAssetCodes();
      } else {
        console.error("Failed to delete");
      }
    } catch (error) {
      console.error("There was an error deleting the asset code!", error);
    }
  };

  

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
                width:'100%',
                marginBottom:"16px"
              }}
            >
             <p
                style={{
                  color: "#131313",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
               Asset Codes
              </p>
              <div>
                  <>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          position: "relative",
                          width: "250px",
                          paddingLeft: "5px",
                          height: "50px",
                        }}
                      >
                        <input
                          placeholder="Search AssetCodes"
                          onChange={(e)=>setQuery(e.target.value)}
                          style={{
                            paddingLeft: "20px",
                            border: "1px solid var(--primary-color)",
                            width: "250px",
                            height: "40px",
                          }}
                        />
                        <Icon.Search
                          style={{
                            position: "absolute",
                            right: "15px",
                            top: "40%",
                            transform: "translateY(-50%)",
                            color: "#ACACAC",
                          }}
                        />
                      </div>
                      <div 
                      style={{width:"40px",height:"40px",position:"relative"}}>

                      <img
                        src={Filter}
                        style={{
                          width: "40px",
                          height: "40px",
                          marginLeft: "10px",
                          padding: "5px",
                          border: "1px solid var(--primary-color)",
                          cursor: "pointer",
                        }}
                        onClick={(e)=>setShowFilter(true)}
                      />
                      {showFilter && <select className={Styles.selectInput} style={{position:"absolute",left:"10px"}}
                        onChange={(e)=>{
                          setFilterType(e.target.value)
                          setShowFilter(false)
                          
                        }}
                      >
                        <option value="" hidden>Select type</option>
                        <option value="all">All</option>
                        <option value="type">Type</option>
                        <option value="assetCode">Asset</option>
                        <option value="name">Name</option>

                      </select>}

            </div>
                      <Button
                        style={{
                          height: "40px",
                          marginLeft: "15px",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "var(--primary-color)",
                          border: "1px solid var(--primary-color)",
                          borderRadius: "0px",
                        }}
                          onClick={handleClickOpen}
    
                      >
                        <Icon.PlusCircle
                          size={20}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        Add New
                      </Button>
                    </div>
                    <div>
                    </div>
                 
                </>
         

</div>
            </div>
            <DrawingTableWrapper
                  tableHead={
                  <tr >
                     <th  style={{ width: "10%" }}>S.No</th>
                    <th  style={{ width: "20%" }}>Type</th>
                    <th  style={{ width: "15%" }}>AssetCode</th>
                    <th  style={{ width: "20%" }}>Name</th>
                    <th  style={{ width: "20%" }}>Queries(Documents)</th>
                    <th  style={{ width: "15%" }}>Actions</th>
                  
                  </tr>
                }
                tableBody={
                    [...assetCode]
                      .reverse()
                      .filter((assetCode) => {
                        const queryLower = query.toLowerCase();
                        if (filterType === "all") {
                            return (
                              assetCode.name.toLowerCase().includes(queryLower) ||
                              assetCode.type.toLowerCase().includes(queryLower) ||
                              assetCode.assetCode.toLowerCase().includes(queryLower) ||
                              (typeof assetCode.query === 'string' && assetCode.query.toLowerCase().includes(queryLower)) // Safely handle query
                            );
                          }
                  
                        // Handle filtering based on filterType
                        if (filterType === "type") {
                          return assetCode.type.toLowerCase().includes(queryLower);
                        }
                        if (filterType === "assetCode") {
                          return assetCode.assetCode.toLowerCase().includes(queryLower);
                        }
                        if (filterType === "name") {
                          return assetCode.name.toLowerCase().includes(queryLower);
                        }
                        return false; 
                      })
                      .map((assetCode, index) => {
                        return (
                          <tr
                            key={index}
                            style={{
                              backgroundColor: index % 2 === 0
                                ? "var(--table-oddrow-color)"
                                : "var(--table-evenrow-color)",
                            }}
                          >
                            <td style={{ width: "10%" }}>
                              {(index + 1).toString().padStart(2, "0")}
                            </td>
                            <td style={{ width: "20%" }}>{assetCode.type}</td>
                            <td style={{ width: "15%" }}>{assetCode.assetCode}</td>
                            <td style={{ width: "20%" }}>{assetCode.name}</td>
                            <td style={{ width: "15%" }}>{assetCode.documents?.length || "-"}</td> 
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <Icon.Pen
                                size={20}
                                style={{ marginRight: "10px", cursor: "pointer" }}
                                onClick={() => handleEditOpen(assetCode)}
                              />
                              <Icon.EyeFill
                                size={20}
                                style={{ marginRight: "10px", cursor: "pointer" }}
                                onClick={() => handleViewOpen(assetCode)}
                              />
                              <Icon.Trash
                                size={20}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleTrash(assetCode._id)}
                              />
                            </td>
                          </tr>
                        );
                      })
                  }
                  
              />
           
          </>
        }
      />
 
    <AddAssetcode
    open={open}
    close={handleClose}
     />
    
<EditAssetcode
  open={editDialogOpen}
  close={handleEditClose}
  data={editData}/>
  <ViewAssetcode
    open={viewDialogOpen}
    close={handleViewClose}
    data={singleAssetCodeData}
    />
      <Dialog
        open={trashopen}
        onClose={handleTrashClose}
        maxWidth="xs"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 180px", textAlign: "center", color: "white" }}
          >
            Delete
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleTrashClose}
          />
        </div>
        <DialogContent>
          <div>

          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
        <p className={styles.question}>Are you sure want to Delete ? </p>
        <div className={styles.buttons}>
        <button className={styles.noButton}   onClick={handleTrashClose}>No</button>
        <button className={styles.submitButton}   onClick={() => handleDelete(formId)}>Yes</button>
        </div>
        </div>
        </DialogContent>
      </Dialog>

    </>
  );
};

export default Assetcode;
