import React from 'react'

const TwittenIcon = () => {
  return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2690_7749)">
        <path d="M25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25C19.4036 25 25 19.4036 25 12.5Z" fill="#1D9BF0"/>
        <path d="M13.7145 11.8908L17.8951 7.03125H16.9044L13.2744 11.2507L10.3752 7.03125H7.03125L11.4155 13.4118L7.03125 18.5078H8.02196L11.8553 14.0519L14.9171 18.5078H18.2611L13.7142 11.8908H13.7145ZM8.37892 7.77705H9.90059L16.9048 17.7959H15.3832L8.37892 7.77705Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_2690_7749">
        <rect width="25" height="25" fill="white"/>
        </clipPath>
        </defs>
      </svg>
  )
}

export default TwittenIcon