import React, { useEffect, useRef, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

const MultiSelectSearch = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = (fromButtonClick) => {
        if (fromButtonClick && !isOpen) {
            setIsOpen(true);
        } else {
            setIsOpen(!isOpen);
        }
    };

    const filteredList = props.listItems.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearch = (event) => setSearchTerm(event.target.value);

    const handleSelectAll = () => {
        if (props.selectedIds.length === filteredList.length) {
            props.clearAll();
        } else {
            props.addAll();
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <label className="form-label" style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                {props.heading}
                {/* <span style={{ color: 'red' }}>*</span> */}
            </label>
            <div ref={dropdownRef} className="dropdown" style={{ width: '100%', position: 'relative' }}>
                <button 
                    className="btn btn-outline-secondary dropdown-toggle" 
                    type="button" 
                    onClick={() => toggleDropdown(true)}
                    style={{ width: '100%', textAlign: 'left', display: 'flex', alignItems: 'center' }}
                >
                    <div>
                        {props.selectedIds.length > 0 ? `${props.selectedIds.length} selected` : 'Select'}
                    </div>
                    <span style={{ marginLeft: 'auto' }} className="caret"></span>
                </button>
                <div className={`dropdown-menu${isOpen ? ' show' : ''}`} style={{ width: '100%', maxHeight: '40vh', overflowY: 'auto', position: 'absolute', zIndex: 1000 }}>
                    <div style={{ zIndex: 2, position: 'sticky', top: 0, width: '100%', height: '8px', background: 'white' }}></div>
                    <div className="px-2 mb-2" style={{ position: 'sticky', top: '8px', zIndex: 1, backgroundColor: 'white' }}>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Search..." 
                            value={searchTerm} 
                            onChange={handleSearch} 
                        />
                    </div>
                    <div className="dropdown-item" onClick={handleSelectAll}>
                        <input type="checkbox" checked={props.selectedIds.length === filteredList.length} readOnly /> Select All
                    </div>
                    {/* Render selected items first */}
                    {props.selectedIds.map((selectedId) => {
                        const selectedItem = filteredList.find(item => item.id === selectedId);
                        if (selectedItem) {
                            return (
                                <div 
                                    key={selectedItem.id} 
                                    className={`dropdown-item${props.selectedIds.includes(selectedItem.id) ? ' active' : ''}`} 
                                    onClick={() => props.handleSelectedPropertyOption(selectedItem, props.type, props.selectedIds, props.setSelectedIds)} 
                                    id={`tooltip-${selectedItem.id}`}
                                >
                                    <input type="checkbox" checked={props.selectedIds.includes(selectedItem.id)} readOnly /> {selectedItem.name}
                                </div>
                            );
                        }
                        return null;
                    })}
                    {/* Render remaining items */}
                    {filteredList.map((option) => {
                        if (!props.selectedIds.includes(option.id)) {
                            return (
                                <div 
                                    key={option.id} 
                                    className={`dropdown-item${props.selectedIds.includes(option.id) ? ' active' : ''}`} 
                                    onClick={() => props.handleSelectedPropertyOption(option, props.type, props.selectedIds, props.setSelectedIds)} 
                                    id={`tooltip-${option.id}`}
                                >
                                    <input type="checkbox" checked={props.selectedIds.includes(option.id)} readOnly /> {option.name}
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
    );
};

export default MultiSelectSearch;





// import React, { useEffect, useRef, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './MultiSelectSearch.css'; // Import the CSS file

// const MultiSelectSearch = (props) => {
//     const [isOpen, setIsOpen] = useState(false);
//     const [searchTerm, setSearchTerm] = useState('');
//     const dropdownRef = useRef(null);

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//                 setIsOpen(false);
//             }
//         };
    
//         document.addEventListener('mousedown', handleClickOutside);
    
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, []);

//     const toggleDropdown = () => setIsOpen(!isOpen);

//     const filteredList = props.listItems.filter(item =>
//         item.name.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     const handleSearch = (event) => setSearchTerm(event.target.value);

//     const handleSelectAll = () => {
//         if (props.selectedIds.length === filteredList.length) {
//             props.clearAll();
//         } else {
//             props.addAll();
//         }
//     };

//     const handleItemClick = (item) => {
//         props.handleSelectedPropertyOption(item);
//     };

//     return (
//         <div className="multi-select-container" style={{ display: 'flex', flexDirection: 'column' }}>
//             <label className="form-label" style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
//                 {props.heading} {props.mandatory && <span style={{ color: 'red' }}>*</span>}
//             </label>
//             <div ref={dropdownRef} className="dropdown custom-dropdown">
//                 <button
//                     className="btn btn-outline-secondary dropdown-toggle custom-dropdown-button"
//                     type="button"
//                     aria-expanded={isOpen}
//                     onClick={toggleDropdown}
//                 >
//                     <div>{props.selectedIds.length > 0 ? `${props.selectedIds.length} selected` : 'Select'}</div>
//                     <span className="caret" style={{ marginLeft: 'auto' }}></span>
//                 </button>
//                 <div className={`dropdown-menu${isOpen ? ' show' : ''}`} style={{ maxHeight: '40vh', overflowY: 'auto' }}>
//                     <div style={{ zIndex: 2, position: 'sticky', top: 0, width: '100%', height: '8px', background: 'white' }}></div>
//                     <div className="px-2 mb-2" style={{ position: 'sticky', top: '8px', zIndex: 1, backgroundColor: 'white' }}>
//                         <input
//                             type="text"
//                             className="form-control"
//                             placeholder="Search..."
//                             value={searchTerm}
//                             onChange={handleSearch}
//                         />
//                     </div>
//                     <div className="dropdown-item" onClick={handleSelectAll}>
//                         <input type="checkbox" checked={props.selectedIds.length === filteredList.length} readOnly /> Select All
//                     </div>
//                     {filteredList.map((item) => (
//                         <div
//                             key={item.id}
//                             className={`dropdown-item${props.selectedIds.includes(item.id) ? ' active' : ''}`}
//                             onClick={() => handleItemClick(item)}
//                         >
//                             <input type="checkbox" checked={props.selectedIds.includes(item.id)} readOnly /> {item.name}
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default MultiSelectSearch;
