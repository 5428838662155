import React, { useEffect, useState } from "react";
import "./ConnectedDevices.css";
import { WebApimanager } from "../../../WebApiManager";

const ConnectedDevices = () => {
  const [limit, setLimit] = useState(1);
  const [devices, setDevices] = useState([]);
  const webApi = new WebApimanager();

  const getIpAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");

      // Check if the response is OK (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Log the entire response data
      console.log("IP Address Response:", data);

      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return "Unavailable";
    }
  };

  const getSystemInfo = () => {
    const os = getOS();
    const browserInfo = getBrowserInfo();
    return {
      manufacturer: "Unknown",
      model: "Unknown",
      operatingSystem: os.name,
      osVersion: os.version,
      browser: browserInfo.name,
      browserVersion: browserInfo.version,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      userAgent: navigator.userAgent,
      language: navigator.language,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      cpuClass: navigator.cpuClass || "Unknown",
      deviceMemory: navigator.deviceMemory || "Unknown",
      hardwareConcurrency: navigator.hardwareConcurrency || "Unknown",
    };
  };

  const getOS = () => {
    const userAgent = window.navigator.userAgent;
    let os = { name: "Unknown", version: "Unknown" };

    if (userAgent.indexOf("Win") !== -1)
      os = { name: "Windows", version: getOSVersion("Windows") };
    else if (userAgent.indexOf("Mac") !== -1)
      os = { name: "macOS", version: getOSVersion("Mac OS") };
    else if (userAgent.indexOf("X11") !== -1)
      os = { name: "UNIX", version: "Unknown" };
    else if (userAgent.indexOf("Linux") !== -1)
      os = { name: "Linux", version: "Unknown" };

    return os;
  };

  const getOSVersion = (osName) => {
    if (osName === "Windows") {
      if (navigator.userAgent.includes("Windows NT 10.0")) return "10";
      if (navigator.userAgent.includes("Windows NT 6.3")) return "8.1";
      if (navigator.userAgent.includes("Windows NT 6.2")) return "8";
      if (navigator.userAgent.includes("Windows NT 6.1")) return "7";
    }
    return "Unknown";
  };

  const getBrowserInfo = () => {
    const ua = navigator.userAgent;
    let name = "Unknown";
    let version = "Unknown";

    if (ua.indexOf("Firefox") > -1) {
      name = "Mozilla Firefox";
      const match = ua.match(/Firefox\/([0-9]+(\.[0-9]+)?)/);
      if (match) version = match[1];
    } else if (ua.indexOf("Chrome") > -1) {
      name = "Google Chrome";
      const match = ua.match(/Chrome\/([0-9]+(\.[0-9]+)?)/);
      if (match) version = match[1];
    } else if (ua.indexOf("Safari") > -1) {
      name = "Safari";
      const match = ua.match(/Version\/([0-9]+(\.[0-9]+)?)/);
      if (match) version = match[1];
    } else if (ua.indexOf("Edge") > -1) {
      name = "Microsoft Edge";
      const match = ua.match(/Edg\/([0-9]+(\.[0-9]+)?)/);
      if (match) version = match[1];
    }

    return { name, version };
  };

  const sendDeviceToBackend = async (device) => {
    try {
      const response = await webApi.post("api/connectedDevices", device);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log("Device stored in backend:", await response.json());
    } catch (error) {
      console.error("Error sending device info to backend:", error);
    }
  };

  const displaySystemInfo = async () => {
    const ipAddress = await getIpAddress();
    const systemInfo = getSystemInfo();
    const newDevice = {
      id: new Date().getTime(),
      ipAddress,
      ...systemInfo,
    };

    console.log("New Device Data:", newDevice);

    setDevices((prevDevices) => {
      const exists = prevDevices.some(
        (device) => device.ipAddress === newDevice.ipAddress
      );
      if (exists) {
        console.log("Device with this IP already exists:", newDevice.ipAddress);
        return prevDevices;
      }
      console.log("Adding new device:", newDevice);
      return [...prevDevices, newDevice];
    });

    await sendDeviceToBackend(newDevice);
  };

  useEffect(() => {
    displaySystemInfo();
  }, []);

  const handleLogout = async (deviceId, backendId) => {
    try {
      const response = await webApi.delete(`api/connectedDevices/${backendId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log(`Device with ID ${deviceId} logged out successfully.`);
      setDevices(devices.filter((device) => device.id !== deviceId));
    } catch (error) {
      console.error("Error logging out device:", error);
    }
  };

  return (
    <div className="container">
      <div className="py-3">
        <p className="connectedDevicesTitle">Connected Devices</p>
      </div>
      <div style={{ borderBottom: "2px solid #E5E5E5" }}></div>

      {/* <div className="limitedeConnectDev">
        <p className="LimitTitle">Limit of connected devices</p>
        <div className="limitBox">
          <span className="limitValue">{limit}</span>
          <div className="arrowButtons">
            <button
              className="arrowButton"
              onClick={incrementLimit}
              aria-label="Increase limit"
            >
              <MdKeyboardArrowUp />
            </button>
            <button
              className="arrowButton"
              onClick={decrementLimit}
              aria-label="Decrease limit"
            >
              <MdKeyboardArrowDown />
            </button>
          </div>
        </div>
      </div> */}

      <div className="containerdeviseContainer">
        {devices.slice(0, limit).map((device) => (
          <div key={device.id} className="deviceCard">
            <div className="row">
              <div className="column">Device Name :</div>
              <div className="column">{device.operatingSystem}</div>
            </div>

            <div className="row">
              <div className="column">IP Address:</div>
              <div className="column">{device.ipAddress}</div>
            </div>
            <div className="row">
              <div className="column">Logout the Connected device:</div>
              <div
                className="columnLogout"
                onClick={() => handleLogout(device.id, device._id)}
                style={{ cursor: "pointer", color: "red" }}
              >
                Logout
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConnectedDevices;
