import React from 'react'
import FormHeadings from '../../../PNMAssets/FormHeadings'
import { FaX } from "react-icons/fa6";
import Style from '../../../DailyLogReport/VehicleAndMachinery.module.css'


const LogReportViewMiscellaneous = ({handleClose,tableFormData}) => {
  return (
    <>
    <div style={{position:"relative",width:"100%",backgroundColor:"white",padding:"80px"}}>
    { <div style={{position:"absolute",right:"40px",top:"40px",color:"#E47331",cursor:"pointer"}} >
            <FaX onClick={handleClose}/>
          </div>}
          <h3 className={Style.VMHeading}>
          Miscellaneous Report
          </h3>
          <form  className={Style.VMFormContainer}>
            <div className={Style.VMSiteContainer}>
              <div className={Style.VMSiteHeading}>
                <FormHeadings text={"Site Name"} />
              </div>
              <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
              <div className={Style.VMSite}>
                <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.siteName.siteName}</p>
              </div>
            </div>
            <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition ID"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.transitionId}</p>

                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition Date"} />
                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.transitionDate.slice(0,10)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Equipment Type"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.equipmentType}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Asset code/Name"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.assetCode.assetCode}</p>
                    
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Make  Name"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.makeName}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Model"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.model}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Vendor"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.vendor}</p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Mobile No"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.mobileNo}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer} >
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Rate"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont} style={{display:"flex",position:"relative"}}>
                      
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.selectRateType} &nbsp;&nbsp;{tableFormData.rateCharge}</p>
                        
                      
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Vehicle No"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.vehicleNo}</p>
                    </div>
                  </div>
                </div>
              </div>
              
                
            </div>
            <div className={Style.VMFormSeperator}></div>
            <h3 className={Style.VMHeading} style={{marginTop:"30px"}}>
          Details
          </h3>
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>Date:</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Start"} />
                      </div>
                      <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                      <div className={Style.VMMidFormInputContainer}>
                      <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.startDate.slice(0,10)}</p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"End"} />
                      </div>
                      <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                      <div className={Style.VMMidFormInputContainer}>
                      <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.endDate.slice(0,10)}</p>
                      
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference"} />
                      </div>
                      <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                      <div className={Style.VMMidFormInputContainer}>
                      <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.differenceDate}</p>
                      
                      </div>
                </div>
            </div>
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>Clock:</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"In"} />
                      </div>
                      <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                      <div className={Style.VMMidFormInputContainer}>
                      <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.startTime}</p>
                        
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Out"} />
                      </div>
                      <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                      <div className={Style.VMMidFormInputContainer}>
                      <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.endTime}</p>
                      
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference"} />
                      </div>
                      <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                      <div className={Style.VMMidFormInputContainer}>
                      <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.differenceTime}</p>
                      
                      </div>
                </div>
            </div>

            <div className={Style.VMFormSeperator}></div>
            <div className={Style.VMTopFormMainContainer}>
              
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Debit Note"} />

                    </div>
                    <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                    <div className={Style.VMTopInputCont}>
                    <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.debitNote}</p>
                   
                    </div>
                  </div>
                </div>
                
              </div>
              
              { tableFormData?.debitNote === "Yes" && <>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Employee"} />

                      </div>
                      <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                      <div className={Style.VMTopInputCont}>
                      <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.employeeType}</p>
                      
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Employee ID"} />

                      </div>
                      <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                      <div className={Style.VMTopInputCont}>
                      <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.employeeId}</p>
                      
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Debit Reason"} />

                      </div>
                      <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                      <div className={Style.VMTopInputCont}>
                      <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.debitReason}</p>
                      
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Debit Amount"} />
                      </div>
                      <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                      <div className={Style.VMTopInputCont}>
                      <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.debitAmount}</p>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </>}
                
            </div>
            <div className={Style.VMFormSeperator}></div>

            <div className={Style.VMWorkDetails}>
              <div className={Style.VMWorkDetailsHeading}>
                <FormHeadings text={"Scope of work Details"} />
              </div>
              <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
              <div className={Style.VMWorkDetailsInputCont}>
              <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.scopeOfWorkDetails}</p>
                
              </div>

            </div>
            <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Location"} />

                      </div>
                      <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                      <div className={Style.VMTopInputCont}>
                      <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.location}</p>
                      
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Remarks"} />
                      </div>
                      <p style={{fontSize:"14px",fontWeight:"bold"}}>:</p>
                      <div className={Style.VMTopInputCont}>
                      <p style={{fontSize:"14px",fontWeight:"bold",textAlign:"left"}}>&nbsp;&nbsp;{tableFormData?.remarks}</p>
                        
                      </div>
                    </div>
                  </div>
                </div>
                

          </form>
    </div>
      
    </>
  )
}

export default LogReportViewMiscellaneous
