import React, { useEffect, useState, useRef } from "react";
import { Button } from "@mui/material";
import * as Icon from "react-bootstrap-icons";
import { WebApimanager } from "../../../WebApiManager";
import AddFileDialog from "./AddFileDialog";
import AddFolderDialog from "./AddFolderDialog";
import { IoArrowBack } from "react-icons/io5";
import { BACKEND_URL } from "../../../Utilities/Constants";
import FolderDetailsDialog from "./FolderDetailsDialog";
import FolderFilesViewDialog from "./FolderFilesViewDialog";
import FolderEditDialog from "./FolderEditDialog";
import Download from "../../../Images/IscodeDownload.svg";
import ViewIcon from "../../../Images/IscodeView.svg";

const IsCodes = () => {
  let webApi = new WebApimanager();
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showAddFolderModal, setShowAddFolderModal] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [showFolderDetailsModal, setShowFolderDetailsModal] = useState(false);
  const [editingFolder, setEditingFolder] = useState(null);
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleFolderDetails = () => {
    setShowFolderDetailsModal(true);
  };

  const refetchData = () => {
    webApi
      .get("api/isCode")
      .then((response) => {
        setFolders(response.data.data.isCodes);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  };

  useEffect(() => {
    refetchData();
  }, []);

  const handleEditFolderClose = () => {
    setShowEditFolderModal(false);
    setEditingFolder(null);
  };

  const handleSaveFolderEdit = () => {
    refetchData();
    handleEditFolderClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFolderClick = (folder) => {
    setSelectedFolder(folder);
    setSelectedFolderId(folder._id);
  };

  const handleBackToFolders = () => {
    setSelectedFolder(null);
    setSelectedFolderId("");
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFolders = folders.filter((folder) =>
    (folder.fName || "").toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredFiles = selectedFolder?.files.filter((file) =>
    (file.fileName || "").toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddFolder = () => setShowAddFolderModal(true);

  const handleAddFolderClose = () => {
    setShowAddFolderModal(false);
  };

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const handleFileUpload = () => {
    refetchData();
    setShowUploadModal(true);
  };

  const handleFileUploadClose = () => {
    refetchData();
    setShowUploadModal(false);
  };

  const handleViewFile = async (no) => {
    try {
      const response = await webApi.getImage(
        `api/isCode/file/${selectedFolderId}/${no}`
      );

      if (!response.data) throw new Error("File data not found");

      console.log(response.headers["content-type"]);

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error viewing file:", error);
    }
  };

  const handleDownloadFile = async (no) => {
    try {
      const response = await webApi.getImage(
        `api/isCode/file/${selectedFolderId}/${no}`
      );

      console.log(response.headers["content-type"]);

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `filename.${
        response.headers["content-type"].split("/")[1]
      }`;
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div
      style={{
        margin: "90px auto",
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "400",
        fontSize: "16px",
        padding: "20px",
        maxWidth: "1200px",
        position: "relative",
        paddingBottom: "50px",
      }}
    >
      {selectedFolder ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "20px",
            }}
          >
            <Button
              onClick={handleBackToFolders}
              style={{
                marginTop: "-10px",
                color: "black",
                cursor: "pointer",
                border: "none",
              }}
            >
              <IoArrowBack
                size={30}
                style={{ color: "var(--primary-color)" }}
              />
            </Button>
            <h4 style={{ color: "#E47331" }}>{selectedFolder.fName}</h4>
            <div
              style={{
                position: "relative",
                width: "250px",
                marginLeft: "auto",
                marginRight: "20px",
                height: "40px",
              }}
            >
              <input
                placeholder="Search Files"
                value={searchTerm}
                onChange={handleSearchInputChange}
                style={{
                  paddingLeft: "20px",
                  borderRadius: "6px",
                  border: "1px solid #E47331",
                  width: "100%",
                  height: "100%",
                }}
              />
              <Icon.Search
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "#ACACAC",
                }}
              />
            </div>
            <Button
              style={{
                height: "40px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#E47331",
                borderColor: "#E47331",
                color: "white",
              }}
              onClick={handleFileUpload}
            >
              <Icon.PlusCircle size={20} style={{ marginRight: "5px" }} /> Add
              File
            </Button>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "20px",
            }}
          >
            {filteredFiles?.map((file, index) => (
              <div
                key={file._id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  backgroundColor: index % 4 < 2 ? "#F3F6F8" : "#FDF8F4",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#333",
                    marginLeft: "20px",
                  }}
                >
                  {file.fileName}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    right: "15px",
                  }}
                >
                  <Button
                    variant="link"
                    onClick={() => handleViewFile(file.fNo)}
                  >
                    <img src={ViewIcon} alt="not Found" />
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => handleDownloadFile(file.fNo)}
                  >
                    <img src={Download} alt="not Found" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "20px",
            }}
          >
            <h3 style={{ color: "var(--primary-color)", fontSize: "20px" }}>
              IS Code Books
            </h3>
            <div
              style={{
                position: "relative",
                width: "250px",
                marginLeft: "auto",
                marginRight: "20px",
                height: "40px",
              }}
            >
              <input
                placeholder="Search Folders"
                value={searchTerm}
                onChange={handleSearchInputChange}
                style={{
                  paddingLeft: "20px",
                  borderRadius: "6px",
                  border: "1px solid #E47331",
                  width: "100%",
                  height: "100%",
                }}
              />
              <Icon.Search
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "#ACACAC",
                }}
              />
            </div>

            <div style={{ position: "relative", display: "inline-block" }}>
              <button
                onClick={handleDropdownToggle}
                style={{
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#E47331",
                  borderColor: "#E47331",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  padding: "0 10px",
                  cursor: "pointer",
                }}
              >
                <Icon.Plus
                  size={20}
                  style={{ marginRight: "5px", color: "white" }}
                />
                Add Folder
              </button>
              {dropdownOpen && (
                <div
                  ref={dropdownRef}
                  className="mt-1"
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "-20px",
                    backgroundColor: "#fff",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    zIndex: 1000,
                    width: "150px",
                  }}
                >
                  <button
                    onClick={handleAddFolder}
                    style={{
                      display: "block",
                      width: "100%",
                      padding: "10px",
                      border: "none",
                      color: "#333",
                      textAlign: "left",
                      cursor: "pointer",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Add Folder
                  </button>
                  <button
                    onClick={handleFolderDetails}
                    style={{
                      display: "block",
                      width: "100%",
                      padding: "10px",
                      border: "none",
                      backgroundColor: "#fff",
                      color: "#333",
                      textAlign: "left",
                      cursor: "pointer",
                    }}
                  >
                    Folder Details
                  </button>
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "20px",
            }}
          >
            {filteredFolders.map((folder, index) => (
              <div
                key={folder._id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "15px",
                  height: "100%",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  backgroundColor:
                    index % 6 < 3
                      ? "var(--table-oddrow-color)"
                      : "var(--table-evenrow-color)",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
                onClick={() => handleFolderClick(folder)}
              >
                {folder.fImage && folder.fImage ? (
                  <img
                    src={`${BACKEND_URL}${folder.fImage}`}
                    alt="Not Found"
                    style={{
                      width: "68.218px",
                      height: "75px",
                      marginRight: "15px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                ) : (
                  <Icon.Folder
                    size={40}
                    style={{
                      marginRight: "15px",
                      color: "#E47331",
                      borderRadius: "10px",
                    }}
                  />
                )}
                <div>
                  <div
                    style={{
                      fontSize: "20px",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      color: "#303030",
                      marginBottom: "5px",
                      lineHeight: "140%",
                      marginLeft: "20px",
                    }}
                  >
                    {folder.fName}
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      color: "#979797",
                      marginLeft: "20px",
                    }}
                  >
                    {folder.files.length} files
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <AddFolderDialog close={handleAddFolderClose} open={showAddFolderModal} />

      <AddFileDialog
        close={handleFileUploadClose}
        open={showUploadModal}
        onDataRefetch={refetchData}
        id={selectedFolderId}
      />

      <FolderDetailsDialog
        open={showFolderDetailsModal}
        onClose={() => setShowFolderDetailsModal(false)}
        folders={folders}
        onFolderDelete={refetchData}
      />

      <FolderFilesViewDialog onFolderDelete={refetchData} />

      <FolderEditDialog
        open={showEditFolderModal}
        onClose={handleEditFolderClose}
        folder={editingFolder}
        onSave={handleSaveFolderEdit}
      />
    </div>
  );
};

export default IsCodes;
