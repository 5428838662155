import React from "react";
import "./CheckListTable.css"; // Import the CSS file

const CheckListTable = (props) => {
  const { CheckListTableBody, CheckListTableHead } = props;

  return (
    <div className="table-responsive">
      <table className="table table-striped header-fixed">
        <thead className="checklkist-table-head">{CheckListTableHead}</thead>
        <tbody className="checklkist-table-body">{CheckListTableBody}</tbody>
      </table>
    </div>
  );
};

export default CheckListTable;
