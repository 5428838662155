

import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import styles from "../../Design/Design.module.css";
import Style from "./ApprovalView.module.css";
// import Add from "../../../Images/Add.svg";
// import Sub from "../../../Images/subtration.svg";
import { WebApimanager } from "../../../../WebApiManager";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import CheckListTable from "../../ChecklistTable";

const ApprovalView = (props) => {
  const { open, handleDialogClose, formData } = props;
  console.log(formData);
  let webApi = new WebApimanager();
  
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

 const [reason,setReason] = useState('')
 const [error,setError] = useState('')
 const [showForm,setShowForm] = useState(false)

  const handleClose = () => {
    handleDialogClose();

  };

 const handleSubmit =(e) =>{
  e.preventDefault();
  if(!reason){
    setError("Please Enter the Reason")
    return
  } 

  console.log("FormSubmitted")
 }
  

 const handleClick=()=>{
  setShowForm(true)
 }

  

 

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: '50px',
          }}
        >
          <p
            style={{ margin: "5px 550px", textAlign: "center", color: "white" }}
          >
          View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "500px" }}
        >
          <div className={styles.overall}>
            <div>
              <p className={styles.heading}>
                {formData?.companyId ? ` ${formData.companyId.companyDetails.companyName}` : 'No Company Data'}
              </p>
            </div>
            <div className={styles.second}>
              <div className={styles.grid}>
                <p style={{fontSize:'16px',fontWeight:'500'}}>Activity Name</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.activity?.activity || 'No Activity Data'}</p>

                <p style={{fontSize:'16px',fontWeight:'500'}}>Checklist Type</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.checklistType || 'No Checklist Type'}</p>

                <p style={{fontSize:'16px',fontWeight:'500'}}>Form No</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.formNo || 'No Form No'}</p>

                <p style={{fontSize:'16px',fontWeight:'500'}}>Departments</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.department?.join(', ') || 'No Departments'}</p>
              </div>
            </div>
{  formData?.descriptionDetails?.length > 0 && (
            <div>
              <CheckListTable
                CheckListTableHead={
                  <tr className="HeaderTableRow">
                    <th className="CheckListTableTableHead" style={{ width: "5%" }}>
                      S.No
                    </th>
                    <th className="CheckListTableTableHead" style={{ width: "95%" }}>
                      Description
                    </th>
                  </tr>
                }
              
                CheckListTableBody={
                  
                  formData?.descriptionDetails?.map((desc, index) => (
                    <tr
                      key={index}
                      className="ValueTableRow"
                      style={{
                        borderBottom: "1px dotted #97979740",
                        paddingBottom: "8px",
                      }}
                    >
                      <td className="CheckListTableTableData" style={{ width: "5%" }}>
                        {index + 1}
                      </td>
                      <td className="CheckListTableTableData" style={{ width: "95%" }}>
                        {desc.description || 'No Description'}
                      </td>
                    </tr>
                  )) || (
                    <tr>
                      <td colSpan="2">No Description Details Available</td>
                    </tr>
                  )
                }
          
              />
            </div>
)}
            <div className={styles.last}>
  {formData?.assigningIncharge?.length > 0 && (
    <>
      <p>Signature Of</p>
      <div className={styles.assignednames}>
        {formData.assigningIncharge.map((incharge, index) => (
          <p key={index}>
            {incharge.department ? incharge.department : 'No Department'} -{" "}
            {incharge.role ? incharge.role : 'No Role'}
          </p>
        ))}
      </div>
    </>
  )}
</div>

          </div>
        </DialogContent>

        {/* <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "500px" }}
        >
   <div className={styles.overall}>
    <div>
        <p className={styles.heading}>Ranjith Buildcon Pvt. Ltd.</p>
    </div>
    <div className={styles.second}>
       
        <div className={styles.grid}>
<p>ActivityName</p>
<p>:</p>
<p>ActivityName</p>

<p>ActivityName</p>
<p>:</p>
<p>ActivityName</p>


<p>ActivityName</p>
<p>:</p>
<p>ActivityName</p>

<p>ActivityName</p>
<p>:</p>
<p>ActivityName</p>
</div>
    </div>

<div >
    <table>
        <tr className={styles.tableHead}>
            <th>S.No</th>
            <th>Description</th>
        </tr>
        <tbody>
            <tr className={styles.tableBody}>
                <td>01</td>
                <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae feugiat quam, eget congue nibh.</td>
            </tr>
        </tbody>
    </table>
    </div>

    <div className={styles.last}>
        <p>Signature Of</p>
        <div className={styles.assignednames}>
            <p>Site Engineer</p>
            <p>QC Engineer</p>
            <p>Project Manager</p>
        </div>
    </div>

   </div>

   <div className={Style.MainBtnContainer}>
         <button onClick={handleClick}>REJECT</button>   
         <button onClick={handleClick}>REDO</button>
         <button>APPROVE</button>
   </div>

   {showForm && <form onSubmit={handleSubmit} className={Style.FormContainer}>
        <label >Reason</label>
        <div className={Style.TextContainer}>
          <textarea 
          name="reason" 
          value={reason} 
          id="reason" 
          placeholder="Enter Reason"  
          cols={4} 
          className={Style.TextArea}
          onChange={(e)=>setReason(e.target.value)}
          ></textarea>
          {!reason && <p className={Style.Error}>{error}</p>}
        </div>
        <div className={Style.SubmitBtnContainer}>
          <button>SUBMIT</button>
        </div>
   </form>}
         
        </DialogContent> */}
      </Dialog>
    </>
  );
};

export default ApprovalView;
