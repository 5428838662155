import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import ReactDOM from "react-dom";

const SearchDropdownIdVal = ({
  options,
  value,
  onOptionSelect,
  backgroundColor,
  placeholder,
  onKeyDown,
  tabIndex,
}) => {
  const [filteredOptions, setFilteredOptions] = useState();
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

useEffect(() => {
  if (options && options.length > 0) {
    setFilteredOptions(options);
  }
}, [options]);


  // Filter options based on input value
  const filterOptions = (input) => {
    return options.filter(
      (option) =>
        typeof option.name === "string" &&
        option.name.toLowerCase().includes(input.toLowerCase())
    );
  };

  // Handle input change and open dropdown
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setFilteredOptions(filterOptions(value));
    setIsOpen(true); // Immediately open dropdown
    setSelectedIndex(-1); // Reset selected index
    if (value === "") {
      onOptionSelect({ value: "" });
    }
  };

  // Handle option click
  const handleOptionClick = (option) => {
    setInputValue(option.name);
    onOptionSelect(option);
    setIsOpen(false);
    setSelectedIndex(-1);
  };

  // Handle key down for navigation
  const handleKeyDown = (event) => {
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        setSelectedIndex((prevIndex) =>
          Math.min(prevIndex + 1, filteredOptions?.length - 1)
        );
        break;
      case "ArrowUp":
        event.preventDefault();
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        break;
      case "Enter":
        if (selectedIndex >= 0) {
          handleOptionClick(filteredOptions[selectedIndex]);
        }
        break;
      case "Escape":
        setIsOpen(false);
        break;
      default:
        break;
    }

    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  // Get dropdown position relative to the input field
  const getDropdownPosition = () => {
    const inputRect = inputRef.current?.getBoundingClientRect();
    return inputRect
      ? {
          top: inputRect.bottom + window.scrollY,
          left: inputRect.left + window.scrollX,
        }
      : {};
  };

  // Set dropdown position using layout effect for immediate rendering
  const setDropdownPosition = () => {
    if (dropdownRef.current) {
      const position = getDropdownPosition();
      dropdownRef.current.style.top = `${position.top}px`;
      dropdownRef.current.style.left = `${position.left}px`;
      dropdownRef.current.style.width = `${inputRef.current?.offsetWidth}px`; // Match input width
    }
  };

  // Ensure dropdown stays attached on scroll/resize
  useLayoutEffect(() => {
    if (isOpen) {
      setDropdownPosition();

      const handleScrollOrResize = () => {
        setDropdownPosition(); // Update position on scroll or resize
      };

      window.addEventListener("scroll", handleScrollOrResize, true);
      window.addEventListener("resize", handleScrollOrResize);

      return () => {
        window.removeEventListener("scroll", handleScrollOrResize, true);
        window.removeEventListener("resize", handleScrollOrResize);
      };
    }
  }, [isOpen, inputValue]);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !inputRef.current.contains(event.target)
      ) {
        setIsOpen(false); // Close dropdown if clicked outside
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Dropdown rendering block
  const renderDropdown = () => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
      <ul
        ref={dropdownRef}
        style={{
          position: "absolute",
          maxHeight: "120px", // Limit height to 3 options
          overflowY: "auto", // Enable scrolling if more than 3 options
          border: "1px solid #ccc",
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
          zIndex: 3000,
          listStyleType: "none",
          backgroundColor: "white",
        }}
      >
        {filteredOptions?.length === 0 ? (
          <li style={{ padding: "8px", cursor: "default" }}>No options found</li>
        ) : (
          filteredOptions?.map((option, index) => (
            <li
              key={index}
              onMouseDown={() => handleOptionClick(option)}
              style={{
                padding: "8px",
                fontSize: "14px",
                cursor: "pointer",
                backgroundColor: selectedIndex === index ? "#e9e9e9" : "white",
              }}
            >
              {option.name}
            </li>
          ))
        )}
      </ul>,
      document.body
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setIsOpen(true)} // Open dropdown instantly on focus
        onKeyDown={handleKeyDown}
        style={{
          width: "100%",
          padding: "8px",
          boxSizing: "border-box",
          backgroundColor: backgroundColor,
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
        tabIndex={tabIndex}
      />
      {renderDropdown()}
    </div>
  );
};

export default SearchDropdownIdVal;
