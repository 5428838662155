import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../../WebApiManager";
import Style from "./VehicleAndMachinery.module.css"
import FormHeadings from "../PNMAssets/FormHeadings";
import { useRecoilState } from "recoil";
import { siteId } from "../../../atoms";

const DistributionBox = () => {
  const webApi = new WebApimanager();
  const [formData, setFormData] = useState({
    siteName: "",
    type: "",
    assetCode: "",
    equipmentType: "Distribution Board",
    transitionDate: new Date().toISOString().split("T")[0],
    subCode: "",
    scopeOfWorkDetails: "",
    location: "",
    tower: "",
    floor: "",
    flat: "",
    room: "",
    remark: "",
   
  });

  const [errors, setErrors] = useState({});
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [nameData, setNameData] = useState([]);
  const [id, setId] = useState();
  const [site, setSite] = useRecoilState(siteId);
  const [sites, setDatasites] = useState([]);

  const handleSiteChange = (e) => {
    const { name, value } = e.target;

    setSite(value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // const newValue = name === "kmOrHrReading" ? parseInt(value, 10) : value;
    let newFormData = {
      ...formData,
      [name]: value,
    };
    if (name === "assetCode" && value) {
      const assCode = assetCodeData.find(
        (asset) => asset.assetCode === value.split("/")[0]
      );
      if (assCode) {
        setId(assCode._id)
        newFormData = {
          ...newFormData,

          ["subCode"]: assCode.subCode,
        };
      }
    }
    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
    });
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      // setFormData((prev) => ({ ...prev, assetCode: id }));
      console.log(formData);
      const response = await webApi.post("api/dailyLogReport/db", {...formData,["assetCode"]:id});
      console.log(response);
      window.location.reload();
    }
  };
  console.log({...formData,["assetCode"]:id},"idcheck")

  useEffect(() => {
    const fetchAssetCodeData = async () => {
      const response = await webApi.get(`api/pnms/assetCode`);
      setAssetCodeData(response.data.data.AssetCodes);
    };

    // const fetchSiteData = async () => {
    //   const response = await webApi.get(`api/sites/AllSites`);
    //   setNameData(response.data.sites);
    // };

    // fetchSiteData();
    fetchAssetCodeData();
  }, []);
  useEffect(() => {
 
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
      
          if (!site) {
            const defaultSiteId = response.data.sites[0]._id;
            setSite(defaultSiteId); 
            setFormData((prev) => ({ ...prev, siteName: defaultSiteId })); 
          } else {
            setFormData((prev) => ({ ...prev, siteName: site })); 
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [site]);

  return (
    
    <>
      <div className={Style.VMMainContainer}>
          <h3 className={Style.VMHeading}>
            Daily Log Report For Distribution Board
          </h3>
          <form onSubmit={handleSubmit} className={Style.VMFormContainer}>
            <div className={Style.VMSiteContainer}>
              <div className={Style.VMSiteHeading}>
                <FormHeadings text={"Site Name:"} />
              </div>
              <div className={Style.VMSite}>
                <select
                  className={Style.VMSiteInputField}
                  name="siteName"
                  value={formData.siteName}
                  onChange={handleSiteChange}
                  
                >
                  <option value="" hidden>
                    Select a site
                  </option>
                  {sites &&
                    Array.isArray(sites) &&
                    sites.map((item) => (
                      <option value={item._id} key={item._id}>
                        {item.siteName}
                      </option>
                    ))}
                </select>
                <p className={Style.PNMFormErrors}>{errors.siteName}</p>
              </div>
            </div>
            <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition ID:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                      <input type="text" readOnly className={Style.VMTopInputField} />
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition Date:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                            type="date"
                            className={Style.VMTopInputField}
                            name="transitionDate"
                            value={formData.transitionDate}
                            onChange={handleChange}
                            placeholder="---------"
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.transitionDate}
                          </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Equipment Type:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          name="equipmentType"
                          value={formData.equipmentType}
                          readOnly
                        />
                        <p className={Style.PNMFormErrors}>
                          {errors.equipmentType}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Asset code/Name:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="assetCode"
                          value={formData.assetCode}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            Select name/Asset Code
                          </option>
                          {assetCodeData.length &&
                            assetCodeData
                              .filter(
                                (item) =>
                                  item.type === "Distribution Board"
                              )
                              .map((item) => (
                                <option
                                  value={item.assetCode + "/" + item.name}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              ))}
                        </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.assetCode}
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Type:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="type"
                          value={formData.type}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            select Type
                          </option>
                          <option value="Own">Own</option>
                          <option value="Hire">Hire</option>
                        </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.type}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"SubCode:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          type="text"
                          placeholder="Enter SubCode"
                          name="subCode"
                          onChange={handleChange}
                          value={formData.subCode}
                        />
                        <p className={Style.PNMFormErrors}>{errors.subCode}</p>
                    </div>
                  </div>
                </div>
              </div>
              
                
            </div>
            <div className={Style.VMFormSeperator}></div>

            <div className={Style.VMWorkDetails}>
              <div className={Style.VMWorkDetailsHeading}>
                <FormHeadings text={"Scope of work Details:"} />
              </div>
              <div className={Style.VMWorkDetailsInputCont}>
                <input
                  type="text"
                  placeholder="Enter Scope of work"
                  className={Style.VMWorkDetailsInputField}
                  name="scopeOfWorkDetails"
                  value={formData.scopeOfWorkDetails}
                  onChange={handleChange}
                />
                <p className={Style.PNMFormErrors}>
                  {errors.scopeOfWorkDetails}
                </p>
              </div>

            </div>

            <h3 className={Style.VMHeading} style={{marginBlock:"30px"}}>
            Work Area Details
          </h3>

          
           
           

          <div className={Style.DBMidFormCont}>
                <div className={Style.DBMidFormContent}>
                    <div className={Style.VMMidFormInputHeading}>
                      <FormHeadings text={"Location:"} />
                    </div>
                    <div className={Style.DBMidFormInputContainer}>
                    <input
                          placeholder="Enter Location"
                          type="text"
                          className={Style.VMMidInput}
                          name="location"
                          value={formData.location}
                          onChange={handleChange}
                          
                        />
                        <p className={Style.PNMFormErrors}>
                          {errors.location}
                        </p>
                    </div>
                </div>
                <div className={Style.DBMidFormContent}>
                    <div className={Style.VMMidFormInputHeading}>
                      <FormHeadings text={"Tower:"} />
                    </div>
                    <div className={Style.DBMidFormInputContainer}>
                    <input
                          placeholder="Enter Tower"
                          type="text"
                          className={Style.VMMidInput}
                          name="tower"
                          value={formData.tower}
                          onChange={handleChange}
                          
                        />
                        <p className={Style.PNMFormErrors}>
                          {errors.tower}
                        </p>
                    </div>
                </div>
                <div className={Style.DBMidFormContent}>
                    <div className={Style.VMMidFormInputHeading}>
                      <FormHeadings text={"Floor:"} />
                    </div>
                    <div className={Style.DBMidFormInputContainer}>
                    <input
                          placeholder="Enter Floor"
                          type="text"
                          className={Style.VMMidInput}
                          name="floor"
                          value={formData.floor}
                          onChange={handleChange}
                          
                        />
                        <p className={Style.PNMFormErrors}>
                          {errors.floor}
                        </p>
                    </div>
                </div>
          </div>
            
          <div className={Style.DBMidFormCont}>
                <div className={Style.DBMidFormContent}>
                    <div className={Style.VMMidFormInputHeading}>
                      <FormHeadings text={"Flat:"} />
                    </div>
                    <div className={Style.DBMidFormInputContainer}>
                    <input
                          placeholder="Enter flat"
                          type="text"
                          className={Style.VMMidInput}
                          name="flat"
                          value={formData.flat}
                          onChange={handleChange}
                          
                        />
                        <p className={Style.PNMFormErrors}>
                          {errors.flat}
                        </p>
                    </div>
                </div>
                <div className={Style.DBMidFormContent}>
                    <div className={Style.VMMidFormInputHeading}>
                      <FormHeadings text={"Room:"} />
                    </div>
                    <div className={Style.DBMidFormInputContainer}>
                    <input
                          placeholder="Enter Room"
                          type="text"
                          className={Style.VMMidInput}
                          name="room"
                          value={formData.room}
                          onChange={handleChange}
                          
                        />
                        <p className={Style.PNMFormErrors}>
                          {errors.room}
                        </p>
                    </div>
                </div>
                <div className={Style.DBMidFormContent}>
                    <div className={Style.VMMidFormInputHeading}>
                      <FormHeadings text={"Remark:"} />
                    </div>
                    <div className={Style.DBMidFormInputContainer}>
                    <input
                          placeholder="Enter Remark"
                          type="text"
                          className={Style.VMMidInput}
                          name="remark"
                          value={formData.remark}
                          onChange={handleChange}
                          
                        />
                        <p className={Style.PNMFormErrors}>
                          {errors.remark}
                        </p>
                    </div>
                </div>
          </div>

          <div className={Style.VMBtnContainer}>
            <button >SUBMIT</button>
          </div>

          </form>
    </div>

    </>
  );
};

export default DistributionBox;
