import { Dialog, DialogContent } from '@mui/material'
import React from 'react'
import { RxCross1 } from 'react-icons/rx'

const ViewAssignTask = ({showViewForm,handleClose}) => {
  return (
    <Dialog
        open={showViewForm}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 500px", textAlign: "center", color: "white" }}
          >
            View Task
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "300px" }}
        >
         <div className="AWFormContainer">
            <div className="AWMainInputContainer">
                <div className="ATShowContainer">
                    <p className="ATFieldName">Type</p>
                    <p>:</p>
                    <p></p>
                </div>
                <div className="ATShowContainer">
                    <p className="ATFieldName">Asset Code</p>
                    <p>:</p>
                    <p></p>
                </div>
                <div className="ATShowContainer">
                    <p className="ATFieldName">Name</p>
                    <p>:</p>
                    <p></p>
                </div>

                <div className="ATShowContainer">
                    <p className="ATFieldName">Assign To</p>
                    <p>:</p>
                    <p></p>
                </div>
            </div>
            
            
        </div> 
        </DialogContent>
    </Dialog>
  )
}

export default ViewAssignTask