import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { WebApimanager } from "../../../WebApiManager";
import { siteId } from "../../../atoms";
import { useRecoilState } from "recoil";
import Style from "./VehicleAndMachinery.module.css"
import FormHeadings from "../PNMAssets/FormHeadings";


const BatchingPlant = () => {
  let webApi = new WebApimanager();

  const [sites, setDatasites] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [id, setId] = useState();

  const handleSiteChange = (e) => {
    const { name, value } = e.target;

    setSite(e.target.value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
 
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
      
          if (!site) {
            const defaultSiteId = response.data.sites[0]._id;
            setSite(defaultSiteId); 
            setFormData((prev) => ({ ...prev, siteName: defaultSiteId })); 
          } else {
            setFormData((prev) => ({ ...prev, siteName: site })); 
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [site]);

  const [formData, setFormData] = useState({
    type: "",
    siteName: "",
    assetCode: "",
    equipmentType: "Batching Plant",
    transitionDate: new Date().toISOString().split("T")[0],
    meterStartReadings: "",
    meterEndReadings: "",
    meterDifference: "",
    startDate: "",
    endDate: "",
    differenceDate: "",
    startTime: "",
    endTime: "",
    differenceTime: "",
    Engine2StartReadings: "",
    Engine2EndReadings: "",
    Engine2ReadingsDifference: "",
    scopeOfWorkDetails: "",
    location: "",
    remark: "",
    subCode: "",
    debitNote: "" || "No",
    employeeId: "",
    employeeType: "",
    debitAmount: "",
    debitReason: "",
    totalConcreteProduction: "",
  });

  const [errors, setErrors] = useState({});
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [nameData, setNameData] = useState([]);

  function calculateDateDifference(startDate, endDate) {
    const [startYear, startMonth, startDay] = startDate.split("-").map(Number);
    const [endYear, endMonth, endDay] = endDate.split("-").map(Number);
    console.log(startYear, endYear);
    console.log(startMonth, endMonth);
    console.log(startDay, endDay);

    const start = new Date(startYear, startMonth - 1, startDay);
    const end = new Date(endYear, endMonth - 1, endDay);

    if (end >= start) {
      const diffTime = end.getTime() - start.getTime();
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return Math.round(diffDays);
    } else {
      return -1;
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newFormData = {
      ...formData,
      [name]: value,
    };
    if (name === "assetCode" && value) {
      const assCode = assetCodeData.find(
        (doc, ind) => doc.assetCode === value.split("/")[0]
      );
      if (assCode) {
        console.log(assCode.name);
        setId(assCode._id);
        newFormData = {
          ...newFormData,

          ["subCode"]: assCode.subCode,
        };
        setFormData(newFormData);
      }
    }
    setFormData(newFormData);

    if (name === "startDate" || name === "endDate") {
      if (newFormData.startDate !== "" && newFormData.endDate !== "") {
        const diff = calculateDateDifference(
          newFormData.startDate,
          newFormData.endDate
        );
        setFormData({ ...newFormData, differenceDate: diff });
        if (diff < 0) {
          errors.differenceDate = "start date should be less than end Date";
          setFormData({
            ...newFormData,
            differenceDate: "",
          });
        } else {
          setFormData({ ...newFormData, differenceDate: diff });
          errors.differenceDate = "";
        }
      } else {
        setFormData({ ...newFormData, differenceDate: "" });
      }
    }

    // Calculate meter difference
    if (name === "meterStartReadings" || name === "meterEndReadings") {
      if (
        newFormData.meterStartReadings !== "" &&
        newFormData.meterEndReadings !== ""
      ) {
        const start = parseInt(newFormData.meterStartReadings, 10);
        const end = parseInt(newFormData.meterEndReadings, 10);
        const difference = end - start;
        setFormData({
          ...newFormData,
          meterDifference: difference,
        });
        if (start >= end) {
          errors.meterDifference =
            "Start Reading should be less than End Reading";
          setFormData({
            ...newFormData,
            meterDifference: "",
          });
        } else {
          errors.meterDifference = "";
        }
      } else {
        setFormData({
          ...newFormData,
          meterDifference: "",
        });
      }
    }

    // Calculate Engine2 readings difference
    if (name === "Engine2StartReadings" || name === "Engine2EndReadings") {
      if (
        newFormData.Engine2StartReadings !== "" &&
        newFormData.Engine2EndReadings !== ""
      ) {
        const start = parseInt(newFormData.Engine2StartReadings, 10);
        const end = parseInt(newFormData.Engine2EndReadings, 10);
        const difference = end - start;
        setFormData({
          ...newFormData,
          Engine2ReadingsDifference: difference,
        });
        if (start >= end) {
          errors.Engine2ReadingsDifference =
            "Start Reading should be less than End Reading";
          setFormData({
            ...newFormData,
            Engine2ReadingsDifference: "",
          });
        } else {
          errors.Engine2ReadingsDifference = "";
        }
      } else {
        setFormData({
          ...newFormData,
          Engine2ReadingsDifference: "",
        });
      }
    }

    // Calculate time difference
    if (name === "startTime" || name === "endTime") {
      if (newFormData.startTime && newFormData.endTime) {
        const start = new Date(`1970-01-01T${newFormData.startTime}`);
        const end = new Date(`1970-01-01T${newFormData.endTime}`);
        let difference = (end.getTime() - start.getTime()) / 1000; // Difference in seconds

        const hours = Math.floor(difference / 3600);
        difference = difference % 3600;
        const minutes = Math.floor(difference / 60);

        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedDifference = `${formattedHours}:${formattedMinutes}`;
        if (start >= end) {
          errors.differenceTime = "Start Time should be less than End Date";
          setFormData({
            ...newFormData,
            differenceTime: "",
          });
        } else {
          errors.differenceTime = "";
        }
        setFormData({
          ...newFormData,
          differenceTime: formattedDifference,
        });
      } else {
        setFormData({
          ...newFormData,
          differenceTime: "",
        });
      }
    }
  };

  const filteredNames = assetCodeData.filter(
    (item) => item._id === formData.assetCode
  );
  const filteredSubcodes = assetCodeData.filter(
    (item) => item._id === formData.assetCode && formData.name
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};

    const requiredFields = [
      "type",
      "siteName",
      "equipmentType",
      "assetCode",
      "startDate",
      "endDate",
      "differenceDate",
      "startTime",
      "endTime",
      "differenceTime",
      "meterStartReadings",
      "meterEndReadings",
      "meterDifference",
      "differenceTime",
      "Engine2StartReadings",
      "Engine2EndReadings",
      "Engine2ReadingsDifference",
      "transitionDate",
      "scopeOfWorkDetails",
      "location",
      "remark",
      "subCode",
      "debitNote",
      "totalConcreteProduction",
    ];

    requiredFields.forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
    });

    if (formData.debitNote === "Yes") {
      const conditionalDebitNoteFields = [
        "employeeType",
        "employeeId",
        "debitAmount",
        "debitReason",
      ];
      conditionalDebitNoteFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
      });
    }

    

    console.log("submitted", formData);

    if (Object.keys(formErrors).length > 0) {
      console.log("Form errors:", formErrors);
      setErrors(formErrors);
    } else {
      console.log(formData);
      const tempFormData = {
        siteName: site,
        type: formData.type,
        equipmentType: formData.equipmentType,
        assetCode: id,

        meterStartReadings: Number(formData.meterStartReadings),
        meterEndReadings: Number(formData.meterEndReadings),
        meterDifference: Number(formData.meterDifference),
        Engine2StartReadings: Number(formData.Engine2StartReadings),
        Engine2EndReadings: Number(formData.Engine2EndReadings),
        Engine2ReadingsDifference: Number(formData.Engine2ReadingsDifference),

        startDate: formData.startDate,
        endDate: formData.endDate,
        startTime: formData.startTime,
        endTime: formData.endTime,
        differenceTime: formData.differenceTime,
        transitionDate: formData.transitionDate,
        scopeOfWorkDetails: formData.scopeOfWorkDetails,
        location: formData.location,
        remark: formData.remark,
        subCode: formData.subCode,
        debitAmount: Number(formData.debitAmount),
        debitReason: formData.debitReason,

        debitNote: formData.debitNote || "No",
        employeeType: formData.employeeType,

        employeeId: formData.employeeId,
      };

      console.log(tempFormData);
      const response = await webApi.post("api/dailyLogReport/bp", tempFormData);
      if(response.status === 200 || response.status === 201){
        window.location.reload();
      }
      console.log(response);
    }
  };

  useEffect(() => {
    const fetchAssetCodeData = async () => {
      let response = await webApi.get(`api/pnms/assetCode`);
      setAssetCodeData(response.data.data.AssetCodes);
    };

    fetchAssetCodeData();
  }, []);

  return (
    
    <>
    <div className={Style.VMMainContainer}>
          <h3 className={Style.VMHeading}>
            Daily Log Report For Batching Plant
          </h3>
          <form onSubmit={handleSubmit} className={Style.VMFormContainer}>
            <div className={Style.VMSiteContainer}>
              <div className={Style.VMSiteHeading}>
                <FormHeadings text={"Site Name:"} />
              </div>
              <div className={Style.VMSite}>
                <select
                  className={Style.VMSiteInputField}
                  name="siteName"
                  value={formData.siteName}
                  onChange={handleSiteChange}
                  
                >
                  <option value="" hidden>
                    Select a site
                  </option>
                  {sites &&
                    Array.isArray(sites) &&
                    sites.map((item) => (
                      <option value={item._id} key={item._id}>
                        {item.siteName}
                      </option>
                    ))}
                </select>
                <p className={Style.PNMFormErrors}>{errors.siteName}</p>
              </div>
            </div>
            <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition ID:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                      <input type="text" readOnly className={Style.VMTopInputField} />
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition Date:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                            type="date"
                            className={Style.VMTopInputField}
                            name="transitionDate"
                            value={formData.transitionDate}
                            onChange={handleChange}
                            placeholder="---------"
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.transitionDate}
                          </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Equipment Type:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          name="equipmentType"
                          value={formData.equipmentType}
                          readOnly
                        />
                        <p className={Style.PNMFormErrors}>
                          {errors.equipmentType}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Asset code/Name:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="assetCode"
                          value={formData.assetCode}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            Select name/Asset Code
                          </option>
                          {assetCodeData.length &&
                            assetCodeData
                              .filter(
                                (item) =>
                                  item.type === "Batching Plant"
                              )
                              .map((item) => (
                                <option
                                  value={item.assetCode + "/" + item.name}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              ))}
                        </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.assetCode}
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Type:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="type"
                          value={formData.type}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            select Type
                          </option>
                          <option value="Own">Own</option>
                          <option value="Hire">Hire</option>
                        </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.type}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"SubCode:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          type="text"
                          placeholder="Enter SubCode"
                          name="subCode"
                          onChange={handleChange}
                          value={formData.subCode}
                        />
                        <p className={Style.PNMFormErrors}>{errors.subCode}</p>
                    </div>
                  </div>
                </div>
              </div>
              
                
            </div>
            <div className={Style.VMFormSeperator}></div>
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>1st Km/Hr Meter</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Start:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                        <input
                              placeholder="Enter Reading"
                              type="number"
                              className={Style.VMMidInput}
                              name="meterStartReadings"
                              value={formData.meterStartReadings}
                              onChange={handleChange}
                            />
                            <p className={Style.PNMFormErrors}>
                              {errors.meterStartReadings}
                            </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"End:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            placeholder="Enter Reading"
                            type="number"
                            className={Style.VMMidInput}
                            name="meterEndReadings"
                            value={formData.meterEndReadings}
                            onChange={handleChange}
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.meterEndReadings}
                          </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            type="number"
                            className={Style.VMMidInput}
                            name="meterDifference"
                            value={formData.meterDifference}
                            onChange={handleChange}
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.meterDifference}
                          </p>
                      </div>
                </div>
            </div>
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>2nd Km/Hr Meter</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Start:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                        <input
                              placeholder="Enter Reading"
                              type="number"
                              className={Style.VMMidInput}
                              name="Engine2StartReadings"
                              value={formData.Engine2StartReadings}
                              onChange={handleChange}
                            />
                            <p className={Style.PNMFormErrors} style={{width:"11%"}}>
                              {errors.Engine2StartReadings}
                            </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"End:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            placeholder="Enter Reading"
                            type="number"
                            className={Style.VMMidInput}
                            name="Engine2EndReadings"
                            value={formData.Engine2EndReadings}
                            onChange={handleChange}
                            
                          />
                          <p className={Style.PNMFormErrors} style={{width:"11%"}}>
                            {errors.Engine2EndReadings}
                          </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            type="number"
                            className={Style.VMMidInput}
                            name="Engine2ReadingsDifference"
                            value={formData.Engine2ReadingsDifference}
                            onChange={handleChange}
                            
                          />
                          <p className={Style.PNMFormErrors} style={{width:"11%"}}>
                            {errors.Engine2ReadingsDifference}
                          </p>
                      </div>
                </div>
            </div>
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>Date:</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Start:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                        <input
                              placeholder="Enter Reading"
                              type="date"
                              className={Style.VMInputDate}
                              name="startDate"
                              value={formData.startDate}
                              onChange={handleChange}
                            />
                            <p className={Style.PNMFormErrors}>
                              {errors.startDate}
                            </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"End:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            placeholder="Enter Reading"
                            type="date"
                            className={Style.VMInputDate}
                            name="endDate"
                            value={formData.endDate}
                            onChange={handleChange}
                            
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.endDate}
                          </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            type="number"
                            className={Style.VMMidInput}
                            name="differenceDate"
                            value={formData.differenceDate}
                            onChange={handleChange}
                            
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.differenceDate}
                          </p>
                      </div>
                </div>
            </div>
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>Clock:</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"In:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                        <input
                              placeholder="Enter Reading"
                              type="time"
                              className={Style.VMMidInput}
                              name="startTime"
                              value={formData.startTime}
                              onChange={handleChange}
                            />
                            <p className={Style.PNMFormErrors}>
                              {errors.startTime}
                            </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Out:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            placeholder="Enter Reading"
                            type="time"
                            className={Style.VMMidInput}
                            name="endTime"
                            value={formData.endTime}
                            onChange={handleChange}
                            
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.endTime}
                          </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            type="text"
                            className={Style.VMMidInput}
                            name="differenceTime"
                            value={formData.differenceTime}
                            onChange={handleChange}
                            
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.differenceTime}
                          </p>
                      </div>
                </div>
            </div>

            <div className={Style.VMFormSeperator}></div>

            <div className={Style.VMWorkDetails} style={{marginBottom:"30px"}}>
              <div className={Style.VMWorkDetailsHeading}>
                <FormHeadings text={"Total Concrete Production:"} />
              </div>
              <div className={Style.VMWorkDetailsInputCont}>
                <input
                  type="text"
                  placeholder="Enter Total Concrete Production"
                  className={Style.VMWorkDetailsInputField}
                  name="totalConcreteProduction"
                  value={formData.totalConcreteProduction}
                  onChange={handleChange}
                />
                <p className={Style.PNMFormErrors}>
                  {errors.totalConcreteProduction}
                </p>
              </div>

            </div>

            <div className={Style.VMWorkDetails}>
              <div className={Style.VMWorkDetailsHeading}>
                <FormHeadings text={"Scope of work Details:"} />
              </div>
              <div className={Style.VMWorkDetailsInputCont}>
                <input
                  type="text"
                  placeholder="Enter Scope of work"
                  className={Style.VMWorkDetailsInputField}
                  name="scopeOfWorkDetails"
                  value={formData.scopeOfWorkDetails}
                  onChange={handleChange}
                />
                <p className={Style.PNMFormErrors}>
                  {errors.scopeOfWorkDetails}
                </p>
              </div>

            </div>

            <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
              <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Location:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                          type="text"
                          className={Style.VMTopInputField}
                          name="location"
                          placeholder="Enter Work Location"
                          value={formData.location}
                          onChange={handleChange}
                        />
                        <p className={Style.PNMFormErrors}>
                          {errors.location}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Remarks:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                        <input
                            type="text"
                            className={Style.VMTopInputField}
                            name="remark"
                            placeholder="Enter Remarks"
                            value={formData.remark}
                            onChange={handleChange}
                          />
                          <p className={Style.PNMFormErrors}>{errors.remark}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Debit Note:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                            className={Style.VMTopInputField}
                            name="debitNote"
                            value={formData.debitNote || "No"}
                            onChange={handleChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                          <p className={Style.PNMFormErrors} style={{left:"10%"}}>
                            {errors.debitNote}
                          </p>
                    </div>
                  </div>
                </div>
                
              </div>
              
              {formData.debitNote === "Yes" && <>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Employee:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  name="employeeType"
                                  value={formData.employeeType}
                                  placeholder="Enter employee Type"
                                  onChange={handleChange}
                                />

                                <p className={Style.PNMFormErrors}>
                                  {errors.employeeType}
                                </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Employee ID:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  name="employeeId"
                                  placeholder="Enter Employee Id"
                                  value={formData.employeeId}
                                  onChange={handleChange}
                                />

                                <p className={Style.PNMFormErrors}>
                                  {errors.employeeId}
                                </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Debit Reason:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  placeholder="Enter Reason"
                                  name="debitReason"
                                  value={formData.debitReason}
                                  onChange={handleChange}
                                />
                                <p className={Style.PNMFormErrors}>
                                  {errors.debitReason}
                                </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Debit Amount:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                    type="text"
                                    className={Style.VMTopInputField}
                                    name="debitAmount"
                                    placeholder="Enter amount"
                                    value={formData.debitAmount}
                                    onChange={(e)=>{
                                      const value=e.target.value
                                      if (!isNaN(value) ) {
                                        handleChange(e);
                                      }
                                    }}
                                  />
                                  <p className={Style.PNMFormErrors}>
                                    {errors.debitAmount}
                                  </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>}
                
            </div>
           
            
            <div className={Style.VMBtnContainer}>
              <button >SUBMIT</button>
            </div>

          </form>
    </div>
      
    </>
  );
};

export default BatchingPlant;
