import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import * as Icon from "react-bootstrap-icons";
import RFIEditReopenDialog from "../../ROtoSiteLevel/RFIEditReopenDialog";

const RfiViewModal = ({ open, onClose, drawingData,rfiRaisedAccess,selectedStatus }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [tableRowData, setTableRowData] = useState([]);

  useEffect(() => {
    console.log("ViewTabs for issues", drawingData);
  }, [drawingData]);

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  const handleViewClose = () => {
    onClose();
    if (
      drawingData.status === "Requested" ||
      drawingData.status === "Rejected"
    ) {
      setTableRowData(drawingData);
    }
  };

  const handleEditPopup = () => {
    setShowEditPopup(true);
  };

  const singleDrawingData = drawingData || {};

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const calculateDueDays = (submissionDate, submittedDate) => {
    if (!submissionDate || !submittedDate) return "NA";
    const submission = new Date(submissionDate);
    const submitted = new Date(submittedDate);
    const diffTime = submission - submitted;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays >= 0 ? diffDays : "Overdue";
  };

  return (
    <>
      {showEditPopup && (
        <RFIEditReopenDialog
          showEditPopup={showEditPopup}
          setShowEditPopup={setShowEditPopup}
          tableRowData={tableRowData}
        />
      )}

      <Dialog
        open={open}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            fontFamily: "Poppins",
            borderRadius: "10px",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#4D4D4D",
            height: "50px",
          }}
        >
          <p style={{ margin: "0 auto", textAlign: "center", color: "white" }}>
            View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          {singleDrawingData?.status === "Requested" && (
            <>{rfiRaisedAccess &&
            <button
              style={{
                backgroundColor: "#e47331",
                color: "white",
                padding: "6px 20px",
                position: "absolute",
                right: "20px",
              }}
              onClick={() => handleEditPopup()}
            >
              Edit
            </button>
}
</>
          )}
          {singleDrawingData?.status === "Rejected" && (
            <>{rfiRaisedAccess &&
            <button
              style={{
                backgroundColor: "#e47331",
                color: "white",
                padding: "6px 20px",
                position: "absolute",
                right: "20px",
              }}
              onClick={() => handleEditPopup()}
            >
              ReOpen
            </button>
            }
</>
          )}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "20px",
              padding: "20px",
              color: "#4D4D4D",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "28px",
              justifyContent: "center",
            }}
          >
            {/* Map over the fields as needed */}
            <div>Drawing Number</div>
            <div>:</div>
            <div>{singleDrawingData?.drawingNo || "NA"}</div>

            <div>Design Consultant</div>
            <div>:</div>
            <div>
              {singleDrawingData?.designDrawingConsultant
                ?.designDrawingConsultant || "NA"}
            </div>

            <div>Design Category</div>
            <div>:</div>
            <div>{singleDrawingData?.category?.category || "NA"}</div>

            <div>Drawing Title</div>
            <div>:</div>
            <div>{singleDrawingData?.drawingTitle || "NA"}</div>

            <div>Requested Date</div>
            <div>:</div>
            <div>
              {singleDrawingData?.requestedDate
                ? formatDate(singleDrawingData.requestedDate)
                : "NA"}
            </div>

            <div>Expected Date</div>
            <div>:</div>
            <div>
              {singleDrawingData?.expectedDate
                ? formatDate(singleDrawingData.expectedDate)
                : "NA"}
            </div>

            {/* <div>Nature of Request Info</div>
            <div>:</div>
            <div>
              {singleDrawingData?.natureOfRequestedInformation?.length > 0
                ? singleDrawingData.natureOfRequestedInformation.join(", ")
                : "-"}
            </div>

            <div>Reason</div>
            <div>:</div>
            <div>{singleDrawingData?.remarks || "NA"}</div> */}
             
            <div>Status</div>
            <div>:</div>
            <div>{singleDrawingData?.status || "NA"}</div>

            {singleDrawingData?.status === "Rejected" && (
              <>
                <div>Rejected Reason</div>
                <div>:</div>
                <div>{singleDrawingData?.reason || "NA"}</div>
              </>
            )}
            <div>Revision</div>
            <div>:</div>
            <div>
              {Array.isArray(singleDrawingData?.acceptedRORevisions) &&
              singleDrawingData.acceptedRORevisions[selectedTab]
                ? singleDrawingData.acceptedRORevisions[selectedTab].revision
                : "-"}
            </div>
       
      <div >Nature of Request Information</div>
      <div>:</div>
      <div>
        {singleDrawingData?.natureOfRequestedInformationReasons?.length > 0 ? (
          singleDrawingData.natureOfRequestedInformationReasons.map((item) => (
            <div key={item._id} style={{ marginTop: "10px" }}>
              <div >{item.natureOfRequest}</div>
              <ul style={{margin: 0 }}>
                {item.reason.map((reason, index) => (
                  <li key={index} style={{ color: "#4D4D4D" }}>
                    {reason}
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <div>No nature of requested information available.</div>
        )}
      </div>

            <div>Requested Drawing File</div>
            <div>:</div>
            <div style={{ display: "flex" }}>
              <Icon.Image
                size={20}
                style={{ marginRight: "10px", cursor: "pointer" }}
                // onClick={() => handleViewFile(singleDrawingData?._id, "")}
              />
              <Icon.Download
                size={20}
                style={{ marginRight: "5px", cursor: "pointer" }}
                // onClick={() => handleDownload(singleDrawingData?._id, "")}
              />
            </div>

            {singleDrawingData?.status === "Rejected" && (
              <>
                <div>Rejected Drawing File</div>
                <div>:</div>
                <div style={{ display: "flex" }}>
                  <Icon.Image
                    size={20}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    // onClick={() =>
                    //   handleViewFile(singleDrawingData?._id, "Rejected")
                    // }
                  />
                  <Icon.Download
                    size={20}
                    style={{ marginRight: "5px", cursor: "pointer" }}
                    // onClick={() =>
                    //   // handleDownload(singleDrawingData?._id, "Rejected")
                    // }
                  />
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RfiViewModal;
