import React, { useEffect, useState } from 'react'
import TableWrapper from '../../../Utilities/TableWrapper'
import "./WorkFlow.css"
import { Tab, Tabs } from '../../Tabs/Tabv1';
import * as Icon from "react-bootstrap-icons";
import Filter from "../../../Images/Filter.svg";
import ToggleSwitch from '../../Sites/ToggleSwitch';
import ToggleSubModuleSwitch from '../../Sites/ToggleSubModuleSwitch';
import { WebApimanager } from '../../../WebApiManager';
import { siteId } from '../../../atoms';
import { useRecoilState } from "recoil";
import { RiDeleteBin5Fill } from "react-icons/ri";
import WorkFlowSiteHead from './WorkFlowSiteHead';
import WorkFlowSiteLevel from './WorkFlowSiteLevel';
import SitesDropdown from '../../../Utilities/SitesDropdown';


const WorkFlow = () => {

  let webApi = new WebApimanager();

  const [site, setSite] = useRecoilState(siteId);
  const [currentView, setCurrentView] = useState("Architect");
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false)
  const [architectRoles,setArchitectRoles] = useState([])
  const [department,setDepartment] = useState('')
  const [datas,setDatas] = useState([])
  const [existingConsultants,setExistingConsultants] = useState([])
  const [existingCategories, setExistingCategories] = useState([])
  const [selectedConsultant, setSelectedConsultant] = useState([]);
  const [selectedRole,setSelectedRole] = useState([])
  const [filteredRole,setFilteredRole] = useState([])
  const [errors, setErrors] = useState({})
  const [deptError,setDeptError] = useState('')
  const [formData, setFormData] = useState({
    designConsultant: "",
    DrawingCategory: [],
  });

  const [sites, setDatasites] = useState([]);
  const [isCheckedRFI,setIsCheckedRFI] = useState(false)
  const [isCheckedFrwd,setIsCheckedFrwd] = useState(false)
  const [isCheckedAny,setIsCheckedAny]= useState(false)
  const [assignedData,setAssignedData] = useState([])

  const handleSiteChange = async (e) => {
    setSite(e.target.value);
    
  }

  useEffect(()=>{
    setDepartment("")
    setDatas([])
    setExistingConsultants([])
    setExistingCategories([])
    setSelectedConsultant([])
    setSelectedRole([])
    setFormData({designConsultant: "",
      DrawingCategory: [],})
      setIsCheckedRFI(false)
      setIsCheckedFrwd(false)
      setIsCheckedAny(false)
      setAssignedData([])

  },[currentView])

  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const handleTabViewChange = (event, newValue) => {
    console.log(newValue);
    setCurrentView(newValue);
  };

  const handleInputSearchChange = () =>{

  }
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Clear categories and set consultant
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "designConsultant" && { DrawingCategory: [] }), // Clear categories if consultant changes
    }));

    // Fetch new categories based on the selected consultant
    if (name === "designConsultant") {
      setSelectedConsultant(value);
    }
  };
  const handleCategoryChange = (index, value) => {
    setSelectedRole((prev)=>[...prev,value])
    const newCategories = [...formData.DrawingCategory];
    newCategories[index] = value;
    setFormData({ ...formData, DrawingCategory: newCategories });
  };

  const fetchRoUserDepartment = async(site)=>{
    try {
      const response = await webApi.get(`api/drawingWorkFlow/?siteId=${site}`)
      console.log(response,'response for RO department user')
      if(response.status === 200)
        setDatas(response.data.data.users)
        setExistingConsultants([...new Set(response.data.data.users.map((item)=>item.department))])
        if(response.data.data.users){
          const data = response.data.data.users.map((user)=>user.permittedSites.map((item)=>{ 
            if(item?.enableModules?.siteId === site){
              console.log("hgsdb")
              if(item.enableModules.siteId?.drawingDetails?.roDetails?.rfiRaisedAccess){
                setIsCheckedRFI(true)

              }
              if(item.enableModules.siteId?.drawingDetails?.roDetails?.forwardAccess){
                setIsCheckedFrwd(true)

              }
              if(item.enableModules.siteId?.drawingDetails?.roDetails?.rfiRaisedAccess || item.enableModules.siteId?.drawingDetails?.roDetails?.forwardAccess){
                setIsCheckedAny(true)
              }

            }
            
            }))
          console.log(data,"data")
          console.log(site,"site")
        }
    } catch (error) {
      console.log(error.message)
    }
  }

  const fetchArchitectConsultant = async(site)=>{
    try {
        const response = await webApi.get(`api/drawingWorkFlow/?siteId=${site}`)
        console.log(response,"response for architect")
        if(response.status === 200){
          setArchitectRoles(response.data.data.users)
        }
      
    } catch (error) {
      console.log(error.message)
    }

  }
  useEffect(()=>{
    if(currentView==="RO"){
      fetchRoUserDepartment(site);
    }
    if(currentView === "Architect"){
      fetchArchitectConsultant(site)
    }
  },[currentView,site])


 

  

  

  const handleForm1Submit =(e)=>{
    e.preventDefault();
    let isValid = true;
    const newErrors = {};

    if (!department) {
      newErrors.department = "Department is required";
      isValid = false;
    }

    setDeptError(newErrors.department || '');
    if (!isValid) {
      setErrors(newErrors);
      return;
    }
    

  }


  const handleForm2Submit = async(e) => {
    e.preventDefault();
console.log("jdfskjdn ")
    // Validation for department
    let valid = true;
    const newErrors = {};

    if (!formData.designConsultant?.trim()) {
      newErrors.designConsultant = "Department is required";
      valid = false;
    }
    if (!formData.DrawingCategory.length) {
      newErrors.DrawingCategory = "At least one Drawing Category must be selected";
      valid = false;
    } else {
      const hasEmptyCategory = formData.DrawingCategory.some((cat) => cat === "");
    if (hasEmptyCategory) {
      newErrors.DrawingCategory = "Each Drawing Category must have a valid value";
      valid = false;
    }
    }

    if (!valid) {
      setErrors(newErrors);
      return;
    }

    try {
      const payload={rfiRaisedAccess:isCheckedRFI,forwardAccess:isCheckedFrwd}
      const response = await webApi.put(`api/drawingWorkFlow/updateModules/${datas[0]._id}?siteId=${site}&module=roDetails`,payload)
      console.log(response,"response after form submition in Ro")
    } catch (error) {
      console.log(error.message)
    }

    
  }
  const handleAddField = () => {
    setFormData({
      ...formData,
      DrawingCategory: [...formData.DrawingCategory, ""],
    });
  };
  const handleRemoveField = (index) => {
    const newCategories = formData.DrawingCategory.filter(
      (_, i) => i !== index
    );
    setFormData({ ...formData, DrawingCategory: newCategories });
  };

  const onDelete = (categoryId) => {
    const apiEndpoint = `api/assignDesignConsultants/designConsultant?assignDesignConsultantsToDepartmentId=671f8a21b61de4eeb5661537&designConsultantId=6718b71c9a1f69bed0a049ed`;

    webApi
      .delete(apiEndpoint)
      .then((response) => {
        console.log(" deleted successfully:", response.data);
        // getAll();
        // fetchCategories();
      })
      .catch((error) => {
        console.error("Error deleting :", error);
      });
  };

  const fetchViewConsultantData = async(site)=>{
    try {
      const response = await webApi.get(`api/designDrawingConsultant/getAll?siteId=${site}`)
      console.log(response.data.data.users,"response for view")
      if(response.status === 200){
        const filteredData= assignedData.length ? response.data.data.users.filter((user)=>!assignedData.includes(user.role)):[]
        console.log(filteredData,"filteredData")
        setExistingCategories(filteredData)
      }
    } catch (error) {
      console.log(error.message)
      
    }
  }

  useEffect(()=>{
    if(formData.designConsultant && currentView === "RO"){
      fetchViewConsultantData(site)
      fetchViewCategories()
    }
    
  },[formData,site,])

  // useEffect(()=>{
  //   const filteredData = existingCategories.length && existingCategories.filter((cat)=>!(selectedRole.includes(cat.role)))
  //   setFilteredRole(filteredData)
  // },[selectedRole,existingCategories])
  const fetchViewCategories = async () =>{
    try {
      const response = await webApi.get(`api/assignDesignConsultants/designConsultantsByDepartment?department=${formData.designConsultant}&module=ro`)
      console.log(response,"response in the view cat")
      if(response.status === 200){
        const datas = response.data.data.assignments.filter((data)=>data.module === "ro")
        console.log(datas,"datas")
        setAssignedData(datas[0].designConsultants)
      }

    } catch (error) {
      console.log(error.message)
      
    }
  }

  // useEffect(()=>{
  //   if(currentView === "RO" && formData.designConsultant)
  //   fetchViewCategories(site)
  // },[site,selectedRole,formData])
  useEffect(()=>{
    console.log(existingCategories,"existingCategories")
  },[existingCategories])

  
  const handleToggle = (val) => {
    if (val === 0) {
      setIsCheckedAny((prev) => {
        if (prev) {
          setIsCheckedRFI(false);
          setIsCheckedFrwd(false);
        }
        return !prev;
      });
    }
    if (val === 1) {
      setIsCheckedRFI((prev) => !prev);
    }
    if (val === 2) {
      setIsCheckedFrwd((prev) => !prev);
    }
  };


  useEffect(() => {
    if (isCheckedRFI || isCheckedFrwd) {
      setIsCheckedAny(true);
    } else {
      setIsCheckedAny(false);
    }
  }, [isCheckedRFI, isCheckedFrwd]);
  
  
  

  return (
    <TableWrapper heightStyle={currentView==="RO" || currentView === "SiteHead" ?true:false}
        children={
            <>
                <div className="DWFMainContainer " >
                    <div style={{ display: "flex" }}>
                        <Tabs value={currentView} onChange={handleTabViewChange}>
                        <Tab label="Architect" value="Architect" />

                        <Tab label="RO" value="RO" />
                        <Tab label="SiteHead" value="SiteHead" />

                        <Tab label="SiteLevel" value="SiteLevel" />
                        </Tabs>
                    </div>
                    <div
                        style={{
                            position: "relative",
                            paddingLeft: "20px",
                            height: "50px",
                            display:"flex"
                        }}
                >
                  <SitesDropdown
                  siteId={site}
                  handleSiteChange={handleSiteChange}
                  sites={sites}
                  defaultValue={"Select Site"}
            />
                    </div>
                </div>
                {currentView === "Architect" && (
                    <div className='DWFMainContentContainer'>
                        <div className="DWFHeadingContainer">
                            <p>List of Consultant for the site</p>
                        </div>
                        {architectRoles.length ? architectRoles.map((data,index)=>(<div className="DWFContentContainer">
                          <label style={{width:"100%",textAlign:"left"}}>{data.role}</label>
                          {index+1!==architectRoles.length && <div style={{border:"1px dotted #979797",marginTop:"10px"}}></div>}
                        </div>)):""}
                    </div>
                )}
                {currentView === "RO" && (
                    <div className="DWFMainBoxesContainer" >
                      <div className="DWFBoxContainer">
    
                        <form style={{width:"100%",border:"1px solid #979797",paddingBottom:"40px",height:"max-content"}} onSubmit={handleForm1Submit} >
                            <div className="DWFBoxHeadingContainer">
                                <p>View Category</p>
                            </div>

                            <div className="DWFCatMainContent">
                                <label>Select Department &nbsp; &nbsp; *</label>
                                <select name="department" value={department} onChange={(e)=>setDepartment(e.target.value)}>
                                <option value="">Select Department</option>
                                    {existingConsultants.map((dept)=>(<option value={dept}>{dept}</option>))}
                                </select>
                                {deptError &&  <p style={{ textAlign:"left",  color: "red", fontSize: "14px" }}>{deptError}</p>}
                                <div className="DWFAccessMainContainer">
                                    <label>Are you giving access for the Department</label>
                                    
                                    <ToggleSubModuleSwitch isChecked={isCheckedAny} setWidth={true}
                                          onToggle={() =>
                                            handleToggle(0)
                                          }
                                        />
                                    
                                </div>
                               

                            </div>
                            <div style={{width:"100%",display:"flex",padding:" 0px 20px",height:"30px",alignItems:"center",marginTop:"10px"}}>
                                    <label style={{fontSize:"16px"}}>RFI Raised Access</label>
                                    <ToggleSubModuleSwitch isChecked={isCheckedRFI} onToggle={() =>
                                            handleToggle(1)
                                          }/>
                            </div>
                            <div style={{width:"100%",display:"flex",padding:" 0px 20px",height:"30px",alignItems:"center",marginTop:"10px"}}>
                                    <label style={{fontSize:"16px"}}>Forward Access &nbsp; &nbsp;</label>
                                    <ToggleSubModuleSwitch isChecked={isCheckedFrwd} onToggle={() =>
                                            handleToggle(2)
                                          }/>
                            </div>
                            {/* <div style={{width:"100%",display:"flex",justifyContent:"center",marginTop:"50px"}}>
                                <button style={{padding:"6px 10px",color:"white",backgroundColor:"#e47331"}}>SUBMIT</button>
                            </div> */}

                        </form>
                       {isCheckedRFI && <div style={{marginTop:"30px",padding:"20px 0px",width:"100%",border:"1px solid #979797",height:"max-content"}}>
                          <label style={{padding:"0px 30px",textAlign:"start",width:"100%"}}>RFI Rasied Access</label>
                          <div style={{marginInline:"20px",border:"1px dotted #979797"}}></div>
                          <div style={{marginTop:"30px" ,width:"100%",display:"flex",justifyContent:"space-between",padding:"0px 20px"}}>
                            <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>UserName</p>
                            <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>Role</p>
                            <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>Employee ID</p>
                          </div>
                          <div style={{marginInline:"20px",border:"1px dotted #979797"}}></div>
                          { datas.length && datas.filter((data)=>data.department === department).map((item)=>(<div key={item._id} style={{marginTop:"30px" ,width:"100%",display:"flex",justifyContent:"space-between",padding:"0px 20px"}}>
                            <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>{item.firstName}</p>
                            <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>{item.role}</p>
                            <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>{item.empId}</p>
                          </div>))}

                        </div>}

                         {isCheckedFrwd && <div style={{marginTop:"30px",padding:"20px 0px",width:"100%",border:"1px solid #979797",height:"max-content"}}>
                          <label style={{padding:"0px 30px",textAlign:"start",width:"100%"}}>Forward Access</label>
                          <div style={{marginInline:"20px",border:"1px dotted #979797"}}></div>
                          <div style={{marginTop:"30px" ,width:"100%",display:"flex",justifyContent:"space-between",padding:"0px 20px"}}>
                            <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>UserName</p>
                            <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>Role</p>
                            <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>Employee ID</p>
                          </div>
                          <div style={{marginInline:"20px",border:"1px dotted #979797"}}></div>


                          { datas.length && datas.filter((data)=>data.department === department).map((item)=>(<div key={item._id} style={{marginTop:"30px" ,width:"100%",display:"flex",justifyContent:"space-between",padding:"0px 20px"}}>
                            <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>{item.firstName}</p>
                            <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>{item.role}</p>
                            <p style={{width:"30%",textAlign:"center",fontWeight:"500"}}>{item.empId}</p>
                          </div>))}
                        </div>}
                      </div>
                      <div className="DWFBoxContainer">
                        <form style={{width:"100%",border:"1px solid #979797",paddingBottom:"40px",height:"max-content"}} className="DWFBoxContainer" onSubmit={handleForm2Submit}>
                          <   div className="DWFBoxHeadingContainer">
                                  <p>Add Consultant to the Department</p>
                              </div>
                              <div style={{width:"100%",marginTop:"30px",padding:"0px 20px",display:"flex",justifyContent:"space-between"}}>
                                <label style={{fontSize:"16px",marginTop:"4px"}}>Select Department</label>
                                <button style={{padding:"6px 10px",backgroundColor:"#e47331",color:"white",display:"flex",alignItems:"center",borderRadius:"5px"}} onClick={handleAddField}> <Icon.PlusCircle size={14} style={{ marginRight: "5px" }} />{" "}Add </button>
                              </div>
                              <div className="DWFSecondBoxInputContainer">
                                <select name="designConsultant" value={formData.designConsultant} onChange={handleChange}>
                                    <option value="">Select Department</option>
                                      {existingConsultants.map((dept)=>(<option value={dept}>{dept}</option>))}
                                </select>
                                {errors.designConsultant ? <p style={{ textAlign:"left",  color: "red", fontSize: "14px" }}>{errors.designConsultant}</p>:(errors.DrawingCategory && formData.DrawingCategory.length ===0) ? <p style={{ textAlign:"left",  color: "red", fontSize: "14px" }}>{errors.DrawingCategory}</p>:""}
                              </div>
                              {formData.DrawingCategory && formData.DrawingCategory.map((category,index)=>(<div key={category.id} style={{ width: "100%", marginTop: "30px", padding: "0px 20px", display: "flex", justifyContent: "space-between",position:"relative" }}>
                                <div style={{ width: "5%", height: "45px", backgroundColor: "#f3f3f3", outline: "none", border: "1px solid #97979740", color: "black", paddingTop: "10px",position:"relative" }}>{index+1}</div>
                                <select 
                                  className="DWFinputFeild" 
                                  placeholder="Enter category" 
                                  type="text" 
                                  value={category}
                                  onChange={(e) =>
                                  handleCategoryChange(index, e.target.value)

                        }
                                >
                                  <option value="">Select Category</option>
                                  {existingCategories &&
                                    existingCategories.map((cat) => (
                                      <option key={cat._id} value={cat._id}>
                                        {cat.role}
                                      </option>
                                    ))}
                                </select>
                                {(errors.DrawingCategory && formData.DrawingCategory.length) && <p style={{ color: "red", fontSize: "14px",position:"absolute",top:"100%" }}>{errors.DrawingCategory}</p>}
                                <Icon.DashCircleFill
                                  size={20}
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                    marginLeft: "5px",
                                    position:"absolute",
                                    right:"0px",
                                    top:"10px"
                                  }}
                                  onClick={() => handleRemoveField(index)}
                                />
                              </div>))}
                            
                              {formData.DrawingCategory.length? <div style={{width:"100%",display:"flex",justifyContent:"center",marginTop:"50px"}}>
                                  <button style={{padding:"6px 10px",color:"white",backgroundColor:"#e47331"}}>SUBMIT</button>
                              </div>:""}
                          
                        </form>

                        <div style={{width:"100%",border:"1px solid #979797",paddingBottom:"40px",height:"max-content",marginTop:"20px"}} className="DWFBoxContainer" onSubmit={handleForm2Submit}>
                          <   div className="DWFBoxHeadingContainer">
                                  <p>View Consultant to the Department</p>
                              </div>
                              {assignedData.length ? assignedData?.map((assData)=>(<div style={{width:"100%"}}>
                                <div style={{display:"flex",justifyContent:"space-between",width:"100%",marginTop:"10px",padding:"0px 20px"}}>
                                  <p>{assData.role}</p>
                                  <RiDeleteBin5Fill onClick={()=>onDelete(assData._id)}/>
                                </div>
                                <div style={{marginInline:"20px",border:"1px dotted #979797"}}></div>
                                
                                
                              </div>)):""}
                          
                        </div>
                      </div>
                    </div>
                )}
                {currentView === "SiteHead" && <WorkFlowSiteHead/> }
                {currentView === "SiteLevel" && <WorkFlowSiteLevel />}
            </>
        }
        />
  )
}

export default WorkFlow