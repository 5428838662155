import React, { useState, useEffect } from "react";
import DrawingTableWrapper from "../../Components/DrawingTableWrapper";
import { Dialog, DialogContent } from "@mui/material";
import * as Icon from "react-bootstrap-icons";
import Eye from "../IconsSiteHead/Eye.svg";
import Download from "../IconsSiteHead/Download.svg";
import { useNavigate } from "react-router-dom";
import pen from "../IconsSiteHead/penIcon.svg";
import { WebApimanager } from "../../../../WebApiManager";
import { siteId } from "../../../../atoms";
import { useRecoilState } from "recoil";
import { RxCross1 } from "react-icons/rx";
import { Tab1, Tabs1 } from "../../../Tabs/Tabv2";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import { formatDate } from "../../../../Utilities/DateUtils";
import { processFile } from "../../BackendUtils";
const Collected = ({ searchTerm }) => {
  const webApi = new WebApimanager();
  const navigate = useNavigate();
  const [issess, setIssuss] = useState([]);
  const [site] = useRecoilState(siteId);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    if (drawingItem.acceptedROHardCopyRevisions.length > 0) {
      setSelectedTab(drawingItem.acceptedROHardCopyRevisions.length - 1);
    }
    setViewDialogOpen(true);
  };
  const handleDownload = async (id, revision) => {
    console.log(id,"id of hard copy");
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/hardCopy/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewFile = async (id, revision) => {
    setApiProcessing({
      loader: true,
      message: "opening...",
    });
    try {
      // Make the request to get the response based on the updated endpoint
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/hardCopy/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "view", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  useEffect(() => {
    const fetchIssuedData = async () => {
      try {
        const response = await webApi.get(
          `api/architect/drawingRo/${site}?filterType=collected`
        );
        console.log("Issued Data:", response.data);
        setIssuss(response.data.data);
      } catch (error) {
        console.error("Error fetching issued data:", error);
      }
    };

    fetchIssuedData();
  }, [site]);

  const filteredData = issess.filter((item) => {
    return (
      item.drawingNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.drawingTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.designDrawingConsultant?.role
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item?.category?.category?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <>
      <DrawingTableWrapper
        tableHead={
          <tr>
            <th>S.No</th>
            <th>Drawing No</th>
            <th>Design Consultant</th>
            <th>Folder</th>
            <th>Drawing Category</th>
            <th>Drawing Title</th>
            <th>R0 Hard Copy Submission Date</th>
            <th>Revision</th>
            <th>Action</th>
          </tr>
        }
        tableBody={
          filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <tr
                key={item._id}
                style={{
                  backgroundColor: index % 2 === 0 ? "#FDF8F4" : "#F3F6F8",
                }}
              >
                <td style={{ width: "5%", padding: "15px", textAlign: "left" }}>
                  {index + 1}
                </td>
                <td
                  style={{ width: "10%", padding: "15px", textAlign: "left" }}
                >
                  {item.drawingNo}
                </td>
                <td
                  style={{ width: "10%", padding: "15px", textAlign: "left" }}
                >
                  {item?.designDrawingConsultant?.role}
                </td>
                <td style={{ width: "5%" }}>{item.folderId?.folderName}</td>
                <td
                  style={{ width: "10%", padding: "15px", textAlign: "left" }}
                >
                  {item?.category?.category}
                </td>
                {/* <td
                  style={{ width: "10%", padding: "15px", textAlign: "left" }}
                >
                  {item.drawingTitle}
                </td> */}
                <td
                     style={{ width: "10%", padding: "15px", textAlign: "left" }}
                    >
                      <span
                        title={item.drawingTitle}
                        style={{
                          display: "inline-block",
                          maxWidth: "100%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.drawingTitle
                          .split(" ")
                          .slice(0, 2)
                          .join(" ")}
                        ...
                      </span>
                    </td>
                <td
                  style={{ width: "13%", padding: "15px", textAlign: "left" }}
                >
                  {item.acceptedROSubmissionDate
                    ? new Date(
                        item.acceptedROSubmissionDate
                      ).toLocaleDateString()
                    : "N/A"}
                </td>
                {/* <td
                  style={{ width: "10%", padding: "15px", textAlign: "left" }}
                >
                  {item.acceptedRORevisions?.length || 0}
                </td> */}
                 <td style={{ width: "10%", padding: "15px", textAlign: "left" }}>
              {item.acceptedROHardCopyRevisions && item.acceptedROHardCopyRevisions.length > 0
                ? item.acceptedROHardCopyRevisions
                    .slice(-2)
                    .map((rev) => rev.revision)
                    .join(", ")
                : "N/A"}
            </td>
                <td style={{ width: "7%", padding: "15px", textAlign: "left" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginLeft: "40px",
                    }}
                  >
                    <img src={Eye} alt="view" style={{ cursor: "pointer" }} onClick={() => handleViewOpen(item)}/>
                    <img
                      src={Download}
                      alt="download"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const latestRevision =
                          item.acceptedROHardCopyRevisions
                            .length > 0
                            ? item
                                .acceptedROHardCopyRevisions[
                                item
                                  .acceptedROHardCopyRevisions
                                  .length - 1
                              ].revision
                            : null;
                        handleDownload(item.drawingId, latestRevision);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" style={{ textAlign: "center" }}>
                No data found
              </td>
            </tr>
          )
        }
      />

<Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 20,
            fontFamily: "Poppins",
            background: "#FFF",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {singleDrawingData?.acceptedROHardCopyRevisions?.length >
            0 ? (
              <Tabs1
                value={selectedTab}
                onChange={handleTabChange}
              
              >
                {singleDrawingData.acceptedROHardCopyRevisions.map(
                  (revisionItem, index) => (
                    <Tab1
                      key={index}
                      value={index}
                      label={`${revisionItem.revision}`}
                      style={{
                        backgroundColor:
                          singleDrawingData.acceptedROHardCopyRevisions
                            .length === 1 || selectedTab === index
                            ? "var(--primary-color)"
                            : "transparent",
                        color:
                          singleDrawingData.acceptedROHardCopyRevisions
                            .length === 1 || selectedTab === index
                            ? "white"
                            : "var(--primary-color)",
                        width: "150px",
                        height: "35px",
                        cursor: "pointer",
                        borderRight:
                          index <
                          singleDrawingData.acceptedROHardCopyRevisions
                            .length -
                            1
                            ? "1px solid var(--primary-color)"
                            : "none",
                        // borderRadius: index === 0 && !isSingleRevision ? '5px 0 0 5px' : (index === singleDrawingData.acceptedRORevisions.length - 1 && !isSingleRevision ? '0 5px 5px 0' : 'none'),
                      }}
                    />
                  )
                )}
              </Tabs1>
            ) : (
              <div>No Revisions</div>
            )}
          </div>
        </div>
        {/* {singleDrawingData?.acceptedROHardCopyRevisions?.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {singleDrawingData?.acceptedROHardCopyRevisions?.length >
                0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {selectedTab ===
                  singleDrawingData.acceptedROHardCopyRevisions.length -
                    1 ? (
                    <div style={{ color: "green" }}>Good For Construction</div>
                  ) : (
                    <div style={{ color: "red" }}>Superseeded</div>
                  )}
                </div>
              )}
            </div>
          </>
        )} */}

        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",

                justifyContent: "center",
                marginLeft: "80px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.designDrawingConsultant?.role || "NA"}
              </div>

              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>

              <div>Folder</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.folderId?.folderName || "NA"}
              </div>
              
              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>

              <div>Site Submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedSiteSubmissionDate
                  ? new Date(singleDrawingData.acceptedSiteSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              <div>Hard Copy Submitted Date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedROHardCopyRevisions?.length >
                0
                  ? formatDate(
                      singleDrawingData.acceptedROHardCopyRevisions[0]
                        ?.hardCopySubmittedDate
                    ) || "-"
                  : "-"}
              </div>

              <div>Received Copies</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedROHardCopyRevisions?.length >
                0
                  ? singleDrawingData.acceptedROHardCopyRevisions[0]
                      ?.receivedCopies || "-"
                  : "-"}
              </div>

              {singleDrawingData?.acceptedROHardCopyRevisions?.length >
                0 && (
                <>
                  {singleDrawingData.acceptedROHardCopyRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedROHardCopyRevisions[
                          selectedTab
                        ].revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}

              {singleDrawingData?.acceptedROHardCopyRevisions?.length >
                0 && (
                <>
                  <div>Drawing File</div>
                  <div>:</div>
                  <div style={{ display: "flex", marginLeft: "-120px" }}>
                    <Icon.Image
                      size={20}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() =>
                        handleViewFile(
                          singleDrawingData._id,
                          singleDrawingData
                            .acceptedROHardCopyRevisions[0]?.revision
                        )
                      }
                    />
                    <Icon.Download
                      size={20}
                      style={{ marginRight: "5px", cursor: "pointer" }}
                      onClick={() =>
                        handleDownload(
                          singleDrawingData._id,
                          singleDrawingData
                            .acceptedROHardCopyRevisions[0]?.revision
                        )
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Collected;
