import React, { useState,useEffect } from "react";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import styles from "./Design.module.css";
import Bin from "../../../Images/bin.svg";
import Add from "../../../Images/Add.svg"
import Sub from "../../../Images/subtration.svg"
import CheckListTable from "../ChecklistTable";

const AddDescriptions = (props) => {
  const { open, handleDialogClose,formData } = props;
console.log(formData,"formdata od add seacriptions ");
  let webApi = new WebApimanager();
  const [apiProcessing, setApiProcessing] = useState({loader: false,message: "Loading...",});
  const departmentData = {
    Management: {
      roles: [
        "Company Managing Director",
        "Director",
        "Technical President",
        "Senior Vice President",
        "Vice President",
        "Assistant Vice President",
      ],
    },
    "Design Consultant": {
      roles: [
        "Archiectural",
        "Structural",
        "MEP",
        "Facade",
        "Landscape and Lighting",
        "Vertical Transportation",
        "Traffic and Parking",
      ],

    },

    Execution: {
      roles: [
        "Deputy Project Manager",
        "Assistant Project Manager",
        "Assistant Manager",
        "Sr.Project Engineer",
        "Project Engineer",
        "Jr.Project Engineer",
        "Senior Engineer",
        "Engineer",
        "Junior Engineer",
        "GTE",
      ],
  
    },
    Planning: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],

    },
    SiteManagement: {
      role: [
        "General Manager",
        "Assistant General Manager",
        "Senior Project Manager",
        "Project Manager",
      ],

    },
    QualitySurveyorBilling: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],

    },
    MEP: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Supervisors",
      ],

    },
    EHS: {
      roles: [
        "Head",
        "Manager",
        "Assistant Manager",
        "Safety Incharge",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Steve Ward",
        "Male Nurse",
      ],
  
    },
    CRMTECH: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],

    },
    Store: {
      roles: [
        "Head",
        "Store Manager",
        "Assistant Store Manager",
        "Store Officer",
        "Store Incharge",
        "Sr.Store Keeper",
        "Store Keeper/ Executive",
        "Sr.Store Asst",
        "Store Asst",
        "Trainee Store",
      ],
   
    },
    Survey: {
      roles: ["Sr.Engineer", "Engineer", "Jr.Engineer", "GTE", "Helper"],
    },
    Accounts: {
      roles: [
        "Head",
        "Manager",
        "Sr.Accountant",
        "Accountant",
        "Jr.Accountant",
      ],
   
    },
    Admin: {
      roles: [
        "Head",
        "Project Admin",
        "Ass Admin",
        "Time Keeper",
        "Colony Admin",
      ],
    },
  };
  const formId = formData?._id; 
  const [data,setData] = useState(formData);
  const [descriptions, setDescriptions] = useState([{ description: "", }]);
  const [dropdownRows, setDropdownRows] = useState([{ department: "", role: "", availableRoles: [] }]);
  const [errorMessage, setErrorMessage] = useState({});
  const [errors,setErrors] =useState({});
  const [changedValues, setChangedValues] = useState({});
 
  const handleDepartmentChange = (index, event) => {
    const selectedDepartment = event.target.value;
    const updatedRows = [...dropdownRows];
    updatedRows[index].department = selectedDepartment;
    updatedRows[index].availableRoles = departmentData[selectedDepartment]?.roles || [];
    updatedRows[index].role = ''; 
    setChangedValues((prev) => ({
      ...prev,
      [`department${index}`]: selectedDepartment, 
    }));

    setDropdownRows(updatedRows);
  };
  const handleRoleChange = (index, event) => {
    const selectedRole = event.target.value;
    const updatedRows = [...dropdownRows];
    updatedRows[index].role = selectedRole;
    setChangedValues((prev) => ({
      ...prev,
      [`role${index}`]: selectedRole, 
    }));
    setDropdownRows(updatedRows);
  };
  useEffect(()=>{console.log(changedValues,dropdownRows,"changedValues,dropdownRows")},[changedValues,dropdownRows])
  
  const handleAddClick = () => {
    setDropdownRows([
      ...dropdownRows,
      { department: '', role: '', availableRoles: [] },
    ]);
  };
  const handleRemoveDropdownRow = async (index) => {
    if (dropdownRows[index]._id) {
      const id = dropdownRows[index]._id;
      console.log("id of dropdown row", id);
      try {
        const apiResponse = await webApi.delete(`api/checklistDesign/checklist/${formId}/assigningIncharge/${id}`);

        if (apiResponse.status === 200 ) {
          const updatedRows = dropdownRows.filter((_, rowIndex) => rowIndex !== index);
          setDropdownRows(updatedRows);
          refreshFormData();
        } else {
          alert("There was an issue deleting the row. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting the row:", error);
        alert("There was an issue deleting the row. Please try again.");
      }
    } else {
      const updatedRows = dropdownRows.filter((_, rowIndex) => rowIndex !== index);
      setDropdownRows(updatedRows);
    }
  };
  
  const handleClose = () => {
    handleDialogClose();
    setDropdownRows([{ department: '', role: '', availableRoles: [] }]);
  };
  
  // Adds a new row for descriptions only
  const handleAddRow = () => {
    setDescriptions((prev)=>[...prev,{description:""}]);
    console.log(descriptions,"descriptions in handleAddRow")
  };
  
  // Removes a specific description row
  const handleRemoveRow =async  (index) => {
    if(descriptions[index].dNo){
          //TODO: keep api to delete this record based on descriptions[index]._id
          const id =descriptions[index].dNo
          console.log("id of description",id);
          const apiResponse = await webApi.delete(`api/checklistDesign/description/${formId}/dno/${id}`);
          if(apiResponse.status === 200){
            const updatedDescriptions = descriptions.filter((_, i) => i !== index);
            setDescriptions(updatedDescriptions)
            refreshFormData();
          }else{
            alert("There was an issue deleting the row. Please try again.");
          }
    }else{
      const updatedDescriptions = descriptions.filter((_, i) => i !== index);
      setDescriptions(updatedDescriptions);
    }
  };
  
  // Updates the description of a specific row
  const handleDescriptionChange = (index, value) => {
    const updatedDescriptions = descriptions.map((item, i) =>
      i === index ? { ...item, description: value } : item
    );
    setChangedValues((prev) => ({
      ...prev,
      [`description${index}`]: value, 
    }));

    setDescriptions(updatedDescriptions);
    console.log(descriptions,"handleDesscriptionsChange")
  };
  const validateForm = () => {
    let isValid = true;
    const errors = {};
    descriptions.forEach((item, index) => {
      if (!item.description) {
        errors[`description${index}`] =`Description ${index+1} is required`;
        isValid = false;
      }
    });
    dropdownRows.forEach((row, index) => {
      if (!row.department) {
        errors[`department${index}`] = `Please select a department ${index+1}`;
        isValid = false;
      }
      if (!row.role) {
        errors[`role${index}`] = `Please select a role ${index+1}`;
        isValid = false;
      }
    });
  
    setErrors(errors); 
    return isValid;
  };
  
  const handleFormSubmit =async (id) => {
      // handleFormSave(id);

      let body ={
       approvalStatus: "Requesting"
    }
    setApiProcessing({ loader: true, message: "Submitting..." });
  
      try {
        const response = await webApi.put(`api/checklistDesign/updateApprovalStatus/${id}`,body);
        if(response.status === 200){
          setApiProcessing({ loader: false, message: "" });
          handleClose();  
          window.location.reload();
        }
       
      } catch (error) {
        setApiProcessing({ loader: false, message: "" });
        setErrorMessage("An error occurred during submission");
        console.error("Error while submitting form:", error);
      }
    
  };
  // const handleFormSave = async (id) => {
  //   if (validateForm()) {
  //     setApiProcessing({ loader: true, message: "Saving..." });
  //     const descriptionDetails = descriptions.map(item => ({
  //       description: item.description,
  //     }));
  //     const assigningIncharge = dropdownRows.map(row => ({
  //       department: row.department,
  //       role: row.role,
  //     }));
  //     const payload = {
  //       descriptionDetails,
  //       assigningIncharge,
  //     };
  
   
  
  //     try {
  //       const response = await webApi.put(`api/checklistDesign/update/${id}`, payload);
  //       if(response.status === 200){
  //         setApiProcessing({ loader: false, message: "" });
  //         handleClose();
  //         // window.location.reload();
  //       }
       
  //     } catch (error) {
  //       setApiProcessing({ loader: false, message: "" });
  //       setErrorMessage("An error occurred during submission");
  //       console.error("Error while submitting form:", error);
  //     }
  //   }
  // };

  const refreshFormData = () => {
    webApi.get(`api/checklistDesign/checklistById/${formId}`)
      .then((response) => {
        setData(response.data); // Update formData in state
      })
      .catch((error) => {
        console.error("Error fetching form data:", error);
      });
  };
  const handleFormSave = async (id) => {
    if (validateForm()) {
      setApiProcessing({ loader: true, message: "Saving..." });
  
      // Prepare payload based on changed values
      const payload = {};
  
      // Include modified descriptions
      const modifiedDescriptions = descriptions
        .map((item, index) => {
          if (changedValues[`description${index}`]) {
            return { description: changedValues[`description${index}`] };
          }
          return null; // Exclude unchanged items
        })
        .filter(Boolean); // Remove null values
  
       
    
      if (modifiedDescriptions.length > 0) {
        payload.descriptionDetails = modifiedDescriptions;
      }
      console.log(payload,"payloadBefore")
      // Include modified assigning in charge
      console.log(dropdownRows,"dropDownRows")

      const modifiedAssigningIncharge = dropdownRows
        .map((row, index) => {
          const modifiedRow = {};
          if (changedValues[`department${index}`]) {
            modifiedRow.department = changedValues[`department${index}`];
          }
          if (changedValues[`role${index}`]) {
            modifiedRow.role = changedValues[`role${index}`];
          }

          return Object.keys(modifiedRow).length ? modifiedRow : null; 
        })
        
        .filter(Boolean); // Remove null values
   

      if (modifiedAssigningIncharge.length > 0) {
        payload.assigningIncharge = modifiedAssigningIncharge;
      }
     
      // Proceed to send the payload only if there's something to send
      if (Object.keys(payload).length > 0) {
        try {
          const response = await webApi.put(`api/checklistDesign/update/${id}`, payload);
          if (response.status === 200) {
            setApiProcessing({ loader: false, message: "" });
            handleClose();
            window.location.reload()
            // Optionally reload the page or fetch updated data
          }
        } catch (error) {
          setApiProcessing({ loader: false, message: "" });
          setErrorMessage("An error occurred during submission");
          console.error("Error while submitting form:", error);
        }
      } else {
        // No changes to save
        setApiProcessing({ loader: false, message: "" });
        setErrorMessage("No changes detected.");
      }
    }
  };
  // const handleFormSave = async (id) => {
  //   if (validateForm()) {
  //     setApiProcessing({ loader: true, message: "Saving..." });
  
  //     // Prepare payload based on changed values
  //     const payload = {};
  
  //     // Include modified descriptions
  //     const modifiedDescriptions = descriptions
  //       .map((item, index) => {
  //         if (changedValues[`description${index}`]) {
  //           return { description: changedValues[`description${index}`] };
  //         }
  //         if (changedValues[`role${index}`]) {
  //           return { role: changedValues[`role${index}`] };
  //         }
  //         return null; // Exclude unchanged items
  //       })
  //       .filter(Boolean); // Remove null values
  
  //     if (modifiedDescriptions.length > 0) {
  //       payload.descriptionDetails = modifiedDescriptions;
  //     }
  
  //     // Include modified assigning in charge
  //     const modifiedAssigningIncharge = dropdownRows
  //       .map((row, index) => {
  //         const modifiedRow = {};
  
  //         // Check if role has changed
  //         if (changedValues[`role${index}`]) {
  //           modifiedRow.role = changedValues[`role${index}`];
  
  //           // Also include department based on selected role
  //           if (changedValues[`department${index}`]) {
  //             modifiedRow.department = changedValues[`department${index}`];
  //           } else {
  //             // Optionally set a default department if none is selected
  //             modifiedRow.department = row.department || null;
  //           }
  //         }
  
  //         return Object.keys(modifiedRow).length ? modifiedRow : null; // Exclude unchanged items
  //       })
  //       .filter(Boolean); // Remove null values
  
  //     if (modifiedAssigningIncharge.length > 0) {
  //       payload.assigningIncharge = modifiedAssigningIncharge;
  //     }
  
  //     // Proceed to send the payload only if there's something to send
  //     if (Object.keys(payload).length > 0) {
  //       try {
  //         const response = await webApi.put(`api/checklistDesign/update/${id}`, payload);
  //         if (response.status === 200) {
  //           setApiProcessing({ loader: false, message: "" });
  //           handleClose();
  //           // Optionally reload the page or fetch updated data
  //         }
  //       } catch (error) {
  //         setApiProcessing({ loader: false, message: "" });
  //         setErrorMessage("An error occurred during submission");
  //         console.error("Error while submitting form:", error);
  //       }
  //     } else {
  //       // No changes to save
  //       setApiProcessing({ loader: false, message: "" });
  //       setErrorMessage("No changes detected.");
  //     }
  //   }
  // };
  
  
  useEffect(()=>{
    if(formData?.descriptionDetails?.length){
      setDescriptions(formData.descriptionDetails)
      console.log(formData?.descriptionDetails,"formData?.descriptionDetails")
    } else {
      setDescriptions([{ description: "", }])
    }
  },[open])
  useEffect(() => {
    if (formData?.assigningIncharge) {
      const updatedRows = formData.assigningIncharge.map((row) => {
        const availableRoles = departmentData[row.department]?.roles || [];
        return {
          ...row,
          availableRoles,
        };
      });
      setDropdownRows(updatedRows);
    }
  }, [formData, open]);

  useEffect(()=>{
    console.log(descriptions,"descriiptions")
  },[descriptions])
  

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 480px", textAlign: "center", color: "white" }}
          >
            Add Descriptions
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "500px" }}
        >
          <div className={styles.overall}>
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
            <div>
              <p className={styles.heading}>{formData?.companyId ? `${formData.companyId.companyDetails.companyName}` : 'No Company Data'}</p>
            </div>
            <div className={styles.second}>
              <div className={styles.grid}>
                <p style={{fontSize:'16px',fontWeight:'500'}}>Activity Name</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.activity?.activity || 'No Activity Data'}</p>

                <p style={{fontSize:'16px',fontWeight:'500'}}>Checklist Type</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.checklistType || 'No Checklist Type'}</p>

                <p style={{fontSize:'16px',fontWeight:'500'}}>Form No</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.formNo || 'No Form No'}</p>

                <p style={{fontSize:'16px',fontWeight:'500'}}>Departments</p>
                <p style={{fontSize:'16px',fontWeight:'500'}}>:</p>
                <p>{formData?.department?.join(', ') || 'No Departments'}</p>
              </div>
            </div>
            <div>
 
    <>
    <CheckListTable
  CheckListTableHead={
    <tr className="HeaderTableRow">
      <th className="CheckListTableTableHead" style={{ width: "5%" }}>S.No</th>
      <th className="CheckListTableTableHead" style={{ width: "90%" }}>Description</th>
      <th className="CheckListTableTableHead" style={{ width: "5%" }}></th>
    </tr>
  }
  CheckListTableBody={
    <>
      {descriptions.length > 0 && (
        <>
          {descriptions.map((item, index) => (
            <tr
              className="ValueTableRow"
              style={{ borderBottom: "1px dotted #97979740", paddingBottom: "8px" }}
              key={index}
            >
              <td className="CheckListTableTableData" style={{ width: "5%", padding: "20px" }}>
                {index + 1}.
              </td>
              <td className="CheckListTableTableData" style={{ width: "90%" }}>
                <input
                  type="text"
                  placeholder="Enter the description"
                  value={item.description}
                  onChange={(e) => handleDescriptionChange(index, e.target.value)}
                />
                {/* Display the error message for the specific description */}
                {errors[`description${index}`] && (
                  <p className={styles.error} style={{ margin: "5px 0 0", color: "red" }}>
                    {errors[`description${index}`]}
                  </p>
                )}
              </td>
              <td className="CheckListTableTableData" style={{ width: "5%", padding: "20px" }}>
                <img
                  src={Bin}
                  loading="eager"
                  className={styles.bin}
                  onClick={() => handleRemoveRow(index)}
                  alt="Delete"
                />
              </td>
            </tr>
          ))}
        </>
      )}
    </>
  }
/>

    </>
 

  <button className={styles.addMoreButton} onClick={handleAddRow}>
    Add One More
  </button>
</div>

            <div>
              <div className={styles.assginIncharge}>
                <p style={{marginTop:'20px'}}>Assigning In charge</p>
                <img
                src={Add}
                loading="eager"
                alt="loading"
                style={{marginLeft:'20px',marginTop:"20px"}}
                onClick={handleAddClick}
                />
              </div>
                      {/* {dropdownRows.map((row, index) => (
  <div className={styles.DeptContainer} key={index}>
    <div className={styles.InputContainer}>
      <label>Department {index + 1}:</label>
      <div className={styles.InputFieldContainer}>
        <select
          value={row.department}
          onChange={(e) => handleDepartmentChange(index, e)}
          className={styles.InputField}
        >
          <option value="">Select Department</option>
          {Object.keys(departmentData).map((dept) => (
            <option key={dept} value={dept}>
              {dept}
            </option>
          ))}
        </select>
        {errors[`department${index}`] && (
          <p className={styles.error} style={{ position: "absolute" }}>
            {errors[`department${index}`]}
          </p>
        )}
      </div>
    </div>

    <div className={styles.InputContainer} style={{ justifyContent: "flex-end" }}>
      <label>Role {index + 1}:</label>
      <div className={styles.InputFieldContainer}>
        <select
          value={row.role}
          onChange={(e) => handleRoleChange(index, e)}
          className={styles.InputField}
        >
          <option value="">Select Role</option>
          {row.availableRoles.map((role, roleIndex) => (
            <option key={roleIndex} value={role}>
              {role}
            </option>
          ))}
        </select>
        {errors[`role${index}`] && (
          <p className={styles.error} style={{ position: "absolute" }}>
            {errors[`role${index}`]}
          </p>
        )}
      </div>
    </div>
    {index > 0 && (
      <img
        src={Sub}
        alt="Remove"
        style={{ cursor: "pointer", marginLeft: "10px",marginTop:'25px' }}
        onClick={() => handleRemoveDropdownRow(index)}
      />
    )}
  </div>
))} */}
{(dropdownRows || []).map((row, index) => (
  <div className={styles.DeptContainer} key={index}>
    <div className={styles.InputContainer}>
      <label>Department {index + 1}:</label>
      <div className={styles.InputFieldContainer}>
        <select
          value={row.department}
          onChange={(e) => handleDepartmentChange(index, e)}
          className={styles.InputField}
        >
          <option value="">Select Department</option>
          {Object.keys(departmentData).map((dept) => (
            <option key={dept} value={dept}>
              {dept}
            </option>
          ))}
        </select>
        {errors[`department${index}`] && (
          <p className={styles.error} style={{ position: "absolute" }}>
            {errors[`department${index}`]}
          </p>
        )}
      </div>
    </div>

    <div className={styles.InputContainer} style={{ justifyContent: "flex-end" }}>
      <label>Role {index + 1}:</label>
      <div className={styles.InputFieldContainer}>
        <select
          value={row.role}
          onChange={(e) => handleRoleChange(index, e)}
          className={styles.InputField}
        >
          <option value="">Select Role</option>
          {(row.availableRoles || []).map((role, roleIndex) => (
            <option key={roleIndex} value={role}>
              {role}
            </option>
          ))}
        </select>
        {errors[`role${index}`] && (
          <p className={styles.error} style={{ position: "absolute" }}>
            {errors[`role${index}`]}
          </p>
        )}
      </div>
    </div>
    {index > 0 && (
      <img
        src={Sub}
        alt="Remove"
        style={{ cursor: "pointer", marginLeft: "10px", marginTop: '25px' }}
        onClick={() => handleRemoveDropdownRow(index)}
      />
    )}
  </div>
))}

      </div>
 <div className={styles.buttons}>

  <button className={styles.requestingButton} onClick={() => handleFormSubmit(formData._id)}>
          REQUESTING APPROVAL
        </button>
 <button
              onClick={() => handleFormSave(formData._id)}
              className={styles.submitButton}
            >
              Save
            </button>
 </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddDescriptions;
 