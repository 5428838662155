import React, { useState, useEffect } from "react";
import "./CompleteTab.css";
import DrawingTableWrapper from "../../../Drawing/Components/DrawingTableWrapper";
import { CiSearch } from "react-icons/ci";
import chart from "../../../../Images/AllTabsChart.svg";
import eye from "../../../../Images/AlltabEye.svg";
import { WebApimanager } from "../../../../WebApiManager";
import { siteId, userInfo } from "../../../../atoms";
import { useRecoilState } from "recoil";
import CompleteTabView from "../CompleteTab/CompleteTabView.js";

const CompleteTab = () => {
  const webApi = new WebApimanager();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [currentSiteId, setCurrentSiteId] = useRecoilState(siteId);
  const [delayedData, setDelayedData] = useState([]);
  const UserID = currentUserInfo._id;

  useEffect(() => {
    console.log("Delayed page current user data:", currentUserInfo);
    console.log("Delayed page siteId:", currentSiteId);
  }, [currentUserInfo, currentSiteId]);

  const handleEyeClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await webApi.get(
          `api/Task/user/${UserID}/siteId/${`6686ff213a440222d5bb7b96`}?status=Delayed`
        );

        console.log("API Response Delayed pages tasks data display:", response);
        const delayedData = response.data.data.tasks || [];

        setDelayedData(delayedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (UserID && currentSiteId) {
      fetchData();
    }
  }, [UserID, currentSiteId]);

  const calculateDays = (assignedDate) => {
    const now = new Date();
    const assigned = new Date(assignedDate);
    const differenceInTime = now - assigned;
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };
  return (
    <div className="completeTabContainer">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="completeWorkTitle">Complete</p>
        <div
          className="search-container"
          style={{
            position: "relative",
          }}
        >
          <input
            type="text"
            placeholder="Search Planner Lists"
            style={{
              width: "225px",
              height: "40px",
              borderRadius: "5px",
              border: "1px solid #E47331",
              paddingRight: "30px",
              borderColor: "#E47331",
            }}
          />
          <button className="searchComplete-button">
            <CiSearch />
          </button>
        </div>
      </div>
      <DrawingTableWrapper
        tableHead={
          <tr>
            <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
            <th style={{ width: "10%" }}>Site Name</th>
            <th style={{ width: "10%" }}>Flat No/ Equipment Type</th>
            <th style={{ width: "10%" }}>Department</th>
            <th style={{ width: "10%" }}>Activity Name</th>
            <th style={{ width: "13%", paddingLeft: "10px" }}>
              Completed Date
            </th>

            <th style={{ width: "10%" }}>Action</th>
          </tr>
        }
        tableBody={
          <>
            {delayedData.length === 0 ? (
              <tr>
                <td
                  colSpan={7}
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  No tasks found for the specified user.
                </td>
              </tr>
            ) : (
              delayedData.map((delayed, index) => (
                <tr key={index}>
                  <td style={{ paddingLeft: "10px" }}>{index + 1}</td>
                  <td>{delayed.siteId?.siteName || "N/A"}</td>
                  <td>
                    {delayed.assignnewPnmTasksForUser[0]?.assignNewPnmTasks
                      ?.equipmentType || "N/A"}
                  </td>
                  <td>
                    {delayed.assignnewPnmTasksForUser[0]?.assignNewPnmTasks?.assetCode?.formNo?.department?.map(
                      (dept, deptIndex) => <div key={deptIndex}>{dept}</div>
                    ) || "N/A"}{" "}
                  </td>

                  <td>
                    {delayed.assignnewPnmTasksForUser[0]?.assignNewPnmTasks
                      ?.assetCode?.formNo?.activity?.activity || "N/A"}
                  </td>
                  <td>
                    {calculateDays(
                      delayed.assignnewPnmTasksForUser[0]?.assignedDate
                    )}{" "}
                    days{" "}
                  </td>
                  <td>
                    <div className="delayedTabActionContainer">
                      <div>
                        <img src={chart} alt="chart" />
                      </div>
                      <div>
                        <img src={eye} alt="eye" onClick={handleEyeClick} />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </>
        }
      />

      <CompleteTabView isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default CompleteTab;
