  import React, { useState, useEffect } from "react";
  import {
    AiFillCloseCircle,
    AiOutlineSearch,
  } from "react-icons/ai";
  import {
    BsArrowLeftCircleFill,
    BsArrowRightCircleFill,
  } from "react-icons/bs";
  import { TiDelete, TiEdit } from "react-icons/ti";
  import { TablePagination } from "@mui/material";
  import dayjs from "dayjs";
  import "./Widgets.css";

  export const clickEffect = "active:scale-95 transform transition duration-100";

  // Time from year to min
  export const GetTimeYearToMin = (fulldate) => {
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var Months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(fulldate);

    return (
      <span className="flex items-center">
        {weekday[d.getDay()]} | {Months[d.getMonth()]}{" "}
        {d.getDate().toString().padStart(2, "0")} | {d.getFullYear()} |{" "}
        {GetTimein12HrFormat(d)}
      </span>
    );
  };

  // Time in 12hr format
  export const GetTimein12HrFormat = (date) => {
    let time = "";
    let hours = date.getHours();
    let min = date.getMinutes();
    if (hours < 12) {
      time =
        hours.toString().padStart(2, "0") +
        ":" +
        min.toString().padStart(2, "0") +
        " AM";
    } else if (hours === 12) {
      time =
        hours.toString().padStart(2, "0") +
        ":" +
        min.toString().padStart(2, "0") +
        " PM";
    } else {
      time =
        (hours - 12).toString().padStart(2, "0") +
        ":" +
        min.toString().padStart(2, "0") +
        " PM";
    }
    return time;
  };

  // Date in MM/DD/YYYY
  export const GetDateInMMDDYYYY = (inputDate) => {
    if (inputDate) {
      if (inputDate === "0001-01-01T00:00:00Z") {
        return "-";
      } else {
        let fulldate = dayjs(inputDate);
        const value = dayjs(fulldate.$d).format("MM/DD/YYYY");
        return `${value}`;
      }
    } else return "N/A";
  };

  // Gallery View
  export const Gallery = ({
    images,
    videos,
    index,
    openGallery,
    setOpenGallery,
  }) => {
    const [slideNumber, setSlideNumber] = useState(index || 0);
    const hasImages = Array.isArray(images) && images.length > 0;
    const hasVideos = Array.isArray(videos) && videos.length > 0;
    const galleryItems = [
      ...(hasImages ? images.filter(Boolean) : []),
      ...(hasVideos ? videos.filter(Boolean) : []),
    ];

    // Close Modal
    const handleCloseGallery = () => {
      setOpenGallery(false);
    };

    // Previous Slide
    const prevSlide = () => {
      slideNumber === 0
        ? setSlideNumber(galleryItems.length - 1)
        : setSlideNumber(slideNumber - 1);
    };

    // Next Slide
    const nextSlide = () => {
      slideNumber + 1 === galleryItems.length
        ? setSlideNumber(0)
        : setSlideNumber(slideNumber + 1);
    };

    const currentItem = galleryItems[slideNumber];
    const isVideo = hasVideos && videos.includes(currentItem);

    return (
      openGallery && (
        <div className="gallery">
          <span className="index text-2xl">
            {slideNumber + 1}/{galleryItems.length}
          </span>
          <AiFillCloseCircle
            className="btnClose w-8 h-8"
            onClick={handleCloseGallery}
          />
          <BsArrowLeftCircleFill
            className="btnPrev w-8 h-8"
            onClick={prevSlide}
          />
          <BsArrowRightCircleFill
            className="btnNext w-8 h-8"
            onClick={nextSlide}
          />
          <div className="fullScreenItem">
            {isVideo ? (
              <video src={currentItem} controls />
            ) : (
              <img src={currentItem} alt="" />
            )}
          </div>
        </div>
      )
    );
  };

  // Custom SearchBar
  export const Searchbar = (props) => {
    const [searchString, setSearchString] = useState("");

    const handleSearch = (event) => {
      const searchString = event.target.value.toLowerCase();
      setSearchString(event.target.value);

      if (searchString === "") {
        props.setTableData(props.data);
      } else {
        const filteredData = props.data.filter((item) => {
          return searchComplexMatchProps(item, props.matchProps, searchString);
        });
        props.setTableData(filteredData);
      }
    };

    const searchComplexMatchProps = (item, complexMatchProps, searchString) => {
      for (const complexMatch of complexMatchProps) {
        for (const [type, matchProp] of Object.entries(complexMatch)) {
          if (type === "text") {
            const propValue = getNestedPropertyValue(item, matchProp);
            if (
              propValue &&
              propValue.toString().toLowerCase().includes(searchString)
            ) {
              return true;
            }
          } else if (type === "date") {
            const propValue = getNestedPropertyValue(item, matchProp);
            if (
              propValue &&
              dayjs(propValue).format("MM/DD/YYYY").includes(searchString)
            ) {
              return true;
            }
          }
        }
      }
      return false;
    };

    const getNestedPropertyValue = (object, propertyPath) => {
      const properties = propertyPath.split(".");
      let value = object;
      for (const prop of properties) {
        value = value[prop];
        if (value === undefined) {
          return undefined;
        }
      }
      return value;
    };

    const handleClear = () => {
      setSearchString("");
      props.setTableData(props.data);
    };

    return (
      <div
        className={
          props.style + " flex justify-end items-center w-auto max-w-80"
        }
      >
        <input
          className={
            props.className +
            " border shadow rounded-2xl w-full focus:outline-none pr-[30px] py-2 px-3"
          }
          placeholder={props.placeholder}
          value={searchString}
          onChange={handleSearch}
        />
        {searchString === "" && (
          <AiOutlineSearch
            size={25}
            className="cursor-pointer absolute mr-[4px] text-blue-52"
          />
        )}
        {searchString !== "" && (
          <TiDelete
            size={25}
            className="cursor-pointer absolute mr-[4px] text-red-500"
            onClick={handleClear}
          />
        )}
      </div>
    );
  };

  // Custom Table Component
  export const TableComponent = ({
    tableId,
    data,
    columns,
    className,
    dataOnClick,
    selectedRowIndex,
    actions,
    actionHeader,
    initialSort,
    rowStyles,
    ...props
  }) => {
    const [sortConfig, setSortConfig] = useState(
      initialSort || { key: columns[0].key, direction: "ascending" }
    );

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
      setPage(0); // Reset page to 0 whenever props.data changes
    }, [data]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleSort = (key) => {
      let direction = "ascending";
      if (sortConfig.key === key && sortConfig.direction === "ascending") {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    const getPropertyValue = (obj, key) => {
      const properties = key.split(".");
      let value = obj;
      for (const prop of properties) {
        value = value?.[prop];
        if (value === undefined) {
          break;
        }
      }
      return value;
    };

    const sortedData = Array.isArray(data)
      ? data.slice().sort((a, b) => {
          const valueA = getPropertyValue(a, sortConfig.key);
          const valueB = getPropertyValue(b, sortConfig.key);
          if (valueA < valueB) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (valueA > valueB) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        })
      : []; // Ensure sortedData is always an array

    const displayedData = sortedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    const renderHeader = () => {
      return (
        <thead className="text-sm">
          <tr>
            {columns.map((column) => (
              <th
                key={column.key}
                onClick={() => handleSort(column.key)}
                className="py-2 cursor-pointer"
              >
                {column.header}
                {sortConfig.key === column.key ? (
                  sortConfig.direction === "ascending" ? (
                    <span>&#9650;</span>
                  ) : (
                    <span>&#9660;</span>
                  )
                ) : null}
              </th>
            ))}
            {actions && <th>{actionHeader}</th>}
          </tr>
        </thead>
      );
    };

    const renderBody = () => {
      return (
        <tbody>
          {displayedData.map((item, index) => (
            <tr
              key={index}
              className={`text-sm ${index === selectedRowIndex ? "selected" : ""}`}
              onClick={() => dataOnClick(item, index)}
              style={rowStyles ? rowStyles(item, index) : {}}
            >
              {columns.map((column) => (
                <td key={column.key} className="py-2">
                  {column.render ? column.render(item) : getPropertyValue(item, column.key)}
                </td>
              ))}
              {actions && (
                <td className="py-2">
                  {actions.map((action, actionIndex) => (
                    <button
                      key={actionIndex}
                      className={action.className}
                      onClick={() => action.onClick(item, index)}
                    >
                      {action.label}
                    </button>
                  ))}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      );
    };

    return (
      <div className="table-responsive">
        <table id={tableId} className={className}>
          {renderHeader()}
          {renderBody()}
        </table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    );
  };
