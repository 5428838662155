import React, { useState, useEffect, useRef } from "react";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import DrawingTableWrapper from "../../Components/DrawingTableWrapper";
import filter from "../../../../Images/taskFilter.svg";
import { useNavigate } from "react-router-dom";
import Eye from "../IconsSiteHead/Eye.svg";
import Download from "../IconsSiteHead/Download.svg";
import { WebApimanager } from "../../../../WebApiManager";
import { siteId } from "../../../../atoms";
import { useRecoilState } from "recoil";
import RfiViweModal from "./RfiViewModal";
import { calculateDueDays } from "../../../../Utilities/DateUtils";
import { processFile } from "../../BackendUtils";
import { Loader } from "../../../../Widgets/notificationFeedbacks";

const SiteHeadRFI = (rfiRaisedAccess) => {
  const webApi = new WebApimanager();
  console.log(rfiRaisedAccess,"rfi access for the sitehead")
  const navigate = useNavigate();
  const [rfiData, setRfiData] = useState([]);
  const [site] = useRecoilState(siteId);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const dropdownRef = useRef(null);
  const [openModal, setOpenModal] = useState(false); // State for modal visibility
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [currentView, setCurrentView] = useState("raised");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [selectedDrawingId, setSelectedDrawingId] = useState(null);
  useEffect(() => {
    const fetchRfiData = async () => {
      try {
        const response = await webApi.get(
          `api/roToSiteLevelRequested?siteId=${site}`
        );
        console.log("Rfi data response for siteHead", response.data);
        setRfiData(response.data.data);

        const statuses = response.data.data.map((item) => item.status);
        const unique = [...new Set(statuses)];
        setUniqueStatuses(["All", ...unique]);
      } catch (error) {
        console.log("Server error", error);
      }
    };

    fetchRfiData();
  }, [site]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };
  const handleDownload = async (id, status) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      const response = await webApi.getImage(
        status === "Rejected"
          ? `api/roToSiteLevelRequested/reject/${id}`
          : `api/roToSiteLevelRequested/request/${id}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const handleConfirmDialogOpen = (drawingItem) => {
    setSelectedItem(drawingItem);
    setSelectedDrawingId(drawingItem._id);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };
  const handleConfirmSubmit = () => {
    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });
    if (selectedDrawingId) {
      console.log("Selected Drawing ID:", selectedDrawingId);
      webApi
        .put(`api/roToSiteLevelRequested/closed/${selectedDrawingId}`)
        .then((response) => {
          const data = response.data;
          console.log("Server Response:", response);
          console.log("Data:", data);
          setApiProcessing({ loader: false, message: "" });
          setConfirmDialogOpen(false);
        })
        .catch((error) => {
          setApiProcessing({ loader: false, message: "" });
          console.error("There was an error submitting the form!", error);
        });
    }
  };
  const DueDateDisplay = ({ item }) => {
    const today = new Date();
    const daysRemaining = calculateDueDays(today, item.expectedDate);
    return (
      <span
        style={{
          color: new Date(item.expectedDate) >= today ? "green" : "red",
        }}
      >
        {daysRemaining}
      </span>
    );
  };

  const handleDropdownToggle = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setShowDropdown(false);
  };

  const filteredRfiData = rfiData.filter((item) => {
    const isStatusMatch =
      selectedStatus === "All" || item.status === selectedStatus;
    const isSearchMatch =
      item.drawingNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.drawingId?.drawingTitle
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item?.designDrawingConsultant?.role
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item?.category?.category.toLowerCase().includes(searchTerm.toLowerCase());

    return isStatusMatch && isSearchMatch;
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleViewOpen = (drawing) => {
    setSelectedDrawing(drawing);
    setViewDialogOpen(true);
  };

  const handleViewClose = () => {
    setViewDialogOpen(false);
    setSelectedDrawing(null);
  };

  return (
    <div style={{ margin: "5px", backgroundColor: "white", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <p
            style={{
              color: "#303030",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Raised
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            placeholder="Search Drawing Lists"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              paddingLeft: "20px",
              border: "1px solid var(--primary-color)",
              width: "250px",
              height: "40px",
              marginRight: "10px",
            }}
          />
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon.Search
              style={{
                position: "absolute",
                right: "25px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#ACACAC",
              }}
            />
          </div>

          <div style={{ position: "relative" }}>
            <img
              src={filter}
              alt="filter"
              style={{
                cursor: "pointer",
                color: "#4D4D4D",
              }}
              onClick={handleDropdownToggle}
            />
            {showDropdown && (
              <div ref={dropdownRef} style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "45px",
                    right: "0px",
                    borderRadius: "6px",
                    padding: "10px",
                    zIndex: 1000,
                    width: "150px",
                    background: "white",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {uniqueStatuses.map((status) => (
                    <button
                      key={status}
                      onClick={() => handleStatusChange(status)}
                      style={{
                        display: "block",
                        width: "100%",
                        border: "none",
                        marginBottom: "5px",
                        background: "white",
                        textAlign: "left",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color:
                          selectedStatus === status
                            ? "var(--primary-color)"
                            : "#303030",
                      }}
                    >
                      {status}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <DrawingTableWrapper
          tableHead={
            <tr>
              <th>S.No</th>
              <th>Drawing No</th>
              <th>Design Consultant</th>
              <th>Drawing Category</th>
              <th>Drawing Title</th>
              <th>Raised Date</th>
              <th>Expected Date</th>
              <th>Due/Overdue</th>
              <th>Revision</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          }
          tableBody={
            filteredRfiData.length > 0 ? (
              filteredRfiData.map((item, index) => (
                <tr
                  key={item._id}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#FDF8F4" : "#F3F6F8",
                  }}
                >
                  <td style={{ padding: "15px" }}>{index + 1}</td>
                  <td style={{ padding: "15px" }}>{item.drawingNo}</td>
                  <td style={{ padding: "15px" }}>
                    {item?.designDrawingConsultant?.role || "N/A"}
                  </td>
                  <td style={{ padding: "15px" }}>
                    {item?.category?.category || "NA"}
                  </td>
                  <td style={{ padding: "15px" }}>
                    {item.drawingId?.drawingTitle}
                  </td>
                  <td style={{ padding: "15px" }}>
                    {formatDate(item.requestedDate)}
                  </td>
                  <td style={{ padding: "15px" }}>
                    {formatDate(item.expectedDate)}
                  </td>
                  <td>
                    <DueDateDisplay item={item} />
                  </td>
                  <td style={{ padding: "15px" }}>{item.revision}</td>
                  <td style={{ padding: "15px" }}>{item.status}</td>
                  {/* <td
                    style={{
                      padding: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Eye}
                      alt="View"
                      onClick={() => handleViewOpen(item)}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                    />
                    <img
                      src={Download}
                      alt="Download"
                      style={{ cursor: "pointer" }}
                    />
                  </td> */}
                   <td
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "18px",
                            }}
                          >
                            {item.status === "Requested" ? (
                              <>
                                {/* <Icon.Pen
                              size={20}
                              style={{ marginRight: "10px", cursor: "pointer" }}
                              onClick={() => handleEditOpen(drawingItem)}
                            /> */}
                                {/* <Icon.Download
                              size={20}
                              style={{ marginRight: "10px", cursor: "pointer" }}
                              onClick={() => handleDownload(drawingItem._id)}
                            /> */}
                            {rfiRaisedAccess &&
                                <Icon.EyeFill
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleViewOpen(item, currentView);
                                  }}
                                />
                            }
                              </>
                            ) : item.status === "Accepted" ? (
                              <>
                              {rfiRaisedAccess &&
                                <Icon.Pen
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleConfirmDialogOpen(item)
                                  }
                                />
                }
                                <Icon.Download
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleDownload(item._id)
                                  }
                                />
                                <Icon.EyeFill
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                 
                                    handleViewOpen(item, currentView);
                                  }}
                                />
                              </>
                            ) : (
                              <Icon.EyeFill
                                size={20}
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                
                                  handleViewOpen(item, currentView);
                                }}
                              />
                            )}
                          </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={11}
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    color: "#ACACAC",
                  }}
                >
                  No data found
                </td>
              </tr>
            )
          }
        />
      </div>
      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogContent>
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            Are you sure want to close ?
          </>
        </DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <button
            style={{
              background: "var(--primary-color)",
              color: "white",
              width: "100px",
              height: "35px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={handleConfirmDialogClose}
          >
            Cancel
          </button>
          <button
            style={{
              background: "var(--primary-color)",
              color: "white",
              width: "100px",
              height: "35px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={handleConfirmSubmit}
          >
            Submit
          </button>
        </div>
      </Dialog>
      <RfiViweModal
        open={viewDialogOpen}
        onClose={handleViewClose}
        drawingData={selectedDrawing}
        rfiRaisedAccess={rfiRaisedAccess}
   
      />
    </div>
  );
};

export default SiteHeadRFI;
