import React from "react";

const DrawingTableWrapper = (props) => {
  const { tableBody, tableHead } = props;

  return (
    <div style={{ maxHeight: "65vh",  overflowY: "auto"  }}>
      <table
        style={{
          width: "100%",
          tableLayout: "fixed",
          borderCollapse: "separate", 
          borderSpacing: "0 10px",
        }}
      >
        <thead
          style={{
            fontSize: "14px",
            backgroundColor: "#4a4a4a",
            color: "#fff",
            position: "sticky",
            top: 0,
            zIndex: 1,
            height: "50px",
          }}
        >
          {tableHead}
        </thead>
   
          {tableBody}

        </table>
    </div>
  );
};

export default DrawingTableWrapper;
