/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../WebApiManager";
import { Loader } from "../../Widgets/notificationFeedbacks";
import "./sites.css";
import AddCompanyDialog from "./AddCompanyDialog";
import { BACKEND_URL } from "../../Utilities/Constants";
import * as Icon from "react-bootstrap-icons";
import { json } from "react-router-dom";

const ViewComapnyDialog = (props) => {
  const { open, onClose, companyData } = props;
  console.log(props, "view props");
  const webApi = new WebApimanager();
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const cmpID = companyData ? companyData._id : "";
    console.log("CompanyData:", companyData);
    console.log("CMPID:", cmpID);
  }, [companyData]);

  useEffect(() => {
    console.log("bcdshbcfjdsfbhcdsjcbs  view Data", companyData);
  }, [companyData]);

  const [expandedSiteId, setExpandedSiteId] = useState(null);
  const handleToggleExpand = (siteId) => {
    setExpandedSiteId(expandedSiteId === siteId ? null : siteId);
  };
  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const handleEditClick = (companyData) => {
    setSelectedCompanyData(companyData);
    setShowAddCompanyModal(true);
  };

  const [adminDetails, setAdminDetails] = useState(null);

  const handleAddCompanyClose = () => {
    setShowAddCompanyModal(false);
  };

  useEffect(() => {
    const cmpID = companyData ? companyData._id : "";
    console.log("CompanyData:", companyData);
    console.log("CMPID:", cmpID);

    const fetchCompanyAdmin = async () => {
      try {
        if (!cmpID) return;

        const response = await webApi.get(`api/users/companyAdmin/${cmpID}`);
        console.log("Company Admin Data:", response.data);

        if (
          response.data.data &&
          response.data.data.users &&
          response.data.data.users.length > 0
        ) {
          setAdminDetails(response.data.data.users[0]);
        }
      } catch (error) {
        console.error("Error fetching company admin:", error);
      }
    };

    fetchCompanyAdmin();
  }, [companyData]);

  useEffect(() => {
    console.log("Updated adminDetails:", adminDetails);
  }, [adminDetails]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: "white",
              width: "100%",
              margin: "0px",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            View Company Details
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "10px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            {/* // Edit button */}
            {/* <div style={{display:'flex',justifyContent:'space-between',   margin: "0 80px",}}>
            <p></p>
            <button style={{border:'1px solid blue' , backgroundColor:'blue', color:'white',padding:'5px',width:'80px',borderRadius:'2px'}}
            onClick={() => handleEditClick(companyData)}>
                EDIT
           </button>
        </div> */}
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                justifyItems: "center",
                margin: "0 80px",
              }}
            >
              <>
                <div style={{ marginLeft: "10px", marginRight: "80px" }}>
                  <div
                    style={{
                      borderBottom: "1px dotted lightgray",
                      paddingBottom: "32px",
                      marginBottom: "16px",
                    }}
                  >
                    <h3>Company Details</h3>
                    <div style={{ marginTop: "16px" }}>
                      {[
                        {
                          label: "Company Name",
                          value: companyData?.companyDetails?.companyName,
                        },
                        {
                          label: "Owner Name",
                          value: companyData?.companyDetails.ownerName,
                        },
                        {
                          label: "Company Mailing Address",
                          value:
                            companyData?.companyDetails.companyMailingaddress,
                        },
                        {
                          label: "Phone Number",
                          value: companyData?.companyDetails.phoneNo,
                        },
                        {
                          label: "GST No",
                          value: companyData?.companyDetails.gstNo,
                        },
                        {
                          label: "PAN No",
                          value: companyData?.companyDetails.panNo,
                        },
                      ].map((field, index) => (
                        <div
                          key={index}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "20px",
                            marginBottom: "16px",
                          }}
                        >
                          <span style={{ fontWeight: "600" }}>
                            {field.label}
                          </span>
                          <span>:</span>
                          <span>{field.value}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    style={{
                      borderBottom: "1px dotted lightgray",
                      paddingBottom: "32px",
                      marginBottom: "16px",
                    }}
                  >
                    <h3>Contact Person Details</h3>
                    <div style={{ marginTop: "16px" }}>
                      {[
                        {
                          label: "Name",
                          value: companyData?.personalInfo?.name,
                        },
                        {
                          label: "Designation",
                          value: companyData?.personalInfo?.designation,
                        },
                        {
                          label: "Mail ID",
                          value: companyData?.personalInfo?.emailId,
                        },
                        {
                          label: "Phone Number",
                          value: companyData?.personalInfo?.phNo,
                        },
                      ].map((field, index) => (
                        <div
                          key={index}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "20px",
                            marginBottom: "16px",
                          }}
                        >
                          <span style={{ fontWeight: "600" }}>
                            {field.label}
                          </span>
                          <span>:</span>
                          <span>{field.value}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    style={{
                      borderBottom: "1px dotted lightgray",
                      paddingBottom: "32px",
                      marginBottom: "16px",
                    }}
                  >
                    <h3>Address</h3>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: "20px",
                        marginBottom: "16px",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Office Address</span>
                      <span>:</span>
                      <span>{companyData?.companyAddress.officeAddress}</span>
                    </div>
                    <div style={{ marginTop: "16px" }}>
                      {[
                        {
                          label: "Country",
                          value: companyData?.companyAddress.country,
                        },
                        {
                          label: "City / State",
                          value: companyData?.companyAddress.cityOrState,
                        },
                        {
                          label: "PinCode",
                          value: companyData?.companyAddress.pinCode,
                        },
                        {
                          label: "Industry",
                          value: companyData?.companyAddress.industry,
                        },
                        {
                          label: "Website URL",
                          value: companyData?.companyAddress.webSiteUrl,
                        },
                        {
                          label: "Description",
                          value: companyData?.companyAddress.description,
                        },
                      ].map((field, index) => (
                        <div
                          key={index}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "20px",
                            marginBottom: "16px",
                          }}
                        >
                          <span style={{ fontWeight: "600" }}>
                            {field.label}
                          </span>
                          <span>:</span>
                          <span>{field.value}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    style={{
                      borderBottom: "1px dotted lightgray",
                      paddingBottom: "32px",
                      marginBottom: "16px",
                    }}
                  >
                    <h3>Company Documents</h3>
                    <div style={{ marginTop: "16px" }}>
                      {[
                        {
                          label: "Agreement Document",
                          value:
                            companyData?.companyDocuments?.agreementDocument,
                        },
                        {
                          label: "Company TAN Document",
                          value: companyData?.companyDocuments?.companyTanNo,
                        },
                      ].map((field, index) => (
                        <div
                          key={index}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "20px",
                            marginBottom: "16px",
                          }}
                        >
                          <span style={{ fontWeight: "600" }}>
                            {field.label}
                          </span>
                          <span>:</span>
                          <span>
                            {field.value ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    marginLeft: "10px",
                                    gap: "15px",
                                  }}
                                >
                                  <a
                                    href={`${BACKEND_URL}/${field.value}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      color: "black",
                                      marginBottom: "10px",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Icon.Image />
                                  </a>
                                  <a
                                    href={`${BACKEND_URL}/${field.value}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      const link = document.createElement("a");
                                      link.href = `${BACKEND_URL}/${field.value}`;
                                      link.download = field.value
                                        .split("/")
                                        .pop();
                                      document.body.appendChild(link);
                                      link.click();
                                      document.body.removeChild(link);
                                    }}
                                    style={{
                                      color: "black",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <Icon.Download />
                                  </a>
                                </div>
                              </>
                            ) : (
                              <span>Not Uploaded</span>
                            )}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  {companyData?.companyEnableModules && (
                    <div
                      style={{
                        borderBottom: "1px dotted lightgray",
                        paddingBottom: "32px",
                        marginBottom: "16px",
                      }}
                    >
                      <h3>Enable Modules</h3>
                      <div
                        style={{
                          marginTop: "16px",
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          gap: "20px",
                        }}
                      >
                        {[
                          {
                            label: "Drawing",
                            value: companyData?.companyEnableModules?.drawings,
                          },
                          {
                            label: "P&M",
                            value: companyData?.companyEnableModules?.pAndM,
                          },
                          {
                            label: "QA/QC",
                            value: companyData?.companyEnableModules?.qaAndQc,
                          },
                          {
                            label: "EHS",
                            value: companyData?.companyEnableModules?.ehs,
                          },
                          {
                            label: "QS",
                            value: companyData?.companyEnableModules?.qs,
                          },
                          {
                            label: "Planner",
                            value: companyData?.companyEnableModules?.planner,
                          },
                          {
                            label: "HR",
                            value: companyData?.companyEnableModules?.hr,
                          },
                          {
                            label: "User",
                            value: companyData?.companyEnableModules?.user,
                          },
                          {
                            label: "Store",
                            value: companyData?.companyEnableModules?.store,
                          },
                          {
                            label: "Admin",
                            value: companyData?.companyEnableModules?.admin,
                          },
                          {
                            label: "Dashboard",
                            value: companyData?.companyEnableModules?.dashBoard,
                          },
                          {
                            label: "Workstatus",
                            value:
                              companyData?.companyEnableModules?.workStatus,
                          },
                          {
                            label: "Space",
                            value: companyData?.companyEnableModules?.space,
                          },
                          {
                            label: "Checklist",
                            value: companyData?.companyEnableModules?.checkList,
                          },
                          {
                            label: "Checklist Process",
                            value:
                              companyData?.companyEnableModules
                                ?.checkListProcess,
                          },
                          {
                            label: "HR PRocess",
                            value: companyData?.companyEnableModules?.hrProcess,
                          },
                        ]
                          .filter((field) => field.value === true)
                          .map((field, index) => (
                            <React.Fragment key={index}>
                              <span style={{ fontWeight: "600" }}>
                                {field.label}
                              </span>
                              <span>:</span>
                              <span>Enabled</span>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      paddingBottom: "32px",
                      marginBottom: "16px",
                      borderBottom: "1px dotted lightgray",
                    }}
                  >
                    <h3>Admin User Details</h3>
                    <div style={{ marginTop: "16px" }}>
                      {adminDetails ? (
                        <section className="grid grid-cols-1 gap-5 mx-2">
                          <div className="py-3">
                            <div className="row">
                              <div className="col-5 taskviewTitle">
                                First Name
                              </div>
                              <div className="col-2">:</div>
                              <div className="col-5 text-start taskviewData">
                                {adminDetails.firstName}
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-5 taskviewTitle">
                                Last Name
                              </div>
                              <div className="col-2">:</div>
                              <div className="col-5 text-start taskviewData">
                                {adminDetails.lastName}
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-5 taskviewTitle">Mail ID</div>
                              <div className="col-2">:</div>
                              <div className="col-5 text-start taskviewData">
                                {adminDetails.email}
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="col-5 taskviewTitle">
                                Phone Number
                              </div>
                              <div className="col-2">:</div>
                              <div className="col-5 text-start taskviewData">
                                {adminDetails.contactNumber}
                              </div>
                            </div>
                          </div>
                        </section>
                      ) : (
                        <div>No admin details available.</div>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      paddingBottom: "32px",
                      marginBottom: "16px",
                      borderBottom: "1px dotted lightgray",
                    }}
                  >
                    <h3>Sites</h3>

                    {companyData?.sites.map((site) => (
                      <div key={site._id} style={{ marginBottom: "16px" }}>
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          onClick={() => handleToggleExpand(site._id)}
                        >
                          <span>{site.siteName}</span>
                          <span style={{ fontSize: "12px" }}>
                            {expandedSiteId === site._id ? "▲" : "▼"}
                          </span>
                        </div>
                        {expandedSiteId === site._id && (
                          <div
                            style={{
                              marginTop: "8px",
                              paddingLeft: "16px",
                              borderLeft: "2px solid lightgray",
                            }}
                          >
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(3, 1fr)",
                                gap: "20px",
                                marginBottom: "16px",
                                marginTop: "16px",
                              }}
                            >
                              <strong>Address</strong> <strong>:</strong>{" "}
                              <span>{site.siteAddress}</span>
                              <strong>Location</strong> <strong>:</strong>
                              <span>
                                Latitude {site.siteLocationDetails?.latitude},
                                Longitude {site.siteLocationDetails?.longitude}
                              </span>
                              <strong>Pin Code</strong> <strong>:</strong>
                              <span>{site.pinCode}</span>
                              <strong>Venture Type</strong> <strong>:</strong>
                              <span>{site.ventureType}</span>
                              <strong>Permissions</strong> <strong>:</strong>
                              <span>
                                {site.permissions?.authentication
                                  ? "Authentication Required"
                                  : "No Authentication"}
                              </span>
                            </div>

                            {site.apartmentsDetails && (
                              <div style={{ marginTop: "16px" }}>
                                <h4>Apartments Details</h4>
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                    gap: "20px",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <strong>Amenities</strong> <strong>:</strong>{" "}
                                  <span>
                                    {site.apartmentsDetails.amenities.length > 0
                                      ? site.apartmentsDetails.amenities.join(
                                          ", "
                                        )
                                      : "None"}
                                  </span>
                                  <strong>Clubhouse</strong> <strong>:</strong>
                                  <span>
                                    {site.apartmentsDetails.clubhouse.length > 0
                                      ? site.apartmentsDetails.clubhouse.join(
                                          ", "
                                        )
                                      : "None"}
                                  </span>
                                  <strong>Towers</strong> <strong>:</strong>{" "}
                                  <span>
                                    {site.apartmentsDetails.towers.length > 0
                                      ? site.apartmentsDetails.towers.length
                                      : "None"}
                                  </span>
                                </div>
                              </div>
                            )}

                            {site.buildingsDetails && (
                              <div style={{ marginTop: "16px" }}>
                                <h4>Buildings Details</h4>
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                    gap: "20px",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <strong>Amenities</strong> <strong>:</strong>
                                  <span>
                                    {site.buildingsDetails.amenities.length > 0
                                      ? site.buildingsDetails.amenities.join(
                                          ", "
                                        )
                                      : "None"}
                                  </span>
                                  <strong>Basements</strong> <strong>:</strong>
                                  <span>
                                    {site.buildingsDetails.basements === "Yes"
                                      ? `${site.buildingsDetails.noOfBasements} basements`
                                      : "No basements"}
                                  </span>
                                  <strong>Pours for Basement</strong>{" "}
                                  <strong>:</strong>{" "}
                                  <span>
                                    {
                                      site.buildingsDetails
                                        .noOfPouresForBasement
                                    }
                                  </span>
                                  <strong>Towers</strong> <strong>:</strong>
                                  <span>
                                    {site.buildingsDetails.towers.length}
                                  </span>
                                </div>
                              </div>
                            )}

                            {site.villasDetails && (
                              <div style={{ marginTop: "16px" }}>
                                <h4>Villas Details</h4>
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                    gap: "20px",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <strong>Amenities</strong> <strong>:</strong>{" "}
                                  <span>
                                    {site.villasDetails.amenities.join(", ")}
                                  </span>
                                  <strong>Cellar for Villas</strong>
                                  <strong>:</strong>
                                  <span>
                                    {site.villasDetails
                                      .cellarIsThereForVillas === "Yes"
                                      ? `Yes, ${site.villasDetails.noOfCellarsForVillas} cellars`
                                      : "No"}
                                  </span>
                                  <strong>Floors for Villas</strong>{" "}
                                  <strong>:</strong>{" "}
                                  <span>
                                    {site.villasDetails.noOfFloorsForVillas}
                                  </span>
                                  <strong>Number of Villas</strong>{" "}
                                  <strong>:</strong>
                                  <span>{site.villasDetails.noOfVillas}</span>
                                  <strong>Villa Type</strong> <strong>:</strong>
                                  <span>
                                    {site.villasDetails.selectVillaType}
                                  </span>
                                  <strong>Villa Type Details</strong>{" "}
                                  <strong>:</strong>
                                  <span>
                                    {site.villasDetails
                                      .villaTypesAreCommonForEveryVillas ===
                                    "No"
                                      ? "Different types for each villa"
                                      : "Common type for all villas"}
                                  </span>
                                </div>
                              </div>
                            )}

                            <div style={{ marginTop: "16px" }}>
                              <h4>Enabled Modules</h4>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(3, 1fr)",
                                  gap: "8px 16px",
                                }}
                              >
                                {[
                                  {
                                    label: "Drawing",
                                    value: site.enableModules?.drawing,
                                  },
                                  {
                                    label: "P&M",
                                    value: site.enableModules?.pAndM,
                                  },
                                  {
                                    label: "QA/QC",
                                    value: site.enableModules?.qaAndQc,
                                  },
                                  {
                                    label: "EHS",
                                    value: site.enableModules?.ehs,
                                  },
                                  {
                                    label: "QS",
                                    value: site.enableModules?.qs,
                                  },
                                  {
                                    label: "Planner",
                                    value: site.enableModules?.planner,
                                  },
                                  {
                                    label: "HR",
                                    value: site.enableModules?.hr,
                                  },
                                  {
                                    label: "User",
                                    value: site.enableModules?.user,
                                  },
                                  {
                                    label: "Store",
                                    value: site.enableModules?.store,
                                  },
                                  {
                                    label: "Admin",
                                    value: site.enableModules?.admin,
                                  },
                                  {
                                    label: "Checklist",
                                    value: site.enableModules?.checkList,
                                  },
                                ]
                                  .filter((module) => module.value === true)
                                  .map((module, index) => (
                                    <React.Fragment key={index}>
                                      <strong>{module.label}</strong>
                                      <strong>:</strong>
                                      <span>Enabled</span>
                                    </React.Fragment>
                                  ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            </form>
          </>
        </DialogContent>
      </Dialog>
      <AddCompanyDialog
        close={handleAddCompanyClose}
        open={showAddCompanyModal}
        companyData={selectedCompanyData}
      />
    </>
  );
};
export default ViewComapnyDialog;
