import React from "react";
import PerformanceSummaryReport from "./PerformanceSummaryReport";
import LogReportStatus from "../PNMreports/LogReportStatus/LogReportStatus";
import { useRecoilState } from "recoil";
import { reportsTab } from "../../../atoms";
import InspectionReports from "./InspectionReports";
const Reports = () => {

//   return (
//     <Tabs
//       id="controlled-tab-example"
//       activeKey={key}
//       onSelect={(k) => setKey(k)}
//       className="nav-pills"
//       style={{ marginTop: "15px" }}
//     >
//       <Tab eventKey="LRS" title="Log Report Status">
//         <div><LogReportStatus/></div>
//       </Tab>
//       <Tab eventKey="PSR" title=" Perform Summary Report">
//         <PerformanceSummaryReport />
//       </Tab>
//     </Tabs>
//   );
// };
const [value, setValue] = useRecoilState(reportsTab);
const handleChange = (newValue) => {
  setValue(newValue);
};
const allTabs = [
  { label: "Log Report Status", index: 0, component: <LogReportStatus/> },
  { label: "Perform Summary Report", index: 1, component:  <PerformanceSummaryReport /> },
  { label: "Inspections Report", index: 2, component:  <InspectionReports /> },
];

return (
  <>
    <div className="containerDrawingPagesRotoSite">
      <ul className="tabsRotoSite" role="tablist">
        {allTabs.map((tab) => (
          <li
            key={tab.index}
            className={`tabRotoSite ${value === tab.index ? "active" : ""}`}
            role="tab"
            aria-selected={value === tab.index}
            aria-controls={`tabpanel-${tab.index}`}
            id={`tab-${tab.index}`}
  
            onClick={() => handleChange(tab.index)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
    </div>

    {allTabs.map((tab) => (
      <TabPanel key={tab.index} value={value} index={tab.index}>
        {tab.component}
      </TabPanel>
    ))}
  </>
  
);
};
function TabPanel(props) {
const { children, value, index, ...other } = props;

return (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
    className="tabpanel"
  >
    {value === index && <div className="tabpanel-content">{children}</div>}
  </div>
);
}
export default Reports;
