import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import {drawingoptionsTab, siteId, userInfo,  } from "../../../atoms";
import { WebApimanager } from "../../../WebApiManager";
import SelectSite from "../../../Utilities/SelectSite";
import AddCategory from "./AddCategory";
import AssignCategory from "./AssignCategory";
import AddRegister from "./AddRegister";
import WorkFlow from "./WorkFlow";

const Options = () => {
  const webApi = new WebApimanager();
  const [value, setValue] = useRecoilState(drawingoptionsTab);
  const [site] = useRecoilState(siteId);
  const [currentUserInfo] = useRecoilState(userInfo);
//   const [tabEnableModules, setTabEnableModules] = useState({
//     drawing: false,
//     rfi: false,
//     pending: false,
//     register: false,
//     analysis: false,
//   });

//   useEffect(() => {
//     if (currentUserInfo?.permittedSites) {
//       let enabledTabs = {
//         drawing: false,
//         rfi: false,
//         pending: false,
//         register: false,
//         analysis: false,
//       };

//       currentUserInfo.permittedSites.forEach((site) => {
//         const architectureToRoDetails =
//           site.enableModules.drawingDetails?.architectureToRoDetails;

//         if (architectureToRoDetails) {
//           enabledTabs = {
//             drawing: enabledTabs.drawing || architectureToRoDetails.drawing,
//             rfi: enabledTabs.rfi || architectureToRoDetails.rfi,
//             pending: enabledTabs.pending || architectureToRoDetails.pending,
//             register: enabledTabs.register || architectureToRoDetails.register,
//             analysis: enabledTabs.analysis || architectureToRoDetails.analysis,
//           };
//         }
//       });

//       setTabEnableModules(enabledTabs);
//     }
//   }, [currentUserInfo]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  if (site === "") {
    return <SelectSite />;
  }

//   const anyTabEnabled = Object.values(tabEnableModules).some((val) => val);

  const allTabs = [
    { label: "Category Register", index: 0, 
        component: <AddCategory/>
     },
    { label: "Assign Category", index: 1,
         component: <AssignCategory /> 
        },
    { label: "Add Register", index: 2, 
        component: <AddRegister /> 
    },
    { label: "Work Flow", index: 3, 
      component: <WorkFlow/>
  },
   
  ];

  // Filter enabled tabs based on user permissions
//   const enabledTabs = allTabs.filter(
//     (tab) => tabEnableModules[tab.label.toLowerCase()]
//   );

  return (
    <>
     
        <>
          <div className="containerDrawingPagesArchitecture">
            <ul className="tabsArchitectureTabs" role="tablist">
              {allTabs.map((tab) => (
                <li
                  key={tab.index}
                  className={`tabArchitectureTab ${
                    value === tab.index ? "active" : ""
                  }`}
                  role="tab"
                  aria-selected={value === tab.index}
                  aria-controls={`tabpanel-${tab.index}`}
                  id={`tab-${tab.index}`}
                  onClick={() => handleChange(tab.index)}
                >
                  {tab.label}
                </li>
              ))}
            </ul>
          </div>

          {allTabs.map((tab) => (
            <TabPanel key={tab.index} value={value} index={tab.index}>
              {tab.component}
            </TabPanel>
          ))}
        </>
     
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      className="tabpanelArchitecture"
    >
      {value === index && <div className="tabpanel-content">{children}</div>}
    </div>
  );
}

export default Options;
