/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import CreateWorkSequenceDialog from './CreateWorkSequenceDialog';
import AddDocumentsDialog from './AddDocumentsDialog';
import CreateTasksDialog from './CreateTasksDialog';
import { WebApimanager } from '../../WebApiManager';
import { Button } from 'react-bootstrap'; 
import * as Icon from "react-bootstrap-icons";
import AssignTasksDialog from './AssignTasksDialog';
import AddPlannerCategory from './AddPlannerCategory';
import { useRecoilState } from "recoil";
import { siteId } from '../../atoms';
import SelectSite from '../../Utilities/SelectSite';

const Planner = () => {
    let webApi = new WebApimanager();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDocumentsDialog, setOpenAddDocumentsDialog] = useState(false);
  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [openCreateCategoryialog, setOpenCreateCategoryDialog] = useState(false);
  const [openAssignTaskDialog, setOpenAssignTaskDialog] = useState(false);
  const [formData, setFormData] = useState({  category: '', names: [{ name: '' }] });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [datasites, setDatasites] = useState([]);
  const [plannerList, setPlannerList] = useState([]); 
  const [site, setSite] = useRecoilState(siteId)



  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {

    webApi.get( "api/Task")
      .then((response) => {
        if (response.data && Array.isArray(response.data.data.tasks)) {
          const tasksData = response.data.data.tasks.map((task)=>{
              return {
                workSequence: task.workSequenceId && task.workSequenceId.name ? task.workSequenceId.name:'',
                unit: task.unitId.name,
                unitId: task.unitId._id,
                duration: task.duration,
                startDate: task.startDate,
                completionDate: task.endDate
              }
          })
          
          setData(tasksData);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // useEffect(() => {
  //   webApi.get( "api/sites/AllSites")
  //   .then((response) => {
  //     if (response.data && Array.isArray(response.data.sites)) {
  //       setDatasites(response.data.sites);
  //       console.log('dataSites: ', response.data.sites);
  //     } else {
      
  //       console.error("Unexpected data format:", response.data);
  //     }
  //   })
  //   .catch((error) => {
  //     console.error("There was an error fetching the data!", error);
     
  //   })
  //   .finally(() => {
      
  //   });
  // },[]);


  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    const filtered = data.filter((item) =>
      `${item.workSequence}
      ${item.unit}
      ${item.duration}
      ${new Date(item.startDate).toLocaleDateString()}
      ${new Date(item.completionDate).toLocaleDateString()}
     `
      .toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filtered);
  }, [data, searchTerm]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  }

  const handleCategoryDialogOpen = () => {
    setOpenCreateCategoryDialog(true);
  }


  const  handleDialogClose = () =>{
    setOpenDialog(false);
    window.location.reload();
  }


  const handleAddDocumentsDialogOpen = () => {
    setOpenAddDocumentsDialog(true);
  };

  const handleAddDocumentsDialogClose = () => {
    setOpenAddDocumentsDialog(false);
    window.location.reload(); 
  };

  const handleCreateTasksDialogOpen = () => {
    setOpenCreateTaskDialog(true);
  };

  const handleCreateTasksDialogClose = () => {
    setOpenCreateTaskDialog(false);
    window.location.reload(); 
  };

  const handleAssignTasksDialogOpen = () => {
    setOpenAssignTaskDialog(true);
  };

  const handleAssignTasksDialogClose = () => {
    setOpenAssignTaskDialog(false);
    window.location.reload(); 
  };


  

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (name.startsWith('name')) {
      const updatedNames = [...formData.names];
      updatedNames[index] = { name: value };
      setFormData({ ...formData, names: updatedNames });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleAddNameField = () => setFormData({ ...formData, names: [...formData.names, { name: '' }] });
  const handleRemoveNameField = (index) => setFormData({ ...formData, names: formData.names.filter((_, i) => i !== index) });

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if(value === 'taskCategory'){
      handleCategoryDialogOpen()
    } else if (value === 'createWorkSequence') {
      handleDialogOpen()
    } else if (value === 'addDocuments') {
      handleAddDocumentsDialogOpen()
    } else if(value === 'createTasks') {
      handleCreateTasksDialogOpen()
    }
    else if(value === 'assignTasks') {
      handleAssignTasksDialogOpen()
      }
    else {
      event.target.value = "";
    }
  };


  const handleSubmit = () => {
    if (validateForm()) {
      setErrors({});
      setLoading(true);  
   
       if (!site) {
        setErrors( 'Selected site not found' );
        setLoading(false);
        return;
      }
      const transformedNames = formData.names.map(item => item.name);
      webApi.post( "api/WorkSequence", {
        siteId: site,
        category: formData.category,
        names: transformedNames
       
      })
      .then((response) => {
        const data = response.data;
        console.log("Server Response:", response); 
        console.log("Data:", data);
        setOpenDialog(false);
        setPlannerList([...plannerList, formData]); 
        setFormData({
          category:'',
          names: [{ name: '' }]
        });
        setErrors({});
        window.location.reload();
      })
      .catch((error) => {
        console.error("There was an error logging in!", error);
        setError("There was an error logging in");
      })
      .finally(() => {
        setLoading(false);  
      });
    }
  };
  const validateForm = () => {
    const newErrors = {};
    // if (!formData.siteName) newErrors.siteName = 'Site Name is required';
    if (!formData.category) newErrors.category = 'Category is required';
    if (formData.names.some(name => !name.name)) newErrors.names = 'All name fields are required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
<>
<div>
{site === '' ?
      <>
       <SelectSite />
       </>
      : 
<>
<div style={{marginTop:'50px'}}>
   <div style={{ display: 'flex',  justifyContent: 'space-around' }}>
           <h3 style={{ color: 'brown' }}>Planner Lists</h3>
           <div style={{display:'flex',flexDirection:'row'}} >
       
           
     <div style={{ position: 'relative', width: '250px' ,paddingLeft:'20px',height:'50px'}}> {/* Adjust width as needed */}
<input 
placeholder='Search Planner Lists'
value={searchTerm}
onChange={handleSearchInputChange}
style={{ paddingLeft: '20px', borderRadius: '10px', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)', border: 'none',width:'250px',height:'100%' }} />
<Icon.Search style={{ position: 'absolute',right:'1px', top: '50%', transform: 'translateY(-50%)' }} />
</div>
          <Button style={{ background: 'none', color: 'blue', height: '50px', marginLeft: '30px', display: 'flex', alignItems: 'center' }}>
<Icon.PlusCircle size={20} style={{ marginRight: '5px' }} />
Add New
<div style={{ position: 'relative' }}>
<select className="form-control fs-12" style={{ width: "300px", height: "40px", marginLeft: "10px" }}
onChange={handleSelectChange}
>
 <option value="">Select A Planner Work</option>
 <option value="taskCategory"><AddPlannerCategory 
            openCreateTaskDialog={openCreateCategoryialog}
            onClose= {()=>setOpenCreateCategoryDialog(false)} 
            site={site}
  />
  1. Create Category</option>
 <option value="createWorkSequence">
 <CreateWorkSequenceDialog
          open={openDialog}
          onClose={handleDialogClose}
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleAddNameField={handleAddNameField}
          handleRemoveNameField={handleRemoveNameField}
          errors={errors}
          site={site}
        />
  2. Create Work Sequence</option>
 <option value="addDocuments">
 <AddDocumentsDialog
          openAddDocumentsDialog={openAddDocumentsDialog}
          onClose={handleAddDocumentsDialogClose}
          site={site}
        />
  3. Add Documents to Work Sequence</option>
 <option value="createTasks">
 <CreateTasksDialog openCreateTaskDialog={openCreateTaskDialog} onClose= {handleCreateTasksDialogClose}  site={site}/>
  4. Create Tasks</option>
    
  <option value="assignTasks">
 <AssignTasksDialog openAssignTaskDialog={openAssignTaskDialog} onClose= {handleAssignTasksDialogClose} data ={data}/>
  5. Assign Tasks</option>
    
</select>
</div>
</Button>

       </div> 
       </div>
       <div style={{marginRight:'50px',marginLeft:'50px',boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)'}}>
       <table style={{ width: '100%',marginTop:'10px'}}>
<thead style={{backgroundColor:'lightslategrey',textAlign:'center',height:'45px'}} >
<tr>
 <th style={{ width: '5%' }}>S.No</th>
 <th style={{ width: '15%' }}>Task</th>
 <th style={{ width: '15%' }}>Unit</th>
 <th style={{ width: '10%' }}>Duration</th>
 <th style={{ width: '10%' }}>Start<br/> Date</th>
 <th style={{ width: '10%' }}>Completion<br/> Date</th>
</tr>
</thead>
<tbody style={{ textAlign: 'center', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)' }}>
{filteredData.map((item, index) => (
                          <tr key={item._id} style={{ borderBottom: '1px solid black', padding: '10px',height:'60px', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)'}}>
                          <td>{index + 1}</td>
                           <td>{item.workSequence}</td>
                           <td>{item.unit}</td>
                           <td>{item.duration ?? ''}</td>
                           <td>{new Date(item.startDate).toLocaleDateString()}</td>
                           <td>{new Date(item.completionDate).toLocaleDateString()}</td>
                       </tr>
           
           
         ))}
       </tbody>
</table>
</div>
</div>
</>
}
</div>
<div style={{marginTop:'10px'}}>
</div>
</>
  );
};

export default Planner;
