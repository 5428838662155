import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dialog, DialogContent } from "@mui/material";
import * as Icon from "react-bootstrap-icons";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import MultiSelectSearch from "../../../Utilities/MultiSelectSearch";
import { siteId } from "../../../atoms";
import { useRecoilState } from "recoil";
import { Button } from "react-bootstrap";
import TableWrapper from "../../../Utilities/TableWrapper";

const WorkFlowSiteLevel = () => {
    let webApi = new WebApimanager();
    const [open, setOpen] = useState(false);
    const [filteredCategories, setFilteredCategories] = useState([]); // for selected categories
    const [selectedConsultant, setSelectedConsultant] = useState("");
    const [existingCategories, setExistingCategories] = useState([]); // for unassigned categories
    const [existingConsultants, setExistingConsultants] = useState([]);
    const [site, setSite] = useRecoilState(siteId);
  
    const [formData, setFormData] = useState({
      designConsultant: "",
      DrawingCategory: [],
    });
    const [apiProcessing, setApiProcessing] = useState({
      loader: false,
      message: "Loading...",
    });
    const [errors, setErrors] = useState({});
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setFormData({
        designConsultant: "",
        DrawingCategory: [],
      });
      setOpen(false);
    //   props.reloadPage();
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
  
      // Clear categories and set consultant
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        ...(name === "designConsultant" && { DrawingCategory: [] }), // Clear categories if consultant changes
      }));
  
      // Fetch new categories based on the selected consultant
      if (name === "designConsultant") {
        setSelectedConsultant(value);
      }
    };
  
    const handleCategoryChange = (index, value) => {
      const newCategories = [...formData.DrawingCategory];
      newCategories[index] = value;
      setFormData({ ...formData, DrawingCategory: newCategories });
    };
  
    const handleSubmit = async () => {
      let valid = true;
      const newErrors = {};
  
      if (!formData.designConsultant?.trim()) {
        newErrors.designConsultant = "Design Consultant is required";
        valid = false;
      }
      if (!formData.DrawingCategory.length) {
        newErrors.DrawingCategory =
          "At least one Drawing Category must be selected";
        valid = false;
      } else {
        const hasEmptyCategory = formData.DrawingCategory.some((cat) => cat === "");
      if (hasEmptyCategory) {
        newErrors.DrawingCategory = "Each Drawing Category must have a valid value";
        valid = false;
      }
      }
  
      if (!valid) {
        setErrors(newErrors);
        return;
      }
  
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
  
      const submissionData = {
        siteId:site,
        department:formData.designConsultant,
        module:"siteLevel",
        designConsultants:formData.DrawingCategory
      };
  
      try {
        const response = await webApi.post(
          "api/assignDesignConsultants",
          submissionData
        );
        console.log(response);
        fetchCategories();
        // window.location.reload();
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setApiProcessing({ loader: false, message: "" });
        handleClose();
      }
    };
  
    const getAll = () => {
      webApi
        .get(`api/drawingWorkFlow/siteLevelTrue?siteId=${site}`)
        .then((data) => {
          if (data) {
            setExistingConsultants(data.data.data.users);
          }
        });
    };
    useEffect(() => {
      getAll();
    }, [open]);
  
    const fetchCategories = async () => {
      if (selectedConsultant) {
        try {
          const response = await webApi.get(
            // `api/architectureToRoSelectionRegister/getAllcategoriesAssigned?designDrawingConsultantId=${selectedConsultant}`
            `api/designDrawingConsultant/getAll?siteId=${site}`
          );
          console.log(response.data.data.users,"gvdsbjfskn")
          if (response) {
            const { assignedCategories, unassignedCategories } =
              response.data.data.users;
            console.log("Received assignedCategories:", assignedCategories);
            console.log("Received unassignedCategories:", unassignedCategories);
            setFilteredCategories(response.data.data.users);
            setExistingCategories(response.data.data.users);
          }
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      }
    };
    const fetchDepartment = async() =>{
        try {
           const response = await webApi.get(`api/assignDesignConsultants/designConsultantsByDepartment?department=${selectedConsultant}`) 
           console.log("response",response)
        } catch (error) {
            console.log(error.message)
            
        }
    }
  
    useEffect(() => {
      fetchCategories();
      if(selectedConsultant){
        
          fetchDepartment();
      }
    }, [selectedConsultant]);
  
    useEffect(() => {
      console.log("Filtered Categories:", filteredCategories);
    }, [filteredCategories]);
  
    useEffect(() => {
      console.log("Existing Categories:", existingCategories);
    }, [existingCategories]);
  
    const handleAddField = () => {
      setFormData({
        ...formData,
        DrawingCategory: [...formData.DrawingCategory, ""],
      });
    };
  
    const handleRemoveField = (index) => {
      const newCategories = formData.DrawingCategory.filter(
        (_, i) => i !== index
      );
      setFormData({ ...formData, DrawingCategory: newCategories });
    };
  
    const onDelete = (categoryId) => {
      const apiEndpoint = `api/architectureToRoSelectionRegister/categories?designDrawingConsultant=${formData.designConsultant}&categories=${categoryId}`;
  
      webApi
        .delete(apiEndpoint)
        .then((response) => {
          console.log(" deleted successfully:", response.data);
          getAll();
          fetchCategories();
        })
        .catch((error) => {
          console.error("Error deleting :", error);
        });
    };

    useEffect(()=>{
        console.log(existingCategories,"existingCategories")
    },[existingCategories])
  
    return (
      <>
        {" "}
        <TableWrapper
          children={
              <>
              <div
                style={{
                  display: "flex",
                  marginLeft: "5px",
                  marginRight: "5px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                
               
                </div>
        <Loader open={apiProcessing.loader} message={apiProcessing.message} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              margin: "15px",
            }}
          >
            <div
              style={{
                margin: "10px",
                width: "50%",
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  border: "1px solid #97979740",
                  maxHeight: "63vh",
                  overflowY: "auto",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    backgroundColor: " #4D4D4D",
                    color: "white",
                  }}
                >
                  <p style={{ padding: "5px" }}>View Category</p>
                </div>
                <div
                //   style={{
                //     paddingRight: "30px",
                //     paddingLeft: "30px",
                //   }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                  >
                    <div style={{ padding: "10px 20px" }}>
                      <label
                        htmlFor="designConsultant"
                        style={{
                          fontSize: "20px",
  
                          fontWeight: "500",
  
                          textAlign: "start",
  
                          paddingTop: "0.5rem",
  
                          paddingBottom: "0.5rem",
                          display: "block",
                        }}
                      >
                        Department :<span style={{ color: "red" }}>*</span>
                      </label>
  
                      <select
                        id="designConsultant"
                        name="designConsultant"
                        value={formData.designConsultant}
                        onChange={handleChange}
                        style={{
                          width: "100%", // Full width of the parent container
                          padding: "0.375rem 0.75rem", // Padding similar to Bootstrap's form-control
                          fontSize: "1rem", // Standard font size for form-control
                          lineHeight: "2", // Line height similar to form-control
                          color: "#495057", // Text color similar to form-control
                          backgroundColor: "#fff", // White background for the input
                          border: "1px solid #ced4da", // Standard gray border for form-control
                          borderRadius: "0.25rem", // Rounded corners
                          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Custom box shadow
                          transition:
                            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out", // Smooth transition
                          outline: "none", // No outline on focus
                        }}
                      >
                        <option value="">Select Consultant</option>
                        {existingConsultants &&
                          existingConsultants.map((consultant) => (
                            <option key={consultant._id} value={consultant.department}>
                              {consultant.department}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
  
                  {formData.designConsultant && (
                    <div
                      style={{
                        margin: "10px",
                        maxHeight: "270px",
                        overflowY: "auto",
                      }}
                    >
                      {filteredCategories &&
                        filteredCategories.map((category, index) => (
                          <li
                            key={category._id}
                            style={{
                              display: "flex",
                              borderTop: index > 0 ? "1px dotted #000" : "none",
                              borderBottom:
                                index === filteredCategories.length - 1
                                  ? "1px dotted #000"
                                  : "none",
                              padding: "15px 5px",
                            }}
                          >
                            <p
                              style={{
                                width: "500px",
                                margin: 0,
                                textAlign: "start",
                                marginLeft: "5px",
                                fontSize: "16px",
                              }}
                            >
                              {category.role}
                            </p>
                            <Icon.TrashFill
                              size={20}
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={() => onDelete(category._id, "Category")}
                            />
                          </li>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                margin: "10px",
                width: "50%",
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  border: "1px solid #97979740",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    backgroundColor: " #4D4D4D",
                    color: "white",
                  }}
                >
                  <p style={{ padding: "5px" }}>Add Category</p>
                </div>
                <div
                  style={{
                    maxHeight: "55vh",
                    overflowY: "auto",
                    paddingRight: "30px",
                    paddingLeft: "30px",
                    padding: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "15px",
                      marginBottom: "5px",
                    }}
                  >
                    <p></p>
                    <Button
                      style={{
                        height: "40px",
                        marginLeft: "40px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "var(--primary-color)",
                        border: "1px solid var(--primary-color)",
                      }}
                      onClick={() => handleAddField("categories")}
                    >
                      <Icon.PlusCircle
                        size={20}
                        style={{
                          marginRight: "5px",
                          color: "var(--primary-color)",
                          backgroundColor: "white",
                          borderRadius: "50%",
                          padding: "1px",
                        }}
                      />{" "}
                      Add
                    </Button>
                  </div>
  
                  {formData.DrawingCategory &&
                    formData.DrawingCategory.map((category, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          style={{
                            marginRight: "5px",
                            width: "50px",
                            textAlign: "center",
                            textTransform: "uppercase",
                            display: "block", // Mimicking form-control display
                            padding: "0.375rem 0.75rem", // Padding similar to Bootstrap
                            fontSize: "1rem", // Default font size for form-control
                            lineHeight: "2", // Line height similar to form-control
                            color: "#495057", // Text color
                            backgroundColor: "#fff", // White background for label
                            border: "1px solid #ced4da", // Standard gray border
                            borderRadius: "0.25rem", // Border-radius to mimic form-control
                          }}
                        >
                          {index + 1}.
                        </label>
                        <select
                          value={category}
                          onChange={(e) =>
                            handleCategoryChange(index, e.target.value)
                          }
                          style={{
                            marginRight: "5px",
                            width: "80%",
                            display: "block", // Ensures block display like form-control
                            fontSize: "1rem", // Standard font size for form-control
                            lineHeight: "1.5", // Ensures proper text spacing
                            color: "#495057", // Text color
                            backgroundColor: "#fff", // White background for select
                            border: "1px solid #ced4da", // Standard gray border
                            borderRadius: "0.25rem", // Rounded corners
                            padding: "0.375rem 0.75rem", // Padding similar to form-control
                            transition:
                              "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out", // Smooth transition
                            boxShadow: "none", // No shadow by default
                            outline: "none", // No outline on focus
                          }}
                        >
                          <option value="">Select Category</option>
                          {existingCategories &&
                            existingCategories.map((cat) => (
                              <option key={cat._id} value={cat._id}>
                                {cat.role}
                              </option>
                            ))}
                        </select>
                        <Icon.DashCircleFill
                          size={20}
                          style={{
                            cursor: "pointer",
                            color: "red",
                            marginLeft: "5px",
                          }}
                          onClick={() => handleRemoveField(index)}
                        />
                      </div>
                    ))}
                  {formData.DrawingCategory.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        style={{
                          marginLeft: "50px",
                          marginTop: "8px",
                          padding: "5px",
                          border: "1px solid var(--primary-color)",
                          backgroundColor: "var(--primary-color)",
                          color: "white",
                          width: "100px",
                        }}
                        onClick={handleSubmit}
                      >
                        SUBMIT
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
          }
          />
      </>
    );
  };

export default WorkFlowSiteLevel