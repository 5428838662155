import React, { useState, useEffect } from 'react';
import styles from '../PNMreports/LogReportStatus/LogReportStatus.module.css';
import TimePeriodOption from '../PNMreports/LogReportStatus/TimePeriodOption';
import { WebApimanager } from '../../../WebApiManager';
const InspectionReports = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [dateRange, setDateRange] = useState({ from: '', to: '' });
  const [filteredAssetCodes, setFilteredAssetCodes] = useState([]);
  const [tableData,setTableData] = useState([]);
  const [assetCode, setAssetCodeData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    assetCode:'',
    equipmentType: '',
    fromDate: '',
    toDate: '',
    month: '',
    year: '',
    fromYear: '',
    toYear: '',
    
  });
  
  let webApi = new WebApimanager();
  const [errors, setErrors] = useState({});
  const timePeriodOptions = [
    { label: 'By Date', isSelected: selectedOption === 'byDate' },
    { label: 'By Month', isSelected: selectedOption === 'byMonth' },
    { label: 'Last 6 Months', isSelected: selectedOption === 'last6Months' },
    { label: 'Year', isSelected: selectedOption === 'byYear' },
  ];

  useEffect(() => {
    if (selectedOption === 'last6Months') {
      const today = new Date();
      const sixMonthsAgo = new Date(today);
      sixMonthsAgo.setMonth(today.getMonth() - 6);

      const formatDate = (date) => {
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
      };

      setDateRange({
        from: formatDate(sixMonthsAgo),
        to: formatDate(today),
      });
    }
  }, [selectedOption]);
const handleChange = (e) => {
    const { name, value } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "equipmentType") {
      const filteredCodes = assetCode.filter((code) => code.type === value);
      setFilteredAssetCodes(filteredCodes); 
      if (formData.assetCode) {
        setFormData((prevData) => ({
          ...prevData,
          assetCode: "", 
        }));
      }
    }
  };
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ['assetcode', 'equipmentType'];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field} is required`;
      }
    });
    if(!selectedOption){
      newErrors.selectTimePeriod="Select a time Period"
    }

    if (selectedOption === 'byDate') {
      if (!formData.fromDate || !formData.toDate) {
        newErrors.fromDate = 'From date is required';
        newErrors.toDate = 'To date is required';
      } else {
        const from = new Date(formData.fromDate);
        const to = new Date(formData.toDate);
        if (from > to) {
          newErrors.fromDate = 'From date must be earlier than to date';
          newErrors.toDate = 'To date must be later than from date';
        }
      }
    } else if (selectedOption === 'byMonth' && (!formData.month || !formData.year)) {
      newErrors.month = 'Month is required';
      newErrors.year = 'Year is required';
    } else if (selectedOption === 'byYear') {
      const numRegex = /^\d{4}$/;
      if (!formData.fromYear) newErrors.fromYear = 'From year is required';
      else if (!numRegex.test(formData.fromYear)) newErrors.fromYear = 'Enter a valid year';
      if (!formData.toYear) newErrors.toYear = 'To year is required';
      else if (!numRegex.test(formData.toYear)) newErrors.toYear = 'Enter a valid year';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) {
      return;
    }

    const reportType = formData.reportType;
    const equipmentType = formData.equipmentType;
    let apiUrl = `api/pnms/report?reportType=${reportType}&equipmentType=${equipmentType}&selectTimePeriod=${selectedOption}`;

    if (selectedOption === 'byDate') {
      const fromDate = formData.fromDate;
      const toDate = formData.toDate;
      apiUrl += `&fromDate=${fromDate}&toDate=${toDate}`;
    }
    if (selectedOption === 'last6Months') {
      apiUrl += `&fromDate=${dateRange.from}&toDate=${dateRange.to}`;
    }
    if (selectedOption === 'byMonth') {
      apiUrl = `api/pnms/report?year=${formData.year}&month=${formData.month}&equipmentType=${equipmentType}&reportType=${reportType}&selectTimePeriod=${selectedOption}`;
    }
    if (selectedOption === 'byYear') {
      apiUrl = `api/pnms/report?equipmentType=${equipmentType}&reportType=${reportType}&selectTimePeriod=${selectedOption}&fromYear=${formData.fromYear}&toYear=${formData.toYear}`;
    }

    try {
      const response = await webApi.get(apiUrl);
      const data = await response.data;
      setTableData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSelect = (name) => {
    if (name === 'By Date') setSelectedOption('byDate');
    if (name === 'By Month') setSelectedOption('byMonth');
    if (name === 'Last 6 Months') setSelectedOption('last6Months');
    if (name === 'Year') setSelectedOption('byYear');
  };
   useEffect(()=>{
    console.log(tableData,"tableData")
   },[tableData])
   
   const fetchAssetCodes = async () => {
    try {
      const response = await webApi.get("api/pnms/assetCode");
      console.log("API response:", response);
      if (response.data && Array.isArray(response.data.data.AssetCodes)) {
        setAssetCodeData(response.data.data.AssetCodes);
        console.log(response.data.data.AssetCodes);
      } else {
        setErrors("Unexpected data format");
        console.error("Unexpected data format:", response.data);
      }
    } catch (error) {
     
      console.error("Error object:", error);

     
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Asset code and name must be unique"
      ) {
        setErrorMessage("Asset code and name must be unique");
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    }
  };
  useEffect(() => {
    fetchAssetCodes();
  }, []);
  return (
    
    <>
    <section className={styles.container}>
        <form onSubmit={handleSubmit} className={styles.content}>
          <h1 className={styles.title}>Inspection Report</h1>
          <div className={styles.ContentContainer}>

         
            
            <div className={styles.reportTypeContainer}>
            <label htmlFor="equipmentType" className={styles.equipmentTypeLabel}>Equipment Type:</label>
            <div className={styles.inputContainer}>
                <select
                    id="equipmentType"
                    name="equipmentType"
                    className={styles.InputField}
                    value={formData.equipmentType}
                    onChange={handleChange}
                  >
                    <option value="">Select Equipment</option>
                    <option value="Vehicle">Vehicle</option>
                    <option value="Machinery">Machinery</option>
                    <option value="Power Tools">Power Tools</option>
                    <option value="Distribution Board">Distribution Board</option>
                    <option value="Batching Plant">Batching Plant</option>
                  </select>
                  {errors.equipmentType && <p className={styles.error} >{errors.equipmentType}</p>}
            </div>
                  
            </div>
            <div className={styles.reportTypeContainer}>
            <label htmlFor="assetCode" className={styles.equipmentTypeLabel}>AssetCode:</label>
            <div className={styles.inputContainer}>
                <select
                    id="assetCode"
                    name="assetCode"
                    className={styles.InputField}
                    value={formData.assetCode}
                    onChange={handleChange}
                  >
                    <option value="">Select AssetCode</option>
                    {filteredAssetCodes.map((code) => (
        <option key={code._id} value={code._id}>
          {code.assetCode} 
        </option>
      ))}
                  </select>
                  {errors.assetCode && <p className={styles.error} >{errors.assetCode}</p>}
            </div>
                  
            </div>
          </div>

          <h2 className={styles.timePeriodTitle}>Select Time Period</h2>
          <div className={styles.timePeriodOptions}>
            {timePeriodOptions.map((option) => (
              <TimePeriodOption
                key={option.label}
                label={option.label}
                isSelected={option.isSelected}
                onSelect={() => handleSelect(option.label)}
              />
            ))}
          </div>
          {selectedOption === 'byDate' && (
            <div className={styles.DatesContainer} style={{marginTop:"40px"}}>

            <div className={styles.reportTypeContainer}>
              <label htmlFor="fromDate" className={styles.label}>From:</label>
              <div className={styles.inputContainer}>
                <input type="date"
                  id="fromDate"
                  className={styles.InputField}
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleChange}/>
                  {errors.fromDate && <p className={styles.error}>{errors.fromDate}</p>}
              </div>
              
              </div>
              <div className={styles.reportTypeContainer}>
                <label htmlFor="toDate" className={styles.equipmentTypeLabel}>To:</label>
                <div className={styles.inputContainer}>
                  <input type="date"
                    id="toDate"
                    className={styles.InputField}
                    name="toDate"
                    value={formData.toDate}
                    onChange={handleChange} />
                    {errors.toDate && <p className={styles.error}>{errors.toDate}</p>}
                </div>
                
              </div>
            </div>
          )}
          {selectedOption === 'byMonth' && (
            <div className={styles.DatesContainer} style={{marginTop:"40px"}}>

            <div className={styles.reportTypeContainer}>
              <label htmlFor="month" className={styles.label}>Month:</label>
              <div className={styles.inputContainer}>

                <select id="month"
                  className={styles.InputField}
                  name="month"
                  value={formData.month}
                  onChange={handleChange}>
                <option value="">Select Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              {errors.month && <p className={styles.error}>{errors.month}</p>}
              </div>
              </div>
              <div className={styles.reportTypeContainer} >
                <label htmlFor="year" className={styles.equipmentTypeLabel}>Year:</label>
                <div className={styles.inputContainer}>
                  <input
                    type="number"
                    id="year"
                    className={styles.InputField}
                    name="year"
                    placeholder="YYYY"
                    
                    value={formData.year}
                    onChange={handleChange}
                  />
                  {errors.year && <p className={styles.error}>{errors.year}</p>}
                </div>
                
              </div>
            </div>
          )}
          {selectedOption === 'last6Months' && (
            <div className={styles.reportTypeContainer} style={{marginTop:"40px"}}>
              <p className={styles.dateRangeText}>{dateRange.from} to {dateRange.to}</p>
            </div>
          )}
          {selectedOption === 'byYear' && (
            // <div className={styles.reportTypeContainer}>
            //   <label htmlFor="selectYear" className={styles.label}>Select Year:</label>
              
            // </div>
            <div className={styles.DatesContainer} style={{marginTop:"40px"}}>

            <div className={styles.reportTypeContainer}>
              <label htmlFor="fromYear" className={styles.label}>From:</label>
              <div className={styles.inputContainer}>
                <input type="number"
                    placeholder="XXXX"
                  id="fromYear"
                  className={styles.InputField}
                  name="fromYear"
                  value={formData.fromYear}
                  onChange={handleChange}/>
                  {errors.fromYear && <p className={styles.error}>{errors.fromYear}</p>}
              </div>
              
              </div>
              <div className={styles.reportTypeContainer}>
                <label htmlFor="toYear" className={styles.equipmentTypeLabel}>To:</label>
                <div className={styles.inputContainer}>
                  <input type="number"
                    placeholder="XXXX"
                    id="toYear"
                    className={styles.InputField}
                    name="toYear"
                    value={formData.toYear}
                    onChange={handleChange} />
                    {errors.toYear && <p className={styles.error}>{errors.toYear}</p>}
                </div>
                
              </div>
            </div>
          )}
         

          <div className={styles.actionButtons}>
            <button type="submit" className={styles.actionButton}>GO</button>
            <button type="reset" className={styles.actionButton}>RESET</button>
          </div>
          {errors.selectTimePeriod ? <p style={{textAlign:"center",fontSize:"14px",color:"red",marginTop:"20px"}}>{errors.selectTimePeriod}</p>:errors.selectedViewOption?<p style={{textAlign:"center",fontSize:"14px",color:"red",marginTop:"20px"}}>{errors.selectedViewOption}</p>:""}
        </form>
        
    </section>
 
    <table className={styles.TableContainer}>
      <thead style={{width:"100%"}}>
        <tr style={{ backgroundColor: '#0000FF', color: '#ffffff', textAlign: 'center',width:"100%"}}>
            <th style={{ padding: '12px 15px' ,width:"25%"}}>Transition Id</th>
            <th style={{ padding: '12px 15px' ,width:"15%"}}>Form No</th>
            <th style={{ padding: '12px 15px' ,width:"15%"}}>Date</th>
            <th style={{ padding: '12px 15px' ,width:"15%"}}>Asset Code</th>
            <th style={{ padding: '12px 15px' ,width:"15%"}}>Subcode</th>
            <th style={{ padding: '12px 15px' ,width:"15%"}}>Inspection Person Name</th>
            <th style={{ padding: '12px 15px' ,width:"15%"}}>Actions</th>
        </tr>
      </thead>
      <tbody className={styles.TableBody}>
      {/* <Dialog
        open={trashopen}
        onClose={handleTrashClose}
        maxWidth="xs"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 180px", textAlign: "center", color: "white" }}
          >
            Delete
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleTrashClose}
          />
        </div>
        <DialogContent>
          <div>

          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
        <p className={Styles.question}>Are you sure want to Delete ? </p>
        <div className={Styles.buttons}>
        <button className={Styles.noButton}   onClick={handleTrashClose}>No</button>
        <button className={Styles.submitButton}   onClick={() => handleDelete(formId)}>Yes</button>
        </div>
        </div>
        </DialogContent>
      </Dialog> */}
   
            <tr  style={{ backgroundColor: "#FDF8F4", color: '#ffffff', textAlign: 'center',width:"100%"}}>
          
            <td style={{ padding: '12px 15px' ,width:"25%", textAlign: 'center'}}></td>
            <td style={{ padding: '12px 15px' ,width:"15%", textAlign: 'center'}}></td>
            <td style={{ padding: '12px 15px' ,width:"15%", textAlign: 'center'}}></td>
            <td style={{ padding: '12px 15px' ,width:"15%", textAlign: 'center'}}></td>
            <td style={{ padding: '12px 15px' ,width:"15%", textAlign: 'center'}}></td>
            <td style={{ padding: '12px 15px' ,width:"15%", textAlign: 'center'}}></td>
            <td style={{ padding: '12px 15px' ,width:"15%", textAlign: 'center'}}></td>
            <td style={{ display: 'flex', padding: '8px', alignItems: 'center', justifyContent: 'center', borderRight: '1px solid #dddddd' }}>
              {/* <Icon.Pen size={20} style={{ marginRight: '10px', cursor: 'pointer' }}  onClick={()=>handleEditOpen(tabData)}/> */}
                {/* <Icon.EyeFill size={20} style={{ marginRight: '10px', cursor: 'pointer' }}  */}
                {/* // onClick={()=>handleViewOpen()}
                /> */}
                {/* <Icon.Trash size={20} style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={()=>handleTrash(tabData._id,tabData)}/> */}
            </td>
        </tr> 
   
       
      </tbody>
    </table>
    </>
  );
};

export default InspectionReports;
