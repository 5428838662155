import React from "react";

const TableWrapper = ({children,heightStyle=false}) => {
  // console.log(props,"props")
  return (
    <div
      style={{
        boxShadow: "0 0 10px #F3F3F3",
        borderRadius: "12px",
        backgroundColor: "white",
        padding: "15px",
        // paddingTop: "20px 15px",
        fontFamily: "Poppins",
        height:heightStyle?"max-content":"76vh",
      }}
    >
      {children}
    </div>
  );
};

export default TableWrapper;
