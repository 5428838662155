import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { Button } from "react-bootstrap";
import {
  userInfo,
  siteId,
  designTab,
} from "../../../atoms";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import {Tabs, Tab} from '../../Tabs/Tabv1'
import Filter from "../../../Images/Filter.svg";
import Pen from "../../../Images/Pen.svg";
import Eye from "../../../Images/Eye.svg";
import DescriptionFile from "../../../Images/DescriptionFile.svg";
import Share from "../../../Images/Share.svg";
import Trash from "../../../Images/Trash.svg";
import SelectSite from "../../../Utilities/SelectSite";
import AddActivity from "./AddActivity";
import Create from "./Create";
import ViewDialog from "./ViewDialog";
import AddDescriptions from "./AddDescriptions";
import styles from "./Design.module.css";
import CheckListTable from "../ChecklistTable";
import TableWrapper from "../../../Utilities/TableWrapper";
const DesignForm = () => {
  let webApi = new WebApimanager();
  const[userinfo,setUserInfo] =useRecoilState(userInfo);
  const loggedInUserDept = userinfo?.department;
  const [site, setSite] = useRecoilState(siteId);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [open, setOpen] = useState(false);
  const [viewopen, setviewOpen] = useState(false);
  const [descriptionopen, setDescriptionOpen] = useState(false);
  const [descriptionData, setDescriptionData] = useState(null);
  const [shareopen, setShareOpen] = useState(false);
  const [trashopen, setTrashOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [formId,setFormId] = useState(null);
  const [checklistData,setCheckListData] =useState({});
  const [shareData, setShareData] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [errorDepartment, setErrorDepartment] = useState(""); 
  const [errorUser, setErrorUser] = useState("");
  const [error,setError] = useState("");
  const [currentView, setCurrentView] = useRecoilState(designTab);
  const [showEditForm,setShowEditForm] = useState(false)
  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
    setSearchValue("");
    setSearchActive(false);
  };
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const handleClickOpen = () => {
    setFormData(null);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setShowEditForm(false)
  };

  const handleEdit = (item) => {
    setFormData(item);
    // setOpen(true);
    setShowEditForm(true)
  };

  const handleView = (item) => {
    setViewData(item);
    setviewOpen(true);
  };

  const handleViewClose = () => {
    setviewOpen(false);
    setViewData("");
  };
 const handledescriptionFile =(item) =>{
  setDescriptionOpen(true);
  setDescriptionData(item);
 };
 const handledescriptionFileClose = () => {
  setDescriptionOpen(false);
  setDescriptionData("");
};
const handleShare =(item) => {
  setShareOpen(true);
  setShareData(item);
}
const handleShareClose = () => {
  setShareOpen(false);
  setSelectedUser("");
  setSelectedDepartment("");
};
const handleTrash =(id) => {
  setTrashOpen(true);
  setFormId(id);
}
const handleTrashClose = () => {
  setTrashOpen(false);
  setFormId("");
};

const handleDelete = async (Id) => {
  setApiProcessing({ loader: true, message: "Deleting..." });
  const apiEndpoint = `api/checklistDesign/checklistDesigns/${Id}`;

  try {
    const response = await webApi.delete(apiEndpoint);
    fetchData(); 
    handleTrashClose(); 
  } catch (error) {
    console.error(`Error deleting:`, error);
  } finally {
    setApiProcessing({ loader: false, message: "" }); 
  }
};


const fetchData = async () => {
  try {
    let response = await webApi.get(
      `api/checklistDesign/getAllUsingCompanyId`
    );
    setCheckListData(response.data.data);
    console.log(checklistData,"responsedata of designform")
  } catch (error) {
    console.error("Error fetching checklist data", error);
  }
};

useEffect(() => {

    fetchData();

}, []);

useEffect(() => {
  const fetchUsers = async () => {
    if (selectedDepartment) {
      setError(null); 
      setSelectedUser("");
      try {
        const response = await webApi.get(`api/checklistDesign/users?department=${selectedDepartment}`);
        if (response.data.status === "success") {
          setUsers(response.data.data.users); 
        } else {
          setError('Failed to fetch users'); 
        }
      } catch (err) {
        setError('An error occurred while fetching users');
        console.error(err); 
      } 
    }
  };

  fetchUsers();
}, [selectedDepartment]); 

const handleShareNowClick = async (data) => {
  let hasError = false;
  if (!selectedDepartment) {
    setErrorDepartment("Please select a department.");
    hasError = true;
  }
  if (!selectedUser) {
    setErrorUser("Please select a user.");
    hasError = true;
  }
  if (hasError) return;
  try {

    if (loggedInUserDept === selectedDepartment) {
      const response = await webApi.put(`api/checklistDesign/updateSharedTo/${data._id}`, {
        userId:selectedUser
      });
      console.log("Successfully shared:", response);
      handleShareClose();
    } else {
      handleEdit(data);
    }
  } catch (error) {
    console.error("Error during sharing or editing:", error);
  }
};


const [selectedStatus, setSelectedStatus] = useState("All"); 
const [showDropdown, setShowDropdown] = useState(false);

const handleStatusChange = (status) => {
  setSelectedStatus(status);
  setShowDropdown(false);
};
const filteredAndSortedData = Array.isArray(checklistData)
  ? checklistData
  .filter(item => 
            item.revisions[0]?.approvalStatus === "Redo" || 
            item.revisions[0]?.approvalStatus === "Ongoing"
          ) 
      .sort((a, b) => {
        const aMatches = selectedStatus === "All" || a.revisions[0]?.approvalStatus === selectedStatus;
        const bMatches = selectedStatus === "All" || b.revisions[0]?.approvalStatus === selectedStatus;
        if (aMatches && !bMatches) return -1; 
        if (!aMatches && bMatches) return 1;  
        return a.activity?.activity.localeCompare(b.activity?.activity);
      })
  : [];

 
  if (site === "") {
    return <SelectSite />;
  }

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <Tabs
                  value={currentView}
                  onChange={handleTabViewChange}
                >
                  <Tab label="Design Form" value="designform" />
                  <Tab label="Add Activity" value="addactivity" />
                </Tabs>
              </div>
              <div>
                {currentView === "designform" && (
                  <>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          position: "relative",
                          width: "250px",
                          paddingLeft: "5px",
                          height: "50px",
                        }}
                      >
                        <input
                          placeholder="Search Forms"
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                          style={{
                            paddingLeft: "20px",
                            border: "1px solid var(--primary-color)",
                            width: "250px",
                            height: "40px",
                          }}
                        />
                        <Icon.Search
                          style={{
                            position: "absolute",
                            right: "15px",
                            top: "40%",
                            transform: "translateY(-50%)",
                            color: "#ACACAC",
                          }}
                        />
                      </div>

                      <img
                        src={Filter}
                        style={{
                          width: "40px",
                          height: "40px",
                          marginLeft: "10px",
                          padding: "5px",
                          border: "1px solid var(--primary-color)",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowDropdown(!showDropdown)}
                      />
                       <div style={{ position: "relative" }}>
    {showDropdown && (
      <div
        style={{
          position: "absolute",
          top: "40px",
          right: "-45px",
          borderRadius: "6px",
          padding: "5px",
          zIndex: 1000,
          width: "150px",
          background: "white",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        {["All","Ongoing", "Redo"].map((status) => (
          <button
            key={status}
            onClick={() => handleStatusChange(status)}
            style={{
              display: "block",
              width: "100%",
              padding: "10px",
              border: "none",
              background: "white",
              textAlign: "left",
              color: selectedStatus === status ? "var(--primary-color)" : "var(--bs-dropdown-link-color)",
            }}
            className="DrawingFilterStatus"
          >
            {status}
          </button>
        ))}
      </div>
    )}
  </div>
                      <Button
                        style={{
                          height: "40px",
                          marginLeft: "5px",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "var(--primary-color)",
                          border: "1px solid var(--primary-color)",
                          borderRadius: "0px",
                        }}
                        onClick={handleClickOpen}
                      >
                        <Icon.PlusCircle
                          size={20}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        Add New
                      </Button>
                    </div>
                    <div>
                    </div>
                 
                </>
              )}

</div>
            </div>

            {currentView === "designform" && (
                  <CheckListTable
                  CheckListTableHead={
                  <tr className="HeaderTableRow">
                    <th className="CheckListTableTableHead" style={{width:"4%"}}>S.No</th>
                    <th className="CheckListTableTableHead" style={{width:"14%"}}>Activity</th>
                    <th className="CheckListTableTableHead" style={{width:"14%"}}>Department</th>
                    <th className="CheckListTableTableHead" style={{width:"14%"}}>Checklist Type</th>
                    <th className="CheckListTableTableHead" style={{width:"14%"}}>Approval Status</th>
                    <th className="CheckListTableTableHead" style={{width:"14%"}}>Form No</th>
                    <th className="CheckListTableTableHead" style={{width:"14%"}}>Revisions</th>
                    <th className="CheckListTableTableHead" style={{width:"14%"}}> Action </th>
                  </tr>
                }
                CheckListTableBody={
                  filteredAndSortedData.length > 0 ? (
                    filteredAndSortedData.filter((item)=>item.activity?.activity.toLowerCase().includes(searchInput.toLowerCase()) || 
                    item.checklistType.toLowerCase().includes(searchInput.toLowerCase()) || 
                    item.department.includes(searchInput.toLowerCase()) || 
                    item.revisions[0]?.approvalStatus.toLowerCase().includes(searchInput.toLowerCase()) || 
                    item.revisions[0]?.revision.toLowerCase().includes(searchInput.toLowerCase()) ||
                    item.formNo.toLowerCase().includes(searchInput.toLowerCase())).map((item, index) => (
                      <tr className="ValueTableRow" key={item._id}
                      style={{backgroundColor: index % 2 === 0 ? 'var(--table-oddrow-color)' : 'var(--table-evenrow-color)',}}>
                        <td className="CheckListTableTableData" style={{ width: "4%" }}>{index + 1}</td>
                        <td className="CheckListTableTableData" style={{ width: "14%" }}>{item.activity?.activity}</td>
                        <td className="CheckListTableTableData" style={{ width: "14%" }}>
                          {item.department?.join(", ")}
                        </td>
                        <td className="CheckListTableTableData" style={{ width: "14%" }}>{item.checklistType}</td>
                        <td className="CheckListTableTableData" style={{ width: "14%" }}>
                          {item.revisions[0]?.approvalStatus || "N/A"}
                        </td>
                        <td className="CheckListTableTableData" style={{ width: "14%" }}>{item.formNo}</td>
                        <td className="CheckListTableTableData" style={{ width: "14%" }}>
                          {item.revisions.map((rev) => rev.revision).join(", ")}
                        </td>
                        <td className="CheckListTableTableData"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "14%",
                          }}>
                          <img
                            src={Pen}
                            loading="eager"
                            style={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={() => handleEdit(item)}
                          />
                          <img
                            src={Eye}
                            loading="eager"
                            style={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={() => handleView(item)}
                          />
                          <img
                            src={DescriptionFile}
                            loading="eager"
                            style={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={() => handledescriptionFile(item)}
                          />
                          <img
                            src={Share}
                            loading="eager"
                            style={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={() => handleShare(item)}
                          />
                          <img
                            src={Trash}
                            loading="eager"
                            style={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={() => handleTrash(item._id)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="ValueTableRow">
                    <td ccolSpan={8} style={{ textAlign: "center" }}>
                  Loading .....
                </td>
                    </tr>
                  )
                }
              
              />
            )}
            {currentView === "addactivity" && <AddActivity />}
          </>
        }
      />

      <Create
        handleDialogClose={handleDialogClose}
        open={open}
        showEditForm={showEditForm}
        existingData={formData}
      />

      <ViewDialog
        handleDialogClose={handleViewClose}
        open={viewopen}
        formData={viewData}
      />

      <AddDescriptions
        handleDialogClose={handledescriptionFileClose}
        open={descriptionopen}
        formData={descriptionData}
      />

      <Dialog
        open={trashopen}
        onClose={handleTrashClose}
        maxWidth="xs"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 180px", textAlign: "center", color: "white" }}
          >
            Delete
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleTrashClose}
          />
        </div>
        <DialogContent>
          <div>

          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
        <p className={styles.question}>Are you sure want to Delete ? </p>
        <div className={styles.buttons}>
        <button className={styles.noButton}   onClick={handleTrashClose}>No</button>
        <button className={styles.submitButton}   onClick={() => handleDelete(formId)}>Yes</button>
        </div>
        </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={shareopen}
        onClose={handleShareClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" ,height:"300px"} }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            height:'50px',
            width:"100%"
          }}
        >
          <p
            style={{ width:"100%", textAlign: "center", color: "white",fontSize:"20px" }}
          >
            Share
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleShareClose}
          />
        </div>
        <DialogContent>
          <div style={{display:'flex',flexDirection:'row',marginRight:'10px',marginLeft:'10px',justifyContent:'space-between',width:"92%"}}>
          <div style={{display:'flex',flexDirection:'column',width:"45%",marginInline:"auto"}} >
            <label>Department</label>
            <select
              className={styles.sharedropDown}
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
            >
                      <option value="">Select Department</option>
                      <option value="EHS">EHS</option>
                      <option value="PNM">PNM</option>
                      <option value="QC/QA">QC/QA</option>
                      <option value="Admin">Admin</option>
                      <option value="MEP">MEP</option>
                      <option value="CRMTECH">CRMTECH</option>
                    </select>
                    {errorDepartment && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errorDepartment}
              </span>
            )}
                  </div>
                  <div style={{display:'flex',flexDirection:'column',width:"45%"}}>
                  <label>User</label>
                  <select
                     className={styles.sharedropDown}
                     value={selectedUser} 
                     onChange={(e) => setSelectedUser(e.target.value)}
                    >
                        <option value="">Select User</option> 
                      {users && Array.isArray(users) ? (
            users.map((user) => (
              <option key={user._id} value={user._id}>
            {user.firstName} {user.lastName} - {user.role}
              </option>
            ))
          ) : (
            <option disabled>No users available</option>
          )}
                    </select>
                    {errorUser && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errorUser}
              </span>
            )}
                  </div>
            </div>
        <div style={{display:'flex',justifyContent:'center',marginTop:'60px',marginBottom:'20px'}}>
        <button className={styles.shareButton}
          onClick={() => handleShareNowClick(shareData)}>Share Now</button>
        </div>
    
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DesignForm;
 //   Array.isArray(filteredChecklistData) && filteredChecklistData.length > 0 ? (
                //     filteredChecklistData.map((item, index) => (
                      
                //       <tr className="ValueTableRow" key={item._id}>
                //         <td className="CheckListTableTableData" style={{ width: "4%" }}>{index + 1}</td>
                //         <td className="CheckListTableTableData" style={{ width: "14%" }}>{item.activity?.activity}</td>
                //         <td className="CheckListTableTableData" style={{ width: "14%" }}>
                //           {item.department?.join(", ")}
                //         </td>
                //         <td className="CheckListTableTableData" style={{ width: "14%" }}>{item.checklistType}</td>
                //         <td className="CheckListTableTableData" style={{ width: "14%" }}>
                //           {item.revisions[0]?.approvalStatus || "N/A"}
                //         </td>
                //         <td className="CheckListTableTableData" style={{ width: "14%" }}>{item.formNo}</td>
                //         <td className="CheckListTableTableData" style={{ width: "14%" }}>
                //           {item.revisions.map((rev) => rev.revision).join(", ")}
                //         </td>
                //         <td className="CheckListTableTableData" style={{
                //           display: "flex",
                //           justifyContent: "center",
                //           alignItems: "center",
                //           width: "14%",
                //         }}>
                //           <img
                //             src={Pen}
                //             loading="eager"
                //             style={{ marginRight: "10px", cursor: "pointer" }}
                //             onClick={() => handleEdit(item)}
                //           />
                //           <img
                //             src={Eye}
                //             loading="eager"
                //             style={{ marginRight: "10px", cursor: "pointer" }}
                //             onClick={() => handleView(item)}
                //           />
                //           <img
                //             src={DescriptionFile}
                //             loading="eager"
                //             style={{ marginRight: "10px", cursor: "pointer" }}
                //             onClick={() => handledescriptionFile(item)}
                //           />
                //           <img
                //             src={Share}
                //             loading="eager"
                //             style={{ marginRight: "10px", cursor: "pointer" }}
                //             onClick={() => handleShare(item)}
                //           />
                //           <img
                //             src={Trash}
                //             loading="eager"
                //             style={{ marginRight: "10px", cursor: "pointer" }}
                //             onClick={() => handleTrash(item._id)}
                //           />
                //         </td>
                //       </tr>
                //     ))
                //   ) : (
                //     <tr className="ValueTableRow">
                //       <td colSpan={8} style={{ textAlign: "center" }}>
                //         No data available
                //       </td>
                //     </tr>
                //   )
                // }
                
                // CheckListTableBody={