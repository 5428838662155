import React, { useState, useEffect } from 'react';
import styles from '../PNMreports/LogReportStatus/LogReportStatus.module.css';
import TimePeriodOption from '../PNMreports/LogReportStatus/TimePeriodOption';
import DownloadComponent from './DownloadComponent/DownloadComponent';
import XlsIcon from '../../icons/xls-icon';
import PdfIcon from '../../icons/pdf-icon';
import CsvIcon from '../../icons/csv-icon';
import { WebApimanager } from '../../../WebApiManager';

const PerformanceSummaryReport = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [dateRange, setDateRange] = useState({ from: '', to: '' });
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedViewOption, setSelectedViewOption] = useState('');
  const [tableData,setTableData] = useState([])
  const [selectedDownloadOption, setSelectedDownloadOption] = useState('');
  

  const ViewOption = [
    { label: 'Download', isSelected: selectedViewOption === 'Download' },
    { label: 'View', isSelected: selectedViewOption === 'View' },
  ];

  const [formData, setFormData] = useState({
    reportType: '',
    equipmentType: '',
    fromDate: '',
    toDate: '',
    month: '',
    year: '',
    fromYear: '',
    toYear: '',
    
  });
  
  let webApi = new WebApimanager();
  const [errors, setErrors] = useState({});
  const timePeriodOptions = [
    { label: 'By Date', isSelected: selectedOption === 'byDate' },
    { label: 'By Month', isSelected: selectedOption === 'byMonth' },
    { label: 'Last 6 Months', isSelected: selectedOption === 'last6Months' },
    { label: 'Year', isSelected: selectedOption === 'byYear' },
  ];

  const handleReset = () =>{
    setFormData({
      reportType: '',
    equipmentType: '',
    fromDate: '',
    toDate: '',
    month: '',
    year: '',
    fromYear: '',
    toYear: '',
    })
    setSelectedViewOption('')
    setSelectedOption("")
  }
  useEffect(() => {
    if (selectedOption === 'last6Months') {
      const today = new Date();
      const sixMonthsAgo = new Date(today);
      sixMonthsAgo.setMonth(today.getMonth() - 6);

      const formatDate = (date) => {
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
      };

      setDateRange({
        from: formatDate(sixMonthsAgo),
        to: formatDate(today),
      });
    }
  }, [selectedOption]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDownloadOptionChange = (value) => {
    setSelectedDownloadOption(value);
  };

  // Function to validate all form fields
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ['reportType', 'equipmentType'];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field} is required`;
      }
    });
    if(!selectedOption){
      newErrors.selectTimePeriod="Select a time Period"
    }
    if(!selectedViewOption){
      newErrors.selectedViewOption="Select a View option"
    }

    if (selectedOption === 'byDate') {
      if (!formData.fromDate || !formData.toDate) {
        newErrors.fromDate = 'From date is required';
        newErrors.toDate = 'To date is required';
      } else {
        const from = new Date(formData.fromDate);
        const to = new Date(formData.toDate);
        if (from > to) {
          newErrors.fromDate = 'From date must be earlier than to date';
          newErrors.toDate = 'To date must be later than from date';
        }
      }
    } else if (selectedOption === 'byMonth' && (!formData.month || !formData.year)) {
      newErrors.month = 'Month is required';
      newErrors.year = 'Year is required';
    } else if (selectedOption === 'byYear') {
      const numRegex = /^\d{4}$/;
      if (!formData.fromYear) newErrors.fromYear = 'From year is required';
      else if (!numRegex.test(formData.fromYear)) newErrors.fromYear = 'Enter a valid year';
      if (!formData.toYear) newErrors.toYear = 'To year is required';
      else if (!numRegex.test(formData.toYear)) newErrors.toYear = 'Enter a valid year';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) {
      return;
    }

    const reportType = formData.reportType;
    const equipmentType = formData.equipmentType;
    let apiUrl = `api/pnms/report?reportType=${reportType}&equipmentType=${equipmentType}&selectTimePeriod=${selectedOption}`;

    if (selectedOption === 'byDate') {
      const fromDate = formData.fromDate;
      const toDate = formData.toDate;
      apiUrl += `&fromDate=${fromDate}&toDate=${toDate}`;
    }
    if (selectedOption === 'last6Months') {
      apiUrl += `&fromDate=${dateRange.from}&toDate=${dateRange.to}`;
    }
    if (selectedOption === 'byMonth') {
      apiUrl = `api/pnms/report?year=${formData.year}&month=${formData.month}&equipmentType=${equipmentType}&reportType=${reportType}&selectTimePeriod=${selectedOption}`;
    }
    if (selectedOption === 'byYear') {
      apiUrl = `api/pnms/report?equipmentType=${equipmentType}&reportType=${reportType}&selectTimePeriod=${selectedOption}&fromYear=${formData.fromYear}&toYear=${formData.toYear}`;
    }

    try {
      const response = await webApi.get(apiUrl);
      const data = await response.data;
      setTableData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSelect = (name) => {
    if (name === 'By Date') setSelectedOption('byDate');
    if (name === 'By Month') setSelectedOption('byMonth');
    if (name === 'Last 6 Months') setSelectedOption('last6Months');
    if (name === 'Year') setSelectedOption('byYear');
  };
   useEffect(()=>{
    console.log(tableData,"tableData")
   },[tableData])
   

  return (
    
    <>
    <section className={styles.container}>
        <form onSubmit={handleSubmit} className={styles.content}>
          <h1 className={styles.title}>Performance Summary Report</h1>
          <div className={styles.ContentContainer}>

          <div className={styles.reportTypeContainer}>
            
              <label htmlFor="reportType" className={styles.label} style={{marginTop:"3px"}}>Report Type:</label>
              <div className={styles.inputContainer}>
                <select
                  id="reportType"
                  className={styles.InputField}
                  name="reportType"
                  value={formData.reportType}
                  onChange={handleChange}
                >
                  <option value="">Select Report Type</option>
                  <option value="dailyLogReport">Daily Log Report</option>
                  <option value="breakDownReport">Break Down Report</option>
                  <option value="miscellaneous">Miscellaneous</option>
                </select>
                {errors.reportType && <p className={styles.error} >{errors.reportType}</p>}
              </div>
              
          </div>
            
            <div className={styles.reportTypeContainer}>
            <label htmlFor="equipmentType" className={styles.equipmentTypeLabel} style={{marginTop:"3px"}}>Equipment Type:</label>
            <div className={styles.inputContainer}>
                <select
                    id="equipmentType"
                    name="equipmentType"
                    className={styles.InputField}
                    value={formData.equipmentType}
                    onChange={handleChange}
                  >
                    <option value="">Select Equipment</option>
                    <option value="Vehicle">Vehicle</option>
                    <option value="Machinery">Machinery</option>
                    <option value="Power Tools">Power Tools</option>
                    <option value="Distribution Board">Distribution Board</option>
                    <option value="Batching Plant">Batching Plant</option>
                  </select>
                  {errors.equipmentType && <p className={styles.error} >{errors.equipmentType}</p>}
            </div>
                  
            </div>
          </div>

          <h2 className={styles.timePeriodTitle}>Select Time Period</h2>
          <div className={styles.timePeriodOptions}>
            {timePeriodOptions.map((option) => (
              <TimePeriodOption
                key={option.label}
                label={option.label}
                isSelected={option.isSelected}
                onSelect={() => handleSelect(option.label)}
              />
            ))}
          </div>
          {selectedOption === 'byDate' && (
            <div className={styles.DatesContainer} style={{marginTop:"40px"}}>

            <div className={styles.reportTypeContainer}>
              <label htmlFor="fromDate" className={styles.label} style={{marginTop:"3px"}}>From:</label>
              <div className={styles.inputContainer}>
                <input type="date"
                  id="fromDate"
                  className={styles.InputField}
                  name="fromDate"
                  value={formData.fromDate}
                  onChange={handleChange}/>
                  {errors.fromDate && <p className={styles.error}>{errors.fromDate}</p>}
              </div>
              
              </div>
              <div className={styles.reportTypeContainer}>
                <label htmlFor="toDate" className={styles.equipmentTypeLabel} style={{marginTop:"3px"}}>To:</label>
                <div className={styles.inputContainer}>
                  <input type="date"
                    id="toDate"
                    className={styles.InputField}
                    name="toDate"
                    value={formData.toDate}
                    onChange={handleChange} />
                    {errors.toDate && <p className={styles.error}>{errors.toDate}</p>}
                </div>
                
              </div>
            </div>
          )}
          {selectedOption === 'byMonth' && (
            <div className={styles.DatesContainer} style={{marginTop:"40px"}}>

            <div className={styles.reportTypeContainer}>
              <label htmlFor="month" className={styles.label} style={{marginTop:"3px"}}>Month:</label>
              <div className={styles.inputContainer}>

                <select id="month"
                  className={styles.InputField}
                  name="month"
                  value={formData.month}
                  onChange={handleChange}>
                <option value="">Select Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              {errors.month && <p className={styles.error}>{errors.month}</p>}
              </div>
              </div>
              <div className={styles.reportTypeContainer} >
                <label htmlFor="year" className={styles.equipmentTypeLabel} style={{marginTop:"3px"}}>Year:</label>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id="year"
                    className={styles.InputField}
                    name="year"
                    placeholder="YYYY"
                    
                    value={formData.year}
                    onChange={(e)=>{
                      const value=e.target.value
                      if (!isNaN(value) && value.length<=4 ) {
                        handleChange(e);
                      }
                    }}
                  />
                  {errors.year && <p className={styles.error}>{errors.year}</p>}
                </div>
                
              </div>
            </div>
          )}
          {selectedOption === 'last6Months' && (
            <div className={styles.reportTypeContainer} style={{marginTop:"40px"}}>
              <p className={styles.dateRangeText}>{dateRange.from} to {dateRange.to}</p>
            </div>
          )}
          {selectedOption === 'byYear' && (
            // <div className={styles.reportTypeContainer}>
            //   <label htmlFor="selectYear" className={styles.label}>Select Year:</label>
              
            // </div>
            <div className={styles.DatesContainer} style={{marginTop:"40px"}}>

            <div className={styles.reportTypeContainer}>
              <label htmlFor="fromYear" className={styles.label} style={{marginTop:"3px"}}>From:</label>
              <div className={styles.inputContainer}>
                <input type="text"
                    placeholder="XXXX"
                  id="fromYear"
                  className={styles.InputField}
                  name="fromYear"
                  value={formData.fromYear}
                  onChange={(e)=>{
                    const value=e.target.value
                    if (!isNaN(value) && value.length<=4 ) {
                      handleChange(e);
                    }
                  }}/>
                  {errors.fromYear && <p className={styles.error}>{errors.fromYear}</p>}
              </div>
              
              </div>
              <div className={styles.reportTypeContainer}>
                <label htmlFor="toYear" className={styles.equipmentTypeLabel} style={{marginTop:"3px"}}>To:</label>
                <div className={styles.inputContainer}>
                  <input type="text"
                    placeholder="XXXX"
                    id="toYear"
                    className={styles.InputField}
                    name="toYear"
                    value={formData.toYear}
                    onChange={(e)=>{
                      const value=e.target.value
                      if (!isNaN(value) && value.length<=4 ) {
                        handleChange(e);
                      }
                    }} />
                    {errors.toYear && <p className={styles.error}>{errors.toYear}</p>}
                </div>
                
              </div>
            </div>
          )}
          <h2 className={styles.timePeriodTitle}>Select appropriate options to View / Download the Report</h2>
         <div>
           <div className={styles.timePeriodOptions}>
             {ViewOption.map((option) => (
              <TimePeriodOption
                key={option.label}
                label={option.label}
                isSelected={option.isSelected}
                onSelect={() => setSelectedViewOption(option.label)}
              />
            ))}
          </div>
          {selectedViewOption === 'Download' && <div style={{ marginTop: '32px', display: 'flex',width:"100%",gap:"40px" }}>
          <label style={{display:"flex" ,alignItems:"center",gap:"10px"}}>
                <input
                  type="radio"
                  name="downloadFormat"
                  value="pdf"
                  checked={selectedDownloadOption === 'pdf'}
                  onChange={() => handleDownloadOptionChange('pdf')}
                  onClick={(e)=>e.stopPropagation()}
                />
                <DownloadComponent IconComponent={PdfIcon} downloadText={'Download in PDF format'} />
              </label>
              <label style={{display:"flex" ,alignItems:"center",gap:"10px"}}>
                <input
                  type="radio"
                  name="downloadFormat"
                  value="xls"
                  checked={selectedDownloadOption === 'xls'}
                  onChange={() => handleDownloadOptionChange('xls')}
                />
                <DownloadComponent IconComponent={XlsIcon} downloadText={'Download in MS Excel format'} />
              </label>

              <label style={{display:"flex" ,alignItems:"center",gap:"10px"}}>
                <input
                  type="radio"
                  name="downloadFormat"
                  value="csv"
                  checked={selectedDownloadOption === 'csv'}
                  onChange={() => handleDownloadOptionChange('csv')}
                />
                <DownloadComponent IconComponent={CsvIcon} downloadText={'Download in CSV format'} />
              </label>
          </div>}
         </div>

          <div className={styles.actionButtons}>
            <button type="submit" className={styles.actionButton}>GO</button>
            <button type="reset" className={styles.actionButton} onClick={handleReset}>RESET</button>
          </div>
          {errors.selectTimePeriod ? <p style={{textAlign:"center",fontSize:"14px",color:"red",marginTop:"20px"}}>{errors.selectTimePeriod}</p>:errors.selectedViewOption?<p style={{textAlign:"center",fontSize:"14px",color:"red",marginTop:"20px"}}>{errors.selectedViewOption}</p>:""}
        </form>
        
    </section>
    {(selectedViewOption === "View" && tableData.length) ? <div className={styles.ViewContainer}>
    <div className={styles.TableHeadContent}>
        <div >
            <p style={{fontSize:"14px",fontWeight:"700"}}>Statement showing challans recorded against Vehicle/ Machinery status</p>
        </div>
        <div className={styles.leftHeadTable}>
            <p style={{fontSize:"14px",fontWeight:"700"}} >Project : PSBPLP113 DC Development, Hyderabad</p>
            <p style={{fontSize:"14px",fontWeight:"700"}}>Period : From.... 1-Apr-23 To... 20-Jun-24</p>
        </div>
      </div>
    <table >
      
      
      <thead style={{width:"100%"}}>
        <tr style={{ backgroundColor: '#0000FF', color: '#ffffff', textAlign: 'center',width:"100%"}}>
            <th style={{ padding: '12px 6px',width:"5%" }}>Date</th>
            <th style={{ padding: '12px 6px' ,width:"5%"}}>Status</th>
            <th style={{ padding: '12px 6px' ,width:"13%"}}>Challan Id</th>
            <th style={{ padding: '12px 6px' ,width:"8%"}}>Start Reading</th>
            <th style={{ padding: '12px 6px',width:"6%" }}>End Reading</th>
            <th style={{ padding: '12px 6px',width:"7%" }}>Total Reading</th>
            <th style={{ padding: '12px 6px', width:"10%"}}>Start Reading Meter 2</th>
            <th style={{ padding: '12px 6px' ,width:"10%"}}>End Reading Meter 2</th>
            <th style={{ padding: '12px 6px' ,width:"10%"}}>Total Reading Meter 2</th>
            <th style={{ padding: '12px 6px' ,width:"5%"}}>Time</th>
            <th style={{ padding: '12px 6px' ,width:"5%"}}>End Time</th>
            <th style={{ padding: '12px 6px',width:"5%" }}>Hours</th>
            <th style={{ padding: '12px 6px',width:"5%" }}>Fuel Used</th>
        </tr>
      </thead>
      <tbody className={styles.TableBody}>
      
      { tableData.map((tabData)=>(
            <tr key={tabData._id} style={{ backgroundColor: "#FDF8F4", color: '#ffffff', textAlign: 'center',width:"100%"}}>
          
            <td style={{ padding: '12px 6px' , textAlign: 'center'}}>{tabData.transitionDate.slice(0,10)}</td>
            <td style={{ padding: '12px 6px' , textAlign: 'center'}}>{}</td>
            <td style={{ padding: '12px 6px' , textAlign: 'center'}}>{}</td>
            <td style={{ padding: '12px 6px' , textAlign: 'center'}}>{formData.reportType === "dailyLogReport"?tabData.meterStartReadings:''}</td>
            <td style={{ padding: '12px 6px' , textAlign: 'center'}}>{formData.reportType === "dailyLogReport"?tabData.meterEndReadings:''}</td>
            <td style={{ padding: '12px 6px' , textAlign: 'center'}}>{formData.reportType === "dailyLogReport"?Number(tabData.meterStartReadings)+Number(tabData.meterEndReadings):""}</td>
            <td style={{ padding: '12px 6px' , textAlign: 'center'}}>{formData.reportType === "dailyLogReport"?tabData.Engine2StartReadings:''}</td>
            <td style={{ padding: '12px 6px' , textAlign: 'center'}}>{formData.reportType === "dailyLogReport"?tabData.Engine2EndReadings:''}</td>
            <td style={{ padding: '12px 6px' , textAlign: 'center'}}>{formData.reportType === "dailyLogReport"?Number(tabData.Engine2StartReadings)+Number(tabData.Engine2EndReadings):""}</td>
            <td style={{ padding: '12px 6px' , textAlign: 'center'}}>{(formData.reportType === "dailyLogReport" || formData.reportType === "miscellaneous")?tabData.startTime:""}</td>
            <td style={{ padding: '12px 6px' , textAlign: 'center'}}>{(formData.reportType === "dailyLogReport" || formData.reportType === "miscellaneous")?tabData.endTime:""}</td>
            <td style={{ padding: '12px 6px' , textAlign: 'center'}}>{(formData.reportType === "dailyLogReport" || formData.reportType === "miscellaneous")?tabData.differenceTime:""}</td>
            <td style={{ padding: '12px 6px' , textAlign: 'center'}}>{formData.reportType === "dailyLogReport"?tabData.oil+" "+tabData.oilIn:""}</td>
            
        </tr> 
      ))}
       
      </tbody>
    </table>
    </div>:""}
    
    </>
  );
};

export default PerformanceSummaryReport;
