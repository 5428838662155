import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import SelectionRegister from "./SelectionRegister";
import AddRegister from "./AddRegister";
import { useRecoilState } from "recoil";
import SelectSite from "../../../Utilities/SelectSite";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import AssignCategory from "./AssignCategory";
import TableWrapper from "../../../Utilities/TableWrapper";
import {
  userInfo,
  siteId,
  registerAtom,
  atororegisterTab,
} from "../../../atoms";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Button } from "react-bootstrap";
import { set } from "date-fns";
import Filter from "../../../Images/Filter.svg";
import { v4 as uuidv4 } from "uuid";
import { Tabs, Tab } from "../../Tabs/Tabv1";

const RegisterTable = () => {
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  let webApi = new WebApimanager();
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [currentUserInfo, setUserInfo] = useRecoilState(userInfo);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  // const fetchInData = async () => {
  //   if (registerInfo && registerInfo.length > 0) {
  //     setDrawingList(registerInfo);
  //     setInitialDrawingList(registerInfo);
  //     console.log("fetched from recoil", registerInfo);
  //   } else {
  //     await updateRegister(setRegisterInfo, site).then((response) => {
  //       if (response.status === SUCESS_STATUS) {
  //         setDrawingList(response.registers);
  //         setInitialDrawingList(response.registers);
  //       }
  //     });
  //   }
  // };

  // const fetchData = async () => {
  //   await updateRegister(setRegisterInfo, site).then((response) => {
  //     if (response.status === SUCESS_STATUS) {
  //       setDrawingList(response.registers);
  //       setInitialDrawingList(response.registers);
  //     }
  //   });
  // };

  const fetchData = async () => {
    try {
      let response = await webApi.get(
        `api/architect/register/${site}`
      );
      setDrawingList(response.data.data.filteredData);
      console.log(drawingList,"responsedata of architect register")
    } catch (error) {
      console.error("Error fetching checklist data", error);
    }
  };
  
  useEffect(() => {
  
      fetchData();
  
  }, []);
  useEffect(() => {
    if (!searchActive) {
      fetchData();
    }
  }, [searchActive]);

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [existingCategories, setExistingCategories] = useState([]);
  const [existingConsultants, setExistingConsultants] = useState([]);
  const [currentDrawingId, setCurrentDrawingId] = useState("");
  const [formData, setFormData] = useState({
    DrawingNumber: "",
    DrawingTitle: "",
    DesignConsultant: "",
    DrawingCategory: "",
    AcceptedROSubmissionDate: "",
    AcceptedSiteSubmissionDate: "",
  });
 

 
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const getAll = () => {
    webApi.get("api/category/getAll").then((data) => {
      if (data) {
        setExistingCategories(data.data.data.categories);
      }
    });

    webApi
      .get(`api/designDrawingConsultant/getAll?siteId=${site}`)
      .then((data) => {
        if (data) {
          setExistingConsultants(data.data.data.consultants);
        }
      });
  };

  useEffect(() => {
    if (
      existingCategories &&
      existingCategories.length === 0 &&
      existingConsultants.length === 0
    ) {
      getAll();
    }
  }, []);

  const handleEdit = (drawingItem) => {
    setCurrentDrawingId(drawingItem._id);
    setFormData({
      DrawingNumber: drawingItem.drawingNo,
      DrawingTitle: drawingItem.drawingTitle,
      DrawingCategory: drawingItem.category
        ? drawingItem?.category?.category
        : "", // Ensure this is the correct property
      DesignConsultant: `${drawingItem.designDrawingConsultant.role}`,
      AcceptedROSubmissionDate: drawingItem.acceptedROSubmissionDate
        ? drawingItem.acceptedROSubmissionDate.split("T")[0]
        : "",
      AcceptedSiteSubmissionDate: drawingItem.acceptedSiteSubmissionDate
        ? drawingItem.acceptedSiteSubmissionDate.split("T")[0]
        : "",
    });
    handleOpen();
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });
    const tempFormData = {
      siteId: site,
      // drawingNo:  formData.DrawingNumber,
      drawingTitle: formData.DrawingTitle,
      // designDrawingConsultant: formData.DesignConsultant,
      // category: formData.DrawingCategory,
      acceptedROSubmissionDate: formData.AcceptedROSubmissionDate,
      acceptedSiteSubmissionDate: formData.AcceptedSiteSubmissionDate,
    };
    try {
      const response = await webApi.put(
        `api/architectureToRoRegister/update/${currentDrawingId}`,
        tempFormData
      );
      if (response.status === 200) {
        fetchData();
        handleClose();
        setApiProcessing({ loader: false, message: "" });
        // window.location.reload();
      } else if (response.status === 500 || response.status === 400) {
        setApiProcessing({ loader: false, message: "" });
        // Check if the response contains an error message array
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
        } else {
          // If the specific message is not found, set a generic error message
          setErrorMessage("Please Try After Some Time");
        }
      } else {
        // If the specific message is not found, set a generic error message
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      // Handle errors that occur during the request
      if (
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data) &&
        error.response.data.length > 0
      ) {
        setErrorMessage(error.response.data[0]); // Set the first error message
      } else {
        setErrorMessage("Failed to submit data.");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [trashOpen, setTrashOpen] = useState(false);
  const [trashData, setTrashData] = useState({
    uniquePassword: "",
  });
  const handleTrashOpen = (drawingItem) => {
    setTrashOpen(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setTrashData({
      ...trashData,
      [name]: value,
    });
  };
  const handleTrashClose = () => {
    setTrashOpen(false);
  };
  const handleTrashSubmit = async (id) => {
    try {
      const response = await webApi.delete(``);
      fetchData();
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error, show error message, etc.
    }
  };

  useEffect(() => {
    if (searchValue === "") {
      fetchData(); // This fetches all drawings again
      setSearchActive(false);
    } else {
      const filteredDrawings = initialDrawingList.filter((drawingItem) => {
        if (drawingItem) {
          const drawingNumber = drawingItem.drawingNo
            ? drawingItem.drawingNo.toLowerCase()
            : "";
          const drawingName = drawingItem.drawingTitle
            ? drawingItem.drawingTitle.toLowerCase()
            : "";
          const consultant =
            drawingItem.designDrawingConsultant?.role?.toLowerCase() || "";
          const category =
            drawingItem.category && drawingItem.category.category
              ? drawingItem.category.category.toLowerCase()
              : "";
          const acceptedROSubmissionDate = drawingItem.acceptedROSubmissionDate
            ? new Date(
                drawingItem.acceptedROSubmissionDate
              ).toLocaleDateString()
            : "";
          const acceptedSiteSubmissionDate =
            drawingItem.acceptedSiteSubmissionDate
              ? new Date(drawingItem.acceptedSiteSubmissionDate).toDateString()
              : "";

          return (
            drawingNumber.includes(searchValue) ||
            drawingName.includes(searchValue) ||
            consultant.includes(searchValue) ||
            category.includes(searchValue) ||
            acceptedROSubmissionDate.includes(searchValue) ||
            acceptedSiteSubmissionDate.includes(searchValue)
          );
        } else {
          return false;
        }
      });
      setDrawingList(filteredDrawings);
    }
  }, [searchValue]);

  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase();
    setSearchValue(searchInput);
    setSearchActive(true);
  };

  if (site === "") {
    return <SelectSite />;
  }

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#131313",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                   Drawing Register
              </p>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          position: "relative",
                          width: "250px",
                          paddingLeft: "5px",
                          height: "50px",
                        }}
                      >
                        <input
                          placeholder="Search Drawings"
                          onChange={searchResult}
                          value={searchValue}
                          style={{
                            paddingLeft: "20px",
                            border: "1px solid var(--primary-color)",
                            width: "250px",
                            height: "40px",
                          }}
                        />
                        <Icon.Search
                          style={{
                            position: "absolute",
                            right: "15px",
                            top: "40%",
                            transform: "translateY(-50%)",
                            color: "#ACACAC",
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      {/* { currentUserInfo.department === 'Admin' && <button  */}
                      {/* <div style={{ position: "relative" }}>
                        <img
                          src={Filter}
                          style={{
                            width: "40px",
                            height: "40px",
                            marginLeft: "10px",
                            padding: "5px",
                            border: "1px solid var(--primary-color)",
                            cursor: "pointer",
                          }}
                          // onClick={() => setShowDropdown(!showDropdown)}
                        />
                      </div> */}
                      {/* <div style={{ position: "relative" }}>
                        <Icon.FunnelFill
                          size={20}
                          style={{
                            width: "40px",
                            height: "40px",
                            marginLeft: "10px",
                            padding: "5px",
                            border: "1px solid var(--primary-color)",
                            cursor: "pointer",
                          }}
                          // onClick={() => setShowDropdown(!showDropdown)}
                        />
                      </div> */}
                    </div>
                  </div>
             

              
            </div>

           <div style={{marginTop:'10px'}}>
              <DrawingTableWrapper
                tableHead={
                  <tr>
                    <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{ width: "11%" }}>DRWG.No</th>
                    <th style={{ width: "13%" }}>consultant </th>
                    <th style={{ width: "5%" }}>Folder</th>
                    <th style={{ width: "13%" }}>Drawing Category</th>
                    <th style={{ width: "14%" }}>Drawing Title</th>
                    <th style={{width:'10%'}}>Created Date</th>
                    <th style={{ width: "13%" }}>
                      Accepted R0 Submission Date
                    </th>
                    <th style={{ width: "13%" }}>
                      Accepted site submission date
                    </th>
                 
                  </tr>
                }
                tableBody={
                  <tbody>
                    {drawingList
                      .filter((item) => item && item.designDrawingConsultant)
                      .sort((a, b) => {
                        const consultantA = a.category?.category
                          ? a.category?.category.toLowerCase()
                          : "";
                        const consultantB = b.category?.category
                          ? b.category?.category.toLowerCase()
                          : "";
                        return consultantA.localeCompare(consultantB);
                      })
                      .map((drawingItem, index) => (
                        <tr
                          key={index}
                          style={{
                            height:"60px",
                            backgroundColor:
                              index % 2 === 0
                                ? "var(--table-oddrow-color)"
                                : "var(--table-evenrow-color)",
                          }}
                        >
                          <td>{index + 1}</td>
                          <td>{drawingItem?.drawingNo || "na"}</td>
                          <td>{drawingItem.designDrawingConsultant.role}</td>
                          <td>{drawingItem.folderId?.folderName || ""}</td>
                          <td>{drawingItem.category?.category || ""}</td>
                          <td style={{ width: "9%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",cursor:'default' }}>
  <span 
    title={drawingItem.drawingTitle}
    style={{
      display: "inline-block",
      maxWidth: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }}
  >
    {drawingItem.drawingTitle.split(" ").slice(0, 2).join(" ")}...
  </span>
  </td>
                          <td>{formatDate(drawingItem.creationDate)}</td>
                          <td>
                            {formatDate(drawingItem.acceptedROSubmissionDate)}
                          </td>
                          <td>
                            {formatDate(drawingItem.acceptedSiteSubmissionDate)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                }
              />
            </div>
           
          </>
        }
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            Drawing
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
          <section
            style={{
              marginLeft: "80px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "50px",
                width: "80%",
              }}
            >
              {errorMessage && (
                <div style={{ color: "red", fontSize: "15px" }}>
                  {errorMessage}
                </div>
              )}
              <div>
                <label
                  htmlFor="DrawingNumber"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing No
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="DrawingNumber"
                  name="DrawingNumber"
                  value={formData.DrawingNumber}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />

                {errors.DrawingNumber && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.DrawingNumber}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="DrawingTitle"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Title
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="DrawingTitle"
                  name="DrawingTitle"
                  value={formData.DrawingTitle}
                  onChange={handleChange}
                  placeholder="Enter your drawing title"
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />
                {errors.DrawingTitle && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.DrawingTitle}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="DrawingCategory"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Drawing Category
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="DrawingCategory"
                  name="DrawingCategory"
                  value={formData.DrawingCategory}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />

                {/* <select
                  id="DrawingCategory"
                  name="DrawingCategory"
                  value={formData.DrawingCategory}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: '100%' }}
                >
                  <option value="">Select Category</option>
                  {existingCategories.map((category) => (
                    <option key={category._id} value={category._id}>{category.category}</option>
                  ))}
                </select> */}
                {errors.DrawingCategory && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.DrawingCategory}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="DesignConsultant"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Design Consultant <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="DesignConsultant"
                  name="DesignConsultant"
                  value={formData.DesignConsultant}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  readOnly
                />
                {/* <select
                  id="DesignConsultant"
                  name="DesignConsultant"
                  value={formData.DesignConsultant}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: '100%' }}
                  readOnly
                >
                  <option value="">Select Consultant</option>
                  {existingConsultants.map((consultant) => (
                    <option key={consultant._id} value={consultant._id}>{consultant.designDrawingConsultant}</option>
                  ))}
                </select> */}
                {errors.DesignConsultant && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.DesignConsultant}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="AcceptedROSubmissionDate"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Accepted RO Submission Date
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  name="AcceptedROSubmissionDate"
                  value={formData.AcceptedROSubmissionDate}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />

                {errors.AcceptedROSubmissionDate && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.AcceptedROSubmissionDate}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor=" AcceptedSiteSubmissionDate"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Accepted Site SubmissionDate
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  name="AcceptedSiteSubmissionDate"
                  value={formData.AcceptedSiteSubmissionDate}
                  onChange={handleChange}
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                />
                {errors.AcceptedSiteSubmissionDate && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.AcceptedSiteSubmissionDate}
                  </p>
                )}
              </div>
            </div>
          </section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              style={{
                marginLeft: "50px",
                padding: "10px",
                border: "1px solid var(--primary-color)",
                width: "100px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={trashOpen}
        onClose={handleTrashClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
          }}
        >
          <p
            style={{ margin: "5px 250px", textAlign: "center", color: "white" }}
          >
            Delete
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleTrashClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "auto", minHeight: "auto" }}
        >
          <section
            style={{
              marginLeft: "80px",
              marginRight: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <label
                htmlFor="uniquePassword"
                className="fs-10 fw-bold text-start py-2"
              >
                Password
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                id="uniquePassword"
                name="uniquePassword"
                value={trashData.uniquePassword}
                onChange={handleInputChange}
                type="password"
                className="form-control fs-12"
                style={{ width: "100%" }}
              />

              {errors.uniquePassword && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {errors.uniquePassword}
                </p>
              )}
            </div>
          </section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              className="btn btn-primary"
              style={{
                marginLeft: "50px",
                padding: "10px",

                border: "1px solid var(--primary-color)",
                width: "100px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
              onClick={handleTrashSubmit}
            >
              Submit
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RegisterTable;
