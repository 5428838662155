/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import IsCodes from "./IsCodes/IsCodes";
import ConstructionNeeds from "./ConstructionNeeds/ConstructionNeeds";
import SelectSite from "../../Utilities/SelectSite";
import { useRecoilState } from "recoil";
import { siteId, spaceTab, userInfo } from "../../atoms";

const Space = () => {
  const [value, setValue] = useRecoilState(spaceTab);
  const [site, setSite] = useRecoilState(siteId);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [tabEnableModules, setTabEnableModules] = useState({
    constructionNeeds: false,
    isCodes: false,
  });

  useEffect(() => {
    if (currentUserInfo && currentUserInfo.permittedSites) {
      const enabledModules = currentUserInfo.permittedSites.map(
        (site) => site.enableModules
      );

      const constructionNeedsEnabled = enabledModules.some(
        (module) => module.spaceDetails.constructionNeeds
      );
      const isCodesEnabled = enabledModules.some(
        (module) => module.spaceDetails.isCodes
      );

      setTabEnableModules({
        constructionNeeds: constructionNeedsEnabled,
        isCodes: isCodesEnabled,
      });
    }
  }, [currentUserInfo]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  if (site === "") {
    return <SelectSite />;
  }

  const allModulesDisabled =
    !tabEnableModules.isCodes && !tabEnableModules.constructionNeeds;

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "0px",
          padding: "0 50px",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            marginTop: "90px",
          }}
        >
          {!allModulesDisabled && (
            <div
              style={{
                display: "flex",
                border: "1px solid #E47331",
              }}
            >
              {tabEnableModules.isCodes && (
                <button
                  onClick={() => handleChange(0)}
                  style={{
                    borderRight:
                      value === 0
                        ? "3px solid #E47331"
                        : "3px solid transparent",
                    borderBottom:
                      value === 0
                        ? "3px solid #E47331"
                        : "3px solid transparent",
                    backgroundColor: value === 0 ? "#E47331" : "transparent",
                    color: value === 0 ? "white" : "#E47331",
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                >
                  IS Codes
                </button>
              )}
              {tabEnableModules.constructionNeeds && (
                <button
                  onClick={() => handleChange(1)}
                  style={{
                    border:
                      value === 1
                        ? "3px solid #E47331"
                        : "3px solid transparent",
                    borderBottom:
                      value === 1
                        ? "3px solid #E47331"
                        : "3px solid transparent",
                    backgroundColor: value === 1 ? "#E47331" : "transparent",
                    color: value === 1 ? "white" : "#E47331",
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }}
                >
                  Construction Needs
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      {allModulesDisabled ? (
        <p
          style={{
            textAlign: "center",
            fontSize: "16px",
            color: "#E47331",
            marginTop: "20px",
          }}
        >
          Please contact admin.
        </p>
      ) : (
        <>
          {tabEnableModules.isCodes && (
            <TabPanel value={value} index={0}>
              <IsCodes />
            </TabPanel>
          )}
          {tabEnableModules.constructionNeeds && (
            <TabPanel value={value} index={1}>
              <ConstructionNeeds />
            </TabPanel>
          )}
        </>
      )}
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
      style={{
        width: "100%",
        display: value === index ? "block" : "none",
        padding: "20px",
        backgroundColor: "#F3F3F3",
      }}
    >
      {value === index && <div style={{ textAlign: "center" }}>{children}</div>}
    </div>
  );
}

export default Space;
