import React, { useState, useEffect } from "react";
import DrawingTableWrapper from "../../Components/DrawingTableWrapper";

import Eye from "../IconsSiteHead/Eye.svg";
import Download from "../IconsSiteHead/Download.svg";
import { siteId } from "../../../../atoms";
import { useRecoilState } from "recoil";
import { WebApimanager } from "../../../../WebApiManager";

const IssuedFromR0 = ({ searchQuery, selectedCategory }) => {
  const webApi = new WebApimanager();
  const [site] = useRecoilState(siteId);
  const [issuedData, setIssuedData] = useState([]);

  useEffect(() => {
    const fetchUploadData = async () => {
      try {
        const response = await webApi.get(
          `api/architect/pendingSiteHead/${site}?filterType=issued`
        );
        console.log("Issued Data:", response.data);
        setIssuedData(response.data.data);
      } catch (error) {
        console.error("Error fetching issued data:", error);
      }
    };

    fetchUploadData();
  }, [site]);

  const filterSearchData = issuedData.filter((item) => {
    const matchesSearch =
      item.drawingNo?.toLowerCase().includes(searchQuery) ||
      item.designDrawingConsultant?.role?.toLowerCase().includes(searchQuery) ||
      item.category?.category?.toLowerCase().includes(searchQuery) ||
      item.drawingTitle?.toLowerCase().includes(searchQuery);

    const matchesCategory = selectedCategory
      ? item.category?.category === selectedCategory
      : true;

    return matchesSearch && matchesCategory;
  });

  return (
    <DrawingTableWrapper
      tableHead={
        <tr>
          <th style={{ width: "5%" }}>S.No</th>
          <th style={{ width: "10%" }}>Drawing No</th>
          <th style={{ width: "10%" }}>Design Consultant</th>
          <th style={{ width: "10%" }}>Drawing Category</th>
          <th style={{ width: "10%" }}>Drawing Title</th>
          <th style={{ width: "13%" }}>Accepted RO Submission Date</th>
          <th style={{ width: "10%" }}>Due/Overdue</th>
          <th style={{ width: "10%" }}>Revision</th>
          <th style={{ width: "7%" }}>Action</th>
        </tr>
      }
      tableBody={
        filterSearchData.length > 0 ? (
          filterSearchData.map((issued, issuedIndex) => (
            <tr
              key={issued._id}
              style={{
                backgroundColor: issuedIndex % 2 === 0 ? "#FDF8F4" : "#F3F6F8",
              }}
            >
              <td style={{ width: "5%", padding: "10px" }}>
                {(issuedIndex + 1).toString().padStart(3, "0")}
              </td>
              <td style={{ width: "10%", padding: "10px" }}>
                {issued?.drawingNo}
              </td>
              <td style={{ width: "10%", padding: "10px" }}>
                {issued?.designDrawingConsultant?.role}
              </td>
              <td style={{ width: "10%", padding: "10px" }}>
                {issued?.category?.category}
              </td>
              <td style={{ width: "10%", padding: "10px" }}>
                {issued?.drawingTitle}
              </td>
              <td style={{ width: "13%", padding: "10px" }}>
                {issued?.acceptedSiteSubmissionDate
                  ? new Date(
                      issued.acceptedSiteSubmissionDate
                    ).toLocaleDateString()
                  : "N/A"}
              </td>
              <td style={{ width: "10%", padding: "10px" }}>Due/Overdue</td>
              <td style={{ width: "10%", padding: "10px" }}>
                {issued?.acceptedSiteHeadRevisions
                  ?.slice(-2)
                  .map((revisionItem) => revisionItem.revision)
                  .join(", ")}
              </td>
              <td style={{ width: "7%", padding: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginLeft: "40px",
                  }}
                >
                  <img
                    src={Download}
                    alt="Download"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9" style={{ textAlign: "center" }}>
              No data found
            </td>
          </tr>
        )
      }
    />
  );
};

export default IssuedFromR0;
