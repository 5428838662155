import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { WebApimanager } from "../../../WebApiManager";
import { siteId } from "../../../atoms";
import { useRecoilState } from "recoil";
import styles from "./Vehicle&Machinery.module.css";
import FormHeadings from "../PNMAssets/FormHeadings";
// import FormItem from "../PNMAssets/FormItem";
// import st from "./PowerTools.module.css";
import Style from "./VehicleAndMachinery.module.css"
import { FaX } from "react-icons/fa6";

const VehicleAndMachinery = ({handleClose,showLogReport=true,tableFormData={}}) => {
  let webApi = new WebApimanager();
  const [sites, setDatasites] = useState([]);
  const [site, setSite] = useRecoilState(siteId);


  

  

  useEffect(() => {
 
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
      
          if (!site) {
            const defaultSiteId = response.data.sites[0]._id;
            setSite(defaultSiteId); 
            setFormData((prev) => ({ ...prev, siteName: defaultSiteId })); 
          } else {
            setFormData((prev) => ({ ...prev, siteName: site })); 
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [site]);

  const [formData, setFormData] = useState({
    siteName: site,
    type: "",
    name: "",
    equipmentType: "Vehicle and Machinery",
    assetCode: "",
    transitionId: "",
    transitionDate: new Date().toISOString().split("T")[0],
    meterStartReadings: "",
    meterEndReadings: "",
    meterDifference: "",
    startDate: "",
    endDate: "",
    differenceDate: "",
    startTime: "",
    endTime: "",
    differenceTime: "",
    Engine2StartReadings: "",
    Engine2EndReadings: "",
    Engine2ReadingsDifference: "",
    oil: "",
    oilIn: "",
    scopeOfWorkDetails: "",
    location: "",
    remarks: "",
    subCode: "",
    fuelType: "",
    debitNote: "",
    employeeType: "",
    employeeId: "",
    debitAmount: "",
    debitReason: "",
  });

  const [errors, setErrors] = useState({});
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [nameData, setNameData] = useState();
  const [id, setId] = useState("");

  function calculateDateDifference(startDate, endDate) {
    const [startYear, startMonth, startDay] = startDate.split("-").map(Number);
    const [endYear, endMonth, endDay] = endDate.split("-").map(Number);
    console.log(startYear, endYear);
    console.log(startMonth, endMonth);
    console.log(startDay, endDay);

    const start = new Date(startYear, startMonth - 1, startDay);
    const end = new Date(endYear, endMonth - 1, endDay);

    if (end >= start) {
      const diffTime = end.getTime() - start.getTime();
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return Math.round(diffDays);
    } else {
      return -1;
    }
  }

  const handleSiteChange = (e) => {
    const { name, value } = e.target;
    console.log(value, "site changed ");
    console.log(e.target.value, "Okkk");
    setSite(e.target.value);
    
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newFormData = {
      ...formData,
      [name]: value,
    };

    if (name === "assetCode" && value) {
      const assCode = assetCodeData.find((doc) => doc._id === value); 
      if (assCode) {
        setId(assCode._id); 
        console.log(assCode._id)
        console.log("Asset Name:", assCode.name);
        console.log("SubCode:", assCode.subCode); 
        newFormData = {
          ...newFormData,
          subCode: assCode.subCode,  
        };
        setFormData(newFormData); 
      } else {
        console.log("No asset found with ID:", value);
      }
    }
    setFormData(newFormData);
    if (name === "meterStartReadings" || name === "meterEndReadings") {
      if (
        newFormData.meterStartReadings !== "" &&
        newFormData.meterEndReadings !== ""
      ) {
        const start = parseInt(newFormData.meterStartReadings, 10);
        const end = parseInt(newFormData.meterEndReadings, 10);
        const difference = end - start;
        setFormData({
          ...newFormData,
          meterDifference: difference,
        });
        if (start >= end) {
          errors.meterDifference =
            "Start Reading should be less than End Reading";
          setFormData({
            ...newFormData,
            meterDifference: "",
          });
        } else {
          errors.meterDifference = "";
        }
      } else {
        setFormData({
          ...newFormData,
          meterDifference: "",
        });
      }
    }
    if (name === "startDate" || name === "endDate") {
      if (newFormData.startDate !== "" && newFormData.endDate !== "") {
        const diff = calculateDateDifference(
          newFormData.startDate,
          newFormData.endDate
        );
        setFormData({ ...newFormData, differenceDate: diff });
        if (diff < 0) {
          errors.differenceDate = "start date should be less than end Date";
          setFormData({
            ...newFormData,
            differenceDate: "",
          });
        } else {
          setFormData({ ...newFormData, differenceDate: diff });
          errors.differenceDate = "";
        }
      } else {
        setFormData({ ...newFormData, differenceDate: "" });
      }
    }

    // Calculate Engine2 readings difference
    if (name === "Engine2StartReadings" || name === "Engine2EndReadings") {
      if (
        newFormData.Engine2StartReadings !== "" &&
        newFormData.Engine2EndReadings !== ""
      ) {
        const start = parseInt(newFormData.Engine2StartReadings, 10);
        const end = parseInt(newFormData.Engine2EndReadings, 10);
        const difference = end - start;
        setFormData({
          ...newFormData,
          Engine2ReadingsDifference: difference,
        });
        if (start >= end) {
          errors.Engine2ReadingsDifference =
            "Start Reading should be less than End Reading";
          setFormData({
            ...newFormData,
            Engine2ReadingsDifference: "",
          });
        } else {
          errors.Engine2ReadingsDifference = "";
        }
      } else {
        setFormData({
          ...newFormData,
          Engine2ReadingsDifference: "",
        });
      }
    }

    // Calculate time difference
    if (name === "startTime" || name === "endTime") {
      if (newFormData.startTime && newFormData.endTime) {
        const start = new Date(`1970-01-01T${newFormData.startTime}`);
        const end = new Date(`1970-01-01T${newFormData.endTime}`);
        let difference = (end.getTime() - start.getTime()) / 1000; // Difference in seconds

        const hours = Math.floor(difference / 3600);
        difference = difference % 3600;
        const minutes = Math.floor(difference / 60);

        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedDifference = `${formattedHours}:${formattedMinutes}`;
        if (start >= end) {
          errors.differenceTime = "Start Time should be less than End Date";
          setFormData({
            ...newFormData,
            differenceTime: "",
          });
        } else {
          errors.differenceTime = "";
        }
        setFormData({
          ...newFormData,
          differenceTime: formattedDifference,
        });
      } else {
        setFormData({
          ...newFormData,
          differenceTime: "",
        });
      }
    }
  };

  const filteredNames = assetCodeData.filter(
    (item) => item._id === formData.assetCode
  );
  const filteredSubcodes = assetCodeData.filter(
    (item) => item._id === formData.assetCode && formData.name
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};

    const requiredFields = [
      "siteName",
      "type",
      "equipmentType",
      "assetCode",
      "startDate",
      "endDate",
      "differenceDate",
      "startTime",
      "endTime",
      "differenceTime",
      "meterStartReadings",
      "meterEndReadings",
      "meterDifference",
      "Engine2StartReadings",
      "Engine2EndReadings",
      "Engine2ReadingsDifference",
      "oil",
      "oilIn",
      "transitionDate",
      "scopeOfWorkDetails",
      "location",
      "remarks",
      "subCode",
      "fuelType",
    ];

    requiredFields.forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
    });

    if (formData.debitNote === "Yes") {
      const conditionalDebitNoteFields = [
        "employeeType",
        "employeeId",
        "debitAmount",
        "debitReason",
      ];
      conditionalDebitNoteFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
      });
    }

    console.log("submitted", formData);

    if (Object.keys(formErrors).length) {
      console.log("Form errors:", formErrors);
      setErrors(formErrors);
    } else {
      console.log(formData);
      const tempFormData = {
        siteName: site,
        type: formData.type,
        assetCode: formData.assetCode,
        equipmentType: formData.equipmentType,
        vehicleAndMachinery: {
          meterStartReadings: Number(formData.meterStartReadings),
          meterEndReadings: Number(formData.meterEndReadings),
          meterDifference: Number(formData.meterDifference),
          Engine2StartReadings: Number(formData.Engine2StartReadings),
          Engine2EndReadings: Number(formData.Engine2EndReadings),
          Engine2ReadingsDifference: Number(formData.Engine2ReadingsDifference),
        },
        startDate: formData.startDate,
        endDate: formData.endDate,
        differenceDate: formData.differenceDate,
        startTime: formData.startTime,
        endTime: formData.endTime,
        differenceTime: formData.differenceTime,
        oil: formData.oil,
        oilIn: formData.oilIn,
        transitionDate: formData.transitionDate,
        scopeOfWorkDetails: formData.scopeOfWorkDetails,
        location: formData.location,
        remarks: formData.remarks,
        subCode: formData.subCode,
        fuelType: formData.fuelType,
        debitAmount: Number(formData.debitAmount),
        debitReason: formData.debitReason,

        debitNote: formData.debitNote || "No",
        employeeType: formData.employeeType,
        debitAmount: Number(formData.debitAmount),
        debitReason: formData.debitReason,
        employeeId: formData.employeeId,
      };

      console.log("temp", tempFormData);
      console.log("formData", formData);
      if(Object.keys(tableFormData).length > 0){
        const response = await webApi.put(
          `api/dailyLogReport/vm/${tableFormData._id}`,
          tempFormData
        );
        console.log(response);
        if(response.status === 201 || response.status === 200){

          window.location.reload();
        }
      }else{
      const response = await webApi.post(
        "api/dailyLogReport/vm",
        tempFormData
      );  console.log(response);
      console.log(response.status,"responseStatus")
      if(response.status === 201 || response.status === 200){

        window.location.reload();
      }
    }
  
    }
  };

  useEffect(() => {
    const fetchAssetCodeData = async () => {
      let response = await webApi.get(`api/pnms/assetCode`);
      setAssetCodeData(response.data.data.AssetCodes);
    };

    fetchAssetCodeData();
  }, []);

  useEffect(()=>{
    if(Object.keys(tableFormData).length){
      let updateForm={...tableFormData,
        ["transitionDate"]:tableFormData.transitionDate.slice(0,10),
        ["startDate"]:tableFormData.startDate.slice(0,10),
        ["endDate"]:tableFormData.endDate.slice(0,10),
        ["differenceDate"]:tableFormData.differenceDate,
        ["assetCode"]:tableFormData.assetCode._id
      }
      setFormData(updateForm)
    }
  },[tableFormData])

 

  return (
    <>
    <div className={showLogReport?Style.VMMainContainer:Style.VMEditMainContainer} style={{position:"relative"}}>
          {!showLogReport && <div style={{position:"absolute",right:"40px",top:"40px",color:"#E47331",cursor:"pointer"}} >
            <FaX onClick={handleClose}/>
          </div>}
          <h3 className={Style.VMHeading}>
            Daily Log Report For Vehicle & Machine
          </h3>
          <form onSubmit={handleSubmit} className={Style.VMFormContainer}>
            <div className={Style.VMSiteContainer}>
              <div className={Style.VMSiteHeading}>
                <FormHeadings text={"Site Name:"} />
              </div>
              <div className={Style.VMSite}>
                <select
                  className={Style.VMSiteInputField}
                  name="siteName"
                  value={formData.siteName}
                  onChange={handleSiteChange}
                  
                >
                  <option value="" hidden>
                    Select a site
                  </option>
                  {sites &&
                    Array.isArray(sites) &&
                    sites.map((item) => (
                      <option value={item._id} key={item._id}>
                        {item.siteName}
                      </option>
                    ))}
                </select>
                <p className={Style.PNMFormErrors}>{errors.siteName}</p>
              </div>
            </div>
            <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition ID:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                      <input type="text" 
                      name="transitionId"
                      value={formData.transitionId}
                      readOnly className={Style.VMTopInputField} />
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition Date:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                            type="date"
                            className={Style.VMTopInputField}
                            name="transitionDate"
                            value={formData.transitionDate}
                            onChange={handleChange}
                            placeholder="---------"
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.transitionDate}
                          </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Equipment Type:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          name="equipmentType"
                          value={formData.equipmentType}
                          readOnly
                        />
                        <p className={Style.PNMFormErrors}>
                          {errors.equipmentType}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Asset code/Name:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="assetCode"
                          value={formData.assetCode}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            Select name/Asset Code
                          </option>
                          {assetCodeData.length &&
                            assetCodeData
                              .filter(
                                (item) =>
                                  item.type === "Vehicle" ||
                                  item.type === "Machinery"
                              )
                              .map((item) => (
                                <option
                                  value={item._id}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              ))}
                        </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.assetCode}
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Type:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="type"
                          value={formData.type}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            select Type
                          </option>
                          <option value="Own">Own</option>
                          <option value="Hire">Hire</option>
                        </select>
                        <p className={Style.PNMFormErrors}>
                          {errors.type}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"SubCode:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          type="text"
                          placeholder="Enter SubCode"
                          name="subCode"
                          onChange={handleChange}
                          value={formData.subCode}
                        />
                        <p className={Style.PNMFormErrors}>{errors.subCode}</p>
                    </div>
                  </div>
                </div>
              </div>
              
                
            </div>
            <div className={Style.VMFormSeperator}></div>
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>1st Km/Hr Meter</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Start:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                        <input
                              placeholder="Enter Reading"
                              type="number"
                              className={Style.VMMidInput}
                              name="meterStartReadings"
                              value={formData.meterStartReadings}
                              onChange={handleChange}
                            />
                            <p className={Style.PNMFormErrors} style={{textAlign:"left",width:"14%",textWrap:"wrap"}}>
                              {errors.meterStartReadings}
                            </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"End:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            placeholder="Enter Reading"
                            type="number"
                            className={Style.VMMidInput}
                            name="meterEndReadings"
                            value={formData.meterEndReadings}
                            onChange={handleChange}
                          />
                          <p className={Style.PNMFormErrors} style={{textAlign:"left",width:"14%",textWrap:"wrap"}}>
                            {errors.meterEndReadings}
                          </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            type="number"
                            className={Style.VMMidInput}
                            name="meterDifference"
                            value={formData.meterDifference}
                            onChange={handleChange}
                          />
                          <p className={Style.PNMFormErrors} style={{textAlign:"left",width:"14%",textWrap:"wrap"}}>
                            {errors.meterDifference}
                          </p>
                      </div>
                </div>
            </div>
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>2nd Km/Hr Meter</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Start:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                        <input
                              placeholder="Enter Reading"
                              type="number"
                              className={Style.VMMidInput}
                              name="Engine2StartReadings"
                              value={formData.Engine2StartReadings}
                              onChange={handleChange}
                            />
                            <p className={Style.PNMFormErrors} style={{textAlign:"left",width:"14%",textWrap:"wrap"}}>
                              {errors.Engine2StartReadings}
                            </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"End:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            placeholder="Enter Reading"
                            type="number"
                            className={Style.VMMidInput}
                            name="Engine2EndReadings"
                            value={formData.Engine2EndReadings}
                            onChange={handleChange}
                            
                          />
                          <p className={Style.PNMFormErrors} style={{textAlign:"left",width:"14%",textWrap:"wrap"}}>
                            {errors.Engine2EndReadings}
                          </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            type="number"
                            className={Style.VMMidInput}
                            name="Engine2ReadingsDifference"
                            value={formData.Engine2ReadingsDifference}
                            onChange={handleChange}
                            
                          />
                          <p className={Style.PNMFormErrors} style={{textAlign:"left",width:"14%",textWrap:"wrap"}}>
                            {errors.Engine2ReadingsDifference}
                          </p>
                      </div>
                </div>
            </div>
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>Date:</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Start:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                        <input
                              placeholder="Enter Reading"
                              type="date"
                              className={Style.VMInputDate}
                              name="startDate"
                              value={formData.startDate}
                              onChange={handleChange}
                            />
                            <p className={Style.PNMFormErrors}>
                              {errors.startDate}
                            </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"End:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            placeholder="Enter Reading"
                            type="date"
                            className={Style.VMInputDate}
                            name="endDate"
                            value={formData.endDate}
                            onChange={handleChange}
                            
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.endDate}
                          </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            type="text"
                            className={Style.VMMidInput}
                            name="differenceDate"
                            value={formData.differenceDate}
                            onChange={handleChange}
                            
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.differenceDate}
                          </p>
                      </div>
                </div>
            </div>
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>Clock:</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"In:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                        <input
                              placeholder="Enter Reading"
                              type="time"
                              className={Style.VMMidInput}
                              name="startTime"
                              value={formData.startTime}
                              onChange={handleChange}
                            />
                            <p className={Style.PNMFormErrors}>
                              {errors.startTime}
                            </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Out:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            placeholder="Enter Reading"
                            type="time"
                            className={Style.VMMidInput}
                            name="endTime"
                            value={formData.endTime}
                            onChange={handleChange}
                            
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.endTime}
                          </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            type="text"
                            className={Style.VMMidInput}
                            name="differenceTime"
                            value={formData.differenceTime}
                            onChange={handleChange}
                            readOnly
                            
                          />
                          <p className={Style.PNMFormErrors}>
                            {errors.differenceTime}
                          </p>
                      </div>
                </div>
            </div>

            <div className={Style.VMFormSeperator}></div>
            <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Fuel Type:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                            className={Style.VMTopInputField}
                            name="fuelType"
                            value={formData.fuelType}
                            onChange={handleChange}
                          >
                            <option value="">Select Fuel Type</option>
                            <option value="Diesel">Diesel</option>
                            <option value="Petrol">Petrol</option>
                            <option value="Power">Power</option>
                          </select>
                          <p className={Style.PNMFormErrors}>
                            {errors.fuelType}
                          </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Issued Oil:"} />
                    </div>
                    <div className={Style.VMTopInputCont} style={{display:"flex",position:"relative"}}>
                    <input
                            type="text"
                            className={formData.oil ? Style.VMIssuedOil:
                              Style.VMTopInputField
                            }
                            name="oil"
                            placeholder="Enter Number"
                            value={formData.oil}
                            onChange={(e)=>{
                              const value=e.target.value
                              if (!isNaN(value) ) {
                                handleChange(e);
                              }
                            }}
                          />
                          {formData.oil && <>
                          <select
                              className={Style.VMOilIn}
                              name="oilIn"
                              value={formData.oilIn}
                              onChange={handleChange}
                            >
                              <option value="" hidden>
                                Ltr/Gal
                              </option>
                              <option value="Litre">Ltr</option>
                              <option value="Gallon">Gal</option>
                            </select>
                          
                          </>}
                          {formData.oil === ""  ?<p className={Style.PNMFormErrors} style={{top:"100%"}}>{errors.oil}</p>:

<p className={Style.PNMFormErrors} style={{top:"100%"}}>
  {errors.oilIn}
</p>}
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Debit Note:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                            className={Style.VMTopInputField}
                            name="debitNote"
                            value={formData.debitNote || "No"}
                            onChange={handleChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                          <p className={Style.PNMFormErrors}>
                            {errors.debitNote}
                          </p>
                    </div>
                  </div>
                </div>
                
              </div>
              
              {formData.debitNote === "Yes" && <>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Employee:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  name="employeeType"
                                  value={formData.employeeType}
                                  placeholder="Enter employee Type"
                                  onChange={handleChange}
                                />

                                <p className={Style.PNMFormErrors}>
                                  {errors.employeeType}
                                </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Employee ID:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  name="employeeId"
                                  placeholder="Enter Employee Id"
                                  value={formData.employeeId}
                                  onChange={handleChange}
                                />

                                <p className={Style.PNMFormErrors}>
                                  {errors.employeeId}
                                </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Debit Reason:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  placeholder="Enter Reason"
                                  name="debitReason"
                                  value={formData.debitReason}
                                  onChange={handleChange}
                                />
                                <p className={Style.PNMFormErrors}>
                                  {errors.debitReason}
                                </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Debit Amount:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                    type="text"
                                    className={Style.VMTopInputField}
                                    name="debitAmount"
                                    placeholder="Enter amount"
                                    value={formData.debitAmount}
                                    onChange={(e)=>{
                                      const value=e.target.value
                                      if (!isNaN(value) ) {
                                        handleChange(e);
                                      }
                                    }}
                                  />
                                  <p className={Style.PNMFormErrors}>
                                    {errors.debitAmount}
                                  </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>}
                
            </div>
            <div className={Style.VMFormSeperator}></div>

            <div className={Style.VMWorkDetails}>
              <div className={Style.VMWorkDetailsHeading}>
                <FormHeadings text={"Scope of work Details:"} />
              </div>
              <div className={Style.VMWorkDetailsInputCont}>
                <input
                  type="text"
                  placeholder="Enter Scope of work"
                  className={Style.VMWorkDetailsInputField}
                  name="scopeOfWorkDetails"
                  value={formData.scopeOfWorkDetails}
                  onChange={handleChange}
                />
                <p className={Style.PNMFormErrors}>
                  {errors.scopeOfWorkDetails}
                </p>
              </div>

            </div>
            <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Location:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                          type="text"
                          className={Style.VMTopInputField}
                          name="location"
                          placeholder="Enter Work Location"
                          value={formData.location}
                          onChange={handleChange}
                        />
                        <p className={Style.PNMFormErrors}>
                          {errors.location}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Remarks:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                        <input
                            type="text"
                            className={Style.VMTopInputField}
                            name="remarks"
                            placeholder="Enter Remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                          />
                          <p className={Style.PNMFormErrors}>{errors.remarks}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.VMBtnContainer}>
                  <button >{showLogReport?"SUBMIT":"SAVE"}</button>
                </div>

          </form>
    </div>
      
    </>
  );
};

export default VehicleAndMachinery;
