import React, { useEffect, useState } from "react";
import TableWrapper from "../../../Utilities/TableWrapper";
import DrawingTableWrapper from "../../Drawing/Components/DrawingTableWrapper";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import {Tabs, Tab} from '../../Tabs/Tabv1'
import { Dialog, DialogContent, } from '@mui/material';
import { RxCross1 } from "react-icons/rx";
import Styles from "../../Checklist/Design/Design.module.css"
import { Loader } from "../../../Widgets/notificationFeedbacks";
import TransferPermanentWork from "./TransferPermanentWork";
import TransferTemporaryWork from "./TransferTemporaryWork";

const TransferWork = (

) => {
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [currentView, setCurrentView] = useState("temporarywork");
  const [openAddNewDialog,setOpenAddNewDialog] = useState(false)
  const [showPermanentForm, setShowPermanentForm] = useState(false)
  const [showTemporaryForm, setShowTemporaryForm] = useState(false)

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
  };
  const handleClickOpen = () => {
    setOpenAddNewDialog(true)
    setShowPermanentForm(false)
    setShowTemporaryForm(false)
  };
  const handleAddNewDialogClose=()=>{
    setOpenAddNewDialog(false)
    setShowPermanentForm(false)
    setShowTemporaryForm(false)
  }

  const handlePermanent = () =>{
    setOpenAddNewDialog(false)
    setShowPermanentForm(true)
    setShowTemporaryForm(false)
  }
  const handleTemporary = () =>{
    setOpenAddNewDialog(false)
    setShowPermanentForm(false)
    setShowTemporaryForm(true)
  }
  const handleClose=()=>{
    setOpenAddNewDialog(false)
    setShowPermanentForm(false)
    setShowTemporaryForm(false)
  }
  useEffect(()=>{
    console.log(showTemporaryForm,"transfer")
  },[showTemporaryForm])

return (
<>
   <TableWrapper


    children={
      <>
        <div
          style={{
            display: "flex",
            marginLeft: "5px",
            marginRight: "5px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            <Tabs
              value={currentView}
              onChange={handleTabViewChange}
            
            >
              <Tab label="Temporary Work" value="temporarywork" />
              <Tab label="Permanent Work" value="permanentwork" />
            </Tabs>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                position: "relative",
                width: "250px",
                paddingLeft: "20px",
                height: "50px",
              }}
            >
              <input
                placeholder="Search Works"
                style={{
                  paddingLeft: "20px",
                  border: "1px solid var(--primary-color)",
                  width: "250px",
                  height: "40px",
                }}
              />
              <Icon.Search
                style={{
                  position: "absolute",
                  right: "-3px",
                  top: "40%",
                  transform: "translateY(-50%)",
                  color: "#ACACAC",
                }}
              />
            </div>
            
            <Button
              style={{
                height: "40px",
                marginLeft: "40px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "var(--primary-color)",
                border: "1px solid var(--primary-color)",
                borderRadius: "0px",
              }}
              onClick={()=>handleClickOpen()}
            >
              <Icon.PlusCircle size={20} style={{ marginRight: "5px" }} />{" "}
              Add New
            </Button>
            
          </div>
        </div>
        <div>
          
          {currentView === "temporarywork" ? (
                <DrawingTableWrapper
                  tableHead={
                    <tr>
                      <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                      <th style={{ width: "10%" }}>Employee On Leave</th>
                      <th style={{ width: "14%" }}>From Date</th>
                      <th style={{ width: "13%" }}>To Date</th>
                      <th style={{ width: "10%" }}>Assigned To</th>
                      <th style={{ width: "7%" }}>Action</th>
                    </tr>
                  }
                  tableBody={
                    <tbody>
                        <tr>
                        <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        </tbody>
                  }
                />
              ) : (
                <> <DrawingTableWrapper
                tableHead={
                  <tr>
                    <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{ width: "10%" }}>Resign Employee</th>
                    <th style={{ width: "14%" }}>Date</th>
                    <th style={{ width: "13%" }}>Name</th>
                    <th style={{ width: "7%" }}>Action</th>
                  </tr>
                }
                tableBody={
                  <tbody>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      </tbody>
                }
              />
                </>
              )}
        </div>
      </>
    }
    />
    <Dialog
        open={openAddNewDialog}
        onClose={handleAddNewDialogClose}
        maxWidth="xs"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 180px", textAlign: "center", color: "white" }}
          >
            Select 
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleAddNewDialogClose}
          />
        </div>
        <DialogContent>
          <div>
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
        <p className={Styles.question}>Select Temporary/Permanent </p>
        <div className={Styles.buttons}>
        <button className={Styles.submitButton}  style={{width:"max-content"}} onClick={handlePermanent}>Permanent</button>
        <button className={Styles.submitButton} style={{width:"max-content"}}  onClick={handleTemporary}>Temporary</button>
        </div>
        </div>
        </DialogContent>
    </Dialog>
    {showPermanentForm && <TransferPermanentWork showPermanentForm={showPermanentForm} handleClose={handleClose} currentView={currentView}/>}
    {showTemporaryForm && <TransferTemporaryWork showTemporaryForm={showTemporaryForm} handleClose={handleClose} currentView={currentView}/>}

</>
);
};

export default TransferWork;
