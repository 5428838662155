import React from "react";
import PagesHeroSection from "../Components/constants-comp/pagesHerosection";
import Header from "../Components/Navbar/Header";
import FloatingImageContainer from "../Widgets/FloatingImageContainer";

function WhyUs({ imageSrc, altText, text ,fromAboutus, isPhone, ShowBtn, heading, display, top}) {
  return (
    <>
      <Header />
      <PagesHeroSection
        imageUrl={
          "https://cdn.builder.io/api/v1/image/assets/TEMP/4e265835733c8d6a6eea6998a16b66ad872c731fcb9c8299c01f76cb0d5bfde9?apiKey=3e99c58a56f84e4cb0d84873c390b13e&&apiKey=3e99c58a56f84e4cb0d84873c390b13e"
        }
        title={"Why us"}
      />
      <FloatingImageContainer top={top} title={"Why Us"} description={`
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Etiam pretium purus at blandit consequat. Suspendisse
          vitae orci diam. Fusce quis sem nec nisi imperdiet
          rhoncus nec id dui. Curabitur bibendum tortor et metus
          sodales, nec hendrerit orci tristique.Donec et posuere
          arcu, et blandit est. Pellentesque non pulvinar dui. Nullam
          lacinia lacinia sapien, efficitur congue massa tempus ut.
        `}/>
      <div className="container">
        <div className="row">
          <div className=" col-12 text-center mb-4">
            <h1>Lorem ipsum dolor sit</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
              pretium purus at blandit consequat. Suspendisse vitae orci diam.
              Fusce quis sem nec nisi imperdiet rhoncus nec id dui. Curabitur
              bibendum tortor et metus sodales, nec hendrerit orci tristique.
              Donec et posuere arcu, et blandit est.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center mb-4">
            <h1>Lorem ipsum dolor sit</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
              pretium purus at blandit consequat. Suspendisse vitae orci diam.
              Fusce quis sem nec nisi imperdiet rhoncus nec id dui. Curabitur
              bibendum tortor et metus sodales, nec hendrerit orci tristique.
              Donec et posuere arcu, et blandit est.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center mb-4">
            <h1>Lorem ipsum dolor sit</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
              pretium purus at blandit consequat. Suspendisse vitae orci diam.
              Fusce quis sem nec nisi imperdiet rhoncus nec id dui. Curabitur
              bibendum tortor et metus sodales, nec hendrerit orci tristique.
              Donec et posuere arcu, et blandit est.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyUs;
