import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BACKEND_URL } from "../../../Utilities/Constants";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import "./FolderDetailsDialog.css";
import { WebApimanager } from "../../../WebApiManager";

const FolderFilesViewDialog = ({ folder, open, onClose, onFolderDelete }) => {
  const webApi = new WebApimanager();

  const handleDeleteFile = async (fileId, fNo) => {
    try {
      await webApi.delete(`api/isCode/deleteFile/${fileId}/${fNo}`);
      console.log("File deleted successfully");
      if (onFolderDelete) onFolderDelete();
    } catch (error) {
      console.error(
        "Error deleting file:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleBackClick = () => {
    onClose();
  };

  if (!open) return null;

  return (
    <div className="modal show d-block" tabIndex="-1">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              backgroundColor: "#303030",
              height: "50px",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 15px",
            }}
          >
            <p className="modal-title editFolderTitle">{folder.fName}</p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p
                onClick={handleBackClick}
                style={{ marginLeft: "30px", cursor: "pointer" }}
              >
                <IoMdArrowRoundBack />
              </p>
            </div>
            <div>
              {folder.fImage ? (
                <div>
                  <img
                    src={`${BACKEND_URL}${folder.fImage}`}
                    alt="Folder"
                    style={{
                      width: "70px",
                      height: "70px",
                      marginRight: "15px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      marginTop: "20px",
                    }}
                  />
                </div>
              ) : (
                <p>No image available for this folder</p>
              )}
            </div>
            <div></div>
          </div>

          <div>
            <div>
              <p>{folder.fName}</p>
            </div>
            <p className="viewChapters">{folder.files.length} Chapters</p>

            <div className="viewDetailsgrids">
              <div>
                <p className="viewfileLable">Folder created By</p>
              </div>
              <div>
                <span>:</span>
              </div>
              <div className="viewfiledata">
                <p
                  style={{
                    display: "inline",
                    marginRight: "5px",
                    margin: 0,
                  }}
                >
                  {folder.folderCreatedBy.firstName}
                </p>
                <p style={{ margin: 0, display: "inline" }}>
                  {folder.folderCreatedBy.lastName}
                </p>
              </div>
              <div>
                <p className="viewfileLable">Folder created Date</p>
              </div>
              <div>
                <span>:</span>
              </div>
              <div>
                <p className="viewfiledata">
                  {new Date(folder.folderCreatedDate).toLocaleDateString(
                    "en-GB",
                    {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }
                  )}
                </p>
              </div>
            </div>
          </div>

          <div
            className="modal-body"
            style={{
              padding: "15px, 20px",
              width: "95%",
              margin: "0 auto",
            }}
          >
            <table className="table">
              <thead className="tableThead">
                <tr>
                  <th>S No</th>
                  <th>File Name</th>
                  <th>Uploaded By</th>
                  <th>Uploaded Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {folder.files.length > 0 ? (
                  folder.files.map((file, index) => (
                    <tr key={file._id}>
                      <td>{(index + 1).toString().padStart(3, "0")}</td>
                      <td>{file.fileName}</td>
                      <td>
                        {file.fileCreatedBy.firstName}
                        {file.fileCreatedBy.lastName}
                      </td>
                      <td>
                        {new Date(file.fileCreatedDate).toLocaleDateString()}
                      </td>
                      <td>
                        <button
                          onClick={() => handleDeleteFile(folder._id, file.fNo)}
                          className="me-2"
                        >
                          <MdDelete />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No files found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FolderFilesViewDialog;
