import React from "react";
const CsvIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="25" viewBox="0 0 21 25" fill="none">
    <path d="M0 21.5513C0 22.2655 0.578879 22.8444 1.2931 22.8444H3.01724V19.8271H0.862069C0.862069 19.8271 0 19.8271 0 21.5513Z" fill="#5AA064"/>
    <path d="M20.6894 4.74138V23.2759C20.6894 24.228 19.9174 25 18.9652 25H4.31008C3.35792 25 2.58594 24.228 2.58594 23.2759V1.72414C2.58594 0.771983 3.35792 0 4.31008 0H15.948L20.6894 4.74138Z" fill="#E8ECEF"/>
    <path d="M20.6906 4.74138H16.3803C16.1432 4.74138 15.9492 4.54741 15.9492 4.31034V0L20.6906 4.74138Z" fill="#C7D0DD"/>
    <path d="M1.2931 20.2586H16.8103C17.5246 20.2586 18.1034 19.6798 18.1034 18.9655V12.931C18.1034 12.2168 17.5246 11.6379 16.8103 11.6379H1.2931C0.578879 11.6379 0 12.2168 0 12.931V21.5517C0 20.8375 0.578879 20.2586 1.2931 20.2586Z" fill="#61C161"/>
    <path d="M14.7797 13.3802C14.5504 13.3129 14.3111 13.4414 14.243 13.6694L13.3629 16.6034L12.4827 13.6694C12.4142 13.4414 12.1749 13.3129 11.9461 13.3802C11.718 13.4487 11.5887 13.6892 11.6568 13.9168L12.9499 18.2271C13.0047 18.4095 13.1728 18.5345 13.3629 18.5345C13.553 18.5345 13.7211 18.4095 13.7758 18.2271L15.0689 13.9168C15.137 13.6892 15.0077 13.4487 14.7797 13.3802Z" fill="white"/>
    <path d="M4.74172 14.2244C5.21715 14.2244 5.60379 14.611 5.60379 15.0864C5.60379 15.3248 5.79646 15.5175 6.03482 15.5175C6.27318 15.5175 6.46585 15.3248 6.46585 15.0864C6.46585 14.1356 5.69258 13.3623 4.74172 13.3623C3.79085 13.3623 3.01758 14.1356 3.01758 15.0864V16.8106C3.01758 17.7614 3.79085 18.5347 4.74172 18.5347C5.69258 18.5347 6.46585 17.7614 6.46585 16.8106C6.46585 16.5722 6.27318 16.3795 6.03482 16.3795C5.79646 16.3795 5.60379 16.5722 5.60379 16.8106C5.60379 17.286 5.21715 17.6726 4.74172 17.6726C4.26628 17.6726 3.87965 17.286 3.87965 16.8106V15.0864C3.87965 14.611 4.26628 14.2244 4.74172 14.2244Z" fill="white"/>
    <path d="M9.15657 15.53C8.40787 15.3425 8.19019 15.2334 8.19019 14.8705C8.19019 14.5201 8.58502 14.2239 9.05226 14.2239C9.66218 14.2239 9.95312 14.7002 9.97037 14.73C10.0872 14.9356 10.3471 15.0093 10.5544 14.8942C10.7622 14.7787 10.8376 14.5166 10.7221 14.3084C10.7005 14.2696 10.1842 13.3623 9.05226 13.3623C8.1014 13.3623 7.32812 14.039 7.32812 14.8709C7.32812 15.9619 8.30269 16.2054 8.94795 16.3666C9.69666 16.5541 9.91433 16.6632 9.91433 17.0261C9.91433 17.3765 9.5195 17.6726 9.05226 17.6726C8.43675 17.6726 8.1458 17.1869 8.13588 17.1701C8.02037 16.9623 7.75916 16.8864 7.55011 17.0024C7.34235 17.1179 7.26692 17.38 7.38244 17.5882C7.40399 17.627 7.92037 18.5343 9.05226 18.5343C10.0031 18.5343 10.7764 17.8576 10.7764 17.0257C10.7764 15.9347 9.80183 15.6912 9.15657 15.53Z" fill="white"/>
  </svg>
  );
};
export default CsvIcon;