// SiteheadReceivedTable.js
import React, { useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import * as Icon from "react-bootstrap-icons";
import DrawingTableWrapper from "../../Components/DrawingTableWrapper";
import Eye from "../IconsSiteHead/Eye.svg";
import Download from "../IconsSiteHead/Download.svg";
import pen from "../IconsSiteHead/penIcon.svg";
import { WebApimanager } from "../../../../WebApiManager";
import { siteId, userInfo } from "../../../../atoms";
import { useRecoilState } from "recoil";
import ViewDrawingModal from "./ViewDrawingModal";
import RFIPopUp from "./RFIPopUp";
import { FaGalacticSenate } from "react-icons/fa6";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { Tab1, Tabs1 } from "../../../Tabs/Tabv2";
import { RiShareForwardLine } from "react-icons/ri";
import { calculateDueDays, formatDate, todayDate } from "../../../../Utilities/DateUtils";
import { processFile } from "../../BackendUtils";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
const SiteheadReceivedTable = ({ searchTerm,forwardAccess,rfiRaisedAccess }) => {

  const webApi = new WebApimanager();
  const [issess, setIssuss] = useState([]);
  const [site] = useRecoilState(siteId);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [editDialogOpen,setEditDialogOpen] = useState(false)
  const [editData, setEditData] = useState({});
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const navigate = useNavigate();
  const userDetails = useRecoilState(userInfo);
  const companyID = userDetails[0]?.companyId;
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  useEffect(() => {
    const fetchIssuedData = async () => {
      try {
        const response = await webApi.get(
          `api/architect/drawingSiteHead/${site}?filterType=issued`
        );
        console.log("Issued Data:", response.data);
        setIssuss(response.data.data);
      } catch (error) {
        console.error("Error fetching issued data:", error);
      }
    };

    fetchIssuedData();
  }, [site]);
  const handleClose = () => {
    setEditDialogOpen(false)
  };
  const filteredData = issess.filter((item) => {
    return (
      item.drawingNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.drawingTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item?.designDrawingConsultant?.role
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item?.category?.category?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleViewOpen = (drawingItem) => {
    if (drawingItem.acceptedRORevisions?.length > 0) {
      setSelectedTab(drawingItem.acceptedRORevisions?.length - 1);
    }

    setViewDialogOpen(true);
    setOpen(false);
    setEditDialogOpen(false);
    setSingleDrawingData(drawingItem);
  };

  const handleViewClose = () => {
    setViewDialogOpen(false);
    setSelectedDrawing(null);
  };
  const handleEditOpen = (drawingDatas) => { 
    setEditData(drawingDatas)
      setEditDialogOpen(true);
      setViewDialogOpen(false);
    }
    const handleDownload = async (id, revision) => {
      setApiProcessing({
        loader: true,
        message: "Downloading...",
      });
      try {
        // Request the file with binary response handling
        const response = await webApi.getImage(
          `api/architectureToRoRegister/${id}/revisionRo/${revision}`,
          {
            responseType: "arraybuffer", // Expect binary data
          }
        );

        processFile(response, "download", navigate); // Process file for downloading
      } catch (error) {
        console.error("Error downloading file:", error);
      } finally {
        setApiProcessing({ loader: false, message: "" });
      }
    };
    const handleViewFile = async (id, revision) => {
      setApiProcessing({
        loader: true,
        message: "opening...",
      });
      try {
        // Make the request to get the response based on the updated endpoint
        const response = await webApi.getImage(
          `api/architectureToRoRegister/${id}/revisionRo/${revision}`,
          {
            responseType: "arraybuffer", // Expect binary data
          }
        );
  
        processFile(response, "view", navigate); // Process file for downloading
      } catch (error) {
        console.error("Error downloading file:", error);
      } finally {
        setApiProcessing({ loader: false, message: "" });
      }
    };
    const handlePDFDownload = async (filename) => {
      console.log(filename, "fiename");
      setApiProcessing({
        loader: true,
        message: "Downloading...",
      });
      try {
        // Request the file with binary response handling
        //TODO: need to check this path correct or not
        const response = await webApi.getImage(
          `api/architectureToRoRegister/byPath/${companyID}/${site}/drawings/${filename}`,
          // `api/architectureToRoRequested/${id}?revision=${revision}`,
          {
            responseType: "arraybuffer", // Expect binary data
          }
        );
  
        processFile(response, "download", navigate); // Process file for viewing
      } catch (error) {
        console.error("Error downloading file:", error);
      } finally {
        setApiProcessing({ loader: false, message: "" });
      }
    };
  
    const handleViewPDFFile = async (filename) => {
      console.log(filename, "getting inside");
      try {
        setApiProcessing({ loader: true, message: "Viewing" });
  
        // Make the request without explicitly setting the responseType
        const response = await webApi.getImage(
          `api/architectureToRoRegister/byPath/${companyID}/${site}/drawings/${filename}`,
          {
            responseType: "arraybuffer", // Expect binary data
          }
        );
        console.log("Image fot APPROVED", response);
  
        processFile(response, "view", navigate); // Process file for viewing
      } catch (error) {
        console.error("Error viewing file:", error);
      } finally {
        setApiProcessing({ loader: false, message: "" });
      }
    };
    const handleForwardClick = async (singledrawingdata, revisionNo) => {
      console.log(singledrawingdata, revisionNo,"handleForwardClick")
      const registerId = singledrawingdata.drawingId;
      
      // Find the specific revision data in acceptedRORevisions
      const selectedRevision = singledrawingdata.acceptedRORevisions.find(
        (revision) => revision.revision ===`R${revisionNo}`
      );
      console.log(selectedRevision,"revision")
    
      if (!selectedRevision) {
        alert("Revision not found");
        return;
      }
    
      try {
        const ApprovalFormData = new FormData();
        ApprovalFormData.append("softCopySubmittedDate", todayDate());
        ApprovalFormData.append("changes", selectedRevision.changes || "");
        ApprovalFormData.append("issuedSoftCopy", "Yes");
        ApprovalFormData.append("roRef",selectedRevision.revision)
        ApprovalFormData.append("drawingFileName", selectedRevision.drawingFileName);
        ApprovalFormData.append("pdfDrawingFileName", selectedRevision.pdfDrawingFileName);
         ApprovalFormData.append("siteTositeType", "Forwarded");
        console.log("Submitting Approval data:", ApprovalFormData);
        const response = await webApi.imagePut(
          `api/architectureToRoRegister/revisions/${registerId}?revisionType=acceptedSiteHeadRevisions`,
          ApprovalFormData
        );
    
        if (response.status === 200) {
          setApiProcessing({ loader: false, message: "" });
          // handleClose();
          // window.location.reload();
        } else {
          console.error("Failed to submit the form:", response);
          alert("Failed to submit the form. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setApiProcessing({ loader: false, message: "" });
        alert("An error occurred. Please try again later.");
      }
    };
  return (
    <>
      <DrawingTableWrapper
        tableHead={
          <tr>
            <th style={{ width: "5%", padding: "15px" }}>S.No</th>
            <th style={{ width: "10%", padding: "15px" }}>DRWG No</th>
            <th style={{ width: "10%", padding: "15px" }}>Consultant</th>
            <th style={{ width: "5%", padding: "15px" }}>Folder</th>
            <th style={{ width: "10%", padding: "15px" }}>Drawing Category</th>
            <th style={{ width: "10%", padding: "15px" }}>Drawing Title</th>
            <th style={{ width: "13%", padding: "15px" }}>
              R0 Soft Copy Submission Date
            </th>
            <th style={{ width: "6%", padding: "15px" }}>Revision</th>
            <th style={{ width: "7%", padding: "15px" }}>Action</th>
          </tr>
        }
        tableBody={filteredData.map((item, index) => (
          <tr
            key={index}
            style={{
              backgroundColor: index % 2 === 0 ? "#FDF8F4" : "#F3F6F8",
            }}
          >
            <td style={{ padding: "15px" }}>{index + 1}</td>
            <td style={{ padding: "15px" }}>{item.drawingNo}</td>
            <td style={{ padding: "15px" }}>
              {item?.designDrawingConsultant?.role}
            </td>
            <td>{item.folderId?.folderName || "NA"}</td>
            <td style={{ padding: "15px" }}>{item?.category?.category}</td>
            <td style={{ width: "9%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",cursor:'default' }}>
  <span 
    title={item.drawingTitle}
    style={{
      display: "inline-block",
      maxWidth: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }}
  >
    {item.drawingTitle.split(" ").slice(0, 2).join(" ")}...
  </span>
  </td>
            <td style={{ padding: "15px" }}>
              {item.acceptedRORevisions && item.acceptedRORevisions.length > 0
                ? new Date(
                    item.acceptedRORevisions[
                      item.acceptedRORevisions.length - 1
                    ].softCopySubmittedDate
                  ).toLocaleDateString()
                : "N/A"}
            </td>
            <td style={{ padding: "15px" }}>
              {item.acceptedRORevisions && item.acceptedRORevisions.length > 0
                ? item.acceptedRORevisions
                    .slice(-2)
                    .map((rev) => rev.revision)
                    .join(", ")
                : "N/A"}
            </td>
            <td
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                padding: "15px",
              }}
            >
              {rfiRaisedAccess &&
              <img src={pen} alt="edit"
              onClick={() => handleEditOpen(item)}
               />
              }
              <img
                src={Eye}
                alt="view"
                onClick={() => handleViewOpen(item)}
                style={{ cursor: "pointer" }}
              />

              <img src={Download} alt="download" style={{ cursor: "pointer" }}
                 onClick={() => {
                  const latestRevision =
                   item.acceptedRORevisions.length > 0
                      ? item.acceptedRORevisions[
                          item.acceptedRORevisions
                            .length - 1
                        ].revision
                      : null;
                  handleDownload(
                    item.drawingId,
                    latestRevision
                  );
                }}/>
            </td>
          </tr>
        ))}
      />

      {/* <ViewDrawingModal
        open={viewDialogOpen}
        onClose={handleViewClose}
        drawingData={selectedDrawing}
        forwardAccess={forwardAccess}
      /> */}
     <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 10,
            fontFamily: "Poppins",
            background: "#FFF",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {singleDrawingData?.acceptedRORevisions?.length > 0 ? (
              <Tabs1 value={selectedTab} onChange={handleTabChange}>
                {singleDrawingData.acceptedRORevisions.map(
                  (revisionItem, index) => (
                    <Tab1
                      key={index}
                      value={index}
                      label={`${revisionItem.revision}`}
                      style={{
                        backgroundColor:
                          singleDrawingData.acceptedRORevisions
                            .length === 1 || selectedTab === index
                            ? "var(--primary-color)"
                            : "transparent",
                        color:
                          singleDrawingData.acceptedRORevisions
                            .length === 1 || selectedTab === index
                            ? "white"
                            : "var(--primary-color)",
                        width: "150px",
                        height: "35px",
                        cursor: "pointer",
                        borderRight:
                          index <
                          singleDrawingData.acceptedRORevisions.length -
                            1
                            ? "1px solid var(--primary-color)"
                            : "none",
                        // borderRadius: index === 0 && !isSingleRevision ? '5px 0 0 5px' : (index === singleDrawingData.acceptedRORevisions.length - 1 && !isSingleRevision ? '0 5px 5px 0' : 'none'),
                      }}
                    />
                  )
                )}
              </Tabs1>
            ) : (
              <div>No Revisions</div>
            )}
          </div>
        </div>
        {singleDrawingData?.acceptedRORevisions?.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {selectedTab ===
                  singleDrawingData.acceptedRORevisions.length - 1 ? (
                    <div style={{ color: "green" }}>Good For Construction</div>
                  ) : (
                    <div style={{ color: "red" }}>Superseeded</div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",
                justifyContent: "center",
                marginLeft: "80px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.designDrawingConsultant?.role || "NA"}
              </div>
              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>
              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>
              <div>Folder</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.folderId?.folderName || "-"}
              </div>
              <div>Submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.acceptedSiteSubmissionDate
                  ? new Date(singleDrawingData.acceptedSiteSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              {singleDrawingData?.acceptedRORevisions?.length > 0 && (
            <>
              {singleDrawingData.acceptedRORevisions[selectedTab] && (
                <>
                  <div>Soft Copy Submitted Date</div>
                  <div>:</div>
                  <div style={{ marginLeft: "-120px" }}>
                    {singleDrawingData.acceptedRORevisions[selectedTab]
                      .softCopySubmittedDate
                      ? formatDate(
                          singleDrawingData.acceptedRORevisions[
                            selectedTab
                          ].softCopySubmittedDate
                        )
                      : "No Submitted Date"}
                  </div>
                </>
              )}
            </>
          )}
        
    

        {!singleDrawingData?.isSuperseded && forwardAccess && (
    <>
        <div>Issued Soft Copy</div>
        <div>:</div>
        <div style={{ marginLeft: "-130px" }}>
          <button
            style={{
              display: "flex",
              backgroundColor: "#F3F3F3",
              border: "1px solid #97979740",
              color: "#4d4d4d",
              padding: "6px 10px",
              alignItems: "center",
              gap: "10px",
            }}
            onClick={()=>handleForwardClick(singleDrawingData,singleDrawingData.acceptedRORevisions?.length - 1)}
          >
            Forwarded <RiShareForwardLine />
          </button>
        </div>
        </>
   )}
              {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedRORevisions[
                    selectedTab
                  ] && (
                    <> 
                      <div>Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-130px" }}>
                        {singleDrawingData.acceptedRORevisions[
                          selectedTab
                        ].revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}
         
             {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <>
                  <div>Drawing File</div>
                  <div>:</div>
                  <div style={{ display: "flex", marginLeft: "-130px" }}>
                    <Icon.Image
                      size={20}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() =>
                        handleViewFile(
                          singleDrawingData.drawingId,
                          singleDrawingData.acceptedRORevisions[0]
                            ?.revision
                        )
                      }
                    />
                    <Icon.Download
                      size={20}
                      style={{ marginRight: "5px", cursor: "pointer" }}
                      onClick={() =>
                        handleDownload(
                          singleDrawingData.drawingId,
                          singleDrawingData.acceptedRORevisions[0]
                            ?.revision
                        )
                      }
                    />
                  </div>
                </>
              )}
{singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedRORevisions[selectedTab] && (
                    <>
                      <div>PDF File</div>
                      <div>:</div>
                      <div style={{ display: "flex", marginLeft: "-120px" }}>
                        <Icon.Image
                          size={20}
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewPDFFile(
                              singleDrawingData.acceptedRORevisions[selectedTab]
                                ?.pdfDrawingFileName
                            )
                          }
                        />
                        <Icon.Download
                          size={20}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handlePDFDownload(
                              singleDrawingData.acceptedArchitectRevisionss[selectedTab]
                                ?.pdfDrawingFileName
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>

      {editDialogOpen && <RFIPopUp
        open={editDialogOpen}
        onClose={handleClose}
        tableRowData={editData}
        apiData={issess}
        filter = "raised"
      />}
    </>
  );
};

export default SiteheadReceivedTable;