import React from "react";
import AddnewPnm from "../AddnewPnm/AddnewPnm";
import Assetcode from "../AssetCode/Assetcode";
import { optionsTab } from "../../../atoms";
import { useRecoilState } from "recoil";
const Options = () => {
  

const [value, setValue] = useRecoilState(optionsTab);
const handleChange = (newValue) => {
  setValue(newValue);
};
const allTabs = [
  { label: "Asset Code", index: 0, component: <Assetcode/> },
  { label: "Add P&M", index: 1, component:   <AddnewPnm/> },
];

return (
  <>
    <div className="containerDrawingPagesRotoSite">
      <ul className="tabsRotoSite" role="tablist">
        {allTabs.map((tab) => (
          <li
            key={tab.index}
            className={`tabRotoSite ${value === tab.index ? "active" : ""}`}
            role="tab"
            aria-selected={value === tab.index}
            aria-controls={`tabpanel-${tab.index}`}
            id={`tab-${tab.index}`}
  
            onClick={() => handleChange(tab.index)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
    </div>

    {allTabs.map((tab) => (
      <TabPanel key={tab.index} value={value} index={tab.index}>
        {tab.component}
      </TabPanel>
    ))}
  </>
  
);
};
function TabPanel(props) {
const { children, value, index, ...other } = props;

return (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
    className="tabpanel"
  >
    {value === index && <div className="tabpanel-content">{children}</div>}
  </div>
);
}
export default Options;
