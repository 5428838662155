import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../WebApiManager";
import { siteId, userInfo } from "../../atoms";
import { useRecoilValue, useRecoilState } from "recoil";
import swal from "sweetalert";
import CreateNotification from "./CreateNotification";
import { Loader } from "../../Widgets/notificationFeedbacks";
import SelectSite from "../../Utilities/SelectSite";
import * as Icon from "react-bootstrap-icons";
import { MdAccessTime } from "react-icons/md";
import { CiStar } from "react-icons/ci";
import DraftNotifications from "./DraftNotifications";
import StarredNotifications from "./StarredNotifications";
import TrashNotifications from "./TrashNotifications";
import mailComposeIcon from "../../Images/mailComposeIcon.svg";
import DateTimeModal from "./DateTimeModal";

const Notifications = () => {
  const webApi = new WebApimanager();
  const currUserInfo = useRecoilValue(userInfo);
  const [receivedNotifications, setReceivedNotifications] = useState([]);
  const [sentNotifications, setSentNotifications] = useState([]);
  const [openNotifForm, setOpenNotifForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("received");
  const [site, setSite] = useRecoilState(siteId);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDateTimeModal, setShowDateTimeModal] = useState(false);

  const notifTabs = [
    { label: "Received", nickName: "received" },
    { label: "Sent", nickName: "sent" },
    { label: "Draft", nickName: "draft" },
    { label: "Starred", nickName: "starred" },
    { label: "Trash", nickName: "trash" },
  ];

  useEffect(() => {
    if (activeMenuItem === "received") {
      getNotifications();
    } else if (activeMenuItem === "sent") {
      getSentNotifications();
    }
  }, [activeMenuItem]);

  const getNotifications = () => {
    setIsLoading(true);
    webApi
      .get(`api/mail?type=received`)
      .then((res) => {
        setIsLoading(false);
        console.log("Received Notifications Response:", res.data);
        if (res.data.status === "success") {
          const receivedMail = res.data.data.receivedMail || [];
          setReceivedNotifications(receivedMail);
        } else {
          console.error("Failure", res.data.errormessage || "Unexpected error");
        }
      })
      .catch(handleApiError);
  };

  const getSentNotifications = () => {
    setIsLoading(true);
    webApi
      .get(`api/mail?type=sent`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === "success") {
          const sentMail = res.data.data.sentMail || [];
          setSentNotifications(sentMail);
        } else {
          console.error("Failure", res.data.errormessage || "Unexpected error");
        }
      })
      .catch(handleApiError);
  };

  const handleApiError = (error) => {
    const errorMessage =
      error.customErrorMessage ||
      "Oops! Unable to get the Notifications, Please try again later.";
    swal("Error", errorMessage, "error");
  };

  const deleteNotifications = (notif) => {
    console.log("Deleting notification:", notif);
    swal({
      title: "Are you sure?",
      text: "You want to delete this Notification!",
      icon: "warning",
      buttons: ["Cancel", "Yes, delete it!"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        webApi
          .delete(`api/mail/delete/${notif._id}`)
          .then((res) => {
            if (res.status === 204) {
              activeMenuItem === "received"
                ? getNotifications()
                : getSentNotifications();
              swal("Success", "Notification deleted successfully", "success");
            } else {
              console.error(
                "Delete Error:",
                res.data.errormessage || "Unexpected error during deletion"
              );
              swal(
                "Failure",
                res.data.errormessage || "Unexpected error during deletion",
                "error"
              );
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            swal(
              "Failure",
              "Unexpected error occurred during deletion",
              "error"
            );
          });
      }
    });
  };

  const handleTabClick = (e, tabNickName) => {
    e.preventDefault();
    setActiveMenuItem(tabNickName);
    setSearchTerm("");
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredReceivedNotifications = receivedNotifications.filter((row) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      row.subject?.toLowerCase().includes(lowerCaseSearchTerm) ||
      row.message?.toLowerCase().includes(lowerCaseSearchTerm) ||
      `${row.sentBy?.firstName?.toLowerCase()} ${row.sentBy?.lastName?.toLowerCase()}`.includes(
        lowerCaseSearchTerm
      )
    );
  });

  const filteredSentNotifications = sentNotifications.filter((row) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      row.subject?.toLowerCase().includes(lowerCaseSearchTerm) ||
      row.message?.toLowerCase().includes(lowerCaseSearchTerm) ||
      `${row.sentTo?.firstName?.toLowerCase()} ${row.sentTo?.lastName?.toLowerCase()}`.includes(
        lowerCaseSearchTerm
      )
    );
  });

  return (
    <>
      {site === "" ? (
        <SelectSite />
      ) : (
        <>
          {openNotifForm && (
            <CreateNotification
              trigger={openNotifForm}
              setTrigger={setOpenNotifForm}
              getAllNotifs={
                activeMenuItem === "received"
                  ? getNotifications
                  : getSentNotifications
              }
            />
          )}

          <Loader open={isLoading} />

          <div className="m-[60px]">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 style={{ color: "var(--primary-color)", fontSize: "20px" }}>
                Mails
              </h3>
              <div style={{ display: "flex" }}>
                <input
                  placeholder="Search Notifications"
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{
                    paddingLeft: "20px",
                    borderRadius: "6px",
                    border: "1px solid var(--primary-color)",
                    width: "250px",
                    height: "40px",
                    marginRight: "10px",
                  }}
                />

                <button
                  label="Compose"
                  btnName="Compose"
                  onClick={() => setOpenNotifForm(true)}
                  style={{
                    padding: "10px 20px",
                    borderRadius: "6px",
                    backgroundColor: "#e47331",
                    color: "white",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "400",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    border: "none",
                  }}
                >
                  <img
                    src={mailComposeIcon}
                    style={{ marginRight: "8px", color: "white" }}
                  />
                  <span>Compose</span>
                </button>
              </div>
            </div>

            <div>
              <div style={{ display: "flex", marginTop: "20px" }}>
                {notifTabs.map((tab, index) => (
                  <button
                    key={index}
                    onClick={(e) => handleTabClick(e, tab.nickName)}
                    style={{
                      padding: "10px 20px",
                      fontWeight: "bold",
                      borderBottom:
                        activeMenuItem === tab.nickName
                          ? "2px solid #e47331"
                          : "none",
                    }}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>

              {activeMenuItem === "received" && (
                <table className="table-auto w-full mt-4">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Message</th>
                      <th>Time & Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredReceivedNotifications.map(
                      (recivedNotification, index) => (
                        <tr key={index}>
                          <td>
                            {recivedNotification.sentBy?.firstName}{" "}
                            {recivedNotification.sentBy?.lastName}
                          </td>
                          <td>
                            {recivedNotification.subject} &nbsp;
                            {recivedNotification.message}
                          </td>
                          <td>date</td>

                          <td style={{ display: "flex", gap: "15px" }}>
                            <CiStar size={20} style={{ cursor: "pointer" }} />
                            <MdAccessTime
                              size={20}
                              style={{ cursor: "pointer" }}
                              onClick={() => setShowDateTimeModal(true)}
                            />
                            <Icon.Trash
                              size={20}
                              onClick={() =>
                                deleteNotifications(recivedNotification)
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )}

              {activeMenuItem === "sent" && (
                <table className="table-auto w-full mt-4">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Message</th>
                      <th>Time & Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSentNotifications.map(
                      (sentNotification, index) => (
                        <tr key={index}>
                          <td>
                            {sentNotification.sentTo?.firstName} &nbsp;
                            {sentNotification.sentTo?.lastName}
                          </td>
                          <td>
                            {sentNotification.subject} &nbsp;
                            {sentNotification.message}
                          </td>
                          <td>Date</td>

                          <td style={{ display: "flex", gap: "15px" }}>
                            <CiStar size={20} />
                            <Icon.Trash
                              size={20}
                              onClick={() =>
                                deleteNotifications(sentNotification)
                              }
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )}

              {activeMenuItem === "draft" && <DraftNotifications />}
              {activeMenuItem === "starred" && <StarredNotifications />}
              {activeMenuItem === "trash" && <TrashNotifications />}
            </div>
          </div>

          <DateTimeModal
            show={showDateTimeModal}
            handleClose={() => setShowDateTimeModal(false)}
          />
        </>
      )}
    </>
  );
};

export default Notifications;
