// import React ,{useState}from 'react'
// import { Dialog, DialogContent } from "@mui/material";
// const RFIPopUp = (props) => {
//     const {open ,onClose,drawingData,filter} = props
//     console.log(props,"props rfi")
//     console.log(open ,onClose,drawingData,filter,"checking inside popup")
    
//     const handleEditClose = () => {
//        onclose();
//         setEditData({
//           drawingId: "",
//           drawingNumber: "",
//           expectedDate: "",
//           remarks: "",
//           drawingFileName: null,
//           revision: [],
//           natureofrequestedInfo: "",
//         });
//         setError({})
//       };
   
//   return (
//     <>
//       <Dialog
//         open={editDialogOpen}
//         onClose={handleEditClose}
//         maxWidth="md"
//         fullWidth
//         PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
//       >
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             backgroundColor: " #4D4D4D",
//             margin: "0px",
//             height: "50px",
//           }}
//         >
//           <p
//             style={{ margin: "5px 350px", textAlign: "center", color: "white" }}
//           >
//             RFI
//           </p>
//           <RxCross1
//             size={20}
//             style={{
//               marginLeft: "auto",
//               cursor: "pointer",
//               marginRight: "20px",
//               color: "white",
//             }}
//             onClick={handleEditClose}
//           />
//         </div>
//         <DialogContent
//           style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
//         >
//           <form onSubmit={handleEditSubmit}>
//             <Loader
//               open={apiProcessing.loader}
//               message={apiProcessing.message}
//             />
//             <div
//               style={{ display: "flex", flexDirection: "column", gap: "15px" }}
//             >
//               <div style={{ display: "flex", gap: "15px" }}>
//                 <div style={{ flex: 1 }}>
//                   <label
//                     htmlFor="drawingNumber"
//                     className="fs-10  text-start py-2"
//                   >
//                     Drawing Number <span style={{ color: "red" }}>*</span>
//                   </label>
//                   <input
//                     type="text"
//                     name="drawingNumber"
//                     placeholder="Enter your Drawing Number"
//                     className="form-control fs-12"
//                     style={{ width: "100%" }}
//                     value={editData.drawingNumber}
//                     onChange={handleInputChange}
//                     readOnly
//                   />
//                   {error.drawingNumber && (
//                     <div className="text-danger">{error.drawingNumber}</div>
//                   )}
//                 </div>
//                 <div style={{ flex: 1 }}>
//                   <label
//                     htmlFor="requestedDate"
//                     className="fs-10  text-start py-2"
//                   >
//                     Requested Date <span style={{ color: "red" }}>*</span>
//                   </label>
//                   <input
//                     type="text"
//                     name="expectedDate"
//                     className="form-control fs-12"
//                     style={{ width: "100%" }}
//                     value={editData.requestedDate}
//                     onChange={handleInputChange}
//                     readOnly
//                   />
//                   {error.requestedDate && (
//                     <div className="text-danger">{error.requestedDate}</div>
//                   )}
//                 </div>
//               </div>
//               <div style={{ display: "flex", gap: "15px" }}>
//                 <div style={{ flex: 1 }}>
//                   <label
//                     htmlFor="expectedDate"
//                     className="fs-10  text-start py-2"
//                   >
//                     Expected Date <span style={{ color: "red" }}>*</span>
//                   </label>
//                   <input
//                     type="date"
//                     name="expectedDate"
//                     className="form-control fs-12"
//                     style={{ width: "100%", height: "45px" }}
//                     value={editData.expectedDate}
//                     onChange={handleInputChange}
//                     min={tomorrowDateString}
//                   />
//                   {error.expectedDate && (
//                     <div className="text-danger">{error.expectedDate}</div>
//                   )}
//                 </div>
//                 <div style={{ flex: 1 }}>
//                   <label
//                     htmlFor="revision"
//                     className="fs-10  text-start py-2"
//                   >
//                     Revisions
//                     {/* <span style={{ color: 'red' }}>*</span> */}
//                   </label>
//                   <SearchDropdownIdVal
//                     placeholder={"Select Revision"}
//                     backgroundColor={"var(--table-evenrow-color)"}
//                     // Check if `editData.revision` is an array and get the latest revision
//                     options={
//                       Array.isArray(editData.revision) &&
//                       editData.revision.length > 0
//                         ? [
//                             {
//                               name: editData.revision.slice(-1)[0], // Get the latest revision
//                               value: editData.revision.slice(-1)[0], // Use the latest revision for value
//                             },
//                           ]
//                         : []
//                     }
//                     onOptionSelect={(option) =>
//                       handleRevisionSelection({
//                         target: {
//                           name: "selectedRevision",
//                           value: option.value,
//                         },
//                       })
//                     }
//                     className="form-control fs-12"
//                     style={{ width: "100%" }}
//                   />

                  

//                   {error.revision && (
//                     <p style={{ color: "red", fontSize: "12px" }}>
//                       {error.revision}
//                     </p>
//                   )}
//                 </div>
//               </div>
//               <div style={{ display: "flex", gap: "15px" }}>
//                 <div style={{ flex: 1 }}>
                  
//                   <MultiSelectSearch
//                     mandatory={true}
//                     heading={"Nature of Requested Information"}
//                     listItems={listItems}
//                     selectedIds={editData.natureofrequestedInfo || []} // Ensure this is an array
//                     setSelectedIds={(ids) =>
//                       setEditData({ ...editData, natureofrequestedInfo: ids })
//                     }
//                     handleSelectedPropertyOption={(data) => {
//                       if (editData.natureofrequestedInfo.includes(data.id)) {
//                         setEditData({
//                           ...editData,
//                           natureofrequestedInfo:
//                             editData.natureofrequestedInfo.filter(
//                               (id) => id !== data.id
//                             ),
//                         });
//                       } else {
//                         setEditData({
//                           ...editData,
//                           natureofrequestedInfo: [
//                             ...editData.natureofrequestedInfo,
//                             data.id,
//                           ],
//                         });
//                       }
//                     }}
//                     clearAll={() =>
//                       setEditData({ ...editData, natureofrequestedInfo: [] })
//                     }
//                     addAll={() =>
//                       setEditData({
//                         ...editData,
//                         natureofrequestedInfo: listItems.map((item) => item.id),
//                       })
//                     }
//                   />
//                   {error.natureofrequestedInfo && (
//                     <div className="text-danger">{error.natureofrequestedInfo}</div>
//                   )}

//                   {/* Display selected fields */}
//                   <div style={{ marginTop: "20px" }}>
//                     <p className="fs-10  text-start py-2">
//                       Selected Fields:
//                     </p>
//                     <ul>
//                       {editData.natureofrequestedInfo.length > 0 ? (
//                         editData.natureofrequestedInfo.map((id) => (
//                           <li key={id}>{getNameFromId(id)}</li>
//                         ))
//                       ) : (
//                         <li>No fields selected</li>
//                       )}
//                     </ul>
//                   </div>
//                 </div>
//               </div>

//               <div style={{ display: "flex", gap: "15px" }}>
//                 <div style={{ flex: 1 }}>
//                   <label
//                     htmlFor="remarks"
//                     className="fs-10  text-start py-2"
//                     style={{display:"flex",alignItems:"center"}}
//                   >
//                     Request Reason *<span style={{display:"flex",alignItems:"center"}}>&nbsp; &nbsp; &nbsp; <HiPlusCircle style={{marginLeft:"20px",color:"#e47331",fontSize:"24px"}} onClick={handleAddReason}/></span>
//                   </label>
//                   {requestReason.map((reqReason,reqIndex)=>(<div style={{width:"100%",display:"flex",alignItems:"center",position:"relative",marginTop:"30px"}}>
//                     <select style={{width:"15%",marginRight:"20px"}} name="NOR" value={reqReason.NOR} onChange={(e) => handleReasonChange(e, reqIndex)}>
//                       <option vlaue="">Select</option>
//                       {editData.natureofrequestedInfo.length && editData.natureofrequestedInfo.map((requestInfo,index)=>(<option key={index} value={requestInfo}>{requestInfo} </option>))}
//                     </select>

//                     <input type="text" style={{width:"82%"}} name="reason" value={reqReason.reason} onChange={(e) => handleReasonChange(e, reqIndex)}/>
//                     {reqIndex>0 ?<FaCircleMinus style={{fontSize:"24px",position:"absolute",right:"-21px"}} onClick={()=>handleRemoveReason(reqIndex)}/>:""}
//                     {error[`NOR_${reqIndex}`] ?(
//       <p style={{ color: "red", fontSize: "12px", position: "absolute", top: "50px" }}>
//         {error[`NOR_${reqIndex}`]}
//       </p>
//     ):error[`reason_${reqIndex}`] ? (
//       <p style={{ color: "red", fontSize: "12px", position: "absolute", top: "50px", right: "50px" }}>
//         {error[`reason_${reqIndex}`]}
//       </p>
//     ):""}
//                   </div>))}
                  
//                 </div>
//               </div>
//               <div>
//                 <label
//                   htmlFor="drawingFileName"
//                   className="fs-10  text-start py-2"
//                 >
//                   Upload Your File <span style={{ color: "red" }}>*</span>
//                 </label>
//                 <input
//                   type="file"
//                   accept=".pdf, .jpg, .jpeg, .png, .dwg"
//                   id="drawingFileName"
//                   name="drawingFileName"
//                   className="form-control fs-12"
//                   style={{ width: "100%", height: "43px" }}
                  
//                   onChange={(e) => {
//                     const file = e.target.files[0];
//                     if (file && file.name.endsWith(".dwg")) {
//                       handleInputChange(e);
//                     } else {
//                       alert("Please select a .dwg file.");
//                       e.target.value = null; // Reset the file input if the file is not valid
//                     }
//                   }}
//                 />

//                 {error.drawingFileName && (
//                   <div className="text-danger">{error.drawingFileName}</div>
//                 )}
//               </div>
//               {errorMessage && (
//                 <div style={{ color: "red", fontSize: "15px" }}>
//                   {errorMessage}
//                 </div>
//               )}
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   marginTop: "20px",
//                 }}
//               >
//                 <button
//                   type="submit"
//                   style={{
//                     background: "blue",
//                     color: "white",
//                     width: "100px",
//                     height: "35px",
//                     backgroundColor: "var(--primary-color)",
//                     color: "white",
//                   }}
//                 >
//                   Submit
//                 </button>
//               </div>
//             </div>
//           </form>
//         </DialogContent>
//       </Dialog>
//     </>
//   )
// }

// export default RFIPopUp

import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { useRecoilState } from "recoil";
import MultiSelectSearch from "../../../Utilities/MultiSelectSearch";
import { RxCross1 } from "react-icons/rx";
import { HiPlusCircle } from "react-icons/hi";
import { FaCircleMinus } from "react-icons/fa6";
import { todayDate } from "../../../../Utilities/DateUtils";
import SearchDropdownIdVal from "../../../../Widgets/SearchDropdownIdVal";
import { siteId } from "../../../../atoms";
import { Loader } from "../../../../Widgets/notificationFeedbacks";
import { WebApimanager } from "../../../../WebApiManager";

const RFIPopUp = ({open, onClose, tableRowData,apiData=[],filter}) => {
    console.log(open, tableRowData,apiData,filter,"props fdata")

    const formatDate = (dateString) => {
        if (!dateString) return "-";
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        });
      };
    
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowDateString = tomorrow.toISOString().split("T")[0];
    console.log(tableRowData,"tableRowData in RFI Edit open")
    console.log(tableRowData?.acceptedRORevisions.slice(-1)?.revision,"tableRowData?.acceptedRORevisions.slice(-1)?.revision")
    let webApi = new WebApimanager();
    // const [editDialogOpen, setEditDialogOpen] = useState(open);
    const [editData, setEditData] = useState({
        drawingId: "",
        drawingNumber: "",
        expectedDate: "",
        requestedDate: "",
        drawingFileName: null,
        revision: "",
        natureofrequestedInfo: "",
      });
    const [requestReason,setRequestReason] = useState([{NOR:"",reason:""}])
    const [error, setError] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [apiProcessing, setApiProcessing] = useState({
        loader: false,
        message: "Loading...",
        });
    const [site, setSite] = useRecoilState(siteId);
    const [selectedRevision, setSelectedRevision] = useState("");

    const handleRevisionSelection = (event) => {
        setSelectedRevision(event.target.value);
      };
    
      const handleAddReason =()=>{
        setRequestReason([...requestReason, { NOR: "", reason: "" }]);
      }
    
      const handleRemoveReason = (indexToRemove) =>{
        const updatedReasons = requestReason.filter((_, index) => index !== indexToRemove);
      setRequestReason(updatedReasons);
      }
    
      const handleEditClose = () => {
        setEditData({
          drawingId: "",
          drawingNumber: "",
          expectedDate: "",
          remarks: "",
          drawingFileName: null,
          revision: "",
          natureofrequestedInfo: [],
        });
        onClose()
      };

      const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === "file") {
          setEditData({
            ...editData,
            [name]: files[0], // Assuming you are only uploading one file
          });
        } else {
          setEditData({
            ...editData,
            [name]: value,
          });
        }
      };
      const handleEditSubmit = async (e) => {
        e.preventDefault();
    
        let formErrors = {};
        if (!editData.drawingNumber)
          formErrors.drawingNumber = "Drawing Number is required";
        if (!editData.expectedDate)
          formErrors.expectedDate = "Expected Date is required";
        
        if (!editData.drawingFileName) {
          formErrors.drawingFileName = "Drawing File is required";
        }
        if (!editData.natureofrequestedInfo)
          formErrors.natureofrequestedInfo = "this field is requires";
        // if (!editData.revision) formErrors.revision = 'Please select a revision.';
        requestReason.forEach((reason, index) => {
          if (!reason.NOR) {
            formErrors[`NOR_${index}`] = "Nature of Request is required.";
          }
          if (!reason.reason) {
            formErrors[`reason_${index}`] = "Reason is required.";
          }
        });
    
        setError(formErrors);
    
        if (Object.keys(formErrors).length > 0) {
          return;
        }
    
        setApiProcessing({
          loader: true,
          message: "Requesting...",
        });
        try {
          const editFormData = {
            drawingId: editData.drawingId,
            drawingNo: editData.drawingNumber,
            siteId: site,
            requestedDate: todayDate(),
            expectedDate: editData.expectedDate,
            revision: selectedRevision,
            natureOfRequestedInformation: editData.natureofrequestedInfo,
            natureOfRequestedInformationReasons: editData.natureofrequestedInfo.map(
              (info) => ({
                natureOfRequest: info,
                reason: requestReason
                  .filter((req) => req.NOR === info)
                  .map((req) => req.reason),
              })
            ),
          };
    
          console.log("Submitting edit data:", editFormData);
    
          const response = await webApi.post(
            "api/roToSiteLevelRequested/postRequest",
            editFormData
          );
    
          if (response.status === 200) {
            const responseData = response.data;
            if (responseData.status === "error") {
              // Handle error from response data
              setErrorMessage(responseData.message);
              console.log("Response data:", responseData.message);
              setApiProcessing({ loader: false, message: "" });
              return; // Stop further processing
            }
    
            const id = response.data.data._id;
    
            if (editData.drawingFileName) {
              const drawingFileName = new FormData();
              drawingFileName.append("drawingFileName", editData.drawingFileName);
              const uploadResponse = await webApi.imagePut(
                `api/roToSiteLevelRequested/postRequest/${id}`,
                drawingFileName
              );
              console.log("Upload response:", uploadResponse);
            }
            setApiProcessing({ loader: false, message: "" });
            handleEditClose();
            // fetchData();
          } else if (response.status === 400) {
            if (response.data && response.data.message) {
              setErrorMessage(response.data.message);
              console.log("Response data:", response.data.message);
            }
            setApiProcessing({ loader: false, message: "" });
          } else {
            setErrorMessage("An unexpected error occurred.");
            setApiProcessing({ loader: false, message: "" });
          }
        } catch (error) {
          setApiProcessing({ loader: false, message: "" });
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setErrorMessage(error.response.data.message);
          } else {
            setErrorMessage("Failed to submit data.");
          }
        }
      };

      const listItems = [
        { id: "A - Missing Information", name: "A - Missing Information" },
        { id: "B - Clarification", name: "B - Clarification" },
        { id: "C - Additional Information", name: "C - Additional Information" },
        { id: "D - Uncoordinated Drawings", name: "D - Uncoordinated Drawings" },
      ];
    
      // Function to get the name from id
      const getNameFromId = (id) => {
        const item = listItems.find((item) => item.id === id);
        return item ? item.name : "";
      };

      const handleReasonChange = (e, index) => {
        console.log("jksdnfks")
        const { name, value } = e.target;
        const updatedReasons = [...requestReason];
      
        // Update the specific reason or NOR based on name
        updatedReasons[index] = {
          ...updatedReasons[index],
          [name]: value,
        };
      
        // Set the updated requestReason state
        setRequestReason(updatedReasons);
      };

      useEffect(()=>{
        if(Object.keys(tableRowData).length){
            const drawingItem = apiData?.find((item) => item.drawingId === tableRowData.drawingId);
            console.log(drawingItem,"checking loop")
            if (drawingItem) {
                // Check if acceptedSiteRevisions is defined and is an array
                const combinedRevisions = Array.isArray(drawingItem.acceptedRORevisions)
                  ? drawingItem.acceptedRORevisions.flatMap((rev) => rev.revision)
                  : [];
                  console.log(combinedRevisions,"combinedRevisions")
            setEditData({
                drawingId: tableRowData.drawingId,
                drawingNumber: drawingItem.drawingNo,
                expectedDate: "",
                requestedDate: todayDate(),
                
                drawingFileName: "",
                revision: combinedRevisions,
                natureofrequestedInfo: []
                ,
            })
        }
            setRequestReason([{ NOR: "", reason: "" }]) 
            
            
        }
      },[])
      useEffect(()=>{
        console.log(editData,"editData")
      },[editData])
    
  return (
    <Dialog
    open={open}
    onClose={handleEditClose}
    maxWidth="md"
    fullWidth
    PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: " #4D4D4D",
        margin: "0px",
        height: "50px",
      }}
    >
      <p
        style={{ margin: "5px 250px", textAlign: "center", color: "white" }}
      >
        RFI
      </p>
      <RxCross1
        size={20}
        style={{
          marginLeft: "auto",
          cursor: "pointer",
          marginRight: "20px",
          color: "white",
        }}
        onClick={handleEditClose}
      />
    </div>
    <DialogContent
      style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
    >
      <form onSubmit={handleEditSubmit}>
        <Loader
          open={apiProcessing.loader}
          message={apiProcessing.message}
        />
        <div
          style={{ display: "flex", flexDirection: "column", gap: "15px" }}
        >
          <div style={{ display: "flex", gap: "15px" }}>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="drawingNumber"
                className="fs-10  text-start py-2"
              >
                Drawing Number <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="drawingNumber"
                placeholder="Enter your Drawing Number"
                className="form-control fs-12"
                style={{ width: "100%" }}
                value={editData.drawingNumber}
                onChange={handleInputChange}
                readOnly
              />
              {error.drawingNumber && (
                <div className="text-danger">{error.drawingNumber}</div>
              )}
            </div>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="requestedDate"
                className="fs-10  text-start py-2"
              >
                Requested Date <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="expectedDate"
                className="form-control fs-12"
                style={{ width: "100%" }}
                value={editData.requestedDate}
                onChange={handleInputChange}
                readOnly
              />
              {error.requestedDate && (
                <div className="text-danger">{error.requestedDate}</div>
              )}
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px" }}>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="expectedDate"
                className="fs-10  text-start py-2"
              >
                Expected Date <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="date"
                name="expectedDate"
                className="form-control fs-12"
                style={{ width: "100%", height: "45px" }}
                value={editData.expectedDate}
                onChange={handleInputChange}
                // min={tomorrowDateString}
              />
              {error.expectedDate && (
                <div className="text-danger">{error.expectedDate}</div>
              )}
            </div>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="revision"
                className="fs-10  text-start py-2"
              >
                Revisions
                {/* <span style={{ color: 'red' }}>*</span> */}
              </label>
              <SearchDropdownIdVal
                placeholder={"Select Revision"}
                backgroundColor={"var(--table-evenrow-color)"}
                // Check if `editData.revision` is an array and get the latest revision
                options={
                  Array.isArray(editData.revision) &&
                  editData.revision.length > 0
                    ? [
                        {
                          name: editData.revision.slice(-1)[0], // Get the latest revision
                          value: editData.revision.slice(-1)[0], // Use the latest revision for value
                        },
                      ]
                    : []
                }
                onOptionSelect={(option) =>
                  handleRevisionSelection({
                    target: {
                      name: "selectedRevision",
                      value: option.value,
                    },
                  })
                }
                className="form-control fs-12"
                style={{ width: "100%" }}
              />

              

              {error.revision && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {error.revision}
                </p>
              )}
            </div>
          </div>
          <div style={{ display: "flex", gap: "15px" }}>
            <div style={{ flex: 1 }}>
              
              <MultiSelectSearch
                mandatory={true}
                heading={"Nature of Requested Information"}
                listItems={listItems}
                selectedIds={editData.natureofrequestedInfo || []} // Ensure this is an array
                setSelectedIds={(ids) =>
                  setEditData({ ...editData, natureofrequestedInfo: ids })
                }
                handleSelectedPropertyOption={(data) => {
                  if (editData?.natureofrequestedInfo?.length && editData?.natureofrequestedInfo?.includes(data.id)) {
                    setEditData({
                      ...editData,
                      natureofrequestedInfo:
                        editData?.natureofrequestedInfo.filter(
                          (id) => id !== data.id
                        ),
                    });
                  } else {
                    setEditData({
                      ...editData,
                      natureofrequestedInfo: [
                        ...editData?.natureofrequestedInfo,
                        data.id,
                      ],
                    });
                  }
                }}
                clearAll={() =>
                  setEditData({ ...editData, natureofrequestedInfo: [] })
                }
                addAll={() =>
                  setEditData({
                    ...editData,
                    natureofrequestedInfo: listItems.map((item) => item.id),
                  })
                }
              />

              {/* Display selected fields */}
              <div style={{ marginTop: "20px" }}>
                <p className="fs-10  text-start py-2">
                  Selected Fields:
                </p>
                <ul>
                  {editData?.natureofrequestedInfo?.length ? (
                    editData?.natureofrequestedInfo?.map((id) => (
                      <li key={id}>{getNameFromId(id)}</li>
                    ))
                  ) : (
                    <li>No fields selected</li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "15px" }}>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="remarks"
                className="fs-10  text-start py-2"
                style={{display:"flex",alignItems:"center"}}
              >
                Request Reason *<span style={{display:"flex",alignItems:"center"}}>&nbsp; &nbsp; &nbsp; <HiPlusCircle style={{marginLeft:"20px",color:"#e47331",fontSize:"24px"}} onClick={handleAddReason}/></span>
              </label>
              {requestReason.map((reqReason,reqIndex)=>(<div style={{width:"100%",display:"flex",alignItems:"center",position:"relative",marginTop:"30px"}}>
                <select style={{width:"15%",marginRight:"20px"}} name="NOR" value={reqReason.NOR} onChange={(e) => handleReasonChange(e, reqIndex)}>
                  <option vlaue="">Select</option>
                  {editData.natureofrequestedInfo?.length && editData.natureofrequestedInfo?.map((requestInfo,index)=>(<option key={index} value={requestInfo}>{requestInfo} </option>))}
                </select>

                <input type="text" style={{width:"82%"}} name="reason" value={reqReason.reason} onChange={(e) => handleReasonChange(e, reqIndex)}/>
                {reqIndex>0 ?<FaCircleMinus style={{fontSize:"24px",position:"absolute",right:"-21px"}} onClick={()=>handleRemoveReason(reqIndex)}/>:""}
                {error[`NOR_${reqIndex}`] ?(
  <p style={{ color: "red", fontSize: "12px", position: "absolute", top: "50px" }}>
    {error[`NOR_${reqIndex}`]}
  </p>
):error[`reason_${reqIndex}`] ? (
  <p style={{ color: "red", fontSize: "12px", position: "absolute", top: "50px", left:"18%" }}>
    {error[`reason_${reqIndex}`]}
  </p>
):""}
              </div>))}
              
            </div>
          </div>
          <div>
            <label
              htmlFor="drawingFileName"
              className="fs-10  text-start py-2"
            >
              Upload Your File <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="file"
              accept=".dwg"
              id="drawingFileName"
              name="drawingFileName"
              className="form-control fs-12"
              style={{ width: "100%", height: "43px" }}
              
              onChange={(e) => {
                const file = e.target.files[0];
                if (file && file.name.endsWith(".dwg")) {
                  handleInputChange(e);
                } else {
                  alert("Please select a .dwg file.");
                  e.target.value = null; // Reset the file input if the file is not valid
                }
              }}
            />

            {error.drawingFileName && (
              <div className="text-danger">{error.drawingFileName}</div>
            )}
          </div>
          {errorMessage && (
            <div style={{ color: "red", fontSize: "15px" }}>
              {errorMessage}
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              type="submit"
              style={{
                background: "blue",
                color: "white",
                width: "100px",
                height: "35px",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </DialogContent>
  </Dialog>
  )
}

export default RFIPopUp