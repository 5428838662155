import React, { useState } from 'react'
import { Loader } from '../../../Widgets/notificationFeedbacks';
import { Dialog, DialogContent } from '@mui/material';
import { RxCross1 } from 'react-icons/rx';

const ViewWorkflow = (props) => {
    const { open, handleDialogClose, formData } = props;
    const [apiProcessing, setApiProcessing] = useState({
      loader: false,
      message: "Loading...",
    });
  
    const handleClose = () => {
      handleDialogClose();
    };
  
    return (
      <>
        <Loader open={apiProcessing.loader} message={apiProcessing.message} />
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="lg"
          PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: " #4D4D4D",
              margin: "0px",
              height: "50px",
            }}
          >
            <p
              style={{ margin: "5px 550px", textAlign: "center", color: "white" }}
            >
              View
            </p>
            <RxCross1
              size={20}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                marginRight: "20px",
                color: "white",
              }}
              onClick={handleClose}
            />
          </div>
  
          <DialogContent
            style={{ padding: "25px", minWidth: "400px", minHeight: "500px" }}
          >
            <p>Q : The Degree of authority for Checklist Approval</p>
            <div className="levelsMainContainer">
                <div className="levelsHeadContainer">
                  <p style={{ width: "80px" }}></p>
                  <p style={{ textAlign: "left", width: "25%" }}>Department</p>
                  <p style={{ textAlign: "left", width: "25%" }}>Role</p>
                  <p style={{ textAlign: "left", width: "25%" }}>User</p>
                </div>
                
                  <div className="levelsHeadContainer" >
                    <p style={{ width: "80px" }}>Level 1:</p>
                    <p style={{ textAlign: "left", width: "25%" }}>Department</p>
                  <p style={{ textAlign: "left", width: "25%" }}>Role</p>
                  <p style={{ textAlign: "left", width: "25%" }}>User</p>
                  </div>
                  <div className="levelsHeadContainer" >
                    <p style={{ width: "80px" }}>Level 2:</p>
                    <p style={{ textAlign: "left", width: "25%" }}>Department</p>
                  <p style={{ textAlign: "left", width: "25%" }}>Role</p>
                  <p style={{ textAlign: "left", width: "25%" }}>User</p>
                  </div>
                
              </div>
              <p style={{marginTop:"40px"}}>Q : Timeline : 4</p>
              <p>Note :  How long does it takes to reassign an approval task to another 
             person if the primary person is unavailable or does not take 
             action within a set time</p>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  
  

export default ViewWorkflow