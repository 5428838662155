import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaPenFancy, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import FolderFilesViewDialog from "./FolderFilesViewDialog";
import "./FolderDetailsDialog.css";
import { WebApimanager } from "../../../WebApiManager";
import FolderEditDialog from "./FolderEditDialog";

const FolderDetailsDialog = ({ open, onClose, folders, onFolderDelete }) => {
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isFolderModalOpen, setFolderModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const handleViewFolder = (folderId) => {
    const folder = folders.find((f) => f._id === folderId);
    if (folder) {
      setSelectedFolder(folder);
      setFolderModalOpen(true);
    }
  };

  const handleEditFolder = (folderId) => {
    const folder = folders.find((f) => f._id === folderId);
    if (folder) {
      setSelectedFolder(folder);
      setEditModalOpen(true);
    }
  };

  const backToFolderDetailsDialog = () => {
    setFolderModalOpen(false);
    // setSelectedFolder(null);
  };

  const webApi = new WebApimanager();

  const handleDeleteFolder = async (folderId, onSuccess) => {
    try {
      const deleteResponse = await webApi.delete(
        `api/isCode/delete/${folderId}`
      );
      console.log("Delete Response:", deleteResponse);
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  };

  useEffect(() => {
    if (selectedFolder != null && isFolderModalOpen) {
      console.log("triggered");
      const folder = folders.find((f) => f._id === selectedFolder._id);
      if (folder) {
        setSelectedFolder(folder);
      }
    }
  }, [folders]);

  if (!open && !isFolderModalOpen && !isEditModalOpen) return null;

  return (
    <>
      {open && !isFolderModalOpen && !isEditModalOpen && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          style={{
            marginTop: "130px",
            boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
        >
          <div className="modal-dialog modal-lg" style={{ border: "none" }}>
            <div className="modal-content">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "#303030",
                  height: "50px",
                  color: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 15px",
                }}
              >
                <h5 className="modal-title editFolderTitle">Folder Details</h5>
                <button
                  onClick={onClose}
                  aria-label="Close dialog"
                  style={{
                    color: "white",
                    backgroundColor: "transparent",
                    border: "none",
                    fontSize: "20px",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div
                className="modal-body"
                style={{
                  padding: "15px, 20px",
                  width: "95%",
                  margin: "0 auto",
                }}
              >
                <table className="table ">
                  <thead className="tableThead">
                    <tr style={{ height: "20px" }}>
                      <th>S No</th>
                      <th>Folders Name</th>
                      <th>Folders Created By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody
                    className="table-wrapper"
                    style={{ maxHeight: "50px" }}
                  >
                    {folders.map((folder, index) => (
                      <tr key={folder._id}>
                        <td>{(index + 1).toString().padStart(3, "0")}</td>
                        <td>{folder.fName}</td>
                        <td>
                          <p
                            style={{
                              display: "inline",
                              marginRight: "5px",
                              margin: 0,
                            }}
                          >
                            {folder.folderCreatedBy.firstName}
                          </p>
                          <p style={{ margin: 0, display: "inline" }}>
                            {folder.folderCreatedBy.lastName}
                          </p>
                        </td>

                        <td>
                          <button
                            onClick={() =>
                              handleEditFolder(folder._id, () => {
                                onFolderDelete();
                              })
                            }
                            className="me-2"
                          >
                            <FaPenFancy />
                          </button>
                          <button
                            onClick={() => handleViewFolder(folder._id)}
                            className="me-2"
                          >
                            <FaEye />
                          </button>
                          <button
                            onClick={() =>
                              handleDeleteFolder(folder._id, () => {
                                onFolderDelete();
                              })
                            }
                            className="me-2"
                          >
                            <MdDelete />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedFolder && isFolderModalOpen && (
        <FolderFilesViewDialog
          folder={selectedFolder}
          open={isFolderModalOpen}
          // onClose={handleCloseFolderModal}
          backToFolderDetailsDialog={backToFolderDetailsDialog}
          onFolderDelete={() => {
            onFolderDelete();
          }}
          onClose={() => {
            setFolderModalOpen(false);
            setSelectedFolder(true);
          }}
        />
      )}

      {selectedFolder && isEditModalOpen && (
        <FolderEditDialog
          folder={selectedFolder}
          open={isEditModalOpen}
          onClose={() => {
            setEditModalOpen(false);
            setSelectedFolder(true);
          }}
          onSuccess={onFolderDelete()}
        />
      )}
    </>
  );
};

export default FolderDetailsDialog;
