import React, { useState, useEffect } from "react";
import { IoAdd } from "react-icons/io5";
import AddFolderConstruction from "./AddFolderConstruction";
import ConstructionNeedsDetails from "../ConstructionNeeds/ConstructionNeedsDetails ";
import { FaSearch } from "react-icons/fa";
import { WebApimanager } from "../../../WebApiManager";
import { BACKEND_URL } from "../../../Utilities/Constants";

const ConstructionNeeds = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [viewingDetails, setViewingDetails] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [ConstructionFolders, setConstructionFolders] = useState([]);

  const webApi = new WebApimanager();

  const fetchData = (callFrom) => {
    webApi
      .get("api/constructionNeeds")
      .then((response) => {
        setConstructionFolders(response.data.data.constructionNeeds);
        if (callFrom) {
          const item = response.data.data.constructionNeeds.find(
            (item) => item._id === selectedItem._id
          );
          if (item) {
            setSelectedItem({ ...item });
          }
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenModal = () => {
    setSelectedItem(null);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setShowModal(false);
  };

  const handleViewDetails = (item) => {
    setSelectedItem(item);
    setViewingDetails(true);
  };

  const handleBackToList = () => {
    setViewingDetails(false);
    setSelectedItem(null);
  };

  const handleSaveSuccess = () => {
    // Refetch the data to include the newly added folder
    webApi
      .get("api/constructionNeeds")
      .then((response) => {
        setConstructionFolders(response.data.data.constructionNeeds);
      })
      .catch((error) => {
        console.error("There was an error fetching the updated data!", error);
      });

    handleCloseModal(); // Close the modal
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter items based on search term
  const filteredItems = ConstructionFolders.filter((item) =>
    item.fName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div>
        <div
          style={{
            width: "90%",
            boxShadow: "0px 0px 10px 0px #F3F3F3",
            borderRadius: "10px",
            backgroundColor: "#FFF",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "400",
            fontSize: "16px",
            height: "auto",
            padding: "10px 10px 10px 10px",
            // marginTop: "80px",
            margin: "80px auto",
          }}
        >
          {viewingDetails ? (
            <ConstructionNeedsDetails
              key={selectedItem?._id}
              item={selectedItem}
              fetchData={() => {
                fetchData(true);
              }}
              onClose={handleBackToList}
            />
          ) : (
            <>
              <div className="row my-4" style={{ marginLeft: "10px" }}>
                <div className="col-lg-6 col-md-12">
                  <h3
                    className="d-flex justify-content-start"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "20px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      marginTop: "10px",
                    }}
                  >
                    Construction Needs
                  </h3>
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "300px",
                    marginLeft: "auto",
                    marginRight: "20px",
                    height: "40px",
                  }}
                >
                  <input
                    placeholder="Search Files"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    style={{
                      paddingLeft: "20px",
                      borderRadius: "6px",
                      border: "1px solid #E47331",
                      width: "100%",
                      height: "100%",
                      marginLeft: "30px",
                    }}
                  />
                  <FaSearch
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#ACACAC",
                    }}
                  />
                </div>
                <div className="col-lg-2 col-md-6 col-sm-4 text-md-right text-sm-center">
                  <button
                    className="btn btn-primary"
                    style={{
                      width: "150px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#E47331",
                      borderColor: "#E47331",
                      color: "white",
                      marginRight: "150px",
                    }}
                    onClick={handleOpenModal}
                  >
                    <IoAdd
                      style={{
                        marginRight: "10px",
                        color: "white",
                        fontWeight: "400",
                        fontSize: "20px",
                      }}
                    />
                    Add Folder
                  </button>
                </div>
              </div>

              <div className="row" style={{ marginLeft: "10px" }}>
                {filteredItems.map((item) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 mb-4 mt-4"
                    key={item._id}
                  >
                    <div
                      className="card h-100"
                      onClick={() => handleViewDetails(item)}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#F3F6F8",
                        width: "390px",
                        height: "303px",
                      }}
                    >
                      <img
                        src={`${BACKEND_URL}${item.fImage}`}
                        // src={`https://api.chromacheer.com/api/constructionNeeds/fImage/${item._id}`}
                        className="card-img-top"
                        alt={item._id}
                        style={{
                          objectFit: "cover",
                          width: "425px",
                          height: "237px",
                        }}
                      />
                      <div className="card-body text-start">
                        <h5
                          className="card-title"
                          style={{
                            marginLeft: "20px",
                            fontSize: "20px",
                            fontFamily: "Poppins",
                            fontWeight: "400",
                          }}
                        >
                          {item.fName}
                        </h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      {showModal && (
        <AddFolderConstruction
          onClose={handleCloseModal}
          onSaveSuccess={handleSaveSuccess}
        />
      )}
    </>
  );
};

export default ConstructionNeeds;
