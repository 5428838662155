import React, { useState,useEffect } from 'react';
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { Button } from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';


import { todayDate } from '../../../Utilities/DateUtils';
import { useRecoilState } from "recoil";
import { registerAtom, siteId } from '../../../atoms';
import MultiSelectSearch from '../../../Utilities/MultiSelectSearch';
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from '../../../WebApiManager';
import { getRegisterData, updateRegister } from '../BackendUtils';
import { SUCESS_STATUS } from '../../../Utilities/Constants';



const  ReceivedTable = () => {


  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
    };
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowDateString = tomorrow.toISOString().split('T')[0];

  let webApi = new WebApimanager();

  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [error, setError] = useState({});
  const [drawingList, setDrawingList] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  

 


  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    setViewDialogOpen(true);
  };

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };


  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const fetchInData = async () => {
    if(registerInfo && registerInfo.length>0){
        setDrawingList(registerInfo)
    } else {
      await updateRegister(setRegisterInfo, site).then((response)=>{
        if(response.status === SUCESS_STATUS){
          setDrawingList(response.registers)
        }
      })
    }
  }

  useEffect(() => {
    fetchInData();
  },[] );
  
  const handleDownload = async (id, revision) => {
    try {
      const response = await webApi.getImage(`api/roToSiteLevelRequested/${id}?revision=${revision}`);
  
      console.log(response.headers['content-type']); // Should log 'image/png'
  
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `image-${id}.png`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
  
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }  
  
  const handleViewFile= async (id, revision) => {
    try {
      const response = await webApi.getImage(`api/roToSiteLevelRequested/${id}?revision=${revision}`);
  
      console.log(response.headers['content-type']); // Should log 'image/png'
  
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank'; // Open link in a new tab
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
  
    } catch (error) {
      console.error('Error viewing file:', error);
    }
  };
  


 


  
    
    return (
      <>
       
       <table>
  <thead>
    <tr>
    <th style={{ width: '5%',paddingLeft: '10px' }}>S.No</th>
      <th style={{ width: '10%' }}>Drawing No</th>
      <th style={{ width: '10%' }}>Design Consultant</th>
      <th style={{ width: '10%' }}>Drawing Category</th>
      <th style={{ width: '10%' }}>Drawing Title</th>
      <th style={{ width: '13%' }}>Submission Date</th>
      <th style={{ width: '13%' }}>Received Copies</th>
      <th style={{ width: '10%' }}>Revision</th>
      <th style={{ width: '10%' }}>Action</th>
    </tr>
  </thead>
  <tbody>
    {drawingList &&
      [...drawingList
        .filter(item => item.acceptedArchitectHardCopyRevisions.length > 0) // Filter by acceptedArchitectHardCopyRevisions
        .sort((a, b) => {
          const consultantA = a.designDrawingConsultant.designDrawingConsultant.toLowerCase();
          const consultantB = b.designDrawingConsultant.designDrawingConsultant.toLowerCase();
          return consultantA.localeCompare(consultantB); // Sort alphabetically
        })
      ]
      .map((drawingItem, index) => (
        <tr key={index} style={{
          backgroundColor: index % 2 === 0 ? 'var(--table-oddrow-color)' : 'var(--table-evenrow-color)',
        }}>
          <td>{index+1}</td>
          <td>{drawingItem.drawingNo}</td>
          <td>{drawingItem.designDrawingConsultant.designDrawingConsultant}</td>
          <td>{drawingItem.category.category}</td>
          <td>{drawingItem.drawingTitle}</td>
          <td>
            {drawingItem.acceptedArchitectHardCopyRevisions.length > 0
              ? formatDate(drawingItem.acceptedArchitectHardCopyRevisions[0]?.hardCopySubmittedDate) || 'N/A'
              : 'N/A'}
          </td>
          <td>
  {drawingItem.acceptedArchitectHardCopyRevisions.length > 0
    ? drawingItem.acceptedArchitectHardCopyRevisions[0]?.receivedCopies || 'N/A'
    : 'N/A'}
</td>

          <td>
            {drawingItem.acceptedArchitectHardCopyRevisions.length > 0 ? (
              <div>{drawingItem.acceptedArchitectHardCopyRevisions.map((revisionItem) => `${revisionItem.revision}` || 'NA').join(', ')}</div>
            ) : (
              <div>-</div>
            )}
          </td>
          <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
            <Icon.EyeFill size={20} style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => handleViewOpen(drawingItem)} />
            <Icon.Download size={20} style={{ marginLeft: '5px', cursor: 'pointer' }}
              onClick={() => {
                const latestRevision = drawingItem.acceptedArchitectHardCopyRevisions.length > 0
                  ? drawingItem.acceptedArchitectHardCopyRevisions[drawingItem.acceptedArchitectHardCopyRevisions.length - 1].revision
                  : null;
                handleDownload(drawingItem._id, latestRevision);
              }}
            />
            {/* <Icon.Trash size={20} style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => handleTrash(drawingItem._id)}/> */}
          </td>
        </tr>
      ))}
  </tbody>
</table>


<Dialog open={viewDialogOpen} onClose={handleViewClose} maxWidth="md" fullWidth PaperProps={{style: {borderRadius: 20, fontFamily: 'Poppins', background: '#FFF', boxShadow: '0px 0px 10px 0px #F3F3F3'}}}>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#4D4D4D', margin: '0px' }}>
    <p style={{ margin: '5px 400px', textAlign: 'center', color: 'white' }}>View</p>
    <RxCross1
      size={20}
      style={{
        marginLeft: "auto",
        cursor: "pointer",
        marginRight: "20px",
        color: 'white',
      }}
      onClick={handleViewClose}
    />
  </div>

  <DialogContent style={{ padding: '25px', minWidth: '300px', minHeight: '300px' }}>
    <div style={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: '20px',
      padding: '20px',
      color: '#4D4D4D',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '28px',
      justifyContent: 'center',
      marginLeft: '80px'
    }}>
      <div>Drawing Number</div>
      <div>:</div>
      <div>{singleDrawingData?.drawingNo || 'NA'}</div>

      <div>Design Consultant</div>
      <div>:</div>
      <div>{singleDrawingData?.designDrawingConsultant?.designDrawingConsultant || 'NA'}</div>

      <div>Category</div>
      <div>:</div>
      <div>{singleDrawingData?.category?.category || 'NA'}</div>

      <div>Drawing Title</div>
      <div>:</div>
      <div>{singleDrawingData?.drawingTitle || 'NA'}</div>

      <div>Submission date</div>
      <div>:</div>
      <div>{singleDrawingData?.acceptedSiteSubmissionDate ? new Date(singleDrawingData.acceptedSiteSubmissionDate).toISOString().split('T')[0] : 'NA'}</div>

      <div>Hard Copy Submitted Date</div>
      <div>:</div>
      <div>
        {singleDrawingData?.acceptedArchitectHardCopyRevisions?.length > 0
          ? formatDate(singleDrawingData.acceptedArchitectHardCopyRevisions[0]?.hardCopySubmittedDate) || 'N/A'
          : 'N/A'}
      </div>

      <div>Received Copies</div>
      <div>:</div>
      <div>
        {singleDrawingData?.acceptedArchitectHardCopyRevisions?.length > 0
          ? singleDrawingData.acceptedArchitectHardCopyRevisions[0]?.receivedCopies || 'N/A'
          : 'N/A'}
      </div>

      {singleDrawingData?.acceptedArchitectHardCopyRevisions?.length > 0 && (
        <>
          <div>Revision</div>
          <div>:</div>
          <div>{singleDrawingData.acceptedArchitectHardCopyRevisions[0]?.revision || 'NA'}</div>
        </>
      )}

      {singleDrawingData?.acceptedArchitectHardCopyRevisions?.length > 0 && (
        <>
          <div>Drawing File</div>
          <div>:</div>
          <div style={{ display: 'flex' }}>
            <Icon.Image size={20} style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => handleViewFile(singleDrawingData._id, singleDrawingData.acceptedArchitectHardCopyRevisions[0]?.revision)} />
            <Icon.Download size={20} style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => handleDownload(singleDrawingData._id, singleDrawingData.acceptedArchitectHardCopyRevisions[0]?.revision)} />
          </div>
        </>
      )}
    </div>
  </DialogContent>
</Dialog>

  </>
    );
  }



  
  export default ReceivedTable;
