// ArchitectureToRo.jsx

import React, { useState, useEffect } from "react";
import ApprovedTable from "./ApprovedTable";
import RequestedTable from "./RequestedTable";
import PendingTable from "./PendingTable";
import RegisterTable from "./RegisterTable";
import Analysis from "../Components/Analysis";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { architectureTab, siteId, userInfo } from "../../../atoms";
import SelectSite from "../../../Utilities/SelectSite";
import "./ArchitectureToRo.css";

import DrawingDefaultICon from "../../../Images/DrawingDefault.svg";

const ArchitectureToRo = () => {
  const webApi = new WebApimanager();
  const [value, setValue] = useRecoilState(architectureTab);
  const [site] = useRecoilState(siteId);
  const [currentUserInfo] = useRecoilState(userInfo);
  const [tabEnableModules, setTabEnableModules] = useState({
    drawing: false,
    rfi: false,
    pending: false,
    register: false,
    analysis: false,
  });

  useEffect(() => {
    if (currentUserInfo?.permittedSites) {
      let enabledTabs = {
        drawing: false,
        rfi: false,
        pending: false,
        register: false,
        analysis: false,
      };

      currentUserInfo.permittedSites.forEach((site) => {
        const architectureToRoDetails =
          site.enableModules.drawingDetails?.architectureToRoDetails;

        if (architectureToRoDetails) {
          enabledTabs = {
            drawing: enabledTabs.drawing || architectureToRoDetails.drawing,
            rfi: enabledTabs.rfi || architectureToRoDetails.rfi,
            pending: enabledTabs.pending || architectureToRoDetails.pending,
            register: enabledTabs.register || architectureToRoDetails.register,
            analysis: enabledTabs.analysis || architectureToRoDetails.analysis,
          };
        }
      });

      setTabEnableModules(enabledTabs);
    }
  }, [currentUserInfo]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  if (site === "") {
    return <SelectSite />;
  }

  const anyTabEnabled = Object.values(tabEnableModules).some((val) => val);

  const allTabs = [
    { label: "Drawing", index: 0, component: <ApprovedTable /> },
    { label: "RFI", index: 1, component: <RequestedTable /> },
    { label: "Pending", index: 2, component: <PendingTable /> },
    { label: "Register", index: 3, component: <RegisterTable /> },
    {
      label: "Analysis",
      index: 4,
      component: <Analysis type="architect" />,
    },
  ];

  // Filter enabled tabs based on user permissions
  const enabledTabs = allTabs.filter(
    (tab) => tabEnableModules[tab.label.toLowerCase()]
  );

  return (
    <>
      {anyTabEnabled ? (
        <>
          <div className="containerDrawingPagesArchitecture">
            <ul className="tabsArchitectureTabs" role="tablist">
              {enabledTabs.map((tab) => (
                <li
                  key={tab.index}
                  className={`tabArchitectureTab ${
                    value === tab.index ? "active" : ""
                  }`}
                  role="tab"
                  aria-selected={value === tab.index}
                  aria-controls={`tabpanel-${tab.index}`}
                  id={`tab-${tab.index}`}
                  onClick={() => handleChange(tab.index)}
                >
                  {tab.label}
                </li>
              ))}
            </ul>
          </div>

          {enabledTabs.map((tab) => (
            <TabPanel key={tab.index} value={value} index={tab.index}>
              {tab.component}
            </TabPanel>
          ))}
        </>
      ) : (
        <div className="no-tabs-message">
          <p
            style={{
              margin: "0 auto",
              color: "#E47331",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            You do not have the necessary permissions to view any
            ArchitectureToRo Tabs.
            <br />
            Please contact your administrator for access rights.
          </p>

          {/* <img
            src={DrawingDefaultICon}
            alt="ArchitectureToRoImg"
            style={{
              width: "350px",
              height: "350px",
              margin: "50px auto",
            }}
          /> */}
        </div>
      )}
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      className="tabpanelArchitecture"
    >
      {value === index && <div className="tabpanel-content">{children}</div>}
    </div>
  );
}

export default ArchitectureToRo;
