import React from 'react';
import styles from './PartnershipBenefits.module.css';

const BenefitCard = ({ iconSrc, title, altText }) => {
  return (
    <div className={styles.benefitCard}>
      <img loading="lazy" src={iconSrc} className={styles.benefitIcon} alt={altText} />
      <div className={styles.benefitTitle} dangerouslySetInnerHTML={{ __html: title }} />
    </div>
  );
};

export default BenefitCard;