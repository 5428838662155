// import React from "react";
// import "./DevicePermissions.css";
// import { FaChevronRight } from "react-icons/fa";

// const DevicePermissions = () => {
//   const handlePermissionClick = (permission) => {
//     if (permission === "Microphones") {
//       requestMicrophoneAccess();
//     } else {
//       alert(`Opening ${permission} permission settings...`);
//       // Implement other permissions logic here
//     }
//   };

//   const requestMicrophoneAccess = () => {
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         alert("Microphone access granted!");
//         // You can work with the audio stream here
//       })
//       .catch((error) => {
//         console.error("Microphone access denied:", error);
//         alert("Microphone access denied. Please allow microphone permissions.");
//       });
//   };

//   return (
//     <>
//       <div className="container py-3">
//         <div>
//           <p className="DevicePermissions-Title">Device Permissions</p>
//         </div>

//         <div
//           style={{ borderBottom: "2px solid #E5E5E5", marginTop: "25px" }}
//         ></div>

//         <div className="d-flex flex-column">
//           <div className="p-2" onClick={() => handlePermissionClick("Camera")}>
//             <div className="d-flex flex-row align-items-center">
//               <div className="p-2">Camera</div>
//               <div className="p-2 ml-auto">
//                 <FaChevronRight />
//               </div>
//             </div>
//           </div>

//           <div
//             className="p-2"
//             onClick={() => handlePermissionClick("Contacts")}
//           >
//             <div className="d-flex flex-row align-items-center">
//               <div className="p-2">Contacts</div>
//               <div className="p-2 ml-auto">
//                 <FaChevronRight />
//               </div>
//             </div>
//           </div>

//           <div
//             className="p-2"
//             onClick={() => handlePermissionClick("Microphones")}
//           >
//             <div className="d-flex flex-row align-items-center">
//               <div className="p-2">Microphones</div>
//               <div className="p-2 ml-auto">
//                 <FaChevronRight />
//               </div>
//             </div>
//           </div>

//           <div
//             className="p-2"
//             // onClick={() => handlePermissionClick("Photos & Videos")}
//           >
//             <div className="d-flex flex-row align-items-center">
//               <div className="p-2">Photos & Videos</div>
//               <div
//                 className="p-2 ml-auto"
//                 onClick={() => handlePermissionClick("Photos & Videos")}
//               >
//                 <FaChevronRight />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default DevicePermissions;

import React from "react";
import "./DevicePermissions.css";
import { FaChevronRight } from "react-icons/fa";

const DevicePermissions = () => {
  const handlePermissionClick = (permission) => {
    if (permission === "Microphones") {
      requestMicrophoneAccess();
    } else if (permission === "Camera") {
      requestCameraAccess();
    } else {
      alert(`Opening ${permission} permission settings...`);
    }
  };

  const requestMicrophoneAccess = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        alert("Microphone access granted!");
        // You can work with the audio stream here
      })
      .catch((error) => {
        console.error("Microphone access denied:", error);
        alert("Microphone access denied. Please allow microphone permissions.");
      });
  };

  const requestCameraAccess = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        alert("Camera access granted!");
      })
      .catch((error) => {
        console.error("Camera access denied:", error);
        alert("Camera access denied. Please allow camera permissions.");
      });
  };

  return (
    <>
      <div className="container py-3">
        <div>
          <p className="DevicePermissions-Title">Device Permissions</p>
        </div>

        <div
          style={{ borderBottom: "2px solid #E5E5E5", marginTop: "25px" }}
        ></div>

        <div className="d-flex flex-column">
          <div className="p-2" onClick={() => handlePermissionClick("Camera")}>
            <div className="d-flex flex-row align-items-center">
              <div className="p-2">Camera</div>
              <div className="p-2 ml-auto">
                <FaChevronRight />
              </div>
            </div>
          </div>

          <div
            className="p-2"
            onClick={() => handlePermissionClick("Contacts")}
          >
            <div className="d-flex flex-row align-items-center">
              <div className="p-2">Contacts</div>
              <div className="p-2 ml-auto">
                <FaChevronRight />
              </div>
            </div>
          </div>

          <div
            className="p-2"
            onClick={() => handlePermissionClick("Microphones")}
          >
            <div className="d-flex flex-row align-items-center">
              <div className="p-2">Microphones</div>
              <div className="p-2 ml-auto">
                <FaChevronRight />
              </div>
            </div>
          </div>

          <div
            className="p-2"
            onClick={() => handlePermissionClick("Photos & Videos")}
          >
            <div className="d-flex flex-row align-items-center">
              <div className="p-2">Photos & Videos</div>
              <div className="p-2 ml-auto">
                <FaChevronRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DevicePermissions;
