import React from "react";

const FloorDetails = ({
  floors,
  errors,
  handleChange,
  ventureType,
  towerIndex,
  clubIndex,
}) => (
  <>
    {Array.isArray(floors) &&
      floors.map((floor, floorIndex) => (
        <React.Fragment key={floorIndex}>
          {floorIndex === 0 && (
            <div
              style={{
                borderBottom: "1px dotted lightgray",
                mariginTop: "20px",
              }}
            >
              <h3> Ground Floor</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "20px",
                  paddingBottom: "40px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "80px",
                    }}
                  >
                    <label>
                      Number of Units<a style={{ color: "red" }}>*</a>
                    </label>
                    <input
                      type="text"
                      name="numberOfUnits"
                      placeholder="Enter Number of Units"
                      value={floor.numberOfUnits || ""}
                      style={{ marginTop: "15px" }}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d{0,2}$/.test(value)) {
                          handleChange(
                            e,
                            floorIndex,
                            null,
                            towerIndex,
                            clubIndex
                          );
                        }
                      }}
                    />
                    {towerIndex !== null &&
                      towerIndex !== undefined &&
                      floorIndex !== null &&
                      floorIndex !== undefined &&
                      clubIndex !== null &&
                      clubIndex !== undefined &&
                      errors[
                        `numberOfUnits-${towerIndex}-${floorIndex}-${clubIndex}`
                      ] && (
                        <p style={{ color: "red" }}>
                          {
                            errors[
                              `numberOfUnits-${towerIndex}-${floorIndex}-${clubIndex}`
                            ]
                          }
                        </p>
                      )}
                    {towerIndex !== null &&
                      towerIndex !== undefined &&
                      floorIndex !== null &&
                      floorIndex !== undefined &&
                      errors[`numberOfUnits-${towerIndex}-${floorIndex}`] && (
                        <p style={{ color: "red" }}>
                          {errors[`numberOfUnits-${towerIndex}-${floorIndex}`]}
                        </p>
                      )}
                    {floorIndex !== null &&
                      floorIndex !== undefined &&
                      errors[`numberOfUnits-${floorIndex}`] && (
                        <p style={{ color: "red" }}>
                          {errors[`numberOfUnits-${floorIndex}`]}
                        </p>
                      )}
                    {towerIndex !== null &&
                      towerIndex !== undefined &&
                      errors[`numberOfUnits-${towerIndex}`] && (
                        <p style={{ color: "red" }}>
                          {errors[`numberOfUnits-${towerIndex}`]}
                        </p>
                      )}
                    {clubIndex !== null &&
                      clubIndex !== undefined &&
                      floorIndex !== null &&
                      floorIndex !== undefined &&
                      errors[`numberOfUnits-${clubIndex}-${floorIndex}`] && (
                        <p style={{ color: "red" }}>
                          {errors[`numberOfUnits-${clubIndex}-${floorIndex}`]}
                        </p>
                      )}
                  </div>
                </div>

                {/* Display unit fields based on the number of units */}
                {Array.from({ length: floor.numberOfUnits || 0 }).map(
                  (_, unitIndex) => (
                    <div
                      key={unitIndex}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "12px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "80px",
                          }}
                        >
                          <label>
                            Unit Number<a style={{ color: "red" }}>*</a>
                          </label>
                          <input
                            type="text"
                            name="unitNumber"
                            placeholder="Enter Unit Number"
                            value={floor.units?.[unitIndex]?.unitNumber || ""}
                            style={{ marginTop: "15px" }}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,5}$/.test(value) || value === "") {
                                handleChange(
                                  e,
                                  floorIndex,
                                  unitIndex,
                                  towerIndex,
                                  clubIndex
                                );
                              }
                            }}
                          />
                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            clubIndex !== null &&
                            clubIndex !== undefined &&
                            errors[
                              `unitNumber-${towerIndex}-${floorIndex}-${unitIndex}-${clubIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitNumber-${towerIndex}-${floorIndex}-${unitIndex}-${clubIndex}`
                                  ]
                                }
                              </p>
                            )}
                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            errors[
                              `unitNumber-${towerIndex}-${floorIndex}-${unitIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitNumber-${towerIndex}-${floorIndex}-${unitIndex}`
                                  ]
                                }
                              </p>
                            )}
                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            errors[
                              `unitNumber-${towerIndex}-${floorIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitNumber-${towerIndex}-${floorIndex}`
                                  ]
                                }
                              </p>
                            )}
                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            errors[`unitNumber-${towerIndex}`] && (
                              <p style={{ color: "red" }}>
                                {errors[`unitNumber-${towerIndex}`]}
                              </p>
                            )}
                          {floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            errors[`unitNumber-${floorIndex}-${unitIndex}`] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitNumber-${floorIndex}-${unitIndex}`
                                  ]
                                }
                              </p>
                            )}
                          {clubIndex !== null &&
                            clubIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            errors[
                              `unitNumber-${clubIndex}-${floorIndex}-${unitIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitNumber-${clubIndex}-${floorIndex}-${unitIndex}`
                                  ]
                                }
                              </p>
                            )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <label>
                            Unit Type<a style={{ color: "red" }}>*</a>
                          </label>
                          <select
                            name="unitType"
                            value={floor.units?.[unitIndex]?.unitType || ""}
                            style={{ marginTop: "15px" }}
                            onChange={(e) => {
                              handleChange(
                                e,
                                floorIndex,
                                unitIndex,
                                towerIndex,
                                clubIndex
                              );
                            }}
                          >
                            <option value="">Select Type</option>
                            <option value="1Room">1Room</option>
                            <option value="2Room">2Room</option>
                            <option value="Commercial">Commercial</option>
                          </select>
                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            clubIndex !== null &&
                            clubIndex !== undefined &&
                            errors[
                              `unitType-${towerIndex}-${floorIndex}-${unitIndex}-${clubIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitType-${towerIndex}-${floorIndex}-${unitIndex}-${clubIndex}`
                                  ]
                                }
                              </p>
                            )}

                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            errors[
                              `unitType-${towerIndex}-${floorIndex}-${unitIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitType-${towerIndex}-${floorIndex}-${unitIndex}`
                                  ]
                                }
                              </p>
                            )}

                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            errors[`unitType-${towerIndex}-${floorIndex}`] && (
                              <p style={{ color: "red" }}>
                                {errors[`unitType-${towerIndex}-${floorIndex}`]}
                              </p>
                            )}

                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            errors[`unitType-${towerIndex}`] && (
                              <p style={{ color: "red" }}>
                                {errors[`unitType-${towerIndex}`]}
                              </p>
                            )}
                          {clubIndex !== null &&
                            clubIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            errors[
                              `unitType-${clubIndex}-${floorIndex}-${unitIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitType-${clubIndex}-${floorIndex}-${unitIndex}`
                                  ]
                                }
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          )}

          {floorIndex > 0 && (
            <div
              style={{
                borderBottom: "1px dotted lightgray",
                marginTop: "20px",
              }}
            >
              <h3>Floor {floorIndex}</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "20px",
                  paddingBottom: "40px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "80px",
                    }}
                  >
                    <label>
                      Number of Units<a style={{ color: "red" }}>*</a>
                    </label>
                    <input
                      type="text"
                      name="numberOfUnits"
                      placeholder="Enter Number of Units"
                      value={floor.numberOfUnits || ""}
                      style={{ marginTop: "15px" }}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d{0,2}$/.test(value) || value === "") {
                          handleChange(
                            e,
                            floorIndex,
                            null,
                            towerIndex,
                            clubIndex
                          );
                        }
                      }}
                    />
                    {towerIndex !== null &&
                      towerIndex !== undefined &&
                      floorIndex !== null &&
                      floorIndex !== undefined &&
                      clubIndex !== null &&
                      clubIndex !== undefined &&
                      errors[
                        `numberOfUnits-${towerIndex}-${floorIndex}-${clubIndex}`
                      ] && (
                        <p style={{ color: "red" }}>
                          {
                            errors[
                              `numberOfUnits-${towerIndex}-${floorIndex}-${clubIndex}`
                            ]
                          }
                        </p>
                      )}
                    {towerIndex !== null &&
                      towerIndex !== undefined &&
                      floorIndex !== null &&
                      floorIndex !== undefined &&
                      errors[`numberOfUnits-${towerIndex}-${floorIndex}`] && (
                        <p style={{ color: "red" }}>
                          {errors[`numberOfUnits-${towerIndex}-${floorIndex}`]}
                        </p>
                      )}
                    {floorIndex !== null &&
                      floorIndex !== undefined &&
                      errors[`numberOfUnits-${floorIndex}`] && (
                        <p style={{ color: "red" }}>
                          {errors[`numberOfUnits-${floorIndex}`]}
                        </p>
                      )}
                    {towerIndex !== null &&
                      towerIndex !== undefined &&
                      errors[`numberOfUnits-${floorIndex}`] && (
                        <p style={{ color: "red" }}>
                          {errors[`numberOfUnits-${towerIndex}`]}
                        </p>
                      )}
                    {clubIndex !== null &&
                      clubIndex !== undefined &&
                      floorIndex !== null &&
                      floorIndex !== undefined &&
                      errors[`numberOfUnits-${clubIndex}-${floorIndex}`] && (
                        <p style={{ color: "red" }}>
                          {errors[`numberOfUnits-${clubIndex}-${floorIndex}`]}
                        </p>
                      )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {clubIndex !== null && clubIndex !== undefined ? (
                      <></>
                    ) : (
                      <>
                        <label>
                          Manual/Automatic<a style={{ color: "red" }}>*</a>
                        </label>
                        <select
                          name="type"
                          value={floor.type || ""}
                          style={{ marginTop: "15px" }}
                          onChange={(e) =>
                            handleChange(
                              e,
                              floorIndex,
                              null,
                              towerIndex,
                              clubIndex
                            )
                          }
                        >
                          <option value="">Select Type</option>
                          <option value="Manual">Manual</option>
                          <option value="Automatic">Automatic</option>
                        </select>
                      </>
                    )}
                    {/* {towerIndex !== null &&
                      towerIndex !== undefined &&
                      floorIndex !== null &&
                      floorIndex !== undefined &&
                      clubIndex !== null &&
                      clubIndex !== undefined &&
                      errors[
                        `type-${towerIndex}-${floorIndex}-${clubIndex}`
                      ] && (
                        <p style={{ color: "red" }}>
                          {
                            errors[
                              `type-${towerIndex}-${floorIndex}-${clubIndex}`
                            ]
                          }
                        </p>
                      )} */}
                    {towerIndex !== null &&
                      towerIndex !== undefined &&
                      floorIndex !== null &&
                      floorIndex !== undefined &&
                      errors[`type-${towerIndex}-${floorIndex}`] && (
                        <p style={{ color: "red" }}>
                          {errors[`type-${towerIndex}-${floorIndex}`]}
                        </p>
                      )}
                    {towerIndex !== null &&
                      towerIndex !== undefined &&
                      errors[`type-${towerIndex}`] && (
                        <p style={{ color: "red" }}>
                          {errors[`type-${towerIndex}`]}
                        </p>
                      )}
                    {floorIndex !== null &&
                      floorIndex !== undefined &&
                      errors[`type-${floorIndex}`] && (
                        <p style={{ color: "red" }}>
                          {errors[`type-${floorIndex}`]}
                        </p>
                      )}
                    {clubIndex !== null &&
                      clubIndex !== undefined &&
                      floorIndex !== null &&
                      floorIndex !== undefined &&
                      errors[`type-${clubIndex}-${floorIndex}`] && (
                        <p style={{ color: "red" }}>
                          {errors[`type-${clubIndex}-${floorIndex}`]}
                        </p>
                      )}
                  </div>
                </div>

                {Array.isArray(floor.units) &&
                  floor.units.length > 0 &&
                  floor.units.map((unit, unitIndex) => (
                    <div
                      key={unitIndex}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "12px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "80px",
                          }}
                        >
                          <label>
                            Unit Number<a style={{ color: "red" }}>*</a>
                          </label>
                          <input
                            type="text"
                            name="unitNumber"
                            placeholder="Enter Unit Number"
                            value={
                              floor.type === "Automatic"
                                ? `${floorIndex}0${unitIndex + 1}`
                                : unit.unitNumber || ""
                            }
                            style={{ marginTop: "15px" }}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^[0-9]+$/.test(value) || value === "")
                                handleChange(
                                  e,
                                  floorIndex,
                                  unitIndex,
                                  towerIndex,
                                  clubIndex
                                );
                            }}
                          />
                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            clubIndex !== null &&
                            clubIndex !== undefined &&
                            errors[
                              `unitNumber-${towerIndex}-${floorIndex}-${unitIndex}-${clubIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitNumber-${towerIndex}-${floorIndex}-${unitIndex}-${clubIndex}`
                                  ]
                                }
                              </p>
                            )}
                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            errors[
                              `unitNumber-${towerIndex}-${floorIndex}-${unitIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitNumber-${towerIndex}-${floorIndex}-${unitIndex}`
                                  ]
                                }
                              </p>
                            )}
                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            errors[
                              `unitNumber-${towerIndex}-${floorIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitNumber-${towerIndex}-${floorIndex}`
                                  ]
                                }
                              </p>
                            )}
                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            errors[`unitNumber-${towerIndex}`] && (
                              <p style={{ color: "red" }}>
                                {errors[`unitNumber-${towerIndex}`]}
                              </p>
                            )}
                          {floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            errors[`unitNumber-${floorIndex}-${unitIndex}`] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitNumber-${floorIndex}-${unitIndex}`
                                  ]
                                }
                              </p>
                            )}
                          {clubIndex !== null &&
                            clubIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            errors[
                              `unitNumber-${clubIndex}-${floorIndex}-${unitIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitNumber-${clubIndex}-${floorIndex}-${unitIndex}`
                                  ]
                                }
                              </p>
                            )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {clubIndex !== null && clubIndex !== undefined ? (
                            <>
                              <div
                                style={{
                                  gap: "13px",
                                }}
                              >
                                <label>
                                  Unit Name<a style={{ color: "red" }}>*</a>
                                </label>

                                <input
                                  type="text"
                                  name="unitType"
                                  value={unit.unitType || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      e,
                                      floorIndex,
                                      unitIndex,
                                      towerIndex,
                                      clubIndex
                                    )
                                  }
                                  style={{ marginTop: "15px" }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <label>
                                Unit Type<a style={{ color: "red" }}>*</a>
                              </label>
                              <select
                                name="unitType"
                                value={
                                  floor.type === "Automatic"
                                    ? unit.unitType || "2BHK"
                                    : unit.unitType || ""
                                }
                                style={{ marginTop: "15px" }}
                                onChange={(e) =>
                                  handleChange(
                                    e,
                                    floorIndex,
                                    unitIndex,
                                    towerIndex,
                                    clubIndex
                                  )
                                }
                              >
                                <option value="">Select Type</option>
                                <option value="1BHK">1BHK</option>
                                <option value="2BHK">2BHK</option>
                                <option value="3BHK">3BHK</option>
                                <option value="4BHK">4BHK</option>
                                <option value="5BHK">5BHK</option>
                              </select>
                            </>
                          )}
                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            clubIndex !== null &&
                            clubIndex !== undefined &&
                            errors[
                              `unitType-${towerIndex}-${floorIndex}-${unitIndex}-${clubIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitType-${towerIndex}-${floorIndex}-${unitIndex}-${clubIndex}`
                                  ]
                                }
                              </p>
                            )}

                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            errors[
                              `unitType-${towerIndex}-${floorIndex}-${unitIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitType-${towerIndex}-${floorIndex}-${unitIndex}`
                                  ]
                                }
                              </p>
                            )}

                          {towerIndex !== null &&
                            towerIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            errors[`unitType-${towerIndex}-${floorIndex}`] && (
                              <p style={{ color: "red" }}>
                                {errors[`unitType-${towerIndex}-${floorIndex}`]}
                              </p>
                            )}

                          {clubIndex !== null &&
                            clubIndex !== undefined &&
                            floorIndex !== null &&
                            floorIndex !== undefined &&
                            unitIndex !== null &&
                            unitIndex !== undefined &&
                            errors[
                              `unitType-${clubIndex}-${floorIndex}-${unitIndex}`
                            ] && (
                              <p style={{ color: "red" }}>
                                {
                                  errors[
                                    `unitType-${clubIndex}-${floorIndex}-${unitIndex}`
                                  ]
                                }
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
  </>
);

export default FloorDetails;
