import React from "react";
const PdfIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 22 25" fill="none">
  <path d="M4.6875 0C3.82812 0 3.125 0.703125 3.125 1.5625V23.4375C3.125 24.2969 3.82812 25 4.6875 25H20.3125C21.1719 25 21.875 24.2969 21.875 23.4375V6.25L15.625 0H4.6875Z" fill="#E2E5E7"/>
  <path d="M17.1875 6.25H21.875L15.625 0V4.6875C15.625 5.54688 16.3281 6.25 17.1875 6.25Z" fill="#B0B7BD"/>
  <path d="M21.875 10.9375L17.1875 6.25H21.875V10.9375Z" fill="#CAD1D8"/>
  <path d="M18.75 20.3125C18.75 20.7422 18.3984 21.0938 17.9688 21.0938H0.78125C0.351562 21.0938 0 20.7422 0 20.3125V12.5C0 12.0703 0.351562 11.7188 0.78125 11.7188H17.9688C18.3984 11.7188 18.75 12.0703 18.75 12.5V20.3125Z" fill="#F15642"/>
  <path d="M3.4043 14.8023C3.4043 14.5961 3.5668 14.3711 3.82852 14.3711H5.27148C6.08398 14.3711 6.81523 14.9148 6.81523 15.957C6.81523 16.9445 6.08398 17.4945 5.27148 17.4945H4.22852V18.3195C4.22852 18.5945 4.05352 18.75 3.82852 18.75C3.62227 18.75 3.4043 18.5945 3.4043 18.3195V14.8023ZM4.22852 15.1578V16.7141H5.27148C5.69023 16.7141 6.02148 16.3445 6.02148 15.957C6.02148 15.5203 5.69023 15.1578 5.27148 15.1578H4.22852Z" fill="white"/>
  <path d="M8.03867 18.7504C7.83242 18.7504 7.60742 18.6379 7.60742 18.3637V14.8152C7.60742 14.591 7.83242 14.4277 8.03867 14.4277H9.46914C12.3238 14.4277 12.2613 18.7504 9.52539 18.7504H8.03867ZM8.43242 15.1902V17.9887H9.46914C11.1559 17.9887 11.2309 15.1902 9.46914 15.1902H8.43242Z" fill="white"/>
  <path d="M13.2742 15.24V16.233H14.8672C15.0922 16.233 15.3172 16.458 15.3172 16.676C15.3172 16.8822 15.0922 17.051 14.8672 17.051H13.2742V18.3627C13.2742 18.5814 13.1188 18.7494 12.9 18.7494C12.625 18.7494 12.457 18.5814 12.457 18.3627V14.8143C12.457 14.59 12.6258 14.4268 12.9 14.4268H15.093C15.368 14.4268 15.5305 14.59 15.5305 14.8143C15.5305 15.0143 15.368 15.2393 15.093 15.2393H13.2742V15.24Z" fill="white"/>
  <path d="M17.9688 21.0938H3.125V21.875H17.9688C18.3984 21.875 18.75 21.5234 18.75 21.0938V20.3125C18.75 20.7422 18.3984 21.0938 17.9688 21.0938Z" fill="#CAD1D8"/>
</svg>
  );
};
export default PdfIcon;