/* eslint-disable no-unused-vars */
import React, { useState, useEffect,useRef} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";


import AddNewPnMTableContent from "./AddNewPnMTableContent";
import AddNewPnMForm from "./AddNewPnMForm";
import AddNewPnMViewForm from "./AddNewPnMViewForm";
const AddnewPnm = () => {
  let webApi = new WebApimanager();
  const getDaysCountOptions = (daysType) => {
    let options = [];

    if (daysType === "Week") {
      options = Array.from({ length: 54 }, (_, i) => i + 1);
    } else if (daysType === "Month") {
      options = Array.from({ length: 12 }, (_, i) => i + 1);
    } else if (daysType === "Days") {
      options = Array.from({ length: 31 }, (_, i) => i + 1);
    } else if (daysType === "Years") {
      options = Array.from({ length: 5 }, (_, i) => i + 1);
    }

    return options;
  };

  const [open, setOpen] = useState(false);
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [filteredNames, setFilteredNames] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [documentsData, setDocumentsData] = useState([]);
  const [tableData,setTableData]=useState([])
  const [docErrors,setDocErrors] = useState(Array(documentsData.length).fill(''))
  const [showEditForm,setShowEditForm]= useState(false)
  const [showViewForm,setShowViewForm] = useState(false)
  const [trashopen, setTrashOpen] = useState(false);
  const [tableRowData,setTableRowData] = useState({})
  const [formId,setFormId] = useState(null);
  const [showFilter,setShowFilter] = useState(false)
  const [filterType,setFilterType] = useState("type")
  const [query,setQuery] = useState("")
  const [viewMode, setViewMode] = useState(false); 
  const [editMode, setEditMode] = useState(false);
  const [viewingIndex, setViewingIndex] = useState(null);
  const fileInputRef = useRef(null);
  const [id,setId] = useState('')

  const [apiProcessing, setApiProcessing] = useState({loader: false,message: "Loading...",});
  const [formData, setFormData] = useState({
    type: "",
    fuelRnot: "",
    assetCode:"",
    name:"",
    subCode:"",
    equipmentType:"",
    assetCode: "",
    makename: "",
    modelname: "",
    services:"",
    servicePeriodType: "",
    serviceHours: "",
    serviceDays: "",
    serviceMonths: "",
    serviceWeeks:"",
    kilometers: "",
    inspections: "",
    inspectionsPeriodType:"",
    inspectionYears: "",
    inspectionDays: "",
    inspectionMonths: "",
    inspectionWeeks:"",
    recycletimeperiod: "",
    engineRmotor: "",
    selectNo: "",

    OWN: "",
    oldPreviousServiceDate: "",
    oldPreviousPurchasePrice: "",
    oldPurchaseDate: "",
    oldinsuranceY: false,
    daysTypeoldinsurance: "",
    daysCountoldinsurance: "",
    oldwarrantyY: false,
    daysTypeoldwarranty: "",
    daysCountoldwarranty: "",
    oldguaranteeY: false,
    daysTypeoldguarantee: "",
    daysCountoldguarantee: "",
    newpurchaseDate: "",
    newpurchasePrice: "",
    newinsuranceY: false,
    daysTypenewinsurance: "",
    daysCountnewinsurance: "",
    newwarrantyY: false,
    daysTypenewwarranty: "",
    daysCountnewwarranty: "",
    newguaranteeY: false,
    daysTypenewguarantee: "",
    daysCountnewguarantee: "",
    oldrNew:"",
    hireVendorName:"",
    hireVendorNumber:"",
    hirePreviousServiceDate:"",
    daysTypeHireHowManyDays:"",
    daysCountHireHowManyDays:"",
    hireCharges:"",
    hirePrice:"",
    hireInsurance: false,
    daysTypeHireInsurance:"", 
    daysCountHireInsurance:"",
    hireGuarantee:false,
    daysTypeHireGuarantee:"",
    daysCountHireGuarantee:"",
    document:documentsData,
    hireWarranty:false,
    daysTypeHireWarranty:"",
    daysCountHireWarranty:"",

    queries: [],

  });
  useEffect(()=>{
    console.log(tableRowData,"tableRowData")
    if(Object.keys(tableRowData).length){
      console.log(tableRowData.equipmentType)
      console.log(tableRowData.assetCode.assetCode+"/"+tableRowData.assetCode.name)
      console.log(tableRowData.type)
      if(activeStep === 0){
        let tempFormData ={
          type:tableRowData.type,
          fuelRnot:tableRowData.fuelRnot,
          assetCode:tableRowData.assetCode.assetCode+"/"+tableRowData.assetCode.name ,
          equipmentType:tableRowData.equipmentType,
          makename: tableRowData.makeName,
          modelname: tableRowData.modelName,
          services:tableRowData.services,
          inspections: tableRowData.inspections,
          engineRmotor: tableRowData.engineRmotor,
          selectNo: tableRowData.selectNo,
          OWN: "",
          subCode:tableRowData.assetCode.subCode,
          name:tableRowData.assetCode.name,
          
          
      }
      if(tableRowData.services === "Yes"){
        tempFormData = {
          ...tempFormData,
          servicePeriodType: tableRowData.servicePeriod.periodType,
          ...(tableRowData.servicePeriod.periodType === "Weeks" && {
            serviceWeeks: tableRowData.servicePeriod.weeks
          }),
          ...(tableRowData.servicePeriod.periodType === "Months" && {
            serviceMonths: tableRowData.servicePeriod.months
          }),
          ...(tableRowData.servicePeriod.periodType === "Hours" && {
            serviceHours: tableRowData.servicePeriod.hours
          }),
          ...(tableRowData.servicePeriod.periodType === "Days" && {
            serviceDays: tableRowData.servicePeriod.days
          }),
          ...(tableRowData.servicePeriod.periodType === "Kilometers" && {
            kilometers: tableRowData.servicePeriod.kilometers
          }),
          ...(tableRowData.servicePeriod.periodType === "Both Months & Kilometers" && {
            serviceMonths: tableRowData.servicePeriod.months,
            kilometers: tableRowData.servicePeriod.kilometers
          })
        };
        
      }
      if(tableRowData.inspections === "Yes"){
        tempFormData = {
          ...tempFormData,
          inspectionsPeriodType: tableRowData.inspectionPeriod.periodType,
          ...(tableRowData.inspectionPeriod.periodType === "Weeks" && {
            inspectionWeeks: tableRowData.inspectionPeriod.weeks
          }),
          ...(tableRowData.inspectionPeriod.periodType === "Months" && {
            inspectionMonths: tableRowData.inspectionPeriod.months
          }),
          ...(tableRowData.inspectionPeriod.periodType === "Years" && {
            inspectionYears: tableRowData.inspectionPeriod.years
          }),
          ...(tableRowData.inspectionPeriod.periodType === "Days" && {
            inspectionDays: tableRowData.inspectionPeriod.days
          }),
          
        };
        

      }
      if (tableRowData.type === "own"){
      
       if(tableRowData.own === "Old"){
        tempFormData={...tempFormData,
          oldrNew:tableRowData.own,
          oldPreviousServiceDate: tableRowData.oldPreviousServiceDate.slice(0,10),
          oldPreviousPurchasePrice: tableRowData.oldPreviousPurchasePrice,
          oldPurchaseDate: tableRowData.oldPurchaseDate.slice(0,10)
        }
        if(tableRowData?.oldInsurance && Object.keys(tableRowData?.oldInsurance).length){
          tempFormData = {...tempFormData,
            oldinsuranceY:true,
            daysTypeoldinsurance: tableRowData.oldInsurance.daysType,
            daysCountoldinsurance: tableRowData.oldInsurance.daysCount,
          }
        }
        if(tableRowData?.oldGuarantee && Object.keys(tableRowData?.oldGuarantee).length){
          tempFormData={...tempFormData,
            oldguaranteeY:true,
            daysTypeoldguarantee: tableRowData.oldGuarantee.daysType,
    daysCountoldguarantee: tableRowData.oldGuarantee.daysCount,
          }
        }
        if(tableRowData?.oldWarranty && Object.keys(tableRowData?.oldWarranty).length){
          tempFormData={...tempFormData,
            oldwarrantyY:true,
            daysTypeoldwarranty: tableRowData.oldWarranty.daysType,
            daysCountoldwarranty: tableRowData.oldWarranty.daysCount,
          }
        }
       }
       if(tableRowData.own === "New"){
        tempFormData = {...tempFormData,
          oldrNew:tableRowData.own,
          newpurchaseDate:tableRowData.newPurchaseDate.slice(0,10),
          newpurchasePrice: tableRowData.newPurchasePrice,
        }
       
        if(tableRowData?.newInsurance && Object.keys(tableRowData?.newInsurance).length){
          tempFormData = {...tempFormData,
            newinsuranceY:true,
            daysTypenewinsurance: tableRowData.newInsurance.daysType,
            daysCountnewinsurance: tableRowData.newInsurance.daysCount,
          }
        }
        if(tableRowData?.newGuarantee && Object.keys(tableRowData?.newGuarantee).length){
          tempFormData={...tempFormData,
            newguaranteeY:true,
            daysTypenewguarantee: tableRowData.newGuarantee.daysType,
    daysCountnewguarantee: tableRowData.newGuarantee.daysCount,
          }
        }
        if(tableRowData?.newWarranty && Object.keys(tableRowData?.newWarranty).length){
          tempFormData={...tempFormData,
            newwarrantyY:true,
            daysTypenewwarranty: tableRowData.newWarranty.daysType,
            daysCountnewwarranty: tableRowData.newWarranty.daysCount,
          }
        }
         
        }
        
      } else {
        tempFormData={...tempFormData,
          hireVendorName:tableRowData.hireVendorName,
          hireVendorNumber:tableRowData.hireVendorNumber,
          hirePreviousServiceDate:tableRowData.hirePreviousServiceDate.slice(0,10),
          hireCharges:tableRowData.hireCharges,
          hirePrice:tableRowData.hirePrice,
          daysTypeHireHowManyDays:tableRowData.daysTypeHireHowManyDays,
          daysCountHireHowManyDays:tableRowData.daysCountHireHowManyDays,
        }
        if(tableRowData?.hireInsurance && Object.keys(tableRowData?.hireInsurance).length){
          tempFormData = {...tempFormData,
            hireInsurance:true,
            daysTypeHireInsurance: tableRowData.hireInsurance.daysType,
            daysCountHireInsurance: tableRowData.hireInsurance.daysCount,
          }
        }
        if(tableRowData?.hireGuarantee && Object.keys(tableRowData?.hireGuarantee).length){
          tempFormData={...tempFormData,
            hireGuarantee:true,
            daysTypeHireGuarantee: tableRowData.hireGuarantee.daysType,
            daysCountHireGuarantee: tableRowData.hireGuarantee.daysCount,
          }
        }
        if(tableRowData?.hireWarranty && Object.keys(tableRowData?.hireWarranty).length){
          tempFormData={...tempFormData,
            hireWarranty:true,
            daysTypeHireWarranty: tableRowData.hireWarranty.daysType,
            daysCountHireWarranty: tableRowData.hireWarranty.daysCount,
          }
        }
      }
      setFormData(tempFormData)

      }
      if(activeStep === 1 && showEditForm){
        if(tableRowData?.documents){
          const tabRowData = tableRowData.documents.map((item) => {
            return {
                ...item,
                ["regDate"]: item.regDate.slice(0, 10),
                ["expDate"]: item.expDate.slice(0, 10),
                documentFile: item.documentFile ? item.documentFile : null, 
            };
        });
          console.log(tabRowData,"tabRowData")
          setDocumentsData(tabRowData)
          setFormData((prev)=>({...prev,document:tabRowData}))
        }
      }
      
      
    //   console.log(tempFormData,"aferrrrrrrrr")
    // setFormData(tempFormData)
    console.log("AssetCodeData",assetCodeData)
    setFilteredAssets(assetCodeData)
    setId(tableRowData.assetCode._id)
    }
  },[showEditForm,activeStep,tableRowData])

  
  
  
  useEffect(()=>{
    console.log(documentsData,"documentsDataEditForm")
    
    setFormData((prev)=>({...prev,document:documentsData}))
   
    
  },[documentsData])


  const [inputFields, setInputFields] = useState([{ name: "" }]);
  const [equipment, setEquipment] = useState('')
  const handleAddFields = () => {
    setInputFields([...inputFields, { name: "" }]);
  };
  

  
//   const changeHandler = (e, index) => {

//     const { name, value, type, files } = e.target;
// console.log(name,value,"changeHandler")
//     const newDocumentsData = [...documentsData];
//     const documentName = newDocumentsData[index]?.documentName || filteredAssets.find((asset) => asset._id === id)?.documents[index]?.documentName || "";
//     if (type === "file") {
//       e.stopPropagation()
//       newDocumentsData[index] = {
//         ...newDocumentsData[index],
//         file: files[0]  

//       };
//     } else {

//       newDocumentsData[index] = {
//         ...newDocumentsData[index],
//         [name.split('_')[0]]: value,
//         documentName: documentName 
//       };
//     }

//     setDocumentsData(newDocumentsData);
//   };

  // useEffect(() => {
  //   if (formData.assetCode) {
  //     const asset = filteredAssets.find(
  //       (asset) => asset._id === formData.assetCode
  //     );
  //     if (asset) {
  //       const initialDocumentsData = asset.documents.map(() => ({}));
  //       setDocumentsData(initialDocumentsData);
  //     }
  //   }
  // }, [formData.assetCode, filteredAssets]);
  const changeHandler = (e, index) => {
    const { name, value, type, files } = e.target;
    console.log(name, value, "changeHandler");
  
    const newDocumentsData = [...documentsData]; // Copy the current state
  
    // Find the document name, either from current data or filteredAssets
    const documentName = newDocumentsData[index]?.documentName || 
      filteredAssets.find((asset) => asset._id === id)?.documents[index]?.documentName || "";
  
    // if (type === "file") {
    //   console.log("file inside if");
    //   e.stopPropagation(); // Stop event propagation for file upload
    //   newDocumentsData[index] = {
    //     ...newDocumentsData[index], 
    //     file: files[0] // Add the file to the document data
    //   };
    if (type === "file") {
      console.log("file inside if");
      e.stopPropagation();
      if(!showEditForm){
        newDocumentsData[index] = {
          ...newDocumentsData[index], 
          file: files[0],
          // documentFile:files[0]
        };

      } else {
        newDocumentsData[index] = {
          ...newDocumentsData[index], 
          file: files[0],
          documentFile:`/${files[0].name}`
        };
      }
  //  }
   
      // console.log("file outside if",file);
    } else {
      console.log("file inside else");
      newDocumentsData[index] = {
        ...newDocumentsData[index],
        [name.split('_')[0]]: value, // Handle regular inputs
        documentName: documentName // Preserve or assign the documentName
      };
      // console.log("file outside else",file);
    }
  
    // Update the documentsData state
    setDocumentsData(newDocumentsData);
  };
  useEffect(() => {
    console.log(documentsData);
  }, [documentsData]);
  useEffect(() => {
    if (formData.assetCode) {
      const asset = filteredAssets.find(
        (asset) => asset._id === formData.assetCode
      );

      if (asset) {
        const initialDocumentsData = asset.documents.map((doc) => ({
          documentName: doc.documentName,  // Include documentName here
          documentNo: "",                   // Initialize empty fields
          regDate: "",
          expDate: "",
          file: null,                      // Initialize file as null
        }));

        setDocumentsData(initialDocumentsData);
      }
      console.log(documentsData,"doc data");
    }
  }, [formData.assetCode, filteredAssets,tableRowData]);

  const currentAsset = filteredAssets.find(
    (asset) => asset._id === formData.assetCode
  );
  const documents = currentAsset?.documents || [];

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    setFormData((prev) => ({
      ...prev,
      queries: values,
    }));
  };

  const [errors, setErrors] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowForm(false);
    setFormData({});
    setShowViewForm(false)
    setActiveStep(0)
    setShowEditForm(false)
    setDocumentsData([])
  };
  

  const validateDocuments = () => {
    let isValid = true;
    const newErrors = {};

    documentsData.forEach((doc, index) => {
      const formErrors = {};

      // Validate document number
      if (!doc.documentNo || doc.documentNo.trim() === "") {
        formErrors.documentNo = "Document Number is required";
        isValid = false;
      }

      // Validate registration date
      if (!doc.regDate) {
        formErrors.regDate = "Registration Date is required";
        isValid = false;
      }

      // Validate expiry date
      if (!doc.expDate) {
        formErrors.expDate = "Expiry Date is required";
        isValid = false;
      }

      // Validate file upload
      if (!showEditForm) {
      if (!doc.file) {
        formErrors.file = "File is required";
        isValid = false;
      }
    }
  
      // Store errors for the specific document
      if (Object.keys(formErrors).length > 0) {
        newErrors[index] = formErrors;
      }
    });

    setDocErrors(newErrors);
    return isValid;
  };

  const validateForm1 = () =>{
    let formErrors = {};
    const nameRegex =/^[a-zA-Z]+(?: [a-zA-Z]+)*$/
    const mobRegex =/^[6-9]\d{9}$/
    const requiredFields=[
      "type",
      "equipmentType",
      "assetCode",
      "fuelRnot",
      "makename",
      "modelname",
      "services",
      "inspections",
      "engineRmotor",
      "selectNo",

    ]
    requiredFields.forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
      if(formData[key] && key === "makename"){
        if(!nameRegex.test(formData[key])){
          formErrors[key] = "make name should be alphabets"
        } 
      }
    });
    if(formData.services==="Yes"){
      if(!formData.servicePeriodType){
        formErrors["servicePeriodType"]="servicePeriodType is required"
      } else {
        if(formData.servicePeriodType === "Weeks" && !formData.serviceWeeks)
          formErrors["serviceWeeks"] ="service Weeks is required"

        else if(formData.servicePeriodType === "Months" && !formData.serviceMonths)
          formErrors["serviceMonths"] ="service Months is required"

        else if(formData.servicePeriodType === "Hours" && !formData.serviceHours)
          formErrors["serviceHours"] ="service Hours is required"

        else if(formData.servicePeriodType === "Days" && !formData.serviceDays)
          formErrors["serviceDays"] ="service Days is required"

        else if(formData.servicePeriodType === "Kilometers" && !formData.kilometers)
          formErrors["kilometers"] ="kilometer is required"
        else if(formData.servicePeriodType === "Both Months & Kilometers") {
          if(!formData.serviceMonths){
            formErrors["serviceMonths"]="service Months is required"
          } else if(!formData.kilometers) {
            formErrors["kilometers"]="Kilometer is required"
          }

        }
      }
    }

    if(formData.inspections==="Yes"){
      if(!formData.inspectionsPeriodType){
        formErrors["inspectionsPeriodType"]="inspectionsPeriodType is required"
      } else {
        if(formData.inspectionsPeriodType === "Weeks" && !formData.inspectionWeeks)
          formErrors["inspectionWeeks"] ="inspection Weeks is required"

        else if(formData.inspectionsPeriodType === "Month" && !formData.inspectionMonths)
          formErrors["inspectionMonths"] ="inspection Months is required"

        else if(formData.inspectionsPeriodType === "Years" && !formData.inspectionYears)
          formErrors["inspectionYears"] ="inspection Hours is required"

        else if(formData.inspectionsPeriodType === "Days" && !formData.inspectionDays)
          formErrors["inspectionDays"] ="inspection Days is required"


      }
    }
    if(formData.type === "own"){
      if(!formData.oldrNew){

        formErrors["oldrNew"]="oldRnew is Required"
      } else {
        if(formData.oldrNew ==="New" ){
          const newRequiredFields=[
            "newpurchaseDate",
            "newpurchasePrice",
          ]
          newRequiredFields.forEach((key) => {
            if (!formData[key]) {
              formErrors[key] = `${key} is required`;
            }
          });

          if(formData.newinsuranceY){
            if(!formData.daysTypenewinsurance){
              formErrors["daysTypenewinsurance"]="daysTypenewinsurance is required"
            } else {
              if(!formData.daysCountnewinsurance){
                formErrors["daysCountnewinsurance"]="daysCountnewinsurance is required"
              }
            }
          }
          if(formData.newguaranteeY){
            if(!formData.daysTypenewguarantee){
              formErrors["daysTypenewguarantee"]="daysTypenewguarantee is required"
            } else {
              if(!formData.daysCountnewguarantee){
                formErrors["daysCountnewguarantee"]="daysCountnewguarantee is required"
              }
            }
          }
          if(formData.newwarrantyY){
            if(!formData.daysTypenewwarranty){
              formErrors["daysTypenewwarranty"]="daysTypenewwarranty is required"
            } else {
              if(!formData.daysCountnewwarranty){
                formErrors["daysCountnewwarranty"]="daysCountnewwarranty is required"
              }
            }
          }

        } else {
          const oldRequiredFields =[
            "oldPreviousServiceDate",
            "oldPreviousPurchasePrice",
            "oldPurchaseDate",
          ]
          oldRequiredFields.forEach((key) => {
            if (!formData[key]) {
              formErrors[key] = `${key} is required`;
            }
          });
          if(formData.oldinsuranceY){
            if(!formData.daysTypeoldinsurance){
              formErrors["daysTypeoldinsurance"]="daysTypeoldinsurance is required"
            } else {
              if(!formData.daysCountoldinsurance){
                formErrors["daysCountoldinsurance"]="daysCountoldinsurance is required"
              }
            }
          }
          if(formData.oldguaranteeY){
            if(!formData.daysTypeoldguarantee){
              formErrors["daysTypeoldguarantee"]="daysTypeoldguarantee is required"
            } else {
              if(!formData.daysCountoldguarantee){
                formErrors["daysCountoldguarantee"]="daysCountoldguarantee is required"
              }
            }
          }
          if(formData.oldwarrantyY){
            if(!formData.daysTypeoldwarranty){
              formErrors["daysTypeoldwarranty"]="daysTypeoldwarranty is required"
            } else {
              if(!formData.daysCountoldwarranty){
                formErrors["daysCountoldwarranty"]="daysCountoldwarranty is required"
              }
            }
          }

        }
      }
    } else {
      const hireRequiredFields =[
        "hireVendorName",
        "hireVendorNumber",
        "hirePreviousServiceDate",
        "hireCharges",
        "daysTypeHireHowManyDays",

      ]
      hireRequiredFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
        if(formData[key] && key === "hireVendorName"){
          if(!nameRegex.test(formData[key])){
            formErrors[key] = "Vendor name should be alphabets"
          } 
        }
        if( key === "hireVendorNumber" && formData[key]){
          if(!mobRegex.test(formData[key])){
            formErrors[key]="Enter a valid Mob No"
          }
        }
      });

      if(formData.hireCharges && !formData.hirePrice){

        formErrors["hirePrice"]="hire Price is required"
      }
      if(formData.daysTypeHireHowManyDays && !formData.daysCountHireHowManyDays){
        formErrors["daysCountHireHowManyDays"]="daysCountHireHowManyDays is required"
      }

      if(formData.hireInsurance){
        if(!formData.daysTypeHireInsurance){
          formErrors["daysTypeHireInsurance"]="daysTypeHireInsurance is required"
        } else {
          if(!formData.daysCountHireInsurance){
            formErrors["daysCountHireInsurance"]="daysCountHireInsurance is required"
          }
        }
      }
      if(formData.hireGuarantee){
        if(!formData.daysTypeHireGuarantee){
          formErrors["daysTypeHireGuarantee"]="daysTypeHireGuarantee is required"
        } else {
          if(!formData.daysCountHireGuarantee){
            formErrors["daysCountHireGuarantee"]="daysCountHireGuarantee is required"
          }
        }
      }
      if(formData.hireWarranty){
        if(!formData.daysTypeHireWarranty){
          formErrors["daysTypeHireWarranty"]="daysTypeHireWarranty is required"
        } else {
          if(!formData.daysCountHireWarranty){
            formErrors["daysCountHireWarranty"]="daysCountHireWarranty is required"
          }
        }
      }

    }


    console.log(formErrors,"formErrors")
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  }

  const handleChange = (e) => {
    setErrors({});
    const { name, value, type, checked, files } = e.target;
    console.log("name:=>",name)
    console.log("checked:=>",value)

    // console.log(e.target.checked,"check")
    let newValue = value;
    if (type === "checkbox") {
      newValue = checked;
    }
    if (type === "file") {
      newValue = files[0];
    }

    // Additional logic for kilometers validation and filtered assets update
    if (name === "kilometers" && (value === "" || parseFloat(value) >= 0)) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    }

    // Update filtered assets and names
    updateFilteredAssetsAndNames(name, newValue);

    // Set selected type
    if (name === "type") {
      setSelectedType(newValue);
    }
    if(name === "equipmentType"){
      setEquipment(value)
    }



    if (name === "assetCode" && value) {
      const assCode = assetCodeData.find(
        (doc, ind) => doc.assetCode === value.split("/")[0]
      );
      if (assCode) {
        setId(assCode._id);



        setFormData((prevData) => ({
              ...prevData,
              [name]: value,
              ["subCode"]:assCode.subCode,
              ["name"]:value.split("/")[1],
              ["equipmentType"]:assCode.type
            }));
      }
    }

  };



  const updateFilteredAssetsAndNames = (fieldName, fieldValue) => {
    let updatedFilteredAssets = [...assetCodeData];
    let updatedFilteredNames = [...assetCodeData];

    if (fieldName === "type") {
      updatedFilteredAssets = assetCodeData.filter(
        (item) => item.type === fieldValue
      );
      updatedFilteredNames = assetCodeData.filter(
        (item) => item.type === fieldValue
      );
    }

    if (fieldName === "assetCode") {
      updatedFilteredNames = assetCodeData.filter(
        (item) => item.assetCode === fieldValue
      );
    }

    if (fieldName === "name") {
      updatedFilteredAssets = assetCodeData.filter(
        (item) => item.name === fieldValue
      );
    }

    setFilteredAssets(updatedFilteredAssets);
    setFilteredNames(updatedFilteredNames);
  };






  const handleBack = () => {
    if(activeStep > 0)
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleNext = () => {
    console.log(formData,"hanldeNextFormData")
    console.log(documentsData,"handleNextDocumentsData")
    console.log(filteredAssets,"filteredA")
    if ( activeStep === 0 && id) {
      const dilt=filteredAssets.find((asset)=>asset._id === id)
      console.log("filter",dilt)

      const documentsInfo = filteredAssets.find((asset) => asset._id === id)
      ?.documents;
      console.log(documentsInfo,"documentsInfo")

      const docInfo = []
      console.log(documentsInfo,"documentInfo")
      console.log(dilt,"filteredAsset")

      documentsInfo.forEach((item,index)=>{
        docInfo.push({
          documentNo: "",
            documentName:  item,
            regDate: "",
            expDate: "",
            file: null,
        })
      })
      setDocumentsData(docInfo)
      
    }
    if (activeStep === 1) {
      setFormData((prev) => ({ ...prev, document: documentsData }));
      const filtDoc = filteredAssets
                        .find((asset) => asset._id === id)
      if(filtDoc.documents[0]!==''){
      if(!validateDocuments() ) return
      }
    }

    if (activeStep === 2) {

      handleSubmit();
    } else {
      if(activeStep === 0){
        if(!validateForm1()) return 
      }
      // else if(activeStep === 1){
      //   if(!validateForm2()) return
      // }
      setActiveStep((prevStep) => prevStep + 1);
      console.log(formData,"formDatastep0")
    }
  };

  useEffect(() => {
    console.log(formData.document, "documents");
  }, [showEditForm]);

  
  const handleSubmit = async () => {
    console.log(formData, "insideHandleSubmit");
    const payload = {
      type: formData.type,
      assetCode: id,
      equipmentType: formData.equipmentType,
      fuelRnot: formData.fuelRnot,
      makeName: formData.makename,
      modelName: formData.modelname,
      engineRmotor: formData.engineRmotor,
      selectNo: formData.selectNo,
      services: formData.services,
      ...(formData.services === "Yes" && {
        servicePeriod: {
          periodType: formData.servicePeriodType,
          ...(formData.servicePeriodType === "Hours" && { hours: formData.serviceHours }),
          ...(formData.servicePeriodType === "Days" && { days: formData.serviceDays }),
          ...(formData.servicePeriodType === "Month" && { months: formData.serviceMonths }),
          ...(formData.servicePeriodType === "Weeks" && { weeks: formData.serviceWeeks }),
          ...(formData.servicePeriodType === "kilometers" && { kilometers: formData.kilometers }),
          ...(formData.servicePeriodType === "Both Months & Kilometers" && {
            months: formData.serviceMonths,
            kilometers: formData.kilometers,
          }),
        },
      }),
      inspections: formData.inspections,
      ...(formData.inspections === "Yes" && {
        inspectionPeriod: {
          periodType: formData.inspectionsPeriodType,
          ...(formData.inspectionsPeriodType === "Weeks" && { weeks: formData.inspectionWeeks }),
          ...(formData.inspectionsPeriodType === "Years" && { years: formData.inspectionYears }),
          ...(formData.inspectionsPeriodType === "Days" && { days: formData.inspectionDays }),
          ...(formData.inspectionsPeriodType === "Month" && { months: formData.inspectionMonths }),
        },
      }),
      ...(formData.type === "hire" && {
        hireVendorName: formData.hireVendorName,
        hireVendorNumber: formData.hireVendorNumber,
        hirePreviousServiceDate: formData.hirePreviousServiceDate,
        ...(formData.hireInsurance && {
          hireInsurance: {
            daysType: formData.daysTypeHireInsurance,
            daysCount: formData.daysCountHireInsurance,
          },
        }),
        ...(formData.hireGuarantee && {
          hireGuarantee: {
            daysType: formData.daysTypeHireGuarantee,
            daysCount: formData.daysCountHireGuarantee,
          },
        }),
        ...(formData.hireWarranty && {
          hireWarranty: {
            daysType: formData.daysTypeHireWarranty,
            daysCount: formData.daysCountHireWarranty,
          },
        }),
        daysTypeHireHowManyDays: formData.daysTypeHireHowManyDays,
        daysCountHireHowManyDays: formData.daysCountHireHowManyDays,
        hireCharges: formData.hireCharges,
        hirePrice: formData.hirePrice,
      }),
      ...(formData.type === "own" && {
        own: formData.oldrNew,
        ...(formData.oldrNew === "Old" && {
          oldPreviousServiceDate: formData.oldPreviousServiceDate,
          oldPreviousPurchasePrice: formData.oldPreviousPurchasePrice,
          oldPurchaseDate: formData.oldPurchaseDate,
          ...(formData.oldinsuranceY && {
            oldInsurance: {
              daysType: formData.daysTypeoldinsurance,
              daysCount: formData.daysCountoldinsurance,
            },
          }),
          ...(formData.oldwarrantyY && {
            oldWarranty: {
              daysType: formData.daysTypeoldwarranty,
              daysCount: formData.daysCountoldwarranty,
            },
          }),
          ...(formData.oldguaranteeY && {
            oldGuarantee: {
              daysType: formData.daysTypeoldguarantee,
              daysCount: formData.daysCountoldguarantee,
            },
          }),
        }),
        ...(formData.oldrNew === "New" && {
          newPurchasePrice: formData.newpurchasePrice,
          newPurchaseDate: formData.newpurchaseDate,
          ...(formData.newinsuranceY && {
            newInsurance: {
              daysType: formData.daysTypenewinsurance,
              daysCount: formData.daysCountnewinsurance,
            },
          }),
          ...(formData.newwarrantyY &&{
            newWarranty: {
              daysType: formData.daysTypenewwarranty,
              daysCount: formData.daysCountnewwarranty,
          }
          
          }),
          ...(formData.newguaranteeY &&{
            newGuarantee: {
            daysType: formData.daysTypenewguarantee,
            daysCount: formData.daysCountnewguarantee,
          },
          
          }),
        }),
      }),
    };
    // First API Call (POST)
    if(Object.keys(tableRowData).length && showEditForm){
      const ID = tableRowData._id
      try {
        const response = await webApi.put(`api/newPnm/newpnm/${ID}`,payload)
        
        console.log("put Server Response:", response);
        const id = response.data.data.updatedDocument._id;
        console.log(id, "idresponse");
        if (response.status === 201 || response.status === 200) {
          console.log(documentsData, "Before document processing");
          // Second API Call (PUT for Documents)
            const Documents = documentsData.map((doc) => ({
              documentName: doc.documentName || "Unnamed Document",
              documentNo: doc.documentNo || "N/A",
              regDate: doc.regDate || null,
              expDate: doc.expDate || null,
            }));
          
            const documentsDataPayload = {
              documents: Documents,
            };
            try {
              const uploadResponse = await webApi.put(`api/newPnm/update/${id}`, documentsDataPayload);
              console.log("Upload response:", uploadResponse);
              console.log(documentsData,"Before Success")
              if ((response.status === 201 || response.status === 200 )&& documentsData) {
                console.log(documentsData,"afterScuccess")
                const imageDocuments = documentsData.map((doc) => ({
                    [doc.documentName]: doc.file?doc?.file?.name:doc.documentFile.split("-",2)[1]
                }));
                const documentsUpload={
                  documents:imageDocuments
                }
                console.log("documentsUpload",documentsUpload)
                console.log("DocData",documentsData)
                console.log(documentsUpload.documents,"documentsUpload")
                
  
              const formData = new FormData();
  
  documentsData.forEach((doc) => {
    console.log(doc,"docsssssssssss")
      if (doc.file) {
          const documentPath = doc.file; // Construct the document path
          console.log(documentPath,"documentPath")
          formData.append("documents", documentPath); // Append the document path or file
      } else if(doc.documentFile){
        console.log(doc.documentFile.split("-",2)[1],"doc.documentFile")
        formData.append("documents",doc.documentFile.split("-",2)[1])
      }
  });
  for(const pair of formData.entries()){
    console.log(pair[0],"pair[0]")
    console.log(pair[1],"pair[1]")
  }
                try {
                  const imageUploadResponse = await webApi.imagePut(`api/newPnm/upload/${id}`, formData);
                  console.log("Image Upload response:", imageUploadResponse);
                  if(imageUploadResponse.status === 200){
                    window.location.reload()
                  }
                } catch (error) {
                  console.error("Image Upload Error:", error);
                }
              }
            } catch (error) {
              console.error("Document Upload Error:", error);
            }
            setApiProcessing({ loader: false, message: "" });
          }
        } catch (error) {
          console.error("Form Submit Error:", error);
         
        }
    } else {
      try {
      
        const response = await webApi.post("api/newPnm/", payload);
        console.log("Server Response:", response);
        const id = response.data.data._id;
        console.log(id, "idresponse");
        if (response.status === 201) {
          console.log(documentsData, "Before document processing");
          // Second API Call (PUT for Documents)
            const Documents = documentsData.map((doc) => ({
              documentName: doc.documentName || "Unnamed Document",
              documentNo: doc.documentNo || "N/A",
              regDate: doc.regDate || null,
              expDate: doc.expDate || null,
            }));
          
            const documentsDataPayload = {
              documents: Documents,
            };
            try {
              const uploadResponse = await webApi.put(`api/newPnm/update/${id}`, documentsDataPayload);
              console.log("Upload response:", uploadResponse);
              console.log(documentsData,"Before Success")
              if (response.status === 201 && documentsData) {
                console.log(documentsData,"afterScuccess")
                const imageDocuments = documentsData.map((doc) => ({
                    [doc.documentName]: doc.file?.name,
                }));
                const documentsUpload={
                  documents:imageDocuments
                }
                console.log("documentsUpload",documentsUpload)
                console.log("DocData",documentsData)
                console.log(documentsUpload.documents,"documentsUpload")
                
  
              const formData = new FormData();
  
  documentsData.forEach((doc) => {
      if (doc.file) {
          const documentPath = doc.file; 
  
          formData.append("documents", documentPath);
      }
  });
  
  // Log the FormData entries to verify what is being sent
  for (let entry of formData.entries()) {
      console.log(entry[0], "=>", entry[1]);
  }
                try {
                  const imageUploadResponse = await webApi.imagePut(`api/newPnm/upload/${id}`, formData);
                  console.log("Image Upload response:", imageUploadResponse);
                  if(imageUploadResponse.status === 200){
                    window.location.reload()
                  }
                } catch (error) {
                  console.error("Image Upload Error:", error);
                }
              }
            } catch (error) {
              console.error("Document Upload Error:", error);
            }
            setApiProcessing({ loader: false, message: "" });
          }
        } catch (error) {
          console.error("Form Submit Error:", error);
         
        }
    }
    
   
  };
  

  useEffect(() => {
    const fetchAssetCodeData = async () => {
      try {
        const response = await webApi.get(`api/pnms/assetCode`);
        const assetCodes = response.data.data.AssetCodes;
        setAssetCodeData(assetCodes);
      } catch (error) {
        console.error("Error fetching asset codes:", error);
        // Handle error fetching asset codes
      }
    };

    fetchAssetCodeData();
  }, [tableRowData]);

  const fetchTableData = async()=>{
    try{
      const response = await webApi.get("api/newPnm")
      console.log("AddNwePnMGetTableApi",response.data.data.newPnms)
      if(response.data && Array.isArray(response.data.data.newPnms)){
        setTableData(response.data.data.newPnms)
      } else {
        console.log("AddPnMTableApi Unexpected Data format",response.data)
      }

    } catch(error) {
      console.log("Add New PnM ::",error)
    }
  }

  useEffect(()=>{
    fetchTableData()
  },[])

  const handleEditOpen = (tabListData)=> {
    setTableRowData(tabListData)
    setShowEditForm(true)
    setShowViewForm(false)
    setShowForm(true)
    setOpen(true)
  }
  const handleViewOpen = (tabListData) =>{
    console.log(filteredAssets,"insideEdit")
    setShowEditForm(false)
    setTableRowData(tabListData)
    setShowViewForm(true)
    setShowForm(true)
    setOpen(true)
    
  }
  const handleTrash =(id) => {
    setTrashOpen(true);
    setFormId(id);
  }
  const handleTrashClose = () => {
    setTrashOpen(false);
    setFormId("");
  };
  
  const handleDelete = async (Id) => {
    setApiProcessing({ loader: true, message: "Deleting..." });
    const apiEndpoint = `api/newPnm/newpnm/${Id}`;
  
    try {
      const response = await webApi.delete(apiEndpoint);
      console.log(`Deleted successfully:`, response.data);
      fetchTableData(); 
      handleTrashClose(); 
    } catch (error) {
      console.error(`Error deleting:`, error);
    } finally {
      setApiProcessing({ loader: false, message: "" }); 
    }
  };
  

  return (
    <>
      
      {!showForm && <AddNewPnMTableContent 
      setFormData={setFormData} setDocumentsData={setDocumentsData} setShowForm={setShowForm}  setOpen={setOpen} query={query} setQuery={setQuery} setShowFilter={setShowFilter} setFilterType={setFilterType} showFilter={showFilter}
      trashopen={trashopen} handleTrashClose={handleTrashClose} handleDelete={handleDelete} tableData={tableData} filterType={filterType} handleEditOpen={handleEditOpen} handleViewOpen={handleViewOpen} handleTrash={handleTrash} apiProcessing={apiProcessing} formId={formId} />}
      
      

      {showForm && (
        <AddNewPnMForm 
          open={open}
          showForm={showForm}
          handleClose={handleClose}
          showEditForm={showEditForm}
          showViewForm={showViewForm}
          handleSubmit={handleSubmit}
          activeStep={activeStep}
          formData={formData}
          handleChange={handleChange}
          filteredAssets={filteredAssets}
          changeHandler={changeHandler}
          setViewingIndex={setViewingIndex}
          setViewMode={setViewMode}
          setEditMode={setEditMode}
          documentsData={documentsData}
          errors={errors}
          getDaysCountOptions={getDaysCountOptions}
          assetCodeData={assetCodeData}
          equipment={equipment}
          id={id}
          docErrors={docErrors}
          fileInputRef={fileInputRef}
          viewMode={viewMode}
          viewingIndex={viewingIndex}
          handleBack={handleBack}
          handleNext={handleNext}
          editMode={editMode}
          tableRowData={tableRowData}
          setDocumentsData={setDocumentsData}


        />

      )}
      {showViewForm && <AddNewPnMViewForm open={open} handleClose={handleClose} tableRowData={tableRowData}/>}

    </>
  );
};

export default AddnewPnm;