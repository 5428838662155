import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import * as Icon from "react-bootstrap-icons";
import { useRecoilState } from "recoil";
import { folderId, siteId } from "../../../atoms";
import { todayDate } from "../../../Utilities/DateUtils";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import TableWrapper from "../../../Utilities/TableWrapper";
import SelectSite from "../../../Utilities/SelectSite";
import SitesDropdown from "../../../Utilities/SitesDropdown";
import { Button, } from "react-bootstrap";
import { BsDashCircleFill } from "react-icons/bs";
import { Tabs, Tab } from "../../Tabs/Tabv1";
import RegisterPage from "../RegisterPage";
import FolderDropdown from "../../../Utilities/FolderDropdown";
import AddCategory from "./AddCategory";
import "./AddRegister.css"
const AddRegister = (props) => {
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const todayDateString = today.toISOString().split("T")[0];
  const maxYear = "9999-12-31";

  let webApi = new WebApimanager();
  const [open, setOpen] = useState(false);
  const [existingConsultants, setExistingConsultants] = useState([]);
  const [existingCategories, setExistingCategories] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState(null);
  const [sites, setDatasites] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [numDrawings, setNumDrawings] = useState(0);
  // const [siteName, setSiteName] = useState("");
  const [formData, setFormData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [site, setSite] = useRecoilState(siteId);
  const [folder, setFolder] = useRecoilState(folderId);
  const [folders, setDatafolders] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

 


  const handleTextFieldChange = (val, index, field) => {
    const today = todayDate();
    const updatedErrors = [...errors];
    const updatedFormData = formData.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [field]: val,
        };
      }
      return item;
    });

    // Initialize errors for the current index
    updatedErrors[index] = updatedErrors[index] || {};
    if (
      field === "drawingNumber" ||
      field === "drawingTitle" ||
      field === "consultant" ||
      field === "category"
    ) {
      if (!val.trim()) {
        updatedErrors[index][field] = `${field.replace(
          /([A-Z])/g,
          " $1"
        )} is required`;
      } else {
        updatedErrors[index][field] = "";
      }
    }

    if (field === "R0date" || field === "sitedate") {
      // Validate that dates are in the future
      if (val && val < today) {
        updatedErrors[index][field] = "Date must be in the future";
      } else {
        updatedErrors[index][field] = "";
      }

      // Check if R0date is less than sitedate
      const R0date = updatedFormData[index]?.R0date;
      const sitedate = updatedFormData[index]?.sitedate;

      if (R0date && sitedate) {
        if (new Date(R0date) >= new Date(sitedate)) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            R0date: "R0 submission date must be less than site submission date",
            sitedate:
              "Site submission date must be greater than R0 submission date",
          };
        } else {
          updatedErrors[index] = {
            ...updatedErrors[index],
            R0date: "",
            sitedate: "",
          };
        }
      }
    } else {
      // Clear error for other fields
      updatedErrors[index][field] = "";
    }

    setFormData(updatedFormData);
    setErrors(updatedErrors);
  };

  const handleChange = (e, index, field) => {
    const { value } = e.target;
    const today = todayDate();
    const updatedErrors = [...errors];
    const updatedFormData = formData.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [field]: value,
        };
      }
      return item;
    });

    // Initialize errors for the current index
    updatedErrors[index] = updatedErrors[index] || {};
    if (
      field === "drawingNumber" ||
      field === "drawingTitle" ||
      field === "consultant" ||
      field === "category"
    ) {
      if (!value.trim()) {
        updatedErrors[index][field] = `${field.replace(
          /([A-Z])/g,
          " $1"
        )} is required`;
      } else {
        updatedErrors[index][field] = "";
      }
    }

    if (field === "R0date" || field === "sitedate") {
      // Validate that dates are in the future
      if (value && value < today) {
        updatedErrors[index][field] = "Date must be in the future";
      } else {
        updatedErrors[index][field] = "";
      }

      // Check if R0date is less than sitedate
      const R0date = updatedFormData[index]?.R0date;
      const sitedate = updatedFormData[index]?.sitedate;

      if (R0date && sitedate) {
        if (new Date(R0date) >= new Date(sitedate)) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            R0date: "R0 submission date must be less than site submission date",
            sitedate:
              "Site submission date must be greater than R0 submission date",
          };
        } else {
          updatedErrors[index] = {
            ...updatedErrors[index],
            R0date: "",
            sitedate: "",
          };
        }
      }
    } else {
      // Clear error for other fields
      updatedErrors[index][field] = "";
    }

    setFormData(updatedFormData);
    setErrors(updatedErrors);
  };

  const handleNumDrawingChange = (e) => {
    const newNum = parseInt(e.target.value, 10);
    if (e.target.value === "") {
      setNumDrawings(newNum);
      setFormData([]);
      setErrors([]);
    } else {
        if(newNum >100){
            setErrors(true);  
            setNumDrawings(100);
        }else{
      setNumDrawings(newNum);
      setFormData(Array(newNum).fill({}));
      setErrors(Array(newNum).fill({}));
      setErrors(false); 
        }
    }
  };
  const handleDisableBackspace = (e) => {
    if (e.key === 'Backspace') {
      e.preventDefault();  // Disable backspace key
    }
  };
  const [currentView, setCurrentView] = useState("addfolder");

  const handleTabViewChange = (event, newValue) => {
    console.log(newValue);
    setCurrentView(newValue);
  };
  const handleSubmit = async () => {
    let valid = true;
    const newErrors = [];
    setErrorMessage("");
    const today = new Date().toISOString().split("T")[0];
    if (!folder) {
      setErrorMessage("Please Select The Folder");
      return;
    }
    formData.forEach((drawing, index) => {
      const drawingErrors = {};
      if (!drawing.drawingNumber?.trim()) {
        drawingErrors.drawingNumber = "Drawing Number is required";
        valid = false;
      }
      if (!drawing.drawingTitle?.trim()) {
        drawingErrors.drawingTitle = "Drawing Title is required";
        valid = false;
      }
      if (!drawing.consultant?.trim()) {
        drawingErrors.consultant = "Consultant is required";
        valid = false;
      }
      if (!drawing.category?.trim()) {
        drawingErrors.category = "Category is required";
        valid = false;
      }
      if (!drawing.R0date) {
        drawingErrors.R0date = "R0 Date is required";
        valid = false;
      } else if (drawing.R0date < today) {
        drawingErrors.R0date = "R0 Date must be in the future";
        valid = false;
      }

      if (!drawing.sitedate) {
        drawingErrors.sitedate = "Site Date is required";
        valid = false;
      } else if (drawing.sitedate < today) {
        drawingErrors.sitedate = "Site Date must be in the future";
        valid = false;
      }

      if (
        drawing.R0date &&
        drawing.sitedate &&
        new Date(drawing.R0date) >= new Date(drawing.sitedate)
      ) {
        drawingErrors.R0date =
          "R0 submission date must be less than site submission date";
        drawingErrors.sitedate =
          "Site submission date must be greater than R0 submission date";
        valid = false;
      }

      newErrors[index] = drawingErrors;
    });

    if (!valid) {
      setErrors(newErrors);
      return;
    }

    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });

    const tempFormData = {
      siteId: site,
      drawings: formData.map((drawing) => ({
        folderId:folder,
        drawingNo: drawing.drawingNumber,
        drawingTitle: drawing.drawingTitle,
        designDrawingConsultant: drawing.consultant,
        category: drawing.category,
        acceptedROSubmissionDate: drawing.R0date,
        acceptedSiteSubmissionDate: drawing.sitedate,
      })),
    };

    try {
      const response = await webApi.post(
        "api/architectureToRoRegister/create",
        tempFormData
      );
      if (response.status === 201) {
        setApiProcessing({ loader: false, message: "" });
        setNumDrawings(0);
        setFormData([]);
        setErrors([]);
        window.location.reload();
      } else if (response.status === 400 || response.status === 200) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
        }

        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        return;
      }
    }
  };

useEffect(() => {
    webApi
      .get(`api/designDrawingConsultant/getAll?siteId=${site}`)
      .then((response) => {
        console.log(
          "API Response api/designDrawingConsultant/getAll?siteId:",
          response
        );

        if (response) {
          const { users } = response.data.data;
          setExistingConsultants(users);

          const categories = users.flatMap((user) =>
            user.categories.map((category) => ({
              categoryId: category._id,
              category: category.category,
            }))
          );

          console.log("Extracted Categories:", categories);
          setFilteredCategories(categories);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [site]);

  const handleConsultantSelect = (consultantId, index) => {
    const updatedFormData = [...formData];

    // Update consultant for the specific row
    updatedFormData[index] = {
      ...updatedFormData[index],
      consultant: consultantId,
      category: "", // Reset category when consultant is changed
    };
    setFormData(updatedFormData);

    // Find the selected consultant's categories
    const consultant = existingConsultants.find((c) => c._id === consultantId);
    const categoriesForConsultant = consultant ? consultant.categories : [];

    // Update filtered categories only for the specific row
    const updatedFilteredCategories = [...filteredCategories];
    updatedFilteredCategories[index] = categoriesForConsultant;
    setFilteredCategories(updatedFilteredCategories);
  };
  const handleNumDrawingIncrease = () => {
    setNumDrawings((prevNum) => prevNum + 1);
    setFormData((prevFormData) => [...prevFormData, {}]);
  };
  const handleClearAll = () => {
    setNumDrawings(0); 
    setErrors("");
  };
  
  const handleNumDrawingDecrease = () => {
    if (numDrawings > 0) {
      setNumDrawings((prevNum) => prevNum - 1);
      setFormData((prevFormData) => prevFormData.slice(0, -1));
    }
  };

  const handleDeleteRow = (index) => {
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
    setNumDrawings(updatedFormData.length);
  };

  const numColumns = 6;
  const handleKeyDown = (e, index, field) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputs = Array.from(document.querySelectorAll("input"));
      const currentIndex = inputs.indexOf(e.target);
      const nextInput =
        inputs[currentIndex + 1] ||
        document.querySelector('button[type="submit"]');
      if (nextInput) nextInput.focus();
    }

    if (
      ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(e.key)
    ) {
      return; 
    }
  };

  const handleSiteChange = async (e) => {
    setSite(e.target.value);
    setApiProcessing({
      loader: true,
      message: "Loading...",
    });
   

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setApiProcessing({
        loader: false,
        message: "",
      });
    } catch (error) {
      console.error("Error occurred while changing the site:", error);
      setApiProcessing({
        loader: false,
        message: "Failed to load site data",
      });
    }
  };
  const handleFolderChange = async (e) => {
    setFolder(e.target.value);
    setApiProcessing({
      loader: true,
      message: "Loading...",
    });
    
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setApiProcessing({
        loader: false,
        message: "",
      });
    } catch (error) {
      console.error("Error occurred while changing the site:", error);
      setApiProcessing({
        loader: false,
        message: "Failed to load site data",
      });
    }
  }
  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);
  useEffect(() => {
    webApi
      .get(`api/drawingFolder/bySiteId?siteId=${site}`)
      .then((data) => {
        if (data.data.data.folders&& Array.isArray(data.data.data.folders)) {
          setDatafolders(data.data.data.folders);
          console.log(data.data.data.folders)
        } else {
          console.error("Unexpected data format:", data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);
 
  
  useEffect(() => {
  }, [site,folder]);
  return (
  <>
   <TableWrapper
        children={
            <>
            
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
                gap:'10px'
              }}
            >
                 <div style={{ display: "flex" }}>
                <Tabs
                  value={currentView}
                  onChange={handleTabViewChange}
                
                >
                  <Tab label="Add Folder" value="addfolder"/>
                  <Tab label="Add Register" value="addregister" />
                  <Tab label="Drawing Registers" value="drawingregisters" />
                </Tabs>
              </div>
             <div style={{ display: "flex" }}>
              <SitesDropdown
              siteId={site}
              handleSiteChange={handleSiteChange}
              sites={sites}
              defaultValue={"Select Site"}
            />
             {currentView != "addfolder" &&
  <FolderDropdown
   folderId={folder}
   handleFolderChange={handleFolderChange}
   folders={folders}
   defaultValue={"Select Folder"}
  />
             }
  </div>
              </div>
              {currentView === "addfolder" &&
              (
                <>
                <AddCategory filterCondition="addregister"/>
                </>
              )}
              {currentView === "addregister" &&
              (
              <div
              style={{
                display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                flexDirection: "column",
                marginTop:'15px'
              }}
            >
               
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "1rem",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                      <input
                        placeholder="No. of Drawings do you want to add"
                        type="number"
                        value={numDrawings > 0 ? numDrawings : ""}
                        onChange={handleNumDrawingChange}
                        onKeyDown={handleDisableBackspace}
                        min="0"
                        style={{
                          paddingLeft: "15px",
                          borderRadius: "6px",
                          border: "1px solid var(--primary-color) !important",
                          width: "350px",
                          height: "40px",
                        }}
                      />
                       {errors && (
                         <p style={{ color: "gray", fontSize: "12px", marginTop:'5px'}}>
                        Note:- You can add a maximum of 100 drawings only.
                       </p>
   
  )}
                      </div>
                      <div style={{ position: "relative" }}>
                        {" "}
                        <Button
                          style={{
                            height: "40px",
                            marginLeft: "10px",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "var(--primary-color)",
                            border: "1px solid var(--primary-color)",
                          }}
                          onClick={handleNumDrawingIncrease}
                        >
                          <Icon.PlusCircle
                            size={20}
                            style={{
                              marginRight: "5px",
                              color: "var(--primary-color)",
                              backgroundColor: "white",
                              borderRadius: "50%",
                              padding: "1px",
                            }}
                          />{" "}
                          Add
                        </Button>
                      </div>
                      <p 
                      style={{color:'4D4D4D',
                      textDecoration:'underline',
                      fontSize:'14px',marginTop:'8px',  
                      marginLeft: "10px",cursor:'pointer'}}
                      onClick={handleClearAll}
                      >
                        Clear all
                        </p>
                    </div>
                    
              </div>
              {errorMessage && (
                <div style={{ color: "red", fontSize: "15px" }}>
                  {errorMessage}
                </div>
              )}
                <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
              {numDrawings > 0 && (
              
                <table
                  style={{
                    border: "1px solid #DCDCDC",
                    borderCollapse: "collapse",
                    width: "100%",
                  
                  }}
                >
                    <thead style={{ position: "sticky", top: 0, zIndex: 1,  color:'white' }}>
                    <tr>
                      <th >S.No</th>
                      <th >Drawing Number</th>
                      <th >Design Consultant</th>
                      <th >Drawing Category</th>
                      <th >Drawing Title</th>
                      <th >Accepted RO Submission Date</th>
                      <th >Accepted Site Submission Date</th>
                      <th style={{padding:'5px'}}>Action</th>
                    </tr>
                  </thead>
                  <tbody style={{marginTop:'10px'}}>
                    {[...Array(numDrawings)].map((_, index) => (
                  
                    <tr key={index} style={{width:"100%"}} >
                      <td>
                       <p className="form-control ARFC"  style={{
                            width:'90%',
                            marginLeft:'5px',
                            height:'45px',
                            // marginTop:'16px',
                          backgroundColor: "var(--table-evenrow-color)",
                          padding:'10px',
                        }}>{index + 1}</p>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control ARFC"
                          placeholder="Enter The Drawing"
                          style={{
                            width: "95%",
                            marginLeft:'5px',
                            textTransform: "uppercase",
                            backgroundColor: "var(--table-oddrow-color)",
                           
                          }}
                          onChange={(e) =>
                            handleChange(e, index, "drawingNumber")
                          }
                          onKeyDown={(e) =>
                            handleKeyDown(e, index, "drawingNumber")
                          }
                          tabIndex={index * numColumns + 1}
                        />
                        {errors[index]?.drawingNumber && (
                          <div style={{ color: "red", fontSize: "0.8rem" }}>
                            {errors[index].drawingNumber}
                          </div>
                        )}
                      </td>

                      <td style={{
                            padding:'5px',}}>
                        <SearchDropdownIdVal
                          placeholder={"ENTER CONSULTANT"}
                          backgroundColor={"var(--table-evenrow-color)"}
                          options={
                            (existingConsultants &&
                              existingConsultants?.map((consultant) => ({
                                name: consultant.role,
                                value: consultant._id,
                              }))) ||
                            []
                          }
                          onOptionSelect={(option) =>
                            handleConsultantSelect(option.value, index)
                          }
                          onKeyDown={(e) =>
                            handleKeyDown(e, index, "consultant")
                          }
                          tabIndex={index * numColumns + 2}
                        />
                        {errors[index]?.consultant && (
                          <div style={{ color: "red", fontSize: "0.8rem" }}>
                            {errors[index].consultant}
                          </div>
                        )}
                      </td>
                
 <td>
                        <SearchDropdownIdVal
                          placeholder={"ENTER CATEGORY"}
                          backgroundColor={"var(--table-oddrow-color)"}
                          options={
                            filteredCategories &&
                            filteredCategories[index] &&
                            Array.isArray(filteredCategories[index])
                              ? filteredCategories[index].map((item) => ({
                                  name: item.category,
                                  value: item._id,
                                }))
                              : []
                          }
                        //   onOptionSelect={(option) =>

                        //     handleTextFieldChange(option.value, index, "category")
                        //   }
                          onOptionSelect={(option) => {
                            // console.log(
                            //   "Selected category value:",
                            //   option.value
                            // );
                            // console.log("Selected category name:", option.name);
                            handleTextFieldChange(option.value, index, "category");
                          }}
                          onKeyDown={(e) => handleKeyDown(e, index, "category")}
                          tabIndex={index * numColumns + 3}
                        />

                        {errors[index]?.category && (
                          <div style={{ color: "red", fontSize: "0.8rem" }}>
                            {errors[index].category}
                          </div>
                        )}
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter The Drawing Title"
                          style={{
                            width: "95%",
                            marginLeft:'5px',
                            backgroundColor: "var(--table-evenrow-color)",
                          }}
                          onChange={(e) =>
                            handleChange(e, index, "drawingTitle")
                          }
                          onKeyDown={(e) =>
                            handleKeyDown(e, index, "drawingTitle")
                          }
                          tabIndex={index * numColumns + 4}
                        />
                        {errors[index]?.drawingTitle && (
                          <div style={{ color: "red", fontSize: "0.8rem" }}>
                            {errors[index].drawingTitle}
                          </div>
                        )}
                      </td>
                      <td>
                        <input
                          type="date"
                          className="form-control ARFC"
                          max={maxYear}
                          min={todayDateString}
                          style={{
                            width: "95%",
                           marginLeft:'5px',
                            backgroundColor: "var(--table-oddrow-color)",
                            padding:'8px',
                          }}
                          onChange={(e) => handleChange(e, index, "R0date")}
                          onKeyDown={(e) => handleKeyDown(e, index, "R0date")}
                          tabIndex={index * numColumns + 5}
                        />
                        {errors[index]?.R0date && (
                          <div style={{ color: "red", fontSize: "0.8rem" }}>
                            {errors[index].R0date}
                          </div>
                        )}
                      </td>
                      <td>
                        <input
                          type="date"
                          className="form-control ARFC"
                          max={maxYear}
                          min={todayDateString}
                          style={{
                            width: "95%",
                           marginLeft:'5px',
                            backgroundColor: "var(--table-evenrow-color)",
                             padding:'8px'
                          }}
                          onChange={(e) => handleChange(e, index, "sitedate")}
                          onKeyDown={(e) => handleKeyDown(e, index, "sitedate")}
                          tabIndex={index * numColumns + 6}
                        />
                        {errors[index]?.sitedate && (
                          <div style={{ color: "red", fontSize: "0.8rem" }}>
                            {errors[index].sitedate}
                          </div>
                        )}
                      </td>
                      <td
                        className="form-control ARFC"
                        style={{
                            width:'90%',
                          backgroundColor: "var(--table-oddrow-color)",
                          marginTop: "5px",
                          padding:'8px',
                        marginLeft:'3px'
                        }}
                      >
                        <BsDashCircleFill
                          size={30}
                          style={{
                            cursor: "pointer",
                            marginLeft:"5px",
                            padding:'5px'
                          }}
                          onClick={() => handleDeleteRow(index)}
                        />
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
                
              )}
              <div style={{ marginTop: "8px", textAlign: "center" }}>
              {numDrawings > 0 && (
                <button
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid  var(--primary-color)",
                    backgroundColor: " var(--primary-color)",
                    color: "white",
                    width: "100px",
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                  )}
              </div>
              </div>
            </div>
              )}
              {currentView === "drawingregisters" &&(
                <RegisterPage filter="AddRegister"/>
              )
            }
              </>
        }
        />
  </>
  );
};

export default AddRegister;
