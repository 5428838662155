import React, { useState } from "react";
import "./ChangeLoginPassword.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { WebApimanager } from "../../../WebApiManager";

const ChangeLoginPassword = () => {
  const [activeSection, setActiveSection] = useState("login");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReEnterPassword, setShowReEnterPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterNewPassword, setReEnterNewPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const togglePasswordVisibility = (type) => {
    switch (type) {
      case "old":
        setShowOldPassword(!showOldPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "reenter":
        setShowReEnterPassword(!showReEnterPassword);
        break;
      default:
        break;
    }
  };

  const webApi = new WebApimanager();

  const handlePasswordChange = async () => {
    if (newPassword !== reEnterNewPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      const response = await webApi.post("api/users/changeOldPassword", {
        oldPassword,
        newPassword,
        reEnterNewPassword,
      });

      if (response.status === 200) {
        setSuccessMessage("Password updated successfully");
        setErrorMessage("");
      }
    } catch (error) {
      setErrorMessage("Failed to update password");
      setSuccessMessage("");
    }
  };

  return (
    <div className="containerpy-4" style={{ width: "88%" }}>
      <div className="Change-LoginPassword-container py-3">
        <p
          className={`Change-LoginPassword ${
            activeSection === "login" ? "active" : ""
          }`}
          onClick={() => setActiveSection("login")}
        >
          Change Login Password
        </p>
        {/* <p
          className={`Change-LoginPassword ${
            activeSection === "lock" ? "active" : ""
          }`}
          onClick={() => setActiveSection("lock")}
        >
          Change Lock chat Password
        </p> */}
      </div>

      {activeSection === "login" && (
        <div className="passwordContainer">
          <div className="form-group mb-3">
            <label htmlFor="old-password" className="form-label-PasswordNames">
              Old Password
            </label>
            <div className="input-group">
              <input
                id="old-password"
                type={showOldPassword ? "text" : "password"}
                placeholder="Enter your old password"
                className="form-control"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <span
                className="password-toggle-icon"
                onClick={() => togglePasswordVisibility("old")}
              >
                {showOldPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="new-password" className="form-label-PasswordNames">
              New Password
            </label>
            <div className="input-group">
              <input
                id="new-password"
                type={showNewPassword ? "text" : "password"}
                placeholder="Enter a new password"
                className="form-control"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <span
                className="password-toggle-icon"
                onClick={() => togglePasswordVisibility("new")}
              >
                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <div className="form-group mb-3">
            <label
              htmlFor="reenter-password"
              className="form-label-PasswordNames"
            >
              Re-enter New Password
            </label>
            <div className="input-group">
              <input
                id="reenter-password"
                type={showReEnterPassword ? "text" : "password"}
                placeholder="Re-enter your new password"
                className="form-control"
                value={reEnterNewPassword}
                onChange={(e) => setReEnterNewPassword(e.target.value)}
              />
              <span
                className="password-toggle-icon"
                onClick={() => togglePasswordVisibility("reenter")}
              >
                {showReEnterPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <div className="passwordContainerbutton">
            <button
              className="save-button-passwordChange"
              onClick={handlePasswordChange}
            >
              SAVE
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangeLoginPassword;
