import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import Folder from "../../../Images/Folder.svg"
import { Dialog, DialogContent } from "@mui/material";
import {siteId,} from "../../../atoms";
import TableWrapper from "../../../Utilities/TableWrapper";
import Eye from "../../../Images/Eye.svg";
import SelectSite from "../../../Utilities/SelectSite";
import FolderFile from "./Folder"
import { RxCross1 } from "react-icons/rx";
import CheckListTable from "../ChecklistTable";
const Form = () => {
  let webApi = new WebApimanager();
  const [site, setSite] = useRecoilState(siteId);
  const [viewOpen ,setviewOpen] = useState(false);
  const [viewActivity,setViewActivity] = useState(null);
  const [viewchecklistdata,setViewChecklistData] = useState(null);
  const [SelectedFolder, setSelectedFolder] = useState(null);
  const [approvedCheckListData,setApprovedCheckListData] = useState([]);
  const [selectedactivity, setSelectedActivity] = useState("");
  const [selectedChecklistData, setSelectedChecklistData] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const  handleView = (department, activity, checklistData) =>{
    setViewActivity(activity);
    setViewChecklistData(checklistData);
    setviewOpen(true);
  };
  const handleClose =() =>{
    setviewOpen(false);
    setViewActivity("");
    setViewChecklistData("");
  }
  const handleBackToForms = () => {
    setSelectedFolder(null);
  };
  const fetchData = async () => {
    try {
      let response = await webApi.get(
        `api/checklistDesign/getApprovedChecklists`
      );
      console.log("checklistData", response.data.data);
      setApprovedCheckListData(response.data.data);
    } catch (error) {
      console.error("Error fetching checklist data", error);
    }
  };
  
  useEffect(() => {
      fetchData();
  }, []);

const handleFolder = (department, activity, checklistData) => {
  setSelectedFolder(true); 
  setSelectedActivity(activity);
  setSelectedChecklistData(checklistData); 
  console.log(SelectedFolder);
};
  if (site === "") {
    return <SelectSite/>;
  }
return (
  <>
    {SelectedFolder ? (
      <>
        <div
          style={{
            position: 'fixed', 
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000, 
            boxShadow: 'rgb(243, 243, 243) 0px 0px 10px',
            borderRadius: '12px',
            backgroundColor: 'white',
            padding: '20px 15px 15px',
            fontFamily: 'Poppins',
            width: '100%',
            height: '100%', 
            overflow: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingBottom: '20px',
              gap: '10px',
            }}
          >
            <button
              onClick={handleBackToForms}
              style={{
                marginTop: '-10px',
                color: 'black',
                cursor: 'pointer',
                border: 'none',
              }}
            >
              <Icon.ArrowLeft
                size={30}
                style={{ color: 'var(--primary-color)' }}
              />
            </button>
            <p style={{ marginTop: '5px' }}> Activity of folder: {selectedactivity || 'No Activity'}</p>
          </div>
          <FolderFile formData={selectedChecklistData} />
        </div>
      </>
    ) : (
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: 'flex',
                marginLeft: '5px',
                marginRight: '5px',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <p
                  style={{
                    color: '#131313',
                    fontWeight: '600',
                    fontSize: '16px',
                  }}
                >
                  Form
                </p>
              </div>
              <div>
                <>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      style={{
                        position: 'relative',
                        width: '250px',
                        paddingLeft: '5px',
                        height: '50px',
                      }}
                    >
                      <input
                        placeholder="Search Forms"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        style={{
                          paddingLeft: '20px',
                          border: '1px solid var(--primary-color)',
                          width: '250px',
                          height: '40px',
                        }}
                      />
                      <Icon.Search
                        style={{
                          position: 'absolute',
                          right: '15px',
                          top: '40%',
                          transform: 'translateY(-50%)',
                          color: '#ACACAC',
                        }}
                      />
                    </div>
                    {/* <img
                      src={Filter}
                      style={{
                        width: '40px',
                        height: '40px',
                        marginLeft: '10px',
                        padding: '5px',
                        border: '1px solid var(--primary-color)',
                        cursor: 'pointer',
                      }}
                    /> */}
                  </div>
                  <div></div>
                </>
              </div>
            </div>
            <CheckListTable
              CheckListTableHead={
                <tr className="HeaderTableRow">
                  <th
                    className="CheckListTableTableHead"
                    style={{ width: '5%' }}
                  >
                    S.No
                  </th>
                  <th
                    className="CheckListTableTableHead"
                    style={{ width: '20%' }}
                  >
                    Department
                  </th>
                  <th
                    className="CheckListTableTableHead"
                    style={{ width: '20%' }}
                  >
                    Activity
                  </th>
                  <th
                    className="CheckListTableTableHead"
                    style={{ width: '20%' }}
                  >
                    No of Checklist
                  </th>
                  <th
                    className="CheckListTableTableHead"
                    style={{ width: '17%' }}
                  >
                    Types Of Checklist
                  </th>
                  <th
                    className="CheckListTableTableHead"
                    style={{ width: '17%' }}
                  >
                    Action
                  </th>
                </tr>
              }
              CheckListTableBody={
                Array.isArray(approvedCheckListData) &&
                approvedCheckListData.length > 0 ? (
                  approvedCheckListData.filter((item)=>item.department.toLowerCase().includes(searchInput.toLowerCase()) || 
                  item.activity?.name.toLowerCase().includes(searchInput.toLowerCase()) || 
                  item.activity?.totalChecklistCount.toString().includes(searchInput.toLowerCase()) || 
                  item.activity?.count.toString().includes(searchInput.toLowerCase()))
                  .map((item, index) => (
                    <tr
                      className="ValueTableRow"
                      key={item._id}
                      style={{
                        backgroundColor:
                          index % 2 === 0
                            ? 'var(--table-oddrow-color)'
                            : 'var(--table-evenrow-color)',
                      }}
                    >
                      <td className="ApprovalTableTableData" style={{ width: '5%' }}>
                        {index + 1}
                      </td>
                      <td className="ApprovalTableTableData" style={{ width: '20%' }}>
                        {item.department}
                      </td>
                      <td className="ApprovalTableTableData" style={{ width: '20%' }}>
                        {item.activity?.name}
                      </td>
                      <td className="ApprovalTableTableData" style={{ width: '20%' }}>
                        {item.activity?.totalChecklistCount}
                      </td>
                      <td className="ApprovalTableTableData" style={{ width: '18%' }}>
                        {item.activity?.count}
                      </td>
                      <td
                        className="ApprovalTableTableData"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '17%',
                        }}
                      >
                        <img
                          src={Folder}
                          loading="eager"
                          style={{
                            marginRight: '20px',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            handleFolder(
                              item.department,
                              item.activity?.name,
                              item.activity?.checklists
                            )
                          }
                        />
                        <img
                          src={Eye}
                          loading="eager"
                          style={{
                            marginRight: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleView(  item.department,
                            item.activity?.name,
                            item.activity?.checklists)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="ValueTableRow">
                    <td colSpan={6} style={{ textAlign: 'center' }}>
                      Loading...
                    </td>
                  </tr>
                )
              }
            />
          </>
        }
      />
    )}

<Dialog
        open={viewOpen}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: '50px',
          }}
        >
          <p
            style={{ margin: "5px 170px", textAlign: "center", color: "white" }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "300px" }}
        >
       <div>
  <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #979797' }}>
    <thead>
      <tr style={{height:'50px',color:'white'}}>
        <th>FormNo</th>
        <th>ChecklistType</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(viewchecklistdata) && viewchecklistdata.length > 0 ? (
        viewchecklistdata.map((item, index) => (
          <tr style={{borderTop: '1px solid #979797'}} key={index}>
            <td style={{ borderLeft: '1px solid #979797', padding: '8px' }}>{item.formNo}</td>
            <td style={{ borderRight: '1px solid #979797', padding: '8px' }}>{item.checklistType}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="2" style={{ textAlign: 'center', padding: '8px' }}>No data available</td>
        </tr>
      )}
    </tbody>
  </table>
</div>

        </DialogContent>
      </Dialog>
  </>
);

};

export default Form;
  