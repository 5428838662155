import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../ApplyPartner/ApplyPartner.css";
import { WebApimanager } from "../../WebApiManager";
import { useNavigate } from "react-router-dom";
import style from "./ApplyPartnership.module.css"
import ModalPopup from "../../Components/ModalPopup/ModalPopup";

const ApplyPartnership = () => {
  const [showModal,setShowModal]= useState(false)
  const [formData, setFormData] = useState({
    name: "",
    contactNo: "",
    jobTitle: "",
    partnerType: "",
    solutionFocus: "",
    email: "",
    companyName: "",
    websiteUrl: "",
    industryFocus: "",
    numberOfEmployees: "",
    // numberOfConsultants: "",
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const WebApi = new WebApimanager();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validate mobile number to allow only numeric input
    if (name === "contactNo" && !/^\d*$/.test(value)) {
      return; // Prevent setting value if it's not numeric
    }


    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let formErrors = {};

    // Check required fields
    Object.keys(formData).forEach((field) => {
      if (!formData[field]) {
        formErrors[field] = "This field is required";
      }
    });


    // Additional validations
    if (formData.contactNo && !/^\d{10}$/.test(formData.contactNo)) {
      formErrors.contactNo = "Please enter a valid 10-digit mobile number";
    }

    const nameRegex = /^[a-zA-Z]+$/;
    if(formData.name && !nameRegex.test(formData.name)){
      formErrors.name="Enter a Valid Name"
    }
    if(formData.companyName && !(/^[a-zA-Z]+$/.test(formData.companyName))){
      formErrors.companyName="Enter a valid company Name"
    }
    if(formData.jobTitle && (!/^[a-zA-Z]+$/.test(formData.jobTitle))){
      formErrors.jobTitle="Enter a valid Job title"
    }
    // More validation logic can be added here

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };
  // useEffect(()=>{

  // },[])

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const dataToSent = {
          name: formData.name,
          email: formData.email,
          contactNo: formData.contactNo,
          companyName: formData.companyName,
          jobTitle: formData.jobTitle,
          websiteUrl: formData.websiteUrl,
          partnerType: formData.partnerType,
          industryFocus: formData.industryFocus,
          solutionFocus: formData.solutionFocus,
          numberOfEmployees: formData.numberOfEmployees,
          // numberOfConsultants: formData.numberOfConsultants,
        };
        const ApplyPartenerShip = await WebApi.post("api/partners", dataToSent);
        console.log("Form submitted successfully!", ApplyPartenerShip.data);
        console.log(ApplyPartenerShip.status,"status")
        if(ApplyPartenerShip.status === 200 || ApplyPartenerShip.status === 201){
          setShowModal(true)
        }

        // Reset form and errors
        setFormData({
          name: "",
          contactNo: "",
          jobTitle: "",
          partnerType: "",
          solutionFocus: "",
          email: "",
          companyName: "",
          websiteUrl: "",
          industryFocus: "",
          numberOfEmployees: "",
          // numberOfConsultants: "",
        });

        setErrors({});

        setTimeout(() => {
          navigate("/partners");
        }, 2000);
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error("Server Error:", error.response.data);
        } else if (error.request) {
          // Request was made but no response received
          console.error("Network Error:", error.message);
        } else {
          // Something else happened
          console.error("Error:", error.message);
        }
      }
    } else {
      console.log("Form has errors.");
    }
    setTimeout(() => {
      setShowModal(false);
    }, 3000);
  };

  return (
    <>
      <div className={style.PartnerContainer}  style={{ backgroundColor: "#f0f0f0" }}>
        <div className="container">
          <form onSubmit={handleSubmit} className={style.Form}>
            <div className="partnerContainer">
              <div className="d-flex justify-content-center">
                <div className="partnerHeading text-center mb-[30px]">
                  <p>
                    We appreciate you showing interest in helping our company
                    globally.<br/>
                    We just need a few details to aid us know you better.
                  </p>
                </div>
              </div>
              <div className={style.FormContainer}>
                <div className={style.LeftContainer}>
                  <div className={style.InputContainer}>
                    <label className={style.FormLabel}>
                      Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      className={style.InputField}
                      placeholder="Enter your name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    {errors.name && (
                      <small className="text-danger absolute top-[100%]">
                        {errors.name}
                      </small>
                    )}
                  </div>
                  <div className={style.InputContainer}>
                    <label className={style.FormLabel}>
                      Contact No <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="tel"
                      name="contactNo"
                      className={style.InputField}
                      maxLength="10"
                      placeholder="Enter your contact number"
                      value={formData.contactNo}
                      onChange={handleInputChange}
                    />
                    {errors.contactNo && (
                      <small className="text-danger absolute top-[100%]">
                        {errors.contactNo}
                      </small>
                    )}
                  </div>
                  <div className={style.InputContainer}>
                    <label className={style.FormLabel}>
                      Job Title <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="jobTitle"
                      className={style.InputField}
                      placeholder="Enter your job title"
                      value={formData.jobTitle}
                      onChange={handleInputChange}
                    />
                    {errors.jobTitle && (
                      <small className="text-danger absolute top-[100%]">
                        {errors.jobTitle}
                      </small>
                    )}
                  </div>
                  <div className={style.InputContainer}>
                    <label className={style.FormLabel}>
                      Partner Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      name="partnerType"
                      className={style.InputField}
                      value={formData.partnerType}
                      onChange={handleInputChange}
                    >
                      <option value="">Select partner type</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                    {errors.partnerType && (
                      <small className="text-danger absolute top-[100%]">
                        {errors.partnerType}
                      </small>
                    )}
                  </div>
                  <div className={style.InputContainer}>
                    <label className={style.FormLabel}>
                      Solution Focus <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="solutionFocus"
                      className={style.InputField}
                      placeholder="Enter solution focus"
                      value={formData.solutionFocus}
                      onChange={handleInputChange}
                    />
                    {errors.solutionFocus && (
                      <small className="text-danger absolute top-[100%]">
                        {errors.solutionFocus}
                      </small>
                    )}
                  </div>
                </div>

                {/* Right Column */}
                <div className={style.LeftContainer}>
                  <div className={style.InputContainer}>
                    <label className={style.FormLabel}>
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      className={style.InputField}
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && (
                      <small className="text-danger absolute top-[100%]">
                        {errors.email}
                      </small>
                    )}
                  </div>
                  <div className={style.InputContainer}>
                    <label className={style.FormLabel}>
                      Company Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      className={style.InputField}
                      placeholder="Enter company name"
                      value={formData.companyName}
                      onChange={handleInputChange}
                    />
                    {errors.companyName && (
                      <small className="text-danger absolute top-[100%]">
                        {errors.companyName}
                      </small>
                    )}
                  </div>
                  <div className={style.InputContainer}>
                    <label className={style.FormLabel}>
                      Website URL <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="websiteUrl"
                      className={style.InputField}
                      placeholder="Enter website URL"
                      value={formData.websiteUrl}
                      onChange={handleInputChange}
                    />
                    {errors.websiteUrl && (
                      <small className="text-danger absolute top-[100%]">
                        {errors.websiteUrl}
                      </small>
                    )}
                  </div>
                  <div className={style.InputContainer}>
                    <label className={style.FormLabel}>
                      Industry Focus <span style={{ color: "red" }}>*</span>
                    </label>

                    <select
                      name="industryFocus"
                      className={style.InputField}
                      value={formData.industryFocus}
                      onChange={handleInputChange}
                    >
                      <option value="">Select industry focus</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>

                    {errors.industryFocus && (
                      <small className="text-danger absolute top-[100%]">
                        {errors.industryFocus}
                      </small>
                    )}
                  </div>
                  <div className={style.InputContainer}>
                    <label className={style.FormLabel}>
                      Number of Employees <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="number"
                      name="numberOfEmployees"
                      className={style.InputField}
                      placeholder="Enter number of employees"
                      value={formData.numberOfEmployees}
                      onChange={handleInputChange}
                    />
                    {errors.numberOfEmployees && (
                      <small className="text-danger absolute top-[100%]">
                        {errors.numberOfEmployees}
                      </small>
                    )}
                  </div>
                </div>

                {/* <div className="mb-3 mt-2">
                  <label className={style.FormLabel}>
                    Number of Consultants <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="numberOfConsultants"
                    className="form-control mx-auto numberofEmployees"
                    placeholder="Enter number of consultants"
                    value={formData.numberOfConsultants}
                    onChange={handleInputChange}
                  />
                  {errors.numberOfConsultants && (
                    <small className="text-danger absolute top-[100%]">
                      {errors.numberOfConsultants}
                    </small>
                  )}
                </div> */}

                

                {/* {successMessage && (
                  <div className="alert alert-success text-center">
                    {successMessage}
                  </div>
                )} */}

                {/* Apply for Partnership button */}
                {/* <div className="d-flex justify-content-center mb-3 mt-3">
                  <button className="p-2 ApplyButton" type="submit">
                    Apply for Partnership
                  </button>
                </div> */}
              </div>
              
            </div>
            <div className={style.Container}>
              <p>
                All fields marked in * are mandatory
              </p>
            </div>
            <div className={style.Container} style={{paddingBottom:"80px"}}>
              <button>Apply For partnership</button>
            </div>
          </form>
        </div>
      </div>
      {showModal&& <ModalPopup  setShowModal={setShowModal}/>}
    </>
  );
};

export default ApplyPartnership;
