import React from "react";
import "./AllTab.css";
import "bootstrap/dist/css/bootstrap.min.css";

const EyeModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="modal fade show d-block"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-xl">
        <div
          className="modal-content"
          style={{ position: "sticky", top: 0, zIndex: 10 }}
        >
          <div className="modal-header">
            <h5 className="Eyetitle">View</h5>
            <button
              type="button"
              className="close-button"
              onClick={onClose}
              aria-label="Close"
            >
              &times;
            </button>
          </div>

          <div
            className="modal-body"
            style={{ maxHeight: "600px", overflowY: "auto", padding: "20px" }}
          >
            <section className="grid grid-cols-2 gap-5 mx-2">
              <div className="py-3">
                <div className="row">
                  <div className="col-5 taskviewTitle">Site Name</div>
                  <div className="col-2">:</div>
                  <div className="col-5 text-start taskviewData">
                    Testing Site
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-5 taskviewTitle">Department</div>
                  <div className="col-2">:</div>
                  <div className="col-5 text-start taskviewData">EHS</div>
                </div>

                <div className="row mt-3">
                  <div className="col-5 taskviewTitle">Form No</div>
                  <div className="col-2">:</div>
                  <div className="col-5 text-start taskviewData">
                    Lorem Ipsum
                  </div>
                </div>
              </div>
              <div className="">
                <div className="row ">
                  <div className="col-5 taskviewTitle">
                    Flat No/Eqipment Type
                  </div>
                  <div className="col-2">:</div>
                  <div className="col-5 text-start taskviewData">fhge</div>
                </div>
                <div className="row mt-3">
                  <div className="col-5 taskviewTitle">Activity Name</div>
                  <div className="col-2">:</div>
                  <div className="col-5 text-start taskviewData">dsjhf </div>
                </div>
                <div className="row mt-3">
                  <div className="col-5 taskviewTitle">Date</div>
                  <div className="col-2">:</div>
                  <div className="col-5 text-start taskviewData">
                    Sites Selected
                  </div>
                </div>
              </div>
            </section>

            <div className="mx-1">
              <h3>Before - 3</h3>
              <ul className="BeforeUL">
                <li>This is the first paragraph of the modal.</li>
                <li>This is the second paragraph of the modal.</li>
                <li>This is the third paragraph of the modal.</li>
              </ul>
            </div>

            <div className="mx-1">
              <h3>Close - 3</h3>
              <ul className="BeforeUL">
                <li>This is the first paragraph of the modal.</li>
                <li>This is the second paragraph of the modal.</li>
                <li>This is the third paragraph of the modal.</li>
              </ul>
            </div>
            <div className="mx-1">
              <h3>In Review - 3</h3>
              <ul className="BeforeUL">
                <li>This is the first paragraph of the modal.</li>
                <li>This is the second paragraph of the modal.</li>
                <li>This is the third paragraph of the modal.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EyeModal;
