import React, { useState, useEffect, useRef } from "react";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { Button_COLOUR } from "../../../Utilities/Constants";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { siteId } from "../../../atoms";
import { RxCross1 } from "react-icons/rx";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import TableWrapper from "../../../Utilities/TableWrapper";
import { processFile } from "../BackendUtils";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { IoDownloadOutline, IoImageOutline } from "react-icons/io5";
import "./ROtoSiteLevel.css";
import Filter from "../../../Images/Filter.svg";
import { Tabs, Tab } from "../../Tabs/Tabv1";
import RFIEditReopenDialog from "./RFIEditReopenDialog";
const RequestedTable = (rfiRaisedAccess) => {
  console.log(rfiRaisedAccess,"rfi page" )
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };
  let webApi = new WebApimanager();
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState("requested");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedDrawingId, setSelectedDrawingId] = useState(null);
  const [site, setSite] = useRecoilState(siteId);
  const [tableRowData, setTableRowData] = useState([]);
  const [formData, setFormData] = useState({
    issuedSoftCopy: "",
    issuedHardCopy: "",
    // remarks:'',
    submittedDate: todayDate(),
    reason: "",
    drawingFileName: null,
    response: "",
  });
  const [showEditPopup, setShowEditPopup] = useState(false);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [currentTab, setCurrentTab] = useState("");

  const handleTabChange = (tabKey) => {
    setCurrentTab(tabKey);
  };

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
  };

  const handleEditPopup = () => {
    setShowEditPopup(true);
  };

  // Example validation function
  const validateForm = (formData, currentTab) => {
    let errors = {};
    if (currentTab === "accepted") {
      // if (!formData.response) errors.response ='This field is required.';
    } else if (currentTab === "submitted") {
      if (!formData.issuedSoftCopy)
        errors.issuedSoftCopy = "This field is required.";
      if (!formData.receivedHardCopy)
        errors.receivedHardCopy = "This field is required.";
      if (!formData.drawingFileName)
        errors.drawingFileName = "File upload is required.";
      if (!formData.submittedDate)
        errors.submittedDate = "submitted date is required";
      // if (!formData.remarks) errors.remarks = 'This field is required.';
    } else if (currentTab === "reject") {
      if (!formData.reason) errors.reason = "This field is required.";
      if (!formData.drawingFileName)
        errors.drawingFileName = "File upload is required.";
    }
    return errors;
  };

  const handleFormSubmit = async () => {
    // Validate the form data
    const errors = validateForm(formData, currentTab);
    setErrors(errors);

    // If there are errors, return early
    if (Object.keys(errors).length > 0) return;

    // Perform API call based on the active tab
    if (currentTab === "accepted") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitAaccept(selectedDrawingId);
    }
    if (currentTab === "submitted") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitApproval(selectedDrawingId);
    } else if (currentTab === "reject") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitReject(selectedDrawingId);
    }

    // Handle successful submission or any additional logic
  };

  const submitAaccept = async (drawingItemId) => {
    console.log("drawingItemId", drawingItemId);
    try {
      // const AcceptFormData = {
      //   // response:formData.response,
      //   submittedDate:todayDate()

      // };
      // console.log("Submitting Accepted data:", AcceptFormData);

      // const response = await webApi.put(`api/roToSiteLevelRequested/accept/${drawingItemId}`,AcceptFormData);

      const response = await webApi.put(
        `api/roToSiteLevelRequested/accept/${drawingItemId}`
      );
      setApiProcessing({ loader: false, message: "" });
      handleEditClose();
      fetchData();
      // window.location.reload();
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      console.error("Error submitting form:", error);
      // Handle error, show error message, etc.
    }
  };
  const submitApproval = async (drawingItemId) => {
    try {
      const ApprovalFormData = {
        remarks: formData.remarks,
        issuedSoftCopy: formData.issuedSoftCopy,
        receivedHardCopy: formData.receivedHardCopy,
        submittedDate: todayDate(),
      };
      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRequested/updateRevision/${drawingItemId}`,
        ApprovalFormData
      );
      const id = response.data.data._id;

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const drawingFileName = new FormData();
        drawingFileName.append("drawingFileName", formData.drawingFileName);
        const uploadResponse = await webApi.imagePut(
          `api/roToSiteLevelRequested/drawing/${drawingItemId}`,
          drawingFileName
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleEditClose();
      fetchData();
      // window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error, show error message, etc.
    }
  };

  const submitReject = async (drawingItemId) => {
    try {
      const RejectFormData = {
        reason: formData.reason,
      };
      console.log("Submitting Reject data:", RejectFormData);

      const response = await webApi.put(
        `api/roToSiteLevelRequested/reject/${drawingItemId}`,
        RejectFormData
      );
      if (formData.drawingFileName) {
        const drawingFileName = new FormData();
        drawingFileName.append("rejectedFile", formData.drawingFileName);
        const uploadResponse = await webApi.imagePut(
          `api/roToSiteLevelRequested/rejectFile/${drawingItemId}`,
          drawingFileName
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleEditClose();
      fetchData();
      // window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error, show error message, etc.
    }
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const handleConfirmDialogOpen = (drawingItem) => {
    setSelectedItem(drawingItem);
    setSelectedDrawingId(drawingItem._id);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmSubmit = () => {
    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });
    if (selectedDrawingId) {
      console.log("Selected Drawing ID:", selectedDrawingId);
      webApi
        .put(`api/architectureToRoRequested/closed/${selectedDrawingId}`)
        .then((response) => {
          const data = response.data;
          console.log("Server Response:", response);
          console.log("Data:", data);
          setApiProcessing({ loader: false, message: "" });
          setConfirmDialogOpen(false);
          fetchData();
        })
        .catch((error) => {
          setApiProcessing({ loader: false, message: "" });
          console.error("There was an error submitting the form!", error);
        });
    }
  };

  const [errors, setErrors] = useState({});
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleEditOpen = (drawingItem) => {
    setSelectedItem(drawingItem);
    setSelectedDrawingId(drawingItem._id);
    setEditDialogOpen(true);
    setErrors({});
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setFormData({});
    fetchData();
    setCurrentTab("");
  };

  const fetchData = async (currentView, site) => {
    if (currentView === "requested") {
      try {
        let response = await webApi.get(
          `api/roToSiteLevelRequested/site?siteId=${site}`
        );
        console.log(response.data.data);
        setDrawingList(response.data.data);
        setInitialDrawingList(response.data.data);
      } catch (error) {
        console.log(error.message);
      }
    }
    if (currentView === "raised") {
      try {
        let response = await webApi.get(
          `api/architectureToRoRequested/site?siteId=${site}`
        );
        console.log(response.data.data);
        setDrawingList(response.data.data);
        setInitialDrawingList(response.data.data);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  useEffect(() => {
    setDrawingList([]);
    fetchData(currentView, site);
  }, [currentView]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0], // Assuming you are only uploading one file
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleViewFile = async (id, status) => {
    setApiProcessing({
      loader: true,
      message: "Viewing...",
    });
    try {
      // Request the file with binary response handling
      const response = await webApi.getImage(
        status === "Rejected"
          ? `api/roToSiteLevelRequested/rejectFile/${id}`
          : `api/roToSiteLevelRequested/request/${id}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "view", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleDownload = async (id, status) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      const response = await webApi.getImage(
        status === "Rejected"
          ? `api/roToSiteLevelRequested/reject/${id}`
          : `api/roToSiteLevelRequested/request/${id}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const [expandedRows, setExpandedRows] = useState({});

  const handleToggleExpand = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const handleViewOpen = (drawingItem) => {
    console.log(drawingItem, "drawingItem for raised");
    setSingleDrawingData(drawingItem);
    setViewDialogOpen(true);
    if (
      currentView === "raised" &&
      (drawingItem.status === "Requested" || drawingItem.status === "Rejected")
    ) {
      setTableRowData(drawingItem);
    }
  };

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  const truncateText = (text, maxWords = 2) => {
    if (!text) return "";
    const words = text.split(" ");
    return words.length > maxWords
      ? `${words.slice(0, maxWords).join(" ")}...`
      : text;
  };

  const latestRevisionedit =
    selectedItem?.drawingId?.acceptedRORevisions?.length > 0
      ? selectedItem.drawingId.acceptedRORevisions[
          selectedItem.drawingId.acceptedRORevisions.length - 1
        ]
      : null;

  const latestRevision =
    singleDrawingData?.drawingId?.acceptedRORevisions?.length > 0
      ? singleDrawingData.drawingId.acceptedRORevisions[
          singleDrawingData.drawingId.acceptedRORevisions.length - 1
        ]
      : null;

  const searchResult = (e) => {
    const searchValue = e.target.value ? e.target.value.toLowerCase() : "";
    if (!searchValue) {
      fetchData();
    } else {
      const filteredDrawings = initialDrawingList.filter((drawingItem) => {
        const drawingNumber =
          drawingItem.drawingId?.drawingNo?.toLowerCase() || "";
        const drawingName =
          drawingItem.drawingId?.drawingTitle?.toLowerCase() || "";
        // Access Design Consultant role, ensuring correct path
        const consultant = getString(
          drawingItem.drawingId?.designDrawingConsultant?.role
            ?.designDrawingConsultant ||
            drawingItem.drawingId?.designDrawingConsultant?.role ||
            drawingItem.drawingId?.designDrawingConsultant
        );
        const category =
          drawingItem.drawingId?.category?.category?.toLowerCase() || "";
        const requestedDate = drawingItem.requestedDate
          ? new Date(drawingItem.requestedDate)
              .toLocaleDateString()
              .toLowerCase()
          : "";
        const expectedDate = drawingItem.expectedDate
          ? new Date(drawingItem.expectedDate)
              .toLocaleDateString()
              .toLowerCase()
          : "";
        const status = drawingItem.status?.toLowerCase() || "";
        const revisions =
          drawingItem.drawingId?.acceptedRORevisions
            .map((revision) => revision.revision.toLowerCase())
            .join(" ") || "";
        return (
          drawingNumber.includes(searchValue) ||
          drawingName.includes(searchValue) ||
          consultant.includes(searchValue) ||
          category.includes(searchValue) ||
          requestedDate.includes(searchValue) ||
          expectedDate.includes(searchValue) ||
          status.includes(searchValue) ||
          revisions.includes(searchValue)
        );
      });
      setDrawingList(filteredDrawings);
    }
  };

  // Helper function to ensure the value is a string
  const getString = (value) => {
    if (value === null || value === undefined) {
      return "";
    }
    if (typeof value === "object") {
      // Handle cases where value is an object
      return value.toString && typeof value.toString === "function"
        ? value.toString().toLowerCase()
        : "";
    }
    return typeof value === "string" ? value.toLowerCase() : "";
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const handleStatusChange = (status) => {
    if (status) {
      // Ensure status is not undefined
      setSelectedStatus(status);
      setShowDropdown(false);
      // Handle filter logic here
    }
  };

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clear the timeout if user clicks outside
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      // Clean up the timeout on component unmount
    };
  }, []);
  const calculateDueDays = (startDate, endDate) => {
    if (!startDate || !endDate) return "-";

    const start = new Date(startDate);
    const end = new Date(endDate);

    const timeDiff = end - start; // Difference in milliseconds
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Convert to days

    return dayDiff; // Returns a positive or negative number
  };

  const statusOrder = ["Requested", "Rejected", "Accepted", "Closed"];

  // Ensure drawingList is an array and filter out invalid items
  const sortedDrawingList = (Array.isArray(drawingList) ? [...drawingList] : [])
    .filter(
      (item) => item && item.drawingId && item.drawingId.designDrawingConsultant
    )
    .sort((a, b) => {
      // Check if selectedStatus is valid
      if (selectedStatus && statusOrder.includes(selectedStatus)) {
        if (a.status === selectedStatus && b.status !== selectedStatus)
          return -1;
        if (a.status !== selectedStatus && b.status === selectedStatus)
          return 1;
      }

      // Sort by status order
      const statusComparison =
        statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
      if (statusComparison !== 0) return statusComparison;

      const consultantA = a.drawingId?.category?.category.toLowerCase() || "";
      const consultantB = b.drawingId?.category?.category.toLowerCase() || "";
      return consultantA.localeCompare(consultantB);
    });

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <Tabs value={currentView} onChange={handleTabViewChange}>
                  <Tab label="Requested from SiteHead" value="requested" />
                  <Tab label="Raised to Architect" value="raised" />
                </Tabs>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    position: "relative",
                    width: "250px",
                    paddingLeft: "5px",
                    marginLeft: "200px",
                    height: "50px",
                  }}
                >
                  <input
                    placeholder="Search Drawings"
                    onChange={searchResult}
                    style={{
                      paddingLeft: "20px",
                      border: "1px solid var(--primary-color)",
                      width: "250px",
                      height: "40px",
                    }}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "40%",
                      transform: "translateY(-50%)",
                      color: "#ACACAC",
                    }}
                  />
                </div>
                <div style={{ position: "relative" }}>
                  <img
                    src={Filter}
                    style={{
                      width: "40px",
                      height: "40px",
                      marginLeft: "10px",
                      padding: "5px",
                      border: "1px solid var(--primary-color)",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowDropdown(!showDropdown)}
                  />
                  {/* <Icon.FunnelFill
                    size={20}
                    style={{
                      width: "40px",
                      height: "40px",
                      marginLeft: "10px",
                      padding: "5px",
                      border: "1px solid var(--primary-color)",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowDropdown(!showDropdown)}
                  /> */}
                  <div style={{ display: "inline-block" }}>
                    <button
                      onClick={() => setShowDropdown(!showDropdown)}
                      style={{
                        padding: "10px",
                        border: "1px solid var(--primary-color)",
                        borderRadius: "4px",
                        backgroundColor: "white",
                        cursor: "pointer",
                        color: "var(--bs-dropdown-link-color)",
                        display: "none",
                      }}
                    >
                      {selectedStatus}
                    </button>
                    {showDropdown && (
                      <div
                        ref={dropdownRef}
                        style={{
                          position: "absolute",
                          top: "40px", // Adjust position if needed
                          right: "0", // Align with the button
                          borderRadius: "6px",
                          padding: "5px",
                          zIndex: 1000,
                          width: "150px",
                          // maxHeight: "200px",
                          background: "white",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Optional: Add shadow for better visibility
                        }}
                      >
                        {[
                          "All",
                          "Requested",
                          "Rejected",
                          "Accepted",
                          "Closed",
                        ].map((status) => (
                          <button
                            key={status}
                            onClick={() => handleStatusChange(status)}
                            style={{
                              display: "block",
                              width: "100%",
                              padding: "10px",
                              border: "none",
                              background: "white",
                              textAlign: "left",
                              color:
                                selectedStatus === status
                                  ? "var(--primary-color)"
                                  : "var(--bs-dropdown-link-color)",
                            }}
                            className="DrawingFilterStatus"
                          >
                            {status}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {currentView === "requested" && (
              <DrawingTableWrapper
                tableHead={
                  <tr>
                    <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{ width: "10%" }}>DRWG.No</th>
                    <th style={{ width: "10%" }}>Consultant</th>
                    <th style={{ width: "10%" }}>Folder</th>
                    <th style={{ width: "10%" }}>Drawing Category</th>
                    <th style={{ width: "10%" }}>Drawing Title</th>
                    <th style={{ width: "10%" }}>Requested Date</th>
                    <th style={{ width: "10%" }}>Expected Date</th>
                    <th style={{ width: "11%" }}>Due/Overdue</th>
                    {/* <th style={{ width: '15%' }}>Reason</th> */}
                    <th style={{ width: "10%" }}>Revision </th>
                    <th style={{ width: "10%" }}>Status</th>
                    <th style={{ width: "7%" }}>Action</th>
                  </tr>
                }
                tableBody={
                  <tbody style={{ textAlign: "center" }}>
                    <Loader
                      open={apiProcessing.loader}
                      message={apiProcessing.message}
                    />
                    {drawingList
                      .sort((a, b) => {
                        const consultantA = a.drawingId?.category?.category
                          ? a.drawingId?.category?.category.toLowerCase()
                          : "";
                        const consultantB = b.drawingId?.category?.category
                          ? b.drawingId?.category?.category.toLowerCase()
                          : "";
                        return consultantA.localeCompare(consultantB);
                      })
                      .map((drawingItem, index) => (
                        <tr
                          key={index}
                          style={{
                            height: "60px",
                            backgroundColor:
                              index % 2 === 0
                                ? "var(--table-oddrow-color)"
                                : "var(--table-evenrow-color)",
                          }}
                        >
                          <td>{index + 1}</td>
                          <td>{drawingItem.drawingId?.drawingNo || "NA"}</td>
                          <td>
                            {drawingItem?.drawingId?.designDrawingConsultant
                              ?.role || ""}
                          </td>
                          <td>{drawingItem.folderId?.folderName || "NA"}</td>
                          <td>
                            {drawingItem.drawingId?.category?.category || "NA"}
                          </td>
                          <td
                            style={{
                              width: "9%",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              cursor: "default",
                            }}
                          >
                            <span
                              title={drawingItem.drawingId?.drawingTitle}
                              style={{
                                display: "inline-block",
                                maxWidth: "100%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {drawingItem.drawingId?.drawingTitle
                                .split(" ")
                                .slice(0, 2)
                                .join(" ")}
                              ...
                            </span>
                          </td>
                          <td>{formatDate(drawingItem.requestedDate)}</td>
                          <td>{formatDate(drawingItem.expectedDate)}</td>
                          <td>
                            <span
                              style={{
                                color:
                                  new Date(drawingItem.expectedDate) >
                                  new Date()
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {calculateDueDays(
                                new Date(),
                                drawingItem.expectedDate
                              ) === "-"
                                ? "-"
                                : calculateDueDays(
                                    new Date(),
                                    drawingItem.expectedDate
                                  ) >= 0
                                ? `${calculateDueDays(
                                    new Date(),
                                    drawingItem.expectedDate
                                  )} days due`
                                : `${Math.abs(
                                    calculateDueDays(
                                      new Date(),
                                      drawingItem.expectedDate
                                    )
                                  )} days overdue`}
                            </span>
                          </td>
                          <td>
                            {drawingItem.drawingId?.acceptedRORevisions
                              ?.length > 0 ? (
                              <div>
                                {
                                  drawingItem.drawingId.acceptedRORevisions[
                                    drawingItem.drawingId.acceptedRORevisions
                                      .length - 1
                                  ].revision
                                }
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </td>
                          <td>{drawingItem.status}</td>
                          <td
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "18px",
                            }}
                          >
                            {drawingItem.status === "Requested" ||
                            drawingItem.status === "ReOpened" ? (
                              <>
                              {rfiRaisedAccess &&
                                <Icon.Pen
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleEditOpen(drawingItem)}
                                />
                            }
                                <Icon.Download
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleDownload(drawingItem._id)
                                  }
                                />
                                <Icon.EyeFill
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleViewOpen(drawingItem, currentView)
                                  }
                                />
                              </>
                            ) : drawingItem.status === "Accepted" ? (
                              <>
                              {rfiRaisedAccess &&
                                <Icon.Pen
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleConfirmDialogOpen(drawingItem)
                                  }
                                />
                }
                                <Icon.Download
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleDownload(drawingItem._id)
                                  }
                                />
                                <Icon.EyeFill
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleViewOpen(drawingItem, currentView)
                                  }
                                />
                              </>
                            ) : (
                              <Icon.EyeFill
                                size={20}
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleViewOpen(drawingItem, currentView)
                                }
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                }
              />
            )}
            {currentView === "raised" && (
              <DrawingTableWrapper
                tableHead={
                  <tr>
                    <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{ width: "10%" }}>DRWG.No</th>
                    <th style={{ width: "10%" }}>Consultant</th>
                    <th style={{ width: "10%" }}>Folder</th>
                    <th style={{ width: "10%" }}>Drawing Category</th>
                    <th style={{ width: "10%" }}>Drawing Title</th>
                    <th style={{ width: "10%" }}>Raised Date</th>
                    <th style={{ width: "10%" }}>Expected Date</th>
                    <th style={{ width: "11%" }}>Due/Overdue</th>
                    {/* <th style={{ width: '15%' }}>Reason</th> */}
                    <th style={{ width: "10%" }}>Revision </th>
                    <th style={{ width: "10%" }}>Status</th>
                    <th style={{ width: "7%" }}>Action</th>
                  </tr>
                }
                tableBody={
                  <tbody style={{ textAlign: "center" }}>
                    <Loader
                      open={apiProcessing.loader}
                      message={apiProcessing.message}
                    />
                    {drawingList
                      .sort((a, b) => {
                        const consultantA = a.drawingId?.category?.category
                          ? a.drawingId?.category?.category.toLowerCase()
                          : "";
                        const consultantB = b.drawingId?.category?.category
                          ? b.drawingId?.category?.category.toLowerCase()
                          : "";
                        return consultantA.localeCompare(consultantB);
                      })
                      .map((drawingItem, index) => (
                        <tr
                          key={index}
                          style={{
                            height: "60px",
                            backgroundColor:
                              index % 2 === 0
                                ? "var(--table-oddrow-color)"
                                : "var(--table-evenrow-color)",
                          }}
                        >
                          <td>{index + 1}</td>
                          <td>{drawingItem.drawingId?.drawingNo || "NA"}</td>
                          <td>
                            {drawingItem?.drawingId?.designDrawingConsultant
                              ?.role || ""}
                          </td>
                          <td>{drawingItem.folderId?.folderName || "NA"}</td>
                          <td>
                            {drawingItem.drawingId?.category?.category || "NA"}
                          </td>
                          <td
                            style={{
                              width: "9%",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              cursor: "default",
                            }}
                          >
                            <span
                              title={drawingItem.drawingId?.drawingTitle}
                              style={{
                                display: "inline-block",
                                maxWidth: "100%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {drawingItem.drawingId?.drawingTitle
                                .split(" ")
                                .slice(0, 2)
                                .join(" ")}
                              ...
                            </span>
                          </td>

                          <td>{formatDate(drawingItem.requestedDate)}</td>
                          <td>{formatDate(drawingItem.expectedDate)}</td>
                          <td>
                            <span
                              style={{
                                color:
                                  new Date(drawingItem.expectedDate) >
                                  new Date()
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {calculateDueDays(
                                new Date(),
                                drawingItem.expectedDate
                              ) === "-"
                                ? "-"
                                : calculateDueDays(
                                    new Date(),
                                    drawingItem.expectedDate
                                  ) >= 0
                                ? `${calculateDueDays(
                                    new Date(),
                                    drawingItem.expectedDate
                                  )} days due`
                                : `${Math.abs(
                                    calculateDueDays(
                                      new Date(),
                                      drawingItem.expectedDate
                                    )
                                  )} days overdue`}
                            </span>
                          </td>
                          {/* <td onClick={() => handleToggleExpand(`remarks-${index}`)}>
        {expandedRows[`remarks-${index}`]
          ? drawingItem.remarks
          : truncateText(drawingItem.remarks)}
      </td>
      <td onClick={() => handleToggleExpand(`reason-${index}`)}>
        {expandedRows[`reason-${index}`]
          ? drawingItem.reason
          : truncateText(drawingItem.reason)}
      </td> */}

                          <td>
                            {drawingItem.drawingId?.acceptedRORevisions
                              ?.length > 0 ? (
                              <div>
                                {
                                  drawingItem.drawingId.acceptedRORevisions[
                                    drawingItem.drawingId.acceptedRORevisions
                                      .length - 1
                                  ].revision
                                }
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </td>
                          <td>{drawingItem.status}</td>
                          <td
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "18px",
                            }}
                          >
                            {drawingItem.status === "Requested" ? (
                              <>
                                {/* <Icon.Pen
                              size={20}
                              style={{ marginRight: "10px", cursor: "pointer" }}
                              onClick={() => handleEditOpen(drawingItem)}
                            /> */}
                                {/* <Icon.Download
                              size={20}
                              style={{ marginRight: "10px", cursor: "pointer" }}
                              onClick={() => handleDownload(drawingItem._id)}
                            /> */}
                            {rfiRaisedAccess &&
                                <Icon.EyeFill
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSelectedStatus(drawingItem.status);
                                    handleViewOpen(drawingItem, currentView);
                                  }}
                                />
                            }
                              </>
                            ) : drawingItem.status === "Accepted" ? (
                              <>
                              {rfiRaisedAccess &&
                                <Icon.Pen
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleConfirmDialogOpen(drawingItem)
                                  }
                                />
                }
                                <Icon.Download
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleDownload(drawingItem._id)
                                  }
                                />
                                <Icon.EyeFill
                                  size={20}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSelectedStatus(drawingItem.status);
                                    handleViewOpen(drawingItem, currentView);
                                  }}
                                />
                              </>
                            ) : (
                              <Icon.EyeFill
                                size={20}
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setSelectedStatus(drawingItem.status);
                                  handleViewOpen(drawingItem, currentView);
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                }
              />
            )}
          </>
        }
      />
      {showEditPopup && (
        <RFIEditReopenDialog
          showEditPopup={showEditPopup}
          setShowEditPopup={setShowEditPopup}
          tableRowData={tableRowData}
        />
      )}
      <Dialog
        open={editDialogOpen}
        onClose={handleEditClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            fontFamily: "Poppins",
            borderRadius: "10px",
            background: "#FFF",
            boxShadow: "0px 0px 10px 0px #F3F3F3",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 180px", textAlign: "center", color: "white" }}
          >
            Approval/Reject Request
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleEditClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {selectedItem && selectedItem.status !== "Accepted" && (
              <button
                onClick={() => {
                  setCurrentTab("accepted");
                }}
                style={{
                  backgroundColor:
                    currentTab === "accepted"
                      ? "var(--primary-color)"
                      : "transparent",
                  color:
                    currentTab === "accepted"
                      ? "white"
                      : "var(--primary-color)",
                  width: "100px",
                  height: "35px",
                  cursor: "pointer",
                  borderRight: "1px solid var(--primary-color)",
                }}
              >
                Accept
              </button>
            )}
            {/* <button
          onClick={() => setCurrentTab('submitted')}
          style={{
            backgroundColor: currentTab === 'submitted' ? 'var(--primary-color)': 'transparent',
            color: currentTab === 'submitted' ? 'white' : 'var(--primary-color)',
            width: '100px',
            height: '35px',
            cursor: 'pointer',
            borderRight: '1px solid var(--primary-color)',
    
          }}
        >
          Submit
        </button> */}
            <button
              onClick={() => setCurrentTab("reject")}
              style={{
                background:
                  currentTab === "reject"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "reject" ? "white" : "var(--primary-color)",
                width: "100px",
                height: "35px",
                cursor: "pointer",
              }}
            >
              Reject
            </button>
          </div>
        </div>

        {currentTab === "accepted" && (
          <DialogContent
            style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
          >
            <>
              {" "}
              <Loader
                open={apiProcessing.loader}
                message={apiProcessing.message}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  rowGap: "20px",
                  columnGap: "10px",
                  padding: "0 30px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>Request Reason</div>
                <div>:</div>
                <div>{selectedItem ? selectedItem.remarks : "-"}</div>

                <div>Nature of Request Info</div>
                <div>:</div>
                <div>
                  {selectedItem
                    ? selectedItem.natureOfRequestedInformation &&
                      selectedItem.natureOfRequestedInformation.length > 0
                      ? selectedItem.natureOfRequestedInformation.map(
                          (item, index) => (
                            <div key={index} style={{ display: "inline" }}>
                              {item}
                              {index <
                                selectedItem.natureOfRequestedInformation
                                  .length -
                                  1 && ", "}
                              <br />
                            </div>
                          )
                        )
                      : "-"
                    : "-"}
                </div>
                <div>Revision</div>
                <div>:</div>
                <div>
                  {latestRevisionedit ? latestRevisionedit.revision : "-"}
                </div>

                <div>Requested Date</div>
                <div>:</div>
                <div>
                  {selectedItem?.requestedDate
                    ? formatDate(selectedItem.requestedDate)
                    : "-"}
                </div>

                <div>Expected Date</div>
                <div>:</div>
                <div>
                  {selectedItem?.expectedDate
                    ? formatDate(selectedItem.expectedDate)
                    : "-"}
                </div>
              </div>
              <div style={{ padding: "0 30px" }}>
                {/* <div style={{flex:'1'}}>
                <label htmlFor="response" className="fs-10 fw-bold text-start py-2">
                   Response
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  name="response"
                  value={formData.response}
                  onChange={handleChange}
                  placeholder="Enter your Response"
                  className="form-control fs-12"
                  style={{ width: '100%' }}
                />
                {errors.response && <span style={{ color: 'red', fontSize: '12px' }}>{errors.response}</span>}
                </div> */}
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={handleFormSubmit}
                    style={{
                      background: "var(--primary-color)",
                      color: "white",
                      width: "100px",
                      height: "35px",
                      border: "none",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          </DialogContent>
        )}

        {/* {currentTab === 'submitted' && (
            <DialogContent style={{ padding: '25px', minWidth: '300px', minHeight: '300px' }}>
            <>  <Loader open={apiProcessing.loader} message={apiProcessing.message} />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <div>
                <div>
                  <label htmlFor="issuedSoftCopy" className="fs-10 fw-bold text-start py-2">
                    Issued Soft Copy
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <select
                    name="issuedSoftCopy"
                    value={formData.issuedSoftCopy}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: '100%' }}
                  >
                    <option value="">Select Yes/No</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {errors.issuedSoftCopy && (
                    <p style={{ color: 'red', fontSize: '12px' }}>{errors.issuedSoftCopy}</p>
                  )}
                </div>
                <div>
                  <label htmlFor="receivedHardCopy" className="fs-10 fw-bold text-start py-2">
                    Received Hard Copy
                    <span style={{ color: 'red' }}>*</span>
                  </label>
                  <select
                    name="receivedHardCopy"
                    value={formData.receivedHardCopy}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: '100%' }}
                  >
                    <option value="">Select Yes/No</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {errors.receivedHardCopy && (
                    <p style={{ color: 'red', fontSize: '12px' }}>{errors.receivedHardCopy}</p>
                  )}
                </div>
                </div> */}
        {/* <div>
                <label htmlFor="remarks" className="fs-10 fw-bold text-start py-2">
                  Enter your Remarks
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                  placeholder="Enter your Remarks"
                  className="form-control fs-12"
                  style={{ width: '100%' }}
                />
                {errors.remarks && <span style={{ color: 'red', fontSize: '12px' }}>{errors.remarks}</span>}
              </div> */}
        {/* <div >
          <label htmlFor="drawingFileName" className="fs-10 fw-bold text-start py-2">
            Upload Your File
            <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="file"
            accept=".dwg"
            name="drawingFileName"
            onChange={handleChange}
            className="form-control fs-12"
            style={{ width: '100%' }}
          />
          {errors.drawingFileName && (
            <span style={{ color: 'red', fontSize: '12px' }}>{errors.drawingFileName}</span>
          )}
        </div>
        <div
          style={{ marginTop: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
              <button
            onClick={handleFormSubmit}
            style={{
              background: 'var(--primary-color)',
              color: 'white',
              width: '100px',
              height: '35px',
              border: 'none',
              borderRadius: '5px'
            }}
          >
            Submit
          </button>
          </div>
              </div>
              </>
            </DialogContent>
          )}
       */}

        {currentTab === "reject" && (
          <DialogContent
            style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
          >
            <>
              <Loader
                open={apiProcessing.loader}
                message={apiProcessing.message}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  rowGap: "20px",
                  columnGap: "10px",
                  padding: "0 30px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>Request Reason</div>
                <div>:</div>
                <div>{selectedItem ? selectedItem.remarks : "-"}</div>

                <div>Nature of Request Info</div>
                <div>:</div>
                <div>
                  {selectedItem
                    ? selectedItem.natureOfRequestedInformation &&
                      selectedItem.natureOfRequestedInformation.length > 0
                      ? selectedItem.natureOfRequestedInformation.map(
                          (item, index) => (
                            <div key={index} style={{ display: "inline" }}>
                              {item}
                              {index <
                                selectedItem.natureOfRequestedInformation
                                  .length -
                                  1 && ", "}
                              <br />
                            </div>
                          )
                        )
                      : "-"
                    : "-"}
                </div>
              </div>
              <div style={{ padding: "10px 30px" }}>
                <div style={{ flex: "1" }}>
                  <label
                    htmlFor="reason"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Enter your Reason
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="reason"
                    value={formData.reason}
                    onChange={handleChange}
                    placeholder="Could you please provide the reason for rejecting this request?"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />
                  {errors.reason && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.reason}
                    </span>
                  )}
                </div>
                <div style={{ flex: "1" }}>
                  <label
                    htmlFor="drawingFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Upload Your File
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    name="drawingFileName"
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%", height: "43px" }}
                  />
                  {errors.drawingFileName && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingFileName}
                    </span>
                  )}
                </div>
              </div>
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={handleFormSubmit}
                    style={{
                      background: "var(--primary-color)",
                      color: "white",
                      width: "100px",
                      height: "35px",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          </DialogContent>
        )}
      </Dialog>

      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogContent>
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            Are you sure want to close ?
          </>
        </DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <button
            style={{
              background: "var(--primary-color)",
              color: "white",
              width: "100px",
              height: "35px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={handleConfirmDialogClose}
          >
            Cancel
          </button>
          <button
            style={{
              background: "var(--primary-color)",
              color: "white",
              width: "100px",
              height: "35px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={handleConfirmSubmit}
          >
            Submit
          </button>
        </div>
      </Dialog>

      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "10px",
            fontFamily: "Poppins",
            background: "#FFF",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#4D4D4D",
            padding: "10px",
            position: "relative",
          }}
        >
          <p style={{ color: "white", margin: "0 auto" }}>View</p>
          <RxCross1
            size={20}
            style={{ cursor: "pointer", color: "white" }}
            onClick={handleViewClose}
          />
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
          {currentView === "raised" && (
            <>
              {selectedStatus === "Requested" && (
                <>
                {rfiRaisedAccess &&
                <button
                  style={{
                    backgroundColor: "#e47331",
                    color: "white",
                    padding: "6px 20px",
                    position: "absolute",
                    right: "20px",
                  }}
                  onClick={() => handleEditPopup()}
                >
                  Edit
                </button>
}
                </>
              )}
              {selectedStatus === "Rejected" && (
                <>
                {rfiRaisedAccess &&
                <button
                  style={{
                    backgroundColor: "#e47331",
                    color: "white",
                    padding: "6px 20px",
                    position: "absolute",
                    right: "20px",
                  }}
                  onClick={() => handleEditPopup()}
                >
                  ReOpen
                </button>
}
</>
              )}
            </>
          )}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "20px",
              padding: "20px",
              color: "#4D4D4D",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "28px",
              justifyContent: "center",
            }}
          >
            <div>Drawing Number</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.drawingId?.drawingNo || "NA"}
            </div>

            <div>Design Consultant</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.drawingId?.designDrawingConsultant
                ?.designDrawingConsultant || "NA"}
            </div>

            <div>Design Category</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.drawingId?.category?.category || "NA"}
            </div>
            <div>Folder</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.folderId?.folderName || "NA"}
            </div>
            <div>Drawing Title</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.drawingId?.drawingTitle || "NA"}
            </div>

            <div>Requested Date</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.requestedDate
                ? new Date(singleDrawingData.requestedDate)
                    .toISOString()
                    .split("T")[0]
                : "NA"}
            </div>

            <div>Expected Date</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.expectedDate
                ? new Date(singleDrawingData.expectedDate)
                    .toISOString()
                    .split("T")[0]
                : "NA"}
            </div>

            {/* <div>Nature of Request Info</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.natureOfRequestedInformation?.length > 0
                ? singleDrawingData.natureOfRequestedInformation.map(
                    (item, index) => (
                      <div key={index} style={{ display: "inline" }}>
                        {item}
                        {index <
                          singleDrawingData.natureOfRequestedInformation
                            .length -
                            1 && ", "}
                        <br />
                      </div>
                    )
                  )
                : "-"}
            </div> */}

<div >Nature of Request Information</div>
      <div>:</div>
      <div>
        {singleDrawingData?.natureOfRequestedInformationReasons?.length > 0 ? (
          singleDrawingData.natureOfRequestedInformationReasons.map((item) => (
            <div key={item._id} style={{ marginTop: "10px" }}>
              <div style={{ fontWeight: "500" }}>{item.natureOfRequest}</div>
              <ul style={{ margin: 0 }}>
                {item.reason.map((reason, index) => (
                  <li key={index} style={{ color: "#4D4D4D" }}>
                    {reason}
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <div>No nature of requested information available.</div>
        )}
      </div>
            <div>Reason</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.remarks || "NA"}
            </div>

            <div>Status</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {singleDrawingData?.status || "NA"}
            </div>

            {singleDrawingData && singleDrawingData?.status === "Rejected" && (
              <>
                <div>Rejected Reason</div>
                <div>:</div>
                <div style={{ marginLeft: "-120px" }}>
                  {singleDrawingData?.reason || "NA"}
                </div>
              </>
            )}

            <div>Revision</div>
            <div>:</div>
            <div style={{ marginLeft: "-130px" }}>
              {latestRevision ? latestRevision.revision : "-"}
            </div>

            <div>Requested Drawing File</div>
            <div>:</div>
            <div style={{ display: "flex", marginLeft: "-130px" }}>
              <Icon.Image
                size={20}
                style={{ marginRight: "10px", cursor: "pointer" }}
                onClick={() => handleViewFile(singleDrawingData?._id, "")}
              />
              <Icon.Download
                size={20}
                style={{ marginRight: "5px", cursor: "pointer" }}
                onClick={() => handleDownload(singleDrawingData?._id, "")}
              />
            </div>
            {singleDrawingData && singleDrawingData?.status === "Rejected" && (
              <>
                <div>Rejected Drawing File </div>
                <div>:</div>
                <div style={{ display: "flex", marginLeft: "-120px" }}>
                  <Icon.Image
                    size={20}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() =>
                      handleViewFile(singleDrawingData?._id, "Rejected")
                    }
                  />
                  <Icon.Download
                    size={20}
                    style={{ marginRight: "5px", cursor: "pointer" }}
                    onClick={() =>
                      handleDownload(singleDrawingData?._id, "Rejected")
                    }
                  />
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RequestedTable;
