import React, { useEffect, useState } from "react";
import { WebApimanager } from "../../../WebApiManager";
import { BACKEND_URL } from "../../../Utilities/Constants";
import "./ConstructionNeeds.css";

const CardDetailsModal = ({
  show,
  handleClose,
  item,
  index,
  items = [],
  onNavigate,
}) => {
  const [constructionFolders, setConstructionFolders] = useState([]);

  console.log(items, "sdkjfbhsdjgfjdsgcjdshb");

  const {
    companyName,
    address,
    location,
    description,
    typesOfServiceProviding,
    contactNumber,
    mailId,
    uploadFile,
    constructionNeedContactName,
    constructionNeedContactRole,
  } = item || {};

  const webApi = new WebApimanager();

  useEffect(() => {
    webApi
      .get("api/constructionNeeds")
      .then((response) => {
        setConstructionFolders(response.data.data.constructionNeeds);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const handleDotClick = (dotIndex) => {
    onNavigate(dotIndex);
  };

  const renderDots = () => {
    const totalItems = items.length;
    const numOfDotsToShow = 3;

    let startIndex = Math.max(0, index - 1);
    let endIndex = Math.min(totalItems - 1, index + 1);

    if (endIndex - startIndex < numOfDotsToShow - 1) {
      if (startIndex === 0) {
        endIndex = Math.min(numOfDotsToShow - 1, totalItems - 1);
      } else if (endIndex === totalItems - 1) {
        startIndex = Math.max(totalItems - numOfDotsToShow, 0);
      }
    }

    return items.slice(startIndex, endIndex + 1).map((_, dotIndex) => (
      <span
        key={dotIndex + startIndex}
        onClick={() => handleDotClick(dotIndex + startIndex)}
        style={{
          display: "inline-block",
          width: "10px",
          height: "10px",
          margin: "0 5px",
          borderRadius: "50%",
          backgroundColor: dotIndex + startIndex === index ? "#E47331" : "#ddd",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
      />
    ));
  };

  return (
    <div
      className={`modal fade ${show ? "show d-block" : ""}`}
      tabIndex="-1"
      role="dialog"
      style={show ? { backgroundColor: "rgba(0, 0, 0, 0.5)" } : {}}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{
          borderRadius: "10px 10px 0px 0px",
          width: "80%",
          height: "auto",
          maxWidth: "650px",
          margin: "auto",
        }}
      >
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              backgroundColor: "#303030",
              color: "white",
              height: "50px",
              position: "sticky",
              top: 0,
              zIndex: 10,
            }}
          >
            <p
              className="modal-title"
              style={{
                fontSize: "20px",
                fontWeight: "500",
                margin: "0 auto",
                textAlign: "center",
                color: "white",
              }}
            >
              Details
            </p>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleClose}
              style={{ fontSize: "20px", color: "white" }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{ maxHeight: "650px", overflowY: "auto" }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={`${BACKEND_URL}${uploadFile}`}
                alt={companyName}
                style={{
                  width: " 100%",
                  height: "350px",
                  objectFit: "cover",
                  marginBottom: "20px",
                }}
              />

              <div style={{ justifyContent: "center" }}>{renderDots()}</div>

              <div
                style={{
                  textAlign: "left",
                  marginLeft: "20px",
                  color: "#979797",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                <div style={{ marginBottom: "7px" }}>
                  <p className="cardDetailesCompanyName">
                    {companyName || "No Company Name"}
                  </p>

                  <p>
                    <span>DESCRIPTION : </span>
                    {description || "No Description"}
                  </p>
                  <p>
                    <span>TYPES OF SERVICES PROVIDED : </span>
                    {typesOfServiceProviding || "No Service Type"}
                  </p>
                  <p>
                    <span>ADDRESS : </span>
                    {address || "No Address"}, {location || "No Location"}
                  </p>

                  <div>
                    <p className="cardModalTitle">Contact person details</p>
                  </div>

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      gap: "0px",
                    }}
                  >
                    <div>
                      <p className="cardDetailescontactLable">Name</p>
                    </div>
                    <div>
                      <p>:</p>
                    </div>
                    <div>
                      <p className="cardDetailescontactLableData">
                        {constructionNeedContactName}
                      </p>
                    </div>
                    <div>
                      <p className="cardDetailescontactLable">Role</p>
                    </div>
                    <div>
                      <p>:</p>
                    </div>
                    <div>
                      <p className="cardDetailescontactLableData">
                        {constructionNeedContactRole}
                      </p>
                    </div>
                    <div>
                      <p className="cardDetailescontactLable">Mobile</p>
                    </div>
                    <div>
                      <p>:</p>
                    </div>
                    <div>
                      <p className="cardDetailescontactLableData">
                        {contactNumber || "No Contact Number"}
                      </p>
                    </div>
                    <div>
                      <p className="cardDetailescontactLable">Email id</p>
                    </div>
                    <div>
                      <p>:</p>
                    </div>
                    <div>
                      <p className="cardDetailescontactLableData">
                        {mailId || "No Email ID"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetailsModal;
