import React from 'react';
import styles from './PartnerCard.module.css';

const PartnerCard = ({ imageSrc, title, description }) => {
  return (
    <article className={styles.partnerCard}>
      <img loading="lazy" src={imageSrc} alt={`${title} icon`} className={styles.partnerIcon} />
      <h2 className={styles.partnerTitle}>{title}</h2>
      <p className={styles.partnerDescription}>{description}</p>
    </article>
  );
};

export default PartnerCard;