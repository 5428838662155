import React, { useState, useEffect } from "react";
import SelectSite from "../../Utilities/SelectSite";
import Approval from "./Approval/Approval";
import Form from "./Form/Form";
import { useRecoilState } from "recoil";
import { ChecklistTab, siteId, userInfo } from "../../atoms";
import { WebApimanager } from "../../WebApiManager";
import DesignForm from "./Design/DesignForm";
import Workflow from "./Workflow/Workflow";

const Checklist = () => {
  const webApi = new WebApimanager();
  const [site, setSite] = useRecoilState(siteId);
  const [currentUserInfo] = useRecoilState(userInfo);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [value, setValue] = useRecoilState(ChecklistTab);
  const [tabEnabled, setTabEnabled] = useState({
    approval: false,
    design: false,
    forms: false,
    workflow: false,
  });

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log("CheckList data for checking", currentUserInfo);
    if (currentUserInfo?.permittedSites) {
      let enabledTabs = {
        approval: false,
        design: false,
        forms: false,
        workflow: false,
      };

      currentUserInfo.permittedSites.forEach((site) => {
        const checkListDetails = site.enableModules.checkListDetails;
        if (checkListDetails) {
          enabledTabs.approval =
            checkListDetails.approval || enabledTabs.approval;
          enabledTabs.design = checkListDetails.design || enabledTabs.design;
          enabledTabs.forms = checkListDetails.forms || enabledTabs.forms;
          enabledTabs.workflow =
            checkListDetails.workflow || enabledTabs.workflow;
        }
      });
      setTabEnabled(enabledTabs);
    }
  }, [currentUserInfo]);

  const handleSiteChange = async (e) => {
    setSite(e.target.value);
    setApiProcessing({
      loader: true,
      message: "Loading...",
    });

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setApiProcessing({
        loader: false,
        message: "",
      });
    } catch (error) {
      console.error("Error occurred while changing the site:", error);
      setApiProcessing({
        loader: false,
        message: "Failed to load site data",
      });
    }
  };

  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  if (site === "") {
    return <SelectSite />;
  }

  const allTabs = [
    {
      label: "Design",
      index: 0,
      component: <DesignForm />,
      enabled: tabEnabled.design,
    },
    {
      label: "Approval",
      index: 1,
      component: <Approval />,
      enabled: tabEnabled.approval,
    },
    {
      label: "Forms",
      index: 2,
      component: <Form />,
      enabled: tabEnabled.forms,
    },
    {
      label: "Workflow",
      index: 3,
      component: <Workflow />,
      enabled: tabEnabled.workflow,
    },
  ].filter((tab) => tab.enabled); // Only include enabled tabs

  // Check if no tabs are enabled
  const noTabsEnabled = allTabs.length === 0;

  return (
    <>
      <div
        className="containerDrawingPagesRotoSite"
        style={{ backgroundColor: "#F3F6F8" }}
      >
        <ul
          className="tabsRotoSite"
          role="tablist"
          style={{ marginTop: "20px" }}
        >
          {noTabsEnabled ? (
            <li className="tabRotoSite">
              You do not have the necessary permissions to view any
              ArchitectureToRo Tabs.
              <br />
              Please contact your administrator for access rights.
            </li>
          ) : (
            allTabs.map((tab) => (
              <li
                key={tab.index}
                className={`tabRotoSite ${value === tab.index ? "active" : ""}`}
                role="tab"
                aria-selected={value === tab.index}
                aria-controls={`tabpanel-${tab.index}`}
                id={`tab-${tab.index}`}
                onClick={() => handleChange(tab.index)}
              >
                {tab.label}
              </li>
            ))
          )}
        </ul>
      </div>

      {allTabs.map((tab) => (
        <TabPanel key={tab.index} value={value} index={tab.index}>
          {tab.component}
        </TabPanel>
      ))}
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      className="tabpanel"
    >
      {value === index && <div className="tabpanel-content">{children}</div>}
    </div>
  );
}

export default Checklist;
