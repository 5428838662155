import React from "react";

const SiteDetailsModal = ({ show, onHide, site }) => {
  console.log(site, "dhfgvh");

  const modalClass = show ? "modal fade show" : "modal fade";
  const style = show ? { display: "block" } : { display: "none" };

  return (
    <div className={modalClass} style={style} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              color: "white",
              position: "sticky",
              top: 0,
            }}
          >
            <h5
              className="modal-title"
              style={{ flexGrow: 1, textAlign: "center", color: "white" }}
            >
              {site?.siteName}
            </h5>
            <button type="button" className="close" onClick={onHide}>
              <span>&times;</span>
            </button>
          </div>

          <div
            className="modal-body"
            style={{
              maxHeight: "calc(100vh - 150px)", // Adjust based on header and footer height
              overflowY: "auto",
              padding: "1rem",
            }}
          >
            {site && (
              <div style={{ marginLeft: "10px", marginRight: "80px" }}>
                <div style={{ paddingBottom: "32px", marginBottom: "16px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h3>Site Details of {site?.siteName}</h3>
                    {/* <button style={{border:'1px solid blue' , backgroundColor:'blue', color:'white',padding:'5px',width:'80px',borderRadius:'2px'}}
           onClick={() => handleEditClick(companyData)}>
               EDIT
          </button> */}
                  </div>
                  <div
                    style={{
                      marginTop: "16px",
                      borderBottom: "1px dotted lightgray",
                    }}
                  >
                    {[
                      {
                        label: "Venture Type",
                        value: site?.ventureType,
                      },
                      {
                        label: "City or State",
                        value: site?.cityOrState,
                      },
                      { label: "Country", value: site?.country },
                      { label: "Pin Code", value: site?.pinCode },
                      {
                        label: "No Of Checklists",
                        value: site?.fetureDetails,
                      },
                      {
                        label: "Site Address",
                        value: site?.siteAddress,
                      },
                      {
                        label: "Latitude",
                        value: site?.siteLocationDetails?.latitude,
                      },
                      {
                        label: "Longitude",
                        value: site?.siteLocationDetails?.longitude,
                      },
                      {
                        label: "Radius",
                        value: site?.siteLocationDetails?.radius,
                      },
                    ].map((field, index) => (
                      <div
                        key={index}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          gap: "20px",
                          marginBottom: "16px",
                        }}
                      >
                        <span style={{ fontWeight: "600" }}>{field.label}</span>
                        <span>:</span>
                        <span>{field.value || "N/A"}</span>
                      </div>
                    ))}
                  </div>
                  {site?.apartmentsDetails && (
                    <div
                      style={{
                        marginTop: "16px",
                        borderBottom: "1px dotted lightgray",
                      }}
                    >
                      <h3 style={{ fontWeight: 200, marginBottom: "8px" }}>
                        Apartment Details
                      </h3>
                      {[
                        {
                          label: "Number of Towers",
                          value: site?.apartmentsDetails?.noOfTowers,
                        },
                        {
                          label: "Number of Basements",
                          value: site?.apartmentsDetails?.noOfBasements,
                        },
                        {
                          label: "Basements Are Common For Every Tower",
                          value:
                            site?.apartmentsDetails
                              ?.basementsAreCommonForEveryTower,
                        },
                        {
                          label: "Number of Pours for Basement",
                          value: site?.apartmentsDetails?.noOfPouresForBasement,
                        },
                        {
                          label: "Number of Clubhouses",
                          value: site?.apartmentsDetails?.noOfClubHouses,
                        },
                        {
                          label: "Basements",
                          value: site?.apartmentsDetails?.basements,
                        },
                        {
                          label: "Amenities",
                          value: site?.apartmentsDetails?.amenities?.join(", "),
                        },
                        {
                          label: "Clubhouse",
                          value: site?.apartmentsDetails?.clubhouse?.length,
                        },
                      ].map((field, index) => (
                        <div
                          key={index}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "20px",
                            marginBottom: "16px",
                          }}
                        >
                          <span style={{ fontWeight: "600" }}>
                            {field.label}
                          </span>
                          <span>:</span>
                          <span>{field.value || "N/A"}</span>
                        </div>
                      ))}
                    </div>
                  )}

                  {site?.buildingsDetails && (
                    <div
                      style={{
                        marginTop: "16px",
                        borderBottom: "1px dotted lightgray",
                      }}
                    >
                      <h3 style={{ fontWeight: "bold", marginBottom: "8px" }}>
                        Building Details
                      </h3>
                      {[
                        {
                          label: "Amenities Available",
                          value: site?.buildingsDetails?.amenityAreThere,
                        },
                        {
                          label: "Number of Basements",
                          value: site?.buildingsDetails?.noOfBasements,
                        },
                        {
                          label: "Basements",
                          value: site?.buildingsDetails?.basements,
                        },
                        {
                          label: "Number of Pours for Basement",
                          value: site?.buildingsDetails?.noOfPouresForBasement,
                        },
                        {
                          label: "Amenities",
                          value: site?.buildingsDetails?.amenities?.join(", "),
                        },
                      ].map((field, index) => (
                        <div
                          key={index}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "20px",
                            marginBottom: "16px",
                          }}
                        >
                          <span style={{ fontWeight: "600" }}>
                            {field.label}
                          </span>
                          <span>:</span>
                          <span>{field.value || "N/A"}</span>
                        </div>
                      ))}

                      {site?.buildingsDetails?.towers?.floors?.map(
                        (floor, floorIndex) => (
                          <div key={floorIndex} style={{ marginTop: "16px" }}>
                            <h4
                              style={{
                                fontWeight: "bold",
                                marginBottom: "8px",
                              }}
                            >
                              {floor.name}
                            </h4>
                            <div style={{ marginBottom: "8px" }}>
                              <span style={{ fontWeight: "600" }}>
                                Number of Units
                              </span>
                              <span>:</span>
                              <span>{floor.numUnits || "N/A"}</span>
                            </div>

                            {/* Loop through units for each floor */}
                            {floor.units.map((unit, unitIndex) => (
                              <div
                                key={unitIndex}
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(3, 1fr)",
                                  gap: "20px",
                                  marginBottom: "16px",
                                }}
                              >
                                <span style={{ fontWeight: "600" }}>
                                  Unit {unit.name}
                                </span>
                                <span>:</span>
                                <span>{unit.type || "N/A"}</span>
                              </div>
                            ))}
                          </div>
                        )
                      )}
                    </div>
                  )}

                  {site?.villasDetails && (
                    <div>
                      <h3 style={{ fontWeight: "bold", marginBottom: "8px" }}>
                        Villa Details
                      </h3>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          gap: "20px",
                          marginBottom: "16px",
                        }}
                      >
                        {[
                          {
                            label: "Number of Villas",
                            value: site?.villasDetails?.noOfVillas,
                          },
                          {
                            label: "Cellar for Villas",
                            value: site?.villasDetails?.cellarIsThereForVillas,
                          },
                          {
                            label: "Floors Common for Every Villa",
                            value:
                              site?.villasDetails
                                ?.floorsAreCommonForEveryVillas,
                          },
                          {
                            label: "Number of Club Houses",
                            value: site?.villasDetails?.noOfClubHouses,
                          },
                          {
                            label: "Amenities",
                            value: site?.villasDetails?.amenities?.join(", "),
                          },
                        ].map((field, index) => (
                          <React.Fragment key={index}>
                            <span style={{ fontWeight: "600" }}>
                              {field.label}
                            </span>
                            <span>:</span>
                            <span>{field.value || "N/A"}</span>
                          </React.Fragment>
                        ))}
                      </div>

                      {/* Villa Type Details */}
                      {site?.villasDetails?.villaTypeDetails?.map(
                        (villa, villaIndex) => (
                          <div key={villaIndex} style={{ marginTop: "16px" }}>
                            <h4
                              style={{
                                fontWeight: "bold",
                                marginBottom: "8px",
                              }}
                            >
                              {villa.villaName || `Villa ${villaIndex + 1}`}
                            </h4>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(3, 1fr)",
                                gap: "20px",
                                marginBottom: "16px",
                              }}
                            >
                              <span style={{ fontWeight: "600" }}>
                                Villa Type
                              </span>
                              <span>:</span>
                              <span>{villa.villaType || "N/A"}</span>
                            </div>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(3, 1fr)",
                                gap: "20px",
                                marginBottom: "16px",
                              }}
                            >
                              <span style={{ fontWeight: "600" }}>Cellar</span>
                              <span>:</span>
                              <span>{villa.cellar || "N/A"}</span>
                            </div>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(3, 1fr)",
                                gap: "20px",
                                marginBottom: "16px",
                              }}
                            >
                              <span style={{ fontWeight: "600" }}>Floor</span>
                              <span>:</span>
                              <span>{villa.floor || "N/A"}</span>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteDetailsModal;
