import React from 'react';
import styles from './Contact.module.css';

const Contact = () => {
  return (
    <section className={styles.contact}>
      <div className={styles.contactMain}>
      <div className={styles.contactInfo}>
        <h2 className={styles.contactTitle}>Our Office</h2>
        <p className={styles.contactAddress}>
        61/3RT, Street Number 6, East Srinivas Nagar Colony, Sanjeeva Reddy Nagar Office Area, Sanjeeva Reddy Nagar, Hyderabad, Telangana 500038
        </p>
        <div className={styles.contactDetails}>
          <div>
            <h3 className={styles.detailTitle}>Phone No</h3>
            <p className={styles.detailContent}>94911 16000</p>
          </div>
          <div>
            <h3 className={styles.detailTitle}>Email id</h3>
            <p className={styles.detailContent}>support@mrchams.com</p>
          </div>
        </div>
      </div>
      <div className={styles.contactMap}>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/524db4c43dd27067e74b88cfbf016fd98c863e276b2a5f8d3f3cfab1c80095ee?apiKey=3e99c58a56f84e4cb0d84873c390b13e&" alt="Office location map" className={styles.mapImage} />
      </div>
      </div>
      
      
    </section>
  );
};

export default Contact;