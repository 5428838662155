import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { registerAtom, siteId, userInfo } from "../../../atoms";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { processFile, updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Tabs, Tab } from "../../Tabs/Tabv1";
import { HiPlusCircle } from "react-icons/hi";
import { FaCircleMinus } from "react-icons/fa6";
import { RiShareForwardLine } from "react-icons/ri";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import MultiSelectSearch from "../../../Utilities/MultiSelectSearch";
import { Tab1, Tabs1 } from "../../Tabs/Tabv2";

const IssuedTable = ({
  filterCondition,
  reloadData,
  setReloadData,
  searchValue,
  forwardAccess,
  rfiRaisedAccess
}) => {

  const navigate = useNavigate();
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDateString = tomorrow.toISOString().split("T")[0];

  let webApi = new WebApimanager();

  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [error, setError] = useState({});
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [selectedRevision, setSelectedRevision] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const userDetails = useRecoilState(userInfo);
  const companyID = userDetails[0]?.companyId;
  const [formData, setFormData] = useState({
    registerId: "",
    drawingNumber: "",
    drawingName: "",
    category: "",
    designDrawingConsultant: "",
    acceptedArchitectDate: "",
    // actualSubmissionDate: todayDate(),
    // dueDays: '',
    // remarks:'',
    submittedDate: "",
    issuedSoftCopy: "",
    receivedSoftCopy: "",
    drawingFileName: null,
    receivedCopies: "",
    revisions: [],
    selectedRevision: "",
  });
  const [editData, setEditData] = useState({
    drawingId: "",
    drawingNumber: "",
    expectedDate: "",
    requestedDate: todayDate(),

    drawingFileName: null,
    revision: [],
    natureofrequestedInfo: "",
  });
  const [requestReason, setRequestReason] = useState([{ NOR: "", reason: "" }]);
  const [drawingTableList, setDrawingTableList] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [selectedTab, setSelectedTab] = useState(0);

  const listItems = [
    { id: "A - Missing Information", name: "A - Missing Information" },
    { id: "B - Clarification", name: "B - Clarification" },
    { id: "C - Additional Information", name: "C - Additional Information" },
    { id: "D - Uncoordinated Drawings", name: "D - Uncoordinated Drawings" },
  ];

  // Function to get the name from id
  const getNameFromId = (id) => {
    const item = listItems.find((item) => item.id === id);
    return item ? item.name : "";
  };

  const handleClose = () => {
    setOpen(false);
    setEditDialogOpen(false);
    setViewDialogOpen(false);
  };

  const [currentTab, setCurrentTab] = useState("received");

  const handleTabChange = (event, newValue) => {
    setSelectedTab( newValue);
  };
  // const currentTab = 'received';

  // Example validation function
  const validateForm = (formData, currentTab) => {
    const newErrors = {};
    if (currentTab === "upload") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      }
      // if (!formData.remarks) errors.remarks = 'This field is required.';
    } else if (currentTab === "received") {
      if (!formData.drawingNumber.trim()) {
        newErrors.drawingNumber = "DrawingNumber  is required";
      }
      if (!formData.drawingName.trim()) {
        newErrors.drawingName = "Drawing Title is required";
      }
      if (!formData.designDrawingConsultant.trim()) {
        newErrors.designDrawingConsultant =
          "DesignDrawingConsultant is required";
      }
      if (!formData.category.trim()) {
        newErrors.category = "Category is required";
      }
      if (!formData.submittedDate.trim()) {
        newErrors.submittedDate = "Submitted Date is required";
      }
      if (!formData.selectedRevision) {
        newErrors.selectedRevision = "revision is required";
      }
      if (!formData.receivedCopies) {
        newErrors.receivedCopies = "receivedCopies are required";
      }

      if (!formData.drawingFileName) {
        newErrors.drawingFileName = "Drawing File is required";
      }
    }
    return newErrors;
  };

  const handleFormSubmit = async () => {
    const errors = validateForm(formData, currentTab);
    setErrors(errors);

    if (Object.keys(errors).length > 0) return;

    if (currentTab === "received") {
      setApiProcessing({
        loader: true,
        message: "Submitting...",
      });
      await submitReceived(formData.registerId);
    }
  };

  const submitReceived = async (drawingItemId) => {
    try {
      const ApprovalFormData = {
        acceptedROHardCopyRevisions: [
          {
            hardCopySubmittedDate: todayDate(),
            revision: formData.selectedRevision,
            receivedCopies: formData.receivedCopies,
          },
        ],
      };

      console.log("Submitting Approval data:", ApprovalFormData);

      const response = await webApi.put(
        `api/architectureToRoRegister/HardCopyRevision/${drawingItemId}`,
        ApprovalFormData
      );
      const id = response.data.data._id;

      // If there's a file to upload, initiate PUT request to upload file
      if (formData.drawingFileName) {
        const hardCopyFile = new FormData();
        hardCopyFile.append("hardCopyFile", formData.drawingFileName);
        await webApi.imagePut(
          `api/architectureToRoRegister/hardCopyFile/${drawingItemId}`,
          hardCopyFile
        );
      }
      setApiProcessing({ loader: false, message: "" });
      handleClose();
      // window.location.reload();
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      console.error("Error submitting form:", error);
      // Handle error, show error message, etc.
    }
  };
  const handleForwardClick = async (singledrawingdata, revisionNo) => {
    console.log(singledrawingdata, revisionNo,"handleForwardClick")
    const registerId = singledrawingdata.drawingId;
    
    // Find the specific revision data in acceptedArchitectRevisions
    const selectedRevision = singledrawingdata.acceptedArchitectRevisions.find(
      (revision) => revision.revision ===`R${revisionNo}`
    );
    console.log(selectedRevision,"revision")
  
    if (!selectedRevision) {
      alert("Revision not found");
      return;
    }
  
    try {
      const ApprovalFormData = new FormData();
      ApprovalFormData.append("softCopySubmittedDate", todayDate());
      ApprovalFormData.append("changes", selectedRevision.changes || "");
      ApprovalFormData.append("issuedSoftCopy", "Yes");
      ApprovalFormData.append("architectRef",selectedRevision.revision)
      ApprovalFormData.append("drawingFileName", selectedRevision.drawingFileName);
      ApprovalFormData.append("pdfDrawingFileName", selectedRevision.pdfDrawingFileName);
       ApprovalFormData.append("roType", "Forwarded");
      console.log("Submitting Approval data:", ApprovalFormData);
      const response = await webApi.imagePut(
        `api/architectureToRoRegister/revisions/${registerId}?revisionType=acceptedRORevisions`,
        ApprovalFormData
      );
  
      if (response.status === 200) {
        setApiProcessing({ loader: false, message: "" });
        // handleClose();
        // window.location.reload();
      } else {
        console.error("Failed to submit the form:", response);
        alert("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setApiProcessing({ loader: false, message: "" });
      alert("An error occurred. Please try again later.");
    }
  };
  
  const handlePDFDownload = async (filename) => {
    console.log(filename, "fiename");
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      //TODO: need to check this path correct or not
      const response = await webApi.getImage(
        `api/architectureToRoRegister/byPath/${companyID}/${site}/drawings/${filename}`,
        // `api/architectureToRoRequested/${id}?revision=${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewPDFFile = async (filename) => {
    console.log(filename, "getting inside");
    try {
      setApiProcessing({ loader: true, message: "Viewing" });

      // Make the request without explicitly setting the responseType
      const response = await webApi.getImage(
        `api/architectureToRoRegister/byPath/${companyID}/${site}/drawings/${filename}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );
      console.log("Image fot APPROVED", response);

      processFile(response, "view", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const handleEdit = (drawingId) => {
    const selected = drawingList.find((drawing) => drawing._id === drawingId);
    if (selected) {
      const combinedRevisions = [
        // ...selected.acceptedArchitectRevisions,
        ...(selected.acceptedRORevisions || []),
      ];

      setSelectedDrawing(selected);
      setFormData({
        registerId: selected._id,
        drawingNumber: selected.drawingNo || "",
        drawingName: selected.drawingTitle || "",
        category: selected.category.category || "", // Ensure the category field is properly accessed
        designDrawingConsultant: `${selected.designDrawingConsultant?.role}`,
        acceptedArchitectDate: selected.acceptedArchitectDate
          ? new Date(selected.acceptedArchitectDate).toLocaleDateString()
          : "",
        revisions: combinedRevisions,
        submittedDate: todayDate(),
        // dueDays: 0,
        // issuedSoftCopy: selected.issuedSoftCopy,
        // receivedSoftCopy: selected.receivedSoftCopy,
        // drawingFileName: selected.drawingFileName
      });
      setOpen(true);
    }
  };
  // const handleEditOpen = (drawingDatas) =>{
  //   const drawingItem = drawingTableList.find((item) => item._id === drawingDatas);
  //   if (drawingItem) {
  //     const combinedRevisions = drawingItem.acceptedSiteRevisions.flatMap(
  //       (rev) => rev.revision
  //     );

  //   console.log(drawingDatas,"drawingDatas")
  //   setEditData({
  //     drawingId: drawingDatas,
  //   drawingNumber: drawingItem.drawingNo,
  //   expectedDate: "",
  //   requestedDate: todayDate(),
  //   drawingFileName: null,
  //   revision: combinedRevisions,

  //   natureofrequestedInfo: "",
  //   })

  //   setOpen(false)
  //   setEditDialogOpen(true)
  //   setViewDialogOpen(false)
  // }
  // }
  const handleEditOpen = (drawingDatas) => {
    console.log(drawingDatas, "drawingDatas onclick");
    const drawingItem = drawingTableList.find(
      (item) => item.drawingId === drawingDatas.drawingId
    );
    if (drawingItem) {
      // Check if acceptedSiteRevisions is defined and is an array
      const combinedRevisions = Array.isArray(
        drawingItem.acceptedArchitectRevisions
      )
        ? drawingItem.acceptedArchitectRevisions.flatMap((rev) => rev.revision)
        : [];

      console.log(drawingDatas, "drawingDatas");
      console.log(drawingItem, "drawingDatas");
      setEditData({
        drawingId: drawingDatas.drawingId,
        drawingNumber: drawingItem.drawingNo,
        expectedDate: "",
        requestedDate: todayDate(),
        drawingFileName: null,
        revision: combinedRevisions,
        natureofrequestedInfo: "",
      });

      setOpen(false);
      setEditDialogOpen(true);
      setViewDialogOpen(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      const file = files[0];
      setFormData({
        ...formData,
        [name]: files[0], // Assuming you are only uploading one file
      });
      return;
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleViewOpen = (drawingItem) => {
    if (drawingItem.acceptedArchitectRevisions?.length > 0) {
      setSelectedTab(drawingItem.acceptedArchitectRevisions?.length - 1);
    }
    
    setViewDialogOpen(true);
    setOpen(false);
    setEditDialogOpen(false);
    setSingleDrawingData(drawingItem);
  };

  // const handleTabChange = (event, newValue) => {
  //   setSelectedTab(newValue);
  // };

  // const handleViewOpen = (drawingItem) => {
  //   setSingleDrawingData(drawingItem);
  //   if (drawingItem.acceptedROHardCopyRevisions.length > 0) {
  //     setSelectedTab(drawingItem.acceptedROHardCopyRevisions.length - 1);
  //   }
  //   setViewDialogOpen(true);
  // };

  // const handleViewOpen = (drawingItem) => {
  //   setSingleDrawingData(drawingItem);
  //   setViewDialogOpen(true);
  // };

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const fetchInData = async () => {
    if (registerInfo && registerInfo.length > 0) {
      setDrawingList(registerInfo);
      setInitialDrawingList(registerInfo);
      console.log("fetched from recoil", registerInfo);
    } else {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setDrawingList(response.registers);
          setInitialDrawingList(response.registers);
        }
      });
    }
  };

  const fetchData = async () => {
    await updateRegister(setRegisterInfo, site).then((response) => {
      if (response.status === SUCESS_STATUS) {
        setDrawingList(response.registers);
        setInitialDrawingList(response.registers);
      }
    });
  };

  useEffect(() => {
    fetchInData();
  }, []);

  useEffect(() => {
    fetchData();
    setReloadData(false);
  }, [reloadData === true]);

  const handleDownload = async (id, revision) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/RoHardCopy/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewFile = async (id, revision) => {
    setApiProcessing({
      loader: true,
      message: "opening...",
    });
    try {
      // Make the request to get the response based on the updated endpoint
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/revisionRo/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "view", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  // const filteredDrawingList = drawingTableList
  //   .filter(
  //     (item) =>
  //       (filterCondition === "approved"
  //         ? item.acceptedROHardCopyRevisions.length > 0
  //         : item.acceptedROHardCopyRevisions.length === 0) &&
  //       (item.drawingNo?.toLowerCase().includes(searchValue.toLowerCase()) ||
  //         item.drawingTitle
  //           ?.toLowerCase()
  //           .includes(searchValue.toLowerCase()) ||
  //         item.designDrawingConsultant?.role
  //           ?.toLowerCase()
  //           .includes(searchValue.toLowerCase()) ||
  //         (item.category && typeof item.category === "string"
  //           ? item.category.toLowerCase().includes(searchValue.toLowerCase())
  //           : false))
  //     // (item.acceptedROHardCopyRevisions[0]?.hardCopySubmittedDate && typeof item.acceptedROHardCopyRevisions[0].hardCopySubmittedDate === 'string' ? item.acceptedROHardCopyRevisions[0].hardCopySubmittedDate.toLowerCase().includes(searchValue.toLowerCase()) : false) ||
  //     // (item.acceptedROHardCopyRevisions[0]?.receivedCopies && typeof item.acceptedROHardCopyRevisions[0].receivedCopies === 'string' ? item.acceptedROHardCopyRevisions[0].receivedCopies.toLowerCase().includes(searchValue.toLowerCase()) : false) ||
  //     // (item.acceptedROHardCopyRevisions[0]?.revision && typeof item.acceptedROHardCopyRevisions[0].revision === 'string' ? item.acceptedROHardCopyRevisions[0].revision.toLowerCase().includes(searchValue.toLowerCase()) : false)
  //   )
  //   .sort((a, b) => {
  //     const consultantA = a.category?.category
  //       ? a.category?.category.toLowerCase()
  //       : "";
  //     const consultantB = b.category?.category
  //       ? b.category?.category.toLowerCase()
  //       : "";
  //     return consultantA.localeCompare(consultantB); // Sort alphabetically
  //   });

  useEffect(() => {
    const fetchDrawingTableData = async (site) => {
      try {
        const response = await webApi.get(
          `api/architect/drawingRo/${site}?filterType=issued`
        );
        if (response.status === 200) {
          setDrawingTableList(response.data.data);
        }
        console.log(response, "RO tabs tableData");
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchDrawingTableData(site);
  }, [site]);

  useEffect(() => {
    console.log(drawingTableList, "drawingTableList");
  }, [drawingTableList]);

  const handleEditSubmit = async (e) => {
    console.log("rfi data",editData)
    e.preventDefault();

    let formErrors = {};
    if (!editData.drawingNumber)
      formErrors.drawingNumber = "Drawing Number is required";
    if (!editData.expectedDate)
      formErrors.expectedDate = "Expected Date is required";

    if (!editData.drawingFileName) {
      formErrors.drawingFileName = "Drawing File is required";
    }
    if (!editData.natureofrequestedInfo)
      formErrors.natureofrequestedInfo = "this field is requires";
    // if (!editData.revision) formErrors.revision = 'Please select a revision.';
    requestReason.forEach((reason, index) => {
      if (!reason.NOR) {
        formErrors[`NOR_${index}`] = "Nature of Request is required.";
      }
      if (!reason.reason) {
        formErrors[`reason_${index}`] = "Reason is required.";
      }
    });

    setError(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return;
    }

    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });
    try {
      const editFormData = {
        drawingId: editData.drawingId,
        drawingNo: editData.drawingNumber,
        siteId: site,
        requestedDate: todayDate(),
        expectedDate: editData.expectedDate,
        revision: selectedRevision,
        natureOfRequestedInformation: editData.natureofrequestedInfo,
        natureOfRequestedInformationReasons: editData.natureofrequestedInfo.map(
          (info) => ({
            natureOfRequest: info,
            reason: requestReason
              .filter((req) => req.NOR === info)
              .map((req) => req.reason),
          })
        ),
      };

      const response = await webApi.post(
        "api/architectureToRoRequested/postRequest",
        editFormData
      );

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.status === "error") {
          // Handle error from response data
          setErrorMessage(responseData.message);
          console.log("Response data:", responseData.message);
          setApiProcessing({ loader: false, message: "" });
          return; // Stop further processing
        }

        const id = response.data.data._id;

        if (editData.drawingFileName) {
          const drawingFileName = new FormData();
          drawingFileName.append("drawingFileName", editData.drawingFileName);
          const uploadResponse = await webApi.imagePut(
            `api/architectureToRoRequested/${id}`,
            drawingFileName
          );
          console.log("Upload response:", uploadResponse);
        }
        setApiProcessing({ loader: false, message: "" });
        handleEditClose();
        fetchData();
      } else if (response.status === 400) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
          console.log("Response data:", response.data.message);
        }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Failed to submit data.");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setEditData({
        ...editData,
        [name]: files[0], // Assuming you are only uploading one file
      });
    } else {
      setEditData({
        ...editData,
        [name]: value,
      });
    }
  };

  const handleRevisionSelection = (event) => {
    setSelectedRevision(event.target.value);
  };
  const handleAddReason = () => {
    setRequestReason([...requestReason, { NOR: "", reason: "" }]);
  };

  const handleRemoveReason = (indexToRemove) => {
    const updatedReasons = requestReason.filter(
      (_, index) => index !== indexToRemove
    );
    setRequestReason(updatedReasons);
  };

  const handleEditClose = () => {
    setOpen(false);
    setEditDialogOpen(false);
    setEditData({
      drawingId: "",
      drawingNumber: "",
      expectedDate: "",
      remarks: "",
      drawingFileName: null,
      revision: [],
      natureofrequestedInfo: "",
    });
    setError({});
  };
  const handleReasonChange = (e, index) => {
    console.log("jksdnfks");
    const { name, value } = e.target;
    const updatedReasons = [...requestReason];

    // Update the specific reason or NOR based on name
    updatedReasons[index] = {
      ...updatedReasons[index],
      [name]: value,
    };

    // Set the updated requestReason state
    setRequestReason(updatedReasons);
  };

  return (
    <div>
      <DrawingTableWrapper
        tableHead={
          <tr>
            <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
            <th style={{ width: "10%" }}>DRWG.No</th>
            <th style={{ width: "10%" }}>Consultant</th>
            <th style={{ width: "8%" }}>Folder</th>
            <th style={{ width: "10%" }}>Drawing Category</th>
            <th style={{ width: "10%" }}>Drawing Title</th>
            <th style={{ width: "13%" }}>Architect soft Submission Date</th>
            <th style={{ width: "10%" }}>Revision</th>
            <th style={{ width: "6%" }}>Action</th>
          </tr>
        }
        tableBody={
          <tbody>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            {drawingTableList.length ? (
              drawingTableList
                .filter((item) => item.acceptedArchitectRevisions.length > 0)
                .sort((a, b) => {
                  const consultantA = a.category?.category
                    ? a.category?.category.toLowerCase()
                    : "";
                  const consultantB = b.category?.category
                    ? b.category?.category.toLowerCase()
                    : "";
                  return consultantA.localeCompare(consultantB);
                })
                .map((drawingItem, index) => (
                  <tr
                    key={index}
                    style={{
                      height: "60px",
                      backgroundColor:
                        index % 2 === 0
                          ? "var(--table-oddrow-color)"
                          : "var(--table-evenrow-color)",
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{drawingItem?.drawingNo || ""}</td>
                    <td>{drawingItem.designDrawingConsultant?.role || ""}</td>
                    <td>{drawingItem.folderId?.folderName || ""}</td>
                    <td>{drawingItem.category?.category || ""}</td>

                    <td
                      style={{
                        width: "9%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        cursor: "default",
                      }}
                    >
                      <span
                        title={drawingItem.drawingTitle}
                        style={{
                          display: "inline-block",
                          maxWidth: "100%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {drawingItem.drawingTitle
                          .split(" ")
                          .slice(0, 2)
                          .join(" ")}
                        ...
                      </span>
                    </td>
                    <td>
                      {drawingItem.acceptedArchitectRevisions.length > 0
                        ? formatDate(
                            drawingItem.acceptedArchitectRevisions[0]
                              ?.softCopySubmittedDate
                          ) || "N/A"
                        : "N/A"}
                    </td>

                    <td>
                      {drawingItem.acceptedArchitectRevisions.length > 0 ? (
                        <div>
                          {drawingItem.acceptedArchitectRevisions &&
                            drawingItem.acceptedArchitectRevisions
                              .slice(-2)
                              .map(
                                (revisionItem) =>
                                  `${revisionItem.revision}` || "NA"
                              )
                              .join(", ")}
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </td>

                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "18px",
                      }}
                    >
                      <>{rfiRaisedAccess &&
                        <Icon.Pen
                          size={20}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() => handleEditOpen(drawingItem)}
                        />
                      }
                        <Icon.EyeFill
                          size={20}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() => handleViewOpen(drawingItem)}
                        />
                        <Icon.Download
                          size={20}
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={() => {
                            const latestRevision =
                              drawingItem.acceptedArchitectRevisions.length > 0
                                ? drawingItem.acceptedArchitectRevisions[
                                    drawingItem.acceptedArchitectRevisions
                                      .length - 1
                                  ].revision
                                : null;
                            handleDownload(
                              drawingItem.drawingId,
                              latestRevision
                            );
                          }}
                        />
                      </>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="8" style={{ textAlign: "center" }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        }
      />

      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 10,
            fontFamily: "Poppins",
            background: "#FFF",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {singleDrawingData?.acceptedArchitectRevisions?.length > 0 ? (
              <Tabs1 value={selectedTab} onChange={handleTabChange}>
                {singleDrawingData.acceptedArchitectRevisions.map(
                  (revisionItem, index) => (
                    <Tab1
                      key={index}
                      value={index}
                      label={`${revisionItem.revision}`}
                      style={{
                        backgroundColor:
                          singleDrawingData.acceptedArchitectRevisions
                            .length === 1 || selectedTab === index
                            ? "var(--primary-color)"
                            : "transparent",
                        color:
                          singleDrawingData.acceptedArchitectRevisions
                            .length === 1 || selectedTab === index
                            ? "white"
                            : "var(--primary-color)",
                        width: "150px",
                        height: "35px",
                        cursor: "pointer",
                        borderRight:
                          index <
                          singleDrawingData.acceptedArchitectRevisions.length -
                            1
                            ? "1px solid var(--primary-color)"
                            : "none",
                        // borderRadius: index === 0 && !isSingleRevision ? '5px 0 0 5px' : (index === singleDrawingData.acceptedRORevisions.length - 1 && !isSingleRevision ? '0 5px 5px 0' : 'none'),
                      }}
                    />
                  )
                )}
              </Tabs1>
            ) : (
              <div>No Revisions</div>
            )}
          </div>
        </div>
        {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {selectedTab ===
                  singleDrawingData.acceptedArchitectRevisions.length - 1 ? (
                    <div style={{ color: "green" }}>Good For Construction</div>
                  ) : (
                    <div style={{ color: "red" }}>Superseeded</div>
                  )}
                </div>
              )}
            </div>
          </>
        )}

        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",

                justifyContent: "center",
                marginLeft: "80px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.designDrawingConsultant?.role || "NA"}
              </div>

              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>

              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>
              <div>Folder</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.folderId?.folderName || "-"}
              </div>
              <div>Submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-130px" }}>
                {singleDrawingData?.acceptedSiteSubmissionDate
                  ? new Date(singleDrawingData.acceptedSiteSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              
              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
            <>
              {singleDrawingData.acceptedArchitectRevisions[selectedTab] && (
                <>
                  <div>Soft Copy Submitted Date</div>
                  <div>:</div>
                  <div style={{ marginLeft: "-120px" }}>
                    {singleDrawingData.acceptedArchitectRevisions[selectedTab]
                      .softCopySubmittedDate
                      ? formatDate(
                          singleDrawingData.acceptedArchitectRevisions[
                            selectedTab
                          ].softCopySubmittedDate
                        )
                      : "No Submitted Date"}
                  </div>
                </>
              )}
            </>
          )}
   {  forwardAccess &&
      singleDrawingData?.acceptedArchitectRevisions?.length > 0 &&
      (selectedTab === singleDrawingData.acceptedArchitectRevisions.length - 1) && (
    <>
        <div>Issued Soft Copy</div>
        <div>:</div>
        <div style={{ marginLeft: "-130px" }}>
          <button
            style={{
              display: "flex",
              backgroundColor: "#F3F3F3",
              border: "1px solid #97979740",
              color: "#4d4d4d",
              padding: "6px 10px",
              alignItems: "center",
              gap: "10px",
            }}
            onClick={()=>handleForwardClick(singleDrawingData,singleDrawingData.acceptedArchitectRevisions?.length - 1)}
          >
            Forwarded <RiShareForwardLine />
          </button>
        </div>
        </>
   )}

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[
                    selectedTab
                  ] && (
                    <> 
                      <div>Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-130px" }}>
                        {singleDrawingData.acceptedArchitectRevisions[
                          selectedTab
                        ].revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  <div>Drawing File</div>
                  <div>:</div>
                  <div style={{ display: "flex", marginLeft: "-130px" }}>
                    <Icon.Image
                      size={20}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() =>
                        handleViewFile(
                          singleDrawingData.drawingId,
                          singleDrawingData.acceptedArchitectRevisions[0]
                            ?.revision
                        )
                      }
                    />
                    <Icon.Download
                      size={20}
                      style={{ marginRight: "5px", cursor: "pointer" }}
                      onClick={() =>
                        handleDownload(
                          singleDrawingData.drawingId,
                          singleDrawingData.acceptedArchitectRevisions[0]
                            ?.revision
                        )
                      }
                    />
                  </div>
                </>
              )}

{singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[selectedTab] && (
                    <>
                      <div>PDF File</div>
                      <div>:</div>
                      <div style={{ display: "flex", marginLeft: "-120px" }}>
                        <Icon.Image
                          size={20}
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewPDFFile(
                              singleDrawingData.acceptedArchitectRevisions[selectedTab]
                                ?.pdfDrawingFileName
                            )
                          }
                        />
                        <Icon.Download
                          size={20}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handlePDFDownload(
                              singleDrawingData.acceptedArchitectRevisionss[selectedTab]
                                ?.pdfDrawingFileName
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>

      <Dialog
        open={editDialogOpen}
        onClose={handleEditClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 350px", textAlign: "center", color: "white" }}
          >
            RFI
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleEditClose}
          />
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <form onSubmit={handleEditSubmit}>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="drawingNumber"
                    className="fs-10  text-start py-2"
                  >
                    Drawing Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="drawingNumber"
                    placeholder="Enter your Drawing Number"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    value={editData.drawingNumber}
                    onChange={handleInputChange}
                    readOnly
                  />
                  {error.drawingNumber && (
                    <div className="text-danger">{error.drawingNumber}</div>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="requestedDate"
                    className="fs-10  text-start py-2"
                  >
                    Requested Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="expectedDate"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    value={editData.requestedDate}
                    onChange={handleInputChange}
                    readOnly
                  />
                  {error.requestedDate && (
                    <div className="text-danger">{error.requestedDate}</div>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="expectedDate"
                    className="fs-10  text-start py-2"
                  >
                    Expected Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    name="expectedDate"
                    className="form-control fs-12"
                    style={{ width: "100%", height: "45px" }}
                    value={editData.expectedDate}
                    onChange={handleInputChange}
                    min={tomorrowDateString}
                  />
                  {error.expectedDate && (
                    <div className="text-danger">{error.expectedDate}</div>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <label htmlFor="revision" className="fs-10  text-start py-2">
                    Revisions
                    {/* <span style={{ color: 'red' }}>*</span> */}
                  </label>
                  <SearchDropdownIdVal
                    placeholder={"Select Revision"}
                    backgroundColor={"var(--table-evenrow-color)"}
                    // Check if `editData.revision` is an array and get the latest revision
                    options={
                      Array.isArray(editData.revision) &&
                      editData.revision.length > 0
                        ? [
                            {
                              name: editData.revision.slice(-1)[0], // Get the latest revision
                              value: editData.revision.slice(-1)[0], // Use the latest revision for value
                            },
                          ]
                        : []
                    }
                    onOptionSelect={(option) =>
                      handleRevisionSelection({
                        target: {
                          name: "selectedRevision",
                          value: option.value,
                        },
                      })
                    }
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />

                  {error.revision && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {error.revision}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <MultiSelectSearch
                    mandatory={true}
                    heading={"Nature of Requested Information"}
                    listItems={listItems}
                    selectedIds={editData.natureofrequestedInfo || []} // Ensure this is an array
                    setSelectedIds={(ids) =>
                      setEditData({ ...editData, natureofrequestedInfo: ids })
                    }
                    handleSelectedPropertyOption={(data) => {
                      if (editData.natureofrequestedInfo.includes(data.id)) {
                        setEditData({
                          ...editData,
                          natureofrequestedInfo:
                            editData.natureofrequestedInfo.filter(
                              (id) => id !== data.id
                            ),
                        });
                      } else {
                        setEditData({
                          ...editData,
                          natureofrequestedInfo: [
                            ...editData.natureofrequestedInfo,
                            data.id,
                          ],
                        });
                      }
                    }}
                    clearAll={() =>
                      setEditData({ ...editData, natureofrequestedInfo: [] })
                    }
                    addAll={() =>
                      setEditData({
                        ...editData,
                        natureofrequestedInfo: listItems.map((item) => item.id),
                      })
                    }
                  />
                  {error.natureofrequestedInfo && (
                    <div className="text-danger">
                      {error.natureofrequestedInfo}
                    </div>
                  )}

                  {/* Display selected fields */}
                  <div style={{ marginTop: "20px" }}>
                    <p className="fs-10  text-start py-2">Selected Fields:</p>
                    <ul>
                      {editData.natureofrequestedInfo.length > 0 ? (
                        editData.natureofrequestedInfo.map((id) => (
                          <li key={id}>{getNameFromId(id)}</li>
                        ))
                      ) : (
                        <li>No fields selected</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="remarks"
                    className="fs-10  text-start py-2"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Request Reason *
                    <span style={{ display: "flex", alignItems: "center" }}>
                      &nbsp; &nbsp; &nbsp;{" "}
                      <HiPlusCircle
                        style={{
                          marginLeft: "20px",
                          color: "#e47331",
                          fontSize: "24px",
                        }}
                        onClick={handleAddReason}
                      />
                    </span>
                  </label>
                  {requestReason.map((reqReason, reqIndex) => (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        marginTop: "30px",
                      }}
                    >
                      <select
                        style={{ width: "15%", marginRight: "20px" }}
                        name="NOR"
                        value={reqReason.NOR}
                        onChange={(e) => handleReasonChange(e, reqIndex)}
                      >
                        <option vlaue="">Select</option>
                        {editData.natureofrequestedInfo.length &&
                          editData.natureofrequestedInfo.map(
                            (requestInfo, index) => (
                              <option key={index} value={requestInfo}>
                                {requestInfo}{" "}
                              </option>
                            )
                          )}
                      </select>

                      <input
                        type="text"
                        style={{ width: "82%" }}
                        name="reason"
                        value={reqReason.reason}
                        onChange={(e) => handleReasonChange(e, reqIndex)}
                      />
                      {reqIndex > 0 ? (
                        <FaCircleMinus
                          style={{
                            fontSize: "24px",
                            position: "absolute",
                            right: "-21px",
                          }}
                          onClick={() => handleRemoveReason(reqIndex)}
                        />
                      ) : (
                        ""
                      )}
                      {error[`NOR_${reqIndex}`] ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            top: "50px",
                          }}
                        >
                          {error[`NOR_${reqIndex}`]}
                        </p>
                      ) : error[`reason_${reqIndex}`] ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            top: "50px",
                            right: "50px",
                          }}
                        >
                          {error[`reason_${reqIndex}`]}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <label
                  htmlFor="drawingFileName"
                  className="fs-10  text-start py-2"
                >
                  Upload Your File <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="file"
                  accept=".dwg"
                  id="drawingFileName"
                  name="drawingFileName"
                  className="form-control fs-12"
                  style={{ width: "100%", height: "43px" }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file && file.name.endsWith(".dwg")) {
                      handleInputChange(e);
                    } else {
                      alert("Please select a .dwg file.");
                      e.target.value = null; // Reset the file input if the file is not valid
                    }
                  }}
                />

                {error.drawingFileName && (
                  <div className="text-danger">{error.drawingFileName}</div>
                )}
              </div>
              {errorMessage && (
                <div style={{ color: "red", fontSize: "15px" }}>
                  {errorMessage}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  type="submit"
                  style={{
                    background: "blue",
                    color: "white",
                    width: "100px",
                    height: "35px",
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 20, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            Drawing
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "15px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {/* <button
            onClick={() => {
              setCurrentTab('upload')
            }}
            style={{
              backgroundColor: currentTab === 'upload' ? 'var(--primary-color)': 'transparent',
              color: currentTab === 'upload' ? 'white' : 'var(--primary-color)',
              width: '150px',
              height: '35px',
              cursor: 'pointer',
              borderRight: '1px solid var(--primary-color)', 
             
            }}
          >
            Upload
          </button> */}

            <button
              onClick={() => setCurrentTab("received")}
              style={{
                background:
                  currentTab === "received"
                    ? "var(--primary-color)"
                    : "transparent",
                color:
                  currentTab === "received" ? "white" : "var(--primary-color)",
                width: "150px",
                height: "35px",
                cursor: "pointer",
              }}
            >
              Received
            </button>
          </div>
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <section
              style={{
                marginLeft: "80px",
                marginRight: "80px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "50px",
                  width: "80%",
                }}
              >
                <div>
                  <label
                    htmlFor="drawingNumber"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing No
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="drawingNumber"
                    name="drawingNumber"
                    value={formData.drawingNumber}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />

                  {errors.drawingNumber && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingNumber}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="drawingName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Title
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="drawingName"
                    name="drawingName"
                    value={formData.drawingName}
                    onChange={handleChange}
                    placeholder="Enter your drawing title"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.drawingName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingName}
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="category"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Category
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    placeholder="Enter your Drawing Category"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.category && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.category}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="designDrawingConsultant"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Design Consultant
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="designDrawingConsultant"
                    name="designDrawingConsultant"
                    value={formData.designDrawingConsultant}
                    onChange={handleChange}
                    placeholder="Enter your  Design Consultant"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    readOnly
                  />
                  {errors.designDrawingConsultant && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.designDrawingConsultant}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="revisions"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Revisions
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    id="revisions"
                    name="selectedRevision"
                    value={formData.selectedRevision}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  >
                    <option value="">Select Revision</option>
                    {formData.revisions.map((revision, index) => (
                      <option key={index} value={revision.revision}>
                        {revision.revision}
                      </option>
                    ))}
                  </select>
                  {errors.selectedRevision && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.selectedRevision}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="receivedCopies"
                    className="fs-10 fw-bold text-start py-2
              "
                  >
                    No of Copies Received
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    id="receivedCopies"
                    name="receivedCopies"
                    placeholder="Enter No of Received Copies"
                    value={formData.receivedCopies}
                    onChange={handleChange}
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />
                </div>

                <div>
                  <label
                    htmlFor="drawingFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Upload Your Drawing File
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".pdf, .jpg, .jpeg, .png"
                    id="drawingFileName"
                    name="drawingFileName"
                    onChange={handleChange}
                    placeholder="Select your drawing file"
                    className="form-control fs-12"
                    style={{ width: "100%", height: "43px" }}
                  />
                  {errors.drawingFileName && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {errors.drawingFileName}
                    </p>
                  )}
                </div>
              </div>
            </section>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "45px",
              }}
            >
              <button
                style={{
                  padding: "10px",

                  border: "1px solid lightgray",
                  backgroundColor: "transparent",
                  width: "100px",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "lightgray")
                }
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = "transparent")
                }
                onClose={handleClose}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                style={{
                  marginLeft: "50px",
                  padding: "10px",

                  border: "1px solid var(--primary-color)",
                  width: "100px",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
                onClick={handleFormSubmit}
              >
                Submit
              </button>
            </div>
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default IssuedTable;
