import React, { useState } from "react";
import { useTheme } from "../../../ThemeContext";
import "./Theam.css";

const Theme = () => {
  const { changeTheme } = useTheme();
  const [selectedColor, setSelectedColor] = useState("");

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleApplyTheme = () => {
    if (selectedColor) {
      changeTheme(selectedColor);
    }
  };

  return (
    <div className="container py-4">
      <div className="themeHeadingContainer">
        <p className="theme-title">Theme</p>
        <p className="theme-Desc">Choose a color to apply a Theme</p>
      </div>
      <div className="color-options">
        {[
          "black",
          "green",
          "orange",
          "white",
          "navy",
          "red",
          "blue",
          "purple",
          "pink",
          "yellow",
        ].map((color) => (
          <div key={color} className="color-box">
            <div className="boxColor" style={{ border: "red" }}>
              <button
                style={{
                  border: color,
                  color: color === "black" ? "white" : "black",
                }}
                onClick={() => handleColorChange(color)}
              >
                {color.charAt(0).toUpperCase() + color.slice(1)}
              </button>
            </div>
          </div>
        ))}
      </div>
      <button
        className="apply-button"
        onClick={handleApplyTheme}
        disabled={!selectedColor} // Disable button if no color is selected
      >
        Apply
      </button>
    </div>
  );
};

export default Theme;
