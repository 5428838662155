import React from 'react'

const FolderDropdown = (props) => {

    const { folderId, handleFolderChange, folders, defaultValue, hideDefault } = props
  
    return (
      <select
          id="folderName"
          name="folderName"
          value={folderId}
          onChange={handleFolderChange}
          style={{ marginLeft: '10px', width: '150px' , backgroundColor:'transparent',  border:  'none'}}
      >
          { !hideDefault && <option value="">{defaultValue ?? "All Folders"}</option> }
          { folders && folders.map(folder => (
          <option key={folder._id} value={folder._id}>{folder.folderName}</option>
          ))}
      </select>
    )
  }

export default FolderDropdown