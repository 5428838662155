import React from "react";

const SubmitButton = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "45px",
      }}
    >
      <button
        type="submit"
        style={{
          width: "150px",
          height: "50px",
          marginLeft: "50px",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid var(--primary-color)",
          backgroundColor: "var(--primary-color)",
          color: "white",
        }}
      >
        SUBMIT
      </button>
    </div>
  );
};

export default SubmitButton;
