import React from 'react'

const FacebookIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25C19.4036 25 25 19.4036 25 12.5Z" fill="#1877F2"/>
      <path d="M15.4255 13.2842L15.7743 10.8664H13.5922V9.29804C13.5922 8.63657 13.8971 7.9911 14.8744 7.9911H15.8665V5.93265C15.8665 5.93265 14.9661 5.76929 14.1053 5.76929C12.3081 5.76929 11.1334 6.92724 11.1334 9.02359V10.8664H9.13574V13.2842H11.1334V19.1292C11.948 19.2647 12.7776 19.2647 13.5922 19.1292V13.2842H15.4255Z" fill="white"/>
    </svg>
  )
}

export default FacebookIcon