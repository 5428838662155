import React, { useState, useEffect } from "react";
import ToggleSwitch from "../Sites/ToggleSwitch";
import "./users.css";
import { WebApimanager } from "../../WebApiManager";

const subModuleDetails = {
  architectureToRoDetails: {
    analysis: "Analysis",
    drawing: "Drawing",
    pending: "Pending",
    register: "Register",
    rfi: "RFI",
  },
  roDetails: {
    analysis: "Analysis",
    drawing: "Drawing",
    pending: "Pending",
    register: "Register",
    rfi: "RFI",
    forwardAccess: "Forward Access",
    rfiRaisedAccess: "RFI Raised Access",
  },
  optionsDetails: {
    addRegister: "Add Register",
    assignCategory: "Assign Category",
    categoryRegister: "Category Register",
    workFlow: "WorkFlow",
  },
  siteHeadDetails: {
    drawing: "Drawing",
    rfi: "RFI",
    pending: "Pending",
    register: "Register",
    analysis: "Analysis",
    forwardAccess: "Forward Access",
    rfiRaisedAccess: "RFI Raised Access",
  },
  siteToSiteDetails: {
    drawing: "Drawing",
    pending: "Pending",
    register: "Register",
  },
  employee: {},
  profile: {},
  organizationChart: {},
};

const SubModuleModal = ({
  show,
  onHide,
  subModuleKey,
  pageType,
  selectedSiteId,
  selectedUser,
  setSelectedUser,
  user,
}) => {
  const [modulesState, setModulesState] = useState(selectedUser?.modules || {});
  const webApi = new WebApimanager();
  const userId = user?._id;
  const subKey = subModuleKey + "Details";

  useEffect(() => {
    console.log(`Loaded subModuleKey: ${subModuleKey}`);
  }, [subModuleKey]);

  const handleToggle = (detailKey) => {
    setModulesState((prevState) => {
      const updatedModules = { ...prevState };

      const currentSubKeyState = updatedModules[pageType]?.[subKey] || {};

      updatedModules[pageType] = {
        ...updatedModules[pageType],
        [subKey]: {
          ...currentSubKeyState,
          [detailKey]: !currentSubKeyState[detailKey],
        },
      };

      return updatedModules;
    });
  };

  const handleSave = async () => {
    try {
      const response = await webApi.put(`api/users/updateDetails/${userId}`, {
        modules: modulesState,
        siteId: selectedSiteId,
      });
      console.log("Modules updated successfully:", response);
      setSelectedUser((prevUser) => ({
        ...prevUser,
        modules: modulesState,
      }));
      onHide();
    } catch (error) {
      console.error("Error updating modules:", error);
    }
  };

  const details = subModuleDetails[subKey];
  const modules = modulesState[pageType] || {};

  return (
    show && (
      <div className="modal-container">
        <div className="submoduleContent">
          <div className="modal-header useModuleModal">
            <p>{`${subModuleKey} Details`}</p>
            <span className="toggleBtn" onClick={onHide}>
              &times;
            </span>
          </div>

          <div
            className="modalBody"
            style={{ maxHeight: "430px", overflowY: "auto" }}
          >
            {details ? (
              Object.entries(details).map(([detailKey, detailLabel], index) => {
                const isDrawingActive = modules[subKey]?.drawing;
                const isRfiActive = modules[subKey]?.rfi;

                const isForwardAccessDisabled = !isDrawingActive;
                const isRfiRaisedAccessDisabled = !isRfiActive;

                if (detailKey === "forwardAccess" && isForwardAccessDisabled) {
                  return null;
                }
                if (
                  detailKey === "rfiRaisedAccess" &&
                  isRfiRaisedAccessDisabled
                ) {
                  return null;
                }

                return (
                  <div
                    key={detailKey}
                    className={`toggle-wrapper toggle-${index}`}
                    style={{ gap: "10px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "50px",
                      }}
                    >
                      <label className="subModuleLableName">
                        {detailLabel}
                      </label>
                      <ToggleSwitch
                        isChecked={modules[subKey]?.[detailKey]}
                        onToggle={() => handleToggle(detailKey)}
                        disabled={
                          detailKey === "forwardAccess"
                            ? isForwardAccessDisabled
                            : isRfiRaisedAccessDisabled
                        }
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No details available for this submodule.</p>
            )}
          </div>

          <div className="modal-footer FooterBtnUser">
            <button className="cancleBtnUsers" onClick={onHide}>
              CANCEL
            </button>
            <button className="saveBtnUsers" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default SubModuleModal;
