/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { Loader } from "../../Widgets/notificationFeedbacks";
import "./Dialog.module.css";
import "./sites.css";
import AddSiteDialog from "./AddSiteDialog";

const ViewSiteDialog = (props) => {
  const { open, onClose, companyData } = props;
  console.log(props, "view props sire");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const handleClose = () => {
    onClose();
  };

  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const handleEditClick = (companyData) => {
    setSelectedCompanyData(companyData);
    setShowAddCompanyModal(true);
  };

  const handleAddCompanyClose = () => {
    setShowAddCompanyModal(false);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: "white",
              width: "100%",
              margin: "0px",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            View Site Details
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "10px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <Loader open={apiProcessing.loader} message={apiProcessing.message} />
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              justifyItems: "center",
              margin: "0 80px",
            }}
          >
            <>
              <div style={{ marginLeft: "10px", marginRight: "80px" }}>
                <div style={{ paddingBottom: "32px", marginBottom: "16px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h3>Site Details of {companyData?.siteName}</h3>
                    {/* <button style={{border:'1px solid blue' , backgroundColor:'blue', color:'white',padding:'5px',width:'80px',borderRadius:'2px'}}
            onClick={() => handleEditClick(companyData)}>
                EDIT
           </button> */}
                  </div>
                  <div
                    style={{
                      marginTop: "16px",
                      borderBottom: "1px dotted lightgray",
                    }}
                  >
                    {[
                      {
                        label: "Venture Type",
                        value: companyData?.ventureType,
                      },
                      {
                        label: "City or State",
                        value: companyData?.cityOrState,
                      },
                      { label: "Country", value: companyData?.country },
                      { label: "Pin Code", value: companyData?.pinCode },
                      {
                        label: "No Of Checklists",
                        value: companyData?.fetureDetails,
                      },
                      {
                        label: "Site Address",
                        value: companyData?.siteAddress,
                      },
                      {
                        label: "Latitude",
                        value: companyData?.siteLocationDetails?.latitude,
                      },
                      {
                        label: "Longitude",
                        value: companyData?.siteLocationDetails?.longitude,
                      },
                      {
                        label: "Radius",
                        value: companyData?.siteLocationDetails?.radius,
                      },
                    ].map((field, index) => (
                      <div
                        key={index}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          gap: "20px",
                          marginBottom: "16px",
                        }}
                      >
                        <span style={{ fontWeight: "600" }}>{field.label}</span>
                        <span>:</span>
                        <span>{field.value || "N/A"}</span>
                      </div>
                    ))}
                  </div>
                  {companyData?.apartmentsDetails && (
                    <div
                      style={{
                        marginTop: "16px",
                        borderBottom: "1px dotted lightgray",
                      }}
                    >
                      <h3 style={{ fontWeight: 200, marginBottom: "8px" }}>
                        Apartment Details
                      </h3>
                      {[
                        {
                          label: "Number of Towers",
                          value: companyData?.apartmentsDetails?.noOfTowers,
                        },
                        {
                          label: "Number of Basements",
                          value: companyData?.apartmentsDetails?.noOfBasements,
                        },
                        {
                          label: "Basements Are Common For Every Tower",
                          value:
                            companyData?.apartmentsDetails
                              ?.basementsAreCommonForEveryTower,
                        },
                        {
                          label: "Number of Pours for Basement",
                          value:
                            companyData?.apartmentsDetails
                              ?.noOfPouresForBasement,
                        },
                        {
                          label: "Number of Clubhouses",
                          value: companyData?.apartmentsDetails?.noOfClubHouses,
                        },
                        {
                          label: "Basements",
                          value: companyData?.apartmentsDetails?.basements,
                        },
                        {
                          label: "Amenities",
                          value:
                            companyData?.apartmentsDetails?.amenities?.join(
                              ", "
                            ),
                        },
                        {
                          label: "Clubhouse",
                          value:
                            companyData?.apartmentsDetails?.clubhouse?.length,
                        },
                      ].map((field, index) => (
                        <div
                          key={index}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "20px",
                            marginBottom: "16px",
                          }}
                        >
                          <span style={{ fontWeight: "600" }}>
                            {field.label}
                          </span>
                          <span>:</span>
                          <span>{field.value || "N/A"}</span>
                        </div>
                      ))}
                    </div>
                  )}

                  {companyData?.buildingsDetails && (
                    <div
                      style={{
                        marginTop: "16px",
                        borderBottom: "1px dotted lightgray",
                      }}
                    >
                      <h3 style={{ fontWeight: "bold", marginBottom: "8px" }}>
                        Building Details
                      </h3>
                      {[
                        {
                          label: "Amenities Available",
                          value: companyData?.buildingsDetails?.amenityAreThere,
                        },
                        {
                          label: "Number of Basements",
                          value: companyData?.buildingsDetails?.noOfBasements,
                        },
                        {
                          label: "Basements",
                          value: companyData?.buildingsDetails?.basements,
                        },
                        {
                          label: "Number of Pours for Basement",
                          value:
                            companyData?.buildingsDetails
                              ?.noOfPouresForBasement,
                        },
                        {
                          label: "Amenities",
                          value:
                            companyData?.buildingsDetails?.amenities?.join(
                              ", "
                            ),
                        },
                      ].map((field, index) => (
                        <div
                          key={index}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "20px",
                            marginBottom: "16px",
                          }}
                        >
                          <span style={{ fontWeight: "600" }}>
                            {field.label}
                          </span>
                          <span>:</span>
                          <span>{field.value || "N/A"}</span>
                        </div>
                      ))}

                      {companyData?.buildingsDetails?.towers?.floors?.map(
                        (floor, floorIndex) => (
                          <div key={floorIndex} style={{ marginTop: "16px" }}>
                            <h4
                              style={{
                                fontWeight: "bold",
                                marginBottom: "8px",
                              }}
                            >
                              {floor.name}
                            </h4>
                            <div style={{ marginBottom: "8px" }}>
                              <span style={{ fontWeight: "600" }}>
                                Number of Units
                              </span>
                              <span>:</span>
                              <span>{floor.numUnits || "N/A"}</span>
                            </div>

                            {/* Loop through units for each floor */}
                            {floor.units.map((unit, unitIndex) => (
                              <div
                                key={unitIndex}
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(3, 1fr)",
                                  gap: "20px",
                                  marginBottom: "16px",
                                }}
                              >
                                <span style={{ fontWeight: "600" }}>
                                  Unit {unit.name}
                                </span>
                                <span>:</span>
                                <span>{unit.type || "N/A"}</span>
                              </div>
                            ))}
                          </div>
                        )
                      )}
                    </div>
                  )}

                  {companyData?.villasDetails && (
                    <div>
                      <h3 style={{ fontWeight: "bold", marginBottom: "8px" }}>
                        Villa Details
                      </h3>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          gap: "20px",
                          marginBottom: "16px",
                        }}
                      >
                        {[
                          {
                            label: "Number of Villas",
                            value: companyData?.villasDetails?.noOfVillas,
                          },
                          {
                            label: "Cellar for Villas",
                            value:
                              companyData?.villasDetails
                                ?.cellarIsThereForVillas,
                          },
                          {
                            label: "Floors Common for Every Villa",
                            value:
                              companyData?.villasDetails
                                ?.floorsAreCommonForEveryVillas,
                          },
                          {
                            label: "Number of Club Houses",
                            value: companyData?.villasDetails?.noOfClubHouses,
                          },
                          {
                            label: "Amenities",
                            value:
                              companyData?.villasDetails?.amenities?.join(", "),
                          },
                        ].map((field, index) => (
                          <React.Fragment key={index}>
                            <span style={{ fontWeight: "600" }}>
                              {field.label}
                            </span>
                            <span>:</span>
                            <span>{field.value || "N/A"}</span>
                          </React.Fragment>
                        ))}
                      </div>

                      {/* Villa Type Details */}
                      {companyData?.villasDetails?.villaTypeDetails?.map(
                        (villa, villaIndex) => (
                          <div key={villaIndex} style={{ marginTop: "16px" }}>
                            <h4
                              style={{
                                fontWeight: "bold",
                                marginBottom: "8px",
                              }}
                            >
                              {villa.villaName || `Villa ${villaIndex + 1}`}
                            </h4>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(3, 1fr)",
                                gap: "20px",
                                marginBottom: "16px",
                              }}
                            >
                              <span style={{ fontWeight: "600" }}>
                                Villa Type
                              </span>
                              <span>:</span>
                              <span>{villa.villaType || "N/A"}</span>
                            </div>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(3, 1fr)",
                                gap: "20px",
                                marginBottom: "16px",
                              }}
                            >
                              <span style={{ fontWeight: "600" }}>Cellar</span>
                              <span>:</span>
                              <span>{villa.cellar || "N/A"}</span>
                            </div>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(3, 1fr)",
                                gap: "20px",
                                marginBottom: "16px",
                              }}
                            >
                              <span style={{ fontWeight: "600" }}>Floor</span>
                              <span>:</span>
                              <span>{villa.floor || "N/A"}</span>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>
          </form>
        </DialogContent>
      </Dialog>
      <AddSiteDialog
        close={handleAddCompanyClose}
        open={showAddCompanyModal}
        companyData={selectedCompanyData}
      />
    </>
  );
};
export default ViewSiteDialog;
