import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import AddDrawingDialog from "./Components/AddDrawingDialog";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { registerAtom, siteId, userInfo } from "../../../atoms";
import MultiSelectSearch from "../../../Utilities/MultiSelectSearch";
import { RxCross1 } from "react-icons/rx";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableWrapper from "../../../Utilities/TableWrapper";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import ReceivedTable from "./ReceivedTable";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import { processFile, updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";
import { Tabs, Tab } from "../../Tabs/Tabv1";
import { Tab1, Tabs1 } from "../../Tabs/Tabv2";
import { HiPlusCircle } from "react-icons/hi";
import { FaCircleMinus } from "react-icons/fa6";

import { FaFilter } from "react-icons/fa";

const ApprovedTable = () => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDateString = tomorrow.toISOString().split("T")[0];

  let webApi = new WebApimanager();

  const [open, setOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [error, setError] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [registerList, setRegisterList] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const userDetails = useRecoilState(userInfo);
  const companyID = userDetails[0]?.companyId;
  const [editData, setEditData] = useState({
    drawingId: "",
    drawingNumber: "",
    expectedDate: "",
    requestedDate: todayDate(),

    drawingFileName: null,
    revision: [],
    natureofrequestedInfo: "",
  });
  const [requestReason, setRequestReason] = useState([{ NOR: "", reason: "" }]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [site, setSite] = useRecoilState(siteId);
  const [selectedRevision, setSelectedRevision] = useState("");

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    fetchData();
  };

  const handleRevisionSelection = (event) => {
    setSelectedRevision(event.target.value);
  };

  const handleAddReason = () => {
    setRequestReason([...requestReason, { NOR: "", reason: "" }]);
  };

  const handleRemoveReason = (indexToRemove) => {
    const updatedReasons = requestReason.filter(
      (_, index) => index !== indexToRemove
    );
    setRequestReason(updatedReasons);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditData({
      drawingId: "",
      drawingNumber: "",
      expectedDate: "",
      remarks: "",
      drawingFileName: null,
      revision: [],
      natureofrequestedInfo: [],
    });
  };
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    console.log(drawingItem, "see single drawing data");
    if (drawingItem.acceptedArchitectRevisions?.length > 0) {
      setSelectedTab(drawingItem.acceptedArchitectRevisions?.length - 1);
    }
    setViewDialogOpen(true);
  };

  const handleViewClose = () => {
    setApiProcessing({ loader: false });
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  useEffect(() => {
    setReloadData(true);
  }, [drawingList]);

  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectFolder, setSelectFolder] = useState("");
  const [typeSelected, setTypeSelected] = useState("");
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const dropdownRef = useRef(null);

  const fetchData = async () => {
    try {
      let response = await webApi.get(
        `api/architect/upload/${site}?filterType=upload`
      );
      setDrawingList(response.data.data);
      console.log(drawingList, "responsedata of architect upload");
    } catch (error) {
      console.error("Error fetching checklist data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [site]);

  const handleSearchChange = useCallback((e) => {
    const inputValue = e.target.value.trim().toLowerCase();
    setSearchValue(inputValue);
  }, []);

  const filteredDrawings = useMemo(() => {
    if (!searchValue) return drawingList;

    return drawingList.filter((drawing) => {
      const matchesSearch = [
        drawing.drawingNo,
        drawing.drawingTitle,
        drawing.designDrawingConsultant?.role,
        drawing.category?.category,
      ].some((field) => field?.toLowerCase().includes(searchValue));

      const matchesCategory = selectedCategory
        ? drawing.category?.category === selectedCategory
        : true;

      const matchesFolder = selectFolder
        ? drawing.folderName?.folderName === selectFolder.toLowerCase()
        : true;

      return matchesSearch && matchesCategory && matchesFolder;
    });
  }, [searchValue, selectedCategory, selectFolder, drawingList]);

  useEffect(() => {
    console.log("Current Search Value:", searchValue);
    console.log("Filtered Drawings:", filteredDrawings);
  }, [searchValue, filteredDrawings]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setFilterDropdownOpen(false);
  };

  const handleType = (type) => {
    console.log("Folder Click");
    setTypeSelected(type);
  };

  const categories = [
    ...new Set(drawingList.map((data) => data.category?.category)),
  ];
  const folderNames = [
    ...new Set(drawingList.map((data) => data.folderId?.folderName)),
  ];

  console.log("Unique Categories:", categories);
  console.log("Unique Folder Names:", folderNames);

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setEditData({
        ...editData,
        [name]: files[0], // Assuming you are only uploading one file
      });
    } else {
      setEditData({
        ...editData,
        [name]: value,
      });
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    let formErrors = {};
    if (!editData.drawingNumber)
      formErrors.drawingNumber = "Drawing Number is required";
    if (!editData.expectedDate)
      formErrors.expectedDate = "Expected Date is required";

    if (!editData.drawingFileName) {
      formErrors.drawingFileName = "Drawing File is required";
    }
    if (!editData.natureofrequestedInfo)
      formErrors.natureofrequestedInfo = "this field is requires";
    // if (!editData.revision) formErrors.revision = 'Please select a revision.';
    requestReason.forEach((reason, index) => {
      if (!reason.NOR) {
        formErrors[`NOR_${index}`] = "Nature of Request is required.";
      }
      if (!reason.reason) {
        formErrors[`reason_${index}`] = "Reason is required.";
      }
    });

    setError(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return;
    }

    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });
    try {
      const editFormData = {
        drawingId: editData.drawingId,
        drawingNo: editData.drawingNumber,
        siteId: site,
        requestedDate: todayDate(),
        expectedDate: editData.expectedDate,
        remarks: requestReason,
        revision: selectedRevision,
        natureOfRequestedInformation: editData.natureofrequestedInfo,
      };

      console.log("Submitting edit data:", editFormData);

      const response = await webApi.post(
        "api/architectureToRoRequested/postRequest",
        editFormData
      );

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.status === "error") {
          // Handle error from response data
          setErrorMessage(responseData.message);
          console.log("Response data:", responseData.message);
          setApiProcessing({ loader: false, message: "" });
          return; // Stop further processing
        }

        const id = response.data.data._id;

        if (editData.drawingFileName) {
          const drawingFileName = new FormData();
          drawingFileName.append("drawingFileName", editData.drawingFileName);
          const uploadResponse = await webApi.imagePut(
           `api/architectureToRoRequested/${id}`,
            drawingFileName
          );
          console.log("Upload response:", uploadResponse);
        }
        setApiProcessing({ loader: false, message: "" });
        handleEditClose();
        fetchData();
      } else if (response.status === 400) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
          console.log("Response data:", response.data.message);
        }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Failed to submit data.");
      }
    }
  };

  const handleDownload = async (id, revision) => {
    console.log(id, "id of drawing");
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      //TODO: need to check this path correct or not
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}?revision=${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewFile = async (id, revision) => {
    try {
      setApiProcessing({ loader: true, message: "Viewing" });

      // Make the request without explicitly setting the responseType
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/revisions/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );
      console.log("Image fot APPROVED", response);

      processFile(response, "view", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handlePDFDownload = async (filename) => {
    console.log(filename, "fiename");
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      //TODO: need to check this path correct or not
      const response = await webApi.getImage(
        `api/architectureToRoRegister/byPath/${companyID}/${site}/drawings/${filename}`,
        // api/architectureToRoRequested/${id}?revision=${revision},
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewPDFFile = async (filename) => {
    console.log(filename, "getting inside");
    try {
      setApiProcessing({ loader: true, message: "Viewing" });

      // Make the request without explicitly setting the responseType
      const response = await webApi.getImage(
        `api/architectureToRoRegister/byPath/${companyID}/${site}/drawings/${filename}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );
      console.log("Image fot APPROVED", response);

      processFile(response, "view", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
  const listItems = [
    { id: "A - Missing Information", name: "A - Missing Information" },
    { id: "B - Clarification", name: "B - Clarification" },
    { id: "C - Additional Information", name: "C - Additional Information" },
    { id: "D - Uncoordinated Drawings", name: "D - Uncoordinated Drawings" },
  ];

  // Function to get the name from id
  const getNameFromId = (id) => {
    const item = listItems.find((item) => item.id === id);
    return item ? item.name : "";
  };

  const [currentView, setCurrentView] = useState("upload");

  const handleTabViewChange = (event, newValue) => {
    console.log(newValue);
    setCurrentView(newValue);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase();
    setSearchValue(searchInput);
    setSearchActive(true);

    if (!searchInput) {
      fetchData();
      setSearchActive(false);
    } else {
      const filteredDrawings = initialDrawingList.filter((drawingItem) => {
        const drawingNumber = drawingItem.drawingNo?.toLowerCase() || "";
        const drawingName = drawingItem.drawingTitle?.toLowerCase() || "";
        const consultant =
          drawingItem.designDrawingConsultant?.role?.toLowerCase() || "";
        const category = drawingItem.category?.category?.toLowerCase() || "";
        // const acceptedROSubmissionDate = drawingItem.acceptedROSubmissionDate ? new Date(drawingItem.acceptedROSubmissionDate).toLocaleDateString().toLowerCase() : "";
        const submittedROSubmissionDate = drawingItem.submittedROSubmissionDate
          ? new Date(drawingItem.submittedROSubmissionDate)
              .toLocaleDateString()
              .toLowerCase()
          : "";
        // const dueDays = calculateDueDays(drawingItem.acceptedArchitectDate).toString().toLowerCase(); // Convert dueDays to lowercase for comparison
        // const dueStatus = dueDays >= 0 ? ${dueDays} days : ${Math.abs(dueDays)} days overdue;
        const revisions =
          drawingItem.acceptedArchitectRevisions
            ?.map((revisionItem) => revisionItem.revision.toLowerCase())
            .join(" ") || "";

        // Filter based on the selected filter criteria
        switch (selectedFilter) {
          case "Drawing No":
            return drawingNumber.includes(searchInput);
          case "Drawing Consultant":
            return consultant.includes(searchInput);
          case "Drawing Category":
            return category.includes(searchInput);
          case "Drawing Title":
            return drawingName.includes(searchInput);
          // case 'Accepted RO Submission Date':
          //   return acceptedROSubmissionDate.includes(searchInput);
          case "Final Submission Date":
            return submittedROSubmissionDate.includes(searchInput);
          // case 'Due/Overdue':
          //   return dueStatus.includes(searchInput) || dueDays.includes(searchInput);
          case "Revision":
            return revisions.includes(searchInput);
          default:
            return (
              drawingNumber.includes(searchInput) ||
              drawingName.includes(searchInput) ||
              consultant.includes(searchInput) ||
              category.includes(searchInput) ||
              // acceptedROSubmissionDate.includes(searchInput) ||
              submittedROSubmissionDate.includes(searchInput) ||
              // dueStatus.includes(searchInput) ||
              // dueDays.includes(searchInput) ||
              revisions.includes(searchInput)
            );
        }
      });

      setDrawingList(filteredDrawings);
    }
  };

  const handleDropdownClick = (filter) => {
    setSelectedFilter(filter);
    setShowDropdown(false);
  };
  const placeholderText = selectedFilter
    ? `Search by ${selectedFilter}`
    : "Search Drawing Lists";

  const calculateDueDays = (
    acceptedROSubmissionDate,
    softCopySubmittedDate = null
  ) => {
    if (!acceptedROSubmissionDate) return "-";

    const dateToCompare = softCopySubmittedDate
      ? new Date(softCopySubmittedDate)
      : new Date();
    const acceptedDate = new Date(acceptedROSubmissionDate);
    const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // convert time difference to days

    return differenceInDays;
  };
  useEffect(() => {
    console.log(editData, "editData");
    console.log(requestReason, "requestReason");
  }, [editData, requestReason]);

  const handleReasonChange = (e, index) => {
    console.log("jksdnfks");
    const { name, value } = e.target;
    const updatedReasons = [...requestReason];

    updatedReasons[index] = {
      ...updatedReasons[index],
      [name]: value,
    };

    setRequestReason(updatedReasons);
  };

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <Tabs value={currentView} onChange={handleTabViewChange}>
                  <Tab label="Upload To RO" value="upload" />
                  <Tab label="Received From RO" value="received" />
                </Tabs>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "250px",
                    paddingLeft: "20px",
                    height: "50px",
                  }}
                >
                  <input
                    placeholder={placeholderText}
                    value={searchValue}
                    onChange={handleSearchChange}
                    style={{
                      paddingLeft: "20px",
                      border: "1px solid var(--primary-color)",
                      width: "250px",
                      height: "40px",
                    }}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "-3px",
                      top: "40%",
                      transform: "translateY(-50%)",
                      color: "#ACACAC",
                    }}
                  />

                  <div
                    style={{
                      position: "relative",
                      marginLeft: "260px",
                      marginTop: "-30px",
                    }}
                  >
                    <div
                      onClick={toggleFilterDropdown}
                      style={{ cursor: "pointer" }}
                    >
                      <FaFilter size={30} />

                      {/* <img src={filter} alt="filter" /> */}
                    </div>
                    {isFilterDropdownOpen && (
                      <div
                        ref={dropdownRef}
                        style={{
                          position: "absolute",
                          background: "white",
                          borderRadius: "4px",
                          zIndex: 1000,
                          textAlign: "left",
                          marginTop: "20px",
                          marginLeft: "-30px",
                          padding: "15px",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                      >
                        <span style={{ marginTop: "0px", display: "block" }}>
                          <span
                            style={{
                              cursor: "pointer",
                              padding: "5px 0",
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={() => {
                                setTypeSelected("All");
                                setSelectedCategory("");
                                setFilterDropdownOpen(false);
                              }}
                            >
                              All
                            </div>
                          </span>
                        </span>

                        <span
                          style={{
                            cursor: "pointer",
                            padding: "5px 0",
                            position: "relative",
                          }}
                        >
                          <div onClick={() => handleType("categories")}>
                            Categories
                          </div>
                          {typeSelected === "categories" && (
                            <div
                              style={{
                                position: "absolute",
                                background: "white",
                                borderRadius: "4px",
                                zIndex: 1000,
                                marginLeft: "-120px",
                                padding: "20px 15px",
                                left: 0,
                                width: "100%",
                                boxShadow:
                                  "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {categories.map((category) => (
                                  <span
                                    key={category}
                                    onClick={() => {
                                      handleCategorySelect(category);
                                      setFilterDropdownOpen(false); // Close dropdown
                                    }}
                                    onMouseEnter={() =>
                                      setHoveredCategory(category)
                                    }
                                    onMouseLeave={() =>
                                      setHoveredCategory(null)
                                    }
                                    style={{
                                      cursor: "pointer",
                                      padding: "5px 0",
                                      transition: "background-color 0.2s",
                                      backgroundColor:
                                        selectedCategory === category
                                          ? "#d0e1ff"
                                          : hoveredCategory === category
                                          ? "#b0c4ff"
                                          : "white",
                                    }}
                                  >
                                    {category}
                                  </span>
                                ))}
                              </div>
                            </div>
                          )}
                        </span>

                        <span style={{ marginTop: "10px", display: "block" }}>
                          <span
                            style={{
                              cursor: "pointer",
                              padding: "5px 0",
                              position: "relative",
                            }}
                          >
                            <div onClick={() => handleType("folders")}>
                              Folders
                            </div>
                            {typeSelected === "folders" && (
                              <div
                                style={{
                                  position: "absolute",
                                  background: "white",
                                  borderRadius: "4px",
                                  zIndex: 1000,
                                  marginLeft: "-120px",
                                  padding: "20px 15px",
                                  left: 0,
                                  width: "100%",
                                  boxShadow:
                                    "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                }}
                              >
                                {folderNames.map((folder) => (
                                  <span
                                    key={folder}
                                    onClick={() => {
                                      handleCategorySelect(folder);
                                      setFilterDropdownOpen(false);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      padding: "5px 0",
                                      transition: "background-color 0.2s",
                                      backgroundColor:
                                        selectedCategory === folder
                                          ? "#d0e1ff"
                                          : hoveredCategory === folder
                                          ? "#b0c4ff"
                                          : "white",
                                    }}
                                    onMouseEnter={() =>
                                      setHoveredCategory(folder)
                                    }
                                    onMouseLeave={() =>
                                      setHoveredCategory(null)
                                    }
                                  >
                                    {folder}
                                  </span>
                                ))}
                              </div>
                            )}
                          </span>
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                {currentView === "upload" ? (
                  <Button
                    style={{
                      height: "40px",
                      marginLeft: "40px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "var(--primary-color)",
                      border: "1px solid var(--primary-color)",
                      borderRadius: "0px",
                    }}
                    onClick={handleClickOpen}
                  >
                    <Icon.PlusCircle size={20} style={{ marginRight: "5px" }} />{" "}
                    Add New
                  </Button>
                ) : (
                  <div style={{ marginLeft: "40px" }}></div>
                )}
              </div>
            </div>
            <div>
              {showDropdown && (
                <div
                  style={{
                    position: "absolute",
                    top: "350px",
                    right: "50px", // Align with the filter icon
                    border: "1px solid var(--primary-color)",
                    borderRadius: "6px",
                    backgroundColor: "#fff",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    padding: "5px",
                    zIndex: 1000,
                    width: "150px", // Adjust width for compact design
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Drawing No")}
                    >
                      DRWG.NO
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick(" Consultant")}
                    >
                      Consultant
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Category")}
                    >
                      Drawing Category
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Title")}
                    >
                      Drawing Title
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick(" R0 Submission Date")}
                    >
                      Accepted R0 Submission Date
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() =>
                        handleDropdownClick("Final Submission Date")
                      }
                    >
                      Final Submission Date
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Due/Overdue")}
                    >
                      Due/Overdue
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Revision")}
                    >
                      Revision
                    </li>
                  </ul>
                </div>
              )}

              {currentView === "upload" ? (
                <div style={{ marginTop: "10px" }}>
                  <DrawingTableWrapper
                    tableHead={
                      <tr>
                        <th style={{ width: "5%", paddingLeft: "10px" }}>
                          S.No
                        </th>
                        <th style={{ width: "10%" }}>DRWG.No</th>
                        <th style={{ width: "10%" }}>Consultant</th>
                        <th style={{ width: "5%" }}>Folder</th>
                        <th style={{ width: "10%" }}>Drawing Category</th>
                        <th style={{ width: "10%" }}>Drawing Title</th>
                        <th style={{ width: "13%", paddingLeft: "10px" }}>
                          Accepted R0 Submission Date
                        </th>
                        <th style={{ width: "13%", paddingLeft: "10px" }}>
                          Submission Date
                        </th>
                        <th style={{ width: "13%" }}>Due/Overdue</th>
                        <th style={{ width: "5%" }}>Revision</th>
                        <th style={{ width: "7%" }}>Action</th>
                      </tr>
                    }
                    tableBody={
                      <tbody style={{ textAlign: "center" }}>
                        {filteredDrawings.map((drawingItem, index) => (
                          <tr
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0
                                  ? "var(--table-oddrow-color)"
                                  : "var(--table-evenrow-color)",
                            }}
                          >
                            <td style={{ width: "5%" }}>{index + 1}</td>
                            <td style={{ width: "10%" }}>
                              {drawingItem?.drawingNo || ""}
                            </td>
                            <td style={{ width: "10%" }}>
                              {drawingItem.designDrawingConsultant?.role || ""}
                            </td>
                            <td style={{ width: "5%" }}>
                              {drawingItem.folderId?.folderName || ""}
                            </td>
                            <td style={{ width: "10%" }}>
                              {drawingItem.category?.category || ""}
                            </td>
                            <td
                              style={{
                                width: "9%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                cursor: "default",
                              }}
                            >
                              <span
                                title={drawingItem.drawingTitle}
                                style={{
                                  display: "inline-block",
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {drawingItem.drawingTitle
                                  .split(" ")
                                  .slice(0, 2)
                                  .join(" ")}
                                ...
                              </span>
                            </td>
                            <td style={{ width: "12.8%" }}>
                              {formatDate(
                                drawingItem?.acceptedROSubmissionDate || ""
                              )}
                            </td>
                            <td style={{ width: "13%" }}>
                              {formatDate(
                                drawingItem.acceptedArchitectRevisions[0]
                                  ?.softCopySubmittedDate
                              ) || ""}
                            </td>
                            <td style={{ width: "12%" }}>
                              <span
                                style={{
                                  color:
                                    new Date(
                                      drawingItem.softCopySubmittedDate
                                    ) < new Date()
                                      ? "black"
                                      : calculateDueDays(
                                          drawingItem.acceptedROSubmissionDate,
                                          drawingItem
                                            .acceptedArchitectRevisions[0]
                                            ?.softCopySubmittedDate
                                        ) >= 0
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {calculateDueDays(
                                  drawingItem.acceptedROSubmissionDate,
                                  drawingItem.acceptedArchitectRevisions[0]
                                    ?.softCopySubmittedDate
                                ) === "-"
                                  ? "-"
                                  : calculateDueDays(
                                      drawingItem.acceptedROSubmissionDate,
                                      drawingItem.acceptedArchitectRevisions[0]
                                        ?.softCopySubmittedDate
                                    ) >= 0
                                  ? `${calculateDueDays(
                                      drawingItem.acceptedROSubmissionDate,
                                      drawingItem.acceptedArchitectRevisions[0]
                                        ?.softCopySubmittedDate
                                    )} days due`
                                  : `${Math.abs(
                                      calculateDueDays(
                                        drawingItem.acceptedROSubmissionDate,
                                        drawingItem
                                          .acceptedArchitectRevisions[0]
                                          ?.softCopySubmittedDate
                                      )
                                    )} days overdue`}
                              </span>
                            </td>
                            <td style={{ width: "5%" }}>
                              {drawingItem.acceptedArchitectRevisions.length >
                              0 ? (
                                <div>
                                  {drawingItem.acceptedArchitectRevisions
                                    .slice(-2)
                                    .map(
                                      (revisionItem, idx) =>
                                       ` ${revisionItem.revision} `|| "NA"
                                    )
                                    .join(", ")}
                                </div>
                              ) : (
                                <div>-</div>
                              )}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "60px",
                                paddingLeft: "0px",
                              }}
                            >
                              {/* <Icon.Pen
                                size={20}
                                style={{
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                                onClick={() => handleEditOpen(drawingItem._id)}
                              /> */}
                              <Icon.EyeFill
                                size={20}
                                style={{
                                  marginRight: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleViewOpen(drawingItem)}
                              />
                              <Icon.Download
                                size={20}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  const latestRevision =
                                    drawingItem.acceptedArchitectRevisions
                                      .length > 0
                                      ? drawingItem.acceptedArchitectRevisions[
                                          drawingItem.acceptedArchitectRevisions
                                            .length - 1
                                        ].revision
                                      : null;
                                  handleDownload(drawingItem, latestRevision);
                                }}
                              />
                              <Loader
                                open={apiProcessing.loader}
                                message={apiProcessing.message}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    }
                  />
                </div>
              ) : (
                <div style={{ marginTop: "10px" }}>
                  <ReceivedTable
                    selectedFilter={selectedFilter}
                    searchValue={searchValue}
                    searchActive={searchActive}
                    setSearchValue={setSearchValue}
                    setSearchActive={setSearchActive}
                    reloadData={reloadData}
                    setReloadData={setReloadData}
                    filterCondition="approved"
                  />
                </div>
              )}
            </div>
          </>
        }
      />
      <AddDrawingDialog
        handleDialogClose={handleDialogClose}
        open={open}
        site={site}
      />
      <Dialog
        open={editDialogOpen}
        onClose={handleEditClose}
        maxWidth="md"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 250px", textAlign: "center", color: "white" }}
          >
            RFI
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleEditClose}
          />
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <form onSubmit={handleEditSubmit}>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="drawingNumber"
                    className="fs-10  text-start py-2"
                  >
                    Drawing Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="drawingNumber"
                    placeholder="Enter your Drawing Number"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    value={editData.drawingNumber}
                    onChange={handleInputChange}
                    readOnly
                  />
                  {error.drawingNumber && (
                    <div className="text-danger">{error.drawingNumber}</div>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="requestedDate"
                    className="fs-10  text-start py-2"
                  >
                    Requested Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="expectedDate"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    value={editData.requestedDate}
                    onChange={handleInputChange}
                    readOnly
                  />
                  {error.requestedDate && (
                    <div className="text-danger">{error.requestedDate}</div>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="expectedDate"
                    className="fs-10  text-start py-2"
                  >
                    Expected Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    name="expectedDate"
                    className="form-control fs-12"
                    style={{ width: "100%", height: "45px" }}
                    value={editData.expectedDate}
                    onChange={handleInputChange}
                    min={tomorrowDateString}
                  />
                  {error.expectedDate && (
                    <div className="text-danger">{error.expectedDate}</div>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <label htmlFor="revision" className="fs-10  text-start py-2">
                    Revisions
                    {/* <span style={{ color: 'red' }}>*</span> */}
                  </label>
                  <SearchDropdownIdVal
                    placeholder={"Select Revision"}
                    backgroundColor={"var(--table-evenrow-color)"}
                    // Check if editData.revision is an array and get the latest revision
                    options={
                      Array.isArray(editData.revision) &&
                      editData.revision.length > 0
                        ? [
                            {
                              name: editData.revision.slice(-1)[0], // Get the latest revision
                              value: editData.revision.slice(-1)[0], // Use the latest revision for value
                            },
                          ]
                        : []
                    }
                    onOptionSelect={(option) =>
                      handleRevisionSelection({
                        target: {
                          name: "selectedRevision",
                          value: option.value,
                        },
                      })
                    }
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />

                  {error.revision && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {error.revision}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <MultiSelectSearch
                    mandatory={true}
                    heading={"Nature of Requested Information"}
                    listItems={listItems}
                    selectedIds={editData.natureofrequestedInfo || []} // Ensure this is an array
                    setSelectedIds={(ids) =>
                      setEditData({ ...editData, natureofrequestedInfo: ids })
                    }
                    handleSelectedPropertyOption={(data) => {
                      if (editData.natureofrequestedInfo.includes(data.id)) {
                        setEditData({
                          ...editData,
                          natureofrequestedInfo:
                            editData.natureofrequestedInfo.filter(
                              (id) => id !== data.id
                            ),
                        });
                      } else {
                        setEditData({
                          ...editData,
                          natureofrequestedInfo: [
                            ...editData.natureofrequestedInfo,
                            data.id,
                          ],
                        });
                      }
                    }}
                    clearAll={() =>
                      setEditData({ ...editData, natureofrequestedInfo: [] })
                    }
                    addAll={() =>
                      setEditData({
                        ...editData,
                        natureofrequestedInfo: listItems.map((item) => item.id),
                      })
                    }
                  />

                  {/* Display selected fields */}
                  <div style={{ marginTop: "20px" }}>
                    <p className="fs-10  text-start py-2">Selected Fields:</p>
                    <ul>
                      {editData.natureofrequestedInfo.length > 0 ? (
                        editData.natureofrequestedInfo.map((id) => (
                          <li key={id}>{getNameFromId(id)}</li>
                        ))
                      ) : (
                        <li>No fields selected</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="remarks"
                    className="fs-10  text-start py-2"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Request Reason *
                    <span style={{ display: "flex", alignItems: "center" }}>
                      &nbsp; &nbsp; &nbsp;{" "}
                      <HiPlusCircle
                        style={{
                          marginLeft: "20px",
                          color: "#e47331",
                          fontSize: "24px",
                        }}
                        onClick={handleAddReason}
                      />
                    </span>
                  </label>
                  {requestReason.map((reqReason, reqIndex) => (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        marginTop: "30px",
                      }}
                    >
                      <select
                        style={{ width: "15%", marginRight: "20px" }}
                        name="NOR"
                        value={reqReason.NOR}
                        onChange={(e) => handleReasonChange(e, reqIndex)}
                      >
                        <option vlaue="">Select</option>
                        {editData.natureofrequestedInfo.length &&
                          editData.natureofrequestedInfo.map(
                            (requestInfo, index) => (
                              <option key={index} value={requestInfo}>
                                {requestInfo}{" "}
                              </option>
                            )
                          )}
                      </select>

                      <input
                        type="text"
                        style={{ width: "82%" }}
                        name="reason"
                        value={reqReason.reason}
                        onChange={(e) => handleReasonChange(e, reqIndex)}
                      />
                      {reqIndex > 0 ? (
                        <FaCircleMinus
                          style={{
                            fontSize: "24px",
                            position: "absolute",
                            right: "-21px",
                          }}
                          onClick={() => handleRemoveReason(reqIndex)}
                        />
                      ) : (
                        ""
                      )}
                      {error[`NOR_${reqIndex}`] ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            top: "50px",
                          }}
                        >
                          {error[`NOR_${reqIndex}`]}
                        </p>
                      ) : error[`reason_${reqIndex}`] ? (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            top: "50px",
                            right: "50px",
                          }}
                        >
                          {error[`reason_${reqIndex}`]}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <label
                  htmlFor="drawingFileName"
                  className="fs-10  text-start py-2"
                >
                  Upload Your File <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="file"
                  accept=".pdf, .jpg, .jpeg, .png, .dwg"
                  id="drawingFileName"
                  name="drawingFileName"
                  className="form-control fs-12"
                  style={{ width: "100%", height: "43px" }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file && file.name.endsWith(".dwg")) {
                      handleInputChange(e);
                    } else {
                      alert("Please select a .dwg file.");
                      e.target.value = null; // Reset the file input if the file is not valid
                    }
                  }}
                />

                {error.drawingFileName && (
                  <div className="text-danger">{error.drawingFileName}</div>
                )}
              </div>
              {errorMessage && (
                <div style={{ color: "red", fontSize: "15px" }}>
                  {errorMessage}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  type="submit"
                  style={{
                    background: "blue",
                    color: "white",
                    width: "100px",
                    height: "35px",
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            fontFamily: "Poppins",
            borderRadius: "10px",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {singleDrawingData?.acceptedArchitectRevisions?.length > 0 ? (
              <Tabs1 value={selectedTab} onChange={handleTabChange}>
                {singleDrawingData.acceptedArchitectRevisions.map(
                  (revisionItem, index) => (
                    <Tab1
                      key={index}
                      value={index}
                      label={`${revisionItem.revision}`}
                      selected={selectedTab === index}
                      style={{
                        backgroundColor:
                          singleDrawingData.acceptedArchitectRevisions
                            .length === 1 || selectedTab === index
                            ? "var(--primary-color)"
                            : "transparent",
                        color:
                          singleDrawingData.acceptedArchitectRevisions
                            .length === 1 || selectedTab === index
                            ? "white"
                            : "var(--primary-color)",
                        width: `${
                          100 /
                          singleDrawingData.acceptedArchitectRevisions.length
                        }%`,
                        height: "30px",
                        minWidth: "20px",
                        cursor: "pointer",
                        borderRight:
                          index <
                          singleDrawingData.acceptedArchitectRevisions.length -
                            1
                            ? "1px solid var(--primary-color)"
                            : "none",
                      }}
                    />
                  )
                )}
              </Tabs1>
            ) : (
              <div>No Revisions</div>
            )}
          </div>
        </div>
        {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {selectedTab ===
                  singleDrawingData.acceptedArchitectRevisions.length - 1 ? (
                    <div style={{ color: "green" }}>Good For Construction</div>
                  ) : (
                    <div style={{ color: "red" }}>Superseeded</div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",

                justifyContent: "center",
                marginLeft: "100px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.designDrawingConsultant?.role}{" "}
              </div>

              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>
              <div>Folder</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.folderId?.folderName || "NA"}
              </div>
              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>

              <div>Scheduled submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedROSubmissionDate
                  ? new Date(singleDrawingData.acceptedROSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Actual Final Submission Date</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedArchitectRevisions[
                          selectedTab
                        ].softCopySubmittedDate
                          ? formatDate(
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ].softCopySubmittedDate
                            )
                            : "No Submitted Date"}
                            </div>
                          </>
                        )}
                      </>
                    )}
      
                    {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                      <>
                        {singleDrawingData.acceptedArchitectRevisions[
                          selectedTab
                        ] && (
                          <>
                            <div>Revision</div>
                            <div>:</div>
                            <div style={{ marginLeft: "-120px" }}>
                              {singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ].revision || "NA"}
                            </div>
                          </>
                        )}
                      </>
                    )}
      
                    <div>Due / Overdue</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      <span
                        style={{
                          color:
                            singleDrawingData &&
                            singleDrawingData.acceptedArchitectRevisions &&
                            singleDrawingData.acceptedArchitectRevisions.length > 0
                              ? calculateDueDays(
                                  singleDrawingData.acceptedROSubmissionDate,
                                  singleDrawingData.acceptedArchitectRevisions[0]
                                    ?.softCopySubmittedDate
                                ) >= 0
                                ? "green"
                                : "red"
                              : "black",
                        }}
                      >
                        {singleDrawingData &&
                        singleDrawingData.acceptedArchitectRevisions &&
                        singleDrawingData.acceptedArchitectRevisions.length > 0
                          ? calculateDueDays(
                              singleDrawingData.acceptedROSubmissionDate,
                              singleDrawingData.acceptedArchitectRevisions[0]
                                ?.softCopySubmittedDate
                            ) === "-"
                            ? "NA"
                            : calculateDueDays(
                                singleDrawingData.acceptedROSubmissionDate,
                                singleDrawingData.acceptedArchitectRevisions[0]
                                  ?.softCopySubmittedDate
                              ) >= 0
                            ? `${calculateDueDays(
                                singleDrawingData.acceptedROSubmissionDate,
                                singleDrawingData.acceptedArchitectRevisions[0]
                                  ?.softCopySubmittedDate
                              )} days due`
                            : `${Math.abs(
                                calculateDueDays(
                                  singleDrawingData.acceptedROSubmissionDate,
                                  singleDrawingData.acceptedArchitectRevisions[0]
                                    ?.softCopySubmittedDate
                                )
                              )} days overdue`
                          : "-"}
                      </span>
                    </div>
      
                    {singleDrawingData?.acceptedArchitectRevisions?.length > 0 &&
                      singleDrawingData?.acceptedArchitectRevisions[selectedTab]
                        ?.changes && (
                        <>
                          <div>Changes in Revsion</div>
                          <div>:</div>
                          <div style={{ marginLeft: "-120px" }}>
                            {singleDrawingData.acceptedArchitectRevisions[selectedTab]
                              .changes || "NA"}
                          </div>
                        </>
                      )}
      
                    {singleDrawingData?.acceptedArchitectRevisions?.length > 0 &&
                      singleDrawingData?.acceptedArchitectRevisions[selectedTab]
                        ?.issuesInRevision?.length > 0 && (
                        <>
                          <div>Issues In Revision</div>
                          <div>:</div>
                          <div style={{ marginLeft: "-120px" }}>
                            {singleDrawingData.acceptedArchitectRevisions[
                              selectedTab
                            ].issuesInRevision.join(", ") || "NA"}
                          </div>
                        </>
                      )}
      
                    {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                      <>
                        {singleDrawingData.acceptedArchitectRevisions[
                          selectedTab
                        ] && (
                          <>
                            <div>Drawing File </div>
                            <div>:</div>
                            <div style={{ display: "flex", marginLeft: "-120px" }}>
                              <Icon.Image
                                size={20}
                                style={{ marginRight: "10px", cursor: "pointer" }}
                                onClick={() =>
                                  handleViewFile(
                                    singleDrawingData._id,
                                    singleDrawingData.acceptedArchitectRevisions[
                                      selectedTab
                                    ].revision
                                  )
                                }
                              />
                              <Icon.Download
                                size={20}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                                onClick={() =>
                                  handleDownload(
                                    singleDrawingData._id,
                                    singleDrawingData.acceptedArchitectRevisions[
                                      selectedTab
                                    ].revision
                                  )
                                }
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                      <>
                        {singleDrawingData.acceptedArchitectRevisions[
                          selectedTab
                        ] && (
                          <>
                            <div>PDF File </div>
                            <div>:</div>
                            <div style={{ display: "flex", marginLeft: "-120px" }}>
                              <Icon.Image
                                size={20}
                                style={{ marginRight: "10px", cursor: "pointer" }}
                                onClick={() =>
                                  handleViewPDFFile(
                                    singleDrawingData.acceptedArchitectRevisions[
                                      selectedTab
                                    ]?.pdfDrawingFileName
                                  )
                                }
                              />
                              <Icon.Download
                                size={20}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                                onClick={() =>
                                  handlePDFDownload(
                                    singleDrawingData.acceptedArchitectRevisions[
                                      selectedTab
                                    ]?.pdfDrawingFileName
                                  )
                                }
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {/* <iframe src="//sharecad.org/cadframe/load?url=https://www.cadsofttools.com/dwgviewer/hostel_block.dwg" scrolling="no"> </iframe> */}
                  </div>
                </>
              </DialogContent>
            </Dialog>
          </>
        );
      };
      
      export default ApprovedTable;