import React from 'react';

export const Tabs1 = ({ value, onChange, children }) => {
    return (
      <div style={{ display: 'flex', }}>
        {children.map((child,index) =>
          React.cloneElement(child, {
            selected: child.props.value === value,
            onClick: (event) => onChange(event, child.props.value), 
            showRightBorder: index < children.length - 1,
          })
        )}
      </div>
    );
  };

export const Tab1 = ({ label, selected, onClick , showRightBorder }) => {
  const styles = {
    tab: {
      padding: '10px 20px',
      cursor: 'pointer',
      fontWeight: '600',
      fontSize: '14px',
      textTransform: 'none',
      color: selected ? 'white' : 'var(--primary-color)',
      backgroundColor: selected ? 'var(--primary-color)' : 'transparent',
      transition: 'color 0.3s',
      borderRight: showRightBorder ? "1px solid var(--primary-color)" : "none",
    },
   
  };

  return (
    <div style={styles.tab} onClick={onClick}>
      {label}
    </div>
  );
};



