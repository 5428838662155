import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Header.module.css';
import logo from '../../Images/TMLOGO.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to check if the link is active
  const isActive = (path) => location.pathname === path ? styles.active : '';

  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <img src={logo} alt="Company Logo" className={styles.logo} />
          <button className={styles.menuToggle} onClick={toggleMenu}>
            <span className={styles.menuIcon}></span>
          </button>
          <nav className={`${styles.navigation} ${isMenuOpen ? styles.open : ''}`}>
            <a href="/" className={`${styles.navLink} ${isActive('/')}`}>Overview</a>
            <a href="/products" className={`${styles.navLink} ${isActive('/products')}`}>Products</a>
            <a href="/why-us" className={`${styles.navLink} ${isActive('/why-us')}`}>Why us</a>
            <a href="/about-us" className={`${styles.navLink} ${isActive('/about-us')}`}>About us</a>
            <a href="/partners" className={`${styles.navLink} ${isActive('/partners')}`}>Partners</a>
            <a href="/contact" className={`${styles.navLink} ${isActive('/contact')}`}>Contact us</a>
            <a href="/sign-in"><button className={styles.loginButton}>LOG IN</button></a>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
