import { React, useState } from "react";
import styles from "./ContactHeroSection.module.css";
import Header from "../Components/Navbar/Header";
import ContactUsImg from "./ContactUsImg.png"
import PagesHeroSection from "../Components/constants-comp/pagesHerosection";
import email from "./email.png"
import Group from "./Group.png"
import location from "./location.png"
import { WebApimanager } from "../WebApiManager";
import Vector from "./Vector.png"
import ModalPopup from "../Components/ModalPopup/ModalPopup";

function ContactHeroSection() {
  const webApi = new WebApimanager();
  const [showModal,setShowModal] = useState(false) 
  const [formData, setFormData] = useState({
    fullName: "",
    mobileNumber: "",
    email: "",
    message: "",
    role:"",
    companyName:"",
    inquiryType:"",
    checkbox:false
  });

  const [errors, setErrors] = useState({
    fullName: "",
    mobileNumber: "",
    email: "",
    message: "",
    role:"",
    companyName:"",
    inquiryType:"",
    checkbox:""
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    let valid = true;
    let newErrors = { ...errors };

    // Validate each field
    Object.keys(formData).forEach((key) => {
      if (!formData[key] && key !== 'checkbox') {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
        valid = false;
      } else if (key === 'checkbox' && !formData.checkbox) {
        newErrors.checkbox = "Checkbox is required.";
        valid = false;
      } else {
        newErrors[key] = ""; // Clear any previous errors
      }
    });

    setErrors(newErrors);

    if (valid) {
      console.log("Form submitted:", formData);
      const submissionData = {
        name: formData.fullName,
        email: formData.email,
        phoneNumber: formData.mobileNumber,
        companyName: formData.companyName,
        role: formData.role,
        inquiryType: formData.inquiryType,
        message: formData.message,
      };
      const response = await webApi.post("api/contactUs", submissionData);
      if (response.status === 201 || response.status === 200) {
        setShowModal(true);
        setFormData({
          fullName: "",
          mobileNumber: "",
          email: "",
          message: "",
          role:"",
          companyName:"",
          checkbox:"",
          inquiryType:"",
        })
        setTimeout(() => {
          setShowModal(false);
        }, 3000);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Update the form data state
    if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    // Error handling using switch case
    let newErrors = { ...errors };

    switch (name) {
      case "fullName":
        if (!value.trim()) {
          newErrors.fullName = "Full Name is required.";
        } else if (!/^[A-Za-z]+$/.test(value)) {
          newErrors.fullName = "Enter a Valid Name";
        } else {
          newErrors.fullName = "";
        }
        break;

      case "mobileNumber":
        const mobileRegex = /^\d{10}$/;
        if (!value) {
          newErrors.mobileNumber = "Mobile Number is required";
        } else if (!mobileRegex.test(value)) {
          newErrors.mobileNumber = "Enter a valid 10-digit mobile number.";
        } else {
          newErrors.mobileNumber = "";
        }
        break;

      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value) {
          newErrors.email = "Email address is required.";
        } else if (!emailRegex.test(value)) {
          newErrors.email = "Enter a valid email address.";
        } else {
          newErrors.email = "";
        }
        break;

      case "companyName":
        newErrors.companyName = !value.trim() ? "Company Name is required" :  !(/^[a-zA-Z]+$/.test(value))?"Enter a valid Company Name":"";
        break;

      case "role":
        newErrors.role = !value ? "Role is required" : !(/^[a-zA-Z]+$/.test(value))?"Enter a valid role":"";
        break;

      case "message":
        newErrors.message = !value ? "Message is required" : "";
        break;

      case "inquiryType":
        newErrors.inquiryType = !value ? "Inquiry Type is required" : "";
        break;

      case "checkbox":
        newErrors.checkbox = !checked ? "Checkbox is required" : "";
        break;

      default:
        break;
    }

    setErrors(newErrors);
  };
  const redirectToWatsapp=()=>{
    const phoneNumber = +919491116000; 
            const message = encodeURIComponent('Hello, I would like to chat with you!'); 
            window.location.href = `https://wa.me/${phoneNumber}?text=${message}`;
  }
  const openMap =()=>{
    const latitude = 17.441557; 
            const longitude = 78.445641; 
            
            const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
            
            window.location.href = mapUrl;
  }


  return (
    <>
      <Header />
      <PagesHeroSection
        imageUrl={ContactUsImg}
        title={"Contact Us"}
      />
      <main classsName={styles.MainContainer}>
        <div className={styles.ContactDetailsContainer}>
            <div className={styles.ContentContainer}>
            <div className={styles.DetailsContainer} onClick={()=>window.location.href = 'tel:+919491116000'}>
              <img style={{marginBottom:"10px",height:"45px",width:"41px"}} src={Vector} alt="Phn" />
              <p className={styles.Text} style={{fontSize:"12px"}}>Contact Us</p>
              <p  className={styles.Text} style={{fontSize:"14px"}}>+919491116000</p>
            </div>
            <div className={styles.DetailsContainer} onClick={()=>redirectToWatsapp()}>
            <img style={{marginBottom:"10px"}} src={Group} alt="Watsapp" />

              <p  className={styles.Text} style={{fontSize:"12px"}}>Whatsapp</p>
              <p  className={styles.Text} style={{fontSize:"14px"}}>+919491116000</p>
            </div>
            </div>
            <div className={styles.ContentContainer}>
            <div className={styles.DetailsContainer} onClick={() => window.location.href = `mailto:support@mrchams.com`}>
                                                                
              <img style={{marginBottom:"10px"}} src={email} alt="Email" />
              <p  className={styles.Text} style={{fontSize:"12px"}}>Email</p>
              <p  className={styles.Text} style={{fontSize:"14px",textWrap:"wrap"}}>support@mrchams.com</p>
            </div>
            <div className={styles.DetailsContainer} onClick={()=>openMap()}>
              <img style={{marginBottom:"10px"}} src={location} alt="Location" />
              <p  className={styles.Text} style={{fontSize:"12px"}}>Location</p>
              <p  className={styles.Text} style={{fontSize:"14px"}}>Hyderabad</p>
            </div>
            </div>
            
        </div>
        <div className={styles.FormContainer}>
          <form onSubmit={handleSubmit} className={styles.FormDetails}>
              <div className={styles.InputsContainer}>
              <h4 className={styles.FormHeading}>Contact Form</h4>
              <div className={styles.InputFieldContainer}>
                <div className={styles.CuConent}>
                <input onChange={handleChange} name="fullName" value={formData.fullName} className={styles.InputField} type="text" placeholder="Full Name" />
                <p className={styles.CuError}>{errors.fullName}</p>
                </div>
                <div className={styles.CuConent}>
                <input onChange={handleChange} name="email" value={formData.email} className={styles.InputField} type="email" placeholder="Email"/>
                <p className={styles.CuError}>{errors.email}</p>
                </div>
                



              </div>
              <div className={styles.InputFieldContainer}>
                <div className={styles.CuConent}>
                <input onChange={handleChange}
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  className={styles.InputField}
                  type="tel" 
                  placeholder="Phone Number"
                  maxLength="10" 
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }} />
                <p className={styles.CuError}>{errors.mobileNumber}</p>
                </div>
                <div className={styles.CuConent}>
                <input onChange={handleChange} name="companyName" value={formData.companyName} className={styles.InputField} type="text" placeholder="Company Name"/>
                <p className={styles.CuError}>{errors.companyName}</p>
                </div>
                
                
              </div>
              <div className={styles.InputFieldContainer}>
                <div className={styles.CuConent}>
                <input onChange={handleChange} name="role" value={formData.role} className={styles.InputField} type="text" placeholder="Role" />
                <p className={styles.CuError}>{errors.role}</p>
                </div>
                <div className={styles.CuConent}>
                <select className={styles.OptionField} name="inquiryType" value={formData.inquiryType} onChange={handleChange}>
                  <option   value="">Select Inquiry Type</option>
                  <option   value="Demo">Demo</option>
                  <option   value="More Info">More Info</option>
                  <option   value="Try">Try</option>
                  

                </select>
                <p className={styles.CuError}>{errors.inquiryType}</p>
                </div>
                
              
              
              </div>
              
              <textarea  name="message"  value={formData.message} id="message" placeholder="Message" className={styles.TextMessage} onChange={handleChange}></textarea>
              <p  style={{fontSize:"12px",color:"red",marginBottom:"10px"}}>{errors.message}</p>
              </div>
              <div className={styles.CheckboxContainer}>
                <input type="checkbox" style={{height:"14px"}} name="checkbox" onChange={handleChange}/>
                <p style={{fontSize:"12px",marginBlock:"20px"}}>I consent above filled information is by my own</p>
              </div>
                
                {errors.checkbox && <p style={{fontSize:"12px",color:"red"}}>{errors.checkbox}</p>}

              <div className={styles.ButtonContainer}>
                <button className={styles.Btn}>Send</button>
              </div>


              
          </form>
        </div>
      </main>
     
    {showModal && <ModalPopup/>}
    </>
  );
}

export default ContactHeroSection;
