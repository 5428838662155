// src/components/ToggleSwitch.js
import React from "react";
import "./ToggleSwitch.css"; // Assuming you have a CSS file for styling

const ToggleSwitch = ({ label, isChecked, onToggle }) => {
  return (
    <div
      className="permission-item"
      style={{ backgroundColor: "transparent", border: "none" }}
    >
      <span>{label}</span>
      <label className="switch">
        <input type="checkbox" checked={isChecked} onChange={onToggle} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
