import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { drawingoptionsTab, siteId, userInfo } from "../../../atoms";
import { WebApimanager } from "../../../WebApiManager";
import SelectSite from "../../../Utilities/SelectSite";
import SiteHeadDrawing from "./Drawing/SiteHeadDrawing";
import SiteHeadRFI from "./RFI/SiteHeadRFI";
import UploadtoSitelevel from "./Pending/UploadtoSitelevel";
import Register from "./Register/Register";
import Analysis from "../Components/Analysis";

const Sitehead = () => {
  const webApi = new WebApimanager();
  const [value, setValue] = useRecoilState(drawingoptionsTab);
  const [site] = useRecoilState(siteId);
  const [currentUserInfo] = useRecoilState(userInfo);
  const [tabEnableModules, setTabEnableModules] = useState({
    drawing: false,
    rfi: false,
    pending: false,
    register: false,
    analysis: false,
  });

  useEffect(() => {
    console.log("SiteHead site Enable Modules", currentUserInfo);

    if (currentUserInfo?.permittedSites) {
      let enabledTabs = {
        drawing: false,
        rfi: false,
        pending: false,
        register: false,
        analysis: false,
      };

      currentUserInfo.permittedSites.forEach((site) => {
        const SiteHeadDetails = site.enableModules?.drawingDetails?.siteHeadDetails;

        if (SiteHeadDetails) {
          enabledTabs = {
            drawing: enabledTabs.drawing || SiteHeadDetails.drawing,
            rfi: enabledTabs.rfi || SiteHeadDetails.rfi,
            pending: enabledTabs.pending || SiteHeadDetails.pending,
            register: enabledTabs.register || SiteHeadDetails.register,
            analysis: enabledTabs.analysis || SiteHeadDetails.analysis,
          };
        }
      });

      setTabEnableModules(enabledTabs);
    }
  }, [currentUserInfo]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  if (site === "") {
    return <SelectSite />;
  }

  const anyTabEnabled = Object.values(tabEnableModules).some((val) => val);

  const allTabs = [
    { label: "Drawing", index: 0, component: <SiteHeadDrawing 
      forwardAccess={
        currentUserInfo.permittedSites[0].enableModules.drawingDetails
          .siteHeadDetails.forwardAccess
      }
      rfiRaisedAccess={
        currentUserInfo.permittedSites[0].enableModules.drawingDetails
          .siteHeadDetails.rfiRaisedAccess
      }/> },
    { label: "RFI", index: 1, component: <SiteHeadRFI rfiRaisedAccess={
      currentUserInfo.permittedSites[0].enableModules.drawingDetails
        .siteHeadDetails.rfiRaisedAccess
    }/> },
    { label: "Pending", index: 2, component: <UploadtoSitelevel forwardAccess={
      currentUserInfo.permittedSites[0].enableModules.drawingDetails
        .siteHeadDetails.forwardAccess
    }/> },
    { label: "Register", index: 3, component: <Register /> },
    { label: "Analysis", index: 4, component: <Analysis type="SiteHead" /> },
  ];

  // Filter enabled tabs based on user permissions
  //   const enabledTabs = allTabs.filter(
  //     (tab) => tabEnableModules[tab.label.toLowerCase()]
  //   );

  return (
    <>
      <>
        <div className="containerDrawingPagesArchitecture">
          <ul className="tabsArchitectureTabs" role="tablist">
            {allTabs.map((tab) => (
              <li
                key={tab.index}
                className={`tabArchitectureTab ${
                  value === tab.index ? "active" : ""
                }`}
                role="tab"
                aria-selected={value === tab.index}
                aria-controls={`tabpanel-${tab.index}`}
                id={`tab-${tab.index}`}
                onClick={() => handleChange(tab.index)}
              >
                {tab.label}
              </li>
            ))}
          </ul>
        </div>

        {allTabs.map((tab) => (
          <TabPanel key={tab.index} value={value} index={tab.index}>
            {tab.component}
          </TabPanel>
        ))}
      </>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      className="tabpanelArchitecture"
    >
      {value === index && <div className="tabpanel-content">{children}</div>}
    </div>
  );
}

export default Sitehead;
