import React from 'react';
import styles from './VisionSection.module.css';
import { useNavigate } from 'react-router-dom';

function VisionSection() {

  const navigate = useNavigate();

  const handelButtonClick = () => {
    navigate("/contact")
  }

  return (
    <section className={styles.visionSection}>
      <h2 className={styles.sectionTitle}>Vision</h2>
      <p className={styles.visionDescription}>
        Our vision for this online digital rights sales platform is to revolutionize the way creators and buyers connect, making it easier and more efficient for both parties to buy and sell digital rights.
      </p>
      <div className={styles.callToAction}>
        <h3 className={styles.ctaTitle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
        <div className={styles.ctaButtons}>
          <button className={styles.chatButton} onClick={handelButtonClick}>Chat with Sales Team</button>
          
        </div>
      </div>
    </section>
  );
}

export default VisionSection;