import React, { useState, useEffect } from "react";
import ToggleSubModuleSwitch from "./ToggleSubModuleSwitch";
import SubModuleModal from "../Users/SubModuleModal";

const getPageType = (label) => {
  console.log(label);
  switch (label) {
    case "Drawing":
      return "drawingDetails";
    case "USER":
      return "userDetails";
    case "CHECKLIST":
      return "checkListDetails";
    case "SPACE":
      return "spaceDetails";
    default:
      return "";
  }
};

const ToggleSwitchWithModules = ({
  label,
  isChecked,
  selectedSiteId,
  onToggle,
  selectedUser,
  setSelectedUser,
  user,
}) => {
  const drawingPages = {
    "Architecture To Ro": "architectureToRo",
    Ro: "ro",
    options: "options",
    "Site Head": "siteHead",
    "Site To Site": "siteToSite",
  };

  const userPages = {
    employee: "employee",
    profile: "profile",
    organizationChart: "organizationChart",
  };

  const checklistPages = {
    design: "design",
    approval: "approval",
    forms: "forms",
    workflow: "WorkFlow",
  };

  const spacePages = {
    isCodes: "isCodes",
    constructionNeeds: "constructionNeeds",
  };

  const pageType = getPageType(label);
  const modules = selectedUser?.modules?.[pageType] || {};

  const [showSubModuleModal, setShowSubModuleModal] = useState(false);
  const [selectedSubModule, setSelectedSubModule] = useState(null);

  const handleIconClick = (subModuleKey) => {
    setSelectedSubModule(subModuleKey);
    setShowSubModuleModal(true);
  };

  const handleCloseSubModal = () => {
    setShowSubModuleModal(false);
    setSelectedSubModule(null);
  };

  useEffect(() => {
    console.log("Selected User:", selectedUser);
  }, [selectedUser]);

  const getSubModulePages = () => {
    switch (pageType) {
      case "drawingDetails":
        return drawingPages;
      case "userDetails":
        return userPages;
      case "checkListDetails":
        return checklistPages;
      case "spaceDetails":
        return spacePages;
      default:
        return {};
    }
  };

  const subModulePages = getSubModulePages();

  console.log(" subModulePages for checking name display", subModulePages);

  return (
    <div
      className="toggle-switch-with-modules"
      style={{ backgroundColor: "transparent", border: "none" }}
    >
      <div className="row">
        <div className="col-sm-6">
          <span>{label}</span>
        </div>
        <div
          className="col-sm-6"
          style={{ marginLeft: "150px", marginTop: "-25px" }}
        >
          <label className="switch">
            <input type="checkbox" checked={isChecked} onChange={onToggle} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>

      {pageType && Object.keys(modules).length > 0 && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            {Object.entries(subModulePages).map(([title, value]) => {
              console.log("SubModule Title:", title);
              console.log("SubModule Value:", value);

              return (
                <ToggleSubModuleSwitch
                  key={value}
                  isChecked={modules[value] || false}
                  label={title}
                  id={value}
                  onToggle={() => {
                    setSelectedUser((prevData) => ({
                      ...prevData,
                      modules: {
                        ...prevData.modules,
                        [pageType]: {
                          ...prevData.modules[pageType],
                          [value]: !prevData.modules[pageType][value],
                        },
                      },
                    }));
                  }}
                  onIconClick={() => handleIconClick(value)}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              );
            })}
          </div>
        </div>
      )}

      {showSubModuleModal && (
        <SubModuleModal
          selectedSiteId={selectedSiteId}
          show={showSubModuleModal}
          onHide={handleCloseSubModal}
          subModuleKey={selectedSubModule}
          pageType={pageType}
          user={user}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      )}
    </div>
  );
};

export default ToggleSwitchWithModules;
