import { Dialog, Step, StepConnector, StepLabel, Stepper } from '@mui/material';
import React, { useEffect } from 'react'
import { RxCross1 } from 'react-icons/rx';
import styles from "./AddnewPnm.module.css"
import FormHeadings from "../PNMAssets/FormHeadings";
import { BACKEND_URL } from "../../../Utilities/Constants";

const AddNewPnMForm = ({open,showForm,handleClose,showEditForm,showViewForm,handleSubmit,activeStep,formData,handleChange,filteredAssets,changeHandler,setViewingIndex,setViewMode,setEditMode,documentsData,errors,getDaysCountOptions,assetCodeData,equipment,id,docErrors,fileInputRef,viewMode,viewingIndex,handleBack,handleNext,editMode,tableRowData,setDocumentsData}) => {
  return (
    <Dialog
          open={open}
          disableEnforceFocus
          onClose={handleClose}
          maxWidth="md" 
          fullWidth 
          PaperProps={{
            style: {
              height: "1553px", 
              width: "90vw", 
              maxWidth: "none",
            },
          }}
        >
          <div className={styles.AddNewPnMFormDailogHeadingContainer}>
            <h3 className={styles.AddNewPnMFormDailogHeading}> Add New </h3>
            <RxCross1
              size={20}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                marginRight: "25px",
                color: "white",
              }}
              onClick={handleClose}
            />
          </div>
          <>
            {(!showEditForm && !showViewForm) && <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<StepConnector style={{ top: "20px" }} />}
              className={styles.AddNewPnMFormStepper}
              style={{
                borderBottom: "2px dotted gray",
                paddingBottom: "60px",
                paddingTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Step style={{ fontSize: "40px", minWidth: "80px" }}>
                <StepLabel
                  StepIconComponent={() => (
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        border: `2px solid #4C4C4C`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "18px",
                        color: activeStep === 0 ? "#fff" : "#4C4C4C",
                        backgroundColor:
                          activeStep === 0 ? "#4C4C4C" : "transparent",
                      }}
                    >
                      1
                    </div>
                  )}
                >
                  Equipment Type Details
                </StepLabel>
              </Step>
              <Step style={{ fontSize: "40px", minWidth: "80px" }}>
                <StepLabel
                  StepIconComponent={() => (
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        border: `2px solid ${
                          activeStep === 0 || activeStep === 1
                            ? "#4C4C4C"
                            : "#979797"
                        }`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "18px",
                        color: activeStep === 1 ? "#fff" : "#4C4C4C",
                        backgroundColor:
                          activeStep === 1 ? "#4C4C4C" : "transparent",
                      }}
                    >
                      2
                    </div>
                  )}
                >
                  Equipment Make Details
                </StepLabel>
              </Step>
              <Step style={{ fontSize: "40px", minWidth: "80px" }}>
                <StepLabel
                  StepIconComponent={() => (
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        border: `2px solid ${
                          activeStep < 2 ? "#4C4C4C" : "#979797"
                        }`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "18px",
                        color: activeStep === 2 ? "#fff" : "#4C4C4C",
                        backgroundColor:
                          activeStep === 2 ? "#4C4C4C" : "transparent",
                      }}
                    >
                      3
                    </div>
                  )}
                >
                  Confirm Details
                </StepLabel>
              </Step>
            </Stepper>}

            
              <form onSubmit={handleSubmit}>
                {activeStep === 0 ? (
                  <>
                    <div className={styles.MainContainer} style={showEditForm ? {marginTop:"40px"}:{}}>
                        <div className={styles.ContentContainer}>
                            <div className={styles.Content}>
                              <FormHeadings text={"Type:"}/>
                              <div className={styles.InputContainer}>
                                <select className={styles.InputField} 
                                onChange={handleChange} 
                                value={formData.type}
                                name="type"
                                >
                                  <option value="" hidden>Select Type</option>
                                  <option value="own">Own</option>
                                  <option value="hire">Hire</option>  
                                </select>
                                <p className={styles.AddNewPnMFormError}>{errors.type}</p>
                              </div>
                            </div>
                          
                            <div className={styles.Content}>
                              <FormHeadings text={" Equipment Type:"}/>
                              <div className={styles.InputContainer}>
                                <select className={styles.InputField} onChange={handleChange} 
                                name="equipmentType"
                                value={formData.equipmentType}>
                                  <option value="" hidden>Select Equipment</option>
                                  <option value="Vehicle">Vehicle</option>
                                  <option value="Machinery">Machinery</option>  
                                  <option value="Power Tools">Power Tools</option>  
                                  <option value="Distribution Board">Distribution Board</option>  
                                  <option value="Batching Plant">Batching Plant</option>  
                                </select>
                                <p className={styles.AddNewPnMFormError}>{errors.equipmentType}</p>
                              </div>
                            </div>
                          
                        </div>
                        <div className={styles.ContentContainer}>
                            <div className={styles.Content}>
                              <FormHeadings text={"Name/Asset Code:"}/>
                              <div className={styles.InputContainer}>
                              
                              <select className={styles.InputField}
                              name="assetCode"
                              value={formData.assetCode}
                              onChange={handleChange}
                              >
                               
                                <option value="" hidden>Select Asset Code/Name</option>
                                  {assetCodeData.length && equipment ?
                            assetCodeData
                            .filter((item) => item.type.includes(equipment))
                              .map((item) => (
                                <option
                                  value={item.assetCode + "/" + item.name}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              )):assetCodeData.map((item)=>(
                                <option
                                  value={item.assetCode + "/" + item.name}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              ))}
                              </select>
                              <p className={styles.AddNewPnMFormError}>{errors.assetCode}</p>
                              </div>
                            </div>
                          
                            <div className={styles.Content}>
                              <FormHeadings text={"Fuel/Not:"}/>
                              <div className={styles.InputContainer}>
                              <select
                            name="fuelRnot"
                            value={formData.fuelRnot}
                            onChange={handleChange}
                            className={styles.InputField}
                          >
                            <option value="">Select Type</option>
                            <option value="Petrol">Petrol</option>
                            <option value="Diesel">Diesel</option>
                            <option value="Power">Power</option>
                          </select>
                          <p className={styles.AddNewPnMFormError}>{errors.fuelRnot}</p>
                              </div>
                            </div>
                           
                        </div>
                        <div className={styles.ContentContainer}>
                            <div className={styles.Content}>
                              <FormHeadings text={"Make Name:"}/>
                              <div className={styles.InputContainer}>
                              <input
                            type="text"
                            className={styles.InputField}
                            name="makename"
                            placeholder="Enter Make Name"
                            value={formData.makename}
                            onChange={handleChange}
                            
                          />
                          <p className={styles.AddNewPnMFormError}>{errors.makename}</p>
                              </div>
                            </div>
                          
                            <div className={styles.Content}>
                              <FormHeadings text={"Model:"}/>
                              <div className={styles.InputContainer}>
                              <input
                            type="text"
                            className={styles.InputField}
                            name="modelname"
                            placeholder="Enter Model Name"
                            value={formData.modelname}
                            onChange={handleChange}
                            
                          />
                          <p className={styles.AddNewPnMFormError}>{errors.modelname}</p>
                              </div>
                            </div>
                        
                        </div>
                        <div className={styles.ContentContainer}>
                            <div className={styles.Content}>
                              <FormHeadings text={"Inspection:"}/>
                              <div className={styles.InputContainer}>
                                <select className={styles.InputField} onChange={handleChange} 
                                value={formData.inspections} name="inspections">
                                  <option value="" hidden>Select Yes/No</option>
                                  <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Not Applicable">N/A</option>
                              </select>

                            
                              <p className={styles.AddNewPnMFormError}>
                                {errors.inspections}
                              </p>
                              </div>
                            </div>
                          
                            {formData.inspections === "Yes" && <div className={styles.Content}>
                              <FormHeadings text={"Inspection Period:"}/>
                              <div className={styles.InputContainer} style={{display:"flex",position:"relative"}}>
                                <select 
                                name="inspectionsPeriodType"
                                value={formData.inspectionsPeriodType}
                                className={formData.inspectionsPeriodType?styles.SmallInputField:styles.InputField} onChange={handleChange}>
                                  <option value="" hidden>Select Inspection</option>
                                  <option value="Weeks">Weeks</option>
                                  <option value="Days">Days</option>  
                                  <option value="Years">Years</option>  
                                  <option value="Months">Months</option>  
                                </select>
                                {formData.inspectionsPeriodType && <select
                                    className={styles.SmallInputField}
                                      
                                      name={
                                        formData.inspectionsPeriodType === "Years"
                                          ? "inspectionYears"
                                          : formData.inspectionsPeriodType === "Days"
                                          ? "inspectionDays"
                                          : formData.inspectionsPeriodType === "Weeks"
                                          ? "inspectionWeeks"
                                          : "inspectionMonths"
                                      }
                                      value={
                                        formData.inspectionsPeriodType === "Years"
                                          ? formData.inspectionYears
                                          : formData.inspectionsPeriodType === "Days"
                                          ? formData.inspectionDays
                                          : formData.inspectionsPeriodType === "Weeks"
                                          ? formData.inspectionWeeks
                                          : formData.inspectionMonths
                                      }
                                      onChange={handleChange}
                                    >
                                      <option value="">
                                        {formData.inspectionsPeriodType === "Years"
                                          ? "Select Years"
                                          : formData.inspectionsPeriodType === "Days"
                                          ? "Select Days"
                                          : formData.inspectionsPeriodType === "Weeks"
                                          ? "Select Weeks"
                                          : "Select Months"}
                                      </option>
                              
                                      {Array.from(
                                        {
                                          length:
                                            formData.inspectionsPeriodType === "Years"
                                              ? 12
                                              : formData.inspectionsPeriodType === "Days"
                                              ? 7
                                              : formData.inspectionsPeriodType === "Weeks"
                                              ? 52
                                              : 12,
                                        },
                                        (_, i) => i + 1
                                      ).map((value) => (
                                        <option key={value} value={value}>
                                          {value}
                                        </option>
                                      ))}
                                </select>}

                              {formData.inspectionsPeriodType && <p className={styles.AddNewPnMFormError} style={{top:"100%"}}>
                                  {formData.inspectionsPeriodType === "Years"
                                    ? errors.inspectionYears
                                    : formData.inspectionsPeriodType === "Days"
                                    ? errors.inspectionDays
                                    : formData.inspectionsPeriodType === "Weeks"
                                    ? errors.inspectionWeeks
                                    : errors.inspectionMonths}
                                </p>}
                              {!formData.inspectionsPeriodType
                                && <p className={styles.AddNewPnMFormError} style={{top:"100%"}}>{errors.inspectionsPeriodType}</p>}
                            </div>
                          </div>}
                      </div>
                      <div className={styles.ContentContainer}>
                          <div className={styles.Content}>
                            <FormHeadings text={"Service:"}/>
                            <div className={styles.InputContainer}>
                              <select className={styles.InputField} onChange={handleChange} 
                              value={formData.services} name="services">
                                <option value="" hidden>Select Yes/No</option>
                                <option value="Yes">Yes</option>
                              <option value="No">No</option>
                              <option value="Not Applicable">N/A</option>
                            </select>
                            <p className={styles.AddNewPnMFormError}>
                              {errors.services}
                            </p>
                            </div>
                          </div>
                          {formData.services === "Yes" && <div className={styles.Content}>
                            <FormHeadings text={"Service Period:"}/>
                            <div className={styles.InputContainer} style={{display:"flex",position:"relative"}}>
                              <select 
                              name="servicePeriodType"
                              value={formData.servicePeriodType}
                              className={formData.servicePeriodType === "Both Months & Kilometers" ?styles.MnthsAndKmInputContainer:formData.servicePeriodType?styles.SmallInputField:styles.InputField} onChange={handleChange}>
                                <option value="" hidden>Select Service period</option>
                                <option value="Weeks">Weeks</option>
                                <option value="Days">Days</option>  
                                <option value="Hours">Hours</option>  
                                <option value="Months">Months</option>
                              <option value="Kilometers">Kilometers</option>
                              <option value="Both Months & Kilometers">
                                Both Months & Kilometers
                              </option>
                              </select>


                              {formData.servicePeriodType && (
                              <>
                                {formData.servicePeriodType === "Kilometers" 

                                    ? (
                                  <input
                                    type="text"
                                    className={styles.SmallInputField}
                                    name="kilometers"
                                    min="0"
                                    placeholder="Enter No Of KM"
                                    value={formData.kilometers}
                                    onChange={(e)=>{
                                        const value=e.target.value
                                        if (!isNaN(value) ) {
                                            handleChange(e);
                                          }
                                      }}
                                  />
                                ) :formData.servicePeriodType === "Both Months & Kilometers"?(
                                  <>
                                  <select
                                    className={styles.MnthsAndKmInputContainer}
                                    name="serviceMonths"
                                    value={formData.serviceMonths}
                                    onChange={handleChange}
                                  >
                                    <option value="" hidden>Select Months</option> 
                                    {Array.from({ length: 12 }, (_, index) => (
                                      <option key={index+1} value={index+1}>{index + 1}</option>
                                    ))}
                                  </select>
                                  {formData.serviceMonths && (
                                    <input
                                    type="text"
                                    className={styles.MnthsAndKmInputContainer}
                                    name="kilometers"
                                    min="0"
                                    placeholder="Enter No Of KM"
                                    value={formData.kilometers}
                                    onChange={(e)=>{
                                        const value=e.target.value
                                        if (!isNaN(value) ) {
                                            handleChange(e);
                                          }
                                      }}
                                  />
                                  )}
                                  </>
                                ): (
                                  <select
                                  className={styles.SmallInputField}

                                    name={
                                      formData.servicePeriodType === "Hours"
                                        ? "serviceHours"
                                        : formData.servicePeriodType === "Days"
                                        ? "serviceDays"
                                        : formData.servicePeriodType === "Weeks"
                                        ? "serviceWeeks"
                                        : "serviceMonths"
                                    }
                                    value={
                                      formData.servicePeriodType === "Hours"
                                        ? formData.serviceHours
                                        : formData.servicePeriodType === "Days"
                                        ? formData.serviceDays
                                        : formData.servicePeriodType === "Weeks"
                                        ? formData.serviceWeeks
                                        : formData.serviceMonths
                                    }
                                    onChange={handleChange}
                                  >
                                    <option value="">
                                      {formData.servicePeriodType === "Hours"
                                        ? "Select Hours"
                                        : formData.servicePeriodType === "Days"
                                        ? "Select Days"
                                        : formData.servicePeriodType === "Weeks"
                                        ? "Select Weeks"
                                        : "Select Months"}
                                    </option>
                                    {Array.from(
                                      {
                                        length:
                                          formData.servicePeriodType === "Hours"
                                            ? 24
                                            : formData.servicePeriodType === "Days"
                                            ? 7
                                            : formData.servicePeriodType === "Weeks"
                                            ? 52
                                            : 12,
                                      },
                                      (_, i) => i + 1
                                    ).map((value) => (
                                      <option key={value} value={value}>
                                        {value}
                                      </option>
                                    ))}
                                  </select>
                                )}
                                {formData.services === "Yes" && <p className={styles.AddNewPnMFormError} style={{top:"100%"}}>
                                  {formData.servicePeriodType === "Hours"
                                    ? errors.serviceHours
                                    : formData.servicePeriodType === "Days"
                                    ? errors.serviceDays
                                    : formData.servicePeriodType === "Weeks"
                                    ? errors.serviceWeeks
                                    : formData.servicePeriodType === "Kilometers" ? errors.kilometers
                                    :formData.servicePeriodType === "Both Months & Kilometers" ? !formData.serviceMonths?errors.serviceMonths:errors.kilometers
                                    : errors.serviceMonths}
                                </p>}
                              </>
                            )}

                            {!formData.servicePeriodType &&  <p className={styles.AddNewPnMFormError} style={{top:"100%"}}>{errors.servicePeriodType}</p>}
                            </div>
                          </div>}
                      </div>

                      <div className={styles.ContentContainer}>
                          <div className={styles.Content}>
                            <FormHeadings text={"Engine/Motor:"}/>
                            <div className={styles.InputContainer}>
                              <select className={styles.InputField}  
                                name="engineRmotor"
                                value={formData.engineRmotor}
                                onChange={handleChange}
                              >
                                <option value="">Select Type</option>
                                <option value="Engine">Engine</option>
                                <option value="Motor">Motor</option>
                              </select>
                              <p className={styles.AddNewPnMFormError}>
                                {errors.engineRmotor}
                              </p>
                            </div>
                          </div>
                          <div className={styles.Content}>
                            <FormHeadings text={"No of Engine/Motor:"}/>
                            <div className={styles.InputContainer}>
                            <select
                                name="selectNo"
                                value={formData.selectNo}
                                className={styles.InputField}
                                onChange={handleChange}
                              >
                                <option value="">Select No.of</option>
                                <option value="Single">Single</option>
                                <option value="Double">Double</option>
                              </select>
                              <p className={styles.AddNewPnMFormError}>
                                {errors.selectNo}
                              </p>
                            </div>
                          </div>
                      </div>

                      {formData.type === "own" && <>
                      <h3 className={styles.Heading}>Equipment Details</h3>


                      {(!formData.oldrNew || formData.oldrNew ==="New") && <div className={styles.ContentContainer}>
                            <div className={styles.FullContent}>
                              <FormHeadings text={"New/Old:"}/>
                              <div className={styles.InputContainer}>
                                <select className={styles.InputField} 
                                onChange={handleChange} 
                                value={formData.oldrNew}
                                name="oldrNew"
                                >
                                  <option value="" hidden>Select Type</option>
                                  <option value="">Select Old/New</option>
                                  <option value="Old">Old</option>
                                  <option value="New">New</option>
                                </select>  
                                <p className={styles.AddNewPnMFormError}>{errors.oldrNew}</p>
                              </div>
                            </div>

                        </div>}

                      {formData.oldrNew === "New" && <>


                          <div className={styles.ContentContainer}>
                            <div className={styles.Content}>
                              <FormHeadings text={"Purchase Date:"}/>
                              <div className={styles.InputContainer}>
                              <input
                                type="date"
                                name="newpurchaseDate"
                                value={formData.newpurchaseDate}
                                className={styles.InputField}
                                onClick={(e)=>e.stopPropagation()}
                                onChange={handleChange}
                              />
                              <p className={styles.AddNewPnMFormError}>
                                {errors.newpurchaseDate}
                              </p>
                              </div>
                            </div>
                            <div className={styles.Content}>
                              <FormHeadings text={"Purchase Price:"}/>
                              <div className={styles.InputContainer}>
                              <input
                                type="text"
                                name="newpurchasePrice"
                                placeholder="Enter Price"
                                value={formData.newpurchasePrice}
                                onClick={(e)=>e.stopPropagation()}

                                className={styles.InputField}
                                onChange={(e)=>{
                                    const value=e.target.value
                                    if (!isNaN(value) ) {
                                        handleChange(e);
                                      }
                                  }}
                              />
                              <p className={styles.AddNewPnMFormError}>
                                {errors.newpurchasePrice}
                              </p>
                              </div>
                            </div>
                        </div>

                        <div className={styles.ContentContainer}>
                          <div className={styles.CheckBoxContent}>
                            <div className={styles.CheckBox}>
                              <input type="checkbox"
                              onClick={(e)=>e.stopPropagation()}
                              name="newinsuranceY"
                              checked={formData.newinsuranceY}
                              value={formData.newinsuranceY}
                              onChange={handleChange}
                              />
                              <FormHeadings text={"Insurance:"}/>
                            </div>
                            <div>
                            {formData.newinsuranceY && <>
                            <select
                              className={styles.SmallInputField}
                              name="daysTypenewinsurance"
                              value={
                                formData.daysTypenewinsurance
                              }
                              onChange={handleChange}
                            >
                              <option value="">
                                Select Type
                              </option>
                              <option value="Week">
                                Week
                              </option>
                              <option value="Month">
                                Month
                              </option>
                              <option value="Days">
                                Days
                              </option>
                              <option value="Years">
                                Years
                              </option>
                            </select>
                            {formData.daysTypenewinsurance && (
                              <select
                              className={styles.SmallInputField}
                              name="daysCountnewinsurance"
                              value={
                                formData.daysCountnewinsurance
                              }
                              onChange={handleChange}

                            >
                              <option value="">
                                Select Count
                              </option>
                              {getDaysCountOptions(
                                formData.daysTypenewinsurance
                              ).map((count) => (
                                <option
                                  key={count}
                                  value={count}
                                >
                                  {count}
                                </option>
                              ))}
                            </select>
                            )}
                            {!formData.newinsuranceY?<p className={styles.AddNewPnMFormError}>{errors.newinsuranceY}</p>:!formData.daysTypenewinsurance?<p className={styles.AddNewPnMFormError}>{errors.daysTypenewinsurance}</p>:<p className={styles.AddNewPnMFormError}>{errors.daysCountnewinsurance}</p>}
                            </>}
                            </div>


                          </div>
                          <div className={styles.CheckBoxContent}>
                            <div className={styles.CheckBox}>
                              <input type="checkbox"
                              onClick={(e)=>e.stopPropagation()}
                              name="newguaranteeY"
                              checked={formData.newguaranteeY}
                              value={formData.newguaranteeY}
                              onChange={handleChange}
                              />
                              <FormHeadings text={"Guarantee:"}/>
                            </div>
                            <div>
                            {formData.newguaranteeY 
                            && <>
                            <select
                              className={styles.SmallInputField}
                              name="daysTypenewguarantee"
                              value={
                                formData.daysTypenewguarantee
                              }
                              onChange={handleChange}
                            >
                              <option value="">
                                Select Type
                              </option>
                              <option value="Week">
                                Week
                              </option>
                              <option value="Month">
                                Month
                              </option>
                              <option value="Days">
                                Days
                              </option>
                              <option value="Years">
                                Years
                              </option>
                            </select>
                            {formData.daysTypenewguarantee && (
                              <select
                              name="daysCountnewguarantee"
                              value={
                                formData.daysCountnewguarantee
                              }
                              onChange={handleChange}
                            className={styles.SmallInputField}
                            >
                              <option value="">
                                Select Count
                              </option>
                              {getDaysCountOptions(
                                formData.daysTypenewguarantee
                              ).map((count) => (
                                <option
                                  key={count}
                                  value={count}
                                >
                                  {count}
                                </option>
                              ))}
                            </select>
                            )}
                            {!formData.newguaranteeY?<p className={styles.AddNewPnMFormError}>{errors.newguaranteeY}</p>:!formData.daysTypenewguarantee?<p className={styles.AddNewPnMFormError}>{errors.daysTypenewguarantee}</p>:<p className={styles.AddNewPnMFormError}>{errors.daysCountnewguarantee}</p>}
                            </>}
                            </div>
                          </div>
                          <div className={styles.CheckBoxContent}>
                            <div className={styles.CheckBox}>
                              <input 
                              type="checkbox"
                              onClick={(e)=>e.stopPropagation()}
                              name="newwarrantyY"
                              checked={formData.newwarrantyY}
                              value={formData.newwarrantyY}
                              onChange={handleChange}
                              />
                              <FormHeadings text={"Warranty:"}/>
                            </div>
                            <div>
                            {formData.newwarrantyY && <>
                            <select
                              className={styles.SmallInputField}
                              name="daysTypenewwarranty"
                              value={
                                formData.daysTypenewwarranty
                              }
                              onChange={handleChange}
                            >
                              <option value="">
                                Select Type
                              </option>
                              <option value="Week">
                                Week
                              </option>
                              <option value="Month">
                                Month
                              </option>
                              <option value="Days">
                                Days
                              </option>
                              <option value="Years">
                                Years
                              </option>
                            </select>
                            {formData.daysTypenewwarranty && (
                              <select

                              className={styles.SmallInputField}
                              name="daysCountnewwarranty"
                              value={
                                formData.daysCountnewwarranty
                              }
                              onChange={handleChange}
                            >
                              <option value="">
                                Select Count
                              </option>
                              {getDaysCountOptions(
                                formData.daysTypenewwarranty
                              ).map((count) => (
                                <option
                                  key={count}
                                  value={count}
                                >
                                  {count}
                                </option>
                              ))}
                            </select>
                            )}
                            {!formData.newwarrantyY?<p className={styles.AddNewPnMFormError}>{errors.newwarrantyY}</p>:!formData.daysTypenewwarranty?<p className={styles.AddNewPnMFormError}>{errors.daysTypenewwarranty}</p>:<p className={styles.AddNewPnMFormError}>{errors.daysCountnewwarranty}</p>}
                            </>}
                            </div>

                          </div>
                        </div>
                      </>}
                      {/* ----------------- */}

                      {formData.oldrNew === "Old" && <>
                        <div className={styles.ContentContainer}>
                            <div className={styles.Content}>
                              <FormHeadings text={"New/Old:"}/>
                              <div className={styles.InputContainer}>
                                <select className={styles.InputField} 
                                onChange={handleChange} 
                                value={formData.oldrNew}
                                name="oldrNew"
                                >
                                  <option value="" hidden>Select Type</option>
                                  <option value="">Select Old/New</option>
                                  <option value="Old">Old</option>
                                  <option value="New">New</option>
                                </select>  
                                <p className={styles.AddNewPnMFormError}>{errors.oldrNew}</p>
                              </div>
                            </div>
                            <div className={styles.Content}>
                              <FormHeadings text={"Purchase Date:"}/>
                              <div className={styles.InputContainer}>
                                <input
                                      type="date"
                                      name="oldPurchaseDate"
                                      value={formData.oldPurchaseDate}
                                      className={styles.InputField}
                                      onClick={(e)=>e.stopPropagation()}
                                      onChange={handleChange}
                                    />
                                    <p className={styles.AddNewPnMFormError}>
                                      {errors.oldPurchaseDate}
                                    </p>
                              </div>
                            </div>
                        </div>



                        <div className={styles.ContentContainer}>
                            <div className={styles.Content}>
                              <FormHeadings text={"Previous Purchase Price:"}/>
                              <div className={styles.InputContainer}>
                              <input
                                type="text"
                                placeholder="Enter Price"
                                name="oldPreviousPurchasePrice"
                                value={formData.oldPreviousPurchasePrice}
                                className={styles.InputField}
                                onChange={(e)=>{
                                    const value=e.target.value
                                    if (!isNaN(value) ) {
                                        handleChange(e);
                                      }
                                  }}
                              />
                              <p className={styles.AddNewPnMFormError}>
                                {errors.oldPreviousPurchasePrice}
                              </p>
                              </div>
                            </div>
                            <div className={styles.Content}>
                              <FormHeadings text={"Previous Purchase Date:"}/>
                              <div className={styles.InputContainer}>
                              <input
                                type="date"
                                name="oldPreviousServiceDate"

                                value={formData.oldPreviousServiceDate}
                                className={styles.InputField}
                                onClick={(e)=>e.stopPropagation()}
                                onChange={handleChange}
                              />
                              <p className={styles.AddNewPnMFormError}>
                                {errors.oldPreviousServiceDate}
                              </p>
                              </div>
                            </div>
                        </div>

                        <div className={styles.ContentContainer}>
                          <div className={styles.CheckBoxContent}>
                            <div className={styles.CheckBox}>
                              <input type="checkbox"
                              onClick={(e)=>e.stopPropagation()}
                              name="oldinsuranceY"
                              checked={formData.oldinsuranceY}
                              value={formData.oldinsuranceY}
                              onChange={handleChange}
                              />
                              <FormHeadings text={"Insurance:"}/>
                            </div>
                            <div>
                            {formData.oldinsuranceY && <>
                            <select
                              className={styles.SmallInputField}
                              name="daysTypeoldinsurance"
                              value={
                                formData.daysTypeoldinsurance
                              }
                              onChange={handleChange}
                            >
                              <option value="">
                                Select Type
                              </option>
                              <option value="Week">
                                Week
                              </option>
                              <option value="Month">
                                Month
                              </option>
                              <option value="Days">
                                Days
                              </option>
                              <option value="Years">
                                Years
                              </option>
                            </select>
                            {formData.daysTypeoldinsurance && (
                              <select
                              className={styles.SmallInputField}
                              name="daysCountoldinsurance"
                              value={
                                formData.daysCountoldinsurance
                              }
                              onChange={handleChange}

                            >
                              <option value="">
                                Select Count
                              </option>
                              {getDaysCountOptions(
                                formData.daysTypeoldinsurance
                              ).map((count) => (
                                <option
                                  key={count}
                                  value={count}
                                >
                                  {count}
                                </option>
                              ))}
                            </select>
                            )}
                            {!formData.oldinsuranceY?<p className={styles.AddNewPnMFormError}>{errors.oldinsuranceY}</p>:!formData.daysTypeoldinsurance?<p className={styles.AddNewPnMFormError}>{errors.daysTypeoldinsurance}</p>:<p className={styles.AddNewPnMFormError}>{errors.daysCountoldinsurance}</p>}
                            </>}
                            </div>

                          </div>
                          <div className={styles.CheckBoxContent}>
                            <div className={styles.CheckBox}>
                              <input type="checkbox"
                              onClick={(e)=>e.stopPropagation()}
                              name="oldguaranteeY"
                              checked={formData.oldguaranteeY}
                              value={formData.oldguaranteeY}
                              onChange={handleChange}
                              />
                              <FormHeadings text={"Guarantee:"}/>
                            </div>
                            <div>
                            {formData.oldguaranteeY && <>
                            <select
                              className={styles.SmallInputField}
                              name="daysTypeoldguarantee"
                              value={
                                formData.daysTypeoldguarantee
                              }
                              onChange={handleChange}
                            >
                              <option value="">
                                Select Type
                              </option>
                              <option value="Week">
                                Week
                              </option>
                              <option value="Month">
                                Month
                              </option>
                              <option value="Days">
                                Days
                              </option>
                              <option value="Years">
                                Years
                              </option>
                            </select>
                            {formData.daysTypeoldguarantee && (
                              <select
                              name="daysCountoldguarantee"
                              value={
                                formData.daysCountoldguarantee
                              }
                              onChange={handleChange}
                            className={styles.SmallInputField}
                            >
                              <option value="">
                                Select Count
                              </option>
                              {getDaysCountOptions(
                                formData.daysTypeoldguarantee
                              ).map((count) => (
                                <option
                                  key={count}
                                  value={count}
                                >
                                  {count}
                                </option>
                              ))}
                            </select>
                            )}
                            {!formData.oldguaranteeY?<p className={styles.AddNewPnMFormError}>{errors.oldguaranteeY}</p>:!formData.daysTypeoldguarantee?<p className={styles.AddNewPnMFormError}>{errors.daysTypeoldguarantee}</p>:<p className={styles.AddNewPnMFormError}>{errors.daysCountoldguarantee}</p>}
                            </>}
                            </div>

                          </div>


                          <div className={styles.CheckBoxContent}>
                            <div className={styles.CheckBox}>
                              <input 
                              type="checkbox"
                              onClick={(e)=>e.stopPropagation()}
                              name="oldwarrantyY"
                              checked={formData.oldwarrantyY}

                              value={formData.oldwarrantyY}
                              onChange={handleChange}
                              />
                              <FormHeadings text={"Warranty:"}/>
                            </div>
                            <div>
                            {formData.oldwarrantyY && <>
                            <select
                              className={styles.SmallInputField}
                              name="daysTypeoldwarranty"
                              value={
                                formData.daysTypeoldwarranty
                              }
                              onChange={handleChange}
                            >
                              <option value="">
                                Select Type
                              </option>
                              <option value="Week">
                                Week
                              </option>
                              <option value="Month">
                                Month
                              </option>
                              <option value="Days">
                                Days
                              </option>
                              <option value="Years">
                                Years
                              </option>
                            </select>
                            {formData.daysTypeoldwarranty && (
                              <select

                              className={styles.SmallInputField}
                              name="daysCountoldwarranty"
                              value={
                                formData.daysCountoldwarranty
                              }
                              onChange={handleChange}
                            >
                              <option value="">
                                Select Count
                              </option>
                              {getDaysCountOptions(
                                formData.daysTypeoldwarranty
                              ).map((count) => (
                                <option
                                  key={count}
                                  value={count}
                                >
                                  {count}
                                </option>
                              ))}
                            </select>


                            )}
                            {!formData.oldwarrantyY?<p className={styles.AddNewPnMFormError}>{errors.oldwarrantyY}</p>:!formData.daysTypeoldwarranty?<p className={styles.AddNewPnMFormError}>{errors.daysTypeoldwarranty}</p>:<p className={styles.AddNewPnMFormError}>{errors.daysCountoldwarranty}</p>}
                            </>}
                            </div>

                          </div>
                        </div>
                      </>}

                      </>}

                      {formData.type === "hire" && <>
                        <h3 className={styles.Heading}>Equipment Details</h3>
                        <div className={styles.ContentContainer}>
                              <div className={styles.Content}>
                                <FormHeadings text={"Vendor Name:"}/>
                                <div className={styles.InputContainer}>
                                <input
                                  type="text"
                                  name="hireVendorName"
                                  value={formData.hireVendorName}
                                  className={styles.InputField}
                                  onChange={handleChange}
                                  placeholder="Enter Name"
                                />
                                <p className={styles.AddNewPnMFormError}>
                                  {errors.hireVendorName}
                                </p>
                                </div>
                              </div>
                              <div className={styles.Content}>
                                <FormHeadings text={"Vendor Contact No:"}/>
                                <div className={styles.InputContainer}>
                                <input
                                  type="text"
                                  name="hireVendorNumber"
                                  placeholder="Enter Price"
                                  value={formData.hireVendorNumber}
                                  className={styles.InputField}
                                  onChange={(e)=>{
                                    const value=e.target.value
                                    if (!isNaN(value) && value.length<=10) {
                                        handleChange(e);
                                      }
                                  }}
                                />
                                <p className={styles.AddNewPnMFormError}>
                                  {errors.hireVendorNumber}
                                </p>
                                </div>
                              </div>
                        </div>

                        <div className={styles.ContentContainer}>
                              <div className={styles.Content} style={{display:"flex"}}>
                                <FormHeadings text={"How Many Days:"}/>
                                <div className={styles.InputContainer}>
                                <select
                                  type="text"
                                  name="daysTypeHireHowManyDays"
                                  value={formData.daysTypeHireHowManyDays}
                                  className={!formData.daysTypeHireHowManyDays?styles.InputField:styles.SmallInputField}
                                  onChange={handleChange}
                                  placeholder="Enter Name"
                                >
                                  <option value="" hidden>Select Type</option>
                                  <option value="Week" >Week</option>
                                  <option value="Month" >Month</option>
                                  <option value="Days" >Days</option>
                                  <option value="Years" >Years</option>

                                </select>

                                { formData.daysTypeHireHowManyDays
                                && <input
                                  placeholder="Enter"
                                  name="daysCountHireHowManyDays"
                                  value={formData.daysCountHireHowManyDays}
                                  className={styles.SmallInputField}
                                  type="text"
                                  onChange={(e)=>{
                                    const value=e.target.value
                                    if (!isNaN(value) ) {
                                        handleChange(e);
                                      }
                                  }}
                                />}

                                <p className={styles.AddNewPnMFormError}>
                                  {!formData.daysTypeHireHowManyDays?errors.daysTypeHireHowManyDays:errors.daysCountHireHowManyDays}
                                </p>
                                </div>
                              </div>

                              <div className={styles.Content}>
                                <FormHeadings text={"Rate:"}/>
                                <div className={styles.InputContainer} style={{display:"flex",position:"relative"}}>
                                <select
                                  name="hireCharges"
                                  value={formData.hireCharges}
                                  onChange={handleChange}
                                  className={!formData.hireCharges ? styles.InputField : styles.SmallInputField}
                                >
                                  <option value="" hidden>Select type</option>
                                  <option value="Daily">Daily</option>
                                  <option value="Hourly">Hourly</option>
                                </select>

                                {formData.hireCharges && <input
                                className={styles.SmallInputField}
                                name="hirePrice"
                                value={formData.hirePrice}
                                placeholder="Enter rate"
                                type="text"
                                onChange={(e)=>{
                                    const value=e.target.value
                                    if (!isNaN(value) ) {
                                        handleChange(e);
                                      }
                                  }}
                                />}
                                <p className={styles.AddNewPnMFormError}>
                                  {!formData.hireCharges?errors.hireCharges:errors.hirePrice}
                                </p>
                                </div>
                              </div>
                        </div>

                        <div className={styles.ContentContainer}>
                              <div className={styles.Content}>
                                <FormHeadings text={"Previous Serivce Date:"}/>
                                <div className={styles.InputContainer}>
                                <input
                                  type="date"
                                  name="hirePreviousServiceDate"
                                  value={formData.hirePreviousServiceDate}
                                  className={styles.InputField}
                                  onChange={handleChange}
                                  onClick={(e)=>e.stopPropagation()}
                                  placeholder="Enter Name"
                                />
                                <p className={styles.AddNewPnMFormError}>
                                  {errors.hirePreviousServiceDate}
                                </p>
                                </div>
                              </div>


                        </div>

{/* ------------------------------------------------- */}
                      <div className={styles.ContentContainer}>
                          <div className={styles.CheckBoxContent}>
                            <div className={styles.CheckBox}>
                              <input type="checkbox"
                              onClick={(e)=>e.stopPropagation()}
                              name="hireInsurance"
                              checked={formData.hireInsurance}
                              value={formData.hireInsurance}
                              onChange={handleChange}
                              />
                              <FormHeadings text={"Insurance:"}/>
                            </div>
                            <div>
                            {formData.hireInsurance && <>
                            <select
                              className={styles.SmallInputField}
                              name="daysTypeHireInsurance"
                              value={
                                formData.daysTypeHireInsurance
                              }
                              onChange={handleChange}
                            >
                              <option value="">
                                Select Type
                              </option>
                              <option value="Week">
                                Week
                              </option>
                              <option value="Month">
                                Month
                              </option>
                              <option value="Days">
                                Days
                              </option>
                              <option value="Years">
                                Years
                              </option>
                            </select>
                            {formData.daysTypeHireInsurance && (
                              <select
                              className={styles.SmallInputField}
                              name="daysCountHireInsurance"
                              value={
                                formData.daysCountHireInsurance
                              }
                              onChange={handleChange}

                            >
                              <option value="">
                                Select Count
                              </option>
                              {getDaysCountOptions(
                                formData.daysTypeHireInsurance
                              ).map((count) => (
                                <option
                                  key={count}
                                  value={count}
                                >
                                  {count}
                                </option>
                              ))}
                            </select>
                            )}
                            {!formData.hireInsurance?<p className={styles.AddNewPnMFormError}>{errors.hireInsurance}</p>:!formData.daysTypeHireInsurance?<p className={styles.AddNewPnMFormError}>{errors.daysTypeHireInsurance}</p>:<p className={styles.AddNewPnMFormError}>{errors.daysCountHireInsurance}</p>}
                            </>}
                            </div>

                          </div>
                          <div className={styles.CheckBoxContent}>
                             <div className={styles.CheckBox}>
                              <input type="checkbox"
                              onClick={(e)=>e.stopPropagation()}
                              name="hireGuarantee"
                              checked={formData.hireGuarantee}
                              value={formData.hireGuarantee}
                              onChange={handleChange}
                              />
                              <FormHeadings text={"Guarantee:"}/>
                            </div>
                            <div>
                            {formData.hireGuarantee && <>
                            <select
                              className={styles.SmallInputField}
                              name="daysTypeHireGuarantee"
                              value={
                                formData.daysTypeHireGuarantee
                              }
                              onChange={handleChange}
                            >
                              <option value="">
                                Select Type
                              </option>
                              <option value="Week">
                                Week
                              </option>
                              <option value="Month">
                                Month
                              </option>
                              <option value="Days">
                                Days
                              </option>
                              <option value="Years">
                                Years
                              </option>
                            </select>
                            {formData.daysTypeHireGuarantee && (
                              <select
                              name="daysCountHireGuarantee"
                              value={
                                formData.daysCountHireGuarantee
                              }
                              onChange={handleChange}
                            className={styles.SmallInputField}
                            >
                              <option value="">
                                Select Count
                              </option>
                              {getDaysCountOptions(
                                formData.daysTypeHireGuarantee
                              ).map((count) => (
                                <option
                                  key={count}
                                  value={count}
                                >
                                  {count}
                                </option>
                              ))}
                            </select>
                            )}
                            {!formData.hireGuarantee?<p className={styles.AddNewPnMFormError}>{errors.hireGuarantee}</p>:!formData.daysTypeHireGuarantee?<p className={styles.AddNewPnMFormError}>{errors.daysTypeHireGuarantee}</p>:<p className={styles.AddNewPnMFormError}>{errors.daysCountHireGuarantee}</p>}
                            </>}
                            </div>

                          </div>


                          <div className={styles.CheckBoxContent}>
                            <div className={styles.CheckBox}>
                              <input 
                              type="checkbox"
                              onClick={(e)=>e.stopPropagation()}
                              name="hireWarranty"
                              checked={formData.hireWarranty}
                              value={formData.hireWarranty}
                              onChange={handleChange}
                              />
                              <FormHeadings text={"Warranty:"}/>
                            </div>
                            <div>
                            {formData.hireWarranty && <>
                            <select
                              className={styles.SmallInputField}
                              name="daysTypeHireWarranty"
                              value={
                                formData.daysTypeHireWarranty
                              }
                              onChange={handleChange}
                            >
                              <option value="">
                                Select Type
                              </option>
                              <option value="Week">
                                Week
                              </option>
                              <option value="Month">
                                Month
                              </option>
                              <option value="Days">
                                Days
                              </option>
                              <option value="Years">
                                Years
                              </option>
                            </select>
                            {formData.daysTypeHireWarranty && (
                              <select

                              className={styles.SmallInputField}
                              name="daysCountHireWarranty"
                              value={
                                formData.daysCountHireWarranty
                              }
                              onChange={handleChange}
                            >
                              <option value="">
                                Select Count
                              </option>
                              {getDaysCountOptions(
                                formData.daysTypeHireWarranty
                              ).map((count) => (
                                <option
                                  key={count}
                                  value={count}
                                >
                                  {count}
                                </option>
                              ))}
                            </select>


                            )}
                            {!formData.hireWarranty?<p className={styles.AddNewPnMFormError}>{errors.hireWarranty}</p>:!formData.daysTypeHireWarranty?<p className={styles.AddNewPnMFormError}>{errors.daysTypeHireWarranty}</p>:<p className={styles.AddNewPnMFormError}>{errors.daysCountHireWarranty}</p>}
                            </>}
                            </div>

                          </div>
                        </div>

                      </>}




                  </div>
                </>
              ) 
              : activeStep === 1 ? (
                <div>
                  <div className={`${styles.AddNewPnMFormDocContainer} row`}>
                    {console.log(filteredAssets,"inside activeStep1")}
                  {  filteredAssets
                      .find((asset) => asset._id === id).documents[0]!=='' && formData.assetCode &&
                      
                    filteredAssets
                      .find((asset) => asset._id === id)
                      ?.documents?.map((doc, index) => (
                        <div key={index} className={styles.AddNewPnMFormMainFieldContainer}>
                          <label style={{paddingLeft:"16px",paddingRight:"16px"}} name={`documentName_${index}`} value={documentsData[index]?.documentName || ""}>{doc}:</label>


                          <div className={styles.AddNewPnMFormDocFieldContainer}>
                            {/* Document Number */}
                            <div className={styles.AddnewPnmDoc}>
                              <input
                                type="text"
                                name={`documentNo_${index}`}
                                placeholder="Enter the Document No"
                                className={styles.AddnewPnmInputFields}
                                value={documentsData[index]?.documentNo || ""}
                                onChange={(e) => changeHandler(e, index)}
                              />
                              {docErrors[index]?.documentNo && (
                                <p className={styles.AddNewPnMFormError}>{docErrors[index]?.documentNo}</p>
                              )}
                            </div>

                            {/* Registration Date */}
                            <div className={styles.AddnewPnmDoc}>
                              <input
                                type="date"
                                name={`regDate_${index}`}
                                className={styles.AddnewPnmInputFields}
                                value={documentsData[index]?.regDate || ""}
                                onChange={(e) => changeHandler(e, index)}
                                onClick={(e)=>e.stopPropagation()}
                              />
                              {docErrors[index]?.regDate && (
                                <p className={styles.AddNewPnMFormError}>{docErrors[index]?.regDate}</p>
                              )}
                            </div>

                            {/* Expiry Date */}
                            <div className={styles.AddnewPnmDoc}>
                              <input
                                type="date"
                                name={`expDate_${index}`}
                                className={styles.AddnewPnmInputFields}
                                value={documentsData[index]?.expDate || ""}
                                onChange={(e) => changeHandler(e, index)}
                                onClick={(e)=>e.stopPropagation()}
                              />
                              
                              {docErrors[index]?.expDate && (
                                <p className={styles.AddNewPnMFormError}>{docErrors[index]?.expDate}</p>
                              )}
                            </div>
{}
                            {/* File Upload */}
                            {!showEditForm &&
                            <div className={styles.AddnewPnmDoc}>
                              <input
                                type="file"
                                ref={fileInputRef} 
                                className={styles.AddnewPnmInputFields}
                                name={`document_${doc}`}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => changeHandler(e, index)}
                              />
                              {docErrors[index]?.file && (
                                <p className={styles.AddNewPnMFormError}>{docErrors[index]?.file}</p>
                              )}
                            </div>
                             }
                          </div>

                        {showEditForm && documentsData[index]?.documentFile ? (
        <div style={{ padding: '16px', borderBottom: "1px dotted lightgray" }} key={index}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p >
          File: {documentsData[index]?.documentFile 
    ? documentsData[index].documentFile.split('/').pop() 
    : "No file uploaded"}</p>
            <div>
              <button
                onClick={(e) => {
                  setViewingIndex(index);
                  setViewMode(true); 
                  setEditMode(false);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  marginRight: "10px"
                }}
              >
                View
              </button>
              <button
                className="edit-icon"
                onClick={(e) => {
                  setEditMode(true);
                  setViewMode(false);
                  setViewingIndex(index);
                  if (fileInputRef.current) {
                    fileInputRef.current.click();
                  }
                  e.preventDefault();
                  e.stopPropagation();
                }}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  marginRight: "10px"
                }}
                
              >
                Edit
              </button>
            </div>
          </div>
   {viewMode &&viewingIndex === index && documentsData[index]?.documentFile && (
  <div>
    {documentsData[index].documentFile.match(/\.(jpeg|jpg|gif|png)$/) ? (
      <img
        src={`${BACKEND_URL}${documentsData[index].documentFile}`}
        alt={`Document ${index}`}
        className="document-image"
        style={{ width: "100px", height: "auto", marginBottom: "10px" }}
      />
    ) : documentsData[index].documentFile.match(/\.pdf$/) ? (
      <iframe
        src={`${BACKEND_URL}${documentsData[index].documentFile}`}
        title={`Document ${index}`}
        style={{ width: "100%", height: "500px", marginBottom: "10px" }}
      ></iframe>
    ) : null}
  </div>
)}
          {editMode && viewingIndex === index && (
            <div className={styles.AddnewPnmDoc}>
              <input
                type="file"
                ref={fileInputRef} 
                className={styles.AddnewPnmInputFields}
                name={`document_${index}`}
                style={{ display: "none" }}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => changeHandler(e, index)}
              />
              {docErrors[index]?.file && (
                <p className={styles.AddNewPnMFormError}>{docErrors[index]?.file}</p>
              )}
            </div>
          )}

        </div>
      ):("")}

      

                        </div>
                      ))}



                  </div>
                </div>

              ) : activeStep === 2 ? (
                <>

                  <section className={styles.AddNewPnMFormViewContainer}>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Type</strong>
                    </p>
                    :<p>{formData.type}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Fuel/Not</strong>
                    </p>
                    :<p>{formData.fuelRnot}</p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Name</strong>
                    </p>
                    :<p>{formData.name}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Make Name</strong>
                    </p>
                    :<p>{formData.makename}</p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Model</strong>{" "}
                    </p>
                    :<p>{formData.modelname}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Inspection</strong>
                    </p>
                    :<p>{formData.inspections}</p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>
                        select Engine / Motor
                      </strong>
                    </p>
                    :<p>{formData.engineRmotor}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Subcode</strong>
                    </p>
                    :<p>{formData.subCode}</p>  
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>Insurance</strong>
                    </p>
                    :<p>{formData.oldinsuranceY?formData.daysCountoldinsurance+" "+formData.daysTypeoldinsurance:formData.newinsuranceY?formData.daysCountnewinsurance+" "+formData.daysTypenewinsurance:formData.hireInsurance?formData.daysCountHireInsurance+" "+formData.daysTypeHireInsurance:"No"
                    }</p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>New / Old</strong>
                    </p>
                    :<p>{formData.oldrNew}</p>
                    <p></p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>
                        Purchase Date
                      </strong>
                    </p>
                    :
                    <p>
                      {formData.oldrNew === "New"
                        ? formData.newpurchaseDate
                        : formData.oldPurchaseDate}
                    </p>
                    <p>
                      <strong style={{ color: "#4D4D4D" }}>
                        Purchase Price
                      </strong>
                    </p>
                    :
                    <p>
                      {formData.oldrNew === "New"
                        ? formData.newpurchasePrice
                        : formData.oldPreviousPurchasePrice}
                    </p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>


                  </section>

                  
                    {console.log(filteredAssets,"fitl")}
                    {/* filteredAssets
                        .find((asset) => asset._id === id).documents[0]!=='' &&  */}

                  
                    {  
                      formData.document && formData.document.length > 0
                      ? formData.document.map((doc, index) => (
                    
                                         
                        <section className={styles.AddNewPnMFormViewContainer}>
                        <p>
                          <strong style={{ color: "#4D4D4D" }}>RC:</strong>
                        </p>
                        :<p>{doc.documentNo || "N/A"}</p>
                        <p></p>
                        <p>
                          <strong style={{ color: "#4D4D4D" }}>Date of Regn:</strong>
                        </p>
                        :<p>{doc.regDate || "N/A"}</p>
                        <p>
                          <strong style={{ color: "#4D4D4D" }}>Regn Validity:</strong>
                        </p>
                        :<p>{doc.expDate || "N/A"}</p>
                        <p></p>
                        <p>
                          <strong style={{ color: "#4D4D4D" }}>Chosen File:</strong>
                        </p>
                        :<p>{doc?.file?.name?doc?.file?.name:doc?.documentFile?doc.documentFile.split("-",2)[1]: "N/A"}</p>
                        
                        
  
                      </section>)):<p style={{padding:"16px"}}>No Document Available</p>
                    }
                    
                  </>
                ) : null}
                {!showViewForm && <div className={styles.AddnewPnmFormBackAndNext}>
                  <button
                    type="button"
                    onClick={handleBack}
                    style={{
                      marginBottom: "20px",
                      padding: "10px",
                      borderRadius: "5px",
                      width: "175px",
                      backgroundColor: "white",
                      color: "var(--primary-color)",
                      border: "2px solid var(--primary-color)",
                    }}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={handleNext}
                    style={{
                      marginBottom: "20px",
                      padding: "10px",
                      borderRadius: "5px",
                      width: "175px",
                      backgroundColor: "var(--primary-color)",
                      color: "white",
                    }}

                >
                  {activeStep === 2 ? "CONFIRM DETAILS" : "Next"}
                </button>
              </div>}
            </form>
          </>

        </Dialog>
  )
}

export default AddNewPnMForm