import React, { useState, useEffect } from "react";
import "./TaskUpdates.css";
import AllTab from "./AllTab/AllTab";
import DelayedTab from "./DelayedTab/DelayedTab";
import RedoTab from "./RedoTab/RedoTab";
import InProgressTab from "./InProgressTab/InProgressTab";
import CompleteTab from "./CompleteTab/CompleteTab";

const TaskUpdates = (siteId) => {
  const [selectedTab, setSelectedTab] = useState("All");

  useEffect(() => {
    console.log("TaskUpdates siteId:", siteId);
  }, [siteId]);

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  return (
    <div className="containerDrawingPagesArchitecture">
      <ul className="tabsArchitectureTabs">
        <li
          className={`tabArchitectureTab ${
            selectedTab === "All" ? "active" : ""
          }`}
          onClick={() => handleTabClick("All")}
        >
          All
        </li>
        <li
          className={`tabArchitectureTab ${
            selectedTab === "Delayed" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Delayed")}
        >
          Delayed
        </li>
        <li
          className={`tabArchitectureTab ${
            selectedTab === "Redo" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Redo")}
        >
          Redo
        </li>
        <li
          className={`tabArchitectureTab ${
            selectedTab === "InProgress" ? "active" : ""
          }`}
          onClick={() => handleTabClick("InProgress")}
        >
          In Progress
        </li>
        <li
          className={`tabArchitectureTab ${
            selectedTab === "Complete" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Complete")}
        >
          Complete
        </li>
      </ul>

      <div className="tabpanelArchitecture">
        <div className="tabpanel-content">
          {selectedTab === "All" && <AllTab site={siteId} />}
          {selectedTab === "Delayed" && <DelayedTab />}
          {selectedTab === "Redo" && <RedoTab />}
          {selectedTab === "InProgress" && <InProgressTab />}
          {selectedTab === "Complete" && <CompleteTab />}
        </div>
      </div>
    </div>
  );
};

export default TaskUpdates;
