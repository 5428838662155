import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const MultiSelectSearch = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (fromButtonClick) => {
    if (fromButtonClick && !isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const filteredList = props.listItems.filter((item) => {
    if (typeof item.name !== "string") {
      return false;
    }
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleSearch = (event) => setSearchTerm(event.target.value);

  const handleSelectAll = () => {
    if (props.selectedIds.length === filteredList.length) {
      props.clearAll();
    } else {
      props.addAll();
    }
  };

  const handleItemClick = (item) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    props.handleSelectedPropertyOption(
      item,
      props.type,
      props.selectedIds,
      props.setSelectedIds
    );

    const newTimeoutId = setTimeout(() => {
      setIsOpen(false);
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  return (
    <div style={{ width: "100%" }}>
      <label
        className="form-label"
        style={{ fontWeight: "bold", marginBottom: "0.5rem", marginLeft: "0" }}
      >
        {props.heading}
        {/* <span style={{ color: 'red' }}>*</span> */}
      </label>
      <div
        ref={dropdownRef}
        className="dropdown"
        style={{ width: "100%", position: "relative" }}
      >
        <button
          className="btn btn-outline-secondary dropdown-toggle"
          type="button"
          onClick={() => toggleDropdown(true)}
          style={{
            width: "100%",
            height: "50px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            {props.selectedIds.length > 0
              ? `${props.selectedIds.length} selected`
              : "Select"}
          </div>
          <span style={{ marginLeft: "auto" }} className="caret"></span>
        </button>
        <div
          className={`dropdown-menu${isOpen ? " show" : ""}`}
          style={{
            width: "100%",
            maxHeight: "40vh",
            overflowY: "auto",
            overflowX: "hidden",
            position: "absolute",
            zIndex: 600,
          }}
        >
          <div
            className="p-2"
            style={{
              position: "sticky",
              top: "2px",
              zIndex: 1,
              backgroundColor: "white",
            }}
          >
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
              style={{ marginTop: "-19px", width: "360px" }}
            />
          </div>
          <div className="dropdown-item" onClick={handleSelectAll}>
            <input
              type="checkbox"
              checked={props.selectedIds.length === filteredList.length}
              readOnly
            />{" "}
            Select All
          </div>
          {/* Render selected items first */}
          {props.selectedIds.map((selectedId) => {
            const selectedItem = filteredList.find(
              (item) => item.id === selectedId
            );
            if (selectedItem) {
              return (
                <div
                  key={selectedItem.id}
                  className={`dropdown-item${
                    props.selectedIds.includes(selectedItem.id) ? " active" : ""
                  }`}
                  onClick={() => handleItemClick(selectedItem)}
                  id={`tooltip-${selectedItem.id}`}
                >
                  <input
                    type="checkbox"
                    checked={props.selectedIds.includes(selectedItem.id)}
                    readOnly
                  />{" "}
                  {selectedItem.name}
                </div>
              );
            }
            return null;
          })}
          {/* Render remaining items */}
          {filteredList.map((option) => {
            if (!props.selectedIds.includes(option.id)) {
              return (
                <div
                  key={option.id}
                  className={`dropdown-item${
                    props.selectedIds.includes(option.id) ? " active" : ""
                  }`}
                  onClick={() => handleItemClick(option)}
                  id={`tooltip-${option.id}`}
                >
                  <input
                    type="checkbox"
                    checked={props.selectedIds.includes(option.id)}
                    readOnly
                  />{" "}
                  {option.name}
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default MultiSelectSearch;
