/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import SelectSite from "../../Utilities/SelectSite";
import { siteId, userInfo } from "../../atoms";
import Employee from "./Employee";
import Profile from "./Profile";
// import OrganisationChart from "./OrganisationChart";
import "./users.css";
import userDefault from "../../Images/userDefaultIcon.svg";

const Users = () => {
  const [value, setValue] = useState(0);
  const [site, setSite] = useRecoilState(siteId);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);

  const [tabEnableModules, setTabEnableModules] = useState({
    employee: true,
    profile: true,
    organizationChart: true,
  });

  useEffect(() => {
    console.log(currentUserInfo, "current info for User Module page");
    if (currentUserInfo && currentUserInfo.permittedSites) {
      const enabledModules = currentUserInfo.permittedSites.map(
        (site) => site.enableModules
      );
      setTabEnableModules({
        employee: enabledModules.some((module) => module.userDetails.employee),
        profile: enabledModules.some((module) => module.userDetails.profile),
        organizationChart: enabledModules.some(
          (module) => module.userDetails.organizationChart
        ),
      });
      console.log("Enabled Tabs: ", tabEnableModules);
    }
  }, [currentUserInfo]);

  const handleChange = (index) => {
    setValue(index);
  };

  const allTabsDisabled =
    !tabEnableModules.employee &&
    !tabEnableModules.profile &&
    !tabEnableModules.organizationChart;

  console.log(allTabsDisabled, "No enabled tabs found");

  if (site === "") {
    return <SelectSite />;
  }

  return (
    <>
      {allTabsDisabled ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <p
            style={{
              margin: "0 auto",
              color: "#E47331",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            You do not have the necessary permissions to view any User page
            tabs.
          </p>
          <p
            style={{
              margin: "0 auto",
              color: "#E47331",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Please contact your administrator for access rights.
          </p>
          <img
            src={userDefault}
            alt="User Default"
            style={{
              width: "350px",
              height: "350px",
              margin: "50px auto",
            }}
          />
        </div>
      ) : (
        <div className="users-container">
          <div className="tabs-container">
            <div className="tabs">
              {tabEnableModules.employee && (
                <div
                  className={`tab ${value === 0 ? "active" : ""}`}
                  onClick={() => handleChange(0)}
                >
                  Employee
                </div>
              )}
              {tabEnableModules.profile && (
                <div
                  className={`tab ${value === 1 ? "active" : ""}`}
                  onClick={() => handleChange(1)}
                >
                  Profile
                </div>
              )}
              {/* {tabEnableModules.organizationChart && (
                <div
                  className={`tab ${value === 2 ? "active" : ""}`}
                  onClick={() => handleChange(2)}
                >
                  Organisation Chart
                </div>
              )} */}
            </div>
          </div>

          {tabEnableModules.employee && value === 0 && (
            <TabPanel>
              <Employee />
            </TabPanel>
          )}
          {tabEnableModules.profile && value === 1 && (
            <TabPanel>
              <Profile />
            </TabPanel>
          )}
          {/* {tabEnableModules.organizationChart && value === 2 && (
            <TabPanel>
              <OrganisationChart />
            </TabPanel>
          )} */}
        </div>
      )}
    </>
  );
};

function TabPanel({ children }) {
  return (
    <div className="tabpanel">
      <div className="tabpanel-content">{children}</div>
    </div>
  );
}

export default Users;
