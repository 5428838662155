import React from 'react';
import ValueCard from './ValueCard';
import styles from './ValuesSection.module.css';

function ValuesSection() {
  const values = [
    { id: 1, iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/d18591659a7687a99f10009950c48bcdeed47cf37c048769755c03bd472a1112?apiKey=3e99c58a56f84e4cb0d84873c390b13e&&apiKey=3e99c58a56f84e4cb0d84873c390b13e", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium purus at blandit consequat." },
    { id: 2, iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/d18591659a7687a99f10009950c48bcdeed47cf37c048769755c03bd472a1112?apiKey=3e99c58a56f84e4cb0d84873c390b13e&&apiKey=3e99c58a56f84e4cb0d84873c390b13e", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium purus at blandit consequat." },
    { id: 3, iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/d18591659a7687a99f10009950c48bcdeed47cf37c048769755c03bd472a1112?apiKey=3e99c58a56f84e4cb0d84873c390b13e&&apiKey=3e99c58a56f84e4cb0d84873c390b13e", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium purus at blandit consequat." },
    { id: 4, iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/d18591659a7687a99f10009950c48bcdeed47cf37c048769755c03bd472a1112?apiKey=3e99c58a56f84e4cb0d84873c390b13e&&apiKey=3e99c58a56f84e4cb0d84873c390b13e", description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium purus at blandit consequat." }
  ];

  return (
    <section className={styles.valuesSection}>
      <h2 className={styles.sectionTitle}>Our Values</h2>
      <div className={styles.valuesGrid}>
        {values.map(value => (
          <ValueCard key={value.id} iconSrc={value.iconSrc} description={value.description} />
        ))}
      </div>
    </section>
  );
}

export default ValuesSection;