

import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import styles from "../Design/Design.module.css";
import Add from "../../../Images/Add.svg";
import Sub from "../../../Images/subtration.svg";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import "./Addtemporary.css"

const Addtemporary = (props) => {
  const { open, handleDialogClose, existingData } = props;
  console.log(existingData,"existingData")
  let webApi = new WebApimanager();
  
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [formData,setFormData] = useState({
    department:"",
    user:"",
    fromDate:"",
    endDate:"",
    allocateUser:""
  })
  const [allUser,setAllUsers] = useState([])
  const [errors, setErrors] = useState({});
  const handleClose = () => {
    handleDialogClose();
    setErrors({});
    setFormData({
      department: "",
      user: "",
      fromDate: "",
      toDate: "",
      allocateUser: "",
    });
  }; 

  const validate = () => {
    const newErrors = {};
    if (!formData.department) newErrors.department = "Department is required";
    if (!formData.user) newErrors.user = "User is required";
    if (!formData.fromDate) newErrors.fromDate = "From Date is required";
    if (!formData.toDate) newErrors.toDate = "To Date is required";
    if (!formData.allocateUser) newErrors.allocateUser = "Other employee is required";

    // Additional validation logic (e.g., date comparisons)
    if (formData.fromDate && formData.toDate && formData.fromDate > formData.toDate) {
      newErrors.toDate = "To Date cannot be earlier than From Date";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = async(e) => {
    e.preventDefault();
    if (validate()) {
      console.log("Form data is valid:", formData);
      
      try{
        const response = await webApi.post(`api/checklistTemporary`,formData)
        console.log(response,"responsepost")
      } catch(error){
        console.log(error.message)
      }
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getDepartmentData = async () => {
    try {
      setApiProcessing({ loader: true, message: "Loading data..." });
      const { department, fromDate, toDate } = formData;
      
      if (department && fromDate && toDate) {
        const response = await webApi.get(
          `api/checklistTemporary/usersByDepartment?department=${department}&fromDate=${fromDate}&toDate=${toDate}`
        );
        console.log(response); 

        if(response.status === 200 || response.status === 201){
          setAllUsers(response.data.data.users)
        }
        
      } else {
        console.log("Waiting for all fields to be filled.");
      }
    } catch (error) {
      console.error("Error fetching department data:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  useEffect(() => {
    // Trigger the API call when department, fromDate, or toDate changes
    if (formData.department && formData.fromDate && formData.toDate) {
      getDepartmentData();
    }
  }, [formData.department, formData.fromDate, formData.toDate]);


  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: '50px',
          }}
        >
          <p
            style={{width:"100%",marginTop:"8px", textAlign: "center", color: "white" }}
          >
            Create
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "300px" }}
        >
          <form onSubmit={handleSubmit}>
            <p>Q : Who is the Person taking Leave?</p>
            <div className="MainContainer">

              <div className="TempMainInputFieldContainer">
                <p>Department :</p>
                <div className="tempInputContainer">
                  <select type="text" name="department" value={formData.department} onChange={handleChange}>
                  <option value="">Select Department</option>
                      <option value="EHS">EHS</option>
                      <option value="PNM">PNM</option>
                      <option value="QC/QA">QC/QA</option>
                      <option value="Admin">Admin</option>
                      <option value="MEP">MEP</option>
                      <option value="CRMTECH">CRMTECH</option>
                  </select>
                  {errors.department && <p className="error">{errors.department}</p>}
                </div>
              </div>
              <div className="TempMainInputFieldContainer">
                <p>User :</p>
                <div className="tempInputContainer">
                  <select type="text" name="user" value={formData.user} onChange={handleChange}>
                  <option value="">Select User</option>
                    {allUser.map((users)=>(
                      <option key={users._id} value={users._id}>{users.firstName} {users.lastName}</option>                      
                      ))}
                  </select>
                  {errors.user && <p className="error">{errors.user}</p>}
                </div>
              </div>
            </div>
            <p>Q : Cite the duration of the leave?</p>
            <div className="MainContainer">

              <div className="TempMainInputFieldContainer">
                <p>fromDate :</p>
                <div className="tempInputContainer">
                  <input type="date" name="fromDate" value={formData.fromDate} onChange={handleChange}/>
                  {errors.fromDate && <p className="error">{errors.fromDate}</p>}
                </div>
              </div>
              <div className="TempMainInputFieldContainer">
                <p>ToDate :</p>
                <div className="tempInputContainer">
                  <input type="date" name="toDate" value={formData.toDate} onChange={handleChange}/>
                  {errors.toDate && <p className="error">{errors.toDate}</p>}
                </div>
              </div>
            </div>

            <p style={{fontWeight:"600"}}>Tasks:</p>
            <p>1. &nbsp;&nbsp; Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
            <p>2. &nbsp;&nbsp; Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
            <p>3. &nbsp;&nbsp; Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
            <p>Allocate this work to other employees:</p>
            <select type="text" className="tempEmpInput" name="allocateUser" value={formData.allocateUser} onChange={handleChange}>
            <option value="">Select User</option>
                  {allUser.map((users)=>(
                      <option key={users._id} value={users._id}>{users.firstName} {users.lastName}</option>                      
                      ))}
                  </select>
            
            {errors.allocateUser && <p style={{color:"red",fontSize:"14px"}}>{errors.allocateUser}</p>}

            <div className="tempBtnContainer">
              <button>SUBMIT</button>
            </div>
          </form>
         
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Addtemporary;
