export const todayDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};


export const formatDate = (dateString) => {
  if (!dateString) return '-';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
};


export const calculateDueDays = (acceptedROSubmissionDate, softCopySubmittedDate = null) => {
  if (!acceptedROSubmissionDate) return '-';

  const dateToCompare = softCopySubmittedDate ? new Date(softCopySubmittedDate) : new Date();
  const acceptedDate = new Date(acceptedROSubmissionDate);
  const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // convert time difference to days

  if (differenceInDays > 0) {
    return `${differenceInDays} days due`;
  } else if (differenceInDays < 0) {
    return `${Math.abs(differenceInDays)} days overdue`;
  } else {
    return 'Due today';
  }
};
