import React from "react";

const VillaTypeDetails = (props) => {
  const { formData, handleOpenAddDialog, handleChange, villaTypes, errors } =
    props;
  return (
    <div style={{ marginTop: "20px" }}>
      {formData.Vtypecommon === "Yes" &&
        formData.Vceller === "Yes" &&
        formData.VcommonFloor === "Yes" &&
        formData.VcommonCellar === "Yes" && (
          <div style={{ borderBottom: "1px dotted lightgray" }}>
            <h3>See the Villas Details</h3>

            <p>
              The venture having{" "}
              {formData.VnoOfVillas && `${formData.VnoOfVillas} - villas`}
              {formData.VtypeHK && ` and every villa is ${formData.VtypeHK}`}
              {formData.VnoOfFloorVillas &&
                ` with G+${formData.VnoOfFloorVillas} floors`}
              {formData.VnoOfCellar && ` and ${formData.VnoOfCellar} cellars`}
            </p>
          </div>
        )}
      {(formData.Vtypecommon === "No" ||
        formData.Vceller === "No" ||
        formData.VcommonFloor === "No" ||
        formData.VcommonCellar === "No") && (
        <>
          <div className="d-flex justify-between ">
            <h3>See the Villas Type Details</h3>
            <button
              style={{
                backgroundColor: "#4D4D4D",
                color: "white",
                height: "40px",
                width: "70px",
                marginLeft: "20px",
                alignSelf: "flex-end",
              }}
              onClick={() => handleOpenAddDialog()}
            >
              Add
            </button>
          </div>
          {villaTypes.length === 0 && (
            <p style={{ color: "gray", fontSize: "12px" }}>
              Note:-Please add the villa details
            </p>
          )}
          <div
            style={{
              borderBottom: "1px dotted lightgray",
              paddingBottom: "40px",
            }}
          >
            {formData.villas.map((villa, index) => {
              if (index % 2 === 0) {
                return (
                  <div key={index} className="d-flex justify-between mb-4">
                    <div style={{ flex: 1, marginRight: "10px" }}>
                      <div className="d-flex flex-col">
                        <label>Villa - {index + 1}</label>
                        {villaTypes.length > 0 && (
                          <select
                            value={villa.selectedType || ""}
                            onChange={(e) =>
                              handleChange(e, null, null, null, null, index)
                            }
                            style={{marginTop:'15px'}}
                          >
                            <option value="">Select Type</option>
                            {villaTypes.map((villaType, idx) => (
                              <option key={idx} value={villaType.Type}>
                                {` ${villaType.villaType} - ${villaType.noOfCellar} - ${villaType.noOfFloors}`}
                              </option>
                            ))}
                          </select>
                        )}
                        {errors[`Villa selectedType-${index}`] && (
                          <p style={{ color: "red" }}>
                            {errors[`Villa selectedType-${index}`]}
                          </p>
                        )}
                      </div>
                    </div>
                    {formData.villas[index + 1] && (
                      <div style={{ flex: 1 }}>
                        <div className="d-flex flex-col">
                          <label>Villa - {index + 2}</label>
                          {villaTypes.length > 0 && (
                            <select
                              value={
                                formData.villas[index + 1].selectedType || ""
                              }
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  null,
                                  null,
                                  null,
                                  null,
                                  index + 1
                                )
                              }
                              style={{marginTop:'15px'}}
                            >
                              <option value="">Select Type</option>
                              {villaTypes.map((villaType, idx) => (
                                <option key={idx} value={villaType.Type}>
                                  {`${villaType.villaType} - ${villaType.noOfCellar} - ${villaType.noOfFloors}`}
                                </option>
                              ))}
                            </select>
                          )}
                          {errors[`Villa selectedType-${index}`] && (
                            <p style={{ color: "red" }}>
                              {errors[`Villa selectedType-${index}`]}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
              return null; 
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default VillaTypeDetails;
