import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types"; // Import prop-types
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../../WebApiManager";
import { usePDF } from "react-to-pdf";
import { calculateDueDays, formatDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { siteId, userInfo } from "../../../atoms";
import "./TablePage.css";

const TablePage = (props) => {
  const { isOpen, handleDialogClose, popupcontent } = props;

  useEffect(() => {
    console.log("Select From/To:", popupcontent);
  }, [popupcontent]);

  const { toPDF, targetRef } = usePDF({ filename: "report.pdf" });

  const webApi = new WebApimanager();

  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [downloaded, setDownloaded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [site, setSite] = useRecoilState(siteId);
  const [siteData, setSiteData] = useState([]);
  const [issuedRo, setIssuedRo] = useState([]);
  const [acceptedRoHardCopyRevisions, setAcceptedRoHardCopyRevisions] =
    useState([]);
  const [
    pendingacceptedRoHardCopyRevisions,
    setPendingAcceptedRoHardCopyRevisions,
  ] = useState([]);
  const [acceptedRORevisions, setAcceptedRORevisionsInternal] = useState([]);
  const [pendingAcceptedRORevisions, setPendingAcceptedRORevisionsInternal] =
    useState([]);
  const [rfiData, setRfiData] = useState([]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1000;

  // Calculate total pages
  const totalPages = useMemo(
    () =>
      Math.ceil(
        (Array.isArray(tableData) ? tableData.length : 0) / itemsPerPage
      ),
    [tableData.length]
  );

  const tableType = popupcontent?.reportType || "defaultType";

  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const siteResponse = await webApi.get("api/sites/getAllSitesInfo");
        setSiteData(siteResponse.data.sites);
        console.log("Site Name Response:", siteResponse.data.sites);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSiteData();
  }, []);

  const matchedSite = siteData.find((siteItem) => siteItem._id === site);

  const now = new Date();

  const day = now.toLocaleDateString("en-US", { day: "numeric" });
  const month = now.toLocaleDateString("en-US", { month: "long" });
  const year = now.toLocaleDateString("en-US", { year: "numeric" });

  const time = now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  useEffect(() => {
    console.log("PopupContent Details:", popupcontent);

    if (
      (popupcontent.reportType &&
        popupcontent.designConsultant &&
        popupcontent.fromDate &&
        popupcontent.toDate) ||
      (popupcontent.month && popupcontent.year) ||
      popupcontent.timePeriod
    ) {
      const fetchData = async () => {
        try {
          let apiEndpoint;
          if (props.type === "architect") {
            apiEndpoint = "report";
          } else if (props.type === "SiteHead") {
            apiEndpoint = "SiteHeadReportsOfAllConsultants";
          } else {
            apiEndpoint = "RoReport";
          }

          let apiUrl = `api/pdf/${apiEndpoint}?reportType=${popupcontent.reportType}&designDrawingConsultantId=${popupcontent.designConsultant}`;

          if (popupcontent.siteId) {
            apiUrl += `&siteId=${popupcontent.siteId}`;
          }

          if (popupcontent.fromDate && popupcontent.toDate) {
            apiUrl += `&selectTimePeriod=byDate&fromDate=${popupcontent.fromDate}&toDate=${popupcontent.toDate}`;
          } else if (popupcontent.timePeriod === "fromBeginningToTillDate") {
            apiUrl += `&selectTimePeriod=${popupcontent.timePeriod}`;
          } else {
            apiUrl += `&selectTimePeriod=byMonth&month=${popupcontent.month}&year=${popupcontent.year}`;
          }

          const response = await webApi.get(apiUrl);
          const data = await response.data.data;

          if (props.type === "SiteHead" && popupcontent.fromtoType === "ro") {
            console.log("SiteHead data Analysis Data for the Site Head:", data);

            const acceptedRoHardCopyRevisionsInternal = data.filter(
              (item) =>
                item.acceptedROHardCopyRevisions &&
                item.acceptedROHardCopyRevisions.length > 0
            );

            const acceptedRORevisionsInternal = data.filter(
              (item) =>
                item.acceptedRORevisions && item.acceptedRORevisions.length > 0
            );

            const pendingAcceptedRoHardCopyRevisionsInternal = data.filter(
              (item) =>
                item.acceptedROHardCopyRevisions &&
                item.acceptedROHardCopyRevisions.length > 0
            );

            const pendingAcceptedRORevisionsInternal = data.filter(
              (item) =>
                item.acceptedRORevisions && item.acceptedRORevisions.length > 0
            );

            const rfiDataInternal = data;

            console.log(rfiDataInternal);

            setAcceptedRoHardCopyRevisions(acceptedRoHardCopyRevisionsInternal);
            setAcceptedRORevisionsInternal(acceptedRORevisionsInternal);
            setRfiData(rfiDataInternal);
            setPendingAcceptedRORevisionsInternal(
              pendingAcceptedRoHardCopyRevisionsInternal
            );
            setPendingAcceptedRoHardCopyRevisions(
              pendingAcceptedRORevisionsInternal
            );

            setLoaded(true);

            console.log(
              "Issued From acceptedRORevisionsInternal:",
              acceptedRORevisionsInternal
            );
            console.log(
              "Collected from acceptedRoHardCopyRevisionsInternal:",
              acceptedRoHardCopyRevisionsInternal
            );

            console.log("All RFI Data:", rfiDataInternal);

            console.log(
              "pending from acceptedRoHardCopyRevisionsInternal:",
              pendingAcceptedRoHardCopyRevisionsInternal
            );
            console.log(
              "pending from acceptedROSoftCopyRevisionsInternal:",
              pendingAcceptedRORevisionsInternal
            );
          }

          const startDate = new Date(data.startDate)
            .toISOString()
            .split("T")[0];
          const endDate = new Date(data.endDate).toISOString().split("T")[0];

          setStartDate(startDate);
          setEndDate(endDate);
          setTableData(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [popupcontent, props.type]);

  useEffect(() => {
    if (
      popupcontent.selectViewDownload === "Download" &&
      tableData.length > 0
    ) {
      console.log("popupcontent inside", popupcontent.fileFormat);
      switch (popupcontent.fileFormat) {
        case "PDF":
          toPDF().then(() => {
            setDownloaded(true);
            handleDialogClose();
          });
          break;

        case "XLS":
          downloadXLS();
          break;

        case "CSV":
          downloadCSV();
          break;

        default:
          break;
      }
    }
  }, [tableData.length > 0]);

  const downloadXLS = () => {
    const xlsData = tableData.map((row, index) => ({
      "S.No": index + 1,
      "Drawing No": row.drawingNo,
      "Drawing Consultant": row.designDrawingConsultant.role,
      "Drawing Category": row.category?.category || "",
      "Drawing Title": row.drawingTitle,
      [props.type === "architect"
        ? "Accepted Ro Submission Date"
        : "Accepted Site Submission Date"]:
        props.type === "architect"
          ? formatDate(row.acceptedROSubmissionDate)
          : formatDate(row.acceptedSiteSubmissionDate),
      "Final Submission Date":
        (props.type === "architect"
          ? formatDate(row.acceptedArchitectRevisions[0]?.softCopySubmittedDate)
          : formatDate(row.acceptedRORevisions[0]?.softCopySubmittedDate)) ||
        "N/A",
      "Due/Overdue": calculateDueDays(
        row.acceptedSiteSubmissionDate,
        row.acceptedRORevisions[0]?.softCopySubmittedDate
      ),
      Revision:
        props.type === "architect"
          ? row.acceptedArchitectRevisions.length > 0
            ? row.acceptedArchitectRevisions.slice(-1)[0].revision
            : "NA"
          : row.acceptedRORevisions.length > 0
          ? row.acceptedRORevisions.slice(-1)[0].revision
          : "NA",
    }));
    // Implement XLS download logic here, e.g., using a library like SheetJS (xlsx)
    // Example:
    const xlsx = require("xlsx");
    const worksheet = xlsx.utils.json_to_sheet(xlsData);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Report");
    xlsx.writeFile(workbook, "report.xlsx");
    setDownloaded(true);
    handleDialogClose();
  };

  const downloadCSV = () => {
    const csvData = tableData.map((row, index) => ({
      "S.No": index + 1,
      "Drawing No": row.drawingNo,
      "Drawing Consultant": row.designDrawingConsultant.role,
      "Drawing Category": row.category?.category || "",
      "Drawing Title": row.drawingTitle,
      [props.type === "architect"
        ? "Accepted Ro Submission Date"
        : "Accepted Site Submission Date"]:
        props.type === "architect"
          ? formatDate(row.acceptedROSubmissionDate)
          : formatDate(row.acceptedSiteSubmissionDate),
      "Final Submission Date":
        (props.type === "architect"
          ? formatDate(row.acceptedArchitectRevisions[0]?.softCopySubmittedDate)
          : formatDate(row.acceptedRORevisions[0]?.softCopySubmittedDate)) ||
        "N/A",
      "Due/Overdue": calculateDueDays(
        row.acceptedSiteSubmissionDate,
        row.acceptedRORevisions[0]?.softCopySubmittedDate
      ),
      Revision:
        props.type === "architect"
          ? row.acceptedArchitectRevisions.length > 0
            ? row.acceptedArchitectRevisions.slice(-1)[0].revision
            : "NA"
          : row.acceptedRORevisions.length > 0
          ? row.acceptedRORevisions.slice(-1)[0].revision
          : "NA",
    }));
    // Implement CSV download logic here
    // Example:
    const csvContent =
      "data:text/csv;charset=utf-8," +
      Object.keys(csvData[0]).join(",") +
      "\n" +
      csvData.map((row) => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    setDownloaded(true);
    handleDialogClose();
  };

  const renderTableHeader = () => {
    console.log("Report Type:", popupcontent.reportType);

    switch (popupcontent.reportType) {
      case "drawing":
        switch (popupcontent.reportType) {
          case "CollectedFromRO":
            return (
              <tr>
                <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                <th style={{ width: "10%" }}>DRWG No</th>
                <th style={{ width: "10%" }}>Consultant</th>
                <th style={{ width: "10%" }}>Folder</th>
                <th style={{ width: "10%" }}>Drawing Category</th>
                <th style={{ width: "10%" }}>Drawing Title</th>
                <th style={{ width: "13%", paddingLeft: "10px" }}>
                  R0 Soft Copy Submission Date
                </th>
                <th style={{ width: "10%" }}>Revision</th>
              </tr>
            );
          case "IssuedFromRO":
            return (
              <tr>
                <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                <th style={{ width: "10%" }}>Drawing No</th>
                <th style={{ width: "10%" }}>Design Consultant</th>
                <th style={{ width: "10%" }}>Drawing Category</th>
                <th style={{ width: "10%" }}>Drawing Title</th>
                <th style={{ width: "13%", paddingLeft: "10px" }}>
                  R0 Hard Copy Submission Date
                </th>
                <th style={{ width: "10%" }}>Revision</th>
              </tr>
            );

          default:
            return null;
        }
      case "RFI":
        return (
          <tr>
            <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
            <th style={{ width: "10%" }}>Drawing Number</th>
            <th style={{ width: "10%" }}>Design Consultant</th>
            <th style={{ width: "10%" }}>Drawing Category</th>
            <th style={{ width: "10%" }}>Drawing Title</th>
            <th style={{ width: "13%" }}>Requested Date</th>
            <th style={{ width: "13%" }}>Expected Date</th>
            <th style={{ width: "10%" }}>Revision</th>
            <th style={{ width: "10%" }}>Status</th>
          </tr>
        );
      case "pending":
        return (
          <tr>
            <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
            <th style={{ width: "10%" }}>Drawing No</th>
            <th style={{ width: "15%" }}>Design Consultant</th>
            <th style={{ width: "10%" }}>Drawing Category</th>
            <th style={{ width: "15%" }}>Drawing Title</th>
            <th style={{ width: "13%" }}>
              {props.type === "architect"
                ? "Accepted Ro Submission Date"
                : "Accepted Site Submission Date"}
            </th>
            <th style={{ width: "10%" }}>Due/Overdue</th>
          </tr>
        );
      case "register":
        return (
          <tr>
            <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
            <th style={{ width: "15%" }}>Drawing No</th>
            <th style={{ width: "10%" }}>Design Consultant</th>
            <th style={{ width: "15%" }}>Drawing Category</th>
            <th style={{ width: "15%" }}>Drawing Title</th>
            {props.type === "architect" && (
              <th style={{ width: "15%" }}>Accepted R0 Submission Date</th>
            )}
            <th style={{ width: "15%" }}>Accepted Site Submission Date</th>
          </tr>
        );
      default:
        return null;
    }
  };

  const renderTableHeaderData = (type) => {
    console.log("Report Type:", popupcontent.reportType);
    console.log("Report Type type:", type);

    switch (popupcontent.reportType) {
      case "drawing":
        switch (type) {
          case "acceptedRoHardCopyRevisions":
            return (
              <>
                <p>Issued From RO</p>

                <tr>
                  <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                  <th style={{ width: "10%" }}>DRWG No</th>
                  <th style={{ width: "10%" }}>Consultant</th>
                  <th style={{ width: "10%" }}>Folder</th>
                  <th style={{ width: "10%" }}>Drawing Category</th>
                  <th style={{ width: "10%" }}>Drawing Title</th>
                  <th style={{ width: "13%", paddingLeft: "10px" }}>
                    R0 Soft Copy Submission Date
                  </th>
                  <th style={{ width: "10%" }}>Revision</th>
                </tr>
              </>
            );
          case "acceptedRORevisions":
            return (
              <>
                <p style={{ margin: "15px", display: "inline-block" }}>
                  Collected from RO
                </p>
                <tr>
                  <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                  <th style={{ width: "10%" }}>Drawing No</th>
                  <th style={{ width: "10%" }}>Consultant</th>
                  <th style={{ width: "10%" }}>Folder</th>
                  <th style={{ width: "10%" }}>Drawing Category</th>
                  <th style={{ width: "10%" }}>Drawing Title</th>
                  <th style={{ width: "13%", paddingLeft: "10px" }}>
                    R0 Hard Copy Submission Date
                  </th>
                  <th style={{ width: "10%" }}>Revision</th>
                </tr>
              </>
            );

          default:
            return null;
        }
      case "RFI":
        switch (type) {
          case "rfiData":
            return (
              <tr>
                <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                <th style={{ width: "10%" }}>Drawing Number</th>
                <th style={{ width: "10%" }}>Design Consultant</th>
                <th style={{ width: "10%" }}>Drawing Category</th>
                <th style={{ width: "10%" }}>Drawing Title</th>
                <th style={{ width: "13%" }}>Requested Date</th>
                <th style={{ width: "13%" }}>Expected Date</th>
                <th style={{ width: "10%" }}>Revision</th>
                <th style={{ width: "10%" }}>Status</th>
              </tr>
            );

          default:
            return null;
        }

      case "pending":
        switch (type) {
          case "pendingacceptedRoHardCopyRevisions":
            return (
              <>
                <p>Issued From RO</p>

                <tr>
                  <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                  <th style={{ width: "10%" }}>DRWG No</th>
                  <th style={{ width: "10%" }}>Consultant</th>
                  <th style={{ width: "10%" }}>Folder</th>
                  <th style={{ width: "10%" }}>Drawing Category</th>
                  <th style={{ width: "10%" }}>Drawing Title</th>
                  <th style={{ width: "13%", paddingLeft: "10px" }}>
                    R0 Soft Copy Submission Date
                  </th>
                  <th style={{ width: "10%" }}>Revision</th>
                </tr>
              </>
            );
          case "pendingAcceptedRORevisions":
            return (
              <>
                <p style={{ margin: "15px", display: "inline-block" }}>
                  Collected from RO
                </p>
                <tr>
                  <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                  <th style={{ width: "10%" }}>Drawing No</th>
                  <th style={{ width: "10%" }}>Consultant</th>
                  <th style={{ width: "10%" }}>Folder</th>
                  <th style={{ width: "10%" }}>Drawing Category</th>
                  <th style={{ width: "10%" }}>Drawing Title</th>
                  <th style={{ width: "13%", paddingLeft: "10px" }}>
                    R0 Hard Copy Submission Date
                  </th>
                  <th style={{ width: "10%" }}>Revision</th>
                </tr>
              </>
            );

          default:
            return null;
        }
      case "register":
        return (
          <tr>
            <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
            <th style={{ width: "15%" }}>Drawing No</th>
            <th style={{ width: "10%" }}>Design Consultant</th>
            <th style={{ width: "15%" }}>Drawing Category</th>
            <th style={{ width: "15%" }}>Drawing Title</th>
            {props.type === "architect" && (
              <th style={{ width: "15%" }}>Accepted R0 Submission Date</th>
            )}
            <th style={{ width: "15%" }}>Accepted Site Submission Date</th>
          </tr>
        );
      default:
        return null;
    }
  };

  const renderTableBodyData = (type, data) => {
    console.log("Current tableType:", type);
    console.log("Current tableData:", data);

    return data.map((item, index) => (
      <tr key={item.id}>
        <td>{index + 1}</td>

        {popupcontent.reportType === "drawing" &&
          type === "acceptedRoHardCopyRevisions" && (
            <>
              <td>{item.drawingNo}</td>
              <td>{item.designDrawingConsultant?.role || "N/A"}</td>
              <td>{item.folderId?.folderName || "N/A"}</td>
              <td>{item.category?.category || "N/A"}</td>
              <td>{item.drawingTitle || "N/A"}</td>
              <td>
                {item.acceptedRORevisions?.[0]?.softCopySubmittedDate
                  ? new Date(
                      item.acceptedRORevisions[0].softCopySubmittedDate
                    ).toLocaleDateString()
                  : "N/A"}
              </td>
              <td>{item.acceptedRORevisions?.[0]?.revision || "N/A"}</td>
            </>
          )}

        {popupcontent.reportType === "drawing" &&
          type === "acceptedRORevisions" && (
            <>
              <td>{item.drawingNo}</td>
              <td>{item.designDrawingConsultant?.role || "N/A"}</td>
              <td>{item.folderId?.folderName || "N/A"}</td>
              <td>{item.category?.category || "N/A"}</td>
              <td>{item.drawingTitle || "N/A"}</td>
              <td>
                {item.acceptedROHardCopyRevisions?.[0]?.hardCopySubmittedDate
                  ? formatDate(
                      item.acceptedROHardCopyRevisions[0].hardCopySubmittedDate
                    )
                  : "N/A"}
              </td>
              <td>
                {item.acceptedROHardCopyRevisions?.[0]?.revision || "N/A"}
              </td>
            </>
          )}

        {popupcontent.reportType === "RFI" && type === "rfiData" && (
          <>
            <td>{item.drawingNo}</td>
            <td>{item.drawingId.designDrawingConsultant?.role || "N/A"}</td>
            <td>{item.drawingId.category?.category || "N/A"}</td>
            <td>{item.drawingId.drawingTitle || "N/A"}</td>
            <td>{formatDate(item.requestedDate) || "N/A"}</td>
            <td>{formatDate(item.expectedDate) || "N/A"}</td>
            <td>{item.revision || "N/A"}</td>
            <td>{item.status || "N/A"}</td>
          </>
        )}

        {popupcontent.reportType === "pending" &&
          type === "pendingacceptedRoHardCopyRevisions" && (
            <>
              <td>{item.drawingNo}</td>
              <td>{item.designDrawingConsultant?.role || "N/A"}</td>
              <td>{item.folderId?.folderName || "N/A"}</td>
              <td>{item.category?.category || "N/A"}</td>
              <td>{item.drawingTitle || "N/A"}</td>
              <td>
                {item.acceptedRORevisions?.[0]?.softCopySubmittedDate
                  ? new Date(
                      item.acceptedRORevisions[0].softCopySubmittedDate
                    ).toLocaleDateString()
                  : "N/A"}
              </td>
              <td>{item.acceptedRORevisions?.[0]?.revision || "N/A"}</td>
            </>
          )}

        {popupcontent.reportType === "pending" &&
          type === "pendingAcceptedRORevisions" && (
            <>
              <td>{item.drawingNo}</td>
              <td>{item.designDrawingConsultant?.role || "N/A"}</td>
              <td>{item.folderId?.folderName || "N/A"}</td>
              <td>{item.category?.category || "N/A"}</td>
              <td>{item.drawingTitle || "N/A"}</td>
              <td>
                {item.acceptedROHardCopyRevisions?.[0]?.hardCopySubmittedDate
                  ? formatDate(
                      item.acceptedROHardCopyRevisions[0].hardCopySubmittedDate
                    )
                  : "N/A"}
              </td>
              <td>
                {item.acceptedROHardCopyRevisions?.[0]?.revision || "N/A"}
              </td>
            </>
          )}

        {popupcontent.reportType === "pending" && (
          <>
            <td>{item.drawingNo}</td>
            <td>{item.designDrawingConsultant?.role || "N/A"}</td>
            <td>{item.category?.category || "N/A"}</td>
            <td>{item.drawingTitle || "N/A"}</td>
            <td>
              {props.type === "architect"
                ? formatDate(item.acceptedROSubmissionDate)
                : formatDate(item.acceptedSiteSubmissionDate)}
            </td>
            <td>
              {props.type === "architect" ? (
                <span
                  style={{
                    color:
                      calculateDueDays(
                        item.acceptedROSubmissionDate,
                        item.submittedDate
                      ) >= 0
                        ? "green"
                        : "red",
                  }}
                >
                  {calculateDueDays(
                    item.acceptedROSubmissionDate,
                    item.submittedDate
                  ) >= 0
                    ? `${calculateDueDays(
                        item.acceptedROSubmissionDate,
                        item.submittedDate
                      )} days`
                    : `${Math.abs(
                        calculateDueDays(
                          item.acceptedROSubmissionDate,
                          item.submittedDate
                        )
                      )} days overdue`}
                </span>
              ) : (
                <span
                  style={{
                    color:
                      calculateDueDays(
                        item.acceptedSiteSubmissionDate,
                        item.submittedDate
                      ) >= 0
                        ? "green"
                        : "red",
                  }}
                >
                  {calculateDueDays(
                    item.acceptedSiteSubmissionDate,
                    item.submittedDate
                  ) >= 0
                    ? `${calculateDueDays(
                        item.acceptedSiteSubmissionDate,
                        item.submittedDate
                      )} days`
                    : `${Math.abs(
                        calculateDueDays(
                          item.acceptedSiteSubmissionDate,
                          item.submittedDate
                        )
                      )} days overdue`}
                </span>
              )}
            </td>
          </>
        )}

        {popupcontent.reportType === "register" && (
          <>
            <td>{item.drawingNo}</td>
            <td>{item.designDrawingConsultant?.role || "N/A"}</td>
            <td>{item.category?.category || "N/A"}</td>
            <td>{item.drawingTitle || "N/A"}</td>
            <td>
              {props.type === "architect"
                ? formatDate(item.acceptedROSubmissionDate)
                : formatDate(item.acceptedSiteSubmissionDate)}
            </td>
          </>
        )}
      </tr>
    ));
  };

  return (
    <Dialog
      open={isOpen}
      disableBackdropClickonClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        handleDialogClose();
      }}
      maxWidth="xxl"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 20,
          fontFamily: "Poppins",
          border: "1px solid",
          padding: "10px",
        },
      }}
    >
      <DialogContent>
        <div ref={targetRef}>
          {popupcontent.selectViewDownload === "View" && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p></p>
              <RxCross1
                size={15}
                onClick={handleDialogClose}
                style={{ cursor: "pointer" }}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "5px 55px",
              gap: "20px",
            }}
          >
            <div>
              <p className="HeadingStatus">
                {popupcontent.reportType} - {popupcontent.consultantRole}
              </p>
            </div>
            <div className="project">
              {matchedSite ? (
                <p className="projectTitle">
                  Project:
                  <span
                    className="siteNameProject"
                    style={{ fontWeight: "400" }}
                  >
                    {matchedSite.siteName}
                  </span>
                </p>
              ) : (
                <p>No site Name</p>
              )}
            </div>
            <div className="date-range-container">
              <p className="PeriodTime">Period :</p>
              {(popupcontent.timePeriod === "By Date" ||
                popupcontent.timePeriod === "Last 6 Months") && (
                <>
                  <p style={{ fontWeight: "400" }}>From..</p>
                  <p style={{ fontWeight: "400" }}>{popupcontent.fromDate}</p>
                  <p style={{ fontWeight: "400" }}>To..</p>
                  <p style={{ fontWeight: "400" }}>{popupcontent.toDate}</p>
                </>
              )}
              {popupcontent.timePeriod === "fromBeginningToTillDate" && (
                <>
                  <p style={{ fontWeight: "400" }}>From..</p>
                  <p style={{ fontWeight: "400" }}>{startDate}</p>
                  <p style={{ fontWeight: "400" }}>To..</p>
                  <p style={{ fontWeight: "400" }}>{endDate}</p>
                </>
              )}

              {popupcontent.timePeriod === "By Month" && (
                <>
                  <p style={{ fontWeight: "400" }}>
                    {popupcontent.month}th month
                  </p>
                  <p style={{ fontWeight: "400" }}>{popupcontent.year} year</p>
                </>
              )}
            </div>
          </div>
          {!loaded ? (
            <>
              <Loader />
              <p style={{ textAlign: "center" }}>No data avaliable</p>
            </>
          ) : (
            <>
              <table>
                <thead>
                  {renderTableHeaderData("acceptedRoHardCopyRevisions")}
                </thead>
                <tbody>
                  {renderTableBodyData(
                    "acceptedRoHardCopyRevisions",
                    acceptedRoHardCopyRevisions
                  )}
                </tbody>
              </table>

              <table>
                <thead>{renderTableHeaderData("acceptedRORevisions")}</thead>
                <tbody>
                  {renderTableBodyData(
                    "acceptedRORevisions",
                    acceptedRORevisions
                  )}
                </tbody>
              </table>

              <table>
                <thead>{renderTableHeaderData("rfiData")}</thead>
                <tbody>{renderTableBodyData("rfiData", rfiData)}</tbody>
              </table>

              <table>
                <thead>{renderTableHeaderData("rfiData")}</thead>
                <tbody>{renderTableBodyData("rfiData", rfiData)}</tbody>
              </table>
              <table>
                <thead>
                  {renderTableHeaderData("pendingAcceptedRORevisions")}
                </thead>
                <tbody>
                  {renderTableBodyData(
                    "pendingAcceptedRORevisions",
                    pendingAcceptedRORevisions
                  )}
                </tbody>
              </table>

              <table>
                <thead>
                  {renderTableHeaderData("pendingacceptedRoHardCopyRevisions")}
                </thead>
                <tbody>
                  {renderTableBodyData(
                    "pendingacceptedRoHardCopyRevisions",
                    pendingacceptedRoHardCopyRevisions
                  )}
                </tbody>
              </table>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  padding: "5px 55px",
                }}
              >
                <div>
                  {popupcontent.selectViewDownload !== "View" && (
                    <p className="downloadBy">
                      Download by {currentUserInfo.firstName}
                      <span>{currentUserInfo.lastName}</span>
                    </p>
                  )}
                </div>

                <div>
                  {/* <p>
                    Page {currentPage} of {totalPages}
                  </p> */}
                </div>

                <div className="date-time-container">
                  <div className="date-container">
                    <p>{day}</p>
                    <p>{month}</p>
                    <p>{year}</p>
                  </div>
                  <p className="time">{time}</p>
                </div>

                <div>
                  <p className="footerTitle">RCON SPACE</p>
                </div>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

TablePage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  popupcontent: PropTypes.shape({
    reportType: PropTypes.string,
    consultantId: PropTypes.string,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }),
};

export default TablePage;
