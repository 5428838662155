import React from "react";
import "./ApprovalTable.css"; // Import the CSS file

const ApprovalTable = (props) => {
  const { ApprovalTableBody, ApprovalTableHead } = props;

  return (
    <div className="table-responsive">
      <table className="table table-striped header-fixed">
        <thead className="approval-table-head">{ApprovalTableHead}</thead>
        <tbody className="approval-table-body">{ApprovalTableBody}</tbody>
      </table>
    </div>
  );
};

export default ApprovalTable;
