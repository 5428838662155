import React, {useState} from 'react';

const works = [
    'Brick Work',
    'Electrical Work',
    'Internal Planning',
    'Internal Toilet Plumbing',
    'Water Proofing',
    'Toilet Flooring',
    'Unit Flooring',
];

const generateRandomPercentages = () => {
    return works.reduce((acc, work) => {
        acc[work] = {
            ratio: Math.random(),
            
            delay: Math.random()
        };
        return acc;
    }, {});
};

const getStatus = (ratio) => {
    if (ratio === 0) {
        return "STARTS";
    } else if (ratio > 0 && ratio < 0.5) {
        return "STARTED";
    } else if (ratio > 0.5 && ratio <= 0.75) {
        return "IN PROGRESS";
    } else if (ratio > 0.75 && ratio <= 0.99) {
        return "COMPLETING SOON";
    } else {
        return "COMPLETED";
    }
};

const initialFloors = Array.from({ length: 15 }, (_, i) => ({
    number: i + 1,
    percentages: generateRandomPercentages(),
}));

const getStatusAndColors = (ratio) => {
    let backgroundColor, textColor, color;
    if (ratio === 0) {
        backgroundColor = 'gray-300';
        textColor = 'black';
        color = "gray-100";
        
    } else if (ratio > 0 && ratio < 0.5) {
        backgroundColor = 'red-500';
        textColor = 'black';
        color = "red-500";
    } else if (ratio > 0.5 && ratio <= 0.75) {
        backgroundColor = 'yellow-500';
        textColor = 'black';
        color = "yellow-500";
    } else if (ratio > 0.75 && ratio <= 0.99) {
        backgroundColor = 'green-500';
        textColor = 'black';
        color = "green-500";
    } else {
        backgroundColor = 'green-500';
        textColor = 'black';
        color = "green-500";
    }
    return { backgroundColor, textColor, color };
};

function getType(input) {
    if (Array.isArray(input) && input.length > 0) {
        const firstItem = input[0];
        const keys = Object.keys(firstItem);
        if (keys.length > 0) {
            const firstKey = keys[0];
            if (firstItem[firstKey].units) {
                return "units";
            } else if (firstItem[firstKey].floors) {
                return "floors";
            }
        }
    }
    return "unknown";
}

const Cards = (cardData, loading) => {
    cardData = cardData.cardData
    let type =  getType(cardData)
    console.log(type)


    if (type === 'units') {
        
        const units = cardData.flatMap(work => Object.values(work)[0].units);
        const uniqueUnits = [...new Map(units.map(unit => [unit.unitid, unit])).values()];
        return (
            <div className='flex flex-col h-full overflow-x-auto'>
                <div  style={{ width: 'fit-content' }}>
                    <table className='min-w-full divide-y divide-gray-200' style={{ width: 'max-content' }}>
                        <thead className='bg-gray-50'>
                            <tr>
                                <th style={{ width: '150px', boxSizing: 'border-box' }} className='sticky top-0 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider bg-white z-10'>Floor</th>
                                {cardData.map(obj => Object.keys(obj)[0]).map((work, index) => (
                                <th key={index} style={{ width: '270px' }} className='sticky top-0 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider bg-white z-10'>{work}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className='bg-white divide-y divide-gray-200'>
                        {uniqueUnits.map((unit, unitIndex) => (
                                <tr key={unitIndex}>
                                    <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>{unit.unitName}</td>
                                    {cardData.map((item, index) => {
                                        const unitsData = Object.values(item)[0].units;
                                        const matchingUnit = unitsData.find(u => u.unitid === unit.unitid);
                                        let borderColor = '#cecece';
                                        let isEmpty = !matchingUnit.startDate && !matchingUnit.endDate
                                        let backgroundColor1 = '#f4f4f4';
                                        if (matchingUnit.progress > 0 && matchingUnit.progress < 100) {
                                            borderColor = '#ffdb5e'
                                            backgroundColor1 = '#fff6cb';
                                        }
                                        if (matchingUnit.progress === 100) {
                                            borderColor = '#ffdb5e'
                                            backgroundColor1 = '#6bcbb6';
                                        }
                                        return (
                                            <td key={index} className='px-6 py-4 whitespace-nowrap text-sm text-gray-900'>
                                                {/*matchingUnit ? matchingUnit.unitid : 'N/A'*/}
                                                <div style = {{height : '115px', width: '200px', border: `3px solid ${borderColor}`, position: 'relative',borderRadius: '5px',overflow: 'hidden',}}>
                                                    <div style = {{height: '100%', width: `${isEmpty ? 100 : matchingUnit.progress}%`, backgroundColor: `${backgroundColor1}`, transition: 'width 0.5s ease-in-out',  display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                        {matchingUnit.progress > 0 && matchingUnit.progress < 100 && (
                                                            <div style = {{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', }}>
                                                                <p>{matchingUnit.progress}%</p>
                                                                <p>{matchingUnit.endDate.substring(0, 10)}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }else if(cardData.length > 1) {
        const names = cardData.map(card => Object.keys(card)[0]);
        const floors = cardData[0][names[0]].floors.map(floor => floor.floorName);
        const tableData = floors.map(floor => {
          const rowData = { Floor: floor };
          names.forEach(name => {
            const progress = cardData.find(card => Object.keys(card)[0] === name)[name].floors.find(floorData => floorData.floorName === floor)?.progress || 0;
            rowData[name] = progress;
          });
          return rowData;
        });
        console.log(tableData)
        return (
            <div className='flex flex-col h-full overflow-x-auto'>
                <div  style={{ width: 'fit-content' }}>
                    <table className='min-w-full divide-y divide-gray-200' style={{ width: 'max-content' }}>
                        <thead className='bg-gray-50'>
                            <tr>
                                <th style={{ width: '150px', boxSizing: 'border-box' }} className='sticky top-0 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider bg-white z-10'>Floor</th>
                                {names.map(name => (
                                     <th key={name} style={{ width: '270px' }} className='sticky top-0 px-6 py-3 text-center text-xs font-bold uppercase tracking-wider bg-white z-10'>{name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className='bg-white divide-y divide-gray-200'>
                        {tableData.map(row => {

                            return (
                            <tr key={row.Floor}>
                            <td>{row.Floor}</td>
                            {names.map(name => {
                                return (
                                <td key={`${row.Floor}-${name}`} className='px-6 py-4 whitespace-nowrap text-sm text-gray-900'>
                                    <div style = {{height : '115px', width: '200px', border: `3px solid black`, position: 'relative',borderRadius: '5px',overflow: 'hidden',}}>
                                        <div style = {{height: '100%', width: `${row[name]}%`, backgroundColor: 'green', transition: 'width 0.5s ease-in-out',  }}>
                                            <div style = {{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', }}>
                                                <p>{row[name]}%</p>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            )})}
                            </tr>
                        )})}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};

export default Cards;

