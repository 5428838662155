import React from 'react';

export const Tabs = ({ value, onChange, children }) => {
    return (
      <div style={{ display: 'flex', }}>
        {children.map((child) =>
          React.cloneElement(child, {
            selected: child.props.value === value,
            onClick: (event) => onChange(event, child.props.value), // Pass event and value to onChange
          })
        )}
      </div>
    );
  };

export const Tab = ({ label, selected, onClick }) => {
  const styles = {
    tab: {
      padding: '10px 15px',
      cursor: 'pointer',
      fontWeight: '600',
      fontSize: '14px',
      textTransform: 'none',
      color: selected ? '#131313' : '#131313',
      borderBottom: selected ? '2px solid var(--primary-color)' : 'none',
      transition: 'color 0.3s',
    },
  };

  return (
    <div style={styles.tab} onClick={onClick}>
      {label}
    </div>
  );
};



