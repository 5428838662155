import React, { useState } from "react";
import { WebApimanager } from "../../../WebApiManager";
import "./ResetPassword.css";

import ResetPasswordLogo from "../../../Images/resetUserLogo.svg";
import TickImg from "../Images/TickImg.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [conformCode, setConformCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [conformCodeError, setConformCodeError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [success, setSuccess] = useState("");
  const [apiError, setApiError] = useState("");
  const webApi = new WebApimanager();

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setEmailError("");
    setConformCodeError("");
    setNewPasswordError("");
    setConfirmPasswordError("");
    setApiError("");
    setSuccess("");

    let hasError = false;

    if (!email) {
      setEmailError("Email is required");
      hasError = true;
    }
    if (!conformCode) {
      setConformCodeError("Conform Code is required");
      hasError = true;
    }
    if (!newPassword) {
      setNewPasswordError("New Password is required");
      hasError = true;
    }
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
      hasError = true;
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const payload = {
      email,
      temporaryPassword: conformCode,
      newPassword,
      confirmNewPassword: confirmPassword,
    };

    try {
      const response = await webApi.post(`api/users/resetPassword`, payload);
      if (response.status === 200) {
        setSuccess("Password successfully reset");
        window.location.href = `https://chromacheer.com/sign-in`;
      }
    } catch (error) {
      setApiError(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 d-flex align-items-center">
          <form onSubmit={handleSubmit} className="w-100">
            <img
              src={ResetPasswordLogo}
              alt="ResetPasswordLogo"
              className="mb-3"
            />
            <div className="mb-3">
              <label htmlFor="email" className="formUserLable">
                Email
              </label>
              <input
                type="email"
                className={`form-control ${emailError ? "is-invalid" : ""}`}
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: "100%" }}
              />
              {emailError && (
                <div className="invalid-feedback">{emailError}</div>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="conformCode" className="formUserLable">
                Conform Code
              </label>
              <input
                type="text"
                className={`form-control ${
                  conformCodeError ? "is-invalid" : ""
                }`}
                id="conformCode"
                placeholder="Enter your code"
                value={conformCode}
                onChange={(e) => setConformCode(e.target.value)}
                style={{ width: "100%" }}
              />
              {conformCodeError && (
                <div className="invalid-feedback">{conformCodeError}</div>
              )}
            </div>

            <div className="mb-3 password-container">
              <label htmlFor="newPassword" className="formUserLable">
                New Password
              </label>
              <div className="password-wrapper">
                <input
                  type={showNewPassword ? "text" : "password"}
                  className={`form-control ${
                    newPasswordError ? "is-invalid" : ""
                  }`}
                  id="newPassword"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  style={{ width: "100%" }}
                />
                <span
                  className="password-icon"
                  onClick={toggleNewPasswordVisibility}
                >
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              {newPasswordError && (
                <div className="invalid-feedback">{newPasswordError}</div>
              )}
            </div>

            <div className="mb-3 password-container">
              <label htmlFor="confirmPassword" className="formUserLable">
                Confirm Password
              </label>
              <div className="password-wrapper">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className={`form-control ${
                    confirmPasswordError ? "is-invalid" : ""
                  }`}
                  id="confirmPassword"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{ width: "100%" }}
                />
                <span
                  className="password-icon"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              {confirmPasswordError && (
                <div className="invalid-feedback">{confirmPasswordError}</div>
              )}
            </div>

            {success && <div className="alert alert-success">{success}</div>}
            {apiError && <div className="alert alert-danger">{apiError}</div>}

            <button type="submit" className="btn-submit mt-3">
              Submit
            </button>
          </form>
        </div>

        <div className="col-md-6 d-none d-md-flex justify-content-center align-items-center">
          <img
            src={TickImg}
            alt="Reset Password Illustration"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
