/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SelectSite = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Navigate to the login page when the component is rendered
    navigate('/sign-in');
  }, [navigate]);

  return null; // Since you want to navigate, we don't need to return anything
};

export default SelectSite;
